import { ActionTree } from 'vuex';
import { MutationTypes } from './mutations';
import { Alert, RootState } from './state';

export const actions = (): ActionTree<RootState, RootState> => ({
  setNextUrl({ commit }, nextUrl: string): void {
    commit(MutationTypes.SET_NEXT_URL, nextUrl);
  },
  updateSearchTerm({ commit }, search: string): void {
    commit(MutationTypes.SET_SEARCH_TERM, search);
  },
  updateFilters({ commit }, filter: { [key: string]: string }): void {
    commit(MutationTypes.ADD_FILTER, filter);
  },
  removeFilter({ commit }, key: string): void {
    commit(MutationTypes.REMOVE_FILTER, key);
  },
  removeAllFilters({ commit }): void {
    commit(MutationTypes.REMOVE_ALL_FILTERS);
  },
  showSnackbar(
    { commit },
    {
      content,
      color,
      timeout = 3500,
      withLoader,
    }: { content: string; color: string; timeout: number; withLoader: boolean },
  ): void {
    commit(MutationTypes.SHOW_SNACKBAR, { content, color, timeout, withLoader });
  },
  hideSnackbar({ commit }) {
    commit(MutationTypes.HIDE_SNACKBAR);
  },
  setAlert({ commit }, alertObject: Alert): void {
    commit(MutationTypes.SET_ALERT, alertObject);
  },
  dismissAlert({ commit }): void {
    commit(MutationTypes.DISMISS_ALERT);
  },
  changeAgency({ commit }, _agency: { name: string; PropertyId: string }): void {
    commit(MutationTypes.SET_ACTIVE_AGENCY, _agency);
  },
  changeSubAgency({ commit }, _agency: { name: string; PropertyId: string }): void {
    commit(MutationTypes.SET_ACTIVE_SUB_AGENCY, _agency);
  },
});

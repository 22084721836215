import 'reflect-metadata';
import { injectable } from 'inversify';
import { NewProposalRoute } from '@/shared/types';
import { LocalStorageServiceContract, UXUtilsServiceContract } from '@/injectables';

@injectable()
export class UXUtilsService implements UXUtilsServiceContract {
  setProposalStepForUser({
    email,
    proposalId,
    route,
    storageService,
  }: {
    email: string;
    proposalId: string;
    route: NewProposalRoute;
    storageService: LocalStorageServiceContract;
  }): void {
    storageService.setItem(`${email}_${proposalId}`, JSON.stringify(route));
  }

  getProposalStepForUser({
    email,
    proposalId,
    storageService,
  }: {
    email: string;
    proposalId: string;
    storageService: LocalStorageServiceContract;
  }): NewProposalRoute {
    const value = storageService.getItem(`${email}_${proposalId}`);

    if (value) {
      const step = JSON.parse(value);

      return step;
    }
    return null;
  }
}

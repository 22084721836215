import { injectable } from 'inversify';
import { Err, Ok, Result } from '@sniptt/monads';
import LRU from 'lru-cache';

import { Failure } from '@/injectables/failure';
import { GeocodeServiceContract } from '@/injectables';
import { Service } from '@/injectables/service';
import { UnsafeAny } from '@/shared/types';

const API_KEY = process.env.VUE_APP_GEO_CODE_SEARCH_API_KEY;

const _cache = new LRU({ max: 500 });

@injectable()
export class GeocodeService extends Service implements GeocodeServiceContract {
  async searchByText(text: string): Promise<UnsafeAny> {
    const { data } = await this._axios.get(
      `https://geocoder.ls.hereapi.com/6.2/geocode.json?apiKey=${API_KEY}&searchtext=${text}`,
    );
    return data;
  }

  async searchByLocation(location: string): Promise<Result<UnsafeAny, Failure>> {
    const url = `https://geocode.search.hereapi.com/v1/geocode?apiKey=${API_KEY}&in=countryCode:PRI,USA&q=${location}`;
    try {
      if (_cache.has(url)) {
        return Ok(_cache.get(url));
      }
      const { data } = await this._axios.get(url);
      _cache.set(url, data.items);
      return Ok(data.items);
    } catch (error) {
      return Err({
        message: `Can't load geocode items at this time.`,
      });
    }
  }
  // Lincoln & Hastings-Kearney Plus
  async searchByLocationWithGeoType(location: string, geoType: string): Promise<Result<UnsafeAny, Failure>> {
    return this.searchWithGeoService(location, geoType);
  }

  async searchWithGeoService(query: string, type) {
    try {
      const url = '/geo/findGeoObjects';
      const { data } = await this._axios.post(url, {
        query,
        type: type.toLowerCase(),
        limit: null,
      });
      return Ok(data?.data?.searchGeoTargets?.items || []);
    } catch (error) {
      return Err({
        message: `Can't find geo at this time.`,
      });
    }
  }
}


import Vue from 'vue';

import PageEditNameField from './PageEditNameField.vue';
import PageListActions from './PageListActions.vue';

interface Data {
  clicked: boolean;
  editMode: boolean;
}
// TODO: we have to rewrite to remove dependencies of  PageEditNameField and PageListActions entities
export default Vue.extend({
  name: 'BasicPageListElement',

  components: {
    PageListActions,
    PageEditNameField,
  },

  props: {
    page: {
      type: Object,
      required: true,
    },
    withDotIcon: {
      type: Boolean,
      default: false,
    },
    addPageUnavailable: {
      type: Boolean,
      default: false,
    },
    subGroupShown: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    actionsAllowed: {
      type: Boolean,
      default: false,
    },
    disabledActions: {
      type: Boolean,
      default: false,
    },
  },

  data(): Data {
    return {
      clicked: false,
      editMode: false,
    };
  },

  computed: {
    chevronIcon() {
      return this.subGroupShown ? 'mdi-chevron-down' : 'mdi-chevron-up';
    },
  },
});

import { Failure } from '@/injectables/failure';
import { Err, Ok, Result } from '@sniptt/monads/build';
import { OutputService as RestOutputService } from './http';
import { OutputServiceContract } from '../contracts';
import {
  GetSlideLibraryDocument,
  GetSlideLibraryQuery,
  GetSlideLibraryQueryVariables,
} from '../graphql/queries/get-slide-library.generated';
import {
  GetLibrarySlideByIdDocument,
  GetLibrarySlideByIdQuery,
  GetLibrarySlideByIdQueryVariables,
} from '../graphql/queries/get-library-slide-by-id.generated';
import {
  RemovePreparedSlideDocument,
  RemovePreparedSlideMutation,
  RemovePreparedSlideMutationVariables,
} from '../graphql/mutations/remove-library-slide.generated';
import { OutputSlide } from '@/shared/legacy/classes';
import {
  CreateUserLibrarySlideDocument,
  CreateUserLibrarySlideMutation,
  CreateUserLibrarySlideMutationVariables,
} from '../graphql/mutations/create-user-library-slide.generated';
import {
  CreateAgencyLibrarySlideDocument,
  CreateAgencyLibrarySlideMutation,
  CreateAgencyLibrarySlideMutationVariables,
} from '../graphql/mutations/create-agency-library-slide.generated';
import {
  UpdateLibrarySlideDocument,
  UpdateLibrarySlideMutation,
  UpdateLibrarySlideMutationVariables,
} from '../graphql/mutations/update-library-slide.generated';
import { OutputLayoutTypeGql, Scalars, UpdateBlankSlideInput } from '@/app/graphql';
import {
  GetLayoutsByAgencyDocument,
  GetLayoutsByAgencyQuery,
  GetLayoutsByAgencyQueryVariables,
} from '../graphql/queries/get-agency-layouts.generated';
import {
  GetAdditionalLayoutInfoDocument,
  GetAdditionalLayoutInfoQuery,
  GetAdditionalLayoutInfoQueryVariables,
} from '../graphql/queries/get-additional-layout-info.generated';
import { omit } from 'lodash';
import {
  UpdateTemplateLibraryDocument,
  UpdateTemplateLibraryMutation,
  UpdateTemplateLibraryMutationVariables,
} from '../graphql/mutations/update-template-slides-library.generated';
import {
  ResetTemplateSlideDocument,
  ResetTemplateSlideMutation,
  ResetTemplateSlideMutationVariables,
} from '../graphql/mutations/reset-template-slide.generated';
import {
  GetProductSlidesDocument,
  GetProductSlidesQuery,
  GetProductSlidesQueryVariables,
} from '../graphql/queries/get-product-slides.generated';
import {
  UpdateProductSlidesDocument,
  UpdateProductSlidesMutation,
  UpdateProductSlidesMutationVariables,
} from '../graphql/mutations/update-product-slides.generated';
import {
  ResetProductTemplateSlideDocument,
  ResetProductTemplateSlideMutation,
  ResetProductTemplateSlideMutationVariables,
} from '../graphql/mutations/reset-product-template-slide.generated';
import {
  GetProposalOutputDocument,
  GetProposalOutputQuery,
  GetProposalOutputQueryVariables,
} from '../graphql/queries/get-proposal-output.generated';
import {
  UpdateProposalLayoutDocument,
  UpdateProposalLayoutMutation,
  UpdateProposalLayoutMutationVariables,
} from '../graphql/mutations/update-proposal-layout.generated';
import {
  UpdateProposalSlidesDocument,
  UpdateProposalSlidesMutation,
  UpdateProposalSlidesMutationVariables,
} from '../graphql/mutations/update-proposal-slides.generated';
import {
  GetFilledSlidesDocument,
  GetFilledSlidesQuery,
  GetFilledSlidesQueryVariables,
} from '../graphql/queries/get-filed-slides.generated';
import {
  ResetProposalOutputDocument,
  ResetProposalOutputMutation,
  ResetProposalOutputMutationVariables,
} from '../graphql/mutations/reset-proposal-output.generated';
import {
  GetPackageSlidesDocument,
  GetPackageSlidesQuery,
  GetPackageSlidesQueryVariables,
} from '../graphql/queries/get-package-slides.generated';
import {
  UpdatePackageSlideDocument,
  UpdatePackageSlideMutation,
  UpdatePackageSlideMutationVariables,
} from '../graphql/mutations/update-package-slide.generated';
import {
  ResetPackageTemplateSlideDocument,
  ResetPackageTemplateSlideMutation,
  ResetPackageTemplateSlideMutationVariables,
} from '../graphql/mutations/reset-package-template-slide.generated';
import {
  GetProductLibraryDocument,
  GetProductLibraryQuery,
  GetProductLibraryQueryVariables,
} from '../graphql/queries/get-product-library.generated';
import {
  GetAgencyBlankSlidesDocument,
  GetAgencyBlankSlidesQuery,
  GetAgencyBlankSlidesQueryVariables,
} from '../graphql/queries/get-agency-blank-slides.generated';
import {
  CreateBlankSlideDocument,
  CreateBlankSlideMutation,
  CreateBlankSlideMutationVariables,
} from '../graphql/mutations/create-blank-slide.generated';
import {
  GetBlankSlideDocument,
  GetBlankSlideQuery,
  GetBlankSlideQueryVariables,
} from '../graphql/queries/get-blank-slide.generated';
import {
  UpdateBlankSlideDocument,
  UpdateBlankSlideMutation,
  UpdateBlankSlideMutationVariables,
} from '../graphql/mutations/update-blank-slide.generated';
import {
  DeleteBlankSlideDocument,
  DeleteBlankSlideMutation,
  DeleteBlankSlideMutationVariables,
} from '../graphql/mutations/delete-blank-slide.generated';
import {
  GetProposalBlankSlidesDocument,
  GetProposalBlankSlidesQuery,
  GetProposalBlankSlidesQueryVariables,
} from '../graphql/queries/get-proposal-blanks.generated';
import {
  CreateLayoutDocument,
  CreateLayoutMutation,
  CreateLayoutMutationVariables,
} from '../graphql/mutations/create-layout.generated';
import {
  GetOrCreateSlideTemplateLibraryDocument,
  GetOrCreateSlideTemplateLibraryMutation,
  GetOrCreateSlideTemplateLibraryMutationVariables,
} from '../graphql/mutations/get-template-library.generated';
import {
  UpdateProductSlidesWithChildrenDocument,
  UpdateProductSlidesWithChildrenMutation,
  UpdateProductSlidesWithChildrenMutationVariables,
} from '@/injectables/services/output/graphql/mutations/update-product-slides-with-children.generated';
import {
  SetColorSchemeDocument,
  SetColorSchemeMutation,
  SetColorSchemeMutationVariables,
} from '../graphql/mutations/set-color-scheme.generated';
import {
  DeleteProductSlideDocument,
  DeleteProductSlideMutation,
  DeleteProductSlideMutationVariables,
} from '../graphql/mutations/delete-product-slide.generated';
import {
  UpdateLibrarySlideNameDocument,
  UpdateLibrarySlideNameMutation,
  UpdateLibrarySlideNameMutationVariables,
} from '../graphql/mutations/update-library-slide-name.generated';

export class OutputService extends RestOutputService implements OutputServiceContract {
  async getSlideLibrary(
    agencyId: string,
    layout: Scalars['UUID'],
  ): Promise<Result<GetSlideLibraryQuery['getPreparedLibrary'], Failure>> {
    try {
      const { data, error } = await this._apollo.query<GetSlideLibraryQuery, GetSlideLibraryQueryVariables>({
        query: GetSlideLibraryDocument,
        variables: {
          input: {
            agencyId,
            layout,
          },
        },
      });
      if (error) {
        return Err({ message: "Can't get slide library at this time" });
      }
      return Ok(data.getPreparedLibrary);
    } catch (error) {
      return Err({ message: "Can't get slide library at this time" });
    }
  }

  async getProductSlideLibrary(
    agencyId: string,
  ): Promise<Result<GetProductLibraryQuery['getProductLibrarySlides'], Failure>> {
    try {
      const { data, error } = await this._apollo.query<GetProductLibraryQuery, GetProductLibraryQueryVariables>({
        query: GetProductLibraryDocument,
        variables: {
          agencyId,
        },
      });
      if (error) {
        return Err({ message: "Can't get product slide library at this time" });
      }
      return Ok(data.getProductLibrarySlides);
    } catch (error) {
      return Err({ message: "Can't get product slide library at this time" });
    }
  }

  async getLibrarySlide({
    slideId,
  }: {
    agencyId: string;
    slideId: string;
  }): Promise<Result<GetLibrarySlideByIdQuery['getPreparedSlide'], Failure>> {
    try {
      const { data, error } = await this._apollo.query<GetLibrarySlideByIdQuery, GetLibrarySlideByIdQueryVariables>({
        query: GetLibrarySlideByIdDocument,
        variables: {
          slideId,
        },
      });
      if (error) {
        return Err({ message: "Can't get library slide at this time" });
      }
      return Ok(data.getPreparedSlide);
    } catch (error) {
      return Err({ message: "Can't get library slide at this time" });
    }
  }

  async getLayoutAdditionalInfo(
    layout: Scalars['UUID'],
    productConfigId?: Scalars['UUID'],
  ): Promise<
    Result<
      GetAdditionalLayoutInfoQuery['getOutputLayoutAdditionalInfo'] &
        GetAdditionalLayoutInfoQuery['getLayoutColorScheme'] & {
          blanks: GetAdditionalLayoutInfoQuery['blanks'];
        },
      Failure
    >
  > {
    try {
      const { data, error } = await this._apollo.query<
        GetAdditionalLayoutInfoQuery,
        GetAdditionalLayoutInfoQueryVariables
      >({
        query: GetAdditionalLayoutInfoDocument,
        variables: {
          input: {
            layoutId: layout,
            productConfigId,
          },
          layoutId: layout,
        },
      });
      if (error) {
        return Err({ message: "Can't get layout info at this time" });
      }
      const result = { ...data.getOutputLayoutAdditionalInfo, blanks: data.blanks, ...data.getLayoutColorScheme };
      return Ok(result);
    } catch (error) {
      return Err({ message: "Can't get layout info at this time" });
    }
  }

  async getTemplateLibrary(
    layout: Scalars['UUID'],
    agencyId: Scalars['UUID'],
  ): Promise<Result<GetOrCreateSlideTemplateLibraryMutation['getOrCreateSlideTemplateLibrary'], Failure>> {
    try {
      const { data } = await this._apollo.mutate<
        GetOrCreateSlideTemplateLibraryMutation,
        GetOrCreateSlideTemplateLibraryMutationVariables
      >({
        mutation: GetOrCreateSlideTemplateLibraryDocument,
        variables: {
          input: {
            layoutId: layout,
            agencyId,
          },
        },
      });

      return Ok(data.getOrCreateSlideTemplateLibrary);
    } catch (error) {
      return Err({ message: "Can't get template library at this time" });
    }
  }

  async updateTemplateSlides({
    agency,
    layout,
    libraryId,
    slides,
  }: {
    agency: Scalars['UUID'];
    layout: Scalars['UUID'];
    slides: OutputSlide[];
    libraryId: Scalars['UUID'];
  }): Promise<Result<UpdateTemplateLibraryMutation['updateSlideTemplateLibrary'], Failure>> {
    try {
      const cleanedSlides = slides.map(slide => omit(slide, ['__typename', 'unsavedChanges']));
      const { data, errors } = await this._apollo.mutate<
        UpdateTemplateLibraryMutation,
        UpdateTemplateLibraryMutationVariables
      >({
        mutation: UpdateTemplateLibraryDocument,
        variables: {
          input: {
            agencyId: agency,
            layoutId: layout,
            id: libraryId,
            slides: cleanedSlides as any[],
          },
        },
      });
      if (errors) {
        return Err({ message: "Can't save slides at this time" });
      }
      return Ok(data.updateSlideTemplateLibrary);
    } catch (error) {
      return Err({ message: "Can't save slides at this time" });
    }
  }

  async resetDefaultSlide(slideId: string): Promise<Result<ResetTemplateSlideMutation['resetTemplateSlide'], Failure>> {
    try {
      const { data, errors } = await this._apollo.mutate<
        ResetTemplateSlideMutation,
        ResetTemplateSlideMutationVariables
      >({
        mutation: ResetTemplateSlideDocument,
        variables: {
          id: slideId,
        },
      });
      if (errors) {
        return Err({ message: "Can't reset slide at this time" });
      }
      return Ok(data.resetTemplateSlide);
    } catch (error) {
      return Err({ message: "Can't reset slide at this time" });
    }
  }

  async addSlideToLibrary(
    slide: OutputSlide,
    agencyId: string,
    { isAgencySlide, isShared }: { isAgencySlide: boolean; isShared: boolean },
  ): Promise<Result<any, Failure>> {
    const updatedSlide = omit(slide, ['__typename', 'defaultOrder', 'defaultSlide']);
    if (isAgencySlide) return this.addSlideToAgencyLibrary(updatedSlide, agencyId);
    if (isShared) return this.addSlideToSharedLibrary(updatedSlide, agencyId);

    return this.addSlideToUserLibrary(updatedSlide, agencyId);
  }

  async addSlideToUserLibrary(slide, agencyId) {
    try {
      const { data, errors } = await this._apollo.mutate<
        CreateUserLibrarySlideMutation,
        CreateUserLibrarySlideMutationVariables
      >({
        mutation: CreateUserLibrarySlideDocument,
        variables: {
          input: { slide, agencyId },
        },
      });
      if (errors) {
        return Err({ message: "Can't add slide to library at this time" });
      }
      return Ok(data.createUserLibrarySlide.slides);
    } catch (error) {
      return Err({ message: "Can't add slide to library at this time" });
    }
  }
  async addSlideToAgencyLibrary(slide, agencyId, isShared = false) {
    try {
      const { data } = await this._apollo.mutate<
        CreateAgencyLibrarySlideMutation,
        CreateAgencyLibrarySlideMutationVariables
      >({
        mutation: CreateAgencyLibrarySlideDocument,
        variables: {
          input: { slide, agencyId, isShared },
        },
      });
      return Ok(data.createAgencyLibrarySlide.slides);
    } catch (error) {
      return Err({ message: "Can't add slide to library at this time" });
    }
  }

  async addSlideToSharedLibrary(slide, agencyId) {
    return this.addSlideToAgencyLibrary(slide, agencyId, true);
  }

  async updateLibrarySlide(
    slide: OutputSlide,
  ): Promise<Result<UpdateLibrarySlideMutation['updatePreparedSlide'], Failure>> {
    try {
      const omittedSlide = omit(slide, [
        '_id',
        'order',
        '__typename',
        'userId',
        'slideLibraryId',
      ]) as UpdateLibrarySlideMutationVariables['input']['slide'];

      const { data } = await this._apollo.mutate<UpdateLibrarySlideMutation, UpdateLibrarySlideMutationVariables>({
        mutation: UpdateLibrarySlideDocument,
        variables: {
          input: { slide: omittedSlide, slideLibraryId: slide.slideLibraryId },
        },
      });
      return Ok(data.updatePreparedSlide);
    } catch (error) {
      return Err({ message: "Can't update library slide at this time" });
    }
  }

  async deleteSlideFromLibrary({
    slideId,
  }: {
    slideId: string;
  }): Promise<Result<RemovePreparedSlideMutation['deletePreparedSlide'], Failure>> {
    try {
      const { data } = await this._apollo.mutate<RemovePreparedSlideMutation, RemovePreparedSlideMutationVariables>({
        mutation: RemovePreparedSlideDocument,
        variables: {
          id: slideId,
        },
      });
      return Ok(data.deletePreparedSlide);
    } catch (error) {
      return Err({ message: "Can't remove slide from library at this time" });
    }
  }

  async getLayouts(
    params: { agencyId: Scalars['UUID'] } | { proposalId: Scalars['UUID'] },
  ): Promise<Result<GetLayoutsByAgencyQuery['getLayoutsByAgency'], Failure>> {
    if ('agencyId' in params) return this.getLayoutsByAgencyId(params.agencyId);
  }

  async getLayoutsByAgencyId(
    agencyId: Scalars['UUID'],
  ): Promise<Result<GetLayoutsByAgencyQuery['getLayoutsByAgency'], Failure>> {
    try {
      const { data } = await this._apollo.query<GetLayoutsByAgencyQuery, GetLayoutsByAgencyQueryVariables>({
        query: GetLayoutsByAgencyDocument,
        variables: {
          agencyId,
        },
      });
      return Ok(data.getLayoutsByAgency);
    } catch (error) {
      return Err({ message: "Can't get layouts at this time" });
    }
  }

  async getOutputPreview(proposalId: string): Promise<
    Result<
      {
        slide: GetProposalOutputQuery['getProposalOutput']['slides'][0];
        colorScheme: GetProposalOutputQuery['getProposalOutput']['colorScheme'];
      },
      Failure
    >
  > {
    try {
      const { isErr, unwrapErr, unwrap } = await this.getProposalOutput(proposalId);

      if (isErr()) {
        const { message } = unwrapErr();
        return Err({ message });
      }

      const { slides = [], colorScheme } = unwrap();

      return Ok({ slide: slides[0], colorScheme });
    } catch (error) {
      return Err({
        message: `Received an error from server: ${error}`,
      });
    }
  }

  async getProductSlides(
    layout: Scalars['UUID'],
    agencyId: string,
    productConfigId: string,
  ): Promise<Result<GetProductSlidesQuery['getProductSlideTemplate'], Failure>> {
    try {
      const { data, error } = await this._apollo.query<GetProductSlidesQuery, GetProductSlidesQueryVariables>({
        query: GetProductSlidesDocument,
        variables: {
          input: {
            layoutId: layout,
            agencyId,
            productConfigId,
          },
        },
      });
      if (error) {
        return Err({ message: "Can't get template library at this time" });
      }
      return Ok(data.getProductSlideTemplate);
    } catch (error) {
      return Err({ message: "Can't get template library at this time" });
    }
  }

  async updateProductSlides({
    layout,
    slides,
    productConfigId,
  }: {
    layout: Scalars['UUID'];
    slides: OutputSlide[];
    productConfigId: string;
  }): Promise<Result<UpdateProductSlidesMutation['updateProductSlideTemplate'], Failure>> {
    try {
      const cleanedSlides: any[] = slides.map(slide => omit(slide, ['__typename', 'unsavedChanges']));
      const { data } = await this._apollo.mutate<UpdateProductSlidesMutation, UpdateProductSlidesMutationVariables>({
        mutation: UpdateProductSlidesDocument,
        variables: {
          input: {
            layoutId: layout,
            productConfigId,
            slides: cleanedSlides,
          },
        },
      });
      return Ok(data.updateProductSlideTemplate);
    } catch (error) {
      return Err({ message: "Can't get layouts at this time" });
    }
  }

  async updateProductSlidesWithChildren({
    layout,
    slides,
    agencyId,
    productConfigId,
    childAgencyIds,
  }: {
    layout: string;
    slides: OutputSlide[];
    agencyId: string;
    productConfigId: string;
    childAgencyIds: string[];
  }): Promise<Result<UpdateProductSlidesWithChildrenMutation['updateProductSlideTemplateWithChildren'], Failure>> {
    try {
      const cleanedSlides: any[] = slides.map(slide => omit(slide, ['__typename', 'unsavedChanges']));
      const { data } = await this._apollo.mutate<
        UpdateProductSlidesWithChildrenMutation,
        UpdateProductSlidesWithChildrenMutationVariables
      >({
        mutation: UpdateProductSlidesWithChildrenDocument,
        variables: {
          input: {
            agencyId,
            layoutId: layout,
            productConfigId,
            slides: cleanedSlides,
          },
          childAgencyIds: childAgencyIds,
        },
      });
      return Ok(data.updateProductSlideTemplateWithChildren);
    } catch (error) {
      return Err({ message: "Can't get layouts at this time" });
    }
  }

  async resetProductTemplateSlide(
    slideId: string,
  ): Promise<Result<ResetProductTemplateSlideMutation['resetProductTemplateSlide'], Failure>> {
    try {
      const { data, errors } = await this._apollo.mutate<
        ResetProductTemplateSlideMutation,
        ResetProductTemplateSlideMutationVariables
      >({
        mutation: ResetProductTemplateSlideDocument,
        variables: {
          slideId,
        },
      });
      if (errors) {
        return Err({ message: "Can't reset slide at this time" });
      }
      return Ok(data.resetProductTemplateSlide);
    } catch (error) {
      return Err({ message: "Can't reset slide at this time" });
    }
  }

  async getProposalOutput(proposalId: string): Promise<Result<GetProposalOutputQuery['getProposalOutput'], Failure>> {
    try {
      // const cleanedSlides: any[] = slides.map(slide => omit(slide, ['__typename', 'unsavedChanges']));
      const { data } = await this._apollo.mutate<GetProposalOutputQuery, GetProposalOutputQueryVariables>({
        mutation: GetProposalOutputDocument,
        variables: {
          proposalId,
        },
      });
      return Ok(data.getProposalOutput);
    } catch (error) {
      return Err({ message: "Can't get proposal output at this time" });
    }
  }

  async updateProposalLayout(
    proposalId: Scalars['UUID'],
    layout: Scalars['UUID'],
  ): Promise<Result<UpdateProposalLayoutMutation['output'], Failure>> {
    try {
      const { data } = await this._apollo.mutate<UpdateProposalLayoutMutation, UpdateProposalLayoutMutationVariables>({
        mutation: UpdateProposalLayoutDocument,
        variables: {
          input: {
            proposalId,
            layoutId: layout,
          },
        },
      });
      return Ok(data.output);
    } catch (error) {
      return Err({ message: "Can't update layout at this time" });
    }
  }

  async updateProposalSlides(
    outputId: Scalars['UUID'],
    slides,
  ): Promise<Result<UpdateProposalSlidesMutation['output'], Failure>> {
    try {
      const { data } = await this._apollo.mutate<UpdateProposalSlidesMutation, UpdateProposalSlidesMutationVariables>({
        mutation: UpdateProposalSlidesDocument,
        variables: {
          input: {
            outputId,
            slides,
          },
        },
      });
      return Ok(data.output);
    } catch (error) {
      return Err({ message: "Can't update slides at this time" });
    }
  }

  async rebuildLibrarySlide({
    slides,
    proposalId,
  }: {
    proposalId: Scalars['UUID'];
    slides;
  }): Promise<Result<GetFilledSlidesQuery['slides'], Failure>> {
    try {
      const cleanedSlides = slides.map(slide => omit(slide, ['slideLibraryId', 'userId', 'agencyId', 'id']));
      const { data } = await this._apollo.query<GetFilledSlidesQuery, GetFilledSlidesQueryVariables>({
        query: GetFilledSlidesDocument,
        variables: {
          input: {
            proposalId,
            slides: cleanedSlides,
          },
        },
      });
      return Ok(data.slides);
    } catch (error) {
      return Err({ message: "Can't get slides with proposal data at this time" });
    }
  }

  async resetOutput(proposalId: Scalars['UUID']): Promise<Result<ResetProposalOutputMutation['output'], Failure>> {
    try {
      const { data } = await this._apollo.mutate<ResetProposalOutputMutation, ResetProposalOutputMutationVariables>({
        mutation: ResetProposalOutputDocument,
        variables: {
          input: {
            proposalId,
          },
        },
      });
      return Ok(data.output);
    } catch (error) {
      return Err({ message: "Can't reset output at this time" });
    }
  }

  async getPackageSlides(
    layout: Scalars['UUID'],
    agencyId: string,
    packageConfigId: string,
  ): Promise<Result<GetPackageSlidesQuery['getPackageSlideTemplate'], Failure>> {
    try {
      const { data, error } = await this._apollo.query<GetPackageSlidesQuery, GetPackageSlidesQueryVariables>({
        query: GetPackageSlidesDocument,
        variables: {
          input: {
            layoutId: layout,
            agencyId,
            packageConfigId,
          },
        },
      });
      if (error) {
        return Err({ message: "Can't get template library at this time" });
      }
      return Ok(data.getPackageSlideTemplate);
    } catch (error) {
      return Err({ message: "Can't get template library at this time" });
    }
  }

  async updatePackageSlides({
    layout,
    slides,
    packageConfigId,
  }: {
    layout: Scalars['UUID'];
    slides: OutputSlide[];
    packageConfigId: string;
  }): Promise<Result<UpdatePackageSlideMutation['updatePackageSlideTemplate'], Failure>> {
    try {
      const cleanedSlides: any[] = slides.map(slide => omit(slide, ['__typename', 'unsavedChanges']));
      const { data } = await this._apollo.mutate<UpdatePackageSlideMutation, UpdatePackageSlideMutationVariables>({
        mutation: UpdatePackageSlideDocument,
        variables: {
          input: {
            layoutId: layout,
            packageConfigId,
            slides: cleanedSlides,
          },
        },
      });
      return Ok(data.updatePackageSlideTemplate);
    } catch (error) {
      return Err({ message: "Can't get layouts at this time" });
    }
  }

  async resetPackageTemplateSlide(
    slideId: string,
  ): Promise<Result<ResetPackageTemplateSlideMutation['resetPackageTemplateSlide'], Failure>> {
    try {
      const { data, errors } = await this._apollo.mutate<
        ResetPackageTemplateSlideMutation,
        ResetPackageTemplateSlideMutationVariables
      >({
        mutation: ResetPackageTemplateSlideDocument,
        variables: {
          id: slideId,
        },
      });
      if (errors) {
        return Err({ message: "Can't reset slide at this time" });
      }
      return Ok(data.resetPackageTemplateSlide);
    } catch (error) {
      return Err({ message: "Can't reset slide at this time" });
    }
  }

  async getAgencyBlankSlides(agencyId: string): Promise<Result<GetAgencyBlankSlidesQuery['getBlankSlides'], Failure>> {
    try {
      const { data, error } = await this._apollo.query<GetAgencyBlankSlidesQuery, GetAgencyBlankSlidesQueryVariables>({
        query: GetAgencyBlankSlidesDocument,
        variables: {
          agencyId,
        },
      });
      if (error) {
        return Err({ message: "Can't get template slides at this time" });
      }
      return Ok(data.getBlankSlides);
    } catch (error) {
      return Err({ message: "Can't get template slides at this time" });
    }
  }

  async createBlankSlide(
    slides: CreateBlankSlideMutationVariables['input']['slides'],
  ): Promise<Result<CreateBlankSlideMutation['createBlankSlide'], Failure>> {
    try {
      const { data, errors } = await this._apollo.mutate<CreateBlankSlideMutation, CreateBlankSlideMutationVariables>({
        mutation: CreateBlankSlideDocument,
        variables: {
          input: {
            slides: [...slides],
          },
        },
      });
      if (errors) {
        return Err({ message: "Can't create the template slide at this time" });
      }
      return Ok(data.createBlankSlide);
    } catch (error) {
      return Err({ message: "Can't create the template slide at this time" });
    }
  }

  async getBlankSlide({
    slideId,
  }: {
    agencyId: string;
    slideId: string;
  }): Promise<Result<GetBlankSlideQuery['getBlank'], Failure>> {
    try {
      const { data, error } = await this._apollo.query<GetBlankSlideQuery, GetBlankSlideQueryVariables>({
        query: GetBlankSlideDocument,
        variables: {
          slideId,
        },
      });
      if (error) {
        return Err({ message: "Can't get the template slide at this time" });
      }
      return Ok(data.getBlank);
    } catch (error) {
      return Err({ message: "Can't get the template slide at this time" });
    }
  }

  async updateBlankSlide(
    slideId,
    slides: UpdateBlankSlideInput['slides'],
  ): Promise<Result<UpdateBlankSlideMutation['updateBlankSlide'], Failure>> {
    try {
      const { data, errors } = await this._apollo.mutate<UpdateBlankSlideMutation, UpdateBlankSlideMutationVariables>({
        mutation: UpdateBlankSlideDocument,
        variables: {
          input: {
            id: slideId,
            slides: [...slides],
          },
        },
      });

      if (errors) {
        return Err({ message: "Can't update the template slide at this time" });
      }
      return Ok(data.updateBlankSlide);
    } catch (error) {
      return Err({ message: "Can't update the template slide at this time" });
    }
  }

  async deleteBlankSlide(slideId: string): Promise<Result<DeleteBlankSlideMutation['deleteBlankSlide'], Failure>> {
    try {
      const { data, errors } = await this._apollo.mutate<DeleteBlankSlideMutation, DeleteBlankSlideMutationVariables>({
        mutation: DeleteBlankSlideDocument,
        variables: {
          slideId,
        },
      });

      if (errors) {
        return Err({ message: "Can't delete the template slide at this time" });
      }

      return Ok(data.deleteBlankSlide);
    } catch (error) {
      return Err({ message: "Can't delete the template slide at this time" });
    }
  }

  async getProposalBlankSlides(
    proposalId: string,
  ): Promise<Result<GetProposalBlankSlidesQuery['getProposalBlankSlides'], Failure>> {
    try {
      const { data, error } = await this._apollo.query<
        GetProposalBlankSlidesQuery,
        GetProposalBlankSlidesQueryVariables
      >({
        query: GetProposalBlankSlidesDocument,
        variables: {
          proposalId,
        },
      });
      if (error) {
        return Err({ message: "Can't get proposal template slides at this time" });
      }
      return Ok(data.getProposalBlankSlides);
    } catch (error) {
      return Err({ message: "Can't get proposal template slides at this time" });
    }
  }

  async createLayout({
    name,
    isDefault,
    basedOnLayout,
    image,
  }: {
    name: string;
    isDefault: boolean;
    basedOnLayout: OutputLayoutTypeGql;
    image: string;
  }): Promise<Result<string, Failure>> {
    try {
      const { data } = await this._apollo.mutate<CreateLayoutMutation, CreateLayoutMutationVariables>({
        mutation: CreateLayoutDocument,
        variables: {
          input: {
            name,
            isDefault,
            basedOnLayout,
            image,
          },
        },
      });

      return Ok(data?.createLayout?.id);
    } catch (error) {
      return Err({ message: "Can't create layout at this time" });
    }
  }

  async setColorScheme(layout, colorScheme): Promise<Result<SetColorSchemeMutation['setLayoutColorScheme'], Failure>> {
    try {
      const { data } = await this._apollo.mutate<SetColorSchemeMutation, SetColorSchemeMutationVariables>({
        mutation: SetColorSchemeDocument,
        variables: {
          layoutId: layout,
          colorScheme,
        },
      });

      return Ok(data.setLayoutColorScheme);
    } catch (error) {
      return Err({ message: "Can't create layout at this time" });
    }
  }

  async deleteProductSlide(slideId: string, productConfigId: string): Promise<Result<boolean, Failure>> {
    try {
      const { data } = await this._apollo.mutate<DeleteProductSlideMutation, DeleteProductSlideMutationVariables>({
        mutation: DeleteProductSlideDocument,
        variables: {
          slideId,
          productOrPackageConfigId: productConfigId,
        },
      });

      const { status, message } = data?.deleteProductSlide || {};

      if (!status) return Err({ message: message || "Can't delete product slide at this time" });

      return Ok(data?.deleteProductSlide?.status);
    } catch (error) {
      return Err({ message: "Can't delete product slide at this time" });
    }
  }

  async updateLibrarySlideName(slideId: string, slideName: string): Promise<Result<boolean, Failure>> {
    try {
      const { data } = await this._apollo.mutate<
        UpdateLibrarySlideNameMutation,
        UpdateLibrarySlideNameMutationVariables
      >({
        mutation: UpdateLibrarySlideNameDocument,
        variables: {
          slideId,
          slideName,
        },
      });

      return Ok(!!data.setSlideName.id);
    } catch (error) {
      return Err({ message: "Can't update library slide name at this time" });
    }
  }
}

/* eslint-disable no-unused-vars */
import { ProposalStatusMonthObj } from '@/shared/types';
import { MutationTree } from 'vuex';

import { ManagementState } from './state';

export enum MutationTypes {
  SET_PROPOSAL_STATUS_CHART_DATA = 'SET_PROPOSAL_STATUS_CHART_DATA',
  SET_USER_AGENCY_ACTIVITY_CHART_DATA = 'SET_USER_AGENCY_ACTIVITY_CHART_DATA',
  SET_PROPOSAL_PRODUCTS_CHART_DATA = 'SET_PROPOSAL_PRODUCTS_CHART_DATA',
}

export const mutations = (): MutationTree<ManagementState> => ({
  [MutationTypes.SET_PROPOSAL_STATUS_CHART_DATA](_state, _chartData: ProposalStatusMonthObj[]): void {
    _state.proposalStatusChartData = _chartData;
  },
  [MutationTypes.SET_USER_AGENCY_ACTIVITY_CHART_DATA](_state, _chartData: ProposalStatusMonthObj[]): void {
    _state.userAgencyActivityChartData = _chartData;
  },
  [MutationTypes.SET_PROPOSAL_PRODUCTS_CHART_DATA](_state, _chartData: ProposalStatusMonthObj[]): void {
    _state.proposalProductsChartData = _chartData;
  },
});

import { AdProductUser } from '@/shared/types';
import { MutationTree } from 'vuex';
import { AuthState } from './state';
export const mutations = (): MutationTree<AuthState> => ({
  SET_USER(_state, _user: AdProductUser | null): void {
    _state.user = _user;
  },
  CHECK_AUTH(_state, _status: boolean): void {
    _state.checkingAuth = _status;
  },
  AUTH_CHECKED(_state, _status: boolean): void {
    _state.authChecked = _status;
  },
  LOADING_USER(_state, _status: boolean): void {
    _state.loadingUser = _status;
  },
  SET_AUTH_PROMISE(_state, _promise): void {
    _state.authPromise = _promise;
  },
  SET_AVAILABLE_AGENCIES(_state, _list): void {
    _state.availableAgencies = _list;
  },
});

import { Failure } from '@/injectables/failure';
import { Err, Ok, Result } from '@sniptt/monads/build';
import { Service } from '../../../service';
import { injectable } from 'inversify';
import { HelpSectionServiceContract, HelpPage, NewHelpPage } from '@/injectables';

interface SinglePageResponse {
  page: HelpPage;
}
interface AllPageResponse {
  page: {
    PageList: HelpPage[];
  };
}

@injectable()
export class HelpSectionService extends Service implements HelpSectionServiceContract {
  async getPages(): Promise<Result<HelpPage[], Failure>> {
    try {
      const url = '/api/help/getPages';
      const { data } = await this._axios.get<AllPageResponse>(url);
      return Ok(data.page.PageList);
    } catch (error) {
      return Err({
        message: "Can't load pages at this time.",
      });
    }
  }

  async updatePage(page: HelpPage): Promise<Result<HelpPage, Failure>> {
    try {
      const url = '/api/help/updatePage';
      const { data } = await this._axios.post<SinglePageResponse>(url, { page });
      return Ok(data.page);
    } catch (error) {
      return Err({
        message: "Can't update page at this time.",
      });
    }
  }

  async createPage(page: NewHelpPage, pageId = 'Root'): Promise<Result<HelpPage, Failure>> {
    try {
      const url = '/api/help/createPage';
      const { data } = await this._axios.post<SinglePageResponse>(
        url,
        { page },
        { params: { pagePropertyId: pageId } },
      );
      return Ok(data.page);
    } catch (error) {
      return Err({
        message: "Can't create page at this time.",
      });
    }
  }

  async deletePage(pageId: string): Promise<Result<HelpPage, Failure>> {
    try {
      const url = '/api/help/deletePage';
      const { data } = await this._axios.delete<SinglePageResponse>(url, { params: { pagePropertyId: pageId } });
      return Ok(data.page);
    } catch (error) {
      return Err({
        message: "Can't delete page at this time.",
      });
    }
  }
}

import { GetterTree } from 'vuex';
import {
  OutputImage,
  OutputShape,
  OutputSlide,
  OutputText,
  PreparedOutputImage,
  PreparedOutputShape,
  PreparedOutputText,
  SlideColors,
  OutputLayout,
} from '@/shared/legacy/classes';
import Vue from 'vue';
import { UnsafeAny } from '@/shared/types';
import { OutputState } from './types';
import { Container } from 'inversify';
import { LoggerContract } from '@/injectables';
import { theme as sbgTheme } from '@/app/themes/sbg';

export const getters = (container: Container): GetterTree<OutputState, UnsafeAny> => {
  const logger = container.get<LoggerContract>('logger');

  return {
    isLocalDev: (): boolean => Vue.config.devtools,
    validateNumber:
      () =>
      ({ value = 0, context = 'no context' }): number => {
        let result = 0;
        if (isNaN(value)) {
          logger.print('info', 'store.output.getters.validateNumber', `Context: ${context} Value: ${value}`);
        } else {
          result = parseFloat(value.toString());
        }
        return result;
      },
    forceArray:
      () =>
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (el: any): any[] => {
        return Array.isArray(el) ? el : [el];
      },
    activeLayout: (state): OutputLayout => {
      const layoutId = state.activeOutput?.layout;
      return state.availableLayouts.find(layout => layout.id === layoutId);
    },
    slideColors(state): SlideColors {
      return (
        state.activeOutput?.slideColors || {
          primary: sbgTheme.primary,
          background: '#FFFFFF',
          text: '#000000',
          secondary: '#ec2d00',
          textAccent: '#f9c2b5',
          mapAccent: '#304FED',
        }
      );
    },
    isEmptySlide:
      () =>
      (slide: OutputSlide): boolean => {
        const { shapes = [], images = [], textItems = [] } = slide;
        return !shapes?.length && !images?.length && !textItems?.length;
      },
    elementInc:
      (state, getters) =>
      (slideId: string): number => {
        const foundSlide = getters.allLocalSlides.find((slide: OutputSlide) => slide._id === slideId);
        if (foundSlide) {
          const { shapes = [], images = [], textItems = [] } = foundSlide || {};
          return [...images, ...textItems, ...shapes].length;
        }
        return 0;
      },
    allLocalSlides(state): OutputSlide[] {
      const localSlides = [...state.outputSlides].sort((a, b) => (a.order || 0) - (b.order || 0));
      return localSlides;
    },
    activeSlide(state): OutputSlide | null {
      return state.outputSlides.find((slide: OutputSlide) => slide._id === state.activeSlideId) || null;
    },
    canUndoAction(state): boolean {
      return !!state.actionHistory.length && state.actionHistoryIndex < state.actionHistory.length - 1;
    },
    canRedoAction(state): boolean {
      return !!state.actionHistory.length && state.actionHistoryIndex > 0;
    },
    slideImages:
      () =>
      (images: OutputImage[]): PreparedOutputImage[] => {
        return images.map((image: OutputImage) => {
          const { index = 1, w = '50%', x = '25%', y = '0%', h = '50%', rotate = 0 } = image.props;
          const style = {
            position: 'absolute',
            'z-index': index,
            width: w,
            left: x,
            top: y,
            height: h,
            transform: `rotate(${rotate}deg)`,
          };
          return { id: image.id, path: image.path, style, props: image.props };
        });
      },
    slideTexts:
      (state, getters) =>
      (
        textItems: OutputText[],
        textColor: string,
        isPreviewSlide: boolean,
        transformHelper: (val: number) => number,
      ): PreparedOutputText[] => {
        // TODO: need refactoring
        return getters.forceArray(textItems).map((item: OutputText) => {
          if (item.htmlValue) {
            const {
              w = '20%',
              h = '20%',
              x = '25%',
              y = '30%',
              line = 1,
              shadow = false,
              align = 'left',
              size = 2,
              index = 3,
              bold = 'normal',
              italic = false,
              strike = false,
              underline = false,
              fontFamily = 'DIN2014-Regular',
              rotate = 0,
              // link = '',
            } = item.props;
            let decoration = 'none';
            if (strike && underline) decoration = 'line-through underline';
            else if (strike) decoration = 'line-through';
            else if (underline) decoration = 'underline';
            const styleString = `position: absolute; line-height: ${line}; text-align: ${align}; text-shadow: ${
              shadow ? '2px 2px 3px #000000' : 'initial'
            };font-weight: ${bold}; font-size: ${transformHelper(size)}vw; font-style: ${
              italic ? 'italic' : 'normal'
            }; font-family: ${fontFamily}; text-decoration: ${decoration}; z-index: ${index}; width: ${w}; left: ${x}; top: ${y}; height: ${h}; transform: rotate(${rotate}deg)`;
            return {
              ...item,
              style: styleString,
            };
          } else {
            const {
              w = '20%',
              h = '20%',
              x = '25%',
              y = '30%',
              line = 1,
              shadow = false,
              align = 'left',
              size = 2,
              index = 3,
              bold = 'normal',
              color = textColor,
              italic = false,
              strike = false,
              underline = false,
              fontFamily = 'DIN2014-Regular',
              rotate = 0,
              link = '',
            } = item.props;
            let decoration = 'none';
            if (strike && underline) decoration = 'line-through underline';
            else if (strike) decoration = 'line-through';
            else if (underline) decoration = 'underline';
            const styleString = `position: absolute; line-height: ${line}; text-align: ${align}; text-shadow: ${
              shadow ? '2px 2px 3px #000000' : 'initial'
            };font-weight: ${bold}; font-size: ${transformHelper(size)}vw; color: ${color}; font-style: ${
              italic ? 'italic' : 'normal'
            }; font-family: ${fontFamily}; text-decoration: ${decoration}; z-index: ${index}; width: ${w}; left: ${x}; top: ${y}; height: ${h}; transform: rotate(${rotate}deg)`;
            return {
              id: `${item.id}${isPreviewSlide ? '_p' : ''}`,
              style: styleString,
              value: item.value,
              ...(link ? { link } : {}),
            };
          }
        });
      },
    slideShapes:
      (state, getters) =>
      (shapes: OutputShape[], slideHeight: number): PreparedOutputShape[] => {
        const borderSize = (
          borderWidth: number,
          returnPixels = true,
          comparatorPercent = '100%',
          isHorizontal = false,
        ): number => {
          const borderPixelValue = (slideHeight / 800) * borderWidth;

          if (returnPixels) return borderPixelValue;

          const decimalPercent = Number(comparatorPercent.slice(0, -1)) / 100;
          const multiplier = isHorizontal ? 16 / 9.49 : 1;

          const slideElementPixelValue = slideHeight * decimalPercent * multiplier;

          return (100 * borderPixelValue) / slideElementPixelValue;
        };

        const nudgePercent = (percent: number, useAddition = false): number => {
          return useAddition ? percent : 100 - percent;
        };

        return getters.forceArray(shapes).map((shape: OutputShape) => {
          const {
            w = '20%',
            h = '20%',
            x = '25%',
            y = '30%',
            index = 3,
            fill = null,
            border = null,
            circle = false,
            triangle = false,
            rotate = 0,
          } = shape.props;

          const isBorderedPolygon = triangle && border; // use with all (future) complex polygons

          const style = {
            position: 'absolute',
            'z-index': index,
            width: w,
            left: x,
            top: y,
            height: h,
            opacity: fill?.alpha || 1,
            background: isBorderedPolygon ? border.color : fill?.color || 'transparent',
            transform: `rotate(${rotate}deg)`,
            ...(circle && { 'border-radius': '50%' }),
            ...(triangle && {
              '--path': '50% 0,100% 100%,0 100%',
              'clip-path': 'polygon(var(--path))',
            }),
            ...(!isBorderedPolygon &&
              border && { border: `${borderSize(border.width)}px ${border.style} ${border.color}` }),
          };

          const nestedElementStyle = !isBorderedPolygon
            ? null
            : {
                ...style,
                background: fill?.color || 'transparent',
                transform: 'none',
                height: `${nudgePercent(borderSize(border.width, false, h) * 6)}%`,
                width: `${nudgePercent(borderSize(border.width, false, w, true) * 6)}%`,
                left: `${borderSize(border.width, false, w, true) * 3}%`,
                top: `${borderSize(border.width, false, h) * 4}%`,
              };

          return { id: shape.id, style, ...(nestedElementStyle && { nestedElementStyle }) };
        });
      },
  };
};

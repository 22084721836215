import { MutationTree } from 'vuex';
import { TargetingSegmentsState } from './state';

export const mutations = (): MutationTree<TargetingSegmentsState> => ({
  SET_MAIN_SEGMENTS(state, payload) {
    state.mainSegments = payload;
  },
  SET_DEMOGRAPHICS_SEGMENTS(state, payload) {
    state.demographicsSegments = payload;
  },
});

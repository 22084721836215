import * as Types from '../../../../../app/graphql/_types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { TargetingSegmentFragmentDoc } from '../../../../../app/graphql/fragments/campaign.fragment.generated';
export type GetTargetingSegmentsQueryVariables = Types.Exact<{
  query?: Types.InputMaybe<Types.Scalars['String']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
  sortDirection?: Types.InputMaybe<Types.SortDirection>;
  isMain?: Types.InputMaybe<Types.Scalars['Boolean']>;
  segmentCategories?: Types.InputMaybe<
    Array<Types.InputMaybe<Types.SegmentCategory>> | Types.InputMaybe<Types.SegmentCategory>
  >;
}>;

export type GetTargetingSegmentsQuery = {
  getAudienceSegments?: {
    total: number;
    items?: Array<{
      id: string;
      name: string;
      externalId: string;
      description?: string | null;
      segmentCategory: Types.SegmentCategory;
      segmentSubCategory?: Types.SegmentSubCategory | null;
      isMain: boolean;
    } | null> | null;
  } | null;
};

export const GetTargetingSegmentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getTargetingSegments' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          defaultValue: { kind: 'IntValue', value: '0' },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortDirection' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'SortDirection' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'isMain' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'segmentCategories' } },
          type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'SegmentCategory' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getAudienceSegments' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'searchKey' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'isMain' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'isMain' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'segmentCategories' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'segmentCategories' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortDirection' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortDirection' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'TargetingSegment' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
        ],
      },
    },
    ...TargetingSegmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetTargetingSegmentsQuery, GetTargetingSegmentsQueryVariables>;

import 'reflect-metadata';
import { inject, injectable } from 'inversify';

import { C360ModelContract, C360ProductLink, LocalStorageServiceContract } from '@/injectables';
import { C360Links, useLink } from '@c360/ui';
import { Services } from '@/injectables/tokens';
import { Environment } from '@/injectables/infrastructure/env/contracts';
@injectable()
export class C360Model implements C360ModelContract {
  _links = C360Links;
  constructor(@inject(Services.LocalStorage) private readonly _storage: LocalStorageServiceContract) {}

  getProductsLinks(products: string[]): C360ProductLink[] {
    const productLabelMapper = {
      'Admin Hierarchy': 'admin hierarchy',
      Dashboard: 'analytics',
      'Media Planner': 'media planner',
      'Help Center': 'help center',
      'compulse oms': 'compulse oms',
    };
    return products
      .map(el => productLabelMapper[el])
      .filter(Boolean)
      .map((product: string) => ({
        name: product,
      }));
  }

  getC360Link(env: Environment): string {
    const link = useLink({ product: 'compulse360', env });
    return link;
  }

  addRefreshToken(link: string, providedToken?: string) {
    const token = providedToken || this._storage.getItem('mp_c360_refresh');
    if (!token) return link;
    return `${link}/ahlogin/?token=${token}`;
  }

  addRefreshTokenToReturnLink(link: string) {
    const token = this._storage.getItem('mp_c360_refresh');
    if (!token) return link;
    return `${link}/?token=${token}`;
  }

  getC360LoginLink(env: Environment): string {
    const link = useLink({ product: 'compulse360', env });
    return `${link}/login`;
  }

  getSinclairSingleSignOnLink(env: Environment): string {
    return useLink({ product: 'mediaplanner', agency: 'sinclair', env });
  }
}

import * as Types from '../../../../../app/graphql/_types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type ActiveProductCategoriesQueryVariables = Types.Exact<{ [key: string]: never }>;

export type ActiveProductCategoriesQuery = {
  activeProductCategoriesComponent: Array<{ count: number; budget: number; category: string }>;
};

export const ActiveProductCategoriesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'activeProductCategories' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activeProductCategoriesComponent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                { kind: 'Field', name: { kind: 'Name', value: 'budget' } },
                { kind: 'Field', name: { kind: 'Name', value: 'category' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ActiveProductCategoriesQuery, ActiveProductCategoriesQueryVariables>;


import Vue from 'vue';

let snackbarMutations: () => void;

export default Vue.extend({
  name: 'Snackbar',

  data: (): {
    show: boolean;
    message: string;
    color: string;
    timeout: number;
    withLoader: boolean;
  } => ({
    color: '',
    show: false,
    message: 'A wild snackbar appears!',
    timeout: 3500,
    withLoader: false,
  }),

  created(): void {
    snackbarMutations = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'SHOW_SNACKBAR') {
        this.message = state.snackbarContent;
        this.color = state.snackbarColor;
        this.timeout = state.snackbarTimeout;
        this.withLoader = state.snackbarWithLoader;
        this.show = true;
      }
      if (mutation.type === 'HIDE_SNACKBAR') {
        this.show = false;
      }
    });
  },

  beforeDestroy(): void {
    snackbarMutations();
  },
});

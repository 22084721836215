import { Err, Ok, Result } from '@sniptt/monads';
import { Failure } from '@/injectables/failure';

import {
  GetOutputScreenshotUrlResponse,
  GetShareOutputLinkResponse,
  OutputImage,
  OutputSlide,
  OutputText,
} from '@/shared/legacy/classes';

import { Service } from '../../../service';
import { injectable } from 'inversify';
import { Status } from '@/injectables';
import { SlideVisibility } from '@/app/graphql';

@injectable()
export class OutputService extends Service {
  async prepareSlideForLibrary({ slide }: { slide: OutputSlide }): Promise<Omit<OutputSlide, 'id'>> {
    const {
      PropertyId,
      defaultSlide = false,
      textItems = [],
      images = [],
      shapes = [],
      colors = undefined,
      name,
      map = null,
      chart = null,
      custom = false,
      isProduct = false,
      slideVariable = '',
      linkedProduct = '',
      visibility = SlideVisibility.Visible,
      // templateVariation ? to be handled after building slides from defaultSlideLibrary
    } = slide;

    const setTextValueFromDynamic = (arr: OutputText[]): OutputText[] => {
      const updatedTextItems: OutputText[] = [];

      arr.forEach(t => {
        if (t?.dynamicLink) {
          const updatedTextItem = {
            ...t,
            ...(t.dynamicLink?.defaultTextValue && { value: t.dynamicLink?.defaultTextValue }),
          };

          updatedTextItems.push(updatedTextItem);
        } else {
          updatedTextItems.push(t);
        }
      });

      return updatedTextItems;
    };

    const setImageValueFromDynamic = (arr: OutputImage[]): OutputImage[] => {
      const updatedImages: OutputImage[] = [];
      arr.forEach(img => {
        if (img?.dynamicLink) {
          const updatedImg = {
            ...img,
            ...(img.dynamicLink?.defaultPath && { path: img.dynamicLink?.defaultPath }),
          };
          updatedImages.push(updatedImg);
        } else {
          updatedImages.push(img);
        }
      });
      return updatedImages;
    };

    const preparedName = (slideName: string): string => {
      let updatedSlideName = slideName;

      if (['lib_', 'dup_'].some(prefix => slideName.includes(prefix))) {
        const slideNamePieces = updatedSlideName.split('_');

        updatedSlideName = `${slideNamePieces?.[1] || updatedSlideName}_${
          slideNamePieces?.[2] || Date.now().toString()
        }`;
      }

      return updatedSlideName;
    };

    const preparedSlide = {
      name: preparedName(name || ''),
      colors,
      textItems: setTextValueFromDynamic(textItems),
      images: setImageValueFromDynamic(images),
      shapes,
      visibility,
      ...(PropertyId && { PropertyId }),
      ...(slideVariable && { slideVariable }),
      ...(defaultSlide && { defaultSlide }),
      ...(custom && { custom }),
      ...(isProduct && { isProduct }),
      ...(linkedProduct && { linkedProduct }),
      ...(map && { map }),
      ...(chart && { chart }),
    };

    return preparedSlide;
  }

  async getOutputScreenshotUrl(payload: {
    clientId: string;
    proposalId: string;
    env: string;
    width: string | number;
  }): Promise<Result<GetOutputScreenshotUrlResponse, Failure>> {
    try {
      const params = {
        clientId: payload.clientId,
        proposalId: payload.proposalId,
        env: payload.env,
        width: payload.width,
      };

      const { data } = await this._axios.get('/api/proposals/getOutputScreenshotUrl', { params });

      return Ok(data);
    } catch (error) {
      return Err({
        message: `Unable to fetch output screenshot url`,
      });
    }
  }

  async getShareOutputLink(payload: {
    clientId: string;
    proposalId: string;
  }): Promise<Result<GetShareOutputLinkResponse, Failure>> {
    try {
      const params = {
        clientId: payload.clientId,
        proposalId: payload.proposalId,
      };

      const { data } = await this._axios.get('/api/proposals/getShareOutputLink', { params });

      return Ok(data);
    } catch (error) {
      return Err({
        message: `Unable to fetch shared output link`,
      });
    }
  }

  defaultOutputFontFamily(agencyId: string): string {
    if (agencyId.toLowerCase().includes('today')) {
      return 'Work Sans';
    }
    return 'DIN2014-Regular';
  }

  proposalOutputIsChangeDisabled({ proposalStatus }: { proposalStatus: Status }): boolean {
    return [Status.ClosedLost, Status.Sold].includes(proposalStatus);
  }

  outputElementPositionIncrement(): number {
    return 0.5;
  }
}


import Vue, { PropType } from 'vue';

import { ToolbarItems } from '@/injectables';

export default Vue.extend({
  name: 'IconSelector',

  props: {
    /**
     * Typical selector interface.
     * Value is an index of selected element or array of indexes
     */
    value: {
      type: [Number, Array] as PropType<number | Array<number>>,
    },
    /**
     * Items. With info about icons, tooltips, shortcuts and value
     */
    items: {
      type: Array as PropType<Array<ToolbarItems>>,
      required: true,
    },
    /**
     * Mode when could be selected some elements. Value is array of indexes
     */
    multiSelection: {
      type: Boolean,
      default: false,
    },
    /**
     * Text places top of the buttons
     */
    label: {
      type: String,
      default: '',
    },
    /**
     * Set mandatory to button groups component. But actually we control it with value
     */
    mandatory: {
      type: Boolean,
      default: false,
    },
    /**
     * Mode when selected item emit event with 'action-' + value
     */
    returnClickedEvents: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    selected: {
      get(): number | number[] {
        return this.value;
      },
      set(newValue: number | number[]) {
        this.$emit('input', newValue);
      },
    },
  },
  methods: {
    emitSelection(item: string) {
      if (!this.returnClickedEvents) return;
      this.$emit('active-' + item);
    },
  },
});

import { Err, Ok, Result } from '@sniptt/monads/build';
import { injectable } from 'inversify';
import { LogoWithSize } from '@/shared/types';

import { Failure } from '@/injectables/failure';
import { ImageObject, UploadServiceContract } from '@/injectables';
import {
  GetUploadUrlDocument,
  GetUploadUrlMutation,
  GetUploadUrlMutationVariables,
} from '../graphql/get-upload-url.generated';
import { Service } from '@/injectables/service';

@injectable()
export class S3UploadService extends Service implements UploadServiceContract {
  async uploadImage(imageFile: File): Promise<Result<ImageObject, Failure>> {
    try {
      const { isErr, unwrap } = await this.uploadFile(imageFile);
      if (isErr()) {
        throw new Error();
      }
      const link = unwrap();

      // works slowly with it
      const { isErr: isInfoErr, unwrap: unwrapInfo } = await this.getImageInfo(link);

      if (isInfoErr()) {
        throw new Error();
      }
      const { width, height } = unwrapInfo();

      const imgObject: ImageObject = {
        img: unwrap(),
        imgWidth: width,
        imgHeight: height,
      };
      return Ok(imgObject);
    } catch (error) {
      return Err({
        message: `Error while loading image: ${imageFile.name}`,
      });
    }
  }

  async uploadLogo(logo: File): Promise<Result<LogoWithSize, Failure>> {
    try {
      const { isErr, unwrap } = await this.uploadFile(logo);
      if (isErr()) {
        throw new Error();
      }
      const link = unwrap();

      // works slowly with it
      const { isErr: isInfoErr, unwrap: unwrapInfo } = await this.getImageInfo(link);

      if (isInfoErr()) {
        throw new Error();
      }

      const { width, height } = unwrapInfo();

      const logoObject: LogoWithSize = {
        logo: link,
        avtr: link,
        logoWidth: width,
        logoHeight: height,
      };
      return Ok(logoObject);
    } catch (error) {
      return Err({
        message: `Error while loading image: ${logo.name}`,
      });
    }
  }

  async getUploadLink(
    fileName: string,
    isPrivate: boolean,
  ): Promise<Result<GetUploadUrlMutation['createUploadUrl'], Failure>> {
    try {
      const { data } = await this._apollo.mutate<GetUploadUrlMutation, GetUploadUrlMutationVariables>({
        mutation: GetUploadUrlDocument,
        variables: { fileName, isPrivate },
      });

      return Ok(data.createUploadUrl);
    } catch (error) {
      return Err({ message: "Can't get upload link at this time" });
    }
  }

  async uploadFile(file: File, isPrivateFile = false): Promise<Result<string, Failure>> {
    try {
      const _formData = new FormData();
      const { isErr, unwrap } = await this.getUploadLink(file.name, isPrivateFile);

      if (isErr()) {
        return Err({
          message: `Unexpected response from the server while uploading image: ${file.name}`,
        });
      }
      const uploadDetails = unwrap();

      Object.entries(JSON.parse(uploadDetails.fields)).forEach(([key, value]: [string, string]) => {
        _formData.append(key, value);
      });
      _formData.append('file', file);

      await this._axios.post(uploadDetails.url, _formData);

      return Ok(uploadDetails.clientUrl);
    } catch (error) {
      return Err({
        message: `Error while uploading file: ${file.name}`,
      });
    }
  }

  async getImageInfo(image: string): Promise<Result<{ width: number; height: number }, Failure>> {
    const img = new Image();
    img.src = image;
    return new Promise(resolve => {
      img.onerror = () => {
        resolve(Err({ message: 'Link is not image' }));
      };
      img.onload = () => {
        const { width, height } = img;
        resolve(Ok({ width, height }));
      };
    });
  }

  async uploadXML(file: File): Promise<Result<string, Failure>> {
    return this.uploadFile(file, true);
  }
}

import { injectable } from 'inversify';
import { SegmentCategory, SegmentSubCategory } from '@/app/graphql';
import { TargetingSegmentsModelContract } from '../contracts';
import { SegmentItem } from '@/injectables/services';

@injectable()
export class TargetingSegmentsModel implements TargetingSegmentsModelContract {
  getReadableCategory(category: SegmentCategory | 'Search all segments'): string {
    const mapper: Record<SegmentCategory | 'Search all segments', string> = {
      [SegmentCategory.Demographic]: 'Demographic',
      [SegmentCategory.Enthusiast]: 'Enthusiast',
      [SegmentCategory.Occupation]: 'Occupation',
      [SegmentCategory.SearchHistory]: 'Search History',
      [SegmentCategory.Shopping]: 'Shopping',
      [SegmentCategory.Services]: 'Services',
      [SegmentCategory.AudienceProfiles]: 'Audience Profiles',
      [SegmentCategory.AdvancedDemographic]: 'Advanced Demographic',
      [SegmentCategory.Other]: 'Other',
      'Search all segments': 'Search all segments',
    };
    return mapper[category];
  }
  getReadableSubCategory(category: SegmentSubCategory): string {
    if (category === SegmentSubCategory.Income) return 'Household Income';

    return category.replaceAll('_', ' ').toLowerCase();
  }

  getGroupSegments(audienceSegments: SegmentItem[]): { audience: SegmentItem[]; demographics: SegmentItem[] } {
    if (!audienceSegments || !Array.isArray(audienceSegments)) return { audience: [], demographics: [] };

    return audienceSegments.reduce(
      (acc, el) => {
        if (el.segmentCategory === SegmentCategory.Demographic) {
          return { ...acc, demographics: [...acc.demographics, el] };
        } else {
          return { ...acc, audience: [...acc.audience, el] };
        }
      },
      { audience: [], demographics: [] },
    );
  }
}


import Vue from 'vue';
import WrapperWithTooltip from '@/components/WrapperWithTooltip.vue';
import ImageUploader from '@/components/Basic/Uploader/ImageUploader.vue';
import { Services } from '@/injectables/tokens';
import { UploadServiceContract } from '@/injectables';

import ImageActions from './ImageActions.vue';

export default Vue.extend({
  name: 'CreativePreview',

  inject: ['showSnackbar'],

  useInjectable: [Services.Upload],

  components: {
    WrapperWithTooltip,
    ImageUploader,
    ImageActions,
  },

  props: {
    link: {
      type: String,
    },
    maxImageHight: {
      type: String,
      default: '320px',
    },
    isChangeDisabled: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    hideLinkField: {
      type: Boolean,
      default: false,
    },
  },

  data: (): {
    imagePreviewDialog: boolean;
    checkImageLoading: boolean;
    addUrlDialog: boolean;
    uploadingImage: boolean;
    linkIsImage: boolean;
  } => ({
    uploadingImage: false,
    addUrlDialog: false,
    checkImageLoading: false,
    imagePreviewDialog: false,
    linkIsImage: false,
  }),
  async mounted() {
    this.linkIsImage = await this.isImgUrl(this.creativeLink);
  },
  async updated() {
    this.linkIsImage = await this.isImgUrl(this.creativeLink);
  },
  methods: {
    isImgUrl(url) {
      if (!url) return false;
      const img = new Image();
      img.src = url;
      return new Promise(resolve => {
        img.onerror = () => resolve(false);
        img.onload = () => resolve(true);
      });
    },
    async onImagePicked(file): Promise<void> {
      this.uploadingImage = true;
      const { isErr, unwrap, unwrapErr } = await (this[Services.Upload] as UploadServiceContract).uploadImage(file);
      this.uploadingImage = false;

      if (isErr()) {
        this.showSnackbar(unwrapErr().message, 'error');
        return;
      }
      this.$emit('update-creative-link', unwrap().img);
    },
  },

  computed: {
    creativeLink: {
      get(): string {
        return this.link;
      },
      set(link: string): void {
        this.$emit('update-creative-link', link);
      },
    },
  },
});

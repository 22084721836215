import { Module } from 'vuex';
import { EnumsState, state } from './state';
import { mutations } from './mutations';
import { RootState } from '../root/state';

export const enumsModule = (): Module<EnumsState, RootState> => {
  return {
    namespaced: true,
    state: state(),
    mutations: mutations(),
  };
};

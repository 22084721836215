
import Vue from 'vue';

export default Vue.extend({
  name: 'MenuItem',

  props: {
    /**
     * Size of menu item.
     */
    size: {
      type: String,
      default: 'normal',
    },

    /**
     * Icon do display.
     */
    icon: {
      type: String,
      required: true,
    },

    /**
     * Menu item name.
     */
    name: {
      type: String,
      required: true,
    },

    /**
     * Is menu item should be displayed but disabled to interact.
     */
    disabled: {
      type: Boolean,
      default: false,
    },

    /**
     * Used when button has router related features.
     */
    exact: {
      type: Boolean,
      default: false,
    },

    /**
     * Route name for redirect.
     */
    to: {
      type: String,
      required: true,
    },

    /**
     * Color for button and icon. May be static or dynamically evaluated.
     */
    color: {
      type: String,
      default: 'primary',
    },
  },
});

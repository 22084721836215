import * as Types from '../../../../../app/graphql/_types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type RecalculateProductsBudgetQueryVariables = Types.Exact<{
  input: Types.BudgetDistributionInput;
}>;

export type RecalculateProductsBudgetQuery = {
  distributeBudget: {
    updatedBudget: number;
    products: Array<{
      id?: string | null;
      isLocked: boolean;
      budget: number;
      flights?: Array<{ id?: string | null; isLocked: boolean; budget: number } | null> | null;
    } | null>;
  };
};

export const RecalculateProductsBudgetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'recalculateProductsBudget' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'BudgetDistributionInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'distributeBudget' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'updatedBudget' },
                  name: { kind: 'Name', value: 'totalBudget' },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'products' },
                  name: { kind: 'Name', value: 'solutions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'budget' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'flights' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'budget' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RecalculateProductsBudgetQuery, RecalculateProductsBudgetQueryVariables>;

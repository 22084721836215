import 'reflect-metadata';
import { injectable } from 'inversify';

import {
  AgencyActivityDocument,
  AgencyActivityQuery,
  AgencyActivityQueryVariables,
} from '../graphql/query/get-agency-activity-chart.generated';
import {
  FetchProposalStatusChartDocument,
  FetchProposalStatusChartQuery,
  FetchProposalStatusChartQueryVariables,
} from '../graphql/query/get-proposal-status-chart.generated';

import { Failure } from '@/injectables/failure';
import { Err, Ok, Result } from '@sniptt/monads/build';

import { ProposalStatusChartData } from '@/shared/types';
import { filterZeroResults, proposalProductsChartDataMapped, statusChartDataMapped } from '../utils';
import { ManagementService as RestManagementService } from './http';
import {
  FetchProposalProductsChartDocument,
  FetchProposalProductsChartQuery,
  FetchProposalProductsChartQueryVariables,
} from '../graphql/query/get-proposal-products-count.generated';

@injectable()
export class ManagementService extends RestManagementService {
  async fetchProposalStatusChartData(start: string, end: string): Promise<Result<ProposalStatusChartData, Failure>> {
    try {
      const { data, error } = await this._apollo.query<
        FetchProposalStatusChartQuery,
        FetchProposalStatusChartQueryVariables
      >({
        query: FetchProposalStatusChartDocument,
        variables: { start, end },
      });

      if (error) {
        return Err({
          message: "Can't proposal status chart at this time",
        });
      }
      return Ok({ MonthList: statusChartDataMapped(data.MonthList) || [] });
    } catch (error) {
      return Err({
        message: `Can't proposal status chart at this time: ${error}`,
      });
    }
  }

  async fetchUserAgencyActivityChartData(
    start: string,
    end: string,
    _agencyId?: string,
    isMonthly?: boolean,
  ): Promise<Result<ProposalStatusChartData, Failure>> {
    try {
      const { data, error } = await this._apollo.query<AgencyActivityQuery, AgencyActivityQueryVariables>({
        query: AgencyActivityDocument,
        variables: { start, end, isMonthly },
      });

      if (error) {
        return Err({
          message: "Can't activity chart at this time.",
        });
      }
      return Ok({
        DateList: filterZeroResults(data.usageTrackerComponent?.DateList || []) || [],
        MonthList: filterZeroResults(data.usageTrackerComponent?.MonthList || []) || [],
      });
    } catch (error) {
      return Err({
        message: `Can't activity chart at this time: ${error}`,
      });
    }
  }

  async fetchProposalProductsChartData(start: string, end: string): Promise<Result<ProposalStatusChartData, Failure>> {
    try {
      const { data, error } = await this._apollo.query<
        FetchProposalProductsChartQuery,
        FetchProposalProductsChartQueryVariables
      >({
        query: FetchProposalProductsChartDocument,
        variables: { start, end },
      });

      if (error) {
        return Err({
          message: "Can't get proposal product chart at this time",
        });
      }

      return Ok({ MonthList: proposalProductsChartDataMapped(data.MonthList) || [] });
    } catch (error) {
      return Err({
        message: `Can't proposal products chart at this time: ${error}`,
      });
    }
  }
}

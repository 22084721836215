
import Vue from 'vue';
import SlideBuilder from './SlideBuilder.vue';
import HiddenSlide from './HiddenSlide.vue';
import OverviewMap from './OverviewMap.vue';
import GeoFenceMap from './GeoFenceMap.vue';
import Donut from './Charts/Donut.vue';
import { PreparedOutputImage } from '@/shared/legacy/classes';
import { Models } from '@/injectables/tokens';
import { OutputModelContract } from '@/injectables';

interface Position {
  left: string;
  width: string;
  height: string;
  top: string;
}

export default Vue.extend({
  name: 'Slide',

  useInjectable: [Models.Output],

  components: { Donut, OverviewMap, GeoFenceMap, SlideBuilder, HiddenSlide },

  props: {
    allowSlideOverflow: {
      type: Boolean,
      default: false,
    },
    isChangeDisabled: {
      type: Boolean,
      default: false,
    },
    height: {
      type: [Number, String],
      default: 0,
    },
    isManageLayouts: {
      type: Boolean,
      default: false,
    },
    colors: {
      type: Object,
      default: () => ({ background: '#000000', text: '#FFFFFF' }),
    },
    images: {
      type: [Array, Object],
      default: () => [],
    },
    isHidden: {
      type: Boolean,
      default: false,
    },
    shapes: {
      type: [Array, Object],
      default: () => [],
    },
    _id: {
      type: String,
      default: '',
    },
    textItems: {
      type: [Array, Object],
      default: () => [],
    },
    charts: {
      type: Array,
      default: () => [],
    },
    map: {
      type: Object,
      default: () => ({}),
    },
    exporting: {
      type: Boolean,
      default: () => false,
    },
    mapOnly: {
      type: Boolean,
      default: () => false,
    },
    sidebar: {
      type: Boolean,
      default: false,
    },
    isShowColors: {
      type: Boolean,
      default: false,
    },
    isPreviewSlide: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      required: true,
    },
    width: {
      type: Number,
      default: 0,
    },
    observer: {
      type: window.IntersectionObserver,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    colorScheme: {
      type: Object,
      default: () => ({}),
    },
  },

  mounted(): void {
    if (this.observer) {
      this.observer.observe(this.$el);
    }
  },

  computed: {
    colorStyles() {
      return (this[Models.Output] as OutputModelContract).getColorStyles(this.colorScheme);
    },
    hideLegend(): boolean {
      return !!this.map?.mapData?.hideLegend;
    },
    slideMapStyle(): Position {
      const { w = '37.5%', h = '100%', y = '0%', x = '62.5%' } = this.map;
      return {
        left: x,
        width: w,
        height: h,
        top: y,
      };
    },
    isCurrentSlide(): boolean {
      const activeSlideId = this.$store.state.output.activeSlideId;
      return activeSlideId === this._id;
    },
    slideWidth(): number {
      const { width, height } = this.$vuetify.breakpoint;
      const yOffset = 130;
      const xOffset = 45;
      const sidebarOffset = 390;
      const maxWidth = ((height - yOffset) * 16) / 9 + xOffset;
      let mainWindowWidth = this.sidebar ? width - sidebarOffset : width;
      mainWindowWidth = this.isShowColors ? mainWindowWidth - 300 : mainWindowWidth;
      mainWindowWidth -= this.sidebar || this.isShowColors ? xOffset : 0;

      if (this.isPreviewSlide && this.width) return this.width;
      if (this.allowSlideOverflow) return mainWindowWidth;
      return Math.min(mainWindowWidth, maxWidth);
    },
    slideHeight(): number {
      const offset = this.sideBar ? 400 : 0;

      if (this.sidebar || this.isPreviewSlide) return ((this.slideWidth - offset) * 9) / 16;

      const propHeight = this.$store.getters['output/validateNumber']({
        value: this.height,
        context: 'Slide/slideHeight',
      });

      return propHeight || (this.slideWidth * 9) / 16;
    },
    slideTexts(): { style: string; value: string; htmlValue?: string; link?: string; id: string }[] {
      return this.$store.getters['output/slideTexts'](
        this.textItems,
        this.colors?.text,
        this.isPreviewSlide,
        this.transformHelper,
      );
    },
    slideShapes(): { id: string; style: string }[] {
      return this.$store.getters['output/slideShapes'](this.shapes, this.slideHeight);
    },
    slideImages(): PreparedOutputImage[] {
      return this.$store.getters['output/slideImages'](this.images);
    },
  },

  methods: {
    mapHeight({ height, percent }: { height?: number; percent?: string }): number {
      let value = height || this.slideHeight;
      if (percent) {
        const parsed = parseFloat(percent.replace('%', ''));
        value = (value * parsed) / 100;
      }
      return value;
    },
    transformHelper(val: number): number {
      if (!this.isPreviewSlide && !this.sidebar) return val;
      const widthPercent = this.slideWidth / window.innerWidth;
      return val * widthPercent;
    },
    componentRendered(mapId, event) {
      if (mapId !== this.map.mapData.mapId) {
        // eslint-disable-next-line no-console
        console.log('warning, unexpected map type render event:', mapId, event);
        return;
      }
      this.$emit('rendered', { empty: false });
    },
  },
});

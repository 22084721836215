import { Models } from '@/injectables/tokens';
import { ProductConfig, CreativeConfig, FormConfig } from '@/shared/types';
import { inject, injectable } from 'inversify';
import 'reflect-metadata';
import { BaseProductModelContract } from '../../base-product/contracts';
import { CreativeModelContract } from '@/injectables';
import { ProductConfigCategory } from '@/app/graphql';
export enum CreativeType {
  Carousel = 'carousel',
  Basic = 'basic',
}
@injectable()
export class CreativeModel implements CreativeModelContract {
  constructor(@inject(Models.BaseProduct) private readonly _baseProduct: BaseProductModelContract) {}

  creativeConfigured(productConfig: ProductConfig, creative): boolean {
    if (productConfig.category === ProductConfigCategory.Social) {
      return (
        (!!creative?.postText &&
          !!creative?.headline &&
          !!creative?.description &&
          !!creative?.socialLink &&
          !!creative?.url) ||
        this.getCreativeType(creative) === CreativeType.Carousel
      );
    }

    return true;
  }

  getCreativeConfig(productConfig: ProductConfig): Omit<CreativeConfig, 'index'> {
    const { id, name, category } = productConfig;
    const FormConfig = this._getFormConfig(productConfig);
    const creativesLimit = FormConfig.length ? 12 : 0;
    return {
      id,
      name,
      category,
      creativesLimit,
      FormConfig,
    };
  }

  private _getFormConfig(productConfig): FormConfig[] {
    const skipType = [
      this._baseProduct.isXMLProduct,
      this._baseProduct.fixedRateConfig,
      this._baseProduct.isEmailMarketingProduct,
    ];

    if (skipType.some(clbck => clbck(productConfig))) return [];

    if (this._baseProduct.isDigitalAudioProduct(productConfig)) {
      return [this._creativeFormElementsProvider.notes(), this._creativeFormElementsProvider.vastTag()];
    }

    // Meta
    if (this._baseProduct.hasPlatform(productConfig?.flightConfigs || [])) {
      return [
        this._creativeFormElementsProvider.socialLink({ required: true }),
        this._creativeFormElementsProvider.creativeLink({ required: true }),
        this._creativeFormElementsProvider.postText({ required: true }),
        this._creativeFormElementsProvider.headline({ required: true }),
        this._creativeFormElementsProvider.description({ required: true }),
        this._creativeFormElementsProvider.landingPage({ required: true }),
      ];
    }

    // questionnaire
    if (this._baseProduct.canHaveQuestionnaire(productConfig)) {
      return [this._creativeFormElementsProvider.questionnaire()];
    }
    // youtube
    if (this._baseProduct.isYoutubeProduct(productConfig)) {
      return [
        this._creativeFormElementsProvider.creativeLink({ required: false }),
        this._creativeFormElementsProvider.notes(),
        this._creativeFormElementsProvider.youtubeURL(),
      ];
    }

    // package
    if (productConfig.category === ProductConfigCategory.Package) {
      return [];
    }

    return [
      this._creativeFormElementsProvider.landingPage({ required: false }),
      this._creativeFormElementsProvider.creativeLink({ required: false }),
      this._creativeFormElementsProvider.notes(),
      this._creativeFormElementsProvider.omsNumber(),
    ];
  }

  private _creativeFormElementsProvider = {
    notes: () => ({
      DataType: 'string',
      label: 'Notes',
      PropertyId: 'notes',
      Required: true,
    }),
    customMargin: () => ({
      DataType: 'float',
      label: 'Custom Margin %',
      PropertyId: 'customMargin',
      Required: true,
    }),
    landingPage: ({ required = false }) => ({
      DataType: 'string',
      label: `Landing Page${required ? '*' : ''}`,
      PropertyId: 'url',
      Required: true,
    }),
    creativeLink: ({ required = false }) => ({
      DataType: 'string',
      label: `Media${required ? '*' : ''}`,
      PropertyId: 'creativeLink',
      Required: true,
    }),
    vastTag: () => ({
      DataType: 'string',
      label: 'VAST Tag',
      PropertyId: 'url',
      Required: true,
    }),
    omsNumber: () => ({
      DataType: 'string',
      label: 'OSI/Wide orbit number',
      PropertyId: 'omsNumber',
      Required: true,
    }),
    socialLink: ({ required = false }) => ({
      DataType: 'string',
      label: `Social Page URL${required ? '*' : ''}`,
      PropertyId: 'socialLink',
      Required: true,
    }),
    youtubeURL: () => ({
      DataType: 'string',
      label: 'YouTube URL',
      PropertyId: 'url',
      Required: true,
    }),
    postText: ({ required = false }) => ({
      DataType: 'string',
      label: `Post Text${required ? '*' : ''}`,
      PropertyId: 'postText',
      Required: true,
    }),
    headline: ({ required = false }) => ({
      DataType: 'string',
      label: `Headline${required ? '*' : ''}`,
      PropertyId: 'headline',
      Required: true,
    }),
    description: ({ required = false }) => ({
      DataType: 'string',
      label: `Description${required ? '*' : ''}`,
      PropertyId: 'description',
      Required: true,
    }),
    questionnaire: () => ({
      DataType: 'form',
      label: 'Questionnaire',
      PropertyId: 'questionnaire',
      Required: true,
    }),
  };

  getCreativeType(creative): CreativeType {
    const { selectedAdFormatList } = creative;
    const adType = selectedAdFormatList?.[0] || null;
    if (adType && adType.toLowerCase().includes('carousel')) {
      return CreativeType.Carousel;
    }

    return CreativeType.Basic;
  }

  getChildConfigs(creative): FormConfig[] {
    if (this.getCreativeType(creative) === CreativeType.Carousel) {
      return [
        this._creativeFormElementsProvider.postText({ required: false }),
        this._creativeFormElementsProvider.headline({ required: false }),
        this._creativeFormElementsProvider.description({ required: false }),
        this._creativeFormElementsProvider.socialLink({ required: false }),
        this._creativeFormElementsProvider.creativeLink({ required: false }),
      ];
    }
    return [];
  }
}


import Vue from 'vue';

import { Models } from '@/injectables/tokens';

import DemographicGroup from '@/entities/instantIO/ui/templates/DemographicGroup.vue';
import { SegmentCategory, SegmentSubCategory } from '@/app/graphql';
import { TargetingSegmentsModelContract } from '@/injectables';

interface DemographicItem {
  property: string;
}

export default Vue.extend({
  name: 'ShowDemographicsCard',

  useInjectable: [Models.NewProposal, Models.TargetingSegments],

  components: { DemographicGroup },

  props: {
    demographics: {
      type: Array,
      required: true,
    },
    audience: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      demographicsList: [
        [{ category: SegmentSubCategory.Age }, { category: SegmentSubCategory.Income }],
        [
          { category: SegmentSubCategory.Family },
          { category: SegmentSubCategory.Gender },
          { category: SegmentSubCategory.Housing },
        ],
      ],
      targetingList: [
        { category: SegmentCategory.AdvancedDemographic },
        { category: SegmentCategory.AudienceProfiles },
        { category: SegmentCategory.Enthusiast },
        { category: SegmentCategory.Occupation },
        { category: SegmentCategory.SearchHistory },
        { category: SegmentCategory.Shopping },
        { category: SegmentCategory.Services },
      ],
    };
  },
  computed: {
    segmentSubCategory() {
      return SegmentSubCategory;
    },
    searchedTargeting(): DemographicItem[] {
      return this.audience.filter(segment => segment.segmentCategory === SegmentCategory.Other);
    },
    demographicsItems() {
      return this.$store.state.targetingSegments.demographicsSegments;
    },
  },
  methods: {
    getSelectedDemographicByCategory(category: SegmentSubCategory): DemographicItem[] {
      return this.demographics.filter(segment => segment.segmentSubCategory === category);
    },
    getDemographicByCategory(category: SegmentSubCategory): DemographicItem[] {
      if (!this.demographicsItems) return [];
      return this.demographicsItems[category] || [];
    },
    getTargetingByCategory(category: SegmentCategory): DemographicItem[] {
      return this.audience.filter(segment => segment.segmentCategory === category);
    },
    getReadableCategory(category: SegmentCategory | 'Search all segments') {
      return (this[Models.TargetingSegments] as TargetingSegmentsModelContract).getReadableCategory(category);
    },
    getReadableSubCategory(category: SegmentSubCategory) {
      return (this[Models.TargetingSegments] as TargetingSegmentsModelContract).getReadableSubCategory(category);
    },
  },
});

import { Err, Ok, Result } from '@sniptt/monads';
import { injectable } from 'inversify';

import { Failure } from '@/injectables/failure';
import { EnumsServiceContract, ProductEnums } from '@/injectables';
import { Service } from '@/injectables/service';

@injectable()
export class EnumsService extends Service implements EnumsServiceContract {
  async getProductsEnums(): Promise<Result<ProductEnums, Failure>> {
    try {
      const { data } = await this._axios.get('/api/product/enums');
      const rateTypes = Object.keys(data.ProductRateType);
      return Ok({
        rateTypes,
      });
    } catch (error) {
      return Err({
        message: `Error while loading product enums, error: ${error}`,
      });
    }
  }
}


import Vue from 'vue';

export default Vue.extend({
  name: 'MapPopup',

  props: {
    name: {
      type: String,
      default: '',
    },
    geoType: {
      type: String,
      default: '',
    },
    population: {
      type: String,
      default: '',
    },
    income: {
      type: String,
      default: '',
    },
    age: {
      type: String,
      default: '',
    },
    female: {
      type: String,
      default: '',
    },
    male: {
      type: String,
      default: '',
    },
  },

  filters: {
    asFractionPercent(value: string | number): string {
      if (!value) return 'No Data';

      const valueAsNumber = typeof value === 'number' ? value : parseFloat(value);

      return `${(valueAsNumber * 100).toFixed(1)}%`;
    },
    asAge(value: string | number): string {
      if (!value) return 'No Data';

      return `${value} years old`;
    },
    asIncome(number: number | string): string {
      if (!number) return 'No Data';

      const roundedNumber = Math.round(Number(number) / 500) * 500;

      if (roundedNumber < 1e3) return `$${roundedNumber}`;

      return `$${+(roundedNumber / 1e3).toFixed(1)}K`;
    },
    asRoundedLocale(number: number | string): string {
      if (!number) return 'No Data';

      return Number(number).toLocaleString();
    },
  },
});


// TODO reuse this component in the output editor
import Vue from 'vue';
import { Services } from '@/injectables/tokens';

import { UploadServiceContract } from '@/injectables';
import ImageUploader from '@/components/Basic/Uploader/ImageUploader.vue';

export default Vue.extend({
  name: 'EditorImagePicker',

  components: {
    ImageUploader,
  },
  useInjectable: [Services.Env, Services.Upload],

  inject: ['showSnackbar'],

  props: {
    value: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data(): { uploadingImage: boolean } {
    return {
      uploadingImage: false,
    };
  },

  computed: {
    innerLoading(): boolean {
      return this.uploadingImage || this.loading;
    },
    showDialog: {
      get(): boolean {
        return this.value;
      },
      set(newValue: boolean) {
        this.$emit('input', newValue);
      },
    },
  },

  methods: {
    async onImageUpload(file: File) {
      this.uploadingImage = true;
      const uploadService = this.uploadService as UploadServiceContract;
      const { isErr, unwrapErr, unwrap } = await uploadService.uploadImage(file);
      if (isErr()) {
        this.uploadingImage = false;
        return this.showSnackbar(unwrapErr().message);
      }
      this.$emit('emit-image', unwrap());
      this.uploadingImage = false;
      this.showDialog = false;
    },
  },
});

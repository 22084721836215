import * as Types from '../../../../../app/graphql/_types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { ProposalTableFragmentFragmentDoc } from '../../../../../app/graphql/fragments/proposal.fragment.generated';
export type GetEndingCampaignsQueryVariables = Types.Exact<{
  status: Array<Types.MediaplannerProposalStatus> | Types.MediaplannerProposalStatus;
  endDateRange: Types.MediaplannerDateRangeInput;
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
  sortDirection?: Types.InputMaybe<Types.SortDirection>;
  orderBy?: Types.InputMaybe<Types.ProposalOrderBy>;
}>;

export type GetEndingCampaignsQuery = {
  getMediaplannerProposals?: {
    total: number;
    limit: number;
    items?: Array<{
      id: string;
      name?: string | null;
      budget?: number | null;
      status: string;
      updatedAt?: string | null;
      campaignStartDate?: string | null;
      campaignEndDate?: string | null;
      startDate?: string | null;
      endDate?: string | null;
      client?: { id: string; name: string; logo?: string | null } | null;
      createdBy?: { email: string } | null;
      updatedBy?: { email: string } | null;
    } | null> | null;
  } | null;
};

export const GetEndingCampaignsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getEndingCampaigns' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'MediaplannerProposalStatus' } },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'endDateRange' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'MediaplannerDateRangeInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortDirection' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'SortDirection' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ProposalOrderBy' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getMediaplannerProposals' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'statuses' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDateRange' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'endDateRange' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortDirection' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortDirection' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProposalTableFragment' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limit' } },
              ],
            },
          },
        ],
      },
    },
    ...ProposalTableFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetEndingCampaignsQuery, GetEndingCampaignsQueryVariables>;


// TODO: use shared component instead
import Vue from 'vue';
import { EChartsOption } from 'echarts/types/dist/shared';

import LegendPlatform from './LegendPlatform.vue';
import { NoData as NoChartData } from '@/shared/ui';
import { Models } from '@/injectables/tokens';
import { BaseProductModelContract, ColorModelContract, ColorType } from '@/injectables';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ECharts = (window as any).echarts || undefined;
if (ECharts === undefined) {
  // eslint-disable-next-line no-console
  console.error('ECharts is not defined');
}

export default Vue.extend({
  name: 'PieChart',

  inheritAttrs: false,

  useInjectable: [Models.BaseProduct, Models.Colors],

  components: { NoChartData, LegendPlatform },

  props: {
    maxHeight: {
      type: Number,
      default: 320,
    },
    dataSource: {
      type: Array,
      default: () => [],
    },
    componentConfig: {
      type: Object,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
  },

  data: (): {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    chartInstance: any;
    canvasWidth: string;
    canvasHeight: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    tooltipCache: any;
    radius: Array<number | string>;
    maxResults: number;
    currKeyIndex: number;
    filterByMenuOpen: boolean;
  } => ({
    chartInstance: undefined,
    canvasWidth: 'auto',
    canvasHeight: '230px',
    tooltipCache: {},
    radius: [''],
    maxResults: 6,
    currKeyIndex: 0,
    filterByMenuOpen: false,
  }),

  computed: {
    xSmall() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    hasEnoughData(): boolean {
      return this.pieData.length > 0;
    },
    smallFontSize(): number {
      // font size needs to adjust to the radius border thickness
      return 19;
    },
    displayOrder(): string {
      // return this.Theme.config.pieCharts.firstItem === 'chart' ? 'first' : 'last'; // sets the chart as the first item or the last item
      return 'last';
    },
    legendColumnCount(): Number {
      // return this.chartColumnCount === 12 ? 12 : 12 - this.chartColumnCount; // either full width or whatever space is left
      return this.xSmall ? 12 : 5;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    canvasStyle(): any {
      return {
        width: this.canvasWidth,
        height: this.canvasHeight,
        'max-width': '850px',
        margin: 'auto',
      };
    },
    center(): string[] {
      return ['50%', '50%'];
    },
    pieConfig(): EChartsOption {
      const getRichData = () => {
        return this.pieData.reduce(
          (
            acc: { fontSize: number; color: string; fontWeight: string },
            _curr: { count?: string; name?: string; revenue?: string; value?: number; valueKey: string },
            index: number,
          ) => {
            acc[`n${index}`] = {
              fontSize: 12,
              color: this.pieColors[index],
              fontWeight: 'bold',
            };
            return acc;
          },
          {},
        );
      };
      const titleTop = ''; // 'Hover over the chart \n to see details';
      const bgColor = '#fff';
      const printLabel = {
        normal: {
          show: true,
          position: 'outside',
          formatter: '{b}: {d}%',
        },
      };
      const usualLabel = {
        normal: {
          show: true,
          position: 'center',
          formatter(): string {
            return `{a|${titleTop}}`;
          },
          rich: {
            a: {
              fontSize: this.smallFontSize,
              color: '#c1c1c1',
              fontWeight: 'lighter',
            },
          },
        },
        emphasis: {
          height: 40,
          width: 110,
          label: { verticalAlign: 'middle', lineHeight: 100 },
          show: true,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          formatter(params: any): string {
            const revenue = `$${Math.trunc(params.data.revenue)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
            return `${revenue}\n{n${params.dataIndex}|${params.data.name}}\n`;
          },
          textStyle: {
            fontSize: 14,
            color: '#444',
            fontWeight: 'bold',
            padding: [15, 15],
            lineHeight: this.smallFontSize,
          },
          backgroundColor: bgColor,
          rich: getRichData(),
        },
      };
      // change label based on what type of pie chart we want to show
      let labelToShow: boolean | object;
      // console.log(this.Theme.config.pieCharts.thickness);
      if (this.radius[0] === 0) {
        labelToShow = printLabel;
      } else {
        labelToShow = usualLabel;
      }

      const cfg: EChartsOption = {
        color: this.pieColors,
        animation: true,
        animationDuration: 500,
        animationDurationUpdate: 300,
        tooltip: {
          show: false,
        },
        legend: {
          show: false,
        },
        series: [
          {
            type: 'pie',
            clockwise: true,
            radius: this.radius,
            avoidLabelOverlap: this.radius[0] === 0, // || this.Theme.config.pieCharts.roseType,
            center: this.center,
            startAngle: this.startAngle,
            data: this.pieData,
            // roseType: this.Theme.config.pieCharts.roseType,
            itemStyle: {
              borderWidth: this.pieData.length === 1 ? 0 : 1,
              borderColor: bgColor,
            },
            label: labelToShow,
            stillShowZeroSum: false,
          },
        ],
      };
      cfg.tooltip = {
        formatter: this.tooltip,
        show: false,
      };
      cfg.height = this.canvasHeight;
      cfg.width = this.canvasWidth;
      return cfg;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    startAngle(): any {
      //   if (this.componentConfig.startAngle) {
      //     return parseInt(this.componentConfig.startAngle, 10);
      //   }
      return 90;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    pieData(): any {
      return this.dataSource.map(v => ({
        name: this.getReadableCategory(v.Category),
        count: v.Count,
        value: Number.parseInt(v.TotalBudget),
        valueKey: v.Category,
        revenue: v.TotalBudget,
      }));
    },
    pieColors(): string[] {
      const compulseColor = this.$c360?.colors?.primary || '';
      const baseColor = compulseColor && compulseColor.includes('#') ? compulseColor : '#7abcfb';
      const numberOfColors = this.pieData.length || 15;

      return (this[Models.Colors] as ColorModelContract).getGeneratedColors({
        numberOfColors,
        baseColor: baseColor,
        type: ColorType.hex,
      });
    },
  },

  watch: {
    pieData: 'initChart',
    radius: 'initChart',
    loading: 'initChart',
  },

  mounted() {
    this.initChart();
    window.addEventListener('optimizedResize', this.onWindowResize);
    this.radius = this.computeRadius();
  },

  beforeDestroy() {
    window.removeEventListener('optimizedResize', this.onWindowResize);
  },

  methods: {
    getReadableCategory(category) {
      return (this[Models.BaseProduct] as BaseProductModelContract).getReadableCategory(category);
    },
    initChart(): void {
      // need to wait for render due to v-if making refs unavailable
      this.$nextTick(() => {
        try {
          if (this.hasEnoughData && this.$refs.canvas) {
            this.chartInstance = ECharts.init(this.$refs.canvas);
            if (this.chartInstance) {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              const c = this.chartInstance as any;
              c.setOption(this.pieConfig);
              setTimeout(() => {
                this.$emit('rendered', { empty: false });
              }, 100);
            }
          } else {
            setTimeout(() => {
              this.$emit('rendered', { empty: true });
            }, 10);
          }
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error('initChart', error);
        }
      });
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onWindowResize(): any {
      setTimeout(() => {
        if (this.chartInstance) {
          this.chartInstance.resize();
        }
      }, 250);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    tooltip(params: any, ticket: string): any {
      if (this.tooltipCache[ticket]) return this.tooltipCache[ticket]; // haven't figured out how to throttle this. caching the return values may help a little
      const value = `${params.name} ${parseFloat(params.value).toFixed(2)}%`; // '';
      return value;
    },
    computeRadius(): string[] {
      return ['80%', '90%'];
    },
    legendHover(dataName): void {
      if (!this.chartInstance) return;
      this.chartInstance.dispatchAction({
        type: 'highlight',
        name: dataName,
      });
    },
    legendUnHover(dataName): void {
      if (!this.chartInstance) return;
      this.chartInstance.dispatchAction({
        type: 'downplay',
        name: dataName,
      });
    },
  },
});


import Vue, { PropType } from 'vue';
import { AdProductUser } from '@/shared/types';
import AgencyButton from '@/features/agency-button';

import { C360UserMenu, C360Avatar } from '@c360/ui';

interface MenuItem {
  id: number;
  title: string;
  icon: string;
  show: boolean;
  action: () => void;
}

interface GlobalMenuData {
  open: boolean;
}

/**
 * Displays dialog with menu items to manage user related things
 * like agency, slide library etc.
 */
export default Vue.extend({
  name: 'UserMenu',

  components: {
    C360UserMenu,
    C360Avatar,
    AgencyButton,
  },

  props: {
    user: {
      type: Object as PropType<AdProductUser | null>,
      default: null,
    },
    currentAgency: {
      type: Object,
      default: null,
    },
    isAgencyAdmin: {
      type: Boolean,
      default: false,
    },
    showProfile: {
      type: Boolean,
      default: false,
    },
    isWhiteLabeledEnv: {
      type: Boolean,
      default: true,
    },
    showHelp: {
      type: Boolean,
      default: true,
    },
    showAgencySelector: {
      type: Boolean,
      default: false,
    },
  },

  data(): GlobalMenuData {
    return {
      open: false,
    };
  },

  computed: {
    menuItems(): MenuItem[] {
      return [
        {
          id: 1,
          title: 'Home',
          icon: 'Bookmark',
          show: true,
          action: () => {
            this.navigateTo('home');
          },
        },
        {
          id: 2,
          title: 'Manage Agency',
          icon: 'Settings_Future',
          show: this.isAgencyAdmin,
          action: () => {
            this.$emit('manage-agency');
          },
        },
        {
          id: 3,
          title: 'Manage Library',
          icon: 'Slider_03',
          show: true,
          action: () => {
            this.$emit('open-slide-library');
          },
        },
        {
          id: 4,
          title: 'Manage Templates',
          icon: 'Slider_02',
          show: this.isAgencyAdmin,
          action: () => {
            this.$emit('open-templates-library');
          },
        },
        {
          id: 5,
          title: 'Manage Layouts',
          icon: 'Bar_Top',
          show: this.isAgencyAdmin,
          action: () => {
            this.navigateTo('manageLayouts');
          },
        },
        {
          id: 6,
          title: 'Help',
          icon: 'Circle_Warning',
          show: this.isWhiteLabeledEnv && this.showHelp,
          action: () => {
            this.$emit('navigate-to-help-center');
          },
        },
        {
          id: 7,
          title: 'Logout',
          icon: 'Log_Out',
          show: true,
          action: () => {
            this.$emit('logout');
          },
        },
      ];
    },
    filteredList() {
      return [this.menuItems.filter(item => item.show)];
    },
    mappedUser() {
      return { ...this.user, fullName: this.user.name };
    },
  },
  methods: {
    navigateTo(routeName: string): void {
      this.$emit('navigate-to', routeName);
      this.menuOpened = false;
    },
    onItemClick(item) {
      item.action();
    },
  },
});

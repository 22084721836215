import * as Types from '../../../../../app/graphql/_types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { EditableProductConfigFragmentFragmentDoc } from '../../../../../app/graphql/fragments/product-config.fragment.generated';
export type GetProductConfigByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
  agencyId?: Types.InputMaybe<Types.Scalars['UUID']>;
}>;

export type GetProductConfigByIdQuery = {
  product?: {
    id: string;
    name?: string | null;
    type?: Types.ProductConfigType | null;
    category?: Types.ProductConfigCategory | null;
    agencyId?: string | null;
    description?: Record<string, any> | null;
    minDays?: number | null;
    minSpend?: number | null;
    recommendedBudget?: number | null;
    cpcMargin?: number | null;
    flightType?: Types.ProductConfigFlightType | null;
    isMonthly?: boolean | null;
    omsAccountNumber?: string | null;
    omsName?: string | null;
    IORecipientEmail?: string | null;
    fulfillmentMethod?: Types.ProductConfigFullfillmentMethod | null;
    flightConfigs?: Array<{
      id: string;
      targetingOption?: string | null;
      flightCategory?: Types.FlightCategory | null;
      adFormatList?: Record<string, any> | null;
      rate?: number | null;
      rateType?: Types.RateType | null;
      markupMin?: number | null;
      markupMax?: number | null;
      index: string;
    } | null> | null;
  } | null;
};

export const GetProductConfigByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getProductConfigById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'agencyId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'product' },
            name: { kind: 'Name', value: 'getMediaplannerProductConfig' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'agencyId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'agencyId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EditableProductConfigFragment' } }],
            },
          },
        ],
      },
    },
    ...EditableProductConfigFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetProductConfigByIdQuery, GetProductConfigByIdQueryVariables>;


import Vue from 'vue';
import { SemKeyword } from '@/shared/types';
import { InputValidationRules } from 'vuetify';

export default Vue.extend({
  name: 'KeywordBody',

  props: {
    entryLimit: {
      type: Number,
      default: 100,
    },
    generatedKeywords: {
      required: true,
      type: Array,
    },
    keywordIdeas: {
      required: true,
      type: Array,
    },
    customKeywords: {
      required: true,
      type: Array,
    },
    isChangeDisabled: {
      type: Boolean,
      default: false,
    },
    isPackageProduct: {
      type: Boolean,
      default: false,
    },
    newKeywordIdea: {
      required: true,
      type: String,
    },
    publicPath: {
      type: String,
      required: false,
    },
    downloadResultsAvailable: {
      type: Boolean,
      default: false,
    },
    newCustomKeyword: {
      required: true,
      type: String,
    },
    loadingKeywords: {
      type: Boolean,
    },
    loadingCustomKeywords: {
      type: Boolean,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
  },

  watch: {
    keywordIdeas(newArr): void {
      newArr.length < this.entryLimit && this.$refs[this.ideaInputRef].resetValidation();
    },
    customKeywords(newArr): void {
      newArr.length < this.entryLimit && this.$refs[this.customInputRef].resetValidation();
    },
  },

  data(): { sortBy: string; isDesc: boolean; ideaInputRef: string; customInputRef: string } {
    return {
      ideaInputRef: 'newKeywordIdeaInput',
      customInputRef: 'newCustomKeywordInput',
      sortBy: 'isSelected',
      isDesc: false,
    };
  },

  computed: {
    sortedKeywords(): SemKeyword[] {
      if (!this.generatedKeywords?.length) return [];
      const keywords = [...this.generatedKeywords];
      return keywords.sort((a, b) => {
        if (this.isDesc) {
          return a[this.sortBy] < b[this.sortBy] ? -1 : 1;
        } else {
          return b[this.sortBy] < a[this.sortBy] ? -1 : 1;
        }
      });
    },
    newKeywordIdeaValue: {
      get(): string[] {
        return this.newKeywordIdea;
      },
      set(value: string) {
        this.$emit('update:newKeywordIdea', value);
      },
    },
    newCustomKeywordValue: {
      get(): string[] {
        return this.newCustomKeyword;
      },
      set(value: string) {
        this.$emit('update:newCustomKeyword', value);
      },
    },
    ideaRules(): InputValidationRules {
      return [
        (v: string) => !this.keywordIdeas.includes(v) || 'This idea is already selected',
        () => this.keywordIdeas.length < this.entryLimit || `Only ${this.entryLimit} ideas can be entered at a time`,
      ];
    },
    customRules(): InputValidationRules {
      return [
        (v: string) =>
          (!this.customKeywords.includes(v) && !this.generatedKeywords.some(kw => kw.keyword === v)) ||
          'This keyword has already been added',
        () =>
          this.customKeywords.length < this.entryLimit || `Only ${this.entryLimit} keywords can be entered at a time`,
      ];
    },
  },
  methods: {
    saveKeywordIdea(): void {
      this.$emit('save-keyword-idea');
      this.handleInputFocus(this.ideaInputRef, this.keywordIdeas.length);
    },
    saveCustomKeyword(): void {
      this.$emit('save-custom-keyword');
      this.handleInputFocus(this.customInputRef, this.customKeywords.length);
    },
    handleInputFocus(ref: string, arrLength: number): void {
      if (this.$refs?.[ref] && arrLength >= this.entryLimit) {
        this.$nextTick(() => this.$refs[ref].blur());
      }
    },
    recommendationConfig(number: number): { tooltip: string; color: string; percent: number } {
      if (number === 1) {
        return {
          tooltip: 'This keyword is not highly recommended',
          color: 'warning',
          percent: 67,
        };
      } else if (number === 2) {
        return {
          tooltip: 'This keyword is highly recommended',
          color: 'success',
          percent: 100,
        };
      } else if (number === -1) {
        return {
          tooltip: 'No recommendation data',
          color: 'primary',
          percent: 100,
        };
      } else if (number === -99) {
        return {
          tooltip: 'grey recommendation data',
          color: 'warning',
          percent: 0,
        };
      }
      return {
        tooltip: 'This keyword is not recommended',
        color: 'error',
        percent: 33,
      };
    },
    needsDivider(index: number): boolean {
      const length = this.keywordIdeas.length;
      return index <= length - 3;
    },
    sortKeywords(attribute: string): void {
      if (this.sortBy === attribute) {
        this.isDesc = !this.isDesc;
        return;
      }
      this.sortBy = attribute;
      this.isDesc = true;
    },
  },
});

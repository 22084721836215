import 'reflect-metadata';
import { injectable } from 'inversify';
import { DateModelContract } from '../contracts';

import { format, parseISO } from 'date-fns';

@injectable()
export class DateModel implements DateModelContract {
  dateToString(date: Date): string {
    return format(date, 'yyyy-MM-dd');
  }

  dateToFormatDateString(date: string): string {
    return format(new Date(date), 'yyyy-MM-dd');
  }

  ISOStringFromFormatDateString(date: string): string {
    return parseISO(date).toISOString();
  }

  getFullMonthDuration(campaignStartDate: Date, campaignEndDate: Date): number {
    let months = (campaignEndDate.getFullYear() - campaignStartDate.getFullYear()) * 12;
    months -= campaignStartDate.getMonth();
    months += campaignEndDate.getMonth();

    if (campaignStartDate.getDate() > 15) {
      months -= 1;
    }

    if (campaignEndDate.getDate() >= 15) {
      months += 1;
    }

    return months || 1;
  }
}

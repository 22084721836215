var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"d-inline"},[_c('v-menu',{attrs:{"bottom":"","offset-y":"","nudge-left":180,"nudge-width":180,"nudge-bottom":6},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"icon":!_vm.menuOpened,"fab":_vm.menuOpened,"width":"36","height":"36"},on:{"click":function($event){_vm.menuOpened = !_vm.menuOpened}}},on),[(!_vm.menuOpened)?_c('v-icon',[_vm._v("mdi-dots-vertical")]):_c('v-icon',[_vm._v("mdi-close")])],1)]}}]),model:{value:(_vm.menuOpened),callback:function ($$v) {_vm.menuOpened=$$v},expression:"menuOpened"}},[_c('v-sheet',{attrs:{"elevation":"10"}},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{staticClass:"cursor-pointer",attrs:{"disabled":_vm.readOnly},on:{"click":function($event){return _vm.handleNavToEditUrl(_vm.proposal)}}},[_vm._v(" Edit ")]),_c('v-divider'),(_vm.isAgencyAdmin)?_c('v-list-item',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.$emit('open-reassign-user', {
              id: _vm.proposal.id,
              campaignName: _vm.proposal.name,
              createdBy: _vm.proposal.createdBy,
            })}}},[_vm._v(" Reassign user ")]):_vm._e(),_c('v-divider'),_c('v-list-item',{staticClass:"cursor-pointer",attrs:{"to":{
            name: 'proposalOutput',
            params: { id: _vm.proposal.client?.id, proposalId: _vm.proposal.id },
            ...(_vm.returnToClientProposals && { query: { clientProposals: true } }),
          }}},[_vm._v(" Open output ")]),_c('v-divider'),_c('v-list-item',{staticClass:"cursor-pointer",attrs:{"disabled":_vm.readOnly},on:{"click":_vm.resetOutput}},[_vm._v("Reset output")]),_c('v-divider'),_c('v-list-item',{staticClass:"cursor-pointer",on:{"click":_vm.duplicateProposal}},[_vm._v("Duplicate")]),(_vm.isDeleteVisible)?[_c('v-divider'),_c('v-list-item',{attrs:{"disabled":_vm.readOnly},on:{"click":function($event){return _vm.deleteProposal()}}},[_c('span',{class:!_vm.readOnly ? 'error--text' : ''},[_vm._v("Delete")])])]:_vm._e()],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
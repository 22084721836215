
import Vue from 'vue';
import MapComponent from '@/shared/legacy/map/map.vue';
import { Services } from '@/injectables/tokens';
import { LoggerContract } from '@/injectables';

export default Vue.extend({
  name: 'overviewMap',

  useInjectable: [Services.Polygons],

  components: { MapComponent },
  data: (): {
    loading: boolean;
    exportBackgroundImage: string | null;
    mapTilerKey: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    localPolygons: any;
  } => ({
    loading: false,
    exportBackgroundImage: null,
    mapTilerKey: 'CCrAlH25DTP89c6iJsO3',
    localPolygons: null,
  }),
  props: {
    mapId: {
      type: String,
      default: '0',
    },
    slideId: {
      type: String,
      default: '',
    },
    height: {
      type: Number,
    },
    width: {
      type: Number,
    },
    zipList: {
      type: [Array, Object],
      default: () => [],
    },
    cityList: {
      type: [Array, Object],
      default: () => [],
    },
    countyList: {
      type: [Array, Object],
      default: () => [],
    },
    dmaList: {
      type: [Array, Object],
      default: () => [],
    },
    stateList: {
      type: [Array, Object],
      default: () => [],
    },
    zips: {
      type: [Array, Object],
      default: () => [],
    },
    cities: {
      type: [Array, Object],
      default: () => [],
    },
    counties: {
      type: [Array, Object],
      default: () => [],
    },
    dmas: {
      type: [Array, Object],
      default: () => [],
    },
    states: {
      type: [Array, Object],
      default: () => [],
    },
    exporting: {
      type: Boolean,
      default: () => false,
    },
    mapOnly: {
      type: Boolean,
      default: () => false,
    },
  },
  watch: {
    outputLayout(): void {
      const map = this.$refs[this.mapCacheKey]?.Get();
      if (map) {
        map.Resize();
      }
    },
    mapColor(): void {
      const map = this.$refs[this.mapCacheKey]?.Get();
      if (map) {
        this.renderPolygons(map, this.localPolygons);
      }
    },
  },
  computed: {
    outputLayout(): string {
      return this.$store.state.output.activeOutput?.layout;
    },
    selectedMap(): string {
      // '24753aa3-7a2d-4bb6-9370-e7d657b08efb'
      return this.$store.state.map.defaultMap;
    },
    mapColor(): string {
      let mapColor = '#ec2d00';
      if (this.$store.getters['output/slideColors']?.secondary) {
        mapColor = this.$store.getters['output/slideColors'].secondary;
      }
      if (this.slideColors?.mapLayer) {
        mapColor = this.slideColors.mapLayer;
      }
      return mapColor;
    },
    geoSelections(): object {
      return {
        zips: [...this.zipList, ...this.zips]
          ?.map(z => z?.key?.replace(/ZIP_/g, ''))
          .filter(Boolean)
          .filter(g => g?.length),
        cities: [...this.cityList, ...this.cities]
          .filter(Boolean)
          .map(city => city?.key?.replace(/CITY_/g, ''))
          .filter(g => g?.length),
        dmas: [...this.dmaList, ...this.dmas]
          .filter(Boolean)
          .map(dma => dma?.key?.replace(/DMA_/g, ''))
          .filter(g => g?.length),
        states: [...this.stateList, ...this.states]
          .filter(Boolean)
          .map(state => state?.key?.replace(/STAT_/g, ''))
          .filter(g => g?.length),
        counties: [...this.countyList, this.counties]
          ?.map(c => c?.key?.replace(/CNTY_/g, ''))
          .filter(Boolean)
          .filter(g => g.length),
      };
    },
    mapRenderCacheKey(): string {
      return `_overviewMap_${this.mapId}_${this.mapOnly ? '1' : '2'}_light`;
    },
    mapCacheKey(): string {
      return `_overviewMap_${this.mapId}_${this.mapOnly ? '1' : '2'}`;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    currentSlide(): any {
      const slides = this.$store.state.output?.activeOutput?.slides;
      if (this.slideId && Array.isArray(slides)) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const found = slides.find((slide: any) => slide._id === this.slideId);
        if (found) {
          return found;
        }
      }
      return null;
    },
    slideColors(): string | null {
      return this.currentSlide?.colors;
    },
  },
  methods: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    forceArray(el: any): any[] {
      return this.$store.getters['output/forceArray'](el);
    },
    async onMapReady(): Promise<void> {
      const map = this.$refs[this.mapCacheKey]?.Get();
      if (!map || !map.host) {
        // eslint-disable-next-line no-console
        console.error('map component did not load correctly', this.mapCacheKey);
        return;
      }

      if (this.geoSelections && Object.keys(this.geoSelections).length) {
        this.loading = true;
        const { isErr, unwrap, unwrapErr } = await this.polygonsService.getPolygonsAnonymous(this.geoSelections);
        this.loading = false;
        if (isErr()) {
          const { message = '' } = unwrapErr();
          const logger: LoggerContract = this.$container.get('logger');
          logger.print('error', 'OverviewMap', message);
          return;
        }

        // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
        const { pstate, ...polygons } = unwrap();
        this.localPolygons = polygons;
        this.renderPolygons(map, polygons);
      } else {
        map.ClearMap();
        map.SetView([40, -100], 4);
        // eslint-disable-next-line no-console
        console.log('onMapReady, no geo to show');
      }
    },
    renderPolygons(map, polygons): void {
      map.RemoveLayer('allPolygons');
      const polyLayer = map.CreateLayer('allPolygons');
      let hasPolygons = false;
      Object.keys(polygons).forEach((key: string) => {
        if (key !== 'LocationNotFound') {
          const polygon = polygons[key];
          const layer = map.AddToLayer(polyLayer);
          map.SetWKT(layer, polygon.WKT_P100, true);
          hasPolygons = true;
          const polyStyle = {
            fillOpacity: 0.4,
            color: this.mapColor,
            fillColor: this.mapColor,
            weight: 3,
          };
          layer.setStyle(polyStyle);
        }
      });

      let paddingOptions = { paddingTopLeft: [20, 20], paddingBottomRight: [20, 20] };
      if (this.exporting) {
        paddingOptions = { paddingTopLeft: [10, 10], paddingBottomRight: [10, 10] };
      }

      if (hasPolygons) {
        map.FitAllLayers({ force: true, animate: false, ...paddingOptions });
        if (this.exporting) {
          this.replaceBackground();
        }
      } else {
        setTimeout(() => {
          this.$emit('rendered', { empty: true });
        }, 10);
      }
    },
    replaceBackground(retries = 5): void {
      const mapRef = this.$refs[this.mapCacheKey];
      const map = mapRef?.Get();
      const mapEl = mapRef?.$el;

      setTimeout(() => {
        if (!mapRef || !mapEl || !map) {
          // eslint-disable-next-line no-console
          console.error('-> overviewmap: no map element', this.mapCacheKey, { mapRef, map, mapEl });
          if (retries > 0) {
            setTimeout(() => {
              this.replaceBackground(retries - 1);
            }, 500);
          } else {
            setTimeout(() => {
              this.$emit('rendered', { empty: true });
            }, 10);
          }
          return;
        }
        const tileBackgroundList = mapEl.getElementsByClassName('mapboxgl-map');
        if (!tileBackgroundList || tileBackgroundList.length === 0) {
          // eslint-disable-next-line no-console
          console.error('OverviewMap, can not export, no map background');
          if (retries > 0) {
            setTimeout(() => {
              this.replaceBackground(retries - 1);
            }, 500);
          } else {
            setTimeout(() => {
              this.$emit('rendered', { empty: true });
            }, 10);
          }
          return;
        }
        const tileBackground = tileBackgroundList[0] as HTMLElement;
        const zoom = map.GetZoom() - 1; // why do we need the -1 ???
        const center = map.GetCenter();
        const staticMapByCenter = `https://api.maptiler.com/maps/${this.selectedMap}/static/${center.lng},${center.lat},${zoom}/${mapEl.offsetWidth}x${mapEl.offsetHeight}@1x.png?key=CCrAlH25DTP89c6iJsO3`;
        const img = new Image();
        img.addEventListener(
          'load',
          () => {
            this.exportBackgroundImage = staticMapByCenter;
            tileBackground.style.display = 'none';
            setTimeout(() => {
              this.$emit('rendered', { empty: false });
            }, 500);
          },
          false,
        );
        img.src = staticMapByCenter;
      }, 100);
    },
  },
});


import Vue from 'vue';
import { Proposal } from '@/shared/types';
import ProposalsTable from '@/components/Proposals/ProposalsTable.vue';
import CampaignsTableFilters from '@/components/Campaigns/CampaignsTableFilters.vue';
import CreateProposalDialog from '@/components/Proposal/CreateProposalDialog.vue';

import { debounce } from 'lodash';
import { Models, Services } from '@/injectables/tokens';
import { RouterModelContract } from '@/injectables';

export default Vue.extend({
  name: 'ClientProposals',

  components: { CampaignsTableFilters, ProposalsTable, CreateProposalDialog },

  props: {
    isByClient: {
      type: Boolean,
      default: false,
    },
  },

  useInjectable: [Services.Proposal, Models.Router],

  data: (): {
    createProposalDialogStatus: boolean;
    clientId: string | null;
    clientName: string | null;
    searchTerm: string;
    searchedProposals: Proposal[];
    options: {
      page: number;
      itemsPerPage: number;
      sortBy: string;
      sortDesc: boolean;
    };
    filters: {
      status: string;
      dates: {
        dates: Record<'start' | 'end' | 'modified', { from: string; to: string; range: string[] }>;
      };
    };
  } => ({
    createProposalDialogStatus: false,
    clientId: null,
    clientName: null,
    searchTerm: '',
    searchedProposals: [],
    options: {
      page: 1,
      itemsPerPage: 15,
      sortBy: 'updatedAgo',
      sortDesc: true,
    },
    filters: {
      status: '',
      dates: {
        dates: {
          start: {
            from: '',
            to: '',
            range: [],
          },
          end: {
            from: '',
            to: '',
            range: [],
          },
          modified: {
            from: '',
            to: '',
            range: [],
          },
        },
      },
    },
  }),

  watch: {
    filters: {
      handler() {
        this.setFirstPage();
        this.getProposals();
      },
      deep: true,
    },
    searchTerm() {
      this.setFirstPage();
      this.getProposals();
    },
  },

  beforeRouteEnter(to, _, next): void {
    next(async vm => {
      await vm.$store.dispatch('client/updateActiveClientId', to.params.id);
    });
  },

  computed: {
    displayRouterView(): boolean {
      return (!this.isByClient && !this.$route.params.id) || this.$route.name === 'proposalOutput';
    },
    client() {
      return this.$store.getters['client/activeClient'];
    },
    clientDailySpend(): number {
      return this.client?.BuzzboardRecommendedDailyBudget || 0;
    },
    proposals(): Proposal[] {
      return this.$store.state['proposal'].proposals.list || [];
    },
    loadingProposals(): boolean {
      return this.$store.state['proposal'].loadingAllProposals;
    },
    total(): number {
      if (this.$store.state.proposal.proposals?.list) {
        return this.$store.state.proposal.proposals.total;
      }
      return 0;
    },
    itemsPerPage: {
      get(): number {
        const routerEntity = this.$container.get(Models.Router);
        return (routerEntity as RouterModelContract).getValidItemPerPage(this.$route.query.itemsPerPage);
      },
      set(itemsPerPage: number) {
        this.updateRoute({ itemsPerPage });
      },
    },
    skippedRows() {
      return (this.options.page - 1) * this.options.itemsPerPage;
    },
  },

  methods: {
    async createNewProposalByClient({ id, name }): Promise<void> {
      this.createProposalDialogStatus = true;
      this.clientId = id;
      this.clientName = name;
    },

    updateFilters(filters) {
      this.filters = filters;
    },
    updateSearch(searchTerm) {
      this.searchTerm = searchTerm;
    },
    async changePage(page) {
      if (this.options.page === page) return;

      this.options.page = page;
      await this.getProposals();
    },
    async changeCountOfItems(itemsPerPage) {
      if (this.options.itemsPerPage === itemsPerPage) return;
      this.options.itemsPerPage = itemsPerPage;
      this.setFirstPage();

      await this.getProposals();
    },
    async updateSort(sortBy) {
      if (this.options.sortBy === sortBy) return;
      this.options.sortBy = sortBy;
      this.setFirstPage();

      await this.getProposals();
    },
    async updateSortType(desc) {
      if (this.options.sortDesc === desc) return;
      this.options.sortDesc = desc;
      this.setFirstPage();
      await this.getProposals();
    },
    getProposals: debounce(function ({
      limit = this.options.itemsPerPage,
      offset = this.skippedRows,
      sortBy = this.options.sortBy,
      desc = this.options.sortDesc,
      searchTerm = this.searchTerm,
      filters = this.filters,
    } = {}) {
      return this.$store.dispatch('proposal/getProposalsByActiveClient', {
        clientId: this.client?.id || this.$route.params.id,
        limit,
        offset,
        sortBy,
        desc,
        searchTerm,
        filters,
      });
    },
    300),
    setFirstPage() {
      this.options.page = 1;
    },
  },

  mounted(): void {
    this.getProposals();
  },
});

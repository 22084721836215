
import Vue, { PropType } from 'vue';

import { ConfiguredProduct } from '@/shared/types';

import ProductCheckBox from '@/entities/instantIO/ui/templates/ProductCheckBox.vue';

import { BaseProductModelContract, ValidationServiceContract } from '@/injectables';
import { Services, Models } from '@/injectables/tokens';
import { ProductConfigType } from '@/app/graphql';

export default Vue.extend({
  name: 'EditableInfoCard',

  useInjectable: [Models.BaseProduct],

  components: { ProductCheckBox },

  props: {
    selected: {
      type: Array as PropType<ConfiguredProduct[]>,
      required: true,
    },
    items: {
      type: Array as PropType<ConfiguredProduct[]>,
      required: true,
    },
    dates: {
      type: Array,
      default: () => ['', ''],
    },
    campaignDuration: {
      type: Number,
    },
    geoSelection: {
      type: Object,
    },
  },
  computed: {
    productIds() {
      return this.selected.map(product => product.productConfigId);
    },
    rules() {
      const validationService: ValidationServiceContract = this.$container.get(Services.Validation);

      return {
        minRange: validationService.minRangeValidatorFactory(),
      };
    },
    hasZips() {
      return this.geoSelection.zipList && this.geoSelection.zipList.length;
    },
  },
  methods: {
    productIcon(product: ConfiguredProduct): string {
      return (this[Models.BaseProduct] as BaseProductModelContract).categoryIconAndColor(product.category as any).icon;
    },
    changeSelection(product) {
      if (this.isSelected(product)) {
        this.$emit('remove-product', product);
        return;
      }
      this.$emit('set-product', product);
    },
    isSelected(product) {
      return this.productIds.includes(product.id);
    },
    productDisabledByGeo(product) {
      if (product.type === ProductConfigType.GoogleSearch && this.hasZips) return true;
      return false;
    },

    productIsDisabledByDate(productCandidate): boolean {
      const { minDays = 0 } = productCandidate;

      if (!minDays) {
        return false;
      }

      const [startDate, endDate] = this.dates;

      const dateRange = `${new Date(startDate)} - ${new Date(endDate)}`;

      const validation = this.rules.minRange(dateRange, minDays);

      return !!validation?.length;
    },
  },
});

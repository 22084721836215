import * as Types from '../../../../../app/graphql/_types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type SendResetPasswordEmailQueryVariables = Types.Exact<{
  redirectUrl: Types.Scalars['String'];
  email: Types.Scalars['String'];
}>;

export type SendResetPasswordEmailQuery = { sendResetPasswordEmail?: boolean | null };

export const SendResetPasswordEmailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'sendResetPasswordEmail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'redirectUrl' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sendResetPasswordEmail' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'redirectUrl' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'redirectUrl' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SendResetPasswordEmailQuery, SendResetPasswordEmailQueryVariables>;

import {
  CalendarServiceContract,
  LoggerContract,
  ProposalProductModelContract,
  ProposalServiceContract,
} from '@/injectables';
import { Failure } from '@/injectables/failure';
import { ErrorResponse } from '@/shared/legacy/classes';
import { Proposal, UnsafeAny } from '@/shared/types';

import { ActionTree } from 'vuex';
import { ProposalState } from './state';
import { MediaplannerProposalStatus } from '@/app/graphql';
import { GetProposalsQueueQuery } from '@/injectables/services/proposal/graphql/queries/get-proposals-queue.generated';
import { GetEndingCampaignsQuery } from '@/injectables/services/proposal/graphql/queries/get-ending-campaigns.generated';
import { CloneProposalMutation } from '@/injectables/services/proposal/graphql/mutations/clone-proposal.generated';
import { DeleteProposalMutation } from '@/injectables/services/proposal/graphql/mutations/delete-proposal.generated';
import { GetProposalsQuery } from '@/injectables/services/proposal/graphql/queries/get-all-proposals.generated';
import { GetClientProposalsQuery } from '@/injectables/services/proposal/graphql/queries/get-client-propolsals.generated';
import { GetSharedProposalOutputQuery } from '@/injectables/services/proposal/graphql/queries/get-shared-proposal-output.generated';
import { UpdateProposalStatusMutation } from '@/injectables/services/proposal/graphql/mutations/update-proposal-status.generated';

interface ActionsInjections {
  logger: LoggerContract;
  proposalService: ProposalServiceContract;
  calendarService: CalendarServiceContract;
  ProposalProductModel: ProposalProductModelContract;
}

export const actions = ({ logger, proposalService, calendarService, ProposalProductModel }: ActionsInjections) =>
  ({
    async getAdminQueueProposals(
      { commit },
      { sortBy = 'CampaignEndDate', sortDesc }: { sortBy?: string; sortDesc?: boolean } = {},
    ): Promise<GetProposalsQueueQuery['getMediaplannerProposals']['items']> {
      try {
        commit('SET_PROPOSALS_ADMIN_QUEUE_LOADING', true);

        const { isErr, unwrap, unwrapErr } = await proposalService.getAdminQueueProposals({ sortBy, desc: sortDesc });

        if (isErr()) {
          const { message } = unwrapErr();
          logger.print('error', 'store.proposal.actions.getAdminQueueProposals', message);
          commit('SET_PROPOSALS_ADMIN_QUEUE', []);
          return [];
        }

        const data = unwrap();

        commit('SET_PROPOSALS_ADMIN_QUEUE', data);
        return data;
      } finally {
        commit('SET_PROPOSALS_ADMIN_QUEUE_LOADING', false);
      }
    },

    // TODO: @to-graphql
    async getEndingCampaigns(
      { commit },
      { sortBy, sortDesc }: { sortBy?: string; sortDesc?: boolean } = {},
    ): Promise<GetEndingCampaignsQuery['getMediaplannerProposals']['items']> {
      try {
        commit('SET_PROPOSALS_ENDING_CAMPAIGNS_LOADING', true);
        const { isErr, unwrap, unwrapErr } = await proposalService.getEndingCampaigns({ sortBy, desc: sortDesc });

        if (isErr()) {
          const { message } = unwrapErr();
          logger.print('error', 'proposal.getEndingCampaigns', message);
          return;
        }

        const response = unwrap();

        commit('SET_PROPOSALS_ENDING_CAMPAIGNS', Array.isArray(response) ? response : []);
        return response;
      } catch (err) {
        logger.print('error', 'store.proposal.actions.getEndingCampaigns', err);
        throw new Error('no data');
      } finally {
        commit('SET_PROPOSALS_ENDING_CAMPAIGNS_LOADING', false);
      }
    },

    async updateActiveProposalId(
      { commit, dispatch, rootState },
      { proposalId, fromShared = false }: { proposalId: string | null; fromShared: boolean },
    ): Promise<void> {
      commit('SET_ACTIVE_PROPOSAL_ID', proposalId);
      if (proposalId && fromShared) {
        commit('SET_ACTIVE_PROPOSAL_ID', proposalId);
      } else if (proposalId) {
        await dispatch('getProposalByProposalId', { proposalId, PRPID: rootState['client'].activeClientId });
      } else {
        commit('SET_ACTIVE_PROPOSAL', null);
      }
    },

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    updateProposals({ commit }, payload: any): void {
      commit('SET_PROPOSALS', { PRPID: payload.PRPID, list: payload.proposals });
    },

    // TODO: @to-graphql
    async getProposalsSummaryByActiveClient({ commit }, { clientId }): Promise<void> {
      try {
        commit('SET_PROPOSALS_SUMMARY_BY_CLIENT_LOADING', true);

        const { isErr, unwrap, unwrapErr } = await proposalService.getProposalsSummaryByActiveClient(clientId);

        if (isErr()) {
          const { message } = unwrapErr();
          logger.print('error', 'proposal.getProposalsSummaryByActiveClient', message);
          return;
        }

        const response = unwrap();

        commit('SET_PROPOSALS_SUMMARY_BY_CLIENT', response);

        return;
      } catch (err) {
        logger.print('error', 'store.proposal.actions.getProposalsSummaryByActiveClient', err);
        throw new Error('no data');
      } finally {
        commit('SET_PROPOSALS_SUMMARY_BY_CLIENT_LOADING', false);
      }
    },

    // TODO: @to-graphql
    async getAllProposals(
      { commit },
      { limit = 5, offset = 0, sortBy = 'updatedAgo', desc = false, searchTerm = '', filters = {} } = {},
    ): Promise<GetProposalsQuery['getMediaplannerProposals']['list']> {
      commit('LOADING_ALL_PROPOSALS', true);

      const { isErr, unwrapErr, unwrap } = await proposalService.getAllProposals({
        limit,
        offset,
        sortBy,
        desc,
        searchTerm,
        filters,
      });
      if (isErr()) {
        const { message } = unwrapErr();
        logger.print('error', 'proposal.getAllProposals', message);
        commit('SET_ALL_PROPOSALS', { items: [], total: 0 });
        commit('LOADING_ALL_PROPOSALS', false);
        return [];
      }

      const { list: items, total } = unwrap();
      commit('SET_ALL_PROPOSALS', { items, total });
      commit('LOADING_ALL_PROPOSALS', false);

      return items;
    },

    // TODO: @to-graphql
    async getProposalSearch({ commit }, { query, params }): Promise<Proposal[]> {
      try {
        commit('LOADING_ALL_PROPOSALS', true);
        const { isErr, unwrap, isOk } = await proposalService.search(query, params, { limit: 500 });
        if (isOk()) {
          const proposals = unwrap();
          return proposalService.getScoredProposals(proposals, query);
        }
        if (isErr()) {
          return [];
        }
      } catch (err) {
        logger.print('error', 'store.proposal.actions.searchProposals', err);
      } finally {
        commit('LOADING_ALL_PROPOSALS', false);
      }
    },

    async searchProposals({ commit, dispatch }, { query, params }): Promise<void> {
      const proposal = await dispatch('getProposalSearch', { query, params });

      commit('SET_FILTERED_PROPOSALS', proposal);
    },

    // TODO: @to-graphql
    async getProposalByProposalId(
      { state, getters, commit },
      { proposalId, PRPID, proposal }: { proposalId?: string; PRPID?: string; proposal?: Proposal },
    ): Promise<Proposal> {
      if (proposalId && state?.activeProposalId === proposalId) {
        return getters.activeProposal;
      }
      let updatedProposal = null;
      if (proposal) {
        updatedProposal = proposal;
      } else {
        const proposalPropertyId = `DLADVERTISER_${PRPID}_${proposalId}`;
        commit('LOADING_PROPOSALS', true);

        const { isErr, unwrapErr, unwrap } = await proposalService.getProposalByProposalId(proposalPropertyId);

        if (isErr()) {
          const { message } = unwrapErr();
          logger.print('error', 'proposal.getProposalByProposalId', message);
          commit('LOADING_PROPOSALS', false);
          return;
        }

        const data = unwrap();
        if (data && typeof data === 'object') {
          updatedProposal = data;
        }
      }
      commit('LOADING_PROPOSALS', false);
      commit('SET_ACTIVE_PROPOSAL', ProposalProductModel.sortAndCleanProductAndFlights(updatedProposal));
      return updatedProposal;
    },

    // TODO: @to-graphql
    async getProposalsByActiveClient(
      { commit },
      payload,
    ): Promise<GetClientProposalsQuery['getMediaplannerProposals']['list']> {
      commit('LOADING_ALL_PROPOSALS', true);
      const { isErr, unwrapErr, unwrap } = await proposalService.getProposalsByClient(payload);
      if (isErr()) {
        logger.print('error', 'store.proposal.actions.getProposalsByActiveClient', unwrapErr().message);
        commit('SET_PROPOSALS', { PRPID: payload.clientId, list: [], total: 0 });
        commit('LOADING_ALL_PROPOSALS', false);
        return [];
      }
      const { list, total } = unwrap();
      commit('SET_PROPOSALS', { PRPID: payload.clientId, list, total });
      commit('LOADING_ALL_PROPOSALS', false);
      return list;
    },

    updateActiveProposalInState(
      { commit, state },
      payload: { proposalId: string; keyValue: { key: string; value: string } },
    ): void {
      if (!state.activeProposal || state.activeProposal.id !== payload.proposalId) return;
      commit('UPDATE_STATE_ACTIVE_PROPOSAL', payload.keyValue);
    },

    triggerRefetchProposals({ commit }): void {
      commit('SET_PROPOSALS_NOT_LOADED');
    },

    // TODO: @to-graphql
    async deleteProposal(
      { dispatch },
      propertyId?: string,
    ): Promise<DeleteProposalMutation['deleteMediaplannerProposal'] | null> {
      if (!propertyId) {
        logger.print('error', 'store.proposal.actions.deleteProposal', 'You must first pass a valid propertyId');
        return null;
      }
      const { isErr, unwrapErr, unwrap } = await proposalService.deleteProposal(propertyId);
      if (isErr()) {
        const error = unwrapErr();
        logger.print('error', 'store.proposal.actions.deleteProposal', error.message);
        return null;
      }
      const { message, proposal } = unwrap();
      dispatch('showSnackbar', { content: message, color: 'success' }, { root: true });
      return proposal;
    },

    // TODO: @to-graphql
    async updateProposalStatus(
      { commit, dispatch },
      { proposalPropertyId, newStatus }: { proposalPropertyId: string; newStatus: MediaplannerProposalStatus },
    ): Promise<UpdateProposalStatusMutation['updateMediaplannerProposalStatus'] | boolean> {
      if (!proposalPropertyId || !newStatus) {
        logger.print('error', 'updateProposalStatus()', 'Did not receive proper update information');
        return null;
      }
      commit('SET_SAVE_PROGRESS_LOADING', true); // use different loading?
      const statusUpdateObject = {
        proposalPropertyId,
        newStatus,
      };
      // inject from statusEntity after refactoring proposalModule
      if (newStatus === MediaplannerProposalStatus.SubmittedForReview) {
        dispatch('output/getOutput', proposalPropertyId, { root: true });
      }

      const { isErr, unwrap } = await proposalService.updateProposalStatus(statusUpdateObject);

      if (isErr()) {
        logger.print(
          'error',
          'store.proposal.actions.localUpdateProposal',
          'proposal/updateProposalStatus - unable to update at this time',
        );
        commit('SET_SAVE_PROGRESS_LOADING', false);
        return false;
      }

      commit('SET_SAVE_PROGRESS_LOADING', false);
      return unwrap();
    },

    // TODO: @to-graphql
    async cloneExistingProposal(
      { commit },
      { proposalToCloneId, delay = 0 }: { proposalToCloneId: string; delay: number },
    ): Promise<CloneProposalMutation['duplicateMediaplannerProposal'] | null> {
      try {
        commit('SET_CLONE_PROPOSAL_LOADING', true);
        commit('SET_PROPOSALS_NOT_LOADED');
        commit('SET_PROPOSAL_ERROR', { status: false });
        await new Promise(res => setTimeout(res, delay));

        const proposalPpid = proposalToCloneId;

        const { isErr, unwrapErr, unwrap } = await proposalService.cloneExistingProposal(proposalPpid);

        if (isErr()) {
          const { message } = unwrapErr();
          logger.print('error', 'proposal.cloneExistingProposal', message);
          commit('SET_PROPOSAL_ERROR', { status: true, message });
          return null;
        }

        const proposal = unwrap();

        return proposal;
      } catch (err) {
        throw new Error(err);
      } finally {
        commit('SET_CLONE_PROPOSAL_LOADING', false);
      }
    },

    // TODO: @to-graphql
    async getProposalsRevenueData({ commit }): Promise<void> {
      try {
        const { isErr, unwrapErr, unwrap } = await proposalService.getProposalsRevenueData();

        if (isErr()) {
          const { message } = unwrapErr();
          logger.print('error', 'proposal.getProposalsRevenueData', message);
          return;
        }

        const response = unwrap();

        if (response && 'ChartList' in response) {
          response.ChartList.sort(function (a, b) {
            if (a.Count === b.Count) {
              return a.Category.toUpperCase() > b.Category.toUpperCase() ? 1 : -1;
            }
            return Number(b.Count) - Number(a.Count);
          });
          commit('SET_PROPOSAL_REVENUE_SUMMARY', response.ChartList);
        }

        return;
      } catch (err) {
        logger.print('error', 'store.proposal.actions.getProposalsRevenueData', err);
        throw err;
      }
    },

    // TODO: @to-graphql
    async getProposalsSummary({ commit }, payload): Promise<void> {
      try {
        commit('SET_PROPOSAL_SUMMARY_LOADING', true);
        const { isErr, unwrap, unwrapErr } = await proposalService.getProposalsSummary(payload.from, payload.to);

        if (isErr()) {
          const { message } = unwrapErr();
          logger.print('error', 'proposal.getProposalsSummary', message);
          commit('SET_PROPOSAL_SUMMARY_LOADING', false);
          return;
        }

        const response = unwrap();

        if (response && 'SummaryList' in response) {
          // TODO: It is better to have empty result from backend if no data exist
          let isSummaryListHasData = false;
          const preparedSummaryList = [];

          response.SummaryList.forEach(s => {
            const { signedCost, signedCount, totalCost, totalCount, month } = s;
            const stat = { signedCost, signedCount, totalCost, totalCount };
            preparedSummaryList.push({
              month: month,
              signedCost: signedCost,
              signedCount: signedCount,
              totalCost: totalCost,
              totalCount: totalCount,
            });
            isSummaryListHasData =
              isSummaryListHasData || Object.entries(stat).every(([, value]) => Boolean(value) || value === 0);
          });

          commit('SET_PROPOSAL_SUMMARY', isSummaryListHasData ? preparedSummaryList : []);
        }

        commit('SET_PROPOSAL_SUMMARY_LOADING', false);

        return;
      } catch (err) {
        commit('SET_PROPOSAL_SUMMARY_LOADING', false);
        logger.print('error', 'store.proposal.actions.getProposalsSummary', err);
        throw new Error('no data');
      }
    },

    async getShareOutputContext(
      { commit },
      payload: { proposalId: string },
    ): Promise<GetSharedProposalOutputQuery['getPublicProposalOutput'] | ErrorResponse | Failure> {
      try {
        const { isErr, unwrap, unwrapErr } = await proposalService.getShareOutputContext(payload);

        if (isErr()) {
          throw new Error(unwrapErr().message);
        }
        const response = unwrap();

        if (!response) {
          throw new Error('empty data');
        }
        if ('message' in response || 'error' in response) {
          commit('output/SET_ACTIVE_OUTPUT', { slides: [] }, { root: true });
          return response;
        }

        commit('output/SET_ACTIVE_OUTPUT', { slides: response?.slides || [] }, { root: true });
        commit('output/SET_LAYOUT_COLORS', response?.colorScheme, { root: true });
        commit('output/SET_LOCAL_LAYOUT_COLORS', response?.colorScheme, { root: true });
        return response;
      } catch (err) {
        logger.print('error', 'store.proposal.actions.getShareOutputContext', err);
        throw new Error('no data');
      }
    },

    setLoadingProposals({ commit }): void {
      commit('LOADING_ALL_PROPOSALS', true);
    },
  } as ActionTree<ProposalState, UnsafeAny>);

import 'reflect-metadata';
import { add, sub, addMonths, format, differenceInMonths, differenceInCalendarDays } from 'date-fns';
import { CalendarServiceContract } from '@/injectables';
import { injectable } from 'inversify';

@injectable()
export class CalendarService implements CalendarServiceContract {
  dateAfter(days: number, from: Date): Date {
    return add(from, { days });
  }

  yearAfter(years: number, from: Date): Date {
    return add(from, { years });
  }

  dateBefore(days: number, from: Date): Date {
    return sub(from, { days });
  }

  dateFormatter(date: Date) {
    const d = new Date(date);
    return format(d, 'MMM dd, yyyy');
  }

  getFirstDayOfNextMonth(date: Date | string) {
    const d = new Date(date);
    return new Date(d.getFullYear(), d.getMonth() + 1, 1);
  }

  getNumOfDaysInMonth(year: number, month: number) {
    return new Date(year, month, 0).getDate();
  }

  addMonthsToDate(date: Date | string, numOfMonths: number) {
    const d = new Date(date);
    return addMonths(d, numOfMonths);
  }

  dateWithTimezoneOffset(date: Date): Date {
    return new Date(date.getTime() - date.getTimezoneOffset() * 60000);
  }

  getDifferenceInMonths(first: Date, second: Date): number {
    return differenceInMonths(first, second);
  }

  getDifferenceInCalendarDays(first: Date, second: Date): number {
    return differenceInCalendarDays(first, second);
  }
}

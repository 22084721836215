
import Vue from 'vue';

export default Vue.extend({
  name: 'BulkZipList',
  props: {
    itemIcon: {
      type: String,
      default: 'mdi-checkbox-marked-circle',
    },
    iconColor: {
      type: String,
      default: 'success',
    },
    typeLabel: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    allNumber: {
      type: Number,
      required: true,
    },
  },
  data: (): {
    file: File;
    parsing: boolean;
  } => ({
    file: null,
    parsing: false,
  }),
  methods: {
    shortSting(zip) {
      const maxLength = 7;
      return zip.length > maxLength ? zip.slice(0, maxLength) + '...' : zip;
    },
  },
});

import { Proposal, ProposalRevenueData, ProposalSummary } from '@/shared/types';

export type AdminQueueProposal = EndingCampaign;

export interface EndingCampaign {
  CampaignEndDate?: string;
  CampaignStartDate?: string;
  CreatedBy: string;
  DataSourceCode: string;
  DateEntered: string;
  LastModifiedBy: string;
  LastModifiedDate: string;
  LastModifiedPitchDate: string;
  LastModifiedPitchUser: string;
  PRDSC?: string;
  PRDSCID?: string;
  PRPID: string;
  PropertyId: string;
  Version: string;
  budget: number;
  clientLogo: {
    PitchLogoHeight: number;
    PitchLogoWidth: number;
    avtr: string;
    logo: string;
  };
  clientName: string;
  name: string;
  proposalId: string;
  pstate: string;
  status: string;
}

export interface ProposalState {
  activeProposalId: string | null;
  activeProposal: Proposal;
  loadingProposals: boolean;
  loadingEndingCampaigns: boolean;
  loadingProposalsQueue: boolean;
  loadingAllProposals: boolean;
  loadingStatuses: boolean;
  proposalsInitialized: boolean;
  proposalError: {
    status: boolean;
    message?: string;
  };
  allProposals: {
    loaded: boolean;
    list?: Proposal[];
    total: number;
  };
  endingCampaigns: {
    list: EndingCampaign[];
    loaded: boolean;
  };
  adminQueue: {
    list: EndingCampaign[];
    loaded: boolean;
  };
  // by client
  proposals: {
    loaded: boolean;
    total?: number;
    list?: Proposal[];
    PRPID?: string;
  };
  proposalsSummaryByClient: ProposalRevenueData[];
  proposalsSummaryByClientLoading: boolean;
  statuses: { [key: string]: string } | null;
  statusesLoading: boolean;
  cloneProposalLoading: boolean;
  saveProgressLoading: boolean;
  activeProducts?: ProposalRevenueData[];
  proposalsSummary?: ProposalSummary[];
  proposalsSummaryLoading?: boolean;
  filteredProposals: Proposal[];
}

export const state = (): ProposalState => ({
  loadingStatuses: false,
  cloneProposalLoading: false,
  proposalsSummaryByClient: [],
  proposalsSummaryByClientLoading: false,
  proposalsInitialized: false,
  activeProposalId: null,
  activeProposal: null,
  proposals: { loaded: false },
  allProposals: { loaded: false, total: 0 },
  loadingProposals: false,
  loadingEndingCampaigns: false,
  loadingProposalsQueue: false,
  loadingAllProposals: false,
  proposalError: { status: false },
  statuses: null,
  statusesLoading: false,
  saveProgressLoading: false,
  activeProducts: [],
  proposalsSummary: [],
  proposalsSummaryLoading: false,
  endingCampaigns: {
    list: [],
    loaded: false,
  },
  adminQueue: {
    list: [],
    loaded: false,
  },
  filteredProposals: [],
});


import Vue from 'vue';
import { debounce } from 'lodash';
import { Models, Services } from '@/injectables/tokens';
import { InstantIOServiceContract, RouterModelContract } from '@/injectables';
import InstantIoTable from '@/widgets/instant-io/ui/table/instant-io-table.vue';
import CampaignsTableFilters from '@/components/Campaigns/CampaignsTableFilters.vue';
import InstantIODialog from '@/pages/instant-io/instant-io-dialog.vue';

export default Vue.extend({
  name: 'ClientInstantIOs',

  components: { InstantIODialog, InstantIoTable, CampaignsTableFilters },

  props: {
    isByClient: {
      type: Boolean,
      default: false,
    },
  },

  useInjectable: [Services.Proposal, Services.InstantIO, Models.Router],

  data: (): {
    clientId: string | null;
    clientName: string | null;
    searchTerm: string;
    campaigns;
    loading: boolean;
    total: number;
    options: {
      page: number;
      itemsPerPage: number;
      sortBy: string;
      sortDesc: boolean;
    };
    filters: {
      status: string;
      dates: {
        dates: Record<'start' | 'end' | 'modified', { from: string; to: string; range: string[] }>;
      };
    };
    isDialogOpen: boolean;
    lastOpenTime: number;
    instantIoId: string;
  } => ({
    clientId: null,
    clientName: null,
    searchTerm: '',
    campaigns: [],
    loading: false,
    total: 0,
    options: {
      page: 1,
      itemsPerPage: 15,
      sortBy: 'updatedAgo',
      sortDesc: true,
    },
    filters: {
      status: '',
      dates: {
        dates: {
          start: {
            from: '',
            to: '',
            range: [],
          },
          end: {
            from: '',
            to: '',
            range: [],
          },
          modified: {
            from: '',
            to: '',
            range: [],
          },
        },
      },
    },
    isDialogOpen: false,
    lastOpenTime: Date.now(),
    instantIoId: '',
  }),

  watch: {
    filters: {
      handler() {
        this.setFirstPage();
        this.getInstantIOs();
      },
      deep: true,
    },
    searchTerm() {
      this.setFirstPage();
      this.getInstantIOs();
    },
  },

  beforeRouteEnter(to, _, next): void {
    next(async vm => {
      await vm.$store.dispatch('client/updateActiveClientId', to.params.id);
    });
  },

  computed: {
    client() {
      return this.$store.getters['client/activeClient'];
    },
    clientDailySpend(): number {
      return this.client?.BuzzboardRecommendedDailyBudget || 0;
    },
    statuses(): { [key: string]: string } {
      if (this.$store.state.proposal.statuses) {
        return this.$store.state.proposal.statuses;
      }
      return {};
    },
    itemsPerPage: {
      get(): number {
        const routerEntity = this.$container.get(Models.Router);
        return (routerEntity as RouterModelContract).getValidItemPerPage(this.$route.query.itemsPerPage);
      },
      set(itemsPerPage: number) {
        this.updateRoute({ itemsPerPage });
      },
    },
    skippedRows() {
      return (this.options.page - 1) * this.options.itemsPerPage;
    },
  },

  methods: {
    closeDialog() {
      this.isDialogOpen = false;
      this.instantIoId = '';
    },
    async deleteInstantIo(id: string): Promise<void> {
      const { isErr } = await this[Services.InstantIO].deleteInstantIo(id);
      if (isErr()) {
        return;
      }

      this.getInstantIOs();
    },
    async duplicateInstantIo(id: string): Promise<void> {
      this.duplicationLoading = true;
      const { unwrap, isErr } = await (this[Services.InstantIO] as InstantIOServiceContract).duplicateInstantIo(id);
      this.duplicationLoading = false;

      if (isErr()) {
        return;
      }

      const { id: newId } = unwrap();
      this.openEditInstantIoDialog(newId);
      this.getInstantIOs();
    },
    openEditInstantIoDialog(id: string): void {
      this.instantIoId = id;
      this.$nextTick(() => {
        this.showCreateModal();
      });
    },
    showCreateModal(): void {
      this.isDialogOpen = true;
      this.lastOpenTime = Date.now();
    },
    updateFilters(filters) {
      this.filters = filters;
    },
    updateSearch(searchTerm) {
      this.searchTerm = searchTerm;
    },
    async changePage(page) {
      if (this.options.page === page) return;

      this.options.page = page;
      await this.getInstantIOs();
    },
    async changeCountOfItems(itemsPerPage) {
      if (this.options.itemsPerPage === itemsPerPage) return;
      this.options.itemsPerPage = itemsPerPage;
      this.setFirstPage();

      await this.getInstantIOs();
    },
    async updateSort(sortBy) {
      if (this.options.sortBy === sortBy) return;
      this.options.sortBy = sortBy;
      this.setFirstPage();

      await this.getInstantIOs();
    },
    async updateSortType(desc) {
      if (this.options.sortDesc === desc) return;
      this.options.sortDesc = desc;
      this.setFirstPage();
      await this.getInstantIOs();
    },
    getInstantIOs: debounce(async function ({
      limit = this.options.itemsPerPage,
      offset = this.skippedRows,
      sortBy = this.options.sortBy,
      desc = this.options.sortDesc,
      searchTerm = this.searchTerm,
      filters = this.filters,
    } = {}) {
      this.loading = true;

      const clientId = this.$store.getters['client/activeClient']?.id || null;

      const { unwrap, isErr } = await this[Services.InstantIO].getInstantCampaigns({
        limit,
        offset,
        sortBy,
        desc,
        searchTerm,
        filters,
        clientId,
      });

      if (isErr()) {
        this.campaigns = [];
        this.total = 0;
        this.loading = false;
        return;
      }
      const { list, total } = unwrap();
      this.campaigns = list.map(item => {
        delete item.client;
        return item;
      });
      this.total = total;
      this.loading = false;
      return;
    },
    300),
    setFirstPage() {
      this.options.page = 1;
    },
  },

  mounted(): void {
    this.getInstantIOs();
  },
});


import Vue from 'vue';
import { EditorContent, Editor, mergeAttributes, JSONContent, Extension } from '@tiptap/vue-2';
import StarterKit from '@tiptap/starter-kit';
import { Selections } from '../../store/outputTextEditor';
import Underline from '@tiptap/extension-underline';
import TextStyle from '@tiptap/extension-text-style';
import FontFamily from '@tiptap/extension-font-family';
import { Color } from '@tiptap/extension-color';
import BulletList from '@tiptap/extension-bullet-list';
import ListItem from '@tiptap/extension-list-item';
import Link from '@tiptap/extension-link';
import Highlight from '@tiptap/extension-highlight';
import Table from '@tiptap/extension-table';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';

const CustomExtension = Extension.create({
  addCommands() {
    return {
      ...this.parent?.(),
      setOutputProps:
        ({ bold, fontFamily, color }) =>
        ({ chain }) => {
          if (!bold && !fontFamily && !color) {
            return;
          }

          let x = chain().selectParentNode();

          if (color) {
            x = x.setColor(color);
          }
          if (bold) {
            x = x.setBold();
          }
          if (fontFamily) {
            chain().selectAll().setFontFamily(fontFamily).run();
          }

          return x.blur().run();
        },
    };
  },
});

export default Vue.extend({
  name: 'TextEditor',
  components: {
    EditorContent,
  },
  props: {
    element: {
      type: Object,
      required: true,
    },
    baseElement: {
      type: Object,
      required: true,
    },
  },
  data: (): {
    initialValue: string;
    editor: Editor | null;
  } => ({
    editor: null,
    initialValue: '',
  }),

  watch: {
    modifiedProp(payload) {
      const isDynamicLink = this.element.dynamicLink;
      const preCondCommand = isDynamicLink ? this.editor.chain().selectAll() : this.editor.chain().focus();
      if (payload.prop === 'bold') {
        preCondCommand.toggleBold().run();
      }

      if (payload.prop === 'underline') {
        preCondCommand.toggleUnderline().run();
      }

      if (payload.prop === 'strike') {
        preCondCommand.toggleStrike().run();
      }

      if (payload.prop === 'italic') {
        preCondCommand.toggleItalic().run();
      }

      if (payload.prop === 'fontFamily') {
        preCondCommand.setFontFamily(`${payload.value}`).run();
      }

      if (payload.prop === 'color') {
        preCondCommand.setColor(`${payload.value}`).run();
      }

      if (payload.prop === 'bullets') {
        this.editor.chain().focus().toggleBulletList().run();
      }

      if (payload.prop === 'list') {
        this.editor.chain().focus().toggleOrderedList().run();
      }

      if (payload.prop === 'link') {
        this.editor
          .chain()
          .focus()
          .unsetHighlight()
          .extendMarkRange('link')
          .toggleLink({ href: payload.value, target: '_blank' })
          .run();
      }

      if (payload.prop === 'setColorOfSelectedText') {
        this.editor.chain().focus().toggleHighlight({ color: this.$vuetify.theme.themes.light.selectedText }).run();
      }

      if (payload.prop === 'unsetColorOfSelectedText') {
        this.editor.chain().focus().unsetHighlight().run();
      }

      if (payload.prop === 'addColumnAfter') {
        this.editor.chain().focus().addColumnAfter().run();
      }

      if (payload.prop === 'addColumnBefore') {
        this.editor.chain().focus().addColumnBefore().run();
      }

      if (payload.prop === 'addRowAfter') {
        this.editor.chain().focus().addRowAfter().run();
      }

      if (payload.prop === 'addRowBefore') {
        this.editor.chain().focus().addRowBefore().run();
      }

      if (payload.prop === 'deleteColumn') {
        this.editor.chain().focus().deleteColumn().run();
      }

      if (payload.prop === 'deleteRow') {
        this.editor.chain().focus().deleteRow().run();
      }

      if (payload.prop === 'deleteTable') {
        this.$emit('delete-table-element');
      }

      if (payload.prop === 'toggleHeaderCell') {
        this.editor.chain().focus().toggleHeaderCell().run();
      }

      if (payload.prop === 'tableFillColor') {
        this.editor.chain().focus().setCellAttribute('backgroundColor', payload.value).run();
      }
    },
  },

  mounted() {
    if (this.element.value && !this.element.htmlValue) {
      this.$emit('update-text-value', { html: this.element.value });
    }

    const CustomTableCell = TableCell.extend({
      addAttributes() {
        return {
          ...this.parent?.(),
          backgroundColor: {
            default: null,
            parseHTML: element => element.getAttribute('data-background-color'),
            renderHTML: attributes => {
              return {
                'data-background-color': attributes.backgroundColor,
                style: `background-color: ${attributes.backgroundColor}`,
              };
            },
          },
        };
      },
    });

    const CustomTableHeader = TableHeader.extend({
      // workaround for issue with cell width in raw html
      addAttributes() {
        return {
          ...this.parent?.(),
          colwidth: {
            default: null,
            parseHTML: element => {
              const colwidth = element.getAttribute('colwidth');
              const value = colwidth ? colwidth.split(',').map(item => parseInt(item, 10)) : null;

              return value;
            },
          },
          style: {
            default: null,
          },
        };
      },
      renderHTML({ HTMLAttributes }) {
        let totalWidth = 0;
        let fixedWidth = true;

        if (HTMLAttributes.colwidth) {
          HTMLAttributes.colwidth.forEach(col => {
            if (!col) {
              fixedWidth = false;
            } else {
              totalWidth += col;
            }
          });
        } else {
          fixedWidth = false;
        }

        if (fixedWidth && totalWidth > 0) {
          HTMLAttributes.style = `width: ${totalWidth}px;`;
        } else if (totalWidth && totalWidth > 0) {
          HTMLAttributes.style = `min-width: ${totalWidth}px`;
        } else {
          HTMLAttributes.style = null;
        }

        return ['th', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
      },
    });

    this.editor = new Editor({
      parseOptions: {
        preserveWhitespace: 'full',
      },
      enablePasteRules: false,
      injectCSS: false,
      enableInputRules: false,
      extensions: [
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        StarterKit as any,
        Underline,
        TextStyle,
        FontFamily,
        Color,
        BulletList,
        ListItem,
        Highlight.configure({ multicolor: true }),
        Link.configure({
          openOnClick: false,
        }),
        Table.configure({
          resizable: true,
        }),
        TableRow,
        CustomTableHeader,
        CustomTableCell,
        CustomExtension,
      ],
      content: this.element.htmlValue || this.element.value,
    });

    this.editor.on('selectionUpdate', ({ editor }) => {
      const { backgroundColor } = editor.getAttributes('tableCell');
      const { color: editorColor } = editor.getAttributes('textStyle');
      const { from, to, empty } = editor.state.selection;

      this.$store.dispatch('outputTextEditor/setCurrentSelections', {
        isBold: editor.isActive('bold'),
        isStrike: editor.isActive('strike'),
        isUnderline: editor.isActive('underline'),
        isItalic: editor.isActive('italic'),
        isBullet: editor.isActive('bulletList'),
        isList: editor.isActive('orderedList'),
        link: editor.isActive('link') ? editor.getAttributes('link').href : '',
        selectedText: !empty ? editor.state.doc.textBetween(from, to, ' ') : '',
        selectedTextColor: editorColor,
        selectedCellColor: backgroundColor,
      });
    });
    this.editor.on('update', ({ editor }) => {
      this.$emit('update-text-value', this.getOutput(this.editor));

      const { backgroundColor } = editor.getAttributes('tableCell');
      const { color: editorColor } = editor.getAttributes('textStyle');
      const { from, to, empty } = editor.state.selection;

      this.$store.dispatch('outputTextEditor/setCurrentSelections', {
        isBold: editor.isActive('bold'),
        isStrike: editor.isActive('strike'),
        isUnderline: editor.isActive('underline'),
        isItalic: editor.isActive('italic'),
        isBullet: editor.isActive('bulletList'),
        isList: editor.isActive('orderedList'),
        link: editor.isActive('link') ? editor.getAttributes('link').href : '',
        selectedText: !empty ? editor.state.doc.textBetween(from, to, ' ') : '',
        selectedTextColor: editorColor,
        selectedCellColor: backgroundColor,
      });
    });
    this.editor.on('create', ({ editor }) => {
      const { color = null, fontFamily = null, bold = null } = this.baseElement.props;

      editor.commands.setOutputProps({
        ...(color ? { color } : {}),
        fontFamily,
        bold: bold && bold !== 'lighter' && bold !== 'normal',
      });

      this.$emit('clear-default-props', { color, fontFamily, bold });
    });
  },

  beforeDestroy() {
    this.$emit('update-text-value', this.getOutput(this.editor));
    this.editor.destroy();
  },

  computed: {
    modifiedProp(): Selections {
      return this.$store.getters['outputTextEditor/getModifiedProp'];
    },
    preventTextValueEdits(): boolean {
      return this.baseElement?.proposalUpdated || this.baseElement.hasOwnProperty('dynamicLink');
    },
    disabledInputClass(): string {
      if (this.baseElement.props.table) return 'disable-input';
      return '';
    },
  },

  methods: {
    handleKeydown(e: KeyboardEvent): void {
      if (this.preventTextValueEdits) {
        e.preventDefault();
        if (e.key === 'Enter') this.editor.commands.undo();
      }
    },
    getOutput(editor: Editor): { html: string; json: JSONContent } {
      const html = editor.getHTML();
      const json = editor.getJSON();
      return { html, json };
    },
  },
});

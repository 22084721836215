
import Vue from 'vue';

import StatusIcon from './StatusIcon.vue';
export default Vue.extend({
  name: 'ProductStatus',

  components: { StatusIcon },

  props: {
    status: {
      type: Boolean,
      default: false,
    },
  },
});

import commonPkg from '@/shared/legacy/isomorphic';
import { injectable } from 'inversify';

import { ValidationServiceContract, FromToValidationArgument, RegExpCollection } from '@/injectables';

const { datesHelpers } = commonPkg;

@injectable()
export class ValidationService implements ValidationServiceContract {
  requiredValidatorFactory(
    messageProvider = () => 'Required field',
  ): (v: string | number | (string | number)[]) => string | boolean {
    return value => {
      if (value && Array.isArray(value)) {
        return (
          (value.length &&
            value.every(el => {
              if (typeof el === 'number') {
                return !isNaN(el);
              }
              return Boolean(el);
            })) ||
          messageProvider()
        );
      }

      if (typeof value === 'number') {
        return !isNaN(value) || messageProvider();
      }
      return Boolean(value) || messageProvider();
    };
  }

  isLargerValidatorFactory(than: number, messageProvider: () => string): (v: string | number) => string | boolean {
    return (value: string | number) => {
      let validating: number;
      if (typeof value === 'string') {
        validating = parseInt(value);
      } else {
        validating = value;
      }
      return validating >= than || messageProvider();
    };
  }

  isBetweenValidatorFactory(
    { from, to, messages }: FromToValidationArgument,
    formatter?: (v: string | number) => string,
  ) {
    const { min = 'Cannot be less than', max = 'Cannot be greater than' } = messages || {};
    return (value: string | number) => {
      const parsedValue = typeof value === 'string' ? parseInt(value, 10) : value;
      if (parsedValue < from) {
        return `${min} ${formatter ? formatter(from) : from}`;
      }
      if (parsedValue > to) {
        return `${max} ${formatter ? formatter(to) : to}`;
      }
      return true;
    };
  }

  requiredMultipleValidatorFactory(
    messageProvider = () => 'Must select at least one',
  ): (v: string[]) => string | boolean {
    return v => v.length > 0 || messageProvider();
  }

  minLengthValidatorFactory(
    minLength = 3,
    messageProvider = value => `Must have at least ${value} characters`,
  ): (value: string) => string | boolean {
    return value => !value || value.length >= minLength || messageProvider(minLength);
  }

  phoneMinLengthValidationFactory(minLength = 11): (value: string) => string | boolean {
    return value => !value || value.length >= minLength || `Full phone number required`;
  }

  maxLengthValidatorFactory(
    maxLength = 100,
    messageProvider = () => 'Exceeds maximum allowed characters',
  ): (value: string) => string | boolean {
    return value => !value || value.length <= maxLength || messageProvider();
  }

  emailValidatorFactory(
    messageProvider = () => 'Invalid e-mail',
    regExpCollection = new RegExpCollection(),
  ): (value: string) => string | boolean {
    const pattern = regExpCollection.email;
    return value => pattern.test(value) || messageProvider();
  }

  notRequiredEmailValidatorFactory(
    messageProvider = () => 'Invalid e-mail',
    regExpCollection = new RegExpCollection(),
  ): (value: string) => string | boolean {
    const pattern = regExpCollection.email;
    return value => !value || pattern.test(value) || messageProvider();
  }

  phoneValidatorFactory(
    messageProvider = () => 'Invalid phone',
    regExpCollection = new RegExpCollection(),
  ): (value: string) => string | boolean {
    const pattern = regExpCollection.phone;
    return value => pattern.test(value) || messageProvider();
  }

  minBudgetValidatorFactory(): (value: number | string, minBudget: number) => string | boolean {
    return (value, minBudget) => Number(value) >= minBudget || `Budget must be at least ${minBudget}$`;
  }

  minRangeValidatorFactory(): (range: string, minDays: number) => string | boolean {
    return (range, minDays) => {
      if (!range) return 'Invalid date range';
      const rangeArray = range.split('-');
      let start, end;
      if (rangeArray.length === 4) {
        [start, , end] = rangeArray;
      } else {
        [start, end] = rangeArray;
      }
      const currentRange = datesHelpers.getDifferenceInCalendarDays(new Date(end), new Date(start));
      return currentRange + 1 >= minDays || `Range must be at least ${minDays} days`;
    };
  }

  isNumberValidatorFactory(
    messageProvider = () => 'Must be a number',
    regExpCollection = new RegExpCollection(),
  ): (value: string) => string | boolean {
    const pattern = regExpCollection.digits;

    return value => !value || pattern.test(value) || messageProvider();
  }

  isFloatValidatorFactory(messageProvider = () => 'Must be a number'): (value: string) => string | boolean {
    return value => {
      const numberValue = Number(value);
      return (numberValue > 0 && !isNaN(numberValue)) || messageProvider();
    };
  }

  isDomainValidatorFactory(
    messageProvider = () => 'Must be a valid domain',
    regExpCollection = new RegExpCollection(),
  ): (value: string) => string | boolean {
    const pattern = regExpCollection.domain;

    return value => !value || pattern.test(value) || messageProvider();
  }

  isStringLink(str: string): boolean {
    const ruleOfLink = new RegExp(/(www|http:|https:)+[^\s]+[\w]/);
    return ruleOfLink.test(str);
  }

  isAlphabetic(
    messageProvider = () => 'Must be letters',
    regExpCollection = new RegExpCollection(),
  ): (value: string) => string | boolean {
    const pattern = regExpCollection.alphabetic;

    return value => !value || pattern.test(value) || messageProvider();
  }
}

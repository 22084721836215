export type Tooltip = {
  title: string;
  message: string;
  key?: string;
};

const METRIC_TOOLTIPS: { [key: string]: Tooltip } = {
  AUDIENCE: {
    title: 'Audience',
    message: 'The number of recipients who were sent this ad via email.',
  },
  AIRED: {
    title: 'Aired',
    message: 'Number of times ad appeared on TV.',
  },
  AVG_SESSION_DURATION: {
    title: 'Avg. Session Duration',
    message:
      'The average amount of time a user spends on a website = Total time across all sessions / Number of sessions.',
  },
  AVG_DURATION: {
    title: 'Avg. Duration',
    message:
      'The average number of minutes and seconds of each Call conducted = Total number of minutes and seconds / Calls.',
  },
  BOUNCE_RATE: {
    title: 'Bounce Rate',
    message: 'Percentage of visits to your website where only 1 page was viewed',
  },
  COMPLETION_RATE: {
    title: 'VCR (Video Completion Rate)',
    message:
      'Percentage of campaign impressions that are viewed to completion. If 10 impressions are served, and 9 are viewed to completion, VCR is 90%. A campaign is not likely ever going to reach 100%. A VCR of at least 90% is good.',
    key: 'completionrate',
  },
  OTT_COMPLETION_RATE: {
    title: 'VCR (Video Completion Rate) for OTT/CTV',
    message:
      'Percentage of campaign impressions that are viewed to completion. If 10 impressions are served, and 9 are viewed to completion, VCR is 90%. A campaign is not likely ever going to reach 100%. A VCR of at least 85% is good.',
    key: 'completionrate',
  },
  DVIDEO_COMPLETION_RATE: {
    title: 'VCR (Video Completion Rate) for Digital Video',
    message:
      'Percentage of campaign impressions that are viewed to completion. If 10 impressions are served, and 8 are viewed to completion, VCR is 80%. A campaign is not likely ever going to reach 100%. A VCR of at least 75% is good.',
    key: 'completionrate',
  },
  CTR: {
    title: 'CTR (Click Through Rate)',
    message:
      'Percentage of times a user clicked on an ad (clicks/impressions). For Display, the industry average is 0.05% to 0.10%. For Preroll, industry average is 0.10% to 0.30%.',
  },
  CLICKS: {
    title: 'Clicks',
    message: 'The number of times an ad was clicked on.',
  },
  CALLS: {
    title: 'Calls',
    message: 'The number of calls made to potential customers.',
  },
  CONVERSIONS: {
    title: 'Conversions',
    message:
      'A conversion is the point at which a recipient of a marketing message performs a desired action, such as opening an email message, going to a landing page and filling out a registration form or buying the intended product in the ad.',
  },
  CPC: {
    title: 'CPC (Cost Per Click)',
    message:
      'Cost per Click, represented in Dollars = Dollars spent on a campaign / number of clicks the campaign achieved.',
  },
  CPM: {
    title: 'CPM (Cost Per Thousand Impressions)',
    message:
      'Cost per Mille (Thousand), represented in Dollars = Dollars spent on a campaign / number of Impressions / 1,000.',
  },
  CVR: {
    title: 'Conversion Rate',
    message: `Conversion rate is Conversions divided by Clicks. A good conversion rate depends on your business type and
    campaign goals. To improve your conversion rate, you should consider both the messaging in your ads as well as how well your website or landing page drives users towards a conversion.`,
  },
  CPV: {
    title: 'Average CPV',
    message:
      "Average cost-per-view (CPV) is the average amount an advertiser pays for a view of their video ad. CPV fluctuates based on ad length, creative quality, targeting, and auction dynamics among other factors. It's calculated as Spend divided by Views.",
  },
  DAYPART: {
    title: 'Dayparts',
    message:
      '<ul><li>Morning: 4:00AM–9:00AM</li><li>MidDay: 9:00AM–2:00PM</li><li>Afternoon: 2:00PM–6:00PM</li><li>Night: 6:00PM–12:00AM</li><li>Overnight: 12:00AM–4:00AM</li></ul>',
  },
  FACEBOOK_PAGE_ENGAGEMENTS: {
    title: 'Page Engagements',
    message:
      'The total number of actions that people took on a Facebook Page and its posts, attributed to ads. Page engagement includes actions such as liking the FB Page, loving a post, checking in to a location, clicking a link, etc.',
  },
  FACEBOOK_VIDEO_VIEWS: {
    title: 'Video Views',
    message:
      'The number of times people watched your video ad on Facebook for at least 2 continuous seconds, or 15 seconds or longer',
  },
  FACEBOOK_PAGE_FOLLOWERS: {
    title: 'Page Followers',
    message: 'The number of people who FOLLOW a Facebook Page.',
  },
  FACEBOOK_PAGE_LIKES: {
    title: 'Page Likes',
    message: 'The number of times the LIKE button was clicked for a Facebook Page',
  },
  FACEBOOK_PAGE_VIEWS: {
    title: 'Page Views',
    message: `Page views are the number of times a Page's profile has been viewed by people, including people who are logged into Facebook and those who aren't.`,
  },
  FACEBOOK_ACTIONS_ON_PAGE: {
    title: 'Actions On Page',
    message:
      `Number of actions that people do once they’ve landed on a Facebook Page. Actions are activities that occur on your ad and may include link clicks, outbound clicks, post engagement and video views. ` +
      `Actions that happen as a result of your ad include website conversions, website leads, mobile app installs, mobile app purchases and offline conversions.`,
  },
  FACEBOOK_POST_ENGAGEMENTS: {
    title: 'Post Engagements',
    message:
      'The total number of actions that people took on a Facebook post, attributed to ads. Includes actions such as liking the FB Page, loving a post, checking in to a location, clicking a link, etc.',
  },
  FACEBOOK_CLICKS: {
    title: 'Clicks',
    message:
      'Clicks (all) includes Link Clicks plus clicks on other parts of an ad, including clicks on post likes, comments or shares, clicks to a Facebook Page or Instagram profile and clicks to expand a photo or video to full screen. Clicks will be equal to or greater than Link Clicks.',
  },
  FACEBOOK_LINK_CLICKS: {
    title: 'Link Clicks',
    message:
      'The number of clicks on links within the ad that led to advertiser-specified destinations, on or off Facebook.',
  },
  FACEBOOK_CTR: {
    title: 'CTR (Click Through Rate)',
    message: 'Percentage of times a user clicked on an ad (clicks/impressions).',
  },
  FACEBOOK_LINK_CTR: {
    title: 'Link CTR (Click Through Rate)',
    message: 'The number of clicks on links within the ad divided by Impressions.',
  },
  FACEBOOK_UNIQUE_CLICKS: {
    title: 'Total Unique Clicks',
    message: 'The total number of people who performed a click (all). This metric counts people, not actions.',
  },
  FACEBOOK_POST_REACH: {
    title: 'Post Reach',
    message: 'Number of people who saw your post at least once.',
  },
  FREQUENCY: {
    title: 'Frequency',
    message:
      'The number of times a unique user within a target demographic sees a marketing message within the campaign period.',
  },
  GOAL: {
    title: 'Goal',
    message: 'The target number of Impressions.',
  },
  HOURS: {
    title: 'Hours',
    message: 'The total number of Hours that a video creative was watched.',
  },
  HUNDRED_PERCENT_VIEWED: {
    title: '100% Viewed',
    message: 'The number of video ad Impressions which were viewed in their entirety.',
  },
  IMPRESSIONS: { title: 'Impressions', message: 'The number of times your ads were on screen.' },
  IMPS_ALL_TIME: {
    title: 'Impressions All Time',
    message: 'The number of times an ad was presented to a user over the life of this campaign.',
  },
  IMPRESSIONS_PERCENT: {
    title: 'Impressions %',
    message:
      'Percentage of the Total Impressions. It is calculated as Impressions divided by Total Impressions. Same as “% of Total”.',
  },
  IMPRESSIONS_GOAL: {
    title: 'Impressions Goal',
    message: 'The target number of Impressions',
  },
  PACING: {
    title: 'Pacing',
    message: `Refers to how on track your campaign is to meet its goal by the time the campaign ends where 0% means perfectly on track. A positive value means you are pacing ahead of your goal. A negative value means you are pacing behind your goal. The value also indicates
    how much you will beat or miss your goal by if your performance continues as is. If this campaign is pacing below -20%, check if the campaign has been paused or ended.`,
  },
  PERCENT_OF_GOAL: {
    title: '% of Goal',
    message: `Percentage of the Goal that was fulfilled. It is calculated as Impressions divided by Goal.`,
  },
  PAGE_VIEWS: {
    title: 'Page Views (Pageviews)',
    message:
      'Number of Page Views for users who visited website, reported by Google Analytics. A pageview (or pageview hit, page tracking hit) is an instance of a page being loaded (or reloaded) in a browser. Pageviews is the total number of pages viewed.',
  },
  VIEW_RATE: {
    title: 'View Rate',
    message:
      'View rate is the total number of views of your video ad divided by impressions. The view rate is a good indicator of how compelling viewers find your video. The higher the view rate, the more engaged viewers are with your content.',
  },
  PAGES_PER_SESSION: {
    title: 'Pages Per Session',
    message:
      'Average number of pages a person views in a given session, reported by Google Analytics. This metric is calculated by dividing the number of pageviews by the total number of sessions.',
  },
  PERCENT_OF_TOTAL: {
    title: '% of Goal',
    message: 'Percentage of the Goal that was fulfilled = Impressions / Goal.',
  },
  LIFT_SPOT: {
    title: 'Lift per Spot',
    message:
      'The number of incremental increase in website visits or sales / the number of 15s, 30s and 60s ad spots that aired on TV',
  },
  LIFT_VISITS: {
    title: 'Lift Visits',
    message: 'The number of incremental increase in website visits due to an ad campaign.',
  },
  NEW_SESSIONS: {
    title: 'New Sessions',
    message: 'Number of Sessions by Users who had never visited the website before, reported by Google Analytics.',
  },
  NEW_SESSIONS_PERCENTAGE: {
    title: 'New Sessions',
    message: 'Percentage of Sessions by Users who had never visited the website before, reported by Google Analytics.',
  },
  OTT: {
    title: 'OTT (Over the Top)',
    message: 'Content delivered to viewers via streaming and on-demand video services.',
  },
  REACH: {
    title: 'Reach',
    message:
      'Number of people who saw your ads at least once. Reach is different from impressions, which may include multiple views of your ads by the same people.',
  },
  SEARCH_IMP_SHARE: {
    title: 'Search Impressions Share',
    message:
      'The percentage of impressions that an ad receives through Search compared to the estimated number of impressions that an ad could get.',
  },
  SEARCH_POSITION: {
    title: 'Position',
    message:
      'A relative ranking of the position of your link on Google, where 1 is the topmost position, 2 is the next position, and so on. See more details below.',
  },
  SESSIONS: {
    title: 'Sessions',
    message: 'Group of user interactions with your website that take place within a given time frame.',
  },
  SEM: {
    title: 'SEM (Search Engine Marketing)',
    message: 'Paid advertisements that appear in search engine results',
  },
  SPEND: {
    title: 'Spend',
    message: 'The dollar amount spent on a campaign.',
  },
  // GroundTruth
  ESTIMATED_VISITS: {
    title: 'Estimated Visits (Projected Store Visits)',
    message: `This is a probabilistic GroundTruth term. It applies a multiplier to the observed visits based on background data. For example, GT’s reach covers about
    40% of those with cellphones in the US. Assuming that there is another 60% unaccounted for, the multiplier uses data to fill in the gaps on visits that
    likely occurred due to their advertising, but that they were not able to track. This value is can be otherwise known as "Estimated Store Visits", rather than a projection.`,
  },
  STORE_VISITS: {
    title: 'Store Visits',
    message: `'Store Visits' only applies if this is a Foot Traffic Attribution campaign. In order to count a store visit a person must have been served a campaign ad previously, entered the customer store front, and was then served an additional ad while in that store.`,
  },
  FTA: {
    title: 'FTA',
    message: `Visits are recorded when a user is exposed to the mobile ad and later arrives within the blueprinted store location. This “raw” visitation metric is the actual observed measurement.
    Since the user must both see the ad and be on a mobile device when they visit the targeted location, visits reported will be lower than the actual visitors from ad spend.`,
  },
  GEO_FENCING_LOCATIONS: {
    title: 'Geo-Fencing Locations',
    message: `GeoFencing defines a set geographic perimeter around a location. Geo-fencing Locations are each of these locations. Oftentimes the output of this method is to prompt an
    advertisement based on location data signals derived through a mobile phone. These alerts are triggered when a mobile device enters the radius around a pre-established point of
    interest on a map and users have opted into the app’s location services. Geofencing is most often used in advertising to send an ad to a user through an app.`,
  },
  GEO_FENCING_LOCATIONS_SHORT: {
    title: 'GeoFencing Locations',
    message: `GeoFencing Locations defines a set geographic perimeter around a specific address.`,
  },
  GEO_FENCING_CITIES: {
    title: 'GeoFencing Cities',
    message: `Geofencing Cities defines a set geographic perimeter within a city.`,
  },
  GEOCOOKIE_IMPRESSIONS: {
    title: 'Historical Impressions',
    message: `'Historical Impressions' only applies if this is a Location Audience campaign. It represents the number of Impressions served to a targeted audience, based on visitations
    to select stores or a specific brand, or based on demographic information to form audience profiles which define consumer lifestyle and interests.`,
  },
  GEOBEHAVIOR_IMPRESSIONS: {
    title: 'Behavioral Impressions',
    message: `'Behavioral Impressions' only applies if this is a Behavior Audience campaign. It represents the number of Impressions served to a targeted audience, based on consumer visitation patterns.`,
  },
  GEORETARGETING_IMPRESSIONS: {
    title: 'Retargeting Impressions',
    message: `'Retargeting Impressions' only applies if this is a Retargeting campaign. It represents the number of Impressions served to a retargeted audience. Consumers that fall within the determined
    fence are captured. Once these users have received an ad, they’re targeted to receive a retargeting ad. This tactic is commonly deployed for event targeting. We would geofence the event, then retarget these users later.`,
  },
  GEOVIDEO_VCR: {
    title: 'GeoVideo VCR',
    message: `GeoVideo are location-aware video ads that are served to the right people at the right time and place. VCR (video completion rate) is the percentage of videos that were watched to completion.`,
  },
  GEOVIDEO_NODATA_FOOT_TRAFFIC: {
    title: 'No Data',
    message: `This module is blank, because this campaign is not targeted for Foot Traffic Attribution`,
  },
  GEOVIDEO_NODATA_AUDIENCE: {
    title: 'No Data',
    message: `This module is blank, because this campaign is not targeted for Location Audience, Behavior Audience or Retargeting Audience`,
  },
  GEOVIDEO_NODATA_GEO_FENCING: {
    title: 'No Data',
    message: `This module is blank, because this campaign has no geofencing locations data`,
  },
  GEOVIDEO_NODATA_PREVIOUS_GEO: {
    title: 'Possibly due to implementation date',
    message: `This module contains data only from April 2022 on, when the module was implemented`,
  },
  // End GroundTruth

  TACTIC: {
    title: 'Tactic',
    message: 'Also known as "Product" in the Dashboard, such as Display, Preroll, OTT, Email, Facebook, etc.',
  },
  TIMES_AIRED: {
    title: 'Times Aired',
    message:
      'Number of times a Broadcast ad was aired on TV. Airings with spot duration less than 4.0 seconds are not counted.',
  },
  USERS: {
    title: 'Users',
    message: 'Number of Users who visited Website, reported by Google Analytics.',
  },
  VC100: {
    title: 'Complete (Video Impressions)',
    message: 'Number of impressions where user completed video.',
  },
  VISITS: {
    // specific to broadcast
    title: 'Visits',
    message:
      `Number of visits tracked to the intended destination. This metric is only available if a Google Analytics account is connected to this Advertiser. ` +
      `We use Google Analytics Page Views (not Sessions/Visits), because Google Analytics can provide data for Page Views which occur within 8 minutes of the ad airing. ` +
      `Note that there could be individual days with “0” Visits, even if Google Analytics is connected, but you will see "0" for all days, if no Google Analytics account is connected.`,
  },
  VISITS_AIRING: {
    title: 'Visits Per Airing',
    message: 'Average number of visits for every time a broadcast ad was aired on TV.',
  },
  CLICK_TO_VIEW_RATE: {
    title: 'Click to View Rate',
    message: 'For Email Marketing campaigns, the percentage of times a user clicked on an ad (clicks/views).',
  },
  // YouTube / Google Video
  GOOGLEVIDEO_PUBLISHERS: {
    title: 'Publishers',
    message: 'Publishers are websites where the YouTube video ad appeared.',
  },
  GOOGLEVIDEO_ENGAGEMENTS: {
    title: 'Engagements',
    message:
      'Engagements show you the number of times users have watched your video ad for at least 10 seconds, or the complete video ad if it is shorter than 10 seconds.',
  },
  GOOGLEVIDEO_ENGAGEMENT_RATE: {
    title: 'Engagement Rate',
    message:
      'Engagement rate is the number of engagements that your ad receives divided by the number of times your ad is shown.',
  },
  GOOGLEVIDEO_100PERCENT_VIEWED: {
    title: '100% Viewed',
    message: 'The number of times your video was watched in its entirety.',
  },
  // Weblift
  WEBLIFT: {
    title: 'OTT Attribution',
    message:
      `Traffic to a website that is attributed to an ad being served, as measured by the Compulse Weblift Pixel. This graph shows data from all campaigns, so it appears ` +
      `on each campaign page. In the future, we'll be able to separate out each campaign.`,
  },
  WEBLIFT_UNIQUE_VISITS: {
    title: 'Unique Web Visits',
    message: 'Number of daily unique web visits whether the website visitor saw the ad or not.',
  },
  WEBLIFT_IMPRESSIONS: {
    title: 'Impressions',
    message: 'Number of times the ad was served.',
  },
  WEBLIFT_CONVERSIONS: {
    title: 'Conversions',
    message: 'Number of users who visited the website within 90 days of seeing the ad on a connected TV device.',
  },
  WEBLIFT_CUMULATIVE_IMPRESSIONS: {
    title: 'Impressions (cumulative)',
    message:
      "Number of times the ad was seen across campaigns, as measured by the Compulse Weblift Pixel. Currently, this metric is for all campaigns. In the future, we'll be able to separate out each campaign.",
  },
  WEBLIFT_CUMULATIVE_CONVERSIONS: {
    title: 'Conversions (cumulative)',
    message:
      "Number of users who visited the website within 30 days of seeing the ad on a connected TV device, as measured by the Compulse Weblift Pixel. Currently, this metric is for all campaigns. In the future, we'll be able to separate out each campaign.",
  },
  WEBLIFT_CUMULATIVE_CVR: {
    title: 'CVR (conversion rate)',
    message:
      "Ratio of Conversions to Impressions across campaigns, as measured by the Compulse Weblift Pixel. Currently, this metric is for all campaigns. In the future, we'll be able to separate out each campaign.",
  },
  // TVSquared
  TVSQUARED_IMPRESSIONS: {
    title: 'Impressions	',
    message: 'Number of times an ad was served',
  },
  TVSQUARED_RESPONSES: {
    title: 'Responses	',
    message: 'Number of times a user responded to an ad that was served to them',
  },
  TVSQUARED_RESPONSE_RATE: {
    title: 'Response Rate',
    message:
      'Percentage of Impressions that resulted in a Response (Responses / Impressions). A Response can be either an Appointment Request or when Contact Information was provided.',
  },
  TVSQUARED_APPOINTMENT_REQUEST_CONVERSION_RATE: {
    title: 'Appointment Request Conversion Rate',
    message:
      'Percentage of Impressions that resulted in a request for an appointment (Appointment Request Responses / Impressions)',
  },
  TVSQUARED_CONTACT_INFO_CONVERSION_RATE: {
    title: 'Contact Info Conversion Rate',
    message:
      'Percentage of Impressions that resulted in a user providing their contact information (Contact Info Responses / Impressions)',
  },
  TVSQUARED_IMPRESSION_SHARE: {
    title: 'Impression Share',
    message:
      'Percentage of Impressions served by a Creative compared to the total number of Impressions for all Creatives (Impressions / Total Impressions)',
  },
  TVSQUARED_RESPONSE_SHARE: {
    title: 'Response Share',
    message:
      'Percentage of Responses as a result of a Creative Impression compared to the total number of Responses as a result of all Creative Impressions (Responses / Total Responses)',
  },
  TVSQUARED_RESPONSES_OVER_TIME: {
    title: 'Responses Over Time',
    message: 'Number of days from the time the ad was served until the day the user responsed to the ad',
  },
  TVSQUARED_HOUSEHOLDS_REACHED: {
    title: 'Households Reached',
    message: 'Number of unique households which saw an ad',
  },
  TVSQUARED_AVERAGE_IMPRESSIONS_PER_HOUSEHOLD: {
    title: 'Average Impressions Per Household',
    message: 'Number of total Impressions served divided by the number of unique Households served',
  },
  // Display
  DISPLAY_PERF_BY_SITE_OTHER: {
    title: 'Other',
    message: 'Consolidation of all Sites beyond the Top {count}',
  },
  OTHER: {
    title: 'Other',
    message: 'Consolidation of records that could not be categorized',
  },
  MANAGED_NETWORK: {
    title: 'Managed Networks',
    message:
      'Uses a highly curated list of sites that index high with the desired audience. Examples: Mom network, Sports, Arts & Entertainment, Political and others.',
  },
  AUDIENCE_TARGETING: {
    title: 'Audience Targeting',
    message:
      'Serves ads utilizing 3rd party data sources to target specific audiences by behavior, demographic, interest and intent. Examples: Gender, Household Income, Health Enthusiasts, Parents and others.',
  },
  RETARGETING: {
    title: 'Retargeting',
    message:
      'Ads are delivered to your most valuable customers and prospects after they visit your website. Less than 2% of consumers convert on a first-time visit. Conversions can go up as much as 300% with retargeting.',
  },
  VIEWS: {
    title: 'Views',
    message:
      'Views show you the number of times people watched or engaged with your video ad. For campaigns with non-skippable ads, Views is always "0".',
  },
  EMAIL_VIEWS: {
    title: 'Views',
    message:
      'For Email Marketing campaigns, Views show you the number of times people engaged with your email message to generate impressions.',
  },
  LINEAR_OTHER_LEAGUE_TEAMS: {
    title: 'Other',
    message:
      'The "League" and "Team" names are listed for impressions served in live in-game broadcasts or pregame/postgame studio shows. Where "Other" appears, those impressions were served against ROS (run-of-site) units outside of the live broadcasts.',
  },
  // OTT/CTV and Digital Video
  OTTDVIDEO_REACH: {
    title: 'Reach',
    message:
      'Unique households reached based on CTV IP addresses for the lifetime of the campaign. It is not date-specific.',
  },
  OTTDVIDEO_FREQUENCY: {
    title: 'Frequency',
    message:
      'Average number of times the ad was shown to the same household for the lifetime of the campaign. It is not date-specific.',
  },
  // Digital Video
  VIDEO_IMPRESSIONS: {
    title: 'Video Impressions',
    message: 'Impressions only from online video, not from OTT devices.',
  },
  DVIDEO_CTR: {
    title: 'CTR (Click Through Rate)',
    message: 'CTR is Clicks divided by Video Impressions. It does not include OTT Impressions.',
  },
  DVIDEO_IMPRESSIONS: {
    title: 'Impressions',
    message:
      'Impressions for Digital Video is the total impressions for online video plus total impressions for OTT devices.',
  },
  // Summary
  LAST_UPDATED: {
    title: 'Last Updated',
    message: `The date and time when this tactic's data feed was last processed.<br />It doesn't necessarily mean that data for this campaign is complete.<br />Contact Support if you suspect that some data is missing.`,
  },
};

export default {
  METRIC_TOOLTIPS,
};

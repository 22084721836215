/* eslint-disable no-unused-vars */
import { MutationTree } from 'vuex';
import { NotificationsState } from './state';

export enum NotificationsMutations {
  SetNotifications = 'SET_NOTIFICATIONS',
}

export const mutations = (): MutationTree<NotificationsState> => {
  return {
    [NotificationsMutations.SetNotifications](state, notifications = []) {
      state.notifications = notifications;
    },
  };
};

import 'reflect-metadata';
import { OutputModelContract, SlideGroupType, SlideModelContract, ImageKeys } from '@/injectables';
import { OutputSlide, OutputText } from '@/shared/legacy/classes';
import { LogoWithSize } from '@/shared/types';
import { inject, injectable } from 'inversify';
import { SlideVisibility } from '@/app/graphql';
import { Models } from '@/injectables/tokens';

export interface CreateSlideOptions {
  colors: Record<string, string>;
  setFooter: boolean;
  productId: string;
  fromLibrary: boolean;
  fromLayout: boolean;
  selectedLayout: string;
  clientName: string;
  defaultSlide?: boolean;
  agencyLogo: LogoWithSize | null;
  slideGroup: SlideGroupType;
}

@injectable()
export class SlideModel implements SlideModelContract {
  @inject(Models.Output) private readonly _outputModel: OutputModelContract;

  createNewSlide({
    slide,
    index,
    options,
  }: {
    slide: OutputSlide | undefined;
    index: number;
    options: CreateSlideOptions;
  }): Omit<OutputSlide, 'id'> {
    const textItems = [];
    const images = [];
    if (options.setFooter) {
      textItems.push(this.createTodayMediaFooter(options));
    }

    images.push(this.createAgencyLogo(options.agencyLogo));

    const { slideGroup = SlideGroupType.Before } = options;

    let newSlide: Omit<OutputSlide, 'id'> = {
      _id: `s-${index}`,
      order: index,
      name: `Custom-${Date.now()}`,
      custom: true,
      colors: options.colors,
      images,
      textItems,
      shapes: [],
      layoutId: options.selectedLayout,
      visibility: SlideVisibility.Visible,
      ...(options.productId && { isProduct: true, linkedProduct: options.productId }),
    };
    if (slide) {
      const {
        colors = newSlide.colors,
        images = [],
        shapes = [],
        textItems = [],
        name,
        defaultSlide = null,
        layoutId = '',
        map = null,
        custom = name ? name?.includes('Custom') : true,
        isProduct = !!options.productId,
        linkedProduct = options.productId,
        slideVariable = '',
      } = slide;

      newSlide = {
        ...newSlide,
        colors,
        images,
        shapes,
        textItems,
        name: options.fromLayout
          ? `Custom-layout-${Date.now()}`
          : options.fromLibrary
          ? `lib_${name}_${Date.now()}`
          : name,
        fromLibrary: options.fromLibrary,
        custom,
        ...(isProduct && { isProduct }),
        ...(linkedProduct && { linkedProduct }),
        ...(slideVariable && { slideVariable }),
        ...(defaultSlide && { defaultSlide }),
        ...(layoutId && { layoutId: layoutId }),
        ...(map ? { map } : {}),
      };
    }
    return this._outputModel.getSlideMarkedByOrderGroup(newSlide, slideGroup);
  }

  reorderSlides({ slides }: { slides: OutputSlide[] }): OutputSlide[] {
    const mapped = slides.map((slide, i) => {
      const prevOrder = slide.order;
      const prevId = slide._id;
      const order = (i + 1) * 100;
      const _id = `s-${order}`;
      return { ...slide, _id, order, ...((prevOrder !== order || prevId !== _id) && { unsavedChanges: true }) };
    });

    return mapped;
  }

  createTodayMediaFooter({ clientName, colors }: { clientName: string; colors: Record<string, string> }): OutputText {
    const month = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    const letterWidth = 1 * 0.5;
    const date = new Date();

    const gapX = 3.5;
    const gapY = 93.5;

    const textColor = colors.textBody;
    const accentTextColor = colors.alternativeTextBody;

    const dateText = `${month[date.getMonth()]} ${date.getFullYear()}`;
    const confidentialText = 'Confidential and Proprietary';

    const fullText = `${clientName} | ${dateText} | ${confidentialText}`;
    const fullTextWidth = fullText.length * letterWidth;

    return {
      id: `${clientName.replace(' ', '')}_label`,
      value: fullText,
      htmlValue: `<p><strong style="color: ${accentTextColor};">${clientName}</strong> | ${dateText} | ${confidentialText}</p>`,
      props: {
        w: `${fullTextWidth}%`,
        h: `2.2%`,
        x: `${gapX}%`,
        y: `${gapY}%`,
        color: textColor,
        size: 1,
        align: 'left',
        index: 99,
      },
    };
  }

  createAgencyLogo(agencyLogo: LogoWithSize) {
    const props = { h: 6, x: 3, y: 82, w: 6 };
    const { logoHeight = 0, logoWidth = 36, logo } = agencyLogo ?? {};
    const heightRatioMultiplier = 1.5555;

    let heightPercent = props.h * heightRatioMultiplier;

    if (Number(logoHeight) > 0 && !isNaN(Number(logoHeight)) && !isNaN(Number(logoWidth))) {
      heightPercent = ((Number(logoHeight) * heightRatioMultiplier) / Number(logoWidth)) * props.w;
    }

    const dynamicAgencyLogoObj = {
      variable: 'AGENCYLOGO',
      label: 'Agency Logo',
      defaultImageProps: {
        h: `${heightPercent}%`,
        w: `${props.w}%`,
        x: `${props.x}%`,
        y: `${props.y}%`,
      },
      defaultPath: this._outputModel.getOutputImage(ImageKeys.ClientLogo),
      currentPath: logo,
    };

    return {
      id: 'agency_logo',
      path: !agencyLogo ? dynamicAgencyLogoObj.defaultPath : dynamicAgencyLogoObj.currentPath,
      props: {
        ...dynamicAgencyLogoObj.defaultImageProps,
        align: 'center',
        index: 2,
      },
      dynamicLink: { ...dynamicAgencyLogoObj },
    };
  }

  mergeSlides(originalSlides: OutputSlide[], updatedSlides: OutputSlide[]): OutputSlide[] {
    return originalSlides.map(originalSlide => {
      const updatedSlide = updatedSlides.find(slide => slide.PropertyId === originalSlide.PropertyId) || {};
      return { ...originalSlide, ...updatedSlide };
    });
  }
  getSlideSizeInsideWindow({
    saveHeightSpacing,
    scales,
    window,
  }: {
    saveHeightSpacing: number;
    scales: { width: number; height: number };
    window: { width: number; height: number };
  }) {
    const widthScale = scales.width;
    const heightScale = scales.height;
    const width = widthScale * window.width;
    const height = (width * 9) / 16;

    if (height + saveHeightSpacing > window.height * heightScale) {
      const height = window.height * heightScale - saveHeightSpacing;
      const width = (height * 16) / 9;
      return { width, height };
    }

    return { width, height };
  }

  getIsSlideSelected(slide: OutputSlide, selectedList: OutputSlide[]): boolean {
    return selectedList.some(sl => sl.id === slide.id);
  }
}

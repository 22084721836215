
import Vue from 'vue';
import debounce from 'lodash.debounce';
import { mask } from 'vue-the-mask';
import {
  ProposalGeoSelections,
  Client,
  ClientLocation,
  ClientProspect,
  ClientInsights as ClientInsightsType,
} from '@/shared/types';
import MapComponent from '@/shared/legacy/map/map.vue';
import EditableAddress from '@/components/EditableAddress.vue';
import CategoryPicker from '@/components/Clients/CategoryPicker.vue';
import ProposalMarketMap from '@/components/Proposal/ProposalMarketMap.vue';
import ClientInsights from '@/components/Clients/ClientInsights.vue';
import { ValidationServiceContract, TaxonomyServiceContract, GeocodeServiceContract } from '@/injectables';
import { Services } from '@/injectables/tokens';

const addressToLatLong: Record<string, { lat: number; lon: number }> = {};

export default Vue.extend({
  name: 'ClientInfo',

  inject: ['$confirm', 'showSnackbar'],

  useInjectable: [Services.Geocode],

  components: {
    CategoryPicker,
    MapComponent,
    EditableAddress,
    ProposalMarketMap,
    ClientInsights,
  },

  directives: { mask },

  data: (): {
    mask: string;
    enableCategoryPicker: boolean;
    loading: boolean;
    clientUpdated: boolean;
    locationsUpdated: boolean;
    client: Record<'category' | 'phone' | 'email' | 'url', string>;
    canAutocompleteLocations: boolean;
    showLocationPicker: boolean;
    fetchingLocations: boolean;
    locationAutocompleteList: object[];
    locationSearch: string;
    newClientLocation: ClientLocation;
    newClientLocationLabel: string;
    mapHeight: number;
    clientLocations: ClientLocation[];
    maximumLocations: number;
    isClientInformationValid: boolean;
    isInsightsPending: boolean;
  } => ({
    mapHeight: 0,
    mask: '1 (###) ###-####',
    enableCategoryPicker: false,
    loading: false,
    clientUpdated: false,
    locationsUpdated: false,
    clientLocations: [],
    client: {
      category: null,
      phone: null,
      url: null,
      email: null,
    },
    showLocationPicker: false,
    canAutocompleteLocations: false,
    fetchingLocations: false,
    locationSearch: '',
    newClientLocationLabel: '',
    newClientLocation: {
      address: '',
      lat: 0,
      lon: 0,
      city: '',
      zip: '',
      state: '',
    },
    locationAutocompleteList: [],
    maximumLocations: 25,
    isClientInformationValid: false,
    isInsightsPending: false,
  }),

  watch: {
    activeClient(newVal, oldVal): void {
      if (!oldVal) {
        this.setInitialClientValues();
      }
    },
    locationSearch(val: string): void {
      if (!val || val.length < 5) {
        return;
      }
      this.fetchingLocations = true;
      this.fetchAutocompleteApi(val);
    },
    'client.category': function (newVal: string): void {
      if (newVal && newVal !== this.activeClient.category) {
        this.clientUpdated = true;
      }
    },
  },

  computed: {
    clientInsightsLoading(): boolean {
      return (
        this.$store.state.client.activeClientInsightsLoading ||
        this.$store.state.client.activeClientProspectLoading ||
        this.$store.state.client.activeClientProspectRefreshing
      );
    },
    clientInsights(): ClientInsightsType | null {
      return this.$store.state.client.activeClientInsights;
    },
    clientProspect(): ClientProspect | null {
      return this.$store.state.client.activeClientProspect;
    },
    clientName() {
      return this.activeClient?.name;
    },
    rules() {
      const validationService: ValidationServiceContract = this.$container.get(Services.Validation);
      return {
        maxLength: validationService.maxLengthValidatorFactory(),
        minLengthPhone: validationService.minLengthValidatorFactory(
          16,
          () => 'Phone number must have 10 numeric after country code',
        ),
        required: validationService.requiredValidatorFactory(),
        domain: validationService.isDomainValidatorFactory(),
      };
    },
    canAddAddress(): boolean {
      return (
        this.newClientLocation?.label &&
        this.newClientLocation?.address &&
        !this.clientLocations.find(({ address }) => address === this.newClientLocation?.address) &&
        !this.clientLocations.find(({ label }) => label === this.newClientLocationLabel)
      );
    },
    canSaveGeo(): number | boolean {
      return (
        !!this.geoSelections &&
        Object.keys(this.geoSelections).length &&
        Object.keys(this.geoSelections).some(
          key => Array.isArray(this.geoSelections[key]) && this.geoSelections[key].length,
        )
      );
    },
    clientHasGeo(): boolean {
      return Object.values(this.geoSelections).some(arr => Array.isArray(arr) && arr.length);
    },
    updateClientLoading(): boolean {
      return this.$store.state.client.updateClientLoading;
    },
    geoSelections(): ProposalGeoSelections {
      return { ...this.$store.state.client.activeClient.geoSelections };
    },
    selectedMap(): string {
      // '24753aa3-7a2d-4bb6-9370-e7d657b08efb'
      return this.$store.state.map.defaultMap;
    },
    canEditClient(): boolean {
      return !!this.activeClient;
    },
    activeClient(): Client {
      return this.$store.getters['client/activeClient'];
    },
    lastUpdated(): string {
      return this.$options.filters.formatDate(this.activeClient?.updatedAt);
    },
    friendlyCategory(): string {
      const taxonomyService: TaxonomyServiceContract = this.$container.get(Services.Taxonomy);
      if (!this.client || !this.client.category || !this.client.category.name) return '';
      return taxonomyService.fullTitle(this.client.category.name);
    },
    items(): object[] {
      const matches = [];
      this.locationAutocompleteList.forEach(entry => {
        if (entry.position && entry.address && entry.address.label) {
          const value = {
            label: entry.address.label,
            address: entry.address.label,
            lat: entry.position.lat,
            lon: entry.position.lng,
            city: entry.address.city,
            zip: entry.address.postalCode,
            state: entry.address.stateCode,
          };
          matches.push({ text: entry.address.label, value });
          addressToLatLong[entry.address.label] = { lat: entry.position.lat, lon: entry.position.lng };
        }
      });
      return matches;
    },
    numberOfLocations(): number {
      return this.clientLocations.length;
    },
    contactEmail() {
      return this.activeClient?.contactEmail || '';
    },
    contactPhone() {
      return this.activeClient?.contactPhone || '';
    },
    contactFullName() {
      return this.activeClient?.contactName || '';
    },
  },

  methods: {
    async refreshBuzzboardData(): Promise<void> {
      await this.refreshProspect();
      await this.getClientInsights();
      await this.getClientProspect();
    },
    async getClientInsights(): Promise<void> {
      const insightsResponse = await this.$store.dispatch('client/buzzboardGetInsights', {
        clientPropertyId: this.activeClient?.PropertyId,
      });

      this.isInsightsPending = insightsResponse?.message === 'Signals generation is in progress.';
    },
    async getClientProspect(): Promise<void> {
      await this.$store.dispatch('client/buzzboardGetProspect', {
        clientPropertyId: this.activeClient?.PropertyId,
      });
    },
    async refreshProspect(): Promise<void> {
      await this.$store.dispatch('client/buzzboardRefreshProspect', {
        clientPropertyId: this.activeClient?.PropertyId,
      });
    },
    async deleteClientGeoSelections() {
      const success = await this.$store.dispatch('client/removeClientGeoSelections', this.activeClient.id);

      if (success) {
        this.$refs.geoMap.debounceLoadPolygons();
      }
    },
    updateClientGeoSelections() {
      this.$store.dispatch('client/updateClientGeoSelections', {
        geoSelections: { ...this.geoSelections },
        clientId: this.activeClient.id,
      });
    },
    updateLabel(locationIndex, newLabel: string): void {
      const updatedLocation = { ...this.clientLocations[locationIndex], label: newLabel };
      const updatedLocations = [...this.clientLocations].map((loc, i) => {
        if (i === locationIndex) {
          return updatedLocation;
        }
        return loc;
      });
      this.clientLocations = updatedLocations;
      this.locationsUpdated = true;
      this.$nextTick(() => this.ShowClientLocations(this.$refs.clientInfoMap?.Get(), true));
    },
    updateAddress(locationIndex, newAddress: ClientLocation): void {
      const updatedLocation = {
        ...this.clientLocations[locationIndex],
        address: newAddress.address,
        lat: newAddress.lat,
        lon: newAddress.lon,
        city: newAddress.city,
        state: newAddress.state,
        zip: newAddress.zip,
      };
      const updatedLocations = [...this.clientLocations].map((loc, i) => {
        if (i === locationIndex) {
          return updatedLocation;
        }
        return loc;
      });
      this.clientLocations = updatedLocations;
      this.locationsUpdated = true;
      this.$nextTick(() => this.ShowClientLocations(this.$refs.clientInfoMap?.Get(), true));
    },
    deleteLocation(location): void {
      const filteredLocations = this.clientLocations.filter(loc => {
        return loc.address !== location.address;
      });
      this.clientLocations = filteredLocations;
      this.locationsUpdated = true;
      this.$nextTick(() => this.ShowClientLocations(this.$refs.clientInfoMap?.Get(), true));
    },
    submitClientLocationUpdate() {
      this.$store
        .dispatch('client/updateClientLocations', {
          locations: [...this.clientLocations],
          clientId: this.activeClient.id,
        })
        .then(() => {
          this.locationsUpdated = false;
        })
        .catch(err => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },
    setCategory(category: string): void {
      this.client.category = category;
    },
    submitClientInfoUpdate(): void {
      const updatedClient = {
        ...this.client,
        categoryId: this.client.category.id,
      };
      this.loading = true;
      this.$store
        // must create this action
        .dispatch('client/updateClientInfo', { ...updatedClient })
        .then(success => {
          if (success) {
            this.clientUpdated = false;
            // this.locationsUpdated = false;
          }
        })
        .then(() => {
          if (this.$store.state.nextUrl) {
            this.$nextTick(() => {
              this.$router
                .push({ name: this.$store.state.nextUrl, params: this.$route.params })
                .then(() => this.$store.dispatch('setNextUrl', ''));
            });
          }
        })
        .catch(err => this.$log('error', 'clientInfo/submitClientUpdate', err))
        .finally(() => (this.loading = false));
    },
    setInitialClientValues(): void {
      this.client.category = this.activeClient?.category;
      this.client.url = this.activeClient?.url;
      this.client.phone = this.activeClient?.phone;
      this.clientUpdated = false;
      this.clientLocations = [];
      if (this.activeClient?.address) {
        this.clientLocations = [...this.$store.getters['output/forceArray'](this.activeClient.address)];
      }
    },
    onMapReady(evt): void {
      const { id, redrawing } = evt;
      const map = this.$refs[id]?.Get();
      if (!map || !map.host || !map.leaflet) {
        // eslint-disable-next-line no-console
        console.error('map component did not load correctly', map);
        return;
      }

      if (redrawing) {
        // we're coming back to this view, don't animate
        // TODO: if we're coming back with a different client, we should animate
        this.ShowClientLocations(map, false);
      } else {
        map.SetView([39.8283, -98.5795], 4); // show the US, then zoom to active area
        setTimeout(() => {
          this.ShowClientLocations(map, true);
        }, 200);
      }
    },
    fetchAutocompleteApi: debounce(async function (query: string) {
      const geocodeService: GeocodeServiceContract = this.geocodeService;
      const { isErr, unwrapErr, unwrap } = await geocodeService.searchByLocation(encodeURIComponent(query));
      if (isErr()) {
        this.showSnackbar(unwrapErr().message, 'error');
        return;
      }
      this.locationAutocompleteList = unwrap();
      this.fetchingLocations = false;
    }, 500),
    addClientLocation(): void {
      if (!this.$refs?.newLabelInput.validate() || !this.$refs?.newAddressInput.validate()) {
        // Drop current new location if invalid data provided
        this.newClientLocationLabel = '';
        this.newClientLocation = {
          label: '',
          address: '',
          lat: 0,
          lon: 0,
          city: '',
          zip: '',
          state: '',
        };
        this.showLocationPicker = false;
        return;
      }
      const ll = addressToLatLong[this.newClientLocation.address];
      if (!ll) {
        // eslint-disable-next-line no-console
        console.log('error, no latlong for ', this.newClientLocation.address, addressToLatLong);
        return;
      }
      if (!this.clientLocations) {
        this.clientLocations = [];
      }
      this.clientLocations.push({
        address: this.newClientLocation.address,
        ...(this.newClientLocationLabel
          ? { label: this.newClientLocationLabel }
          : { label: this.newClientLocation.label }),
        lat: ll.lat,
        lon: ll.lon,
        city: this.newClientLocation.city,
        zip: this.newClientLocation.zip,
        state: this.newClientLocation.state,
      });
      this.newClientLocationLabel = '';
      this.newClientLocation = {
        label: '',
        address: '',
        lat: 0,
        lon: 0,
        city: '',
        zip: '',
        state: '',
      };
      this.ShowClientLocations(this.$refs.clientInfoMap?.Get(), true);
      this.showLocationPicker = false;
      this.locationsUpdated = true;
    },
    CancelLocationClick(): void {
      if (this.showLocationPicker) {
        this.showLocationPicker = false;
      }
      this.newClientLocation = {
        label: '',
        address: '',
        lat: 0,
        lon: 0,
        city: '',
        zip: '',
        state: '',
      };
    },
    ShowClientLocations(map, animate): void {
      if (!this.clientLocations || !this.clientLocations.length) {
        map.RemoveLayer('locations');
        map.SetView([39.8283, -98.5795], 4); // reset back to US view
        return;
      }

      if (!map || !map.host || !map.leaflet) {
        // eslint-disable-next-line no-console
        console.error('map component did not load correctly', map);
        return;
      }

      map.RemoveLayer('locations');
      const layer = map.CreateLayer('locations');

      const haveLatLongs = this.clientLocations.filter(
        (location: ClientLocation) => typeof location.lat !== 'undefined' && typeof location.lon !== 'undefined',
      );
      const needLatLongs = this.clientLocations.filter(
        (location: ClientLocation) => typeof location.lat === 'undefined' || typeof location.lon === 'undefined',
      );

      if (haveLatLongs.length > 0) {
        let single;
        haveLatLongs.forEach((location: ClientLocation) => {
          single = [location.lat, location.lon];
          map.leaflet.marker(single).bindPopup(location.label).addTo(layer);
        });
        if (haveLatLongs.length === 1) {
          if (animate) {
            setTimeout(() => {
              map.SetView(single, 13);
            }, 100);
          } else {
            map.SetView(single, 13);
          }
        } else if (animate) {
          map.FlyToBounds(layer.getBounds());
        } else {
          map.FitBounds(layer.getBounds());
        }
      }

      if (needLatLongs.length > 0) {
        const geocodeService: GeocodeServiceContract = this.geocodeService;
        const requests = needLatLongs.map((location: ClientLocation) => geocodeService.searchByText(location.address));
        Promise.all(requests).then(responses => {
          let single;
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          responses.forEach((res: any) => {
            if (res && res.Response && res.Response.View) {
              if (Array.isArray(res.Response.View) && res.Response.View.length > 0) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const v: any = res.Response.View[0];
                if (v.Result && Array.isArray(v.Result)) {
                  this.locationAutocompleteList = v.Result;
                  if (v.Result.length > 0) {
                    const ll = v.Result[0].Location.DisplayPosition;
                    single = [ll.Latitude, ll.Longitude];
                    map.leaflet.marker(single).bindPopup(v.Result[0].Location.Address.Label).addTo(layer);
                  }
                }
              }
            }
          });
          if (this.clientLocations.length === 1) {
            map.SetView(single, 13);
          } else {
            map.FlyToBounds(layer.getBounds());
          }
        });
      }
    },
  },

  mounted(): void {
    this.setInitialClientValues();
    this.$store.dispatch('client/getCategories');
    this.$nextTick(() => (this.mapHeight = this.$refs?.marketMapContainer.$el.clientHeight - 120));
  },

  /**
   * TODO: this doesn't actually get triggered, this code is unreachable
   */
  async beforeRouteLeave(to, from, next): Promise<void> {
    if (this.clientUpdated || this.locationsUpdated) {
      try {
        const { confirmed } = await this.$confirm.show({
          title: 'Unsaved changes',
          body: 'Are you sure you want to leave the page without saving changes?',
          cancelText: 'Leave',
          confirmText: 'Stay',
        });
        if (confirmed) {
          next();
        }
      } catch (err) {
        this.$log('warning', 'clientInfo/beforeRouteLeave.confirm', err);
        next();
      }
      return;
    }
    next();
  },
});

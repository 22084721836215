import { formattedDateStr } from './filters-common';

// Format date:
// - input valid date [<number> or <string>] -> return formatted string
// - input invalid date [<number> or <string>] -> return placeholder
// - input 0 or null or "" -> return placeholder
// - data format MMM DD, YYYY (Jan 1, 1970)
export const filter = (value: number | string | null, placeholder = ''): string => {
  try {
    if (value) {
      const date = new Date(new Date(`${value}`).toISOString().slice(0, -1));
      if (date.toString() !== 'Invalid Date') {
        return formattedDateStr(date);
      }
    }
    return placeholder;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('error', 'main.formatDate', value, err);
    return placeholder;
  }
};

import 'reflect-metadata';
import { Err, Ok, Result } from '@sniptt/monads';
import { Failure } from '@/injectables/failure';
import { AgencyRate, FlightModelContract } from '@/injectables';
import { injectable } from 'inversify';
import { DarkPeriod } from '@/shared/types';

@injectable()
export class FlightModel implements FlightModelContract {
  /**
   * Max value of the flight rate.
   * Use it to prevent setting to big rate values.
   */
  private MAX_RATE = 999999999;

  validateRate(
    rate: number,
    defaultRate: number,
    initialRate: number,
    agencyRate: AgencyRate,
    isAdmin = false,
  ): Result<number, Failure & { rate: number }> {
    // if it used by Admin it just passed
    if (isAdmin) {
      return Ok(Math.min(rate, this.MAX_RATE));
    }

    // calculate min and max rate according to the Agency Rate
    const minRate = parseInt(((1 - agencyRate.min) * defaultRate).toFixed());
    const maxRate = parseInt(((1 + agencyRate.max) * defaultRate).toFixed());

    if (rate < minRate || rate > maxRate) {
      // If initialRate is out of allowed Agency rate it means
      // that this rate was updated by the Admin
      // so just return initial rate
      // otherwise return default rate value
      return Err({
        message: 'Please contact your admin to adjust rate this much',
        rate: initialRate < minRate || initialRate > maxRate ? initialRate : defaultRate,
      });
    }

    return Ok(Math.min(rate, this.MAX_RATE));
  }

  /**
   * We must validate that the dates aren't overlapping with other blackouts. The date disabling
   * mechanism only disallows **picking** the specific days that are part of other blackouts.
   * However, it allows the date range to include the other blackouts, with "holes" in between.
   */
  datesIntersectPeriods(index: number, periodList: DarkPeriod[], dates: [string, string]): boolean {
    const currentBlackoutIndex = index;
    const otherBlackouts = periodList.filter(b => b.index !== currentBlackoutIndex);
    const otherBlackoutsDates = otherBlackouts.map(b => [b.startDate, b.endDate]);
    const [startDate, endDate] = dates;
    for (const [otherStartDate, otherEndDate] of otherBlackoutsDates) {
      if (startDate <= otherEndDate && endDate >= otherStartDate) {
        return true;
      }
    }
    return false;
  }
}

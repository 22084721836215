import 'reflect-metadata';
import { StatusModelContract, StatusReturnProps, Status } from '@/injectables';
import { injectable } from 'inversify';
import { InstantCampaignStatus } from '@/app/graphql';

@injectable()
export class StatusModel implements StatusModelContract {
  private _status: Status = Status.InProgress;

  get currentStatus() {
    return this._status;
  }

  set currentStatus(status: Status) {
    this._status = status;
  }

  prettyStatus(status: Status | InstantCampaignStatus, isReviewFlow = false): { short: string; full: string } {
    if (status === Status.ClosedLost) return { short: 'Closed - lost', full: 'Closed - lost' };
    if (status === Status.Approved && isReviewFlow) return { short: 'Mgr Approved', full: 'Manager Approved' };
    if (status === InstantCampaignStatus.Inprogress) return { short: 'In Progress', full: 'In Progress' };
    if (status === InstantCampaignStatus.Sold) return { short: 'Sold', full: 'Sold' };

    const statusWords = status.split(/(?=[A-Z])/);

    const joinedStatusWord = statusWords.join(' ');

    const prettyStatus = { short: joinedStatusWord, full: joinedStatusWord };

    if (status.length > 15) {
      prettyStatus.short = statusWords[0];
    }

    return prettyStatus;
  }

  clientApprovedStatuses({ adminReviewEnabled = false }: { adminReviewEnabled: boolean }): StatusReturnProps {
    return {
      enableStatuses: adminReviewEnabled ? [Status.InProgress] : [],
      targetStatus: Status.ClientApproved,
    };
  }

  submittedStatuses({ adminReviewEnabled = false }: { adminReviewEnabled: boolean }): StatusReturnProps {
    return {
      enableStatuses: adminReviewEnabled
        ? [Status.ClientApproved]
        : [Status.InProgress, Status.ClientApproved, Status.UnderReview],
      targetStatus: adminReviewEnabled ? Status.SubmittedForReview : Status.Approved,
    };
  }

  underReviewStatuses({ adminReviewEnabled = false }: { adminReviewEnabled: boolean }): StatusReturnProps {
    return {
      enableStatuses: adminReviewEnabled
        ? [Status.SubmittedForReview]
        : [Status.InProgress, Status.ClientApproved, Status.UnderReview, Status.SubmittedForReview],
      targetStatus: adminReviewEnabled ? Status.UnderReview : Status.Approved,
    };
  }

  approvedStatuses({ adminReviewEnabled = false }: { adminReviewEnabled: boolean }): StatusReturnProps {
    return {
      enableStatuses: adminReviewEnabled
        ? [Status.UnderReview]
        : [Status.InProgress, Status.ClientApproved, Status.SubmittedForReview, Status.UnderReview],
      targetStatus: Status.Approved,
    };
  }

  triggerRevertWarningStatuses = [Status.Approved];

  soldStatus = Status.Approved;

  adminProposalsQueueStatuses = [Status.SubmittedForReview, Status.ClientApproved, Status.UnderReview];

  statusConfigs(isReviewFlow = false): Record<Status, { color: string; text: string }> {
    return {
      [Status.InProgress]: {
        color: '#AA00FF',
        text: this.prettyStatus(Status.InProgress, isReviewFlow).short,
      },
      [Status.UnderReview]: {
        color: '#F57F17',
        text: this.prettyStatus(Status.UnderReview, isReviewFlow).short,
      },

      [Status.SubmittedForReview]: {
        color: '#3FBC56',
        text: this.prettyStatus(Status.SubmittedForReview, isReviewFlow).short,
      },

      [Status.Approved]: {
        color: '#0099FF',
        text: this.prettyStatus(Status.Approved, isReviewFlow).short,
      },
      [Status.ClientApproved]: {
        color: '#0099FF',
        text: this.prettyStatus(Status.ClientApproved, isReviewFlow).short,
      },
      [Status.Sold]: {
        color: '#000000',
        text: this.prettyStatus(Status.Sold, isReviewFlow).short,
      },
      [Status.ClosedLost]: {
        color: '#000000',
        text: this.prettyStatus(Status.ClosedLost, isReviewFlow).short,
      },
    };
  }
}

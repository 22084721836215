
import Vue from 'vue';
import { BaseProductModelContract, BudgetModelContract } from '../injectables';
import { Models } from '../injectables/tokens';
import { ProductConfigCategory } from '@/app/graphql';

export default Vue.extend({
  name: 'ProductInfoDialog',

  useInjectable: [Models.Budget, Models.BaseProduct],

  props: {
    productInfo: {
      type: Object,
    },
    productsPage: {
      type: Boolean,
    },
    iconClasses: {
      type: String,
      default: '',
    },
    outlinedIcon: {
      type: Boolean,
      default: false,
    },
    proposalDuration: {
      type: Number,
      default: 1,
    },
  },

  computed: {
    info(): { name: string; budgetText: string; tooltipText: string } {
      if (this.isPackage) {
        return this.getPackageInfo(this.productInfo);
      }
      return this.getProductInfo(this.productInfo);
    },
    isPackage() {
      return this.productInfo.category === ProductConfigCategory.Package;
    },
  },

  methods: {
    formatPrice(value: string | number | null): string {
      return (this.budgetEntity as BudgetModelContract).formatPrice(Number(value));
    },
    getProductInfo(productInfo) {
      const productInfoText = productInfo?.description?.short;
      const minSpendText = `Avg price ${this.formatPrice(productInfo?.calcMinSpend)}`;

      const recommendedBudgetText =
        this.productInfo?.recommendedBudget && this.productInfo?.calcMinSpend
          ? ` - ${this.formatPrice(
              (this[Models.BaseProduct] as BaseProductModelContract).getCalcRecommendedSpend(
                productInfo,
                this.proposalDuration,
              ),
            )}`
          : '';
      const budgetText = minSpendText + recommendedBudgetText;
      return {
        name: this.productInfo?.name || '',
        budgetText: budgetText,
        tooltipText: productInfoText || '',
      };
    },
    getPackageInfo(packageInfo) {
      const productInfoText = packageInfo?.packageDescription;
      const budgetText = `Avg price ${this.formatPrice(packageInfo?.budget)}`;
      return {
        name: this.productInfo?.name || '',
        budgetText: budgetText,
        tooltipText: productInfoText || '',
      };
    },
  },
});

/* eslint-disable @typescript-eslint/no-explicit-any */
import { UnwrapErrorMessageMapper } from '@/injectables';

/**
 * use error response and return error string
 */
export const unwrapErrorMessage: UnwrapErrorMessageMapper = function (
  err?: Record<string, any> | string,
  name?: string,
) {
  const prefix = name || 'error';
  if (!err) {
    return `${prefix}: logError without error`;
  }
  if (typeof err === 'string') {
    return err;
  }
  if (err.response) {
    if (err.response.data) {
      if (err.response.data?.message) {
        return `${prefix}: ${err.response.data.message}`;
      }
      return `${prefix}: ${err.response.data}`;
    }
    return `${prefix}: api error`;
  }
  if (err.message) {
    return `${prefix}: ${err.message}`;
  }
  return `${prefix}: unhandled error`;
};


import Vue, { PropType } from 'vue';

import { Models } from '@/injectables/tokens';

import TargetingSelector from '@/features/instant-io/ui/targeting-selector.vue';
import DemographicGroup from '@/entities/instantIO/ui/templates/DemographicGroup.vue';
import { SegmentCategory, SegmentSubCategory } from '@/app/graphql';
import { SegmentItem, TargetingSegmentsModelContract } from '@/injectables';

interface DemographicItem {
  property: string;
}

export default Vue.extend({
  name: 'EditableInfoCard',

  useInjectable: [Models.NewProposal, Models.TargetingSegments],

  components: { DemographicGroup, TargetingSelector },

  props: {
    size: {
      type: [String, Number],
      default: 28,
    },
    status: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: '',
    },
    selectedDemographics: {
      type: Array as PropType<SegmentItem[]>,
      required: true,
    },
    selectedMainTargets: {
      type: Array as PropType<SegmentItem[]>,
      required: true,
    },
  },
  data() {
    return {
      demographics: [
        [{ category: SegmentSubCategory.Age }, { category: SegmentSubCategory.Income }],
        [
          { category: SegmentSubCategory.Family },
          { category: SegmentSubCategory.Gender },
          { category: SegmentSubCategory.Housing },
        ],
      ],
    };
  },
  computed: {
    demographicsItems() {
      return this.$store.state.targetingSegments.demographicsSegments;
    },
    mainSegmentSelection: {
      get(): DemographicItem[] {
        return this.selectedMainTargets;
      },
      set(val: DemographicItem[]) {
        this.$emit('update-census', val);
      },
    },
    searchSegments() {
      return this.mainSegmentSelection.filter(el => el.segmentCategory === SegmentCategory.Other);
    },
    segmentSubCategory() {
      return SegmentSubCategory;
    },
  },
  methods: {
    deleteSearchItem(segment) {
      this.mainSegmentSelection = this.mainSegmentSelection.filter(el => el.id !== segment.id);
    },
    getGroupItemsByCategory(category: SegmentSubCategory) {
      if (!this.demographicsItems) return [];
      return this.demographicsItems[category] || [];
    },
    updateSelection(selected: any[], category: SegmentSubCategory) {
      const listWithoutCategory = this.selectedDemographics.filter(el => el.segmentSubCategory !== category);
      this.$emit('update-demographic', [...listWithoutCategory, ...selected]);
    },
    getSelectedByCategory(category: SegmentSubCategory) {
      return this.selectedDemographics.filter(el => el.segmentSubCategory === category);
    },
    getReadableSubCategory(category: SegmentSubCategory) {
      return (this[Models.TargetingSegments] as TargetingSegmentsModelContract).getReadableSubCategory(category);
    },
  },
});

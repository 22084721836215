
import Vue from 'vue';
import MapComponent from '@/shared/legacy/map/map.vue';
import { UnsafeAny } from '@/shared/types';
import { OutputSlide } from '@/shared/legacy/classes';

const addressToLatLong: UnsafeAny = {};
const debounced: UnsafeAny = {};

export default Vue.extend({
  name: 'geoFenceMap',
  components: { MapComponent },
  data: (): {
    loading: boolean;
    mapReady: boolean;
    exportBackgroundImage: string | null;
    mapTilerKey: string;
  } => ({
    mapReady: false,
    loading: false,
    exportBackgroundImage: null,
    mapTilerKey: 'CCrAlH25DTP89c6iJsO3',
  }),
  props: {
    zIndex: {
      type: Number,
      default: 3,
    },
    hideLegend: {
      type: Boolean,
      default: false,
    },
    mapId: {
      type: String,
      default: '0',
    },
    slideId: {
      type: String,
      default: '',
    },
    fences: {
      type: Array,
      default: () => [],
    },
    height: {
      type: Number,
    },
    width: {
      type: Number,
    },
    exporting: {
      type: Boolean,
      default: () => false,
    },
    mapOnly: {
      type: Boolean,
      default: () => false,
    },
  },
  mounted(): void {
    addressToLatLong[this.mapCacheKey] = {};

    if (this.fences?.length >= 1) {
      this.fences.forEach(fence => {
        if (fence.address && fence.lat && fence.lon && fence.radius && fence.unitType) {
          addressToLatLong[this.mapCacheKey][fence.address] = {
            lat: fence.lat,
            lon: fence.lon,
            radius: fence.radius,
            unitType: fence.unitType,
          };
        }
      });
    }
  },
  computed: {
    selectedMap(): string {
      // 'deaff9f1-d4a1-4f83-b47f-d7a4ec64f009'
      return this.$store.state.map.defaultMap;
    },
    legend(): string {
      if (this.fences.length === 1) {
        return '1 address';
      }
      return `${this.fences.length} addresses`;
    },
    mapRenderCacheKey(): string {
      return `_geoFenceSlideMap_${this.mapId}_${this.mapOnly ? '1' : '2'}_light`;
    },
    mapCacheKey(): string {
      return `_geoFenceSlideMap_${this.mapId}_${this.mapOnly ? '1' : '2'}`;
    },
    currentSlide(): OutputSlide | null {
      const slides = this.$store.state.output?.activeOutput?.slides;
      if (this.slideId && Array.isArray(slides)) {
        const found = slides.find((slide: OutputSlide) => slide._id === this.slideId);
        if (found) {
          return found;
        }
      }
      return null;
    },
    slideColors(): string | null {
      return this.currentSlide?.colors;
    },
  },
  methods: {
    convertedRadius(radius: number, unitType: string): number {
      let circleRadius = radius || 0;
      switch (unitType) {
        case 'Kilometers':
          circleRadius *= 1000;
          break;
        case 'Meters':
          circleRadius *= 1;
          break;
        case 'Miles':
          circleRadius *= 1609.34;
          break;
        case 'Feet':
          circleRadius *= 0.3048;
          break;
        default:
          circleRadius = 0;
          break;
      }
      return circleRadius;
    },
    onMapReady(): void {
      const map = this.$refs[this.mapCacheKey]?.Get();
      if (!map || !map.host) {
        // eslint-disable-next-line no-console
        console.error('map component did not load correctly', this.mapCacheKey);
        return;
      }
      this.mapReady = true;
      if (!this.fences?.length || !map.GetZoom()) {
        map.ClearMap();
        map.SetView([40, -100], 4);
      }
      if (this.fences && Array.isArray(this.fences) && this.fences.length) {
        if (debounced[this.mapCacheKey]) {
          clearTimeout(debounced[this.mapCacheKey]);
        }
        debounced[this.mapCacheKey] = setTimeout(() => {
          map.ClearMap();
          this.setMapView({ fromInit: true });
        }, 100);
      } else {
        // eslint-disable-next-line no-console
        console.log('geoFenceMap, no fence', this.fences);
      }
    },
    setMapView(): void {
      try {
        const map = this.$refs[this.mapCacheKey]?.Get();
        if (!this.mapReady || !map || !map.host || !map.leaflet) {
          // eslint-disable-next-line no-console
          console.log('map not ready', this.mapCacheKey);
          return;
        }
        if (this.fences.length >= 1) {
          let mapColor = '#ec2d00';
          if (this.$store.getters['output/slideColors']?.secondary) {
            mapColor = this.$store.getters['output/slideColors'].secondary;
          }
          if (this.slideColors?.mapLayer) {
            mapColor = this.slideColors.mapLayer;
          }

          let hasPolygons = false;
          this.fences.forEach(fence => {
            const { radius = 1, unitType = 'miles', address = '' } = fence;
            const addressObj = address ? addressToLatLong[this.mapCacheKey][address] : null;
            if (addressObj) {
              hasPolygons = true;
              const geo = addressToLatLong[this.mapCacheKey][address];
              const circle = map.CreateCircleLayer(
                geo.lat,
                geo.lon,
                this.convertedRadius(radius, unitType),
                `circle_${fence.address}`,
              );
              circle.setStyle({
                fillOpacity: 0.3,
                color: mapColor,
                fillColor: mapColor,
                weight: 2,
              });
            }
          });

          let paddingOptions = { paddingTopLeft: [20, 20], paddingBottomRight: [20, 20] };
          if (this.exporting) {
            paddingOptions = { paddingTopLeft: [10, 10], paddingBottomRight: [10, 10] };
          }

          if (hasPolygons) {
            map.FitAllLayers({ animate: false, ...paddingOptions });
            if (this.exporting) {
              this.replaceBackground();
            } else {
              setTimeout(() => {
                // for when the initial FitAllLayers happened too soon and the map wasn't completely rendered
                map.FitAllLayers({ animate: false, ...paddingOptions });
              }, 100);
            }
          } else {
            // eslint-disable-next-line no-console
            console.log('GeoFenceMap: no polygon');
            setTimeout(() => {
              this.$emit('rendered', { empty: true });
            }, 10);
          }
        } else {
          // eslint-disable-next-line no-console
          console.log('GeoFenceMap: no fences');
          setTimeout(() => {
            this.$emit('rendered', { empty: true });
          }, 10);
        }
      } catch (exp) {
        // eslint-disable-next-line no-console
        console.log('GeoFenceMap error: ' + exp.message);
      }
    },
    replaceBackground(retries = 5): void {
      const mapRef = this.$refs[this.mapCacheKey];
      const map = mapRef?.Get();
      const mapEl = mapRef?.$el;

      setTimeout(() => {
        if (!mapRef || !mapEl || !map) {
          // eslint-disable-next-line no-console
          console.error('geofencemap can not export, no map element', this.mapCacheKey, { mapRef, map, mapEl });
          if (retries > 0) {
            setTimeout(() => {
              this.replaceBackground(retries - 1);
            }, 500);
          } else {
            setTimeout(() => {
              this.$emit('rendered', { empty: true });
            }, 10);
          }
          return;
        }
        const tileBackgroundList = mapEl.getElementsByClassName('mapboxgl-map');
        if (!tileBackgroundList || tileBackgroundList.length === 0) {
          // eslint-disable-next-line no-console
          console.error('GeoFenceMap: can not export, no map background');
          if (retries > 0) {
            setTimeout(() => {
              this.replaceBackground(retries - 1);
            }, 500);
          } else {
            setTimeout(() => {
              this.$emit('rendered', { empty: true });
            }, 10);
          }
          return;
        }
        const tileBackground = tileBackgroundList[0] as HTMLElement;
        const zoom = map.GetZoom() - 1; // why do we need the -1 ???
        const center = map.GetCenter();
        const staticMapByCenter = `https://api.maptiler.com/maps/${this.selectedMap}/static/${center.lng},${center.lat},${zoom}/${mapEl.offsetWidth}x${mapEl.offsetHeight}@1x.png?key=CCrAlH25DTP89c6iJsO3`;

        const img = new Image();
        img.addEventListener(
          'load',
          () => {
            this.exportBackgroundImage = staticMapByCenter;
            tileBackground.style.display = 'none';
            setTimeout(() => {
              this.$emit('rendered', { empty: false });
            }, 500);
          },
          false,
        );
        img.src = staticMapByCenter;
      }, 500);
    },
  },
});


import Vue from 'vue';

export default Vue.extend({
  name: 'ReducibleCard',

  props: {
    title: {
      type: String,
      required: true,
    },
    iconInsteadOfAction: {
      type: Boolean,
      default: false,
    },
    nonClosable: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
});

import * as Types from '../../../../../app/graphql/_types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { TableProductFragmentFragmentDoc } from '../../../../../app/graphql/fragments/product-config.fragment.generated';
import { TablePackageFragmentFragmentDoc } from '../../../../../app/graphql/fragments/package-config.fragment.generated';
export type GetProductsQueryVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
}>;

export type GetProductsQuery = {
  CanHavePackages?: { canHavePackages?: boolean | null } | null;
  products?: Array<{
    id: string;
    agencyId?: string | null;
    type?: Types.ProductConfigType | null;
    name?: string | null;
    category?: Types.ProductConfigCategory | null;
    minDays?: number | null;
    minSpend?: number | null;
    recommendedBudget?: number | null;
    isHidden?: boolean | null;
    description?: Record<string, any> | null;
  } | null> | null;
  packages?: Array<{
    id: string;
    name: string;
    isHidden?: boolean | null;
    category: Types.ProductConfigCategory;
    description?: string | null;
    minSpend: number;
    recommendedBudget: number;
  } | null> | null;
};

export const GetProductsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getProducts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'CanHavePackages' },
            name: { kind: 'Name', value: 'getMediaplannerAgency' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'agencyId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'canHavePackages' } }],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'products' },
            name: { kind: 'Name', value: 'getMediaplannerProductConfigs' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'agencyId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'TableProductFragment' } }],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'packages' },
            name: { kind: 'Name', value: 'getMediaplannerPackages' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'agencyId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'TablePackageFragment' } }],
            },
          },
        ],
      },
    },
    ...TableProductFragmentFragmentDoc.definitions,
    ...TablePackageFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetProductsQuery, GetProductsQueryVariables>;

import * as Types from '../_types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type ProposalPackageFragmentFragment = {
  id: string;
  index?: number | null;
  category: Types.ProductConfigCategory;
  name: string;
  budget: number;
  packageDescription?: string | null;
};

export const ProposalPackageFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProposalPackageFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MediaplannerPackage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'index' } },
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'budget' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'packageDescription' },
            name: { kind: 'Name', value: 'description' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProposalPackageFragmentFragment, unknown>;

import 'reflect-metadata';
import { PackageModelContract } from '@/injectables';
import { injectable } from 'inversify';
import { PackageConfig, ProductConfig } from '@/shared/types';
import { ProductConfigCategory } from '@/app/graphql';

@injectable()
export class PackageModel implements PackageModelContract {
  isPackageBlocked(checkedProduct: PackageConfig, products: ProductConfig[]): boolean {
    return (
      checkedProduct.category === ProductConfigCategory.Package &&
      products.some(
        product =>
          Array.isArray(checkedProduct.products) &&
          checkedProduct.products.map(subproduct => subproduct.id).includes(product.id),
      )
    );
  }
}


import Vue from 'vue';
import { Product } from '@/shared/types';

// injectables
import {
  Category,
  ProductModelContract,
  ProductFulfillmentMethods,
  ProductServiceContract,
  ProductSettings,
} from '@/injectables';
import { Models, Services } from '@/injectables/tokens';

// shared
import { BaseDialog, DialogTabs } from '@/shared/ui';

import Details from './details.vue';
import ProductOptions from './product-options.vue';
import Fulfillment from './fulfillment.vue';
import RateParameters from './rate-parameters.vue';

import { FormFragment, Option } from '../contracts';
import ChildrenSelectModal from '@/entities/product/ChildrenSelectModal.vue';
import MultiSelectButton from '@/shared/ui-kit/MultiSelectButton.vue';
import { ProductConfigFullfillmentMethod } from '@/app/graphql';

interface Data {
  tab: string;
  details: FormFragment;
  options: Option[];
  fulfillment: FormFragment;
  rateParameters: { lowerLimit: number; upperLimit: number };
  category?: Category;
  isValidDetails: boolean;
  isValidFulfillment: boolean;
  isValidRateParameters: boolean;
  isValidOptions: boolean;
  fulfillmentMethodsForEdit: ProductFulfillmentMethods[];
  fulfillmentMethodsForCreate: ProductFulfillmentMethods[];
  isMinSpendLocked: boolean;
  localProduct: Product;
  dataUpdated: boolean;
  loading: boolean;
  wasSaved: boolean;
  saveToChildren: {
    show: boolean;
    menuOpen: boolean;
    type: string;
  };
}

export default Vue.extend({
  name: 'EditProduct',

  inject: ['$confirm', 'showSnackbar'],

  useInjectable: [Services.Product, Models.Product],

  components: {
    ChildrenSelectModal,
    MultiSelectButton,
    BaseDialog,
    DialogTabs,
    ProductDetails: Details,
    Fulfillment,
    ProductOptions,
    RateParameters,
  },

  props: {
    value: {
      type: Boolean,
      required: true,
    },
    productId: {
      type: String,
    },
    categories: {
      type: Array,
      required: true,
    },
    rateTypes: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    action: {
      type: String,
      validator: (action: 'create' | 'edit') => ['create', 'edit'].includes(action),
    },
    freezeCategory: {
      type: String,
    },
    isGlobal: {
      type: Boolean,
      default: false,
    },
  },

  async mounted() {
    await this.getProduct(this.productId);
  },

  data(): Data {
    const productEntity: ProductModelContract = this.$container.get(Models.Product);
    return {
      tab: 'Details',
      details: {},
      fulfillment: {},
      rateParameters: {
        lowerLimit: 0.2,
        upperLimit: 0.2,
      },
      options: null,
      category: null,
      isValidDetails: this.action === 'edit',
      isValidFulfillment: this.action === 'edit',
      isValidRateParameters: true,
      isValidOptions: this.action === 'edit',
      fulfillmentMethodsForEdit: productEntity.fulfillmentMethodsForEdit,
      fulfillmentMethodsForCreate: productEntity.fulfillmentMethodsForCreate,
      isMinSpendLocked: false, // productEntity.isMinSpendLocked,
      localProduct: null,
      dataUpdated: false,
      loading: false,
      wasSaved: false,
      saveToChildren: {
        show: false,
        menuOpen: false,
        type: '',
      },
    };
  },

  computed: {
    tabs(): { value: string; marked: boolean; text: string }[] {
      return [
        { value: 'Details', marked: !this.isValidDetails, text: 'Details' },
        { value: 'Product Options', marked: !this.isValidOptions, text: 'Product Options' },
        { value: 'Fulfillment', marked: !this.isValidFulfillment, text: 'Fulfillment' },
        { value: 'Rate Parameters', marked: false, text: 'Rate Parameters' },
      ];
    },
    ProductConfigFullfillmentMethod() {
      return ProductConfigFullfillmentMethod;
    },
    rateLimits() {
      if (!this.localProduct) return (this.productEntity as ProductModelContract).defaultMarkup;
      return (this.productEntity as ProductModelContract).getProductMarkup(this.localProduct);
    },
    fulfillmentMethods(): ProductFulfillmentMethods[] {
      return this.isCreate ? this.fulfillmentMethodsForCreate : this.fulfillmentMethodsForEdit;
    },
    editedProduct(): Product {
      return {
        ...(this.localProduct || {}),
        ...this.details,
        ...this.fulfillment,
        ...this.rateParameters,
        category: this.category,
        targetingOptions: [...this.options],
      };
    },
    isValidProduct(): boolean {
      return this.isValidDetails && this.isValidFulfillment && this.isValidOptions && this.isValidRateParameters;
    },
    isChangeMarginAvailable() {
      if (!this.localProduct) return false;
      (this.productEntity as ProductModelContract).setProductData(this.localProduct);
      return (this.productEntity as ProductModelContract).isChangeMarginAvailable(this.isGlobal);
    },
    isAgencySuperAdmin(): boolean {
      return this.$store.getters['auth/isAgencySuperAdmin'];
    },
    isParentAgency(): boolean {
      return this.$store.state.agency?.currentAgencyInfo?.parent === null || false;
    },
    isCreate() {
      return this.action === 'create';
    },
  },

  methods: {
    openChildrenSelectionModal(type = ''): void {
      this.saveToChildren.show = true;
      this.saveToChildren.type = type;
    },
    async getProduct(id: string) {
      if (this.isCreate) {
        return this.setProduct((this.productEntity as ProductModelContract).getEmptyProduct());
      }
      const { isErr, unwrap, unwrapErr } = await (this.productService as ProductServiceContract).getProductConfigById(
        id,
        this.isGlobal,
      );
      if (isErr()) {
        return unwrapErr();
      }
      this.setProduct(unwrap());
    },
    setProduct(product) {
      this.dataUpdated = false;
      this.localProduct = product;
      this.options = product.flightConfigs || [];
      this.category = this.freezeCategory ? this.freezeCategory : product.category;
    },
    async saveProduct(childrenIds: string[] = []): Promise<void> {
      const current = this.localProduct.fulfillmentMethod;
      const target = this.fulfillment.fulfillmentMethod;
      const compulse = this.ProductConfigFullfillmentMethod.Compulse;
      if (
        // If target is undefined, then the user didn't open the fulfillment tab
        target !== undefined &&
        current === compulse &&
        target !== compulse
      ) {
        const { confirmed } = await this.$confirm.show({
          title: 'Removing Compulse Fulfillment',
          body: 'If you change fulfillment type, you cannot revert back to Compulse fulfillment',
          cancelText: 'Cancel',
          confirmText: 'Confirm',
        });
        if (confirmed) {
          this.saveProductConfirm(childrenIds);
        }
      } else {
        this.saveProductConfirm(childrenIds);
      }
    },
    async saveProductConfirm(childrenIds: string[] = []): Promise<void> {
      this.saveToChildren.show = false;
      this.dataUpdated = false;
      const agencyId = this.$store.state.activeAgency.PropertyId;
      this.loading = true;
      if (this.isCreate) {
        if (childrenIds.length) {
          await this.onProductCreateWithChildren(this.editedProduct, agencyId, childrenIds);
        } else {
          await this.onProductCreate(this.editedProduct, agencyId);
        }
      } else {
        if (childrenIds.length) {
          await this.onProductSaveWithChildren(this.editedProduct, agencyId, childrenIds);
        } else {
          await this.onProductSave(this.editedProduct, agencyId);
        }
      }
      this.loading = false;
      this.close();
    },
    async onProductCreate(product: ProductSettings, agencyId: string): Promise<void> {
      const { isErr, unwrapErr } = await (this.productService as ProductServiceContract).createProductConfig(
        product,
        agencyId,
      );
      if (isErr()) {
        this.$log('error', 'product/saveProductSettings', unwrapErr());
        return;
      }
      this.wasSaved = true;
      this.showSnackbar('Data saved successfully', 'success');
    },
    async onProductCreateWithChildren(
      product: ProductSettings,
      agencyId: string,
      childrenIds: string[],
    ): Promise<void> {
      const { isErr, unwrapErr } = await (
        this.productService as ProductServiceContract
      ).createProductConfigWithChildren(product, agencyId, childrenIds);
      if (isErr()) {
        this.$log('error', 'product/saveProductSettings', unwrapErr());
        return;
      }
      this.wasSaved = true;
      this.showSnackbar('Data saved successfully', 'success');
    },
    async onProductSave(product: ProductSettings, agencyId: string): Promise<void> {
      const { isErr, unwrapErr, unwrap } = await (this.productService as ProductServiceContract).updateProductConfig(
        product,
        agencyId,
      );
      if (isErr()) {
        this.$log('error', 'product/saveProductSettings', unwrapErr());
        return;
      }
      this.wasSaved = true;
      this.showSnackbar('Data saved successfully', 'success');
      this.setProduct(unwrap());
    },
    async onProductSaveWithChildren(product: ProductSettings, agencyId: string, childrenIds: string[]): Promise<void> {
      const { isErr, unwrapErr, unwrap } = await (
        this.productService as ProductServiceContract
      ).updateProductConfigWithChildren(product, agencyId, childrenIds, this.saveToChildren.type === 'copy');
      if (isErr()) {
        this.$log('error', 'product/saveProductSettings', unwrapErr());
        return;
      }
      this.wasSaved = true;
      this.showSnackbar('Data saved successfully', 'success');
      this.setProduct(unwrap());
    },
    close(): void {
      if (this.dataUpdated) {
        this.$confirm
          .show({
            title: 'Unsaved changes',
            body: 'Are you sure you want to leave the modal window without saving changes?',
            cancelText: 'Leave',
            confirmText: 'Stay',
          })
          .then(result => {
            const { confirmed } = result;
            if (!confirmed) {
              this.$emit('input', false);
            }
          })
          .catch(() => {
            this.$emit('input', false);
          });
        return;
      } else {
        this.dataUpdated = false;
        if (this.wasSaved) this.$emit('refresh');
        this.$emit('input', false);
      }
    },
  },
});

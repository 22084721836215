import { Module } from 'vuex';
import { AdProductUser } from '@/shared/types';
import { RouteItemProps } from './types';
import { Container } from 'inversify';

import { state } from './state';
import { getters } from './getters';
import { mutations } from './mutations';
import { actions } from './actions';
import { RootState } from '../root/state';

interface AuthState {
  user: AdProductUser | null;
  editUserId: string;
  agencyUsers: {
    loaded: boolean;
    list?: AdProductUser[];
  };
  checkingAuth: boolean;
  authChecked: boolean;
  loadingUser: boolean;
  loadingCreateUser: boolean;
  loadingAgencyUsers: boolean;
  routes: RouteItemProps[];
}

export const authModule = (container: Container): { module: Module<AuthState, RootState> } => {
  return {
    module: {
      namespaced: true,
      state: state(),
      getters: getters(),
      mutations: mutations(),
      actions: actions(container),
    },
  };
};

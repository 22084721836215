import 'reflect-metadata';
import { EnvServiceContract } from '@/injectables';
import { injectable } from 'inversify';
import { Environment } from '../contracts';

@injectable()
export class EnvService implements EnvServiceContract {
  isDevelopment(): boolean {
    return this.getEnvironment() === Environment.Development;
  }

  isStaging(): boolean {
    return this.getEnvironment() === Environment.Staging;
  }

  isProduction(): boolean {
    return this.getEnvironment() === Environment.Production;
  }

  isLocalDev(): boolean {
    return this.getEnvironment() === Environment.Local;
  }

  isMixpanelTracking(): boolean {
    return this.isDevelopment() || this.isProduction();
  }

  getEnvironment(): string {
    return process.env.VUE_APP_ENVIRONMENT || Environment.Local;
  }

  getOrThrow<T>(variable: string, converter: (v: string) => T): T {
    const _variable = process.env[variable];
    if (!_variable) {
      throw new Error(`Variable with name: ${variable} is not found`);
    }
    return converter(_variable);
  }

  getEnvironmentName(short?: boolean): Environment {
    if (this.isDevelopment() || this.isLocalDev()) {
      return Environment.Development;
    } else if (this.isStaging()) {
      return short ? Environment.ShortStaging : Environment.Staging;
    }
    return Environment.Production;
  }

  isWhiteLabeledEnv(url: string): boolean {
    // TODO: rethink this. Ask compulse team how to get all existing whitelabels?
    const appHosts = ['mediaplanner', 'mediaplanner-0', 'mediaplanner-1', 'mediaplanner-2', 'mediaplanner-3'];
    const [agencyCandidate] = url.split('.');

    return !appHosts.includes(agencyCandidate);
  }
}

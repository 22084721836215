import 'reflect-metadata';
import { injectable } from 'inversify';
import { FilesModelContract } from '@/injectables';

@injectable()
export class FilesModel implements FilesModelContract {
  private readonly _separatorPrefix: string = 'sep=';

  getSizeValidator({ size, message }) {
    return (file: File) => {
      if (file?.size > size) {
        return message ?? false;
      }
      return true;
    };
  }

  getInvalidFileValidator({ message }) {
    return (file?: File) => {
      if (!file || file?.size === undefined) {
        return message ?? false;
      }
      return true;
    };
  }

  getEmptyFileValidator({ message }) {
    return (file?: File) => {
      if (file && file.size === 0) {
        return message ?? false;
      }
      return true;
    };
  }

  getFormatFileValidator({ formats, availableTypes, message }) {
    return (file: File) => {
      const filesTypeReg = new RegExp(`.*\.(${formats.join('|')})`);
      if (!availableTypes.includes(file.type) && !filesTypeReg.test(file.name)) {
        return message ?? false;
      }
      return true;
    };
  }

  fileName(url: string): string {
    const [fullName, fileType] = url.split('?')[0].split('/').reverse()[0].split('.');

    if (!fullName) return 'file.txt';

    const index = fullName.lastIndexOf('_');

    const fileName = fullName.substring(0, index); // remove s3 key

    return `${fileName}.${fileType}`;
  }

  parseZipsFromFile(file: File, limit: number): Promise<string[]> {
    return new Promise((resolve, reject) => {
      const separator = [',', 't', ';', '|', ':', 'n'];
      const separatorReg = new RegExp(separator.join('|\\'), 'g');
      const parseResult = (str: string): Array<string> =>
        str
          .split(separatorReg)
          .filter(item => !!item)
          .map(item => item.trim());
      const reader = new FileReader();
      reader.onload = event => {
        const items = parseResult(event.target.result as string);
        if (items.length !== 0) {
          resolve(items.slice(0, limit));
        } else {
          reject();
        }
      };
      reader.readAsText(file);
    });
  }

  getSeparator(fileContent: string[], headerLength): string {
    const firstLine = fileContent[0];
    const sep = firstLine.replace(this._separatorPrefix, '').trim();
    if (firstLine.includes(this._separatorPrefix) && sep) return sep;
    if (firstLine.split(';').length === headerLength) return ';';
    if (firstLine.split(',').length === headerLength) return ',';
    throw new Error('Invalid separator');
  }

  parseGeofence(str: string, headerLength: number): Array<{ address: string; radius: number }> {
    const fileInfo = str.split(/\n/g).filter(Boolean);
    const sep = this.getSeparator(fileInfo, headerLength);
    const content = this.getContentWithoutHeader(fileInfo);

    return content.map(item => {
      const [address, city, state, zip, radius] = item.split(sep).map(el => el.trim());
      return { address: [address, city, state, zip].filter(Boolean).join(', '), radius: this.parseRadius(radius) };
    });
  }

  parseRadius(radius: string): number {
    const radiusNumber = parseFloat(radius);
    if (radiusNumber < 0.25 || Number.isNaN(radiusNumber)) throw new Error('Invalid radius');
    return radiusNumber;
  }

  getContentWithoutHeader = (fileContent: string[]): string[] => {
    const firstLine = fileContent[0];

    // Geofences without header
    if (!(firstLine.includes('State') && firstLine.includes('Zip') && firstLine.includes('Radius in Miles'))) {
      return fileContent;
    }

    if (firstLine.includes('sep=')) return fileContent.slice(2);

    return fileContent.slice(1);
  };

  parseGeoFenceFile(file: File, limit = 100): Promise<Array<{ address: string; radius: number }>> {
    const headerLength = 5;

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = event => {
        try {
          const items = this.parseGeofence(event.target.result as string, headerLength);
          resolve(items.slice(0, limit));
        } catch (e) {
          reject();
        }
      };
      reader.readAsText(file);
    });
  }

  parseKeywordsFile(file: File, limit = 50): Promise<string[]> {
    const requiredTitle = 'Ideas/Keywords';
    return new Promise((resolve, reject) => {
      const parseResult = (str: string): Array<string> =>
        str
          .split(/\n/g)
          .filter(item => !!item)
          .map(item => item.trim());

      const reader = new FileReader();
      reader.onload = event => {
        const [title, ...items] = parseResult(event.target.result as string);
        if (title === requiredTitle) {
          resolve(items.slice(0, limit));
        } else {
          reject();
        }
      };
      reader.readAsText(file);
    });
  }

  parseGenericCsvKeywordsFile(file: File): Promise<string[]> {
    return new Promise((resolve, reject) => {
      const parseResult = (str: string): Array<string> =>
        str
          .split(/\n/g)
          .filter(item => !!item)
          .map(item => item.trim());

      const reader = new FileReader();
      reader.onload = event => {
        const [...items] = parseResult(event.target.result as string);
        resolve(items);
      };
      reader.readAsText(file);
    });
  }
}

import { render, staticRenderFns } from "./ProductCardTemplate.vue?vue&type=template&id=6d19f0c2&scoped=true"
import script from "./ProductCardTemplate.vue?vue&type=script&lang=ts"
export * from "./ProductCardTemplate.vue?vue&type=script&lang=ts"
import style0 from "./ProductCardTemplate.vue?vue&type=style&index=0&id=6d19f0c2&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d19f0c2",
  null
  
)

export default component.exports

import Vue from 'vue';
import { ConfiguredProduct, ProductConfig } from '@/shared/types';
import {
  CampaignModelContract,
  ClientServiceContract,
  ConfiguredProductIO,
  DateModelContract,
  FileServiceContract,
  GeoModelContract,
  InstantCampaign,
  InstantIOServiceContract,
  InstantModelContract,
  KeywordsModelContract,
  ProductModelContract,
  ProductServiceContract,
  TrackerContract,
} from '@/injectables';
import { Models, Services } from '@/injectables/tokens';

// entities
import ModalWindow from '@/entities/instantIO/ui/templates/ModalWindow.vue';
import ReducibleCard from '@/entities/instantIO/ui/templates/ReducibleCard.vue';
import CardContainer from '@/entities/instantIO/ui/templates/CardContainer.vue';
import StatusIcon from '@/entities/instantIO/ui/templates/StatusIcon.vue';
import OutlinedCard from '@/entities/instantIO/ui/templates/OutlinedCard.vue';

// Info
import ShowInfoCard from '@/widgets/instant-io/ui/info-card/show-content-card.vue';
import EditableInfoCard from '@/widgets/instant-io/ui/info-card/editable-card.vue';
// Demographics
import ShowDemographicsCard from '@/widgets/instant-io/ui/demographic-card/show-content-card.vue';
import EditableDemographicsCard from '@/widgets/instant-io/ui/demographic-card/editable-card.vue';
// GeoSelections
import EditableGeoCard from '@/widgets/instant-io/ui/geoselections-card/editable-card.vue';
import ShowGeoCard from '@/widgets/instant-io/ui/geoselections-card/show-content-card.vue';
// Products
import EditableProductCard from '@/widgets/instant-io/ui/products-card/editable-card.vue';
import ShowProductCard from '@/widgets/instant-io/ui/products-card/show-content-card.vue';
import ProductCard from '@/features/instant-io/ui/ProductCard.vue';

// Configuration
import EditableConfigurationCard from '@/widgets/instant-io/ui/configuration-card/editable-card.vue';

// Conversion Tracker
import ConversionTracker from '@/widgets/instant-io/ui/conversion-tracker/conversion-tracker.vue';

import { InstantCampaignStatus, ProductConfigCategory } from '@/app/graphql';

export default Vue.extend({
  name: 'InstantIODialog',

  inject: ['$confirm', 'showSnackbar'],

  useInjectable: [
    Services.Tracker,
    Services.Product,
    Services.Client,
    Services.InstantIO,
    Models.Campaign,
    Models.InstantIO,
    Models.Geo,
    Models.Date,
    Models.Product,
    Models.Keywords,
  ],

  provide() {
    const instantIO = {};

    Object.defineProperty(instantIO, 'campaign', {
      enumerable: true,
      get: () => this.campaign,
    });
    Object.defineProperty(instantIO, 'campaignMarket', {
      enumerable: true,
      get: () => this.formattedGeo,
    });

    return { instantIO };
  },

  components: {
    ModalWindow,
    ReducibleCard,
    CardContainer,
    StatusIcon,
    ShowInfoCard,
    EditableInfoCard,
    ShowDemographicsCard,
    EditableDemographicsCard,
    EditableGeoCard,
    ShowGeoCard,
    EditableProductCard,
    ShowProductCard,
    EditableConfigurationCard,
    OutlinedCard,
    ProductCard,
    ConversionTracker,
  },

  props: {
    value: {
      type: Boolean,
      required: true,
    },
    nonRouted: {
      type: Boolean,
      default: false,
    },
    instantIoId: {
      type: String,
      default: '',
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    createdClientId: {
      type: Object,
      required: false,
    },
  },

  data(): {
    step: number;
    lastStep: number;
    allProducts: ConfiguredProduct[];
    productConfigs: ProductConfig[];
    currentGeoType: String;
    campaign: InstantCampaign;
    client: { name: string };
    validation: Record<string, boolean>;
    loading: boolean;
    sendIOLoading: boolean;
    nationalCampaignGeoTypeValue: string;
    trackerValidStatus: boolean;
    isConfirmCloseDialogOpen: boolean;
    hasChanged: boolean;
  } {
    const campaignEntity: CampaignModelContract = this.$container.get(Models.Campaign);
    const conversionTracking = campaignEntity.getDefaultTrackingConfiguration();

    return {
      loading: false,
      step: 1,
      lastStep: 2,
      allProducts: [],
      productConfigs: [],
      currentGeoType: 'DMA',
      nationalCampaignGeoTypeValue: 'NATIONAL',
      sendIOLoading: false,
      campaign: {
        name: '',
        dates: [],
        pixelRequest: false,
        goLiveWitoutRetargeting: true,
        conversionTracking: conversionTracking,
        selectedProducts: [],
        geoSelections: [],
        audience: [],
        demographics: [],
      },
      client: {
        name: '',
      },
      validation: {
        info: false,
      },
      trackerValidStatus: true,
      isConfirmCloseDialogOpen: false,
      hasChanged: false,
    };
  },

  async created() {
    this.getProducts(this.agencyPropertyId);
    this.$store.dispatch('targetingSegments/getTargeting');
  },

  watch: {
    value(newValue) {
      if (this.nonRouted && newValue) {
        this.initialize();
      }
    },
    createdClientId: {
      handler(newValue) {
        if (newValue) {
          this.updateClient(newValue);
        }
      },
      deep: true,
    },
    campaign: {
      handler() {
        this.hasChanged = true;
      },
      deep: true,
    },
  },

  async mounted() {
    this.initialize();
  },
  computed: {
    isNationalCampaign(): boolean {
      const nationalCampaignLength = (this.productEntity as ProductModelContract).statesAndDistrictsNumber?.length;
      return this.campaign.geoSelections.length === nationalCampaignLength && this.currentGeoType === 'NATIONAL';
    },
    campaignCompleted() {
      return this.campaign.status === InstantCampaignStatus.Sold;
    },
    campaignDuration() {
      const [startDate, endDate] = this.campaign.dates || [];
      if (!startDate || !endDate) return 1;
      return (this[Models.Date] as DateModelContract).getFullMonthDuration(new Date(startDate), new Date(endDate));
    },
    canSetPixelRequest() {
      return this.$store.state.agency?.currentAgencyInfo?.canSetPixelRequestForInstantCampaign;
    },
    canSetConversionTracking() {
      return this.$store.state.agency?.currentAgencyInfo?.canSetConversionTracking;
    },
    sendIoEmailEnabled() {
      return this.$store.state.agency?.currentAgencyInfo?.ioEmailEnabled;
    },
    placementEnabled() {
      return this.$store.state.agency?.currentAgencyInfo?.placementEnabled;
    },
    instantId() {
      return this.instantIoId || this.$route.query?.id || null;
    },
    agencyPropertyId() {
      return this.$store.state.auth.user.Agency;
    },
    agencyName() {
      return this.$store.state.auth?.user?.agency?.name;
    },
    activeUser() {
      return this.$store.state.auth.user;
    },
    isUserSelected() {
      return Boolean(this.client.name);
    },
    modalConfigured() {
      return this.infoValidation && this.geoValidation && this.productsValidation && this.configurationValidation;
    },
    infoValidation() {
      return (
        this.isUserSelected && this.campaign.dates.length === 2 && (this.validation.info || this.campaignCompleted)
      );
    },
    keywordsValidation(): boolean {
      if (!this.campaign?.selectedProducts) return true;
      return this.campaign.selectedProducts.every(product => {
        if (!product.flights) return true;

        return product.flights.every(flight => {
          return (this[Models.Keywords] as KeywordsModelContract).flightKeywordsConfigured(flight);
        });
      });
    },
    geoValidation() {
      return Boolean(this.campaign.geoSelections.length);
    },
    productsValidation() {
      return Boolean(this.campaign.selectedProducts.length);
    },
    configurationValidation() {
      if (!this.campaign.selectedProducts.length) return false;
      const instantEntity: InstantModelContract = this.instantIOEntity;
      return this.campaign.selectedProducts.every(pr => {
        return instantEntity.productIsConfigured(pr) && instantEntity.creativeConfigured(pr);
      });
    },
    logger() {
      return this.$container.get('logger');
    },
    campaignBudget() {
      return this.campaign.selectedProducts.reduce((acc, pr) => (acc += pr.budget), 0);
    },
    disabledMessage() {
      if (this.campaignCompleted) return "Campaign can't be changed";
      if (!this.infoValidation) return "Instant io can't be saved without client and dates";
      if (!this.isTrackerValid) return "Instant io can't be saved with invalid Conversion Tracking configuration";
      if (!this.keywordsValidation)
        return `Instant IO can't be saved if the amount of keywords is above ${
          (this[Models.Keywords] as KeywordsModelContract).__maxAmountKeywords
        }`;
      return null;
    },
    formattedGeo() {
      const geoOptions = {
        zip: 'zipList',
        city: 'cityList',
        dma: 'dmaList',
        state: 'stateList',
        county: 'countyList',
        national: 'stateList',
      };
      const geo = this.currentGeoType?.toLowerCase();
      return { [geoOptions[geo]]: this.campaign.geoSelections };
    },
    cleanSelectedProducts() {
      return (this.campaign.selectedProducts || []).map(product => {
        const calcMinSpend = (this[Models.InstantIO] as InstantModelContract).getCalcMinSpend(
          product,
          this.campaignDuration,
        );
        return { ...product, calcMinSpend };
      });
    },
    cleanAllProducts() {
      return (this.allProducts || []).map(product => {
        const calcMinSpend = (this[Models.InstantIO] as InstantModelContract).getCalcMinSpend(
          product,
          this.campaignDuration,
        );
        return { ...product, calcMinSpend };
      });
    },
    isTrackerValid() {
      if (
        (!this.campaign.conversionTracking.visits.trackVisits &&
          !this.campaign.conversionTracking.conversion.trackConversion) ||
        !this.canSetConversionTracking
      )
        return true;

      return this.trackerValidStatus;
    },
    canConfigureTracker() {
      return (
        this.canSetConversionTracking &&
        this.campaign.selectedProducts.some(pr =>
          (this[Models.InstantIO] as InstantModelContract).isTrackableProduct(pr),
        )
      );
    },
  },

  methods: {
    async initialize() {
      if (!this.instantId) {
        const client = this.$store.getters['client/activeClient'] || false;
        if (this.nonRouted && client) {
          this.updateClient(client);
        }
        this.$nextTick(() => {
          this.hasChanged = false;
        });
        return;
      }

      this.loading = true;
      const { unwrap, isErr } = await this[Services.InstantIO].getInstantIo(this.instantId);
      this.loading = false;
      if (isErr()) return;
      this.initInstantIo(unwrap());
    },
    async tryToClose() {
      if (!this.hasChanged) {
        this.close();
        return;
      }

      const { confirmed, declined } = await this.$confirm.show({
        title: 'Save Instant IO before exiting?',
        confirmText: 'Yes',
        declineText: 'No',
        cancelText: 'Cancel',
      });

      if (confirmed) {
        return this.saveThenClose();
      }

      if (declined) {
        return this.close();
      }

      return this.keepEditing();
    },
    async saveThenClose() {
      await this.save();
      this.close();
    },
    keepEditing() {
      this.isConfirmCloseDialogOpen = false;
    },
    close() {
      if (this.nonRouted) {
        return this.$emit('close');
      }
      const query = this.$route.query;
      this.$router.replace({
        path: this.$route.path,
        query: {
          ...query,
          modal: '',
          id: '',
        },
      });
    },
    resetModalData() {
      const conversionTracking = (this[Models.Campaign] as CampaignModelContract).getDefaultTrackingConfiguration();
      this.campaign = {
        name: '',
        dates: [],
        selectedProducts: [],
        geoSelections: [],
        audience: [],
        demographics: [],
        pixelRequest: false,
        goLiveWitoutRetargeting: true,
        conversionTracking,
      };
      this.$nextTick(() => {
        this.hasChanged = false;
      });
    },
    setDemographics(demographics) {
      this.campaign.demographics = demographics;
    },
    setCensus(census) {
      this.campaign.audience = census;
    },
    updateGeo(newGeo) {
      this.campaign.geoSelections = newGeo;
      this.updateProductsAfterGeoChanged();
    },
    updateProductsAfterGeoChanged() {
      const instantEntity: InstantModelContract = this[Models.InstantIO];
      this.campaign.selectedProducts = this.cleanSelectedProducts.map(product =>
        instantEntity.getProductAfterGeoChanged(product),
      );
    },
    changeGeoType(val) {
      this.currentGeoType = val;

      const clientGeo = this.client?.geoSelections;
      const clientGeoType = this.getClientGeoType(clientGeo);

      if (clientGeoType === val) {
        const cleanGeoType = clientGeo?.[`${clientGeoType.toLowerCase()}List`] || [];
        this.campaign.geoSelections = cleanGeoType.map(el => el);
      } else {
        this.campaign.geoSelections = [];
      }
    },
    async updateClient(client) {
      if (this.client.name) this.resetModalData();

      if (!client) {
        this.client = {};
        return;
      }
      this.client = client;
      this.getClientInfo(client.id);
    },
    setProduct(product: ConfiguredProductIO) {
      const index = (this.instantIOEntity as InstantModelContract).getLastIndex(this.campaign.selectedProducts);
      const newProduct = (this.instantIOEntity as InstantModelContract).getNewProduct(
        product,
        {
          budget: product.calcMinSpend,
          dates: this.campaign.dates,
          geoSelections: this.formattedGeo,
          locations: this.client.locations,
          demographics: this.campaign.demographics,
          audience: this.campaign.audience,
        },
        index + 1,
      );
      this.campaign.selectedProducts.push(newProduct);
    },
    removeProduct(product: ConfiguredProductIO) {
      this.campaign.selectedProducts = this.campaign.selectedProducts.filter(p => p.productConfigId !== product.id);
    },
    addNewFlight(product: ConfiguredProductIO) {
      if (!product?.flights) {
        return;
      }
      product.flights.push(this.getNewFlight(product, this.freeBudget(product)));
      this.setProductUpdates(product);
    },
    getNewFlight(product: ConfiguredProductIO, budget) {
      return (this.instantIOEntity as InstantModelContract).getNewFlight(product, {
        budget,
        dates: this.campaign.dates,
        geoSelections: this.formattedGeo,
        locations: this.client.locations,
        demographics: this.campaign.demographics,
        audience: this.campaign.audience,
      });
    },
    freeBudget(pr: ConfiguredProductIO) {
      const { budgetDiff } = (this.instantIOEntity as InstantModelContract).budgetInfo(pr);
      if (budgetDiff > 0) return budgetDiff;
      return 0;
    },
    addNewCreative(product: ConfiguredProductIO) {
      const newCreative = (this.instantIOEntity as InstantModelContract).getNewCreative(
        product,
        product.creativesConfig,
      );
      product.creatives.push(newCreative);
      this.setProductUpdates(product);
    },
    addNewQuestionnaire(product: ConfiguredProductIO) {
      const config = this.cleanAllProducts.find(p => p.id === product.productConfigId);
      const questions = config?.questions || [];
      const newQuestionnaire = (this.instantIOEntity as InstantModelContract).getNewQuestionnaire(questions);
      product.questionnaire = newQuestionnaire;
      this.setProductUpdates(product);
    },
    async getClientInfo(clientId: string) {
      const { isErr, unwrap, unwrapErr } = await (this.clientService as ClientServiceContract).getById(clientId);
      if (isErr()) {
        this.logger.print('error', 'get client by id', unwrapErr().message);
        return;
      }
      this.client = unwrap();
      this.setDefaultGeo(this.currentGeoType);
    },
    async getProducts(agencyPropertyId: string) {
      const { isErr, unwrap, unwrapErr } = await (this.productService as ProductServiceContract).getConfigs(
        agencyPropertyId,
      );
      if (isErr()) {
        this.logger.print('error', 'get products by agencyId', unwrapErr().message);
        return;
      }
      const filtered = unwrap().filter(
        product =>
          !product.isHidden &&
          product.category !== ProductConfigCategory.Package &&
          !(this.instantIOEntity as InstantModelContract).isXMLProduct(product || {}),
      );
      this.allProducts = filtered;
    },
    setDefaultGeo(): void {
      const clientGeo = this.client?.geoSelections;
      const clientGeoType = this.getClientGeoType(clientGeo);

      this.currentGeoType = clientGeoType;
      const cleanGeoType = clientGeo?.[`${clientGeoType.toLowerCase()}List`] || [];
      this.campaign.geoSelections = cleanGeoType.map(el => el);
    },
    getClientGeoType(geo): string {
      if (geo?.zipList?.length) return 'ZIP';
      if (geo?.cityList?.length) return 'CITY';
      if (geo?.dmaList?.length) return 'DMA';
      if (geo?.stateList?.length) return 'STATE';
      if (geo?.countyList?.length) return 'COUNTY';
      return 'DMA';
    },
    updateProductBudget({ product, budget }) {
      this.setProductUpdates({ ...product, budget: budget });
    },
    updateProduct(product: ConfiguredProductIO) {
      this.setProductUpdates(product);
    },
    setProductUpdates(product: ConfiguredProductIO) {
      const productIndex = this.campaign.selectedProducts.findIndex(p => p.id === product.id);
      if (productIndex === -1) return;

      if (
        (product.flights || []).some(
          el =>
            (this[Models.InstantIO] as InstantModelContract).isFlightRateTypeQuote(el) ||
            (this[Models.InstantIO] as InstantModelContract).isFlightRateTypeCpm(el),
        )
      ) {
        return this.campaign.selectedProducts.splice(productIndex, 1, {
          ...product,
          isLocked: product.flights.length ? product.flights.every(el => el.isLocked) : false,
        });
      }

      this.campaign.selectedProducts.splice(productIndex, 1, product);
    },
    async downloadIo() {
      this.tracker &&
        (this.tracker as TrackerContract).trackInstantIoDownloaded({
          campaign: { ...this.campaign, client: this.client },
        });
      const fileService: FileServiceContract = this.$container.get(Services.File);

      const timeOffset = new Date().getTimezoneOffset();

      if (this.campaign.status !== InstantCampaignStatus.Sold) {
        const result = await this.save();
        if (!result) return;
      }

      this.$store.dispatch('showSnackbar', {
        content: 'Generating Excel file, please wait for download to start...',
        color: 'success',
        timeout: -1,
        withLoader: true,
      });

      const trackDownloadProgress = () => {
        this.$store.dispatch('hideSnackbar');
      };

      const fileName = `${this.campaign.name}_${this.client.name || this.client.id}_${
        this.client.AgencyPartner
      }_${new Date(this.campaign.dates[0]).toLocaleDateString('en-US')}`;

      const { isErr, unwrapErr } = await fileService.downloadInstantXlsx(
        {
          id: this.instantId,
          timezoneOffset: timeOffset,
          name: fileName,
        },
        trackDownloadProgress,
      );

      if (isErr()) {
        this.$log('error', 'ProductSemKeywords/export error', unwrapErr());

        this.$store.dispatch('showSnackbar', {
          content: 'error generating Excel file, please try again',
          color: 'error',
        });
      }
    },
    async save() {
      this.tracker &&
        (this.tracker as TrackerContract).trackInstantIoSaved({ campaign: { ...this.campaign, client: this.client } });
      const preparedCampaign = (this[Models.InstantIO] as InstantModelContract).getPreparedCampaign(
        this.campaign,
        this.client,
        this.currentGeoType,
        this.canSetConversionTracking,
      );
      this.loading = true;
      const { isErr, unwrap } = await (this[Services.InstantIO] as InstantIOServiceContract).updateOrCreateInstantIO(
        preparedCampaign,
        this.instantId,
      );
      this.loading = false;

      if (isErr()) return false;
      this.initInstantIo(unwrap());
      if (this.nonRouted) this.$emit('instantIoUpdated');
      this.$nextTick(() => {
        this.hasChanged = false;
      });
      return true;
    },
    initInstantIo(instant) {
      const { id, campaign, client, geoType } = (this[Models.InstantIO] as InstantModelContract).formatInstantCampaign(
        instant,
      );

      this.campaign = campaign;
      this.client = client;
      this.currentGeoType = geoType;
      this.$nextTick(() => {
        this.hasChanged = false;
      });
      if (!this.instantId && !this.nonRouted) {
        this.$router.replace({
          path: this.$route.path,
          query: { ...this.$route.query, id },
        });
      }
      if (campaign.status === InstantCampaignStatus.Sold) this.step = 2;
    },
    cleanGeo(geoName): string {
      return (this[Models.Geo] as GeoModelContract).cleanGeoString(geoName);
    },
    async submitOrder() {
      try {
        const { confirmed } = await this.$confirm.show({
          title: 'Submit Order?',
          body: 'Are you sure you want to submit this order for fulfillment?',
          confirmText: 'Confirm',
          cancelText: 'Cancel',
        });
        if (!confirmed) return;
        this.sendIOLoading = true;

        const result = await this.save();

        if (!result) return;

        this.showSnackbar('Submitting order...', 'success');

        if (this.placementEnabled) await this.sendToPIO();
        else if (this.sendIoEmailEnabled) await this.sendEmailIo();

        await this.changeStatus(InstantCampaignStatus.Sold);

        this.tracker &&
          (this.tracker as TrackerContract).trackInstantIoSubmitted({
            campaign: { ...this.campaign },
          });

        this.showSnackbar('Order was successfully submitted', 'success');
      } catch (e) {
      } finally {
        this.sendIOLoading = false;
      }
    },
    async sendEmailIo() {
      const { isErr } = await (this[Services.InstantIO] as InstantIOServiceContract).sendIO(this.instantId);

      if (isErr()) {
        throw new Error("Can't submit order");
      }
    },
    async sendToPIO() {
      const { isErr } = await (this[Services.InstantIO] as InstantIOServiceContract).sendOrder(this.instantId);

      if (isErr()) {
        throw new Error("Can't submit order");
      }
    },
    async changeStatus(status) {
      const { unwrap, isErr } = await (this[Services.InstantIO] as InstantIOServiceContract).updateStatus(
        this.instantId,
        status,
      );
      if (isErr()) {
        return;
      }
      this.$set(this.campaign, 'status', unwrap().status);
      this.$nextTick(() => {
        this.hasChanged = false;
      });
    },
    updateDates(dates) {
      const instantEntity: InstantModelContract = this[Models.InstantIO];
      this.campaign.dates = dates;

      this.campaign.selectedProducts = this.cleanSelectedProducts
        .map(product => {
          if (product.calcMinSpend > product.budget) return { ...product, budget: product.calcMinSpend, changed: true };
          return product;
        })
        .map(product => {
          const { changed = false, ...cleanProduct } = product;
          if (changed) return instantEntity.getUpdatedProduct(cleanProduct);
          return cleanProduct;
        });
    },
  },
});

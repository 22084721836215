import { Result, Ok, Err } from '@sniptt/monads';
import { injectable } from 'inversify';

import { Failure } from '@/injectables/failure';
import { TargetingSegmentsServiceContract } from '../contracts';
import { Service } from '@/injectables/service';
import { SegmentCategory } from '@/app/graphql';
import {
  GetTargetingSegmentsDocument,
  GetTargetingSegmentsQuery,
  GetTargetingSegmentsQueryVariables,
} from '../graphql/queries/get-segments.generated';

@injectable()
export class TargetingSegmentsService extends Service implements TargetingSegmentsServiceContract {
  async getMainSegments(): Promise<Result<GetTargetingSegmentsQuery['getAudienceSegments']['items'], Failure>> {
    try {
      const { data, error } = await this._apollo.query<GetTargetingSegmentsQuery, GetTargetingSegmentsQueryVariables>({
        query: GetTargetingSegmentsDocument,
        variables: {
          limit: null,
          segmentCategories: [
            SegmentCategory.Demographic,
            SegmentCategory.Enthusiast,
            SegmentCategory.Shopping,
            SegmentCategory.Services,
            SegmentCategory.AudienceProfiles,
            SegmentCategory.SearchHistory,
            SegmentCategory.Occupation,
            SegmentCategory.AdvancedDemographic,
          ],
        },
      });
      if (error) {
        return Err({
          message: "Can't load audience targeting at this time.",
        });
      }
      return Ok(data.getAudienceSegments.items);
    } catch (error) {
      return Err({
        message: `Can't load audience targeting at this time: ${error}`,
      });
    }
  }

  async searchSegments(query): Promise<Result<GetTargetingSegmentsQuery['getAudienceSegments'], Failure>> {
    try {
      const { data, error } = await this._apollo.query<GetTargetingSegmentsQuery, GetTargetingSegmentsQueryVariables>({
        query: GetTargetingSegmentsDocument,
        variables: {
          query: query,
          limit: 0,
          segmentCategories: [SegmentCategory.Other],
        },
      });
      if (error) {
        return Err({
          message: "Can't load audience targeting at this time.",
        });
      }
      return Ok(data.getAudienceSegments);
    } catch (error) {
      return Err({
        message: `Can't load audience targeting at this time: ${error}`,
      });
    }
  }
}


import Vue from 'vue';
import { Services } from '@/injectables/tokens';
import { UploadServiceContract } from '@/injectables';
import ImageUploader from '../Basic/Uploader/ImageUploader.vue';

interface ClientLogoData {
  uploadingImage: boolean;
  imageDialogState: boolean;
  selectedClientId: string | null;
}

// TODO: move to scenes, cover by tests, decouple from store
export default Vue.extend({
  name: 'ClientLogo',
  components: {
    ImageUploader,
  },
  useInjectable: [Services.Env, Services.Upload],

  inject: ['showSnackbar'],

  props: {
    output: {
      type: Boolean,
      default: false,
    },
    inactive: {
      type: Boolean,
      default: false,
    },
    slideIsEmpty: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: '72',
    },
    color: {
      type: String,
      default: 'white',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    clientId: {
      type: String,
      default: '',
    },
  },

  data: (): ClientLogoData => ({
    selectedClientId: null,
    imageDialogState: false,
    uploadingImage: false,
  }),

  computed: {
    imageDialog: {
      get(): boolean {
        if (this.output) return this.$store.state.output.openImageUpload;
        return this.imageDialogState;
      },
      set(status: boolean): void {
        if (this.output) this.$store.dispatch('output/toggleImageUpload', status);
        else this.imageDialogState = status;
      },
    },
    innerLoading(): boolean {
      return this.uploadingImage || this.loading;
    },
    validLogo(): boolean {
      return !this.output && this.clientLogo;
    },
    clientLogo: {
      get(): string {
        return this.$store.state['client'].activeClient?.logo;
      },
      set({ logo }: { logo: string; avtr: string } | null): void {
        this.$store.dispatch('client/updateClientLogo', { clientLogo: logo, clientId: this.clientId });
        this.$emit('refresh');
      },
    },
  },

  methods: {
    async onLogoUpload(file: File) {
      const uploadService = this.uploadService as UploadServiceContract;
      const { isErr, unwrapErr, unwrap } = await uploadService.uploadLogo(file);
      if (isErr()) {
        this.uploadingImage = false;
        return this.showSnackbar(unwrapErr().message);
      }
      this.clientLogo = unwrap();
      this.uploadingImage = false;
      this.showSnackbar('Logo updated successfully', 'success');
    },
    async onImageUpload(file: File) {
      const uploadService = this.uploadService as UploadServiceContract;
      const { isErr, unwrapErr, unwrap } = await uploadService.uploadImage(file);
      if (isErr()) {
        this.uploadingImage = false;
        return this.showSnackbar(unwrapErr().message);
      }
      this.$emit('emit-image', unwrap());
      this.imageDialog = false;
      this.uploadingImage = false;
    },
    activateImageDialog(): void {
      if (this.inactive) return;
      this.imageDialog = true;
    },
  },
});

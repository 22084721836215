
import Vue, { PropType } from 'vue';
import {
  ValidationServiceContract,
  InstantModelContract,
  ConfiguredProductIO,
  BaseProductModelContract,
  GeoModelContract,
} from '@/injectables';
import { ProductConfig } from '@/shared/types';
import { Models, Services } from '@/injectables/tokens';

import ConfigProductListItem from '@/entities/instantIO/ui/templates/ConfigProductListItem.vue';
import WrapperWithTooltip from '@/components/WrapperWithTooltip.vue';
import StatusIcon from '@/entities/instantIO/ui/templates/StatusIcon.vue';

import CreativeCard from '@/features/instant-io/ui/CreativeCard.vue';

import MoneyField from '@/shared/ui/components/money-field.vue';

import ProductSemKeyword from '@/features/instant-io/ui/sem-keywords.vue';
import ProductOptions from '@/features/instant-io/ui/product-options.vue';

import Questionnaire from '@/components/Proposal/Finalize/Questionnaire.vue';
import FlightMap from '@/features/instant-io/ui/FlightMap.vue';

export default Vue.extend({
  name: 'EditableConfigurationCard',

  useInjectable: [Services.Validation, Models.InstantIO, Models.BaseProduct, Models.Geo],

  components: {
    ConfigProductListItem,
    WrapperWithTooltip,
    StatusIcon,
    ProductSemKeyword,
    CreativeCard,
    MoneyField,
    ProductOptions,
    Questionnaire,
    FlightMap,
  },
  props: {
    selected: {
      type: Array as PropType<ConfiguredProductIO[]>,
      required: true,
    },
    items: {
      type: Array as PropType<ConfiguredProductIO[]>,
      required: true,
    },
    configs: {
      type: Array as PropType<ProductConfig[]>,
      required: true,
    },
    agencyPropertyId: {
      type: String,
      required: true,
    },
    geoSelection: {
      type: Object,
    },
    campaignDuration: {
      type: Number,
      default: 1,
    },
    questions: {
      type: Array,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: (): {
    activeProductId: string;
    expandedIndex: number;
    expanded: boolean;
    page: number;
    budgetIsFocused: boolean;
    localBudget: number;
    openConfigMap: boolean;
    isShowMap: boolean;
    activeFlightId;
  } => ({
    page: 0,
    activeProductId: '',
    expandedIndex: 0,
    expanded: true,
    budgetIsFocused: false,
    localBudget: 0,
    openConfigMap: false,
    isShowMap: false,
    activeFlightId: null,
  }),

  computed: {
    geoHasZips() {
      return this.geoSelection.zipList && this.geoSelection.zipList.length > 0;
    },
    activeProductQuestionnaire() {
      const productType = this.activeProduct?.type;
      const config = this.items.find(p => p.type === productType);
      const questions = config?.questions;
      const answers = this.activeProduct?.questionnaire;

      if (!(answers && answers.length)) {
        return [];
      }

      answers?.slice()?.sort((a, b) => a?.index - b?.index);
      questions?.slice()?.sort((a, b) => a?.index - b?.index);

      const questionnaireWithAnswers = questions?.map((q, i) => {
        return {
          ...q,
          question: {
            required: q?.required,
            text: q?.text,
          },
          answer: answers?.[i].answer || '',
          questionnaireId: q?.id,
        };
      });

      return questionnaireWithAnswers ?? [];
    },
    addCreativeButtonTitle(): string {
      return this.canHaveQuestionnaire ? '+ Begin Questionnaire' : '+ Add Creative';
    },
    loading() {
      return false;
    },
    rules() {
      const validationService: ValidationServiceContract = this.validationService;

      return {
        required: validationService.requiredValidatorFactory(),
        requiredMultiple: validationService.maxLengthValidatorFactory(),
        minRange: validationService.minRangeValidatorFactory(),
        minBudget: validationService.minBudgetValidatorFactory(),
      };
    },
    productMinSpend(): number {
      const lockedFlightBudget =
        this.activeProduct?.flights?.reduce((acc, flight) => {
          return flight.isLocked ? acc + flight.budget : acc;
        }, 0) || 0;
      return Math.max(this.activeProduct.calcMinSpend, lockedFlightBudget);
    },
    budget: {
      get(): number {
        return this.activeProduct.budget;
      },
      set(budget: number): void {
        this.updateProduct({ ...this.activeProduct, budget: budget });
      },
    },
    activeProduct() {
      return this.selected.find(p => p.id === this.activeProductId) || this.selected[0];
    },
    canHaveQuestionnaire(): boolean {
      return (this.instantIOEntity as InstantModelContract).canHaveQuestionnaire(this.activeProduct);
    },
    isPaidSearch(): boolean {
      return (this.instantIOEntity as InstantModelContract).isPaidSearchProduct(this.activeProduct);
    },
    addFlightDisabled() {
      return this.activeProduct?.flights?.length >= 12;
    },
    addCreativeDisabled() {
      if (this.canHaveQuestionnaire) {
        return false;
      }

      return this.activeProduct.creatives.length >= this.activeProduct.creativesConfig.creativesLimit;
    },
    ottForecasting() {
      return (this[Models.BaseProduct] as BaseProductModelContract).hasOTT(this.activeProduct?.flightConfigs);
    },
    activeFlight() {
      return this.activeProduct?.flights.find(f => f.id === this.activeFlightId) || null;
    },
    showAlert() {
      return (this.instantIOEntity as InstantModelContract).noneTargetingSupport(this.activeProduct);
    },
  },
  methods: {
    updateQuestion(value: string, index: number): void {
      const questionnaire = this.activeProduct.questionnaire;
      questionnaire?.sort((a, b) => a?.index - b?.index);
      let questionToUpdate = questionnaire[index];

      questionToUpdate = { ...questionToUpdate, answer: value };

      this.activeProduct.questionnaire.splice(index, 1, questionToUpdate);
      this.updateProduct({ ...this.activeProduct }, true);
    },
    emitCreateCreativeEvent() {
      if (this.canHaveQuestionnaire) {
        this.$emit('add-questionnaire', this.activeProduct);
      } else {
        this.$emit('add-creative', this.activeProduct);
      }
    },
    updateAutoRenew(autoRenew: string, flight) {
      const newFlight = { ...flight, AutoRenewType: autoRenew };
      this.updateFlight(newFlight);
    },
    productIcon(product: ConfiguredProductIO): string {
      return (this[Models.BaseProduct] as BaseProductModelContract).categoryIconAndColor(product.category as any).icon;
    },
    isActiveProduct(product: ConfiguredProductIO): boolean {
      return this.activeProduct.id === product.id;
    },
    setActiveProduct(product: ConfiguredProductIO): void {
      this.activeProductId = product.id;
    },
    productConfigured(product: ConfiguredProductIO) {
      return this.setupConfigured(product) && this.creativeConfigured(product);
    },
    setupConfigured(product: ConfiguredProductIO) {
      return (this.instantIOEntity as InstantModelContract).productIsConfigured(product);
    },
    creativeConfigured(product: ConfiguredProductIO) {
      return (this.instantIOEntity as InstantModelContract).creativeConfigured(product);
    },
    updateFlight(flight) {
      const index = this.activeProduct.flights.findIndex(fl => fl.id === flight.id);

      const flights = this.activeProduct.flights.map(el => el);
      flights.splice(index, 1, { ...flight, isChanged: true });
      this.updateProduct({ ...this.activeProduct, flights: flights });
    },
    clearGeoSelections(): void {
      this.updateFlight({ ...this.activeFlight, market: { geoSelections: {} } });
    },
    switchLock(flight) {
      const { isLocked } = flight;
      this.updateFlight({ ...flight, isLocked: !isLocked });
    },
    removeFlight(i) {
      const updatedFlights = this.activeProduct?.flights
        .filter(({ id }) => i !== id)
        .map((flight, i) => ({
          ...flight,
          index: i + 1,
        }));
      this.updateProduct({ ...this.activeProduct, flights: updatedFlights });
    },
    showMap(flightId) {
      this.activeFlightId = flightId;
      this.isShowMap = true;
    },
    hideMap() {
      this.isShowMap = false;
      this.activeFlightId = '';
    },
    setGeoSelections({ target, targetArray }) {
      const newGeoSelections = { ...(this.activeFlight?.market?.geoSelections || {}) };
      newGeoSelections[(this[Models.Geo] as GeoModelContract).getGeoTypeList(target)] = targetArray;
      this.updateFlight({ ...this.activeFlight, market: { geoSelections: newGeoSelections } });
    },
    updateDemographics(demographics) {
      this.updateFlight({ ...this.activeFlight, demographics });
    },
    updateAudience(audience) {
      this.updateFlight({ ...this.activeFlight, audience });
    },
    updateCreative(creative, i) {
      const creatives = this.activeProduct.creatives.map(el => el);
      creatives.splice(i, 1, { ...creative });
      this.updateProduct({ ...this.activeProduct, creatives }, true);
    },
    addCreativeImage(i) {
      const currentCreative = { ...(this.activeProduct.creatives[i] || {}) };

      const newCreative = (this.instantIOEntity as InstantModelContract).getNewCreativeChild(
        currentCreative,
        this.activeProduct.creativesConfig,
      );
      currentCreative.creatives = [...(currentCreative.creatives || []), newCreative];

      this.updateCreative(currentCreative, i);
    },
    removeCreative(i) {
      const creatives = this.activeProduct?.creatives
        .filter((_, id) => i !== id)
        .map((creative, i) => ({
          ...creative,
          index: i + 1,
        }));
      this.updateProduct({ ...this.activeProduct, creatives }, true);
    },
    updateProduct(product: ConfiguredProductIO, skipBudgetUpdate = false) {
      const instantEntity: InstantModelContract = this.instantIOEntity;
      const updatedProduct = !skipBudgetUpdate ? instantEntity.getUpdatedProduct(product) : product;
      this.$emit('update-product', updatedProduct);
    },
  },
});


import Vue from 'vue';

export default Vue.extend({
  name: 'ShowGeoSelectionsCard',

  props: {
    selection: {
      type: Array,
      required: true,
    },
    maxNumber: {
      type: Number,
      default: 50,
    },
    inColumn: {
      type: Boolean,
      default: false,
    },
    geoNameFormatter: {
      type: Function,
      default: (el: String) => el,
    },
    isNationalCampaign: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    address(address: string): void {
      if (address) {
        this.addGeo(address);
        this.$nextTick(() => this.$refs?.mapAddressSearch?.reset());
      }
    },
    addressSearch(val: string): void {
      if (!val || val.length < 5) {
        return;
      }
      this.fetchingLocations = true;
      this.fetchAutocompleteApi(val);
    },
  },

  data() {
    return {
      address: '',
      addressSearch: '',
      geoTypes: ['State', 'DMA', 'County', 'City', 'Zip'],
      selectedGeoType: 'DMA',
      fetchedItems: [],
      fetchingLocations: false,
    };
  },
});

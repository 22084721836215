
import Vue, { PropType } from 'vue';
import { ProductConfiguration } from '@/shared/types';
import { ValidationServiceContract } from '@/injectables';
import { Services } from '@/injectables/tokens';

interface Data {
  name: string;
  minSpend: number;
  cpcMargin: number;
  recommendedBudget: number;
  description: Record<string, string>;
  minDays: number;
  isMonthly: boolean;
}

export default Vue.extend({
  name: 'EditProduct.Details',

  props: {
    product: {
      type: Object as PropType<ProductConfiguration>,
      required: true,
    },
    isChangeMarginAvailable: {
      type: Boolean,
      default: false,
    },
    minSpendLocked: {
      type: Boolean,
      default: false,
    },
    isValid: {
      type: Boolean,
      default: false,
    },
  },

  data(): Data {
    const {
      name = '',
      minSpend = 0,
      recommendedBudget = 0,
      description,
      cpcMargin,
      minDays = 0,
      isMonthly = false,
    } = this.product;
    return {
      name,
      minSpend,
      recommendedBudget,
      description,
      cpcMargin,
      minDays,
      isMonthly,
    };
  },

  computed: {
    rules() {
      const validationService: ValidationServiceContract = this.$container.get(Services.Validation);
      return {
        required: validationService.requiredValidatorFactory(),
        maxLength: validationService.maxLengthValidatorFactory(),
        number: validationService.isNumberValidatorFactory(),
      };
    },
    updatedProduct() {
      return {
        ...this.product,
        name: this.name,
        minSpend: this.minSpend || 0,
        recommendedBudget: this.recommendedBudget || 0,
        description: this.description,
        cpcMargin: this.cpcMargin,
        isMonthly: this.isMonthly,
        minDays: this.minDays ? parseInt(this.minDays.toString()) : this.minDays || 0,
      };
    },
    marginPercent: {
      get(): string {
        return `${parseInt(((this.cpcMargin || 0) * 100).toFixed())}`;
      },
      set(v: string): void {
        const parsed = parseInt(v);
        if (Number.isNaN(parsed)) return;
        this.cpcMargin = parsed / 100;
      },
    },
    descriptionOption: {
      get(): string {
        return this.product.description?.short;
      },
      set(val: string) {
        this.description = {
          short: val,
        };
      },
    },
  },

  watch: {
    updatedProduct: {
      handler() {
        this.$emit('update:details', this.updatedProduct);
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    isBetween(from: number, to: number): Function {
      const validationService: ValidationServiceContract = this.$container.get(Services.Validation);
      return validationService.isBetweenValidatorFactory({
        from,
        to,
        messages: {
          min: 'Too short',
          max: 'Too long',
        },
      });
    },
    validateMargin(from: number, to: number): Function {
      const validationService: ValidationServiceContract = this.$container.get(Services.Validation);
      return validationService.isBetweenValidatorFactory(
        {
          from,
          to,
          messages: {
            min: 'Too short',
            max: 'Too long',
          },
        },
        el => `${el}%`,
      );
    },
  },
});

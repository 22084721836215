import * as Types from '../../../../../app/graphql/_types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { ProposalPackageFragmentFragmentDoc } from '../../../../../app/graphql/fragments/package.fragment.generated';
import {
  ProductFragmentFragmentDoc,
  ProductContractsFragmentDoc,
} from '../../../../../app/graphql/fragments/product.fragment.generated';
import { TargetingSegmentFragmentDoc } from '../../../../../app/graphql/fragments/campaign.fragment.generated';
import { ProductCreativeFragmentDoc } from '../../../../../app/graphql/fragments/creatives.fragment.generated';
export type GetProposalCreativesQueryVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
}>;

export type GetProposalCreativesQuery = {
  getMediaplannerProposal?: {
    id: string;
    products: Array<
      | {
          __typename: 'MediaplannerPackage';
          id: string;
          index?: number | null;
          category: Types.ProductConfigCategory;
          name: string;
          budget: number;
          packageDescription?: string | null;
          products?: Array<{
            id: string;
            dsId?: string | null;
            name: string;
            index?: number | null;
            fulfillmentMethod: Types.ProductConfigFullfillmentMethod;
            isEditable?: boolean | null;
            isHidden?: boolean | null;
            isLocked?: boolean | null;
            minDays?: number | null;
            minSpend?: number | null;
            budget: number;
            omsAccountNumber?: string | null;
            omsName?: string | null;
            category: Types.ProductConfigCategory;
            description?: Record<string, any> | null;
            recommendedBudget?: number | null;
            flightType?: string | null;
            type?: Types.ProductConfigType | null;
            IORecipientEmail?: string | null;
            rateRangeList?: Record<string, any> | null;
            noXmlFlag?: boolean | null;
            keyMetric?: Types.KeyMetric | null;
            keyMetricMultiplier?: number | null;
            productConfigId: string;
            isMonthly?: boolean | null;
            calcMinSpend?: number | null;
            keywords?: {
              list?: Array<{
                keyword?: string | null;
                searchVolume?: number | null;
                competition?: number | null;
                competitionLevel?: string | null;
                averageCpc?: number | null;
                recommendLevel?: number | null;
                isSelected?: boolean | null;
                isCustom?: boolean | null;
              } | null> | null;
              summary?: {
                numKeywords?: number | null;
                totalCpc?: number | null;
                totalClicks?: number | null;
                lowerCpc?: number | null;
                upperCpc?: number | null;
                lowerClicks?: number | null;
                upperClicks?: number | null;
              } | null;
            } | null;
            broadcastInfo?: {
              link?: string | null;
              broadcast?: Array<{
                days?: Array<Types.Weekdays | null> | null;
                startTime: string;
                endTime: string;
                spots: number;
                spend: number;
                rating: number;
                programName: string;
                station: string;
              } | null> | null;
            } | null;
            geoSelections?: {
              cityList?: Array<{ key?: string | null; name?: string | null }> | null;
              countyList?: Array<{ key?: string | null; name?: string | null }> | null;
              dmaList?: Array<{ key?: string | null; name?: string | null }> | null;
              stateList?: Array<{ key?: string | null; name?: string | null }> | null;
            } | null;
            questionnaire?: Array<{ id: string; index: number; answer: string; questionnaireId: string } | null> | null;
            flights?: Array<{
              id: string;
              index?: number | null;
              budget?: number | null;
              startDate?: string | null;
              endDate?: string | null;
              rate?: number | null;
              rateType?: Types.RateType | null;
              selectedPlatform?: string | null;
              isLocked?: boolean | null;
              platformOption?: Array<string | null> | null;
              targetingOption?: string | null;
              flightCategory?: Types.FlightCategory | null;
              market?: Record<string, any> | null;
              mapType?: Types.MapType | null;
              contextualKeywords?: Array<string | null> | null;
              advancedOptions?: {
                autoRenewList?: Array<string | null> | null;
                abTestFlag?: boolean | null;
                darkPeriodList?: Array<{ index: number; startDate: string; endDate: string } | null> | null;
              } | null;
              audienceSegments?: Array<{
                id: string;
                name: string;
                externalId: string;
                description?: string | null;
                segmentCategory: Types.SegmentCategory;
                segmentSubCategory?: Types.SegmentSubCategory | null;
                isMain: boolean;
              }> | null;
            } | null> | null;
            flightConfigs?: Array<{
              id: string;
              markupMax?: number | null;
              markupMin?: number | null;
              rateType?: Types.RateType | null;
              rate?: number | null;
              adFormatList?: Record<string, any> | null;
              targetingOption?: string | null;
              flightCategory?: Types.FlightCategory | null;
              platformOption?: Array<string | null> | null;
              mapType?: Types.MapType | null;
              advancedOptions?: {
                autoRenewList?: Array<string | null> | null;
                abTestFlag?: boolean | null;
                darkPeriodList?: Array<{ index: number; startDate: string; endDate: string } | null> | null;
              } | null;
            } | null> | null;
            creatives?: Array<{
              id: string;
              index?: number | null;
              url?: string | null;
              creativeLink?: string | null;
              customMargin?: number | null;
              omsNumber?: string | null;
              notes?: string | null;
              vastTag?: string | null;
              socialLink?: string | null;
              postText?: string | null;
              headline?: string | null;
              description?: string | null;
              selectedAdFormatList?: Record<string, any> | null;
              flights?: Array<{ id: string } | null> | null;
              creatives?: Array<{
                id: string;
                index?: number | null;
                url?: string | null;
                creativeLink?: string | null;
                customMargin?: number | null;
                omsNumber?: string | null;
                notes?: string | null;
                vastTag?: string | null;
                socialLink?: string | null;
                postText?: string | null;
                headline?: string | null;
                description?: string | null;
              }> | null;
            } | null> | null;
          }> | null;
        }
      | {
          __typename: 'MediaplannerProduct';
          id: string;
          dsId?: string | null;
          name: string;
          index?: number | null;
          fulfillmentMethod: Types.ProductConfigFullfillmentMethod;
          isEditable?: boolean | null;
          isHidden?: boolean | null;
          isLocked?: boolean | null;
          minDays?: number | null;
          minSpend?: number | null;
          budget: number;
          omsAccountNumber?: string | null;
          omsName?: string | null;
          category: Types.ProductConfigCategory;
          description?: Record<string, any> | null;
          recommendedBudget?: number | null;
          flightType?: string | null;
          type?: Types.ProductConfigType | null;
          IORecipientEmail?: string | null;
          rateRangeList?: Record<string, any> | null;
          noXmlFlag?: boolean | null;
          keyMetric?: Types.KeyMetric | null;
          keyMetricMultiplier?: number | null;
          productConfigId: string;
          isMonthly?: boolean | null;
          calcMinSpend?: number | null;
          keywords?: {
            list?: Array<{
              keyword?: string | null;
              searchVolume?: number | null;
              competition?: number | null;
              competitionLevel?: string | null;
              averageCpc?: number | null;
              recommendLevel?: number | null;
              isSelected?: boolean | null;
              isCustom?: boolean | null;
            } | null> | null;
            summary?: {
              numKeywords?: number | null;
              totalCpc?: number | null;
              totalClicks?: number | null;
              lowerCpc?: number | null;
              upperCpc?: number | null;
              lowerClicks?: number | null;
              upperClicks?: number | null;
            } | null;
          } | null;
          broadcastInfo?: {
            link?: string | null;
            broadcast?: Array<{
              days?: Array<Types.Weekdays | null> | null;
              startTime: string;
              endTime: string;
              spots: number;
              spend: number;
              rating: number;
              programName: string;
              station: string;
            } | null> | null;
          } | null;
          geoSelections?: {
            cityList?: Array<{ key?: string | null; name?: string | null }> | null;
            countyList?: Array<{ key?: string | null; name?: string | null }> | null;
            dmaList?: Array<{ key?: string | null; name?: string | null }> | null;
            stateList?: Array<{ key?: string | null; name?: string | null }> | null;
          } | null;
          questionnaire?: Array<{ id: string; index: number; answer: string; questionnaireId: string } | null> | null;
          flights?: Array<{
            id: string;
            index?: number | null;
            budget?: number | null;
            startDate?: string | null;
            endDate?: string | null;
            rate?: number | null;
            rateType?: Types.RateType | null;
            selectedPlatform?: string | null;
            isLocked?: boolean | null;
            platformOption?: Array<string | null> | null;
            targetingOption?: string | null;
            flightCategory?: Types.FlightCategory | null;
            market?: Record<string, any> | null;
            mapType?: Types.MapType | null;
            contextualKeywords?: Array<string | null> | null;
            advancedOptions?: {
              autoRenewList?: Array<string | null> | null;
              abTestFlag?: boolean | null;
              darkPeriodList?: Array<{ index: number; startDate: string; endDate: string } | null> | null;
            } | null;
            audienceSegments?: Array<{
              id: string;
              name: string;
              externalId: string;
              description?: string | null;
              segmentCategory: Types.SegmentCategory;
              segmentSubCategory?: Types.SegmentSubCategory | null;
              isMain: boolean;
            }> | null;
          } | null> | null;
          flightConfigs?: Array<{
            id: string;
            markupMax?: number | null;
            markupMin?: number | null;
            rateType?: Types.RateType | null;
            rate?: number | null;
            adFormatList?: Record<string, any> | null;
            targetingOption?: string | null;
            flightCategory?: Types.FlightCategory | null;
            platformOption?: Array<string | null> | null;
            mapType?: Types.MapType | null;
            advancedOptions?: {
              autoRenewList?: Array<string | null> | null;
              abTestFlag?: boolean | null;
              darkPeriodList?: Array<{ index: number; startDate: string; endDate: string } | null> | null;
            } | null;
          } | null> | null;
          creatives?: Array<{
            id: string;
            index?: number | null;
            url?: string | null;
            creativeLink?: string | null;
            customMargin?: number | null;
            omsNumber?: string | null;
            notes?: string | null;
            vastTag?: string | null;
            socialLink?: string | null;
            postText?: string | null;
            headline?: string | null;
            description?: string | null;
            selectedAdFormatList?: Record<string, any> | null;
            flights?: Array<{ id: string } | null> | null;
            creatives?: Array<{
              id: string;
              index?: number | null;
              url?: string | null;
              creativeLink?: string | null;
              customMargin?: number | null;
              omsNumber?: string | null;
              notes?: string | null;
              vastTag?: string | null;
              socialLink?: string | null;
              postText?: string | null;
              headline?: string | null;
              description?: string | null;
            }> | null;
          } | null> | null;
        }
    >;
  } | null;
};

export const GetProposalCreativesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getProposalCreatives' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getMediaplannerProposal' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'products' },
                  name: { kind: 'Name', value: 'solutions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MediaplannerPackage' } },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProposalPackageFragment' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'products' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProductFragment' } },
                                  { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProductCreative' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MediaplannerProduct' } },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProductFragment' } },
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProductCreative' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...ProposalPackageFragmentFragmentDoc.definitions,
    ...ProductFragmentFragmentDoc.definitions,
    ...TargetingSegmentFragmentDoc.definitions,
    ...ProductCreativeFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetProposalCreativesQuery, GetProposalCreativesQueryVariables>;

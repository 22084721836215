import { Failure } from '@/injectables/failure';
import { ContractServiceContract, AddContractInput, RemoveContractInput } from '@/injectables';
import { Err, Ok, Result } from '@sniptt/monads/build';
import { Service } from '@/injectables/service';
import { injectable } from 'inversify';

import {
  AddContractDocument,
  AddContractMutation,
  AddContractMutationVariables,
} from '../graphql/mutations/add-contract.generated';
import {
  RemoveContractDocument,
  RemoveContractMutation,
  RemoveContractMutationVariables,
} from '../graphql/mutations/remove-contract.generated';
import {
  GetDownloadContractUrlQuery,
  GetDownloadContractUrlQueryVariables,
  GetDownloadContractUrlDocument,
} from '../graphql/query/get-download-contract-url.generated';

import axios from 'axios';

@injectable()
export class ContractService extends Service implements ContractServiceContract {
  async addContract(input: AddContractInput): Promise<Result<AddContractMutation['addContract'], Failure>> {
    try {
      const { data } = await this._apollo.mutate<AddContractMutation, AddContractMutationVariables>({
        mutation: AddContractDocument,
        variables: {
          input,
        },
      });

      const result = data.addContract;
      return Ok(result);
    } catch (error) {
      return Err({ message: "Can't upload contract at this time." });
    }
  }

  async removeContract(input: RemoveContractInput): Promise<Result<RemoveContractMutation['removeContract'], Failure>> {
    try {
      const { data } = await this._apollo.mutate<RemoveContractMutation, RemoveContractMutationVariables>({
        mutation: RemoveContractDocument,
        variables: {
          input,
        },
      });

      const result = data.removeContract;
      return Ok(result);
    } catch (error) {
      return Err({ message: "Can't remove contract at this time." });
    }
  }

  async fetchContract(url: string): Promise<Result<ArrayBuffer, Failure>> {
    try {
      const data = await axios({
        method: 'get',
        url,
        responseType: 'blob',
      });

      return Ok(data.data);
    } catch (error) {
      return Err({
        message: `Unable to fetch contract`,
      });
    }
  }

  async getContractDownloadUrl(id: string): Promise<Result<string, Failure>> {
    try {
      const { data, error } = await this._apollo.query<
        GetDownloadContractUrlQuery,
        GetDownloadContractUrlQueryVariables
      >({
        query: GetDownloadContractUrlDocument,
        variables: {
          id: id,
        },
      });

      if (error) {
        return Err({ message: "Can't get download link at this time" });
      }

      const result = data.getContractSignedUrlById;
      return Ok(result);
    } catch (error) {
      return Err({ message: "Can't get download link at this time" });
    }
  }
}

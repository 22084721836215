import { Module } from 'vuex';
import { RootState } from '../root/state';

interface LoadingState {
  show: number;
}

export const loadingModule = (): Module<LoadingState, RootState> => {
  return {
    namespaced: true,
    state: {
      show: 0,
    },
    mutations: {
      show(state) {
        state.show += 1;
      },
      hide(state) {
        state.show -= 1;
      },
    },
    getters: {
      showGlobalLoading(state): boolean {
        return state.show > 0;
      },
    },
  };
};

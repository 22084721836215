
import Vue from 'vue';
import { Services } from '@/injectables/tokens';
import { TaxonomyServiceContract } from '@/injectables';

export default Vue.extend({
  name: 'category-item',
  props: ['category', 'selected', 'index', 'categories', 'searchPage', 'detailsPage'],
  computed: {
    categoriesList() {
      if (!('categories' in this.category)) return [];
      return Object.values(this.category.categories);
    },
    last(): boolean {
      let last = true;

      if (this.category.name) {
        // if it has another element after it
        if (this.categories && this.categories[this.index + 1]) {
          const nextElement = this.categories[this.index + 1];
          if (nextElement.name) {
            last = false;
          }
        }
      }

      return last;
    },
    categoryName(): string {
      const { name } = this.category;
      const taxonomyService: TaxonomyServiceContract = this.$container.get(Services.Taxonomy);
      return name ? taxonomyService.getTitle(name) : '';
    },
    cssClass(): object {
      return {
        last: this.last,
        category: this.category.name,
        'category-label': this.category.title && this.categoriesList.length,
        // 'not-supported': this.category.name === this.selected && !this.category.WebsiteReady,
      };
    },
  },
  methods: {
    setCategory(category: object): void {
      this.$emit('set-category', category);
    },
  },
});

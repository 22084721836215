var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.numberOfLocations > 1)?_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.$emit('delete-location')}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-close")])],1):_vm._e(),(!_vm.editLabel)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('span',_vm._g({staticStyle:{"cursor":"pointer"},on:{"click":_vm.toggleEditLabel}},on),[_c('strong',[_vm._v(_vm._s(_vm.label))])])]}}],null,false,3234526280)},[_c('span',[_vm._v("Click to edit label name")])]):_c('v-text-field',{ref:"labelInput",staticClass:"label-input",attrs:{"dense":"","rules":[
      _vm.rules.required,
      _vm.rules.maxLength,
      v => _vm.localLabel === _vm.label || !_vm.clientLocations.find(({ label }) => label === v) || 'This label already exists',
    ]},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.submitUpdatedLabel.apply(null, arguments)},"blur":_vm.submitUpdatedLabel},model:{value:(_vm.localLabel),callback:function ($$v) {_vm.localLabel=$$v},expression:"localLabel"}}),_vm._v(" : "),(!_vm.editAddress)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('span',_vm._g({staticStyle:{"cursor":"pointer"},on:{"click":_vm.toggleEditAddress}},on),[_vm._v(_vm._s(_vm.address))])]}}],null,false,526347852)},[_c('span',[_vm._v("Click to edit address")])]):_c('v-autocomplete',{ref:"addressInput",staticClass:"address-input",attrs:{"required":"","items":_vm.items,"search-input":_vm.locationSearch,"hide-no-data":"","hide-selected":"","no-filter":"","placeholder":_vm.address,"rules":[
      _vm.rules.required,
      v =>
        _vm.localAddress.address === _vm.address ||
        !_vm.clientLocations.find(({ address }) => address === _vm.localAddress.address) ||
        'This address already exists',
    ]},on:{"update:searchInput":function($event){_vm.locationSearch=$event},"update:search-input":function($event){_vm.locationSearch=$event},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.submitUpdatedAddress.apply(null, arguments)},"blur":_vm.submitUpdatedAddress},model:{value:(_vm.localAddress),callback:function ($$v) {_vm.localAddress=$$v},expression:"localAddress"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
import { formattedDateStr } from './filters-common';

// Format date range:
// - should additionally format and return a single date (fix validation issue for datepicker)
// - input valid date Array[<number> or <string>] -> return formatted string <from> - <to>
// - invalid date Array[<number> or <string>] -> return placeholder
// - input 0 or null or "" -> return placeholder
// - data format MMM DD, YYYY (Jan 1, 1970)
export const filter = (dates: Array<number | string | null> | null, placeholder = ''): string => {
  try {
    if (dates && dates.length >= 1) {
      const dateFrom = new Date(new Date(`${dates[0]}`).toISOString().slice(0, -1));
      let dateTo, formattedFrom, formattedTo;
      if (dates.length === 2) {
        dateTo = new Date(new Date(`${dates[1]}`).toISOString().slice(0, -1));
      }
      if (dateFrom?.toString() && dateFrom.toString() !== 'Invalid Date') formattedFrom = formattedDateStr(dateFrom);
      if (dateTo?.toString() && dateTo.toString() !== 'Invalid Date') formattedTo = formattedDateStr(dateTo);
      if (formattedFrom && formattedTo) return `${formattedFrom} - ${formattedTo}`;
      else if (formattedFrom) return formattedFrom;
      else if (formattedTo) return formattedTo;
      return placeholder;
    }
    return placeholder;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('error', 'main.formatDateRange', dates, err);
    return placeholder;
  }
};

import { Proposal, ProposalRevenueData, ProposalSummary } from '@/shared/types';
import { MutationTree } from 'vuex';
import { AdminQueueProposal, EndingCampaign, ProposalState } from './state';

export const mutations = (): MutationTree<ProposalState> => ({
  SET_PROPOSALS_ENDING_CAMPAIGNS(_state, _campaigns: EndingCampaign[]): void {
    _state.endingCampaigns = { list: _campaigns, loaded: true };
  },
  SET_PROPOSALS_ADMIN_QUEUE(_state, _campaigns: AdminQueueProposal[]): void {
    _state.adminQueue = { list: _campaigns, loaded: true };
  },
  SET_PROPOSALS_ENDING_CAMPAIGNS_LOADING(_state, _value): void {
    _state.loadingEndingCampaigns = _value;
  },
  SET_PROPOSALS_ADMIN_QUEUE_LOADING(_state, _value): void {
    _state.loadingProposalsQueue = _value;
  },
  SET_PROPOSALS_SUMMARY_BY_CLIENT(_state, _list: []): void {
    _state.proposalsSummaryByClient = _list;
  },
  SET_PROPOSALS_SUMMARY_BY_CLIENT_LOADING(_state, _status: boolean): void {
    _state.proposalsSummaryByClientLoading = _status;
  },
  SET_CLONE_PROPOSAL_LOADING(_state, _status: boolean): void {
    _state.cloneProposalLoading = _status;
  },
  DELETE_PROPOSAL(_state, _index): void {
    _state.proposals.list.splice(_index, 1);
  },
  SET_ACTIVE_PROPOSAL_ID(_state, _id: string | null): void {
    _state.activeProposalId = _id;
  },
  SET_ACTIVE_PROPOSAL(_state, _proposal: Proposal): void {
    _state.activeProposal = _proposal;
  },
  UPDATE_STATE_ACTIVE_PROPOSAL(_state, _keyValue: { key: string; value: string }): void {
    _state.activeProposal[_keyValue.key] = _keyValue.value;
  },
  SET_PROPOSALS(_state, { PRPID, list, total }: { PRPID: string; list: Array<Proposal>; total: number }): void {
    _state.proposals = { loaded: true, list, PRPID, total };
  },
  SET_PROPOSALS_NOT_LOADED(_state): void {
    _state.allProposals.loaded = false;
    _state.adminQueue.loaded = false;
  },
  SET_ALL_PROPOSALS(_state, _data: { items: Array<Proposal>; total: number }): void {
    _state.allProposals = { loaded: true, list: [..._data.items], total: _data.total };
    _state.proposalsInitialized = true;
  },
  RESET_PROPOSALS(_state): void {
    _state.proposals = { loaded: false };
  },
  LOADING_PROPOSALS(_state, _status: boolean): void {
    _state.loadingProposals = _status;
  },
  LOADING_ALL_PROPOSALS(_state, _status: boolean): void {
    _state.loadingAllProposals = _status;
  },
  LOADING_STATUSES(_state, _status: boolean): void {
    _state.loadingStatuses = _status;
  },
  SET_PROPOSAL_ERROR(_state, _errorObject: { status: boolean; message?: string }): void {
    _state.proposalError = _errorObject;
  },
  SET_SAVE_PROGRESS_LOADING(_state, _status): void {
    _state.saveProgressLoading = _status;
  },
  SET_STATUSES(_state, _statuses): void {
    _state.statuses = _statuses;
  },
  SET_PROPOSAL_REVENUE_SUMMARY(_state, _products: ProposalRevenueData[]): void {
    _state.activeProducts = _products;
  },
  SET_PROPOSAL_SUMMARY(_state, _data: ProposalSummary[]): void {
    _state.proposalsSummary = _data;
  },
  SET_PROPOSAL_SUMMARY_LOADING(_state, _data: boolean): void {
    _state.proposalsSummaryLoading = _data;
  },
  SET_FILTERED_PROPOSALS(_state, proposals) {
    _state.filteredProposals = proposals;
  },
});

/* eslint-disable no-unused-vars */
import Vue from 'vue';
import { MutationTree } from 'vuex';
import {
  ConfiguredFlight,
  CreativeConfig,
  KeywordSummary,
  ProductConfig,
  ProductGeoFence,
  ProposalGeoSelections,
  ProposalIncreasedRecommendations,
  ProposalMarket,
  SemKeyword,
  Package,
  Product,
} from '@/shared/types';
import { NewProposalState } from './types';

import merge from 'lodash.merge';
import clonedeep from 'lodash.clonedeep';

import { findProductByID, getProductByIndex } from '.';
import { ProductConfigCategory } from '@/app/graphql';

export enum MutationTypes {
  SET_IN_PROGRESS_LOADING = 'SET_IN_PROGRESS_LOADING',
  SET_IN_PROGRESS_PROPOSAL = 'SET_IN_PROGRESS_PROPOSAL',
  SET_NEW_PROPOSAL_BUDGET = 'SET_NEW_PROPOSAL_BUDGET',
  SET_NEW_PROPOSAL_NAME = 'SET_NEW_PROPOSAL_NAME',
  SET_NEW_PROPOSAL_OUTPUT_ID = 'SET_NEW_PROPOSAL_OUTPUT_ID',
  SET_NEW_PROPOSAL_GOAL = 'SET_NEW_PROPOSAL_GOAL',
  SET_NEW_PROPOSAL_SECONDARY_GOAL = 'SET_NEW_PROPOSAL_SECONDARY_GOAL',
  SET_SAVE_PROGRESS_LOADING = 'SET_SAVE_PROGRESS_LOADING',
  SET_AWAIT_BUDGET_CHECK_LOADING = 'SET_AWAIT_BUDGET_CHECK_LOADING',
  RESET_NEW_PROPOSAL = 'RESET_NEW_PROPOSAL',
  SET_MARKET_ADDRESS = 'SET_MARKET_ADDRESS',
  SET_SHOULD_REDRAW_POLYGONS = 'SET_SHOULD_REDRAW_POLYGONS',
  SET_PROPOSAL_MARKET = 'SET_PROPOSAL_MARKET',
  SET_NEW_PROPOSAL_AUDIENCE = 'SET_NEW_PROPOSAL_AUDIENCE',
  SET_NEW_PROPOSAL_CENSUS = 'SET_NEW_PROPOSAL_CENSUS',
  SET_NEW_PROPOSAL_MARKET_CUSTOM = 'SET_NEW_PROPOSAL_MARKET_CUSTOM',
  SET_NEW_PROPOSAL_MARKET_DEMOGRAPHICS = 'SET_NEW_PROPOSAL_MARKET_DEMOGRAPHICS',
  SET_NEW_PROPOSAL_MARKET_GEO_SELECTIONS = 'SET_NEW_PROPOSAL_MARKET_GEO_SELECTIONS',
  SET_CENSUS_LOADING = 'SET_CENSUS_LOADING',
  SET_FETCH_PRODUCT_CONFIGS_LOADING = 'SET_FETCH_PRODUCT_CONFIGS_LOADING',
  SET_NEW_PROPOSAL_PRODUCT_CONFIGS = 'SET_NEW_PROPOSAL_PRODUCT_CONFIGS',
  TOGGLE_PRODUCT_LOCKED = 'TOGGLE_PRODUCT_LOCKED',
  SET_UPDATE_PRODUCTS_LOADING = 'SET_UPDATE_PRODUCTS_LOADING',
  SET_PRODUCTS = 'SET_PRODUCTS',
  SET_HAS_RECEIVED_RECOMMENDATIONS = 'SET_HAS_RECEIVED_RECOMMENDATIONS',
  SET_RECOMMENDATIONS_LOADING = 'SET_RECOMMENDATIONS_LOADING',
  SET_CLIENT_DEFAULTS_LOADING = 'SET_CLIENT_DEFAULTS_LOADING',
  SET_UPDATING_TO_INCREASED_RECOMMENDATIONS_LOADING = 'SET_UPDATING_TO_INCREASED_RECOMMENDATIONS_LOADING',
  SET_PRODUCT_INCREASED_RECOMMENDATIONS = 'SET_PRODUCT_INCREASED_RECOMMENDATIONS',
  REMOVE_PRODUCT_INCREASED_RECOMMENDATIONS = 'REMOVE_PRODUCT_INCREASED_RECOMMENDATIONS',
  UPDATE_PRODUCT_BUDGET = 'UPDATE_PRODUCT_BUDGET',
  UPDATE_PRODUCT = 'UPDATE_PRODUCT',
  SET_FLIGHT_GEO_FENCES = 'SET_FLIGHT_GEO_FENCES',
  SET_PRODUCT_FLIGHTS = 'SET_PRODUCT_FLIGHTS',
  SET_GENERATED_KEYWORDS = 'SET_GENERATED_KEYWORDS',
  LOADING_CUSTOM_KEYWORDS = 'LOADING_CUSTOM_KEYWORDS',
  LOADING_KEYWORDS_SUMMARY = 'LOADING_KEYWORDS_SUMMARY',
  LOADING_KEYWORDS = 'LOADING_KEYWORDS',
  SET_XML_DATA = 'SET_XML_DATA',
  SET_XML = 'SET_XML',
  SET_NO_XML = 'SET_NO_XML',
  SET_CREATIVE_CONFIG_LOADING = 'SET_CREATIVE_CONFIG_LOADING',
  SET_UPDATE_CREATIVE_LOADING = 'SET_UPDATE_CREATIVE_LOADING',
  SET_NEW_PROPOSAL_CREATIVE_CONFIGS = 'SET_NEW_PROPOSAL_CREATIVE_CONFIGS',
  SET_ADD_CONTRACT_LOADING = 'SET_ADD_CONTRACT_LOADING',
  SET_GET_CONTRACT_LOADING = 'SET_GET_CONTRACT_LOADING',
  SET_NEW_PROPOSAL_GENERATED_CONTRACTS = 'SET_NEW_PROPOSAL_GENERATED_CONTRACTS',
  SET_CHANGE_STATUS_NOTIFICATION_OPTIONS = 'SET_CHANGE_STATUS_NOTIFICATION_OPTIONS',
  SET_IS_PROPOSAL_MARKET_UPDATED = 'SET_IS_PROPOSAL_MARKET_UPDATED',
  SET_DATE_IN_PROGRESS_PROPOSAL = 'SET_DATE_IN_PROGRESS_PROPOSAL',
  SET_LOADING_PROPOSAL_STATUS = 'SET_LOADING_PROPOSAL_STATUS',
  SET_IN_PROGRESS_PROPOSAL_PRODUCT_CREATIVES = 'SET_IN_PROGRESS_PROPOSAL_PRODUCT_CREATIVES',
  SET_PROPOSAL_ACTION_FLAGS = 'SET_PROPOSAL_ACTION_FLAGS',
}

export const mutations = (): MutationTree<NewProposalState> => ({
  // newProposal
  [MutationTypes.SET_IN_PROGRESS_LOADING](_state, _status: boolean): void {
    _state.inProgressLoading = _status;
  },
  [MutationTypes.SET_IN_PROGRESS_PROPOSAL](_state, _proposal): void {
    _proposal.market = merge(clonedeep(_state.initialMarket), _proposal.market || _state.newProposal.market);
    _state.newProposal = { ..._state.newProposal, ..._proposal };
  },
  [MutationTypes.SET_NEW_PROPOSAL_BUDGET](_state, _budget: number): void {
    _state.newProposal.budget = _budget;
  },
  [MutationTypes.SET_NEW_PROPOSAL_NAME](_state, _name): void {
    _state.newProposal.name = _name;
  },
  [MutationTypes.SET_NEW_PROPOSAL_GOAL](_state, _goal): void {
    _state.newProposal.goal = _goal;
  },
  [MutationTypes.SET_NEW_PROPOSAL_SECONDARY_GOAL](_state, _goal): void {
    _state.newProposal.secondaryGoal = _goal;
  },
  [MutationTypes.SET_SAVE_PROGRESS_LOADING](_state, _status): void {
    _state.saveProgressLoading = _status;
  },
  [MutationTypes.SET_AWAIT_BUDGET_CHECK_LOADING](_state, _status: boolean): void {
    _state.awaitBudgetCheckLoading = _status;
  },
  [MutationTypes.RESET_NEW_PROPOSAL](_state): void {
    _state.hasReceivedRecommendations = false;
    _state.productConfigs = { list: [], loaded: false };
    _state.newProposal = {
      id: '',
      agencyId: '',
      agency: null,
      contracts: [],
      startDate: '',
      endDate: '',
      status: '',
      createdBy: { email: '' },
      updatedBy: { email: '' },
      name: '',
      goal: '',
      budget: 0,
      products: [],
      audience: [],
      demographics: [],
      market: clonedeep(_state.initialMarket),
    };
  },
  // newProposal market
  [MutationTypes.SET_MARKET_ADDRESS](_state, _addressObj): void {
    _state.newProposal.market.address = _addressObj;
  },
  [MutationTypes.SET_SHOULD_REDRAW_POLYGONS](_state, _status): void {
    _state.shouldRedrawPolygons = _status;
  },
  [MutationTypes.SET_PROPOSAL_MARKET](
    _state,
    { market, setInitial }: { market: ProposalMarket; setInitial?: boolean },
  ): void {
    if (setInitial) {
      _state.initialMarket = market;
    } else {
      _state.newProposal.market = market;
    }
  },
  [MutationTypes.SET_NEW_PROPOSAL_AUDIENCE](_state, _audience): void {
    _state.newProposal.audience = _audience;
  },
  [MutationTypes.SET_NEW_PROPOSAL_MARKET_DEMOGRAPHICS](_state, _demographics): void {
    _state.newProposal.demographics = _demographics;
  },
  [MutationTypes.SET_NEW_PROPOSAL_CENSUS](_state, _census): void {
    _state.newProposal.market.census = _census;
  },

  [MutationTypes.SET_NEW_PROPOSAL_MARKET_GEO_SELECTIONS](_state, _geoSelections: ProposalGeoSelections): void {
    _state.newProposal.market.geoSelections = _geoSelections;
  },
  [MutationTypes.SET_CENSUS_LOADING](_state, _status: boolean): void {
    _state.censusLoading = _status;
  },
  // products
  [MutationTypes.SET_FETCH_PRODUCT_CONFIGS_LOADING](_state, _status): void {
    _state.fetchProductConfigsLoading = _status;
  },
  [MutationTypes.SET_NEW_PROPOSAL_PRODUCT_CONFIGS](_state, _productConfigs: ProductConfig[]): void {
    _state.productConfigs = { list: _productConfigs, loaded: true };
  },
  [MutationTypes.TOGGLE_PRODUCT_LOCKED](_state, { _status, _productId }): void {
    const productIndex = _state.newProposal.products.findIndex(product => product.id === _productId);
    if (productIndex !== -1) {
      Vue.set(_state.newProposal.products[productIndex], 'isLocked', _status);
    }
  },
  [MutationTypes.SET_UPDATE_PRODUCTS_LOADING](_state, _status: boolean): void {
    _state.updateProductsLoading = _status;
  },
  [MutationTypes.SET_PRODUCTS](_state, _productArray): void {
    Vue.set(_state.newProposal, 'products', _productArray);
  },
  [MutationTypes.SET_HAS_RECEIVED_RECOMMENDATIONS](_state, _status: boolean): void {
    _state.hasReceivedRecommendations = _status;
  },
  [MutationTypes.SET_RECOMMENDATIONS_LOADING](_state, _status: boolean): void {
    _state.recommendationsLoading = _status;
  },
  [MutationTypes.SET_CLIENT_DEFAULTS_LOADING](_state, _status: boolean): void {
    _state.clientDefaultsLoading = _status;
  },
  [MutationTypes.SET_UPDATING_TO_INCREASED_RECOMMENDATIONS_LOADING](_state, _status: boolean): void {
    _state.updatingToIncreasedRecommendationsLoading = _status;
  },
  [MutationTypes.SET_PRODUCT_INCREASED_RECOMMENDATIONS](
    _state,
    _increasedRecommendations: ProposalIncreasedRecommendations,
  ): void {
    _state.newProposal.increasedRecommendations = _increasedRecommendations;
  },
  [MutationTypes.REMOVE_PRODUCT_INCREASED_RECOMMENDATIONS](_state): void {
    _state.newProposal.increasedRecommendations = null;
  },
  [MutationTypes.UPDATE_PRODUCT_BUDGET](_state, { _budget, _productId }): void {
    const productIndex = _state.newProposal.products.findIndex(product => product.id === _productId);
    if (productIndex !== -1) {
      Vue.set(_state.newProposal.products[productIndex], 'budget', _budget);
    }
  },
  [MutationTypes.UPDATE_PRODUCT](_state, _product): void {
    const productId = _product.id;
    const productIndex = _state.newProposal.products.findIndex(product => product.id === productId);
    if (productIndex !== -1) {
      Vue.set(_state.newProposal.products, productIndex, _product);
    }
  },
  // product flights
  [MutationTypes.SET_FLIGHT_GEO_FENCES](
    _state,
    fenceObj: { productId: number; flightIndex: number; geoFences: ProductGeoFence[] },
  ): void {
    const productIndex = findProductByID(_state, fenceObj.productId.toString());
    const product = getProductByIndex(_state, productIndex);

    if (productIndex.some(el => el !== -1)) {
      const market =
        {
          ...product?.flights?.[fenceObj.flightIndex]?.market,
        } || {};

      market['geoSelections'] = { addressList: fenceObj.geoFences };

      if (productIndex[1] === -1) {
        Vue.set(
          (_state.newProposal.products[productIndex[0]] as Product).flights[fenceObj.flightIndex],
          'market',
          market,
        );
      } else {
        Vue.set(
          (_state.newProposal.products[productIndex[0]] as Package).products[productIndex[1]].flights[
            fenceObj.flightIndex
          ],
          'market',
          market,
        );
      }
    }
  },
  [MutationTypes.SET_PRODUCT_FLIGHTS](_state, flightsObj: { productId: string; flights: ConfiguredFlight[] }): void {
    const productIndex = findProductByID(_state, flightsObj.productId);
    if (productIndex.some(el => el !== -1)) {
      if (productIndex[1] === -1) {
        Vue.set(_state.newProposal.products[productIndex[0]], 'flights', flightsObj.flights);
      } else {
        Vue.set(
          (_state.newProposal.products[productIndex[0]] as Package).products[productIndex[1]],
          'flights',
          flightsObj.flights,
        );
      }
    }
  },
  [MutationTypes.SET_GENERATED_KEYWORDS](
    _state,
    { keywords, productId, summary }: { keywords: SemKeyword[]; productId: string; summary: KeywordSummary },
  ): void {
    const productIndex = findProductByID(_state, productId);
    if (productIndex.some(el => el !== -1)) {
      const kwObj = { list: keywords, summary };
      if (productIndex[1] === -1) {
        Vue.set(_state.newProposal.products[productIndex[0]], 'keywords', kwObj);
      } else {
        Vue.set((_state.newProposal.products[productIndex[0]] as Package).products[productIndex[1]], 'keywords', kwObj);
      }
    }
  },
  [MutationTypes.LOADING_CUSTOM_KEYWORDS](_state, _status: boolean): void {
    _state.loadingCustomKeywords = _status;
  },
  [MutationTypes.LOADING_KEYWORDS_SUMMARY](_state, _status: boolean): void {
    _state.loadingKeywordsSummary = _status;
  },
  [MutationTypes.LOADING_KEYWORDS](_state, _status: boolean): void {
    _state.loadingKeywords = _status;
  },
  [MutationTypes.SET_XML](
    _state,
    {
      broadcast,
      productId,
      link,
      isLocked = null,
    }: { broadcast: any[]; productId: string; link: string; isLocked?: boolean },
  ): void {
    const productIndex = _state.newProposal.products.findIndex(product => product.id === productId);
    if (productIndex !== -1) {
      const broadcastInfo = { broadcast, link };
      Vue.set(_state.newProposal.products[productIndex], 'broadcastInfo', broadcastInfo);
      Vue.set(_state.newProposal.products[productIndex], 'noXmlFlag', false);
      if (isLocked !== null) Vue.set(_state.newProposal.products[productIndex], 'isLocked', isLocked);
    }
  },
  [MutationTypes.SET_NO_XML](_state, productId: string): void {
    const productIndex = _state.newProposal.products.findIndex(product => product.id === productId);
    if (productIndex !== -1) {
      Vue.set(_state.newProposal.products[productIndex], 'noXmlFlag', true);
    }
  },
  // finalize/creatives
  [MutationTypes.SET_CREATIVE_CONFIG_LOADING](_state, _status): void {
    _state.loadingCreativeConfig = _status;
  },
  [MutationTypes.SET_UPDATE_CREATIVE_LOADING](_state, _status): void {
    _state.updateCreativeLoading = _status;
  },
  [MutationTypes.SET_NEW_PROPOSAL_CREATIVE_CONFIGS](_state, _creativeConfigs: CreativeConfig[]): void {
    const list = _creativeConfigs
      .map(config => (config.category === ProductConfigCategory.Package ? config.CreativeConfigList : config))
      .flat();
    _state.creativeConfigs = { list, loaded: true };
  },
  [MutationTypes.SET_ADD_CONTRACT_LOADING](_state, _status): void {
    _state.addContractLoading = _status;
  },
  [MutationTypes.SET_GET_CONTRACT_LOADING](_state, _status): void {
    _state.getContractLoading = _status;
  },
  [MutationTypes.SET_NEW_PROPOSAL_GENERATED_CONTRACTS](_state, _contracts): void {
    _state.generatedContracts = _contracts;
  },
  [MutationTypes.SET_CHANGE_STATUS_NOTIFICATION_OPTIONS](_state, _payload): void {
    _state.changeStatusNotificationOptions = _payload;
  },
  [MutationTypes.SET_IS_PROPOSAL_MARKET_UPDATED](_state, _value): void {
    _state.isProposalMarketUpdated = _value;
  },
  [MutationTypes.SET_DATE_IN_PROGRESS_PROPOSAL](
    _state,
    {
      CampaignStartDate,
      CampaignEndDate,
      Version,
      status,
    }: { CampaignStartDate: string; CampaignEndDate: string; Version: number; status: string },
  ): void {
    const update = {
      CampaignEndDate,
      CampaignStartDate,
      startDate: CampaignStartDate,
      endDate: CampaignEndDate,
      Version: String(Version),
      status,
    };
    _state.newProposal = { ..._state.newProposal, ...update };
  },
  [MutationTypes.SET_LOADING_PROPOSAL_STATUS](_state, _value) {
    _state.isLoadingProposalStatuses = _value;
  },
  [MutationTypes.SET_IN_PROGRESS_PROPOSAL_PRODUCT_CREATIVES](_state, { creatives, productId }): void {
    const productIndex = findProductByID(_state, productId.toString());

    if (productIndex.some(el => el !== -1)) {
      if (productIndex[1] === -1) {
        Vue.set(_state.newProposal.products[productIndex[0]], 'creatives', creatives);
      } else {
        Vue.set(
          (_state.newProposal.products[productIndex[0]] as Package).products[productIndex[1]],
          'creatives',
          creatives,
        );
      }
    }
  },
  [MutationTypes.SET_PROPOSAL_ACTION_FLAGS](_state, _flags): void {
    _state.newProposal = { ..._state.newProposal, ..._flags };
  },
});


import Vue from 'vue';
import debounce from 'lodash.debounce';
import { mask } from 'vue-the-mask';
import { ClientLocation } from '@/shared/types';

import { Services } from '@/injectables/tokens';
import { GeocodeServiceContract, ValidationServiceContract } from '@/injectables';

import CategoryPicker from '@/components/Clients/CategoryPicker.vue';
import { mapActions } from 'vuex';
import { Modules } from '@/store';

const addressToLatLong: Record<string, { lat: number; lon: number }> = {};

/**
 * TODO: split into widgets and features, move in apropriate folders
 */
export default Vue.extend({
  name: 'CreateClientDialog',

  directives: { mask },

  useInjectable: [Services.Validation, Services.Geocode],

  inject: ['$confirm', 'showSnackbar'],

  components: { CategoryPicker },

  props: {
    dialog: {
      type: Boolean,
    },
    allAgencies: {
      type: Array as () => { PropertyId: string; name: string }[],
    },
  },

  data: (): {
    mask: string;
    isFormValid: boolean;
    name: string;
    category: { id: string; name: string };
    phone: string;
    locations: {}[];
    url: string;
    agency: string;
    address: string;
    enableCategoryPicker: boolean;
    locationAutocompleteList: object[];
    newClientLocation: ClientLocation;
    locationSearch: string;
    fetchingLocations: boolean;
    loading: boolean;
  } => ({
    mask: '1 (###) ###-####',
    isFormValid: false,
    name: '',
    category: { id: '', name: '' },
    phone: '',
    locations: [],
    url: '',
    agency: '',
    address: '',
    enableCategoryPicker: false,
    locationAutocompleteList: [],
    newClientLocation: {
      label: 'primary',
      address: '',
      lat: 0,
      lon: 0,
      zip: '',
      city: '',
      state: '',
    },
    locationSearch: '',
    fetchingLocations: false,
    loading: false,
  }),

  watch: {
    locationSearch(val: string): void {
      if (!val || val.length < 5 || this.newClientLocation.address) {
        return;
      }
      this.fetchingLocations = true;
      this.fetchAutocompleteApi(val);
    },
    openDialog() {
      this.name = null;
      this.category = { id: '', name: '' };
      this.url = null;
      this.phone = null;
      this.agency = this.userAgency;
      this.locations = [];
      this.locationSearch = null;
      this.newClientLocation = {
        label: 'primary',
        address: '',
        lat: 0,
        lon: 0,
        zip: '',
        city: '',
        state: '',
      };
      this.$refs.createClientForm?.resetValidation();
    },
  },
  computed: {
    createButtonDisabled(): boolean {
      return !this.isFormValid;
    },
    userAgency(): string {
      return this.$store.state.auth.user?.Agency || '';
    },
    openDialog: {
      get(): boolean {
        return this.dialog;
      },
      set(val: boolean): void {
        if (!val) {
          this.closeDialog();
        }
      },
    },
    rules() {
      const validationService: ValidationServiceContract = this.validationService;
      return {
        required: validationService.requiredValidatorFactory(),
        maxLength: validationService.maxLengthValidatorFactory(),
        minPhoneLength: validationService.phoneMinLengthValidationFactory(16),
        domain: validationService.isDomainValidatorFactory(),
      };
    },
    // location search
    items(): { text: string; value: string }[] {
      const matches: { text: string; value: string }[] = [];
      this.locationAutocompleteList.forEach(entry => {
        if (entry.position && entry.address && entry.address.label) {
          matches.push({ text: entry.address.label, value: entry.address.label });
          addressToLatLong[entry.address.label] = { lat: entry.position.lat, lon: entry.position.lng };
        }
      });
      return matches;
    },
  },
  methods: {
    ...mapActions(Modules.Client, ['getClients']),
    async createClient(): Promise<void> {
      this.loading = true;
      this.addClientLocation();
      await this.$refs.createClientForm.validate();

      if (!this.isFormValid) {
        return;
      }

      if (!this.newClientLocation.address) {
        const { confirmed } = await this.$confirm.show({
          title: 'Are you sure you want to create Client without Address?',
          body: '',
          confirmText: 'Yes',
          cancelText: 'No',
        });

        if (!confirmed) {
          this.loading = false;
          return;
        }
      }

      const clientToUpdate = {
        AgencyPartner: this.agency,
        name: this.name,
        category: this.category,
        Phone: this.phone,
        locations: this.locations,
        WURL: this.url,
        dupChecked: true,
      };
      this.$store
        .dispatch('client/updateNewClientDetails', clientToUpdate)
        .then(() => {
          this.$emit('submit-new-client');
        })
        .catch(err => {
          this.$log(err);
          this.$store.dispatch('showSnackbar', { content: 'Unable to update client details', color: 'error' });
        })
        .finally(async () => {
          this.loading = false;
        });
    },
    addClientLocation(): void {
      const ll = addressToLatLong[this.newClientLocation.address];
      if (!ll) {
        return;
      }
      this.parseNewClientLocationAddress();
      this.locations.push({
        address: this.newClientLocation.address,
        label: this.newClientLocation.label,
        lat: ll.lat,
        lon: ll.lon,
        zip: this.newClientLocation.zip,
        city: this.newClientLocation.city,
        state: this.newClientLocation.state,
      });
    },
    parseNewClientLocationAddress(): void {
      const splitAddress = this.newClientLocation.address.split(', ');
      const stateCity = splitAddress[splitAddress.length - 2].split(' ');
      this.newClientLocation.city = splitAddress[splitAddress.length - 3];
      [this.newClientLocation.state, this.newClientLocation.zip] = stateCity;
    },
    closeDialog(): void {
      this.$emit('close-dialog');
    },
    setCategory(category: string): void {
      this.category = category;
    },
    fetchAutocompleteApi: debounce(async function (query: string) {
      const geocodeService: GeocodeServiceContract = this.geocodeService;
      const encoded = encodeURIComponent(query);
      const { unwrap, isErr, unwrapErr } = await geocodeService.searchByLocation(encoded);
      if (isErr()) {
        this.showSnackbar(unwrapErr().message);
        this.fetchingLocations = false;
        return;
      }
      this.locationAutocompleteList = unwrap();
      this.fetchingLocations = false;
    }, 500),
  },
});

import Vue from 'vue';

// Format number:
// - input <number> or <string> -> return formatted string
// - input 0 or null or invalid string -> return placeholder
// - thousands separator is a comma (2500 -> 2,500)
// - update to whole numbers (500.00 -> 500)
export const filter = (value: number | string | null, placeholder = '0'): string => {
  if (typeof value === 'string' && !isNaN(Number.parseFloat(value))) {
    value = Number.parseFloat(value);
  }
  if (typeof value === 'number') {
    return `${Math.round(value)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
  }
  return placeholder;
};

Vue.filter('formatNumber', filter);


import Vue from 'vue';

export default Vue.extend({
  name: 'ProposalStatusSelector',

  props: {
    currentStatus: {
      type: String,
      required: true,
    },
    statusIcon: {
      type: String,
    },
    statuses: {
      type: Array,
      default: () => [],
    },
    formatter: {
      type: Function,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      chosen: '',
      isShowSelector: false,
    };
  },

  computed: {
    disabled(): boolean {
      return !this.statuses.length || this.statuses.every(s => s === this.currentStatus);
    },
  },

  methods: {
    saveStatus(): void {
      if (this.chosen) {
        this.$emit('update-status', this.chosen);
      }
      this.closeSelector();
    },
    closeSelector(): void {
      this.isShowSelector = false;
    },
  },
});

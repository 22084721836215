
import Vue, { PropType } from 'vue';
import { NewProposalModelContract, UIUtilsServiceContract } from '@/injectables';
import { Models, Services } from '@/injectables/tokens';
import { InterestListItem } from '@/shared/types';
import { SegmentItem as SegmentItemType, TargetingSegmentsModelContract } from '@/injectables';
import { SegmentCategory, SegmentSubCategory } from '@/app/graphql';
import SegmentItem from '@/features/instant-io/ui/segmentItem.vue';
import SearchSection from '@/features/instant-io/ui/search-section.vue';
import WrapperWithTooltip from '@/components/WrapperWithTooltip.vue';

export default Vue.extend({
  name: 'TargetedDemographics',

  useInjectable: [Services.TargetingSegments, Models.TargetingSegments, Models.NewProposal],

  components: { SegmentItem, SearchSection, WrapperWithTooltip },

  props: {
    dates: {
      type: Array,
      default: () => [],
    },
    containerHeight: {
      type: Number,
      default: 0,
    },
    disableSelections: {
      type: Boolean,
      default: false,
    },
    hideCensus: {
      type: Boolean,
      default: false,
    },
    top: {
      type: [Number, String],
      default: 100,
    },
    width: {
      type: [Number, String],
      default: 320,
    },
    selectedDemographics: {
      type: Array as PropType<SegmentItemType[]>,
      required: true,
    },
    selectedMainTargets: {
      type: Array as PropType<SegmentItemType[]>,
      required: true,
    },
    targetedPopulation: {
      type: Number,
      default: 0,
    },
    totalPopulation: {
      type: Number,
      default: 0,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data: (): {
    demoField: string;
    addingValue: string;
    pos: {
      top: number;
      y: number;
    };
    draggable: boolean;
    items: {
      tab: SegmentCategory | 'Search all segments';
      main: boolean;
      icon?: string;
    }[];
    demographics: {
      category: SegmentSubCategory;
      formatNumber?: boolean;
    }[];
  } => ({
    addingValue: '',
    demoField: SegmentCategory.Demographic,
    pos: { top: 0, y: 0 },
    draggable: false,
    demographics: [
      { category: SegmentSubCategory.Age },
      { category: SegmentSubCategory.Income, formatNumber: true },
      { category: SegmentSubCategory.Family },
      { category: SegmentSubCategory.Gender },
      { category: SegmentSubCategory.Housing },
    ],
    items: [
      { main: false, tab: SegmentCategory.Demographic },
      { main: true, tab: SegmentCategory.AdvancedDemographic },
      { main: true, tab: SegmentCategory.AudienceProfiles },
      { main: true, tab: SegmentCategory.Enthusiast },
      { main: true, tab: SegmentCategory.Occupation },
      { main: true, tab: SegmentCategory.SearchHistory },
      { main: true, tab: SegmentCategory.Shopping },
      { main: true, tab: SegmentCategory.Services },
      {
        tab: 'Search all segments',
        main: false,
        icon: 'search',
      },
    ],
  }),

  computed: {
    isSegmentsSelectionDisabled() {
      const audienceSegmentLimit = this.$store.state.agency.currentAgencyInfo.audienceSegmentLimit;
      if (!audienceSegmentLimit) return false;
      return this.selectedMainTargets?.filter(el => typeof el !== 'number')?.length >= audienceSegmentLimit;
    },
    demographicsItems() {
      return this.$store.state.targetingSegments.demographicsSegments;
    },
    demographicsCensusData() {
      return this.$store.state.newProposal.newProposal.market.census.segmentDemoInfo;
    },
    targetItems() {
      return this.$store.state.targetingSegments.mainSegments;
    },
    mainCensusItems() {
      return this.items.filter(el => el.main);
    },
    selectedInterestList(): InterestListItem[] {
      const foundInterest = this.interests.find(interest => interest.name === this.demoField);

      return foundInterest?.list || [];
    },
    segmentCategory() {
      return SegmentCategory;
    },
    demographicsPercent(): string {
      if (this.totalPopulation) {
        const percent = (this.targetedPopulation / this.totalPopulation) * 100;
        return percent.toFixed(0);
      }
      return '0';
    },
    overlayHeight(): number {
      return this.containerHeight;
    },
    customCensusSelected() {
      const currentValue = this.addingValue.trim();
      return this.selectedInterestList.some(el => el.name === currentValue);
    },
    addingBlocked() {
      return this.customCensusSelected || !this.addingValue.trim();
    },
    searchSegments() {
      return this.selectedMainTargets.filter(el => el.segmentCategory === SegmentCategory.Other);
    },
  },

  methods: {
    selectSearchedSegment(segments) {
      this.$emit('update:selected-main-targets', segments);
    },
    getItemsByCategory(category: SegmentCategory) {
      if (!this.targetItems) return [];
      return this.targetItems[category] || [];
    },
    getSelectedByCategory(category: SegmentCategory) {
      return this.selectedMainTargets.filter(el => el.segmentCategory === category);
    },
    getSelectedNumber(tab) {
      if (tab === SegmentCategory.Demographic) return this.selectedDemographics.length;
      if (tab === 'Search all segments') return this.getSelectedByCategory(SegmentCategory.Other).length;
      return this.getSelectedByCategory(tab).length;
    },
    setDemographicsSelection(selected: SegmentItemType[]) {
      this.$emit('update:selected-demographics', [...selected]);
    },
    setSelection(selected: SegmentItemType[], category: SegmentCategory) {
      const listWithoutCategory = this.selectedMainTargets.filter(el => el.segmentCategory !== category);
      this.$emit('update:selected-main-targets', [...listWithoutCategory, ...selected]);
    },
    deleteSearchItem(segment) {
      this.$emit(
        'update:selected-main-targets',
        this.selectedMainTargets.filter(el => el.id !== segment.id),
      );
    },
    getReadableTabName(item: { tab: SegmentCategory | 'Search all segments' }) {
      return (this[Models.TargetingSegments] as TargetingSegmentsModelContract).getReadableCategory(item.tab);
    },
    getReadableSubCategory(category: SegmentSubCategory) {
      return (this[Models.TargetingSegments] as TargetingSegmentsModelContract).getReadableSubCategory(category);
    },
    getGroupItemsByCategory(category: SegmentSubCategory) {
      if (!this.demographicsItems) return [];
      return this.demographicsItems[category] || [];
    },
    // 1631 useInjectable
    bigNumberFormatter(number: number): string | number {
      const UIUtilsService: UIUtilsServiceContract = this.$container.get(Services.UI);
      return UIUtilsService.bigNumberFormatter(number);
    },
    bigNumbersRange(range: string) {
      const [start, end] = range.split('-');
      const firstValue = start ? start.replace(/\D+/g, '') : null;
      const secondValue = end ? end.replace(/\D+/g, '') : null;

      if (!firstValue && !secondValue) {
        return '-';
      }

      if (!secondValue && firstValue) {
        // firstValue is <number>+
        const [minimumValue] = firstValue.split('+');
        return `$${this.bigNumberFormatter(Number(minimumValue))}+`;
      }

      return `
        $${this.bigNumberFormatter(Number(firstValue))}
        -
        $${this.bigNumberFormatter(Number(secondValue))}`;
    },
    percentOfTotal(number: number): string {
      if (this.totalPopulation !== 0) {
        return `${Math.round((number / this.totalPopulation) * 100).toLocaleString()}%`;
      }

      return '0%';
    },
    findDemographicsItem(segment) {
      return this.demographicsCensusData.find(
        el => el.property === (this[Models.NewProposal] as NewProposalModelContract).segmentToDemoName(segment.name),
      );
    },
    segmentPercent(segment) {
      const defaultValue = 'No Data';
      if (!this.demographicsCensusData) return defaultValue;
      const item = this.findDemographicsItem(segment);
      if (!item) return defaultValue;
      return this.percentOfTotal(item.value);
    },
    segmentAudienceValue(segment) {
      const defaultValue = null;
      if (!this.demographicsCensusData) return defaultValue;
      const item = this.findDemographicsItem(segment);
      if (!item) return defaultValue;
      return this.bigNumberFormatter(Number(item.value));
    },
    mouseDownHandler(e) {
      const ele = document.getElementById('demographics-section');
      if (ele) {
        this.draggable = true;

        ele.style.cursor = 'grabbing';
        ele.style.userSelect = 'none';

        this.pos = {
          top: ele.scrollTop,
          y: e.clientY,
        };
      }
    },
    mouseMoveHandler(e) {
      const ele = document.getElementById('demographics-section');
      if (ele && this.draggable) {
        // How far the mouse has been moved
        const dy = e.clientY - this.pos.y;

        // Scroll the element
        ele.scrollTop = this.pos.top - dy;
      }
    },
    mouseUpHandler() {
      const ele = document.getElementById('demographics-section');
      if (ele) {
        this.draggable = false;
        ele.style.cursor = 'grab';
        ele.style.removeProperty('user-select');
      }
    },
    mouseLeaveHandler() {
      const ele = document.getElementById('demographics-section');
      if (ele) {
        this.draggable = false;
        ele.style.cursor = 'grab';
      }
    },
  },
});

import * as Types from '../../../../../app/graphql/_types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type UpdateCreativeMutationVariables = Types.Exact<{
  input: Types.UpdateProductCreativeInput;
}>;

export type UpdateCreativeMutation = {
  updateProductCreative?: {
    id: string;
    name: string;
    creatives?: Array<{
      id: string;
      index?: number | null;
      url?: string | null;
      creativeLink?: string | null;
      customMargin?: number | null;
      omsNumber?: string | null;
      notes?: string | null;
      vastTag?: string | null;
      socialLink?: string | null;
      postText?: string | null;
      headline?: string | null;
      description?: string | null;
      selectedAdFormatList?: Record<string, any> | null;
      creatives?: Array<{
        id: string;
        index?: number | null;
        url?: string | null;
        creativeLink?: string | null;
        customMargin?: number | null;
        omsNumber?: string | null;
        notes?: string | null;
        vastTag?: string | null;
        socialLink?: string | null;
        postText?: string | null;
        headline?: string | null;
        description?: string | null;
      }> | null;
      flights?: Array<{ id: string } | null> | null;
    } | null> | null;
  } | null;
};

export const UpdateCreativeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateCreative' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateProductCreativeInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateProductCreative' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'creatives' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'index' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'creativeLink' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'customMargin' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'omsNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'vastTag' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'socialLink' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postText' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'headline' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'selectedAdFormatList' } },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'creatives' },
                        name: { kind: 'Name', value: 'children' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'index' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'creativeLink' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'customMargin' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'omsNumber' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'vastTag' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'socialLink' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'postText' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'headline' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'flights' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateCreativeMutation, UpdateCreativeMutationVariables>;

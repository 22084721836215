import * as Types from '../../../../../app/graphql/_types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type UpdateAgencyFlagsMutationVariables = Types.Exact<{
  input: Types.UpdateMediaplannerAgencyInput;
}>;

export type UpdateAgencyFlagsMutation = {
  updateMediaplannerAgency?: {
    id: string;
    name: string;
    url?: string | null;
    logo?: string | null;
    defaultLayoutId?: string | null;
    congressionalDistrictAvailsEnabled?: boolean | null;
    dynamicColorsLayoutEnabled?: boolean | null;
    ioEmailEnabled?: boolean | null;
    placementEnabled?: boolean | null;
    instantIOEnabled?: boolean | null;
    adminIoOnly?: boolean | null;
    canHavePackages?: boolean | null;
    canGenerateContracts?: boolean | null;
    adminReviewEnabled?: boolean | null;
    canCreateClient?: boolean | null;
    canSetPixelRequestForInstantCampaign?: boolean | null;
    canSetConversionTracking?: boolean | null;
    audienceSegmentLimit?: number | null;
    parent?: { name: string } | null;
  } | null;
};

export const UpdateAgencyFlagsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateAgencyFlags' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateMediaplannerAgencyInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateMediaplannerAgency' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'parent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'defaultLayoutId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'congressionalDistrictAvailsEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dynamicColorsLayoutEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ioEmailEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'placementEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'instantIOEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'adminIoOnly' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canHavePackages' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canGenerateContracts' } },
                { kind: 'Field', name: { kind: 'Name', value: 'adminReviewEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canCreateClient' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canSetPixelRequestForInstantCampaign' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canSetConversionTracking' } },
                { kind: 'Field', name: { kind: 'Name', value: 'audienceSegmentLimit' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateAgencyFlagsMutation, UpdateAgencyFlagsMutationVariables>;

import 'reflect-metadata';
import { injectable } from 'inversify';
import { ClientFilters, DateType, ProposalFilters, RouterModelContract, SortingType, Status } from '@/injectables';
import { addDays } from 'date-fns';

@injectable()
export class RouterModel implements RouterModelContract {
  private getObject = (value, name) => (value !== undefined ? { [name]: value } : {});

  private getSorting = sorting => ({
    ...this.getObject(sorting.sortBy, 'sortBy'),
    ...this.getObject(sorting.sortDesc, 'desc'),
  });

  private getDatesObject = dates => {
    const { start = {}, end = {}, modified = {} } = dates || {};
    return {
      ...this.getObject(start.from, 'startFrom'),
      ...this.getObject(start.to, 'startTo'),
      ...this.getObject(end.from, 'endFrom'),
      ...this.getObject(end.to, 'endTo'),
      ...this.getObject(modified.from, 'modifiedFrom'),
      ...this.getObject(modified.to, 'modifiedTo'),
    };
  };

  getProposalFilteringQuery(
    filters: ProposalFilters,
    sorting: SortingType,
    searchKey: string,
    itemPerPage: number,
  ): Record<string, string> {
    return {
      ...this.getObject(searchKey, 'searchKey'),
      ...this.getDatesObject(filters?.dates),
      ...this.getObject(filters.status, 'status'),
      ...this.getSorting(sorting),
      ...this.getObject(itemPerPage, 'itemsPerPage'),
    };
  }

  getClientFilteringQuery(
    filters: ClientFilters,
    sorting: SortingType,
    searchKey: string,
    itemPerPage: number,
    mine: boolean,
  ): Record<string, string> {
    return {
      ...this.getObject(searchKey, 'searchKey'),
      ...this.getDatesObject(filters?.dates),
      ...this.getObject(filters.agency, 'agency'),
      ...this.getObject(filters.subAgency, 'subAgency'),
      ...this.getObject(filters.category, 'category'),
      ...this.getSorting(sorting),
      ...this.getObject(itemPerPage, 'itemsPerPage'),
      ...this.getObject(mine, 'mine'),
    };
  }

  private datesObject = (dates: Record<string, [string, string]>): Record<string, DateType> => {
    return Object.fromEntries(
      Object.entries(dates).map(([key, [from, to]]: [string, [string, string]]) => [
        key,
        {
          from,
          to,
          range: [from, to].filter(Boolean),
        },
      ]),
    );
  };

  packProposalFilters(status, dates): ProposalFilters {
    return {
      status,
      dates: this.datesObject(dates),
    };
  }

  packClientFilters(category, agency, subAgency, dates): ClientFilters {
    return {
      category,
      agency,
      subAgency,
      dates: this.datesObject(dates),
    };
  }

  public itemsPerPageArray = [15, 25, 50];

  getValidItemPerPage(itemsPerPage: string): number {
    const localItemsPerPage = Number(itemsPerPage);
    if (!this.itemsPerPageArray.includes(localItemsPerPage) || isNaN(localItemsPerPage))
      return this.itemsPerPageArray[0];
    return localItemsPerPage;
  }

  getProposalQuery(variant: 'ending' | 'queue' | 'recent'): Record<string, string[] | string | number | boolean> {
    if (variant === 'ending') {
      const now = new Date();
      const endFrom = addDays(now, -1).toISOString().slice(0, 10);
      const endTo = addDays(now, 31).toISOString().slice(0, 10);

      return {
        status: [Status.Sold],
        endFrom,
        endTo,
        sortBy: 'endDate',
        desc: false,
        itemsPerPage: 15,
      };
    }
    if (variant === 'queue') {
      return {
        status: [Status.ClientApproved, Status.UnderReview, Status.SubmittedForReview],
        sortBy: 'updatedAgo',
        desc: true,
        itemsPerPage: 15,
      };
    }
    return { sortBy: 'updatedAgo', desc: true, itemsPerPage: 15 };
  }

  getDefaultClientSorting(): SortingType {
    return { sortBy: 'name', sortDesc: false };
  }

  getDefaultProposalSorting(): SortingType {
    return { sortBy: 'updatedAgo', sortDesc: true };
  }

  stringToBoolean = (val: string): boolean => String(val).toLowerCase() === 'true';
}

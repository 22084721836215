
import Vue from 'vue';

export default Vue.extend({
  name: 'MultiSelectButton',

  props: {
    /**
     * Model value. Used for two way data binding.
     */
    value: {
      type: Boolean,
      required: true,
    },

    /**
     * Title of the modal.
     */
    buttonLabel: {
      type: String,
      required: true,
    },

    /**
     * Disabled state of the button.
     */
    disabled: {
      type: Boolean,
      default: false,
    },

    mainDisabled: {
      type: Boolean,
      default: false,
    },

    /**
     * Style of the button
     */
    shape: {
      type: String,
      default: 'pill',
    },

    /**
     * Color of the button (if not default)
     */
    color: {
      type: String,
      default: 'c360-gradient',
    },
  },

  computed: {
    dialog: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    mainEvent() {
      this.$emit('main-click');
    },
  },
});

import * as Types from '../../../../../app/graphql/_types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { PackageConfigFragmentFragmentDoc } from '../../../../../app/graphql/fragments/package-config.fragment.generated';
import {
  ProductFragmentFragmentDoc,
  ProductContractsFragmentDoc,
} from '../../../../../app/graphql/fragments/product.fragment.generated';
import { TargetingSegmentFragmentDoc } from '../../../../../app/graphql/fragments/campaign.fragment.generated';
export type GetPackageConfigByIdQueryVariables = Types.Exact<{
  packageId: Types.Scalars['UUID'];
}>;

export type GetPackageConfigByIdQuery = {
  package?: {
    id: string;
    agencyId: string;
    name: string;
    budget: number;
    description?: string | null;
    category: Types.ProductConfigCategory;
    isHidden?: boolean | null;
    products?: Array<{
      id: string;
      dsId?: string | null;
      name: string;
      index?: number | null;
      fulfillmentMethod: Types.ProductConfigFullfillmentMethod;
      isEditable?: boolean | null;
      isHidden?: boolean | null;
      isLocked?: boolean | null;
      minDays?: number | null;
      minSpend?: number | null;
      budget: number;
      omsAccountNumber?: string | null;
      omsName?: string | null;
      category: Types.ProductConfigCategory;
      description?: Record<string, any> | null;
      recommendedBudget?: number | null;
      flightType?: string | null;
      type?: Types.ProductConfigType | null;
      IORecipientEmail?: string | null;
      rateRangeList?: Record<string, any> | null;
      noXmlFlag?: boolean | null;
      keyMetric?: Types.KeyMetric | null;
      keyMetricMultiplier?: number | null;
      productConfigId: string;
      isMonthly?: boolean | null;
      calcMinSpend?: number | null;
      productConfig?: { id: string } | null;
      keywords?: {
        list?: Array<{
          keyword?: string | null;
          searchVolume?: number | null;
          competition?: number | null;
          competitionLevel?: string | null;
          averageCpc?: number | null;
          recommendLevel?: number | null;
          isSelected?: boolean | null;
          isCustom?: boolean | null;
        } | null> | null;
        summary?: {
          numKeywords?: number | null;
          totalCpc?: number | null;
          totalClicks?: number | null;
          lowerCpc?: number | null;
          upperCpc?: number | null;
          lowerClicks?: number | null;
          upperClicks?: number | null;
        } | null;
      } | null;
      broadcastInfo?: {
        link?: string | null;
        broadcast?: Array<{
          days?: Array<Types.Weekdays | null> | null;
          startTime: string;
          endTime: string;
          spots: number;
          spend: number;
          rating: number;
          programName: string;
          station: string;
        } | null> | null;
      } | null;
      geoSelections?: {
        cityList?: Array<{ key?: string | null; name?: string | null }> | null;
        countyList?: Array<{ key?: string | null; name?: string | null }> | null;
        dmaList?: Array<{ key?: string | null; name?: string | null }> | null;
        stateList?: Array<{ key?: string | null; name?: string | null }> | null;
      } | null;
      questionnaire?: Array<{ id: string; index: number; answer: string; questionnaireId: string } | null> | null;
      flights?: Array<{
        id: string;
        index?: number | null;
        budget?: number | null;
        startDate?: string | null;
        endDate?: string | null;
        rate?: number | null;
        rateType?: Types.RateType | null;
        selectedPlatform?: string | null;
        isLocked?: boolean | null;
        platformOption?: Array<string | null> | null;
        targetingOption?: string | null;
        flightCategory?: Types.FlightCategory | null;
        market?: Record<string, any> | null;
        mapType?: Types.MapType | null;
        contextualKeywords?: Array<string | null> | null;
        advancedOptions?: {
          autoRenewList?: Array<string | null> | null;
          abTestFlag?: boolean | null;
          darkPeriodList?: Array<{ index: number; startDate: string; endDate: string } | null> | null;
        } | null;
        audienceSegments?: Array<{
          id: string;
          name: string;
          externalId: string;
          description?: string | null;
          segmentCategory: Types.SegmentCategory;
          segmentSubCategory?: Types.SegmentSubCategory | null;
          isMain: boolean;
        }> | null;
      } | null> | null;
      flightConfigs?: Array<{
        id: string;
        markupMax?: number | null;
        markupMin?: number | null;
        rateType?: Types.RateType | null;
        rate?: number | null;
        adFormatList?: Record<string, any> | null;
        targetingOption?: string | null;
        flightCategory?: Types.FlightCategory | null;
        platformOption?: Array<string | null> | null;
        mapType?: Types.MapType | null;
        advancedOptions?: {
          autoRenewList?: Array<string | null> | null;
          abTestFlag?: boolean | null;
          darkPeriodList?: Array<{ index: number; startDate: string; endDate: string } | null> | null;
        } | null;
      } | null> | null;
    }> | null;
  } | null;
};

export const GetPackageConfigByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPackageConfigById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'packageId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'package' },
            name: { kind: 'Name', value: 'getMediaplannerPackage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'packageId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PackageConfigFragment' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'products' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProductFragment' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'productConfig' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...PackageConfigFragmentFragmentDoc.definitions,
    ...ProductFragmentFragmentDoc.definitions,
    ...TargetingSegmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetPackageConfigByIdQuery, GetPackageConfigByIdQueryVariables>;

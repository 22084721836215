import Vue from 'vue';
import commonPkg from '@/shared/legacy/isomorphic';

const { datesHelpers } = commonPkg;

export const filter = (value: string | null, helpers = datesHelpers): string => {
  if (value) {
    try {
      const toISO = new Date(value).toISOString();

      return helpers.getTimeAgo(toISO);
    } catch (err) {
      return '-';
    }
  }

  return '-';
};

Vue.filter('formatTimeAgo', filter);

import { SegmentCategory, SegmentSubCategory } from '@/app/graphql';
import { SegmentItem } from '@/injectables';

export interface TargetingSegmentsState {
  loading: boolean;
  mainSegments: Record<SegmentCategory, SegmentItem & { children?: SegmentItem[] }[]> | null;
  demographicsSegments: Record<SegmentSubCategory, SegmentItem[]> | null;
}

export const state = (): TargetingSegmentsState => ({
  loading: false,
  mainSegments: null,
  demographicsSegments: null,
});

import * as Types from '../_types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { ProductFragmentFragmentDoc, ProductContractsFragmentDoc } from './product.fragment.generated';
import { TargetingSegmentFragmentDoc } from './campaign.fragment.generated';
export type TablePackageFragmentFragment = {
  id: string;
  name: string;
  isHidden?: boolean | null;
  category: Types.ProductConfigCategory;
  description?: string | null;
  minSpend: number;
  recommendedBudget: number;
};

export type PackageConfigFragmentFragment = {
  id: string;
  agencyId: string;
  name: string;
  budget: number;
  description?: string | null;
  category: Types.ProductConfigCategory;
  isHidden?: boolean | null;
  products?: Array<{
    id: string;
    dsId?: string | null;
    name: string;
    index?: number | null;
    fulfillmentMethod: Types.ProductConfigFullfillmentMethod;
    isEditable?: boolean | null;
    isHidden?: boolean | null;
    isLocked?: boolean | null;
    minDays?: number | null;
    minSpend?: number | null;
    budget: number;
    omsAccountNumber?: string | null;
    omsName?: string | null;
    category: Types.ProductConfigCategory;
    description?: Record<string, any> | null;
    recommendedBudget?: number | null;
    flightType?: string | null;
    type?: Types.ProductConfigType | null;
    IORecipientEmail?: string | null;
    rateRangeList?: Record<string, any> | null;
    noXmlFlag?: boolean | null;
    keyMetric?: Types.KeyMetric | null;
    keyMetricMultiplier?: number | null;
    productConfigId: string;
    isMonthly?: boolean | null;
    calcMinSpend?: number | null;
    keywords?: {
      list?: Array<{
        keyword?: string | null;
        searchVolume?: number | null;
        competition?: number | null;
        competitionLevel?: string | null;
        averageCpc?: number | null;
        recommendLevel?: number | null;
        isSelected?: boolean | null;
        isCustom?: boolean | null;
      } | null> | null;
      summary?: {
        numKeywords?: number | null;
        totalCpc?: number | null;
        totalClicks?: number | null;
        lowerCpc?: number | null;
        upperCpc?: number | null;
        lowerClicks?: number | null;
        upperClicks?: number | null;
      } | null;
    } | null;
    broadcastInfo?: {
      link?: string | null;
      broadcast?: Array<{
        days?: Array<Types.Weekdays | null> | null;
        startTime: string;
        endTime: string;
        spots: number;
        spend: number;
        rating: number;
        programName: string;
        station: string;
      } | null> | null;
    } | null;
    geoSelections?: {
      cityList?: Array<{ key?: string | null; name?: string | null }> | null;
      countyList?: Array<{ key?: string | null; name?: string | null }> | null;
      dmaList?: Array<{ key?: string | null; name?: string | null }> | null;
      stateList?: Array<{ key?: string | null; name?: string | null }> | null;
    } | null;
    questionnaire?: Array<{ id: string; index: number; answer: string; questionnaireId: string } | null> | null;
    flights?: Array<{
      id: string;
      index?: number | null;
      budget?: number | null;
      startDate?: string | null;
      endDate?: string | null;
      rate?: number | null;
      rateType?: Types.RateType | null;
      selectedPlatform?: string | null;
      isLocked?: boolean | null;
      platformOption?: Array<string | null> | null;
      targetingOption?: string | null;
      flightCategory?: Types.FlightCategory | null;
      market?: Record<string, any> | null;
      mapType?: Types.MapType | null;
      contextualKeywords?: Array<string | null> | null;
      advancedOptions?: {
        autoRenewList?: Array<string | null> | null;
        abTestFlag?: boolean | null;
        darkPeriodList?: Array<{ index: number; startDate: string; endDate: string } | null> | null;
      } | null;
      audienceSegments?: Array<{
        id: string;
        name: string;
        externalId: string;
        description?: string | null;
        segmentCategory: Types.SegmentCategory;
        segmentSubCategory?: Types.SegmentSubCategory | null;
        isMain: boolean;
      }> | null;
    } | null> | null;
    flightConfigs?: Array<{
      id: string;
      markupMax?: number | null;
      markupMin?: number | null;
      rateType?: Types.RateType | null;
      rate?: number | null;
      adFormatList?: Record<string, any> | null;
      targetingOption?: string | null;
      flightCategory?: Types.FlightCategory | null;
      platformOption?: Array<string | null> | null;
      mapType?: Types.MapType | null;
      advancedOptions?: {
        autoRenewList?: Array<string | null> | null;
        abTestFlag?: boolean | null;
        darkPeriodList?: Array<{ index: number; startDate: string; endDate: string } | null> | null;
      } | null;
    } | null> | null;
  }> | null;
};

export const TablePackageFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TablePackageFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MediaplannerPackage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', alias: { kind: 'Name', value: 'minSpend' }, name: { kind: 'Name', value: 'budget' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'recommendedBudget' },
            name: { kind: 'Name', value: 'budget' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isHidden' } },
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TablePackageFragmentFragment, unknown>;
export const PackageConfigFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PackageConfigFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MediaplannerPackage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'agencyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'budget' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isHidden' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'products' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProductFragment' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PackageConfigFragmentFragment, unknown>;


import { DateModelContract } from '@/injectables';
import { Models } from '@/injectables/tokens';
import Vue from 'vue';

export default Vue.extend({
  name: 'DatePickerMenu',

  useInjectable: [Models.Date],

  props: {
    /**
     * Reactive value used for v-model, date string
     */
    value: {
      type: String,
      default: '',
    },
    /**
     * Label for default slotted text-field.
     */
    label: {
      type: String,
      default: 'Date',
    },
    /**
     * Min date for datepicker.
     */
    min: {
      type: String,
      default: new Date().toISOString(),
    },
    /**
     * Max date for datepicker.
     */
    max: {
      type: String,
      default: new Date(new Date().setDate(new Date().getDate() + 100)).toISOString(),
    },
    /**
     * Is picker disabled?
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Rules for default slotted text-field
     */
    rules: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      isOpen: false,
    };
  },

  computed: {
    cleanValue(): string {
      return this.value && (this[Models.Date] as DateModelContract).dateToFormatDateString(this.value);
    },
  },

  methods: {
    onInput(date: string) {
      this.$emit('input', (this[Models.Date] as DateModelContract).ISOStringFromFormatDateString(date));
      this.$emit('complete-selection');
      this.isOpen = false;
    },
  },
});

import Vue from 'vue';
import { UIUtilsServiceContract } from '@/injectables/services/ui/contracts';
import { UIUtilsService } from '@/injectables/services/ui/implementations/utils';

// TODO: implement via factory + container
export const filter = (
  value: number | string | null,
  utilsService: UIUtilsServiceContract = new UIUtilsService(),
): number | string => {
  return value ? utilsService.bigNumberFormatter(Number(value)) : 0;
};

Vue.filter('formatBigNumber', filter);


import Vue from 'vue';
import { GeoModelContract } from '@/injectables';
import { Models, Services } from '@/injectables/tokens';

import TargetedAudienceSelector from '@/components/Proposal/TargetAudienceSelector.vue';
import OptionMap from '@/components/Proposal/Options/OptionMap.vue';
import { ProposalMarket, GeoObj } from '@/shared/types';

export default Vue.extend({
  name: 'FlightMap',

  inject: ['instantIO'],

  useInjectable: [
    Services.Validation,
    Models.InstantIO,
    Models.BaseProduct,
    Services.Product,
    Services.Calendar,
    Models.Geo,
  ],

  components: {
    OptionMap,
    TargetedAudienceSelector,
  },
  props: {
    flight: {
      type: Object,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    productId: {
      type: String,
      required: true,
    },
  },

  mounted(): void {
    this.$refs.flightConfigMap.adjustMap();
  },

  computed: {
    isDefaultDemographics(): boolean {
      return (
        (this.flight.audienceSegments === null ||
          (this.flight.audience === null && this.flight.demographics === null)) &&
        this.flight.market === null
      );
    },
    demographics: {
      get(): string[] {
        if (this.isDefaultDemographics) {
          return this.instantIO.campaign.demographics || [];
        }
        return this.flight?.demographics || [];
      },
      set(value: string): void {
        if (this.isDefaultDemographics) {
          return this.updateAllDemographicsData({
            demographics: value,
          });
        }
        this.$emit('update-demographics', value);
      },
    },
    audience: {
      get(): string[] {
        if (this.isDefaultDemographics) {
          return this.instantIO.campaign.audience || [];
        }
        return this.flight?.audience || [];
      },
      set(value: string): void {
        if (this.isDefaultDemographics) {
          return this.updateAllDemographicsData({
            audience: value,
          });
        }
        this.$emit('update-audience', value);
      },
    },
    dates(): string[] {
      return this.flight?.dates || [];
    },
    availsGeoName(): string {
      const allGeoSelections = [
        ...this.selectedStatesObjArr?.map(s => s?.name),
        ...this.selectedDistrictsObjArr?.map(d => d?.name),
        ...this.selectedDmas.map(d => {
          return (this[Models.Geo] as GeoModelContract).cleanDmaString(d);
        }),
        ...this.selectedCounties?.map(d => {
          const [, state] = d?.key?.split('_');
          return `${state}, ${d?.name}`;
        }),
        ...this.selectedCitiesObjArr?.map(c => c?.name),
        ...this.selectedZips?.map(z => z?.name),
      ];

      const totalElements = allGeoSelections.length;

      return `${allGeoSelections?.[0] || ''}${totalElements > 1 ? ' (+' + String(totalElements - 1) + ')' : ''}`;
    },
    market(): ProposalMarket {
      if (this.isDefaultDemographics) {
        return { ...this.instantIO.campaignMarket };
      }
      return { ...this.flight?.market?.geoSelections };
    },
    selectedStates(): GeoObj[] {
      const { stateList = [] } = this.market || {};

      return [...(stateList || [])];
    },
    selectedCities(): GeoObj[] {
      const { cityList = [] } = this.market || {};

      return [...(cityList || [])];
    },
    selectedCounties(): GeoObj[] {
      const { countyList = [] } = this.market || {};

      return [...(countyList || [])];
    },
    selectedCitiesObjArr(): GeoObj[] {
      const { cityList = [] } = this.market || {};

      return [...(cityList || [])];
    },
    selectedStatesObjArr(): GeoObj[] {
      const { stateList = [] } = this.market || {};

      return [...(stateList || [])];
    },
    selectedZips(): GeoObj[] {
      const { zipList = [] } = this.market || {};

      return [...(zipList || [])];
    },
    selectedDistrictsObjArr(): GeoObj[] {
      const { districts = [] } = this.market || {};

      return this.forceArray(districts || []);
    },
    selectedDistricts(): string[] {
      return this.selectedDistrictsObjArr;
    },
    selectedDmas(): GeoObj[] {
      const { dmaList = [] } = this.market || {};

      return [...(dmaList || [])];
    },
  },
  methods: {
    async updateAllDemographicsData({
      target = null,
      targetArray = null,
      demographics = null,
      audience = null,
    }: {
      target?: string | null;
      targetArray?: { key: string; name: string }[] | null;
      demographics?: null | any;
      audience?: null | any;
    } = {}): Promise<void> {
      target = target || Object.keys(this.market)[0];
      targetArray = targetArray || this.market[target];
      demographics = demographics || this.demographics;
      audience = audience || this.audience;

      const newGeoSelections = { ...(this.flight?.market?.geoSelections || {}) };
      newGeoSelections[(this[Models.Geo] as GeoModelContract).getGeoTypeList(target)] = targetArray;

      const flight = {
        ...this.flight,
        demographics,
        audience,
        market: { geoSelections: { ...newGeoSelections } },
      };

      this.$emit('update-flight', flight);
    },
    forceArray<T>(el: T | T[]): T[] {
      return Array.isArray(el) ? el : [el];
    },
    resetProduct(): void {
      this.$emit('clear-geo-selections');
    },
    async setGeoSelections({ target, targetArray }: { target: string; targetArray }): Promise<void> {
      if (this.isDefaultDemographics) {
        return this.updateAllDemographicsData({ target, targetArray });
      }
      this.$emit('set-geo-selections', { target, targetArray });
    },
  },
});

import * as Types from '../../../../../app/graphql/_types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type GetLibrarySlideByIdQueryVariables = Types.Exact<{
  slideId: Types.Scalars['UUID'];
}>;

export type GetLibrarySlideByIdQuery = {
  getPreparedSlide?: {
    colors?: Record<string, any> | null;
    dynamicLinks?: Record<string, any> | null;
    slides?: Array<{
      id: string;
      name?: string | null;
      userId?: string | null;
      colors?: Record<string, any> | null;
      shapes?: Record<string, any> | null;
      images?: Record<string, any> | null;
      textItems?: Record<string, any> | null;
      slideLibraryId?: string | null;
      label: string;
    }> | null;
  } | null;
};

export const GetLibrarySlideByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getLibrarySlideById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'slideId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getPreparedSlide' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'slideId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'slides' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'colors' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'shapes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'images' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'textItems' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'slideLibraryId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'colors' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dynamicLinks' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetLibrarySlideByIdQuery, GetLibrarySlideByIdQueryVariables>;


import CsvUploadDialog from '@/components/CsvUploadDialog.vue';
import uniq from 'lodash/uniq';
import { Models } from '@/injectables/tokens';
import { KeywordsModelContract } from '@/injectables';

export default {
  name: 'keywords-input',
  components: { CsvUploadDialog },

  useInjectable: [Models.Keywords],

  props: {
    keywords: {
      type: Array,
      required: true,
    },
  },

  watch: {
    keywords: {
      handler(keywords: string[]): void {
        this.keywordsText = keywords.join(', ');
      },
      immediate: true,
    },
  },

  data(): {
    keywordsText: string;
    openFileUpload: boolean;
  } {
    return {
      keywordsText: '',
      openFileUpload: false,
    };
  },

  mounted() {
    this.$refs.form.validate();
  },

  computed: {
    isContextual(): boolean {
      return true;
    },
    uploadHelperText(): string {
      return `CSV file smaller than 2MB. Max ${
        (this[Models.Keywords] as KeywordsModelContract).__maxAmountKeywords
      } keywords allowed. Every keyword must be on the first column, every new keyword in a different row.`;
    },
    localKeywords(): string[] {
      return this.splitKeywords(this.keywordsText);
    },
    isAboveMaxNumberKeywords(): boolean {
      return this.localKeywords.length > (this[Models.Keywords] as KeywordsModelContract).__maxAmountKeywords;
    },
    maxNumberKeywords(): boolean | string {
      return this.isAboveMaxNumberKeywords
        ? `The maximum amount of keywords is ${(this[Models.Keywords] as KeywordsModelContract).__maxAmountKeywords}`
        : true;
    },
  },

  methods: {
    uploadKeywordsFromCSV(csv: string[]): void {
      this.updateKeywords(csv);
      // this.$nextTick(() => {
      //   this.$refs.form.validate();
      // });
    },
    splitKeywords(text: string): string[] {
      return uniq(text.split(/[\n;,]+/).map(k => k.trim().toLowerCase())).filter(k => !!k);
    },
    validateKeywords(): void {
      this.updateKeywords(this.splitKeywords(this.keywordsText));
    },
    updateKeywords(keywords: string[]): void {
      this.$emit('update:keywords', keywords);
      this.keywordsText = keywords.join(', ');
    },
  },
};

import 'reflect-metadata';

import { Service } from '../../../service';
import { Err, Ok, Result } from '@sniptt/monads';
import { Failure } from '@/injectables/failure';

import { injectable } from 'inversify';
import { BoundsParameters, GeoSelection, PolygonsServiceContract, PolygonData, Polygon } from '@/injectables';
import { ErrorResponse } from '@/shared/legacy/classes';
import LRU from 'lru-cache';

const _polygonDataCash = new LRU<string, PolygonData>({ max: 500 });

// for tests
export const _clearPolygonDataCash = () => _polygonDataCash.clear();

@injectable()
export class PolygonsService extends Service implements PolygonsServiceContract {
  async getPolygons(geoSelection: GeoSelection): Promise<Result<Polygon, Failure>> {
    const errorMessage = "Can't get polygons at this time";
    try {
      const preparedInput = {
        ...geoSelection,
        zips: geoSelection?.zips?.map(z => z.key.replace(/ZIP_/g, '')),
        counties: geoSelection?.counties?.map(c => c.key.replace(/CNTY_/g, '')),
        dmas: geoSelection?.dmas?.map(d => d.key.replace(/DMA_/g, '')),
        states: geoSelection?.states?.map(s => s.key.replace(/STAT_/g, '')),
        cities: geoSelection?.cities?.map(c => c.key.replace(/CITY_/g, '')),
        congressionalDistricts: geoSelection?.congressionalDistricts?.map(cd => cd.name),
      };

      const url = '/geo/getPolygons';
      const { data } = await this._axios.post<Polygon>(url, preparedInput);
      if (data?.error) {
        return Err({
          message: errorMessage,
        });
      }
      return Ok(data);
    } catch (error) {
      return Err({
        message: errorMessage,
      });
    }
  }

  // TODO: remove this method
  async getPolygonsAnonymous(geoSelection: GeoSelection): Promise<Result<Polygon, Failure>> {
    const errorMessage = "Can't get polygons at this time";

    try {
      const url = '/geo/getPolygons';

      const { data } = await this._axios.post<Polygon>(url, geoSelection);
      if (data?.error) {
        return Err({
          message: errorMessage,
        });
      }
      return Ok(data);
    } catch (error) {
      return Err({
        message: errorMessage,
      });
    }
  }
  async getPolygonData(boundsParameters: BoundsParameters): Promise<Result<PolygonData, Failure>> {
    const errorMessage = "Can't get polygon data at this time";

    try {
      const key = JSON.stringify(boundsParameters);
      if (_polygonDataCash.has(key)) {
        return Ok(_polygonDataCash.get(key) as PolygonData);
      }
      const url = '/geo/getPolygonData';

      const { data } = await this._axios.post<PolygonData | ErrorResponse>(url, boundsParameters);

      if ('error' in data) {
        return Err({
          message: errorMessage,
        });
      }

      const cleanData = Object.fromEntries(
        Object.entries(data).filter(([, value]) => Object.keys(value || {}).length),
      ) as PolygonData;

      _polygonDataCash.set(key, cleanData);
      return Ok(cleanData);
    } catch (error) {
      return Err({
        message: errorMessage,
      });
    }
  }
}

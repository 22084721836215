
import Vue, { PropType } from 'vue';

import { ConfiguredProduct } from '@/shared/types';
import WrapperWithTooltip from '@/components/WrapperWithTooltip.vue';
import ProductInfoDialog from '@/components/ProductInfoDialog.vue';
import { Models } from '@/injectables/tokens';
import { BaseProductModelContract } from '@/injectables';
export default Vue.extend({
  name: 'ProductCheckBox',

  useInjectable: [Models.BaseProduct],

  components: { WrapperWithTooltip, ProductInfoDialog },

  props: {
    product: {
      type: Object as PropType<ConfiguredProduct>,
      required: true,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    disabledByGeo: {
      type: Boolean,
      default: false,
    },
    disabledByDates: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: '',
    },
    customClass: {
      type: String,
      default: '',
    },
    campaignDuration: {
      type: Number,
    },
  },
  computed: {
    isSelected: {
      get(): boolean {
        return this.selected;
      },
      set() {
        this.$emit('change-selection');
      },
    },
    classes() {
      return `${this.customClass} ${this.isSelected ? 'selected-border' : ''} ${
        this.disabled && !this.selected ? 'cursor-not-allowed disabled' : ''
      }`;
    },
    disabled() {
      return this.disabledByGeo || this.disabledByDates;
    },
  },
  methods: {
    productIcon(categoryName?: string): string {
      let category = this.product.category;

      if (categoryName) {
        category = categoryName;
      }

      return (this[Models.BaseProduct] as BaseProductModelContract).categoryIconAndColor(category).icon;
    },
  },
});

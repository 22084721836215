import 'reflect-metadata';
import { injectable, inject } from 'inversify';
import { OutputModelContract, SlideGroupType, ImageKeys, EnvServiceContract } from '@/injectables';
import { OutputSlide, UnsafeAny } from '@/shared/legacy/classes';
import { OutputLayoutTypeGql } from '@/app/graphql';
import { Services } from '@/injectables/tokens';

@injectable()
export class OutputModel implements OutputModelContract {
  @inject(Services.Env) private readonly _env: EnvServiceContract;

  sortSlidesByName<T>(slides: ({ name: string } & T)[]): T[] {
    return slides.map(slide => slide).sort((a, b) => a.name.localeCompare(b.name));
  }

  flattenSlides(slides: { id: string; readonly?: boolean; slides: UnsafeAny[] }[]): OutputSlide[] {
    return slides
      .map(slide => slide.slides.map(sl => ({ ...sl, slideLibraryId: slide.id, readonly: slide.readonly })))
      .flat();
  }

  SlideGroupPropertyMapper: Record<SlideGroupType, string> = Object.freeze({
    [SlideGroupType.Before]: 'orderBeforeProducts',
    [SlideGroupType.After]: 'orderAfterProducts',
    [SlideGroupType.Products]: 'isProduct',
  });

  getSlidesByGroup(slides: OutputSlide[], slideGroup: SlideGroupType): OutputSlide[] {
    return slides.filter(slide => slide[this.SlideGroupPropertyMapper[slideGroup]]);
  }

  defaultGroupFlags = Object.freeze({
    orderBeforeProducts: null,
    orderAfterProducts: null,
    isProduct: null,
  });

  getSlideMarkedByOrderGroup(slide: OutputSlide, slideGroup: SlideGroupType): OutputSlide {
    const currentFlags = { ...this.defaultGroupFlags, [this.SlideGroupPropertyMapper[slideGroup]]: true };

    return { ...slide, ...currentFlags };
  }

  getMarkedSlides(slides: OutputSlide[], slideGroup: SlideGroupType): OutputSlide[] {
    return slides.map(slide => this.getSlideMarkedByOrderGroup(slide, slideGroup));
  }

  getSlideGroup(slide: OutputSlide): SlideGroupType {
    if (slide.orderBeforeProducts) return SlideGroupType.Before;
    if (slide.orderAfterProducts) return SlideGroupType.After;
    return SlideGroupType.Products;
  }

  isDynamicColorOutput(layout: { type: OutputLayoutTypeGql }): boolean {
    const dynamicColorTypes = [OutputLayoutTypeGql.Ultimate, OutputLayoutTypeGql.Genericsecond];
    const { type } = layout || {};
    return dynamicColorTypes.includes(type);
  }

  isGenericSecond(layout: { type: OutputLayoutTypeGql }): boolean {
    return layout.type && layout.type === OutputLayoutTypeGql.Genericsecond;
  }

  getColorStyles(colorScheme): Record<string, string> {
    const colorToVariableMapper = {
      textHeading: '--output-text-heading',
      textSubheading: '--output-text-sub-heading',
      textBody: '--output-text-body',
      cardTextHeading: '--output-card-text-heading',
      cardTextSubheading: '--output-card-text-sub-heading',
      cardTextBody: '--output-card-text-body',
      accent: '--output-accent',
      card: '--output-card',
      mapLayer: '--output-map',
      border: '--output-border',
      primary: '--output-primary',
    };

    return Object.fromEntries(
      Object.entries(colorScheme || {}).map(([key, value]) => [colorToVariableMapper[key], value]),
    );
  }

  getOutputImage(key: ImageKeys): string {
    try {
      const bucket = this._env.getOrThrow('VUE_APP_AWS_S3_PUBLIC_BUCKET', v => v);

      const imageMapper = {
        [ImageKeys.UltimateLayout]: `https://${bucket}.s3.amazonaws.com/layouts/output/aqua.png`,
        [ImageKeys.GenericSecondLayout]: `https://${bucket}.s3.amazonaws.com/layouts/generic-second/cover.png`,
        [ImageKeys.CustomLayout]: `https://${bucket}.s3.amazonaws.com/layouts/output/violet.png`,
        [ImageKeys.ClientLogo]: `https://${bucket}.s3.amazonaws.com/layouts/ultimate/title/logo.png`,
      };
      return imageMapper[key] || '';
    } catch (e) {
      return '';
    }
  }
}


import Vue from 'vue';
import debounce from 'lodash.debounce';
import MapComponent from '@/shared/legacy/map/map.vue';
import MapPopup from '@/components/Proposal/Options/MapPopup.vue';
import PolygonSelectionDropdowns from './PolygonSelectionDropdowns.vue';
import MapAddressSearch from './MapAddressSearch.vue';
import BulkLoader from './BulkLoaderCSV.vue';
import { GeoObj, Nullable, UnsafeAny } from '@/shared/types';
import { Services, Models } from '@/injectables/tokens';
import {
  LoggerContract,
  PolygonsServiceContract,
  GeoTypes,
  PolygonData,
  ProductModelContract,
  GeocodeServiceContract,
} from '@/injectables';
import { uniqBy } from 'lodash';

const addressToLatLong: Record<string, { lat: number; lon: number }> = {};
const PopupComponentClass = Vue.extend(MapPopup);

let polygonStore: PolygonData;
let mapMovedTimer, loadPolygonTimer, popupComponentInstance, popup;

export default Vue.extend({
  name: 'ProposalMarketMap',

  useInjectable: [Models.Product, Services.Polygons, Services.Geocode],

  components: { MapComponent, PolygonSelectionDropdowns, MapAddressSearch, BulkLoader },

  props: {
    height: {
      type: Number,
    },
    basicLegend: {
      type: Boolean,
      default: false,
    },
    displayMapPopup: {
      type: Boolean,
      default: true,
    },
    renderKey: {
      type: String,
    },
    offsetForDemographics: {
      type: Number,
      default: 400,
    },
    isClientLocation: {
      type: Boolean,
      default: false,
    },
    isChangeDisabled: {
      type: Boolean,
      default: false,
    },
  },

  data: (): {
    mapFirstRendered: boolean;
    legend: string;
    polygonSelection: { name: string; key: string; searchType: string; available: boolean } | null;
    polygonTypes: { name: string; key: string; searchType: string; available: boolean }[];
    mapReady: boolean;
    hasClientLocations: boolean;
    polygonsLoading: boolean;
    mapWidth: number;
    showCSVDialog: boolean;
    mapLoaded: boolean;
    mapMoveFor: UnsafeAny;
  } => ({
    mapWidth: 600,
    mapFirstRendered: true,
    legend: '',
    hasClientLocations: false,
    polygonsLoading: false,
    mapReady: false,
    polygonTypes: [
      { name: 'Zip', key: 'zips', searchType: 'zip', available: false },
      { name: 'City', key: 'cities', searchType: 'city', available: false },
      { name: 'DMA', key: 'dmas', searchType: 'dma', available: false },
      { name: 'State', key: 'states', searchType: 'state', available: false },
      { name: 'County', key: 'counties', searchType: 'county', available: false },
    ],
    polygonSelection: { name: 'DMA', key: 'dmas', searchType: 'dma', available: true },
    showCSVDialog: false,
    mapLoaded: false,
    mapMoveFor: null,
  }),

  watch: {
    polygonSelection(): void {
      // only react to change of polygon selection after the map is interactive
      if (this.mapReady) {
        this.RenderMap({ from: 'polygonSelection' });
      }
    },
    shouldRedrawPolygons(val: boolean): void {
      if (val) {
        this.RenderMap({ from: 'shouldRedrawPolygons' });
      }
    },
  },

  computed: {
    dropdownsWidth(): number {
      const offsetForZoom = 45;
      return this.mapWidth - offsetForZoom - this.offsetForDemographics;
    },
    selectedMap(): string {
      return this.$store.state.map.defaultMap;
    },
    address(): { address: string; lat: number; lon: number; text: string } {
      return this.$store.state['newProposal'].newProposal.market.address;
    },
    shouldRedrawPolygons: {
      get(): boolean {
        return this.$store.state['newProposal'].shouldRedrawPolygons;
      },
      set(status: boolean): void {
        this.$store.dispatch('newProposal/setShouldRedrawPolygons', status);
      },
    },
    allSelectedPolygons(): string[] {
      return [
        ...this.selectedCities,
        ...this.selectedZips,
        ...this.selectedDmas,
        ...this.selectedStates,
        ...this.selectedCounties,
      ];
    },
    selectedStates: {
      get(): GeoObj[] {
        if (this.isClientLocation) {
          if (this.$store.state.client.activeClient.geoSelections?.stateList) {
            return this.$store.getters['output/forceArray'](
              this.$store.state.client.activeClient.geoSelections.stateList,
            );
          }
          return [];
        } else {
          return this.$store.state['newProposal'].newProposal.market.geoSelections?.stateList || [];
        }
      },
      set(stateArr: GeoObj[]): void {
        if (this.isClientLocation) {
          this.$store.dispatch('client/setActiveClientGeoSelections', {
            target: 'stateList',
            targetArray: stateArr,
          });
        } else {
          this.$store.dispatch('newProposal/setGeoSelections', {
            target: 'stateList',
            targetArray: stateArr,
          });
        }
        this.$emit('market-map-updated');
      },
    },
    selectedCities: {
      get(): GeoObj[] {
        if (this.isClientLocation) {
          if (this.$store.state.client.activeClient.geoSelections?.cityList) {
            return this.$store.getters['output/forceArray'](
              this.$store.state.client.activeClient.geoSelections.cityList,
            );
          }
          return [];
        } else {
          return this.$store.state['newProposal'].newProposal.market.geoSelections?.cityList || [];
        }
      },
      set(cityArr: GeoObj[]): void {
        if (this.isClientLocation) {
          this.$store.dispatch('client/setActiveClientGeoSelections', {
            target: 'cityList',
            targetArray: cityArr,
          });
        } else {
          this.$store.dispatch('newProposal/setGeoSelections', {
            target: 'cityList',
            targetArray: cityArr,
          });
        }
        this.$emit('market-map-updated');
      },
    },
    selectedZips: {
      get(): GeoObj[] {
        if (this.isClientLocation) {
          if (this.$store.state.client.activeClient.geoSelections?.zipList) {
            return this.$store.getters['output/forceArray'](
              this.$store.state.client.activeClient.geoSelections.zipList,
            );
          }
          return [];
        } else {
          return this.$store.state['newProposal'].newProposal.market.geoSelections?.zipList || [];
        }
      },
      set(zipArr: GeoObj[]): void {
        if (this.isClientLocation) {
          this.$store.dispatch('client/setActiveClientGeoSelections', {
            target: 'zipList',
            targetArray: zipArr,
          });
        } else {
          this.$store.dispatch('newProposal/setGeoSelections', {
            target: 'zipList',
            targetArray: zipArr,
          });
        }
        this.$emit('market-map-updated');
      },
    },
    selectedCounties: {
      get(): GeoObj[] {
        if (this.isClientLocation) {
          if (this.$store.state.client.activeClient.geoSelections?.countyList) {
            return this.$store.getters['output/forceArray'](
              this.$store.state.client.activeClient.geoSelections.countyList,
            );
          }
          return [];
        } else {
          return this.$store.state['newProposal'].newProposal.market.geoSelections?.countyList || [];
        }
      },
      set(countyArr: GeoObj[]): void {
        if (this.isClientLocation) {
          this.$store.dispatch('client/setActiveClientGeoSelections', {
            target: 'countyList',
            targetArray: countyArr,
          });
        } else {
          this.$store.dispatch('newProposal/setGeoSelections', {
            target: 'countyList',
            targetArray: countyArr,
          });
        }
        this.$emit('market-map-updated');
      },
    },
    selectedDmas: {
      get(): GeoObj[] {
        if (this.isClientLocation) {
          if (this.$store.state.client.activeClient.geoSelections?.dmaList) {
            return this.$store.getters['output/forceArray'](
              this.$store.state.client.activeClient.geoSelections.dmaList,
            );
          }
          return [];
        } else {
          return this.$store.state['newProposal'].newProposal.market.geoSelections?.dmaList || [];
        }
      },
      set(dmaArr: GeoObj[]): void {
        if (this.isClientLocation) {
          this.$store.dispatch('client/setActiveClientGeoSelections', {
            target: 'dmaList',
            targetArray: dmaArr,
          });
        } else {
          this.$store.dispatch('newProposal/setGeoSelections', {
            target: 'dmaList',
            targetArray: dmaArr,
          });
        }
        this.$emit('market-map-updated');
      },
    },
  },

  mounted(): void {
    polygonStore = { states: {}, cities: {}, dmas: {}, zips: {}, counties: {}, congressionalDistricts: {} };

    popupComponentInstance = new PopupComponentClass();

    popupComponentInstance.$mount();

    this.calculateMapWidth();

    if (Array.isArray(this.selectedStates) && this.selectedStates.length) {
      const zoom = this.selectedStates.length > 25 ? 4 : 6;
      this.zoomToGeoSelection('states', this.selectedStates, zoom);
    } else if (Array.isArray(this.selectedDmas) && this.selectedDmas.length) {
      this.zoomToGeoSelection('dmas', this.selectedDmas, 8);
    } else if (Array.isArray(this.selectedCounties) && this.selectedCounties.length) {
      this.zoomToGeoSelection('counties', this.selectedCounties, 9);
    } else if (Array.isArray(this.selectedCities) && this.selectedCities.length) {
      this.zoomToGeoSelection('cities', this.selectedCities, 11);
    } else if (Array.isArray(this.selectedZips) && this.selectedZips.length) {
      this.zoomToGeoSelection('zips', this.selectedZips, 12);
    }
  },

  methods: {
    startBulkUpload(): void {
      if (this.polygonSelection?.key !== 'zips') {
        this.cleanGeoSelections(['states', 'cities', 'counties', 'dmas']);
      }

      this.showCSVDialog = true;
    },
    addZips(zips) {
      const parsedZips = zips.map(z => ({ key: `ZIP_${z}`, name: z }));

      const selectedKeys = new Set(this.selectedZips.map(el => el.key));
      const hasDuplicates = parsedZips.some((z: { key: string; name: string }) => selectedKeys.has(z.key));

      if (hasDuplicates) {
        this.$store.dispatch('showSnackbar', { content: 'Some zips already selected', color: 'warning' });
      }

      const newZipList = uniqBy([...this.selectedZips, ...parsedZips], 'key');
      this.updateSelectedPolygons({ polygon: 'zips', list: newZipList });
      this.setPolygonSelection({ name: 'Zip', key: 'zips', available: true });
    },

    async zoomToGeoSelection(key: GeoTypes, list: Array<string>, zoomLevel = 0, retries = 10): Promise<void> {
      this.polygonSelection = this.polygonTypes.find(p => p.key === key);
      this.hasClientLocations = true;
      let map = this.$refs.marketMap?.Get();

      if (!map || !map.host || !map.leaflet) {
        if (retries > 0) {
          setTimeout(() => {
            this.zoomToGeoSelection(key, list, zoomLevel, retries - 1);
          }, 1000);
          return;
        }
        // eslint-disable-next-line no-console
        console.error('map component did not load correctly', map);
        return;
      }

      this.setPolygonTypeByKey(key);
      this.polygonsLoading = true;
      map.ClearMap();

      const { isErr, unwrap, unwrapErr } = await (this.polygonsService as PolygonsServiceContract).getPolygons({
        [key]: list,
      });
      this.polygonsLoading = false;
      if (isErr()) {
        const { message = '' } = unwrapErr();
        const logger: LoggerContract = this.$container.get('logger');
        logger.print('error', 'ProposalSummaryMap', message);
        return;
      }

      // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
      const { pstate, ...polygons } = unwrap();
      map = this.$refs.marketMap?.Get();
      // chances are, we're navigating from the client's info page, that has this same map with different dimensions
      map.host.invalidateSize();

      const selectedPolyStyle = {
        fillOpacity: 0.4,
        color: this.$vuetify.theme.themes.light.primary,
        fillColor: this.$vuetify.theme.themes.light.primary,
        weight: 2,
      };
      const polygonIDs = Object.keys(polygons);
      const layer = map.CreateLayer(key);
      polygonIDs.forEach(polyName => {
        const p: UnsafeAny = polygons[polyName];
        const polygonLayer = map.AddToLayer(layer);
        map.SetWKT(polygonLayer, p.WKT_P100, true);
        polygonLayer.setStyle(selectedPolyStyle);
      });
      map.FitAllLayers({
        animate: false,
        paddingTopLeft: [30, 30],
        paddingBottomRight: [this.offsetForDemographics, 30],
      });
    },
    updateSelectedPolygons({ polygon, list }: { polygon: string; list: UnsafeAny[] }): void {
      if (polygon === 'zips') {
        this.selectedZips = [...list];
      } else if (polygon === 'cities') {
        this.selectedCities = [...list];
      } else if (polygon === 'dmas') {
        this.selectedDmas = [...list];
      } else if (polygon === 'counties') {
        this.selectedCounties = [...list];
      } else if (polygon === 'states') {
        this.selectedStates = [...list];
      }
    },
    calculateMapWidth(): void {
      const container = document.getElementById('market-map-container');
      if (container?.clientWidth) {
        this.mapWidth = container.clientWidth;
      }
    },
    setPolygonSelection(selectionObj: { name: string; key: string; available: boolean }): void {
      this.setPolygonTypeByKey(selectionObj.key, selectionObj.available);
      // this.polygonSelection = selectionObj;
      if (selectionObj?.key === 'cities') {
        this.setMapView({ fromToggleCity: true });
      } else if (selectionObj?.key === 'zips') {
        this.setMapView({ fromToggleZip: true });
      } else if (selectionObj?.key === 'dmas') {
        this.setMapView({ fromToggleDma: true });
      } else if (selectionObj?.key === 'counties') {
        this.setMapView({ fromToggleCounty: true });
      } else {
        this.setMapView({ fromToggleState: true });
      }
    },
    resizeMap(): void {
      this.calculateMapWidth();
      this.debounceMapMoved();
    },
    debounceMapMoved(): void {
      if (mapMovedTimer) {
        clearTimeout(mapMovedTimer);
      }
      mapMovedTimer = setTimeout(() => {
        this.onMapMoved();
      }, 400);
    },
    debounceLoadPolygons(options: object): void {
      // console.log('debounceLoadPolygons');
      if (loadPolygonTimer) {
        clearTimeout(loadPolygonTimer);
      }
      loadPolygonTimer = setTimeout(() => {
        this.LoadActivePolygons(options);
      }, 500);
    },
    onMapMoved(): void {
      const map = this.$refs.marketMap?.Get();

      if (!this.mapReady || !map || !map.host || !map.leaflet) {
        // eslint-disable-next-line no-console
        console.log('map not ready');
        return;
      }

      this.debounceLoadPolygons({ from: 'onMapMoved', userPick: this.mapMoveFor });
    },
    moveToSearchedGeo(val) {
      if (!val?.address && !val?.lat && !val?.lon) {
        return;
      }

      addressToLatLong[val.address] = {
        lat: val.lat,
        lon: val.lon,
      };

      const map = this.$refs.marketMap?.Get();

      if (!map || !map.host) {
        // eslint-disable-next-line no-console
        console.error('map component did not load correctly', map);
        return;
      }

      this.mapMoveFor = val;

      map.ClearMap();

      this.setMapView({ fromInit: true, forceFetchPolygons: true, userPick: val });
    },
    async onMapReady(): Promise<void> {
      const map = this.$refs.marketMap?.Get();
      if (!map || !map.host) {
        // eslint-disable-next-line no-console
        console.error('map component did not load correctly', map);
        return;
      }

      this.mapReady = true;
      if (this.hasClientLocations) {
        return;
      }

      const goToAddress = async (address: string) => {
        const geocodeService: GeocodeServiceContract = this.geocodeService;

        const location = encodeURIComponent(address);

        const { isErr, unwrapErr, unwrap } = await geocodeService.searchByLocation(location);

        if (isErr()) return this.showSnackbar(unwrapErr().message, 'error');

        unwrap().forEach(entry => {
          if (entry.position && entry.address && entry.address.label) {
            addressToLatLong[entry.address.label] = {
              lat: entry.position.lat,
              lon: entry.position.lng,
            };
          }
        });

        this.setMapView({ fromInit: true });
        setTimeout(() => {
          this.debounceLoadPolygons({ from: 'onMapReady' });
        }, 1000);
      };

      map.ClearMap();
      map.SetView([40, -100], 7);

      if (this.address?.address && addressToLatLong[this.address.address]) {
        this.setMapView({ fromInit: true });
        setTimeout(() => {
          this.debounceLoadPolygons({ from: 'onMapReady' });
        }, 1000);
        return;
      } else if (this.address?.address?.length) {
        goToAddress(this.address.address);
        return;
      } else if (this.$store.state?.client?.activeClient?.address?.length) {
        const address = this.$store.state.client.activeClient.address[0];
        if (address?.lat && address?.lon) {
          this.setMapView({ fromInit: true, lat: address.lat, lon: address.lon });
          return;
        }
        goToAddress(address.address);
        return;
      }
      this.debounceLoadPolygons({ from: 'onMapReady' });
    },
    setMapView: debounce(function ({
      fromInit,
      fromToggleState,
      fromToggleDma,
      fromToggleZip,
      fromToggleCity,
      fromToggleCounty,
      fromToggleNational,
      forceFetchPolygons = false,
      lat,
      lon,
    }): void {
      if (fromInit && !(lat && lon) && (!this.address?.address || !addressToLatLong[this.address?.address])) {
        // eslint-disable-next-line no-console
        console.log('no position for address', this.address);
        return;
      }
      const zoomValue = {
        states: 7,
        dmas: 8,
        counties: 9,
        cities: 11,
        zips: 12,
      };
      const defaultZoom = 11;
      const map = this.$refs.marketMap?.Get();
      if (!this.mapReady || !map || !map.host || !map.leaflet) {
        // eslint-disable-next-line no-console
        console.log('map not ready');
        return;
      }

      if (fromInit) {
        if (!lat || !lon) {
          const addr = addressToLatLong[this.address.address];

          if (addr) {
            lat = addr.lat;
            lon = addr.lon;
          }
        }
        map.FlyTo([lat, lon], zoomValue[this?.polygonSelection?.key] || defaultZoom);
      }

      if (forceFetchPolygons) {
        this.debounceMapMoved();
      }

      if (fromToggleState) {
        map.SetZoom(6);
      }
      if (fromToggleNational) {
        map.SetZoom(4);
      }
      if (fromToggleDma) {
        map.SetZoom(8);
      }
      if (fromToggleCounty) {
        map.SetZoom(9);
      }
      if (fromToggleCity) {
        map.SetZoom(11);
      }
      if (fromToggleZip) {
        map.SetZoom(12);
      }
    },
    200),
    async LoadActivePolygons(options: object): Promise<void> {
      if (!this.mapReady) {
        setTimeout(() => {
          // console.log('debounceLoadPolygons !mapReady');
          this.debounceLoadPolygons(options);
        }, 250);
        return;
      }
      const map = this.$refs.marketMap?.Get();
      if (!map || !map.host || !map.leaflet) {
        // eslint-disable-next-line no-console
        console.error('map component did not load correctly', map);
        return;
      }
      const b = map.GetBounds();
      if (!b) {
        // eslint-disable-next-line no-console
        console.error('map component error', map);
        return;
      }
      const s = b.getSouth();
      const w = b.getWest();
      const n = b.getNorth();
      const e = b.getEast();

      const boundsParameters = {
        minLat: s,
        maxLat: n,
        minLon: w,
        maxLong: e,
        zips: false,
        cities: false,
        dmas: false,
        states: false,
        counties: false,
        congressionalDistricts: false,
      };

      const zoom = map.GetZoom();
      const selectedLayer = this.polygonSelection?.key || 'dmas';
      const allowedPolygons: string[] = [];

      if (!this.hasClientLocations && !this.mapLoaded) {
        allowedPolygons.push('dmas');
      } else if (zoom <= 6) {
        allowedPolygons.push('states');
      } else if (zoom <= 8) {
        allowedPolygons.push('states', 'dmas');
      } else if (zoom > 8 && zoom < 11) {
        allowedPolygons.push('dmas', 'cities', 'counties');
      } else {
        allowedPolygons.push('cities', 'zips');
      }

      if (allowedPolygons.includes(selectedLayer)) {
        boundsParameters[selectedLayer] = true;
        this.polygonsLoading = true;
        this.mapMoveFor = null;
        const { isErr, unwrap, unwrapErr } = await (this.polygonsService as PolygonsServiceContract).getPolygonData(
          boundsParameters,
        );
        this.polygonsLoading = false;
        if (isErr()) {
          const { message = '' } = unwrapErr();
          const logger: LoggerContract = this.$container.get('logger');
          logger.print('error', 'ProposalSummaryMap', message);
          return;
        }

        const polygonData = unwrap();
        // console.log('getPolygonData', zoom, polygonData);
        if (polygonData.states) {
          polygonStore.states = { ...polygonData.states, ...polygonStore.states };
        }
        if (polygonData.cities) {
          polygonStore.cities = { ...polygonData.cities, ...polygonStore.cities };
        }
        if (polygonData.dmas) {
          polygonStore.dmas = { ...polygonData.dmas, ...polygonStore.dmas };
        }
        if (polygonData.zips) {
          polygonStore.zips = { ...polygonData.zips, ...polygonStore.zips };
        }
        if (polygonData.counties) {
          polygonStore.counties = { ...polygonData.counties, ...polygonStore.counties };
        }
        this.mapLoaded = true;
        // TODO: populate this.availableZips and cie
        this.RenderMap({ ...options, from: 'getPolygonData' });
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    RenderLayer(map: any, layerName: string, options: any): void {
      const store = polygonStore[layerName];
      if (!store) {
        // eslint-disable-next-line no-console
        console.error('RenderLayer error, layer unavailable', layerName, polygonStore);
        return;
      }

      const polygonIDs = Object.keys(store);
      if (polygonIDs.length === 0) {
        // console.log('trying to render layer', layerName, 'no polygons', polygonStore);
        return;
      }

      let isActiveLayer = false;
      if (this.polygonSelection?.key === layerName) {
        isActiveLayer = true;
      }

      const layer = map.CreateLayer(layerName);

      const backgroundSelectedPolyStyle = {
        fillOpacity: 0.1,
        color: this.$vuetify.theme.themes.light.primary,
        fillColor: this.$vuetify.theme.themes.light.primary,
        weight: 1,
      };
      const selectablePolyStyle = {
        fillOpacity: 0.1,
        color: '#888888',
        fillColor: '#FFFFFF',
        weight: 2,
      };
      const selectableHoverPolyStyle = {
        fillOpacity: 0.5,
        color: '#888888',
        fillColor: '#FFFFFF',
        weight: 2,
      };
      const selectedPolyStyle = {
        fillOpacity: 0.4,
        color: this.$vuetify.theme.themes.light.primary,
        fillColor: this.$vuetify.theme.themes.light.primary,
        weight: 2,
      };
      const selectedHoverStyle = {
        fillOpacity: 0.6,
        color: this.$vuetify.theme.themes.light.primary,
        fillColor: this.$vuetify.theme.themes.light.primary,
        weight: 4,
      };

      let userHasSearchedFor: Nullable<string> = null;
      if (options.userPick) {
        // TODO: need to ask geo service to make key in search the same as in other places TODO: move to injectables
        const geoTypeMap = {
          dma: 'DMA',
          city: 'CITY',
          zip: 'ZIP',
          state: 'STAT',
          county: 'CNTY',
        };

        userHasSearchedFor = `${geoTypeMap[options.userPick.type]}_${options.userPick.key}`;
      }
      let userSelectPolygonPicked = false;

      polygonIDs.forEach(key => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const p: any = store[key];
        if (!p || !p.name || !p.wkt) {
          // console.log('skipping polygon', layerName, key, 'not valid', polygonStore);
          return;
        }
        let isSelected = this.allSelectedPolygons.some(polygon => {
          return polygon.key === p.key || (polygon?.key === polygon?.name && p?.name === polygon?.name);
        });

        if (!isSelected && !isActiveLayer) {
          // only render selected polygons on background layers
          return;
        }

        const polygonKey = p.key;
        // if dma, see it's in the name
        if (!isSelected && userHasSearchedFor === polygonKey && !userSelectPolygonPicked) {
          this.toggleSelectedPolygons(layerName, p);
          isSelected = true;
          userSelectPolygonPicked = true;
        }

        const polygonLayer = map.AddToLayer(layer);

        map.SetWKT(polygonLayer, p.wkt, true);
        if (!isActiveLayer) {
          polygonLayer.setStyle(backgroundSelectedPolyStyle);
          return;
        }
        if (isSelected) {
          polygonLayer.setStyle(selectedPolyStyle);
        } else {
          polygonLayer.setStyle(selectablePolyStyle);
        }
        polygonLayer
          .on('click', () => {
            if (this.isChangeDisabled) return;

            this.toggleSelectedPolygons(layerName, p);
            isSelected = !isSelected;
            if (isSelected) {
              polygonLayer.setStyle(selectedHoverStyle);
            } else {
              polygonLayer.setStyle(selectableHoverPolyStyle);
            }
          })
          .on('mousemove', () => {
            if (isSelected) {
              polygonLayer.setStyle(selectedHoverStyle);
            } else {
              polygonLayer.setStyle(selectableHoverPolyStyle);
            }

            if (popup && this.legend !== p.name) {
              map.ClosePopup(popup);
            }

            this.legend = p.name;
          })
          .on(
            'mousemove',
            debounce(function (e: UnsafeAny) {
              if (this.displayMapPopup && popupComponentInstance && this.legend === p.name) {
                Object.keys(p).forEach(polygonKey => {
                  if (['wkt', 'key'].includes(polygonKey)) return;
                  popupComponentInstance[polygonKey] = p[polygonKey];
                });

                const cleanGeoType = {
                  dmas: 'DMA',
                  cities: 'City',
                  zips: 'Zip',
                  counties: 'County',
                  states: 'State',
                };

                popupComponentInstance.geoType = cleanGeoType[layerName];

                popup = map.CreatePopup(popupComponentInstance?.$el || p.name, e.latlng, {
                  offset: [2, 4],
                  autoPan: false,
                });
              }
            }, 1000).bind(this),
          )
          .on('mouseout', () => {
            if (isSelected) {
              polygonLayer.setStyle(selectedPolyStyle);
            } else {
              polygonLayer.setStyle(selectablePolyStyle);
            }
            this.legend = '';
          });
      });
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    RenderMap(options: any): void {
      try {
        options = options || {};
        options.autoZoom = options.autoZoom || false;
        options.retries = options.retries || 10;

        const map = this.$refs.marketMap?.Get();
        if (!map || !map.host || !map.leaflet) {
          if (options.retries > 0) {
            setTimeout(() => {
              options.retries -= 1;
              this.RenderMap(options);
            }, 1000);
            return;
          }
          // eslint-disable-next-line no-console
          console.error('map component did not load correctly', map);
          return;
        }
        if (this.shouldRedrawPolygons) {
          this.shouldRedrawPolygons = false;
        }
        map.ClearMap();
        // create a layer for each polygon type
        // default order is states > dmas > counties > cities > zips
        // but promote the active selection on top

        ['states', 'dmas', 'counties', 'cities', 'zips'].forEach((s: string) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const sel = this.polygonTypes.find((x: any) => x.key === s);
          sel.available = false;
          const store = polygonStore[s];
          // TODO: we should check if we have polygons of this type actually visible in the current view
          if (store && Object.keys(store).length > 0) {
            sel.available = true;
          }
        });
        if (!this.polygonSelection) {
          ['states', 'dmas', 'counties', 'cities', 'zips'].forEach((s: string) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const sel = this.polygonTypes.find((x: any) => x.key === s);
            if (sel.available) {
              this.setPolygonTypeByKey(s, true);
            }
          });
        }

        if (!this.polygonSelection) {
          // eslint-disable-next-line no-console
          console.error('RenderMap: no polygon available');
        }

        // const visibleLayers = {
        //   zips: false,
        //   cities: false,
        //   dmas: false,
        //   states: false,
        //   counties: false,
        // };
        // const zoom = map.GetZoom();
        // if (zoom < 8) {
        //   visibleLayers.states = true;
        //   visibleLayers.dmas = true;
        // } else if (zoom >= 8 && zoom < 12) {
        //   visibleLayers.dmas = true;
        //   visibleLayers.cities = true;
        // } else {
        //   visibleLayers.cities = true;
        //   visibleLayers.zips = true;
        // }

        switch (this.polygonSelection?.key) {
          case 'states':
            this.RenderLayer(map, 'dmas', options);
            this.RenderLayer(map, 'counties', options);
            this.RenderLayer(map, 'cities', options);
            this.RenderLayer(map, 'zips', options);
            this.RenderLayer(map, 'states', options);
            break;
          case 'dmas':
            this.RenderLayer(map, 'states', options);
            this.RenderLayer(map, 'counties', options);
            this.RenderLayer(map, 'cities', options);
            this.RenderLayer(map, 'zips', options);
            this.RenderLayer(map, 'dmas', options);
            break;
          case 'counties':
            this.RenderLayer(map, 'states', options);
            this.RenderLayer(map, 'dmas', options);
            this.RenderLayer(map, 'cities', options);
            this.RenderLayer(map, 'zips', options);
            this.RenderLayer(map, 'counties', options);
            break;
          case 'cities':
            this.RenderLayer(map, 'states', options);
            this.RenderLayer(map, 'dmas', options);
            this.RenderLayer(map, 'counties', options);
            this.RenderLayer(map, 'zips', options);
            this.RenderLayer(map, 'cities', options);
            break;
          case 'zips':
            this.RenderLayer(map, 'states', options);
            this.RenderLayer(map, 'dmas', options);
            this.RenderLayer(map, 'counties', options);
            this.RenderLayer(map, 'cities', options);
            this.RenderLayer(map, 'zips', options);
            break;
          default:
            // eslint-disable-next-line no-console
            console.error('RenderMap: unhandled polygon selection', this.polygonSelection);
            break;
        }
        if (options.autoZoom) {
          setTimeout(() => {
            map.FitAllLayers({
              animate: false,
              paddingTopLeft: [30, 30],
              paddingBottomRight: [this.offsetForDemographics, 30],
            });
          }, 250);
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error('SetPolygonLayerCity', err);
      }
    },
    setPolygonTypeByKey(key, available) {
      this.polygonSelection = { ...this.polygonTypes.find((x: UnsafeAny) => x.key === key), available };
    },
    setNationalCampaign(): void {
      this.setPolygonTypeByKey('states', true);
      const list = (this.productEntity as ProductModelContract).statesAndDistrictsNumber.map(({ state, short }) => ({
        name: state,
        key: short,
      }));

      this.setMapView({ fromToggleNational: true });
      this.cleanGeoSelections(['zips', 'cities', 'counties', 'dmas']);
      this.updateSelectedPolygons({ polygon: 'states', list });
    },
    cleanGeoSelections(geoList: string[]): void {
      geoList.forEach(geo => {
        this.updateSelectedPolygons({ polygon: geo, list: [] });
      });
    },
    toggleSelectedPolygons(layerName: string, polygon: { name: string; key: string }) {
      if (layerName === 'states') {
        const stateSelection = {
          name: polygon.name,
          key: polygon.key,
        };
        const selectedStates = [...this.selectedStates];
        let updatedSelectedStates: GeoObj[] = [];

        if (selectedStates.some(state => state.key === stateSelection.key)) {
          updatedSelectedStates = selectedStates.filter(state => state.key !== stateSelection.key);
        } else {
          updatedSelectedStates.push(...selectedStates, stateSelection);
        }
        this.selectedStates = updatedSelectedStates;
      } else if (layerName === 'counties') {
        const countySelection = {
          name: polygon.name,
          key: polygon.key,
        };
        const selectedCounties = [...this.selectedCounties];
        const existingCounty = selectedCounties.find(c => c?.key === countySelection?.key);
        let updatedSelectedCounties: GeoObj[] = [];

        if (existingCounty) {
          updatedSelectedCounties = selectedCounties.filter(county => county?.key !== countySelection?.key);
        } else {
          updatedSelectedCounties.push(...selectedCounties, countySelection);
        }
        this.selectedCounties = updatedSelectedCounties;
      } else if (layerName === 'cities') {
        const citySelection = {
          key: polygon.key,
          name: polygon.name,
        };
        const selectedCities = [...this.selectedCities];
        let updatedSelectedCities: GeoObj[] = [];

        if (selectedCities.some(city => city.key === citySelection.key)) {
          updatedSelectedCities = selectedCities.filter(city => city.key !== citySelection.key);
        } else {
          updatedSelectedCities.push(...selectedCities, citySelection);
        }
        this.selectedCities = updatedSelectedCities;
      } else if (layerName === 'zips') {
        const zipSelection = {
          key: polygon.key,
          name: polygon.name,
        };
        const selectedZips = [...this.selectedZips];
        const existingZip = selectedZips.find(z => z?.key === zipSelection?.key);
        let updatedSelectedZips: GeoObj[] = [];

        if (existingZip) {
          updatedSelectedZips = selectedZips.filter(zip => zip?.key !== zipSelection?.key);
        } else {
          updatedSelectedZips.push(...selectedZips, zipSelection);
        }
        this.selectedZips = updatedSelectedZips;
      } else if (layerName === 'dmas') {
        const dmaSelection = {
          key: polygon.key,
          name: polygon.name,
        };
        const selectedDmas = [...this.selectedDmas];
        const existingDma = selectedDmas.find(d => d?.key === dmaSelection?.key);
        let updatedSelectedDmas: GeoObj[] = [];

        if (existingDma) {
          updatedSelectedDmas = selectedDmas.filter(dma => dma?.key !== dmaSelection?.key);
        } else {
          updatedSelectedDmas.push(...selectedDmas, dmaSelection);
        }
        this.selectedDmas = updatedSelectedDmas;
      }
    },
  },
});

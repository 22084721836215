
import Vue from 'vue';
import ImageUpload from '../Proposal/ClientLogo.vue';
import ActiveElementEditor from './ActiveElementEditor.vue';
import HiddenSlide from './HiddenSlide.vue';
import Donut from './Charts/Donut.vue';
import OverviewMap from './OverviewMap.vue';
import GeoFenceMap from './GeoFenceMap.vue';
import { OutputEditorModelContract, OutputServiceContract } from '@/injectables';
import { Models, Services } from '@/injectables/tokens';
import {
  AlignmentType,
  AnyOutputElement,
  OutputProps,
  PreparedOutputImage,
  PreparedOutputShape,
  PreparedOutputText,
} from '@/shared/legacy/classes';

export default Vue.extend({
  name: 'SlideBuilder',

  useInjectable: [Services.Output, Models.OutputEditor],

  components: { Donut, OverviewMap, GeoFenceMap, ImageUpload, ActiveElementEditor, HiddenSlide },

  props: {
    height: {
      type: Number,
      default: ((window.innerWidth - 24) * 9) / 16,
    },
    isManageLayouts: {
      type: Boolean,
      default: false,
    },
    colors: {
      type: Object,
      default: () => ({ background: '#000000', text: '#FFFFFF' }),
    },
    images: {
      type: Array,
      default: () => [],
    },
    shapes: {
      type: Array,
      default: () => [],
    },
    textItems: {
      type: Array,
      default: () => [],
    },
    charts: {
      type: Array,
      default: () => [],
    },
    map: {
      type: Object,
      default: () => ({}),
    },
    _id: {
      type: String,
      default: '',
    },
    width: {
      type: Number,
      default: 0,
    },
    slideWidth: {
      type: Number,
      default: 0,
    },
    name: {
      type: String,
      required: true,
    },
    isHidden: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data: (): {
    activeElementIndex: number;
    contextMenu: {
      show: boolean;
      positionX: number;
      positionY: number;
      elements: string;
      element: string;
    };
    alignment: {
      inProgress: boolean;
      direction: '' | AlignmentType;
      element: {
        id: null | number;
        type: string;
      };
    };
    isCtrlPressed: boolean;
  } => ({
    contextMenu: {
      show: false,
      positionX: 0,
      positionY: 0,
      elements: '',
      element: '',
    },
    alignment: {
      inProgress: false,
      direction: '',
      element: {
        id: null,
        type: '',
      },
    },
    activeElementIndex: null,
    isCtrlPressed: false,
  }),

  computed: {
    fontSizes(): number[] {
      return (this.outputEditorEntity as OutputEditorModelContract).fontSizes;
    },
    slideElementTypes(): { shape: 'shapes'; text: 'textItems'; image: 'images' } {
      return (this.outputEditorEntity as OutputEditorModelContract).slideElementTypes;
    },
    elementPositionIncrement(): number {
      return (this.outputService as OutputServiceContract).outputElementPositionIncrement();
    },
    hideLegend(): boolean {
      return !!this.map?.mapData?.hideLegend;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    slideMapStyle(): any {
      const { w = '37.5%', h = '100%', y = '0%', x = '62.5%' } = this.map;
      return {
        left: x,
        width: w,
        height: h,
        top: y,
      };
    },
    disableDelete(): boolean {
      return !this.canEdit(this.contextMenu?.element, this.contextMenu?.elements);
    },
    deleteElementText(): string {
      let text = 'Delete';
      if (this.contextMenu?.elements === this.slideElementTypes.image) text += ' Image';
      else if (this.contextMenu?.elements === this.slideElementTypes.text) text += ' Text Element';
      else if (this.contextMenu?.elements === this.slideElementTypes.shape) text += ' Shape';
      return text;
    },
    activeElementId: {
      get(): string {
        return this.$store.state.output.activeElementId;
      },
      set(id: string): void {
        this.$store.dispatch('output/setActiveElementId', id);
      },
    },
    activeElementType: {
      get(): string {
        return this.$store.state.output.activeElementType;
      },
      set(type: string): void {
        this.$store.dispatch('output/setActiveElementType', type);
      },
    },
    isFirstLayer(): boolean {
      if (!this.contextMenu?.element) return false;
      const elementsToCheck = [...this.images, ...this.textItems, ...this.shapes].sort(
        (a, b) => parseInt(a?.props?.index, 10) - parseInt(b?.props?.index, 10),
      );
      const fullElement = elementsToCheck.find(el => el.id === this.contextMenu.element);
      const indexAsNumber = this.$store.getters['output/validateNumber']({
        value: fullElement?.props?.index,
        context: 'SlideBuilder/isFirstLayer, first',
      });
      const lowestIndexElement = Math.min(
        ...elementsToCheck.map(el =>
          this.$store.getters['output/validateNumber']({
            value: el?.props?.index,
            context: 'SlideBuilder/isFirstLayer, second',
          }),
        ),
      );
      return fullElement && indexAsNumber === lowestIndexElement;
    },
    isHighestLayer(): boolean {
      if (!this.contextMenu?.element) return false;
      const elementsToCheck = [...this.images, ...this.textItems, ...this.shapes].sort(
        (a, b) => parseInt(a?.props?.index, 10) - parseInt(b?.props?.index, 10),
      );
      const fullElement = elementsToCheck.find(el => el.id === this.contextMenu.element);
      const indexAsNumber = this.$store.getters['output/validateNumber']({
        value: fullElement?.props?.index,
        context: 'SlideBuilder/isHighestLayer, first',
      });
      const highestIndexElement = Math.max(
        ...elementsToCheck.map(el =>
          this.$store.getters['output/validateNumber']({
            value: el?.props?.index,
            context: 'SlideBuilder/isHighestLayer, second',
          }),
        ),
      );
      return fullElement && elementsToCheck?.length >= 1 && indexAsNumber === highestIndexElement;
    },
    // shapes
    slideIsEmpty(): boolean {
      return !this.images.length && !this.textItems.length && !this.shapes.length;
    },
    slideTexts(): PreparedOutputText[] {
      return this.$store.getters['output/slideTexts'](
        this.textItems,
        this.colors?.textBody || this.colors?.text,
        this.isPreviewSlide,
        this.transformHelper,
      );
    },
    slideShapes(): PreparedOutputShape[] {
      return this.$store.getters['output/slideShapes'](this.shapes, this.slideHeight);
    },
    slideImages(): PreparedOutputImage[] {
      return this.$store.getters['output/slideImages'](this.images);
    },
    slideHeight(): number {
      if (this.width) return (this.width * 9) / 16;
      else if (this.height) return this.height;
      return 0;
    },
    elementInc(): number {
      return this.$store.getters['output/elementInc'](this._id);
    },
  },

  methods: {
    doShortcutsOnKeyDown(event) {
      if (event.key === 'Control' || event.keyCode === 17) {
        this.isCtrlPressed = true;
      }
      if (event.key === 'Escape' || event.keyCode === 27) {
        this.activeElementId = '';
        this.activeElementType = '';

        this.alignment.inProgress = false;
        this.alignment.element.id = null;
        this.alignment.element.type = '';
        this.alignment.direction = false;
      }
    },
    doShortcutsOnKeyUp(event) {
      if (event.key === 'Control' || event.keyCode === 17) {
        this.isCtrlPressed = false;
      }
    },
    startAlignmentAction({
      element,
      alignmentType,
    }: {
      element: {
        id: string;
        type: string;
      };
      alignmentType: string;
    }): void {
      this.alignment.inProgress = true;
      this.alignment.element.id = element.id;
      this.alignment.element.type = element.type;
      this.alignment.direction = alignmentType as AlignmentType;
    },
    getPositionAndDimensionsOfElement(
      element: AnyOutputElement,
    ): {
      position: {
        top: number;
        right: number;
        bottom: number;
        left: number;
      };
      dimension: {
        height: number;
        width: number;
      };
    } {
      const top = parseFloat(element.props.y);
      const left = parseFloat(element.props.x);
      const height = parseFloat(element.props.h);
      const width = parseFloat(element.props.w);

      const right = left + width;
      const bottom = top + height;

      return {
        position: { top, right, bottom, left },
        dimension: { height, width },
      };
    },
    doAlignment({ type: targetType, id }): void {
      if (!this.$refs.activeElementEditor?.[0]) return;

      let sourceElement: AnyOutputElement;
      const sourceType = this.alignment.element.type;

      let targetElement: AnyOutputElement;

      if (sourceType === this.slideElementTypes.shape) {
        sourceElement = this.shapes.find(obj => obj.id === this.alignment.element.id);
      } else if (sourceType === this.slideElementTypes.text) {
        sourceElement = this.textItems.find(obj => obj.id === this.alignment.element.id);
      } else if (sourceType === this.slideElementTypes.image) {
        sourceElement = this.images.find(obj => obj.id === this.alignment.element.id);
      } else {
        return;
      }

      if (targetType === this.slideElementTypes.shape) {
        targetElement = this.shapes.find(obj => obj.id === id);
      } else if (targetType === this.slideElementTypes.text) {
        targetElement = this.textItems.find(obj => obj.id === id);
      } else if (targetType === this.slideElementTypes.image) {
        targetElement = this.images.find(obj => obj.id === id);
      } else {
        return;
      }

      if (!sourceElement || !targetElement || sourceElement.id === targetElement.id) return;

      const sourcePositionAndDimension = this.getPositionAndDimensionsOfElement(sourceElement);
      const targetPositionAndDimension = this.getPositionAndDimensionsOfElement(targetElement);
      const alignmentType: AlignmentType = this.alignment.direction;

      const isCenterVertical = /center-vertical/.test(alignmentType);
      const isCenterHorizontal = /center-horizontal/.test(alignmentType);

      const isTop = /top/.test(alignmentType);
      const isRight = /right/.test(alignmentType);
      const isBottom = /bottom/.test(alignmentType);
      const isLeft = /left/.test(alignmentType);
      const isOuter = /out/.test(alignmentType);

      const widthDiff = sourcePositionAndDimension.dimension.width - targetPositionAndDimension.dimension.width;
      const heightDiff = sourcePositionAndDimension.dimension.height - targetPositionAndDimension.dimension.height;

      let newTop = sourcePositionAndDimension.position.top;
      let newLeft = sourcePositionAndDimension.position.left;

      if (isCenterVertical) newTop = targetPositionAndDimension.position.top - heightDiff / 2;
      if (isCenterHorizontal) newLeft = targetPositionAndDimension.position.left - widthDiff / 2;

      if (isTop) newTop = targetPositionAndDimension.position.top;
      if (isBottom) newTop = targetPositionAndDimension.position.top - heightDiff;
      if (isLeft) newLeft = targetPositionAndDimension.position.left;
      if (isRight) newLeft = targetPositionAndDimension.position.left - widthDiff;

      if (isOuter) {
        if (isTop) newTop -= sourcePositionAndDimension.dimension.height;
        if (isBottom) newTop += sourcePositionAndDimension.dimension.height;
        if (isLeft) newLeft -= sourcePositionAndDimension.dimension.width;
        if (isRight) newLeft += sourcePositionAndDimension.dimension.width;
      }

      const newTopPercent = newTop + '%';
      const newLeftPercent = newLeft + '%';

      this.$refs.activeElementEditor[0].newTop = newTopPercent;
      this.$refs.activeElementEditor[0].newLeft = newLeftPercent;
      this.$refs.activeElementEditor[0].updateElementPosition(true);

      this.alignment.inProgress = false;
    },
    taggedText(charsArray): string {
      if (charsArray?.length) {
        const tagsArray = charsArray.map(c => {
          const value = c.char === ' ' ? `&nbsp;` : c.char;
          return `<span style="${c.style}">${value}</span>`;
        });
        return tagsArray.join('');
      }
      return '';
    },
    mapHeight({ height, percent }: { height?: number; percent?: string }): number {
      let value = height || this.slideHeight;
      if (percent) {
        const parsed = parseFloat(percent.replace('%', ''));
        value = (value * parsed) / 100;
      }
      return value;
    },
    getPercentValue(percent: string): string {
      const tempArray = percent.split('%');
      const [value] = tempArray;
      return value;
    },
    duplicateElement(elementId = this.contextMenu.element, type = this.contextMenu.elements): void {
      const slideElements = [...this.images, ...this.textItems, ...this.shapes];
      const shape = slideElements.find(s => s.id === elementId);
      const newShape = {
        id: `${type}-${Date.now()}`,
        ...(shape.htmlValue ? { htmlValue: shape.htmlValue } : {}),
        ...(shape.jsonValue ? { jsonValue: shape.jsonValue } : {}),
        ...(shape.value ? { value: shape.value } : {}),
        ...(shape.path ? { path: shape.path } : {}),
        props: {
          ...shape.props,
          x: `${Number(this.getPercentValue(shape.props?.x)) + Number(this.getPercentValue(shape.props?.w)) / 4}%`,
          y: `${Number(this.getPercentValue(shape.props?.y)) + Number(this.getPercentValue(shape.props?.h)) / 4}%`,
          index: this.elementInc + 1,
        },
      };
      this.$store.dispatch('output/addSlideElement', {
        type,
        slideId: this._id,
        value: newShape,
      });
      this.contextMenu.show = false;
    },
    removeActiveElementMaybe(e): void {
      if (!e.target.closest('.slide-element')) {
        if (this.activeElementId) {
          this.activeElementId = '';
          this.activeElementType = '';
        }
      }
    },
    unsetActiveElementType(): void {
      this.activeElementType = '';
    },
    setActiveElementInfo(type: string, id: string, elementProps?: OutputProps): void {
      if (this.alignment.inProgress) {
        this.doAlignment({ type, id });
        return;
      }

      if (!this.canEdit(id, type)) return;

      if (type === this.slideElementTypes.text && elementProps) {
        this.$store.dispatch('output/setCurrentTextProps', elementProps);
      }

      this.activeElementId = id;
      this.$nextTick(() => {
        this.activeElementType = type;
      });
    },
    moveElementOrder(direction: number): void {
      const slideElements = [...this.images, ...this.textItems, ...this.shapes].sort(
        (a, b) => parseInt(a?.props?.index, 10) - parseInt(b?.props?.index, 10),
      );
      const elementIndex = slideElements.findIndex(el => el.id === this.contextMenu.element);
      if (elementIndex !== -1) {
        const newIndex = elementIndex + direction;
        slideElements.splice(newIndex, 0, slideElements.splice(elementIndex, 1)[0]);
        const mapped = slideElements.map((el, i) => {
          return { ...el, props: { ...el.props, index: i } };
        });
        this.$store
          .dispatch('output/updateSlideElementsIndexes', {
            slideId: this._id,
            newArr: mapped,
          })
          .catch(err => {
            // eslint-disable-next-line no-console
            console.error('moveElementOrder - updateSlideElementsIndexes', err);
          });
      }
      this.contextMenu.show = false;
    },
    handleRightClick(e, elArr: string, elId: string): void {
      e.preventDefault();
      this.contextMenu = {
        show: false,
        elements: elArr,
        element: elId,
        positionX: e.clientX,
        positionY: e.clientY,
      };
      this.$nextTick(() => {
        this.contextMenu['show'] = true;
      });
    },
    deleteElement(elementId = this.contextMenu.element, type = this.contextMenu.elements): void {
      this.$store.dispatch('output/removeSlideElement', { type, slideId: this._id, elementId });
      this.contextMenu.show = false;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    baseElement(type, elementId: string): any {
      return this[type]?.find(el => el?.id === elementId) || null;
    },
    canEdit(elementId: string, type: string): boolean {
      const baseElement = this.baseElement(type, elementId);
      return !baseElement?.noEdit;
    },
    isActiveElement(elementId: string): boolean {
      return elementId === this.activeElementId;
    },
    setActiveElement(imageId: string): void {
      this.activeElementId = imageId;
    },
    addImage(imgObj: { img: string; imgHeight: number; imgWidth: number }): void {
      const { img, imgHeight, imgWidth } = imgObj;
      const newImageWidthHeight = (height: number, width: number): { h: string; w: string } => {
        const windowWidth = this.slideWidth;
        const windowHeight = this.slideHeight;
        let ratio: number, newHeightInt: number, newWidthInt: number;
        if (height > windowHeight && width > windowWidth) {
          newHeightInt = windowHeight * 0.6;
          ratio = newHeightInt / height;
          return { h: '60%', w: `${((width * ratio) / windowWidth) * 100}%` };
        } else if (height > windowHeight) {
          newHeightInt = windowHeight * 0.6;
          ratio = newHeightInt / height;
          return { h: '60%', w: `${((width * ratio) / windowWidth) * 100}%` };
        } else if (width > windowWidth) {
          newWidthInt = windowWidth * 0.6;
          ratio = newWidthInt / width;
          return { h: `${((height * ratio) / windowHeight) * 100}%`, w: '60%' };
        } else {
          newHeightInt = windowHeight * 0.6;
          ratio = newHeightInt / height;
          return { h: '60%', w: `${((width * ratio) / windowWidth) * 100}%` };
        }
      };
      const newImage = {
        id: `custom-img-${Date.now()}`,
        path: img,
        props: {
          w: newImageWidthHeight(imgHeight, imgWidth).w,
          h: newImageWidthHeight(imgHeight, imgWidth).h,
          x: '10%',
          y: '5%',
          index: this.elementInc + 1,
        },
      };
      this.$store.dispatch('output/addSlideElement', { type: this.slideElementTypes.image, slideId: this._id, value: newImage });
    },
    // basically just for displaying text properly when slide isn't fullscreen
    transformHelper(val: number): number {
      const widthPercent = this.width / window.innerWidth;
      return val * widthPercent;
    },
  },

  // TODO: check if this continues to function as desired when SlideBuilder can be used on all slides
  beforeDestroy(): void {
    this.activeElementId = '';
    this.activeElementType = '';
    document.removeEventListener('keydown', this.doShortcutsOnKeyDown);
    document.removeEventListener('keyup', this.doShortcutsOnKeyUp);
  },

  mounted() {
    document.addEventListener('keydown', this.doShortcutsOnKeyDown);
    document.addEventListener('keyup', this.doShortcutsOnKeyUp);
  },
});

import Vue from 'vue';
import { OutputSlide, OutputSnapshot, OutputTextProps } from '@/shared/legacy/classes';
import { MutationTree } from 'vuex';
import { Output, OutputState } from './types';
import { Container } from 'inversify';
import { LoggerContract } from '@/injectables';

export const mutations = (container: Container): MutationTree<OutputState> => {
  const logger = container.get<LoggerContract>('logger');

  return {
    SET_IS_SCROLLING_BY_CLICK(_state, _status): void {
      _state.isScrollingByClick = _status;
    },
    GET_LIBRARY_SLIDES_LOADING(_state, _status): void {
      _state.getLibrarySlidesLoading = _status;
    },
    SET_PRODUCT_LIBRARY_SLIDES_LOADING(_state, _status): void {
      _state.productSlidesLibraryLoading = _status;
    },
    SET_OUTPUT_LOADING(_state, _status): void {
      _state.outputLoading = _status;
    },
    SET_GET_OUTPUT_LOADING(_state, _status): void {
      _state.getOutputLoading = _status;
    },
    SET_UPDATE_LIBRARY_SLIDE_LOADING(_state, _status): void {
      _state.updateLibrarySlideLoading = _status;
    },
    SET_SLIDES_LOADED(_state, _status): void {
      Vue.set(_state.librarySlides, 'loaded', _status);
    },
    SET_USER_LIBRARY_SLIDES(_state, _slides): void {
      Vue.set(_state.librarySlides, 'user', _slides);
    },
    SET_PRODUCT_LIBRARY_SLIDES(_state, _slides): void {
      _state.productLibrarySlides = _slides;
    },
    SET_AGENCY_LIBRARY_SLIDES(_state, { slides }): void {
      Vue.set(_state.librarySlides, 'agency', slides);
    },
    SET_SHARED_LIBRARY_SLIDES(_state, slides): void {
      Vue.set(_state.librarySlides, 'shared', slides);
    },
    SET_ACTIVE_OUTPUT(_state, _output: Output): void {
      _state.activeOutput = _output;
    },
    SET_ACTIVE_OUTPUT_SLIDES(_state, _slides: OutputSlide[]): void {
      Vue.set(_state.activeOutput, 'slides', _slides);
    },
    SET_OUTPUT_LAYOUT(_state, _layout): void {
      Vue.set(_state.activeOutput, 'layout', _layout);
    },
    SET_OUTPUT_BLANKS(_state, _blanks): void {
      Vue.set(_state.activeOutput, 'blanks', _blanks);
    },
    SET_OUTPUT_AGENCY(_state, _agency): void {
      Vue.set(_state.activeOutput, 'agency', _agency);
    },
    SET_OUTPUT_COLORS(_state, _colors): void {
      Vue.set(_state.activeOutput, 'slideColors', _colors);
    },
    SET_OUTPUT_ID(_state, _outputId): void {
      Vue.set(_state.activeOutput, 'PropertyId', _outputId);
    },
    SET_AVAILABLE_DYNAMICS(_state, _dynamics): void {
      const dynamicMenu = [
        {
          label: 'Dynamic',
          icon: 'browser_updated',
          menu: _dynamics,
        },
      ];
      Vue.set(_state, 'availableDynamics', dynamicMenu);
    },
    UPDATE_SLIDE(_state, _slide): void {
      const slideIndex = _state.outputSlides.findIndex(slide => slide.name === _slide.name);
      if (slideIndex !== -1) {
        Vue.set(_state.outputSlides, slideIndex, {
          ..._slide,
          userModified: true,
        });
      } else {
        logger.print('error', 'store.output.mutations.UPDATE_SLIDE', 'Unable to set updated slide');
      }
    },
    SET_LOCAL_OUTPUT(_state, _slides: OutputSlide[]): void {
      Vue.set(_state, 'outputSlides', _slides);
    },
    SET_ACTIVE_SLIDE(_state, _id: string): void {
      _state.activeSlideId = _id;
    },
    SET_ACTIVE_ELEMENT_TYPE(_state, _type: string): void {
      _state.activeElementType = _type;
    },
    SET_ACTIVE_ELEMENT_ID(_state, _id: string): void {
      _state.activeElementId = _id;
    },
    SET_OUTPUT_HAS_BEEN_CHANGED(_state, _status): void {
      _state.outputHasBeenChanged = _status;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    UPDATE_SLIDE_ELEMENTS(_state, _payload: { index: number; type: string; array: any[] }): void {
      const slideToUpdate = _state.outputSlides[_payload.index];
      Vue.set(slideToUpdate, _payload.type, _payload.array);
      if (slideToUpdate?.defaultSlide) {
        Vue.set(_state.outputSlides[_payload.index], 'userModified', true);
      }
    },
    TOGGLE_IMAGE_UPLOAD(_state, _status: boolean): void {
      _state.openImageUpload = _status;
    },
    SET_ACTION_HISTORY(_state, _history: OutputSnapshot[]): void {
      _state.actionHistory = _history;
    },
    SET_ACTION_HISTORY_INDEX(_state, _newIndex: number): void {
      _state.actionHistoryIndex = _newIndex;
    },
    SET_AVAILABLE_PROPOSAL_LAYOUTS(_state, _layouts): void {
      _state.availableLayouts = _layouts;
    },
    SET_GET_LAYOUTS_LOADING(_state, _status): void {
      _state.getLayoutsLoading = _status;
    },
    SET_CURRENT_TEXT_PROPS(_state, _props: OutputTextProps): void {
      _state.currentTextProps = _props;
    },
    SET_LOCAL_LAYOUT_COLOR(_state, { key, value }): void {
      _state.localLayoutColors[key] = value;
    },
    SET_LOCAL_LAYOUT_COLORS(_state, _colors): void {
      _state.localLayoutColors = { ..._colors };
    },
    SET_LAYOUT_COLORS(_state, _colors): void {
      _state.layoutColors = { ..._colors };
    },
    SAVE_LAYOUT_COLORS(_state, _status): void {
      _state.saveLayoutColorsLoading = _status;
    },
  };
};


import { DarkPeriod, DarkPeriodDataSingle } from '@/shared/types';
import { PropType } from 'vue';
import { Models } from '@/injectables/tokens';
import { FlightModelContract } from '@/injectables';

export default {
  name: 'blackout-period-edit-dialog',

  useInjectable: [Models.Flight],

  props: {
    open: {
      type: Boolean,
      required: true,
    },
    periodList: {
      type: Array as PropType<DarkPeriod[]>,
      required: true,
    },
    period: {
      type: Object as PropType<DarkPeriod | null>,
    },
    periodData: {
      type: Object as PropType<DarkPeriodDataSingle | null>,
    },
    min: {
      type: String,
      required: true,
    },
    max: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      localModel: ['', ''],
    };
  },
  watch: {
    period(newPeriod) {
      if (newPeriod && newPeriod.startDate && newPeriod.endDate) {
        this.localModel = [newPeriod.startDate, newPeriod.endDate];
      } else {
        this.localModel = ['', ''];
      }
    },
  },
  computed: {
    darkPeriodHasIntersection(): boolean {
      if (!this.period || !this.periodList || !this.localModel) return false;

      return (this.flightEntity as FlightModelContract).datesIntersectPeriods(
        this.period.index,
        this.periodList,
        this.localModel,
      );
    },
    darkPeriodIncomplete(): boolean {
      return !this.localModel[0] || !this.localModel[1];
    },
    darkPeriodCanSave(): boolean {
      return !this.darkPeriodHasIntersection && !this.darkPeriodIncomplete;
    },
  },
  methods: {
    getAllowedDatesFor(blackout: DarkPeriod): (date: string) => boolean {
      return (date: string) => {
        for (const loopingBlackout of this.periodList) {
          if (loopingBlackout.index === blackout.index) continue;
          if (date >= loopingBlackout.startDate && date <= loopingBlackout.endDate) {
            return false;
          }
        }
        return true;
      };
    },
    cancelPeriod() {
      this.$emit('update:open', false);
      this.localModel = ['', ''];
      this.$emit('cancelPeriod');
    },
    confirmPeriod() {
      if (!this.darkPeriodCanSave) return;
      this.$emit('update:open', false);
      this.localModel = ['', ''];
      this.$emit('confirmPeriod');
    },
    updateActivePeriod(dates: [string, string]) {
      dates.sort();
      this.localModel = dates;
      this.$emit('updatePeriod', dates);
    },
  },
};

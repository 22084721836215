
import Vue, { PropType } from 'vue';
import { OutputSlide } from '@/shared/legacy/classes';

import SlideActions from './slide-actions.vue';
import EditableText from '@/shared/ui/components/editable-text.vue';

export default Vue.extend({
  name: 'SideLibraryListItem',

  components: {
    SlideActions,
    EditableText,
  },

  props: {
    slideRenamingEnabled: {
      type: Boolean,
      default: false,
    },
    slide: {
      type: Object as PropType<OutputSlide>,
      required: true,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    selectionAvailable: {
      type: Boolean,
      default: false,
    },
    actionsAvailable: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isSlidesEditable: {
      type: Boolean,
      default: false,
    },
    dynamicColored: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    slideWidth(): number {
      return 238;
    },
    slideClasses() {
      return {
        'selected-slide': this.selected,
        'hover-effect': this.selectionAvailable,
      };
    },
    slideName(): string {
      return this.slide?.productSlideLabel || this.slide?.label || this.slide?.name;
    },
    slideNameClasses(): string {
      return `${this.selected ? 'primary--text' : ''} px-5 py-2 body-1 slide-container__title`;
    },
  },
});

import { LoggerContract } from '@/injectables';
import { Proposal } from '@/shared/types';
import { GetterTree } from 'vuex';
import { RootState } from '../root/state';
import { ProposalState } from './state';

interface GettersInjection {
  logger: LoggerContract;
}

export const getters = ({ logger }: GettersInjection): GetterTree<ProposalState, RootState> => ({
  activeProposal: (state): Proposal => {
    try {
      if (!state.activeProposalId) {
        return null;
      }
      const proposalInList = state.proposals.list.find(c => c.id && c.id === state.activeProposalId);
      if (state.activeProposal) {
        return state.activeProposal;
      } else {
        return proposalInList;
      }
    } catch (err) {
      logger.print('error', 'store.proposal.getters.activeProposal', err);
      throw err;
    }
  },
  tomorrowDate: (): string => {
    const current = new Date();
    current.setDate(current.getDate() + 1);
    return current.toISOString().slice(0, 10);
  },
  currentDate: (): string => {
    const current = new Date();
    return current.toISOString().slice(0, 10);
  },
});

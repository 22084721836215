
import Vue from 'vue';

import BasicPageListElement from '@/entities/help-section/ui/BasicPageListElement.vue';

export default Vue.extend({
  name: 'DropDownPageListElement',

  components: {
    BasicPageListElement,
  },

  props: {
    page: {
      required: true,
      type: Object,
    },
    selected: {
      type: Object,
      required: true,
    },
    actionsAllowed: {
      type: Boolean,
      default: false,
    },
    disabledActions: {
      type: Boolean,
      default: false,
    },
  },

  data: (): {
    showSubGroup: boolean;
  } => ({
    showSubGroup: false,
  }),

  computed: {
    subPages() {
      return this.page.PageList ?? [];
    },
  },

  methods: {
    switchShowSubGroup() {
      this.showSubGroup = !this.showSubGroup;
    },
  },
});

import Vue from 'vue';
import VueRouter, { NavigationGuard, RouteConfig } from 'vue-router';
import { Store } from 'vuex';

import { UnsafeAny } from '@/shared/types';
import { TrackerContract, LoggerContract } from '@/injectables';

import { Routes } from './routes';
import { meta } from './meta';
import { afterEachRoute } from './guards/after';

Vue.use(VueRouter);

const routes = (store): RouteConfig[] => [
  {
    path: '/',
    name: Routes.Home,
    component: () => import('@/pages/home'),
    meta: meta({
      title: 'Media Planner',
    }),
  },
  {
    path: '/signin',
    name: Routes.Signin,
    component: () => import('@/pages/signin'),
    meta: meta({
      skipAuth: true,
      title: 'Signin',
    }),
  },
  {
    path: '/error',
    alias: '*', // redirect miss matched pages to error page
    name: Routes.Error,
    component: () => import('@/pages/error'),
    meta: meta({
      title: 'Error',
    }),
  },
  {
    path: '/login',
    name: Routes.Login,
    component: () => import('@/pages/login'),
    meta: meta({
      skipAuth: true,
      title: 'Login',
    }),
  },
  {
    path: '/reset-password',
    name: Routes.ResetPassword,
    component: () => import('@/pages/reset-password'),
    meta: meta({
      skipAuth: true,
      title: 'Reset Password',
    }),
  },
  {
    path: '/ahlogin',
    name: Routes.AHLogin,
    component: () => import('@/pages/admin-hierarchy'),
    meta: meta({
      skipAuth: true,
      title: 'AH Login',
    }),
  },
  {
    path: '/clients',
    name: Routes.Clients,
    component: () => import('@/pages/clients'),
    meta: meta({
      title: 'Clients',
    }),
  },
  {
    path: '/myClients',
    name: Routes.MyClients,
    component: () => import('@/pages/clients'),
    props: {
      onlyMine: true,
    },
    meta: meta({
      title: 'My Clients',
      fullscreen: false,
    }),
  },
  {
    path: '/proposals',
    name: Routes.Proposals,
    component: () => import('@/pages/proposals'),
    meta: meta({
      title: 'Proposals',
    }),
  },
  {
    path: '/instant-campaigns',
    name: Routes.InstantCampaigns,
    component: () => import('@/pages/instant-io/instant-io-list.vue'),
    meta: meta({
      title: 'Instant Campaigns',
    }),
    beforeEnter: (to, from, next) => {
      if (store.state.agency?.currentAgencyInfo?.instantIOEnabled || store.getters['auth/isAgencyAdmin']) return next();
      return next({ name: Routes.Home });
    },
  },
  {
    path: '/avails-calculator',
    name: Routes.AvailsCalculator,
    component: () => import('@/pages/avails-calculator'),
    meta: meta({
      title: 'Avails Calculator',
    }),
  },
  {
    path: '/keyword-planner',
    name: Routes.KeywordPlanner,
    component: () => import('@/pages/keyword-planner'),
    meta: meta({
      title: 'Keyword Planner',
    }),
  },
  {
    path: '/manage-layouts',
    name: Routes.ManageLayouts,
    component: () => import('@/pages/manage-layouts'),
    meta: meta({
      title: 'Manage Layouts',
      backRoute: Routes.Home,
    }),
  },
  {
    path: '/manage-library-slide/:agency/:slideId',
    name: Routes.ManageLibrarySlide,
    component: () => import('@/pages/library-slide-output'),
    meta: meta({
      title: 'Manage Library Slide',
      backRoute: Routes.Home,
    }),
  },
  {
    path: '/create-library-slide/:agency',
    name: Routes.CreateLibrarySlide,
    component: () => import('@/pages/create-library-slide-output'),
    meta: meta({
      title: 'Create Library Slide',
      backRoute: Routes.Home,
    }),
  },
  {
    path: '/create-template-slide/:agency',
    name: Routes.CreateTemplateSlide,
    component: () => import('@/pages/create-blank-slide'),
    meta: meta({
      title: 'Create Template Slide',
      backRoute: Routes.Home,
    }),
  },
  {
    path: '/manage-template-slide/:agency/:slideId',
    name: Routes.ManageTemplateSlide,
    component: () => import('@/pages/manage-blank-slide'),
    meta: meta({
      title: 'Manage Template Slide',
      backRoute: Routes.Home,
    }),
  },
  {
    path: '/manage-product-slides/:agency/:productId',
    name: Routes.ManageProductSlides,
    component: () => import('@/pages/manage-product-slides'),
    meta: meta({
      title: 'Manage Product Slides',
      backRoute: Routes.Products,
    }),
  },
  {
    path: '/manage-package-slides/:agency/:packageConfigId',
    name: Routes.ManagePackageSlides,
    component: () => import('@/pages/manage-package-slides'),
    meta: meta({
      title: 'Manage Package Slides',
      backRoute: Routes.Products,
    }),
  },
  {
    path: '/proposal/:id',
    name: Routes.Proposal,
    component: () => import('@/pages/proposal'),
    meta: meta({
      title: 'Proposal',
    }),
    children: [
      {
        path: ':proposalId?/market',
        component: () => import('@/pages/proposal/market.vue'),
        name: Routes.ProposalMarket,
        meta: meta({
          title: 'Proposal Market',
          backRoute: Routes.Clients,
        }),
      },
      {
        path: ':proposalId/solutions',
        component: () => import('@/pages/proposal/solutions.vue'),
        name: Routes.ProposalSolutions,
        meta: meta({
          title: 'Proposal Solutions',
          backRoute: Routes.Clients,
        }),
      },
      {
        path: ':proposalId/summary',
        name: Routes.ProposalSummary,
        component: () => import('@/pages/proposal/summary.vue'),
        meta: meta({
          title: 'Proposal Summary',
          backRoute: Routes.Clients,
        }),
      },
      {
        path: ':proposalId/finalize',
        name: Routes.ProposalFinalize,
        component: () => import('@/pages/proposal/finalize.vue'),
        meta: meta({
          title: 'Proposal Finalize',
          backRoute: Routes.Clients,
        }),
      },
    ],
  },
  {
    path: '/client/:id',
    name: Routes.Client,
    component: () => import('@/pages/client'),
    meta: meta({
      title: 'Client',
    }),
    children: [
      {
        path: 'summary',
        name: Routes.ClientSummary,
        component: () => import('@/pages/client/summary.vue'),
        meta: meta({
          title: 'Client Summary',
          backRoute: Routes.Clients,
        }),
      },
      {
        path: 'info',
        name: Routes.ClientInfo,
        component: () => import('@/pages/client/info.vue'),
        meta: meta({
          title: 'Client Information',
          backRoute: Routes.Clients,
        }),
      },
      {
        path: 'proposals/:proposalId/output',
        name: Routes.ClientProposalOutput,
        component: () => import('@/pages/output'),
        meta: meta({
          title: 'Proposal Output',
          backRoute: Routes.ClientProposals,
        }),
      },
    ],
  },
  {
    path: '/products',
    name: Routes.Products,
    component: () => import('@/pages/products'),
    meta: meta({
      title: 'Products',
    }),
  },
  {
    path: '/view/:token',
    name: Routes.SharedOutput,
    component: () => import('@/pages/shared-output'),
    meta: meta({
      title: 'Proposal',
      skipAuth: true,
      fullscreen: true,
    }),
  },
  {
    path: '/export/:token',
    name: Routes.ExportOutput,
    component: () => import('@/pages/shared-output'),
    meta: meta({
      title: 'Proposal Export',
      skipAuth: true,
      fullscreen: true,
      exporting: true,
    }),
  },
  {
    path: '/export-ott/:token',
    name: Routes.SharedOtt,
    component: () => import('@/pages/shared-ott'),
    meta: meta({
      title: 'Export OTT',
      skipAuth: true,
      fullscreen: true,
    }),
  },
  {
    path: '/files/getContract/:token',
    name: Routes.GetContract,
    component: () => import('@/pages/get-contract'),
    meta: meta({
      title: 'Download Proposal Contract',
      skipAuth: true,
    }),
  },
  {
    path: '/s/:shortId',
    name: Routes.ShortOutput,
    component: () => import('@/pages/shared-output'),
    meta: meta({
      title: 'Proposal',
      skipAuth: true,
      fullscreen: true,
    }),
  },
  {
    path: '/campaign/:shortId',
    name: Routes.Campaign,
    component: () => import('@/pages/campaign'),
    meta: meta({
      title: 'Campaign',
      backRoute: Routes.Home,
    }),
  },
  {
    path: '/agency-dashboard',
    name: Routes.AgencyDashboard,
    component: () => import('@/pages/agency'),
    meta: meta({
      title: 'Campaign',
      backRoute: Routes.Home,
    }),
    beforeEnter: (to, from, next) => {
      if (store.getters['auth/isAdmin']) return next();
      return next({ name: Routes.Home });
    },
  },
];

// TODO: remove binding with store
// try to separate guards within related components
// to follow Information Expert pattern.
export const createRouter = <S extends Store<UnsafeAny>>(
  store: S,
  tracker: TrackerContract,
  logger: LoggerContract,
) => {
  const router = new VueRouter({
    mode: 'history',
    routes: routes(store),
    scrollBehavior: () => ({ x: 0, y: 0 }),
  });

  afterEachRoute(router);

  const afterAuth: NavigationGuard = (to, from, next) => {
    if (from.params.id && from.params.id !== to.params.id) {
      store
        .dispatch('client/resetActiveClient')
        .then(() => next())
        .catch(err => {
          logger.print('warning', 'router/resetActiveClient', err);
          // next(/) redirect?
          next();
        });
      return;
    }
    if (from.params.proposalId && from.params.proposalId !== to.params.proposalId) {
      store
        .dispatch('proposal/updateActiveProposalId', { proposalId: null })
        .then(() => {
          if (to.name === 'clientProposals' || to.name === 'clientSummary' || to.name === 'clientInfo') {
            store
              .dispatch('client/updateActiveClientId', to.params.id)
              .then(() => store.dispatch('proposal/getProposalsByActiveClient', { force: false }));
          }
        })
        .then(() => next())
        .catch(err => {
          logger.print('warning', 'router/updateActiveProposalId.null', err);
          // redirect?
          next();
        });
      return;
    }
    if (to.name === 'clientInfo' || to.name === 'clientSummary') {
      store
        .dispatch('client/updateActiveClientId', to.params.id)
        .then(() => next())
        .catch(err => {
          logger.print('error', 'router/toClientInfo', err);
          // redirect?
          next();
        });
    } else {
      next();
    }
  };

  router.beforeEach(async (to, from, next) => {
    const skip = to.meta?.skipAuth ?? false;
    if (skip) return next();
    if (store.state.auth.user || store.state.auth.authChecked) return next();
    const nextUrl = to.query.adfslogout ? '/' : to.fullPath;
    const signinRoute =
      nextUrl === '/' ? { name: 'login', replace: true } : { name: 'login', query: { nextUrl }, replace: true };
    const user = await store.dispatch('auth/checkIfAuth');
    if (!user) return next(signinRoute);

    afterAuth(to, from, next);
  });

  return router;
};

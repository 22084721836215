import clonedeep from 'lodash.clonedeep';
import { ActionTree } from 'vuex';
import { ClientState } from './state';
import { GeoObj, ClientInsights, ClientProspect, DefaultBuzzboardResponse, AgencyListItem } from '@/shared/types';

import { UpdateClientNotesMutationVariables } from '@/injectables/services/client/graphql/mutations/update-client-notes.generated';

import { Container } from 'inversify';

import { AnyObject, Client, NewClient } from '@/shared/types';
import { Services } from '@/injectables/tokens';
import { LoggerContract, ClientServiceContract } from '@/injectables';
import { RootState } from '../root/state';
import { MediaplannerLocationInput, Scalars } from '@/app/graphql';

export const actions = (container: Container): ActionTree<ClientState, RootState> => {
  const logger = container.get<LoggerContract>('logger');
  const clientService = container.get<ClientServiceContract>(Services.Client);

  return {
    async saveClientNotes({ commit, state }, notesUpdateObj: UpdateClientNotesMutationVariables): Promise<string> {
      if (!notesUpdateObj?.clientId) {
        logger.print('error', 'store.client.saveClientNotes - clientId not provided');
        return null;
      }

      const { isErr, unwrapErr, unwrap } = await clientService.updateNotes({ ...notesUpdateObj });

      if (isErr()) {
        const { message } = unwrapErr();
        commit('SET_ACTIVE_CLIENT_NOTES', state.activeClient.notes);
        logger.print('error', 'saveClientNotes', message);
        return null;
      }
      const savedNotes = unwrap().notes;

      commit('SET_ACTIVE_CLIENT_NOTES', savedNotes);

      return savedNotes;
    },

    async updateClientLogo(
      { commit },
      { clientLogo, clientId }: { clientLogo: Scalars['URL']; clientId: string },
    ): Promise<void> {
      commit('SET_UPDATE_LOGO_LOADING', true);

      const { unwrap, isErr, unwrapErr, isOk } = await clientService.updateClientLogo(clientId, clientLogo);
      if (isErr()) {
        const { message } = unwrapErr();
        logger.print('error', 'store.clients.updateClientLogo', message);
      }
      if (isOk()) {
        commit('SET_CLIENT_LOGO', { clientLogo: unwrap(), clientId });
        commit('SET_CLIENTS_NOT_LOADED', true);
      }
      commit('SET_UPDATE_LOGO_LOADING', false);
    },

    async updateClientLocations(
      { commit },
      { locations, clientId }: { locations: MediaplannerLocationInput[]; clientId: string },
    ): Promise<Boolean> {
      commit('SET_UPDATE_CLIENT_LOADING', true);
      const { isErr, unwrapErr, unwrap } = await clientService.updateClientLocations(clientId, locations);
      if (isErr()) {
        const { message } = unwrapErr();
        logger.print('error', 'client.updateClientLocations', message);
        commit('SET_UPDATE_CLIENT_LOADING', false);
        return null;
      }
      const client = unwrap();
      commit('SET_UPDATE_CLIENT_LOADING', false);
      commit('SET_ACTIVE_CLIENT_LOCATIONS', client.address);
      return true;
    },

    async updateClientGeoSelections(
      { commit },
      { geoSelections, clientId }: { geoSelections: AnyObject; clientId: string },
    ): Promise<boolean> {
      commit('SET_UPDATE_CLIENT_LOADING', true);
      const { isErr, unwrapErr, unwrap } = await clientService.updateClientGeoSelections(clientId, geoSelections);
      if (isErr()) {
        const { message } = unwrapErr();
        logger.print('error', 'client.updateClientGeoSelections', message);
        commit('SET_UPDATE_CLIENT_LOADING', false);
        return null;
      }
      const client = unwrap();
      commit('SET_UPDATE_CLIENT_LOADING', false);
      commit('SET_ACTIVE_CLIENT_GEO_SELECTIONS', client.geoSelections);
      return true;
    },

    async removeClientGeoSelections({ commit }, clientId: string): Promise<boolean> {
      commit('SET_UPDATE_CLIENT_LOADING', true);
      const { isErr, unwrapErr, unwrap } = await clientService.removeClientGeoSelections(clientId);
      if (isErr()) {
        const { message } = unwrapErr();
        logger.print('error', 'client.updateClientGeoSelections', message);
        commit('SET_UPDATE_CLIENT_LOADING', false);
        return null;
      }
      const client = unwrap();
      commit('SET_UPDATE_CLIENT_LOADING', false);
      commit('SET_ACTIVE_CLIENT_GEO_SELECTIONS', client.geoSelections);
      return true;
    },

    async removeClientLogo({ commit, rootState }, clientId: string): Promise<boolean> {
      const agencyId = rootState.activeAgency.PropertyId || rootState.auth.user.Agency;
      commit('SET_UPDATE_LOGO_LOADING', true);
      const { isErr, unwrapErr } = await clientService.removeClientLogo(clientId, agencyId);
      commit('SET_UPDATE_LOGO_LOADING', false);
      if (isErr()) {
        const { message } = unwrapErr();
        logger.print('error', 'store.clients.removeClientLogo', message);
        return false;
      }
      commit('SET_CLIENT_LOGO', { clientLogo: null, clientId });
      commit('SET_CLIENTS_NOT_LOADED', true);
      return true;
    },

    setActiveClientGeoSelections(
      { commit, state, rootGetters },
      { target, targetArray }: { target: string; targetArray: GeoObj[] },
    ): void {
      const geoSelections = state.activeClient.geoSelections
        ? clonedeep(state.activeClient.geoSelections)
        : {
            cityList: [],
            zipList: [],
            dmaList: [],
            stateList: [],
            countyList: [],
          };

      try {
        geoSelections[target] = rootGetters['output/forceArray'](targetArray);
        commit('SET_ACTIVE_CLIENT_GEO_SELECTIONS', geoSelections);
      } catch (err) {
        logger.print('error', 'store.client.setActiveClientGeoSelections', err);
        throw err;
      }
    },

    resetNewClient({ commit }): void {
      commit('RESET_NEW_CLIENT');
    },

    async updateActiveClientId({ commit, state }, id: string | null): Promise<void> {
      if (state.activeClientId && state.activeClientId === id) {
        return;
      }
      commit('SET_ACTIVE_CLIENT_ID', id);
    },

    resetActiveClient({ dispatch }): void {
      dispatch('updateActiveClientId', null).catch(err => {
        logger.print('error', 'store.client.resetActiveClient');
        throw err;
      });
    },

    async getClientById({ commit }, clientId): Promise<void> {
      commit('LOADING_CLIENT', true);
      commit('SET_ACTIVE_CLIENT', null);

      const { unwrap, isErr } = await clientService.getById(clientId);
      commit('LOADING_CLIENT', false);

      if (isErr()) {
        logger.print('error', 'store.client.updateClientInfo - received incorrect payload');
        return;
      }

      commit('SET_ACTIVE_CLIENT', unwrap());
    },

    async updateClientInfo({ commit, state }, clientInfo): Promise<Client | null> {
      if (!state?.activeClient?.id) {
        logger.print('error', 'store.client.updateClientInfo - activeClientId is not set');
        return null;
      }

      commit('SET_UPDATE_CLIENT_LOADING', true);
      const { isErr, unwrapErr, unwrap } = await clientService.updateInfo(clientInfo, state.activeClient.id);
      if (isErr()) {
        const { message } = unwrapErr();
        logger.print('error', 'updateClientInfo', message);
        commit('SET_UPDATE_CLIENT_LOADING', false);
        return clientInfo;
      }

      const updatedClient = unwrap();
      commit('SET_ACTIVE_CLIENT_INFO', updatedClient);
      commit('SET_UPDATE_CLIENT_LOADING', false);
    },
    updateNewClientDetails({ state, commit }, newClient: NewClient): NewClient {
      try {
        if (!newClient || typeof newClient !== 'object') {
          throw new Error('Incorrect data received for newClient');
        }
        commit('SET_NEW_CLIENT_LOADING', true);
        commit('SET_NEW_CLIENT', newClient);
        return state.newClient;
      } catch (err) {
        logger.print(
          'error',
          'clients.actions.updateNewClientDetails',
          'Something went wrong. Unable to update details for new client',
        );
        throw err;
      } finally {
        commit('SET_NEW_CLIENT_LOADING', false);
      }
    },

    async submitNewClient({ state, commit, dispatch }, { skipModal = false }): Promise<{ id?: string; name?: string }> {
      commit('SET_NEW_CLIENT_LOADING', true);
      const { isErr, unwrapErr, unwrap } = await clientService.submitNewClient(state.newClient);
      if (isErr()) {
        const { message } = unwrapErr();
        logger.print('error', 'client/submitNewClient', message);
        commit('SET_NEW_CLIENT_LOADING', false);
        return {};
      }
      const clientData = unwrap();

      if (!skipModal) commit('SET_ACTIVE_CLIENT_ID', clientData.id);

      dispatch('showSnackbar', { content: 'Successfully created new client!', color: 'success' }, { root: true });
      dispatch('resetNewClient');
      commit('SET_NEW_CLIENT_LOADING', false);
      return clientData;
    },

    async createClient(
      { commit, dispatch },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      { newClient }: { newClient: any },
    ): Promise<Client> {
      try {
        if (!newClient || typeof newClient !== 'object') {
          throw new Error('Incorrect data received for newClient');
        }

        const updatedRecord = newClient;
        const clientToCreate = {
          ...updatedRecord,
          ...(updatedRecord.BIZNM
            ? { name: updatedRecord.BIZNM }
            : updatedRecord.name
            ? { name: updatedRecord.name }
            : { name: null }),
          ...(updatedRecord.DSCAT ? { category: updatedRecord.DSCAT } : { category: '' }),
          ...(updatedRecord.WURL ? { WURL: updatedRecord.WURL } : { WURL: '' }),
          ...(updatedRecord.Phone ? { Phone: updatedRecord.Phone } : { Phone: '' }),
          dupChecked: false,
          locations: [
            // TODO: check for missing information and DON'T create primary if incomplete
            {
              label: 'primary',
              ...(updatedRecord.ADD1 && updatedRecord.CITY && updatedRecord.STAT && updatedRecord.ZIP
                ? {
                    address: `${updatedRecord.ADD1}, ${updatedRecord.CITY}, ${updatedRecord.STAT} ${newClient.ZIP}, United States`,
                  }
                : { address: '' }),
              ...(updatedRecord.LAT ? { lat: parseFloat(updatedRecord.LAT) } : { lat: null }),
              ...(updatedRecord.LONG ? { lon: parseFloat(updatedRecord.LONG) } : { lon: null }),
              ...(updatedRecord.CITY ? { city: updatedRecord.CITY } : { city: '' }),
              ...(updatedRecord.ZIP ? { zip: updatedRecord.ZIP } : { zip: '' }),
              ...(updatedRecord.STAT ? { state: updatedRecord.STAT } : { state: '' }),
            },
          ],
        };
        if (!updatedRecord.ADD1 && !updatedRecord.CITY) {
          clientToCreate.locations = [];
        }

        commit('SET_NEW_CLIENT_LOADING', true);
        commit('SET_NEW_CLIENT', clientToCreate);
        // handle case where no selection worked
        dispatch('showSnackbar', { content: 'Business entry validated', color: 'success' }, { root: true });
        return clientToCreate;
      } catch (err) {
        logger.print('error', 'client.actions.createClient', 'Something went wrong. Unable to create new client');
        throw err;
      } finally {
        commit('SET_NEW_CLIENT_LOADING', false);
      }
    },

    async getAgencies({ state, commit, rootGetters }): Promise<AgencyListItem[]> {
      try {
        if (!rootGetters['auth/isAdmin']) {
          return [];
        }
        if (state.allAgencies.loaded && state.allAgencies?.list?.length) {
          return state.allAgencies.list;
        }
        commit('LOADING_AGENCIES', true);
        const { isOk, unwrap, unwrapErr } = await clientService.getAgencies();
        if (isOk()) {
          const data = unwrap();
          commit('SET_ALL_AGENCIES', data);
          return data;
        }
        const { message } = unwrapErr();
        logger.print('info', 'unexpected data in getAgencies', message);
        return [];
      } catch (unexpectedError) {
        throw unexpectedError;
      } finally {
        commit('LOADING_AGENCIES', false);
      }
    },

    async getCategories({ commit, state }): Promise<void> {
      try {
        if (state.categories.loaded) {
          return;
        }
        commit('LOADING_CATEGORIES', true);

        const { unwrap, isErr, unwrapErr, isOk } = await clientService.getAllCategories();

        if (isOk()) {
          const data = unwrap();
          commit('SET_CATEGORIES', Array.isArray(data) ? data : []);
        }
        if (isErr()) {
          commit('SET_CATEGORIES', []);
          logger.print('info', 'unexpected data in getCategories', unwrapErr().message);
        }
        return;
      } catch (unexpectedError) {
        throw unexpectedError;
      } finally {
        commit('LOADING_CATEGORIES', false);
      }
    },

    async getPurchaseHistory({ commit }, params): Promise<void> {
      commit('SET_PURCHASE_HISTORY', []);
      try {
        const { unwrap, isOk } = await clientService.getPurchaseHistory(params.id);

        if (isOk()) {
          const data = unwrap();
          commit('SET_PURCHASE_HISTORY', data.ChartList);
        }
      } catch (e) {
        logger.print('error', 'error in getPurchaseHistory', e);
      }
    },

    setLoadingClients({ commit }): void {
      commit('LOADING_CLIENTS', true);
    },

    async buzzboardGetProspect(
      { commit },
      {
        clientPropertyId,
      }: {
        clientPropertyId: string;
      },
    ): Promise<ClientProspect | null> {
      if (!clientPropertyId) return null;
      commit('SET_ACTIVE_CLIENT_PROSPECT_LOADING', true);
      const { isErr, unwrapErr, unwrap } = await clientService.getProspect(clientPropertyId);
      if (isErr()) {
        const { message } = unwrapErr();
        logger.print('error', 'client/buzzboardGetProspect', message);
        commit('SET_ACTIVE_CLIENT_PROSPECT_LOADING', false);
        commit('SET_ACTIVE_CLIENT_PROSPECT', null);
        return null;
      }
      const data = unwrap();
      commit('SET_ACTIVE_CLIENT_PROSPECT', data);
      commit('SET_ACTIVE_CLIENT_PROSPECT_LOADING', false);
      return data;
    },

    async buzzboardGetInsights(
      { commit },
      {
        clientPropertyId,
      }: {
        clientPropertyId: string;
      },
    ): Promise<ClientInsights | null> {
      if (!clientPropertyId) return null;
      commit('SET_ACTIVE_CLIENT_INSIGHTS_LOADING', true);
      const { isErr, unwrapErr, unwrap } = await clientService.getInsights(clientPropertyId);
      if (isErr()) {
        const { message } = unwrapErr();
        logger.print('error', 'client/buzzboardGetInsights', message);
        commit('SET_ACTIVE_CLIENT_INSIGHTS_LOADING', false);
        commit('SET_ACTIVE_CLIENT_INSIGHTS', null);
        return null;
      }
      const data = unwrap();
      commit('SET_ACTIVE_CLIENT_INSIGHTS', data);
      commit('SET_ACTIVE_CLIENT_INSIGHTS_LOADING', false);
      return data;
    },

    async buzzboardRefreshProspect(
      { commit, dispatch },
      {
        clientPropertyId,
        skipSnackbar = false,
      }: {
        clientPropertyId: string;
        skipSnackbar: boolean;
      },
    ): Promise<DefaultBuzzboardResponse> {
      if (!clientPropertyId) return null;
      commit('SET_ACTIVE_CLIENT_PROSPECT_REFRESHING', true);
      const { isErr, unwrapErr, unwrap } = await clientService.refreshProspect(clientPropertyId);
      if (isErr()) {
        const { message } = unwrapErr();
        logger.print('error', 'client/buzzboardGetProspect', message);

        if (!skipSnackbar) {
          dispatch('showSnackbar', { content: message, color: 'error' }, { root: true });
        }

        commit('SET_ACTIVE_CLIENT_PROSPECT_REFRESHING', false);
        return null;
      }
      commit('SET_ACTIVE_CLIENT_PROSPECT_REFRESHING', false);
      return unwrap();
    },
  };
};

import Vue, { VNode } from 'vue';
import VueDOMPurifyHTML from 'vue-dompurify-html';
import vuetify from '@/app/plugins/vuetify';
import '@/registerServiceWorker';
import C360, { getTheme, Product } from '@c360/ui';

import { createContainer as createGraphqlContainer } from '@/app/container';

// infrastructure entities
import { createAxios, setAxiosCallbacks } from '@/app/axios';
import { client as apolloClient, setGraphqlCallbacks } from '@/app/graphql';
import { createRouter } from '@/router';
import { createStore } from '@/store';
import { createMixpanel } from '@/app/plugins/mixpanel';
import loggerPlugin from '@/app/plugins/logger';

// injectables
import { EnvService, LocalTracker, Logger, Tracker, TrackerContract } from './injectables';

// contracts
import { Services } from './injectables/tokens';

// filters
import '@/app/filters/format-number';
import '@/app/filters/format-price';
import { filter as formatDate } from '@/app/filters/format-date';
import { filter as formatDateRange } from '@/app/filters/format-date-range';
import '@/app/filters/format-time-ago';
import '@/app/filters/format-time-remaining';
import '@/app/filters/format-big-numbers';
import '@/app/filters/highlight';
import '@/app/filters/boldText';

// components
import '@/shared/ui-kit';
import App from './App.vue';

// directives
import { plugin as numbersOnly } from '@/app/directives/numbersOnly';

// mixins
import useInjectableMixin from './app/mixins/injectableMixin';
import { loadCss } from '@/app/load-css';
import { logout } from './features';
import { Routes } from './router/routes';

Vue.config.productionTip = false;

Vue.use(loggerPlugin);

Vue.use(VueDOMPurifyHTML, {
  default: {
    USE_PROFILES: { html: true },
  },
});

// create axios instance
const { axios } = createAxios();

const infrastructure = {
  EnvService: new EnvService(),
  Logger: new Logger(),
};

const mixpanel = createMixpanel(infrastructure.EnvService);

/**
 * Same logic as in:
 *
 * mp-front/src/app/container.ts
 *
 *   container.bind<TrackerContract>(Services.Tracker).to(mixpanel ? Tracker : LocalTracker);
 *
 * Otherwise it tries to run in prod mode always
 */
const trackerService = mixpanel ? new Tracker(mixpanel) : new LocalTracker(new Logger());

setGraphqlCallbacks({
  async onErrorTrackError({ graphqlCode, graphqlMessage, code }) {
    const { user } = store.state.auth;
    trackerService.trackGraphqlError({
      httpCode: code,
      graphqlCode: graphqlCode,
      graphqlMessage: graphqlMessage,
      userId: user?.id,
    });
  },
  async onErrorShowSnackBar({ message }) {
    await store.dispatch('showSnackbar', { content: message, color: 'warning' }, { root: true });
  },
  accessTokenRefreshed: trackerService.accessTokenRefreshed,
});

const { install: installContainer, container } = createGraphqlContainer({
  axios,
  mixpanel,
  logger: infrastructure.Logger,
  apollo: apolloClient,
});

const forceSignout = async () => {
  // some urls must be preventing from forced logout to prevent
  // app from the infinite refreshing page loop
  const isRootRoute = router && router.currentRoute.fullPath === '/';

  if (isRootRoute) return;

  const signoutUser = async () => {
    await store.dispatch('auth/signoutUser');
  };

  await logout(container, signoutUser);
};

Vue.use({ install: installContainer });

const tracker = container.get<TrackerContract>(Services.Tracker);

// create store
const { store } = createStore(container);

// create router instance
const router = createRouter(store, tracker, infrastructure.Logger);

// set axios callbacks that depends on store or router
setAxiosCallbacks({
  async onUnauthorized() {
    await store.dispatch('auth/resetAuthCheck', '', { root: true });
    await forceSignout();
  },

  async onError(message) {
    await store.dispatch('showSnackbar', { content: message, color: 'warning' }, { root: true });
  },
  onStart: () => {
    store.commit('loading/show');
  },
  onFinish: () => {
    store.commit('loading/hide');
  },
  navigateToErrorPage: (code: string) => {
    if (router.currentRoute.name === Routes.Error) return;

    router.replace({ name: Routes.Error, query: { code } });
  },
});

Vue.filter('formatDate', formatDate);

Vue.filter('formatDateRange', formatDateRange);

// directives
Vue.directive('numbers-only', numbersOnly);

// mixins
Vue.mixin(useInjectableMixin);

loadCss(['Red Hat Display:100,300,400,500,700']);

const env = new EnvService();

const agencyProvider = env.isWhiteLabeledEnv(window.location.host) ? undefined : () => 'compulse360';

getTheme({ agencyProvider }).then(theme => {
  localStorage.setItem('mp_theme_code', theme.name);
  Vue.use(C360, { theme, product: Product.MediaPlanner });

  new Vue({
    router,
    store,
    vuetify,
    render: (h): VNode => h(App),
  }).$mount('#app');
});

export { store };

import * as Types from '../../../../../app/graphql/_types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { ColorSchemeFragmentDoc } from '../../../../../app/graphql/fragments/output.fragments.generated';
export type GetAdditionalLayoutInfoQueryVariables = Types.Exact<{
  input: Types.OutputLayoutInfoInput;
  layoutId: Types.Scalars['UUID'];
}>;

export type GetAdditionalLayoutInfoQuery = {
  getOutputLayoutAdditionalInfo?: {
    colors?: Record<string, any> | null;
    dynamicLinks?: Record<string, any> | null;
  } | null;
  blanks?: Array<{
    id: string;
    slides?: Array<{
      id?: string | null;
      name?: string | null;
      colors?: Record<string, any> | null;
      shapes?: Record<string, any> | null;
      images?: Record<string, any> | null;
      textItems?: Record<string, any> | null;
      layoutId: string;
    }> | null;
  } | null> | null;
  getLayoutColorScheme?: {
    layoutType: Types.OutputLayoutTypeGql;
    colorScheme?: {
      textHeading?: string | null;
      textSubheading?: string | null;
      textBody?: string | null;
      cardTextHeading?: string | null;
      cardTextSubheading?: string | null;
      cardTextBody?: string | null;
      accent?: string | null;
      card?: string | null;
      border?: string | null;
      mapLayer?: string | null;
      primary?: string | null;
    } | null;
  } | null;
};

export const GetAdditionalLayoutInfoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getAdditionalLayoutInfo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'OutputLayoutInfoInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'layoutId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getOutputLayoutAdditionalInfo' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'colors' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dynamicLinks' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'blanks' },
            name: { kind: 'Name', value: 'getBlankSlides' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'layoutId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'layoutId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'slides' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'colors' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'shapes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'images' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'textItems' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'layoutId' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getLayoutColorScheme' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'layoutId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'layoutId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'colorScheme' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ColorScheme' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'layoutType' } },
              ],
            },
          },
        ],
      },
    },
    ...ColorSchemeFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetAdditionalLayoutInfoQuery, GetAdditionalLayoutInfoQueryVariables>;


import { ValidationServiceContract } from '@/injectables';
import { Services } from '@/injectables/tokens';
import Vue from 'vue';

export default Vue.extend({
  name: 'UrlField',

  useInjectable: [Services.Validation],

  props: {
    value: {
      type: String,
      default: '',
    },
    baseDomain: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    atLeastOne: {
      type: Boolean,
      default: false,
    },
    otherFields: {
      type: Array,
      default: () => [],
    },
    checkBaseDomain: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      error: false,
    };
  },
  computed: {
    trackVisitsValue: {
      get(): boolean {
        return this.trackVisits;
      },
      set(value: boolean) {
        this.$emit('update:trackVisits', value);
      },
    },
    campaignTrackingValue: {
      get(): boolean {
        return this.campaignTracking;
      },
      set(value: boolean) {
        this.$emit('update:campaignTracking', value);
      },
    },
    rules() {
      return {
        inBaseDomain: value =>
          !value ||
          !this.checkBaseDomain ||
          (this.baseDomain && this.parseHostname(value) === this.baseDomain) ||
          "The URLs must be on the same domain as the Advertiser's website. Maximum five URLs per campaign.",
        url: (this[Services.Validation] as ValidationServiceContract).isDomainValidatorFactory(
          () => 'Insert a valid URL. Example: example.com.',
        ),
        required: (v: string) => !this.required || !!v || 'This field is required',
        atLeastOne: v =>
          !this.atLeastOne || !!v || !!this.otherFields.filter(Boolean).length || 'At least one URL is required',
      };
    },
    valueModel: {
      get(): string {
        return this.value;
      },
      set(value: string) {
        this.$emit('input', value);
      },
    },
    validationRules() {
      return [this.rules.url, this.rules.required, this.rules.atLeastOne, this.rules.inBaseDomain];
    },
    validationResult() {
      const [error] = this.validationRules
        .map(rule => {
          const value = rule(this.value);
          if (typeof value === 'string') return value;
          return false;
        })
        .filter(Boolean);
      return error;
    },
  },
  methods: {
    parseHostname(url: string): string {
      try {
        if (url.includes('://') === false) {
          return new URL('http://' + url).hostname;
        }
        return new URL(url).hostname;
      } catch (e) {
        return '';
      }
    },
  },
});

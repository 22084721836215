
import Vue from 'vue';
import { mapMutations, mapState, mapGetters, mapActions } from 'vuex';
import { NewProposalRoute, Proposal } from '@/shared/types';

import { Services, Models } from '@/injectables/tokens';
import {
  EnvServiceContract,
  UIUtilsServiceContract,
  AuthServiceContract,
  NotificationsContract,
  LocalStorageServiceContract,
} from '@/injectables';
import { Routes, RoutesWithoutAlert } from '@/router/routes';
import { NotificationsMutations } from '@/store/notifications';
import { Modules } from './store';

import Snackbar from '@/widgets/global/snackbar.vue';
import Alert from '@/widgets/global/alert.vue';
import AsyncDialogProvider from '@/shared/ui/providers/async-dialog-provider.vue';
import CreateProposalDialog from '@/components/Proposal/CreateProposalDialog.vue';
import InstantIODialog from '@/pages/instant-io';

import ClientInfoDialog from '@/components/Clients/ClientInfoDialog.vue';
import Loading from '@/features/global-loading/loading.vue';
import CreateClientDialog from '@/components/CreateClientDialog.vue';

import SideBar from '@/features/user-menu/ui/side-bar.vue';
import { C360Footer, C360Provider, defineAbilityFor } from '@c360/ui';

import AgencySelector from './widgets/agency-selector';

let mutations: () => void;

export default Vue.extend({
  name: 'App',

  useInjectable: [
    Services.Env,
    Services.Tracker,
    Services.Auth,
    Services.LocalStorage,
    Models.Notification,
    Services.UI,
  ],

  provide() {
    return {
      /**
       * Provides global available function to display snackbars without
       * direct dependency.
       *
       * Example
       * ```ts
       * export default {
       *   inject: ['showSnackbar'],
       *
       *   methods: {
       *    action() {
       *      this.showSnackbar('Content');
       *    }
       *   }
       * }
       * ```
       */
      showSnackbar(content: 'string', color = 'error') {
        this.$store.dispatch('showSnackbar', { content, color });
      },

      /**
       * Provides global available function to toggle drawer statae.
       *
       * Example
       * ```ts
       * export default {
       *   inject: ['toggleDrawer'],
       *
       *   methods: {
       *    action() {
       *      this.toggleDrawer();
       *    }
       *   }
       * }
       * ```
       */
      toggleDrawer: () => {
        this.drawer = !this.drawer;
      },
    };
  },

  data: (): {
    refreshing: boolean;
    newBuildLoading: boolean;
    updateExists: boolean;
    serviceWorker: ServiceWorker;
    newBuildNumber: string | null;
    dateCreated: number;
    statusCheckInterval: number;
    drawer: boolean;
    createProposalIsOpen: boolean;
    createProposalRoutes: string[];
    createProposalReturnRoute: string;
    createdClientId: string | null;
    createdProposalClient: { id: string; name: string };
    createClientDialogFlag: boolean;
    proposalCreationInProgress: boolean;
    rail: boolean;
    showAgencySelector: boolean;
    agencySelectorPersistent: boolean;
  } => ({
    createdClientId: null,
    createdProposalClient: null,
    createProposalReturnRoute: 'home',
    createProposalRoutes: ['home', 'proposals'],
    createProposalIsOpen: false,
    refreshing: false,
    newBuildLoading: false,
    serviceWorker: null,
    updateExists: false,
    newBuildNumber: null,
    statusCheckInterval: 0,
    dateCreated: new Date().getTime(),
    drawer: false,
    createClientDialogFlag: false,
    proposalCreationInProgress: false,
    rail: false,
    showAgencySelector: false,
    agencySelectorPersistent: false,
  }),

  components: {
    Snackbar,
    Alert,
    AsyncDialogProvider,
    CreateProposalDialog,
    InstantIODialog,
    Loading,
    CreateClientDialog,
    ClientInfoDialog,
    C360Footer,
    C360Provider,
    SideBar,
    AgencySelector,
  },

  computed: {
    whiteLabeledEnv(): boolean {
      const url = window.location.host;

      return (this[Services.Env] as EnvServiceContract).isWhiteLabeledEnv(url);
    },
    refreshToken() {
      return (this[Services.LocalStorage] as LocalStorageServiceContract).getItem('mp_c360_refresh');
    },
    accessToken() {
      return (this[Services.LocalStorage] as LocalStorageServiceContract).getItem('mp_c360_access');
    },
    disableAlerts() {
      return RoutesWithoutAlert.includes(this.$route.name);
    },
    currentPath(): string {
      return `${window.location.protocol}//${window.location.host}${this.$route.fullPath}`;
    },
    ability() {
      const data = {
        isSuperUser: this.currentUser?.IndividualRight?.includes('SU') ?? false,
        isAgencyAdmin: this.currentUser?.AgencyRight?.includes('AGENCY_ADMIN') ?? false,
        products: this.currentUser?.products ?? [],
        permissions: this.currentUser?.AccessRights ?? [],
        activeAgencyName: this.currentUser?.agency?.id ?? '',
        ...(!this.whiteLabeledEnv ? { products: [] } : {}),
        ...(!this.whiteLabeledEnv
          ? {
              permissions: [
                ...(this.currentUser?.AccessRights?.length ? [this.currentUser?.AccessRights] : []),
                'HIDE_HELP_CENTER',
              ],
            }
          : {}),
      };

      return defineAbilityFor(data);
    },
    showInstantIO(): boolean {
      return (
        this.$store.state.auth.user &&
        this.$store.state.auth.user?.email &&
        this.$route.query.modal === 'insertionOrders' &&
        !this.showAgencySelector
      );
    },
    currentUser() {
      return this.user;
    },
    showNavigationBar(): boolean {
      const showRoutes = [
        Routes.Home,
        Routes.Proposals,
        Routes.Clients,
        Routes.MyClients,
        Routes.Products,
        Routes.AvailsCalculator,
        Routes.KeywordPlanner,
        Routes.InstantCampaigns,
        Routes.AgencyDashboard,
      ];
      return showRoutes.includes(this.routeName);
    },
    showFooter(): boolean {
      const showRoutes = [
        Routes.Home,
        Routes.Proposals,
        Routes.Clients,
        Routes.MyClients,
        Routes.Products,
        Routes.ProposalMarket,
        Routes.ProposalSolutions,
        Routes.ProposalSummary,
        Routes.ProposalFinalize,
        Routes.AvailsCalculator,
        Routes.KeywordPlanner,
        Routes.InstantCampaigns,
        Routes.AgencyDashboard,
      ];
      return showRoutes.includes(this.routeName);
    },
    routeName(): string {
      return this.$route?.name || '';
    },
    fullscreen() {
      return !!this.$route.meta?.fullscreen;
    },
    userAgency(): string {
      return this.$store.state.auth.user?.Agency || '';
    },
    reviewProposals(): Proposal[] {
      if (this.agencyInfo.adminReviewEnabled) {
        return this.$store.state.proposal.allProposals?.list || [];
      }
      return [];
    },
    endingCampaigns(): Proposal[] {
      return this.$store.state.proposal.endingCampaigns.list;
    },

    ...mapState(Modules.Agency, { agencyInfo: 'currentAgencyInfo' }),

    ...mapState(Modules.Auth, ['user']),

    ...mapGetters(Modules.Auth, ['isAgencyAdmin', 'isAdmin', 'isHelpCenterAvailable']),

    ...mapGetters(Modules.Loading, { showGlobalProgressBar: 'showGlobalLoading' }),
  },

  created(): void {
    const envService: EnvServiceContract = this.envService;

    const UIUtilsService: UIUtilsServiceContract = this.uiUtilsService;

    UIUtilsService.throttle('resize', 'optimizedResize', window);

    document.addEventListener('swUpdated', this.showRefreshUI, { once: true });

    document.addEventListener('swUpdateAvailable', this.newBuildEvent, { once: true });

    if (navigator && navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener('message', event => {
        if (event.data) {
          if (event.data.version && event.data.version.length > 10) {
            this.newBuildNumber = `v1.${event.data.version.substr(3, 6)}`;
          }
        }
      });
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        if (this.refreshing) {
          return;
        }
        this.refreshing = true;
        window.location.reload();
      });
    }

    if (envService.isProduction()) this.loadAppzi();
  },

  watch: {
    userAgency(agency: string): void {
      if (agency) {
        this.$store.dispatch('agency/getAgencyInfo', agency);
      }
    },
    reviewProposals(proposals: Proposal[]): void {
      if (proposals.length) this.createNotifications();
    },
    endingCampaigns(proposals: Proposal[]): void {
      if (proposals.length) this.createNotifications();
    },
    currentUser() {
      if (this.isAdmin && !this.user.Agency) this.openPersistentAgencySelector();
    },
  },

  beforeDestroy(): void {
    mutations();
  },

  methods: {
    ...mapMutations(Modules.Notifications, { setNotifications: NotificationsMutations.SetNotifications }),
    ...mapActions(Modules.Auth, ['signoutUser']),
    openPersistentAgencySelector() {
      this.showAgencySelector = true;
      this.agencySelectorPersistent = true;
    },
    openAgencySelector() {
      this.showAgencySelector = true;
    },
    closeAgencySelector() {
      this.showAgencySelector = false;
    },
    closeCreateClientDialog(): void {
      this.createClientDialogFlag = false;
    },
    loadAppzi(): void {
      const script = document.createElement('script');

      script.async = true;

      script.src = 'https://w.appzi.io/w.js?token=1FdFB';

      document.head.appendChild(script);
    },
    async updateUserToken(): Promise<void> {
      const refreshToken = this.refreshToken;

      const { isErr, unwrap } = await (this.authService as AuthServiceContract).refreshToken(refreshToken);

      if (isErr()) return;

      (this[Services.LocalStorage] as LocalStorageServiceContract).setItem('mp_c360_refresh', refreshToken);

      const { access = null } = unwrap();

      if (!access) return;

      (this[Services.LocalStorage] as LocalStorageServiceContract).setItem('mp_c360_access', access);

      await this.$store.dispatch('auth/checkIfAuth');
    },
    async submitNewClient(): Promise<void> {
      const { id, name } = await this.$store.dispatch('client/submitNewClient', { skipModal: this.showInstantIO });

      if (!this.isAgencyAdmin) await this.updateUserToken();

      this.closeCreateClientDialog();

      if (this.showInstantIO) {
        this.createdProposalClient = { id, name };
        return;
      }

      if (this.proposalCreationInProgress) {
        this.createdProposalClient = { id, name };
        this.openCreateProposal();
        this.proposalCreationInProgress = false;
        return;
      }

      await this.$store.dispatch('client/updateActiveClientId', id);
      this.createdClientId = id;
    },
    openCreateProposal(): void {
      this.createProposalIsOpen = true;
      if (this.createProposalRoutes.includes(this.$route.name)) {
        this.createProposalReturnRoute = this.$route.name;
      }
    },
    openCreateClient(): void {
      this.$store.dispatch('client/getCategories');
      this.createClientDialogFlag = true;
      this.proposalCreationInProgress = false;
      if (this.createProposalIsOpen) {
        this.createProposalIsOpen = false;
        this.proposalCreationInProgress = true;
      }
    },
    handleNavigation({ name, params }: NewProposalRoute): void {
      this.$router.push({ name, params: { ...params } });
    },
    refreshApp(): void {
      this.updateExists = false;
      if (!this.serviceWorker || !this.serviceWorker.waiting) {
        return;
      }
      this.serviceWorker.waiting.postMessage('skipWaiting');
    },
    newBuildEvent(): void {
      this.newBuildLoading = true;
    },
    showRefreshUI({ detail }: { detail: ServiceWorker }): void {
      const diff: number = new Date().getTime() - this.dateCreated;
      this.serviceWorker = detail;
      if (diff < 10000) {
        // eslint-disable-next-line no-console
        console.info('new version available, auto refresh ', diff / 1000.0, 's');
        this.serviceWorker.waiting.postMessage('skipWaiting');
        return;
      }
      this.serviceWorker = detail;
      this.updateExists = true;
    },
    async createNotifications(): Promise<void> {
      const notificationEntity: NotificationsContract = this.notificationEntity;

      const { unwrap: unwrapReviewNotifications } = notificationEntity.adminReviewNotifications({
        email: this.user.email,
        isAgencyAdmin: this.isAgencyAdmin,
        proposals: this.reviewProposals,
      });

      const { unwrap } = notificationEntity.allNotifications({
        notifications: unwrapReviewNotifications(),
        endingCampaigns: this.endingCampaigns,
      });

      this.setNotifications(unwrap());
    },
  },
});


import Vue from 'vue';

export default Vue.extend({
  inheritAttrs: false,

  props: {
    title: String,
    domId: String,
    chartType: String,
    limitChartsEnoughData: Boolean,
    isExporting: Boolean,
  },

  computed: {
    isComscore() {
      return this.title === 'comscore';
    },
  },
});

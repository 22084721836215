
import Vue from 'vue';
import BaseDialog from '@/shared/ui/components/base-dialog.vue';
import SelectableListItem from '@/shared/ui/components/selectable-list-item.vue';

const STATION_TYPE = 'STATION' as const;
const SUB_AGENCY_TYPE = 'SUBAGENCY' as const;

type subagencyOrStation = { id: string; name: string; type: typeof SUB_AGENCY_TYPE | typeof STATION_TYPE };

interface FormData {
  subagencies: Record<string, boolean>;
  stations: Record<string, boolean>;
}

interface Data {
  subagencies: subagencyOrStation[];
  stations: subagencyOrStation[];
  formData: FormData;
  anySubagenciesActive: boolean;
  allSubagenciesActive: boolean;
  anyStationsActive: boolean;
  allStationsActive: boolean;
}

export default Vue.extend({
  name: 'ChildrenSelectModal',
  components: { SelectableListItem, BaseDialog },

  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    propagationType: {
      type: String,
      default: '',
    },
    isCreate: {
      type: Boolean,
      default: false,
    },
  },

  data(): Data {
    return {
      subagencies: [],
      stations: [],
      formData: {
        subagencies: {},
        stations: {},
      },
      anySubagenciesActive: false,
      allSubagenciesActive: false,
      anyStationsActive: false,
      allStationsActive: false,
    };
  },

  mounted() {
    this.initializeChildren();
  },

  computed: {
    openDialog: {
      get(): boolean {
        return this.dialog;
      },
      set(val: boolean): void {
        if (!val) {
          this.closeDialog();
        }
      },
    },

    title() {
      const action = this.isCreate
        ? 'Apply'
        : this.propagationType.charAt(0).toUpperCase() + this.propagationType.slice(1);
      if (action === 'Delete') return action + ' from sub-entities';
      return action + ' to sub-entities';
    },
  },

  watch: {
    formData: {
      handler: 'handleFormDataChange',
      deep: true,
    },
  },

  methods: {
    handleFormDataChange() {
      if (this.formData.subagencies) {
        const subagencyValues = Object.values(this.formData.subagencies);
        this.anySubagenciesActive = subagencyValues.some(value => value);
        this.allSubagenciesActive = subagencyValues.every(value => value);
      }
      if (this.formData.stations) {
        const stationValues = Object.values(this.formData.stations);
        this.anyStationsActive = stationValues.some(value => value);
        this.allStationsActive = stationValues.every(value => value);
      }
    },
    confirmClicked(): void {
      const selectedSubagencies = [];
      const selectedStations = [];

      for (const [key, value] of Object.entries(this.formData.subagencies)) {
        if (value) selectedSubagencies.push(key);
      }
      for (const [key, value] of Object.entries(this.formData.stations)) {
        if (value) selectedStations.push(key);
      }

      const selectedChildren = [...selectedSubagencies, ...selectedStations];
      this.$emit('confirm', selectedChildren);
    },
    selectAllSubagencies(): void {
      const allSubagenciesActive = this.allSubagenciesActive;
      this.setAllSubagenciesTo(!allSubagenciesActive);
    },
    selectAllStations(): void {
      const allStationsActive = this.allStationsActive;
      this.setAllStationsTo(!allStationsActive);
    },
    setAllSubagenciesTo(newState: boolean): void {
      this.subagencies.forEach((subagency: subagencyOrStation) => {
        this.$set(this.formData.subagencies, subagency.id, newState);
      });
    },
    setAllStationsTo(newState: boolean): void {
      this.stations.forEach((station: subagencyOrStation) => {
        this.$set(this.formData.stations, station.id, newState);
      });
    },
    initializeChildren(): void {
      const children: subagencyOrStation[] = this.$store.state?.auth?.user?.agency?.children || [];
      if (!children.length) return;

      for (const child of children) {
        if (child.type === STATION_TYPE) {
          this.stations.push(child);
          this.$set(this.formData.stations, child.id, false);
        } else if (child.type === SUB_AGENCY_TYPE) {
          this.subagencies.push(child);
          this.$set(this.formData.subagencies, child.id, false);
        }
      }
    },
    closeDialog(): void {
      this.$emit('close-dialog');
      this.setAllSubagenciesTo(false);
      this.setAllStationsTo(false);
    },
  },
});

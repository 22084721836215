import { GetCategoriesDocument, GetCategoriesQuery } from '../graphql/queries/get-all-categories.generated';
import { Failure } from '@/injectables/failure';
import {
  RevenueData,
  UnsafeAny,
  AnyObject,
  Client,
  ClientArray,
  NewClient,
  ClientCategory,
  AgencyListItem,
} from '@/shared/types';

import { Err, Ok, Result } from '@sniptt/monads/build';
import { ClientServiceContract, RestClientService, SubmitNewClientOk } from '../..';
import { GetAllAgenciesDocument, GetAllAgenciesQuery } from '../graphql/queries/get-all-agencies.generated';
import {
  GetAllClientsDocument,
  GetAllClientsQuery,
  GetAllClientsQueryVariables,
} from '../graphql/queries/get-all-clients.generated';
import { ClientSortBy, MediaplannerLocationInput, Scalars, SortDirection } from '@/app/graphql';
import { arrayRangToObject } from '../../proposal/utils';
import {
  GetClientByIdDocument,
  GetClientByIdQuery,
  GetClientByIdQueryVariables,
} from '../graphql/queries/get-client-by-id.generated';
import {
  GetRecentClientsDocument,
  GetRecentClientsQuery,
  GetRecentClientsQueryVariables,
} from '../graphql/queries/get-recent-client.generated';
import {
  CreateClientDocument,
  CreateClientMutation,
  CreateClientMutationVariables,
} from '../graphql/mutations/create-client.generated';
import { mapActiveProductsData } from '../../proposal/utils';
import {
  GetClientPurchaseHistoryDocument,
  GetClientPurchaseHistoryQuery,
  GetClientPurchaseHistoryQueryVariables,
} from '../graphql/queries/get-client-purchase -history.generated';
import {
  UpdateClientLogoDocument,
  UpdateClientLogoMutation,
  UpdateClientLogoMutationVariables,
} from '../graphql/mutations/update-client-logo.generated';
import {
  UpdateClientNotesDocument,
  UpdateClientNotesMutation,
  UpdateClientNotesMutationVariables,
} from '../graphql/mutations/update-client-notes.generated';
import {
  UpdateClientGeoSelectionsDocument,
  UpdateClientGeoSelectionsMutation,
  UpdateClientGeoSelectionsMutationVariables,
} from '../graphql/mutations/update-client-geo.generated';
import {
  UpdateClientLocationsDocument,
  UpdateClientLocationsMutation,
  UpdateClientLocationsMutationVariables,
} from '../graphql/mutations/update-client-locations.generated';
import {
  UpdateClientInfoDocument,
  UpdateClientInfoMutation,
  UpdateClientInfoMutationVariables,
} from '../graphql/mutations/update-client-info.generated';
import {
  GetClientGeoByIdDocument,
  GetClientGeoByIdQuery,
  GetClientGeoByIdQueryVariables,
} from '../graphql/queries/get-client-geo-by-id.generated';
import {
  GetAllMyClientsDocument,
  GetAllMyClientsQuery,
  GetAllMyClientsQueryVariables,
} from '@/injectables/services/client/graphql/queries/get-all-my-clients.generated';

const clientMapper = client => ({
  ...client,
  AgencyPartner: client?.agency?.name,
});

export class ClientService extends RestClientService implements ClientServiceContract {
  async updateNotes({
    clientId,
    notes,
  }: UpdateClientNotesMutationVariables): Promise<
    Result<UpdateClientNotesMutation['updateMediaplannerClient'], Failure>
  > {
    try {
      const { data } = await this._apollo.mutate<UpdateClientNotesMutation, UpdateClientNotesMutationVariables>({
        mutation: UpdateClientNotesDocument,
        variables: {
          clientId,
          notes,
        },
      });

      return Ok(data.updateMediaplannerClient);
    } catch (error) {
      return Err({ message: (error && error.message) || `Can't save client's notes at this time` });
    }
  }

  async getAll({
    offset = 0,
    limit = 15,
    sortBy = 'clientName',
    desc = false,
    searchTerm,
    filters,
    mine = false,
  }: {
    limit?: number;
    offset?: number;
    desc?: Boolean;
    sortBy?: string;
    searchTerm?: string;
    filters?: UnsafeAny;
    agencyId?: string;
    mine: Boolean;
  }): Promise<Result<{ items: ClientArray; total: number }, Failure>> {
    try {
      const mapper = {
        clientName: ClientSortBy.Name,
        category: ClientSortBy.Category,
        lastUpdated: ClientSortBy.UpdatedAt,
        AgencyPartner: ClientSortBy.Agency,
      };

      const { modified } = filters?.dates || {};
      const { agency, subAgency, category } = filters || {};
      const options = {
        query: mine ? GetAllMyClientsDocument : GetAllClientsDocument,
        variables: {
          input: {
            limit,
            offset,
            sortBy: mapper[sortBy] || ClientSortBy.Name,
            sortDirection: desc ? SortDirection.Desc : SortDirection.Asc,
            ...(searchTerm ? { searchKey: searchTerm } : {}),
            ...(agency || subAgency ? { agencyId: subAgency || agency } : {}),
            ...(category ? { categoryId: category } : {}),
            ...(modified?.range?.length ? { modifiedDateRange: arrayRangToObject(modified.range) } : {}),
          },
        },
      };
      // Options between "my clients" and "all clients" are exactly the same, only the endpoint changes
      let response;
      if (mine) {
        response = await this._apollo.query<GetAllMyClientsQuery, GetAllMyClientsQueryVariables>(options);
      } else {
        response = await this._apollo.query<GetAllClientsQuery, GetAllClientsQueryVariables>(options);
      }
      const { data, error } = response;

      if (error) {
        return Err({
          message: `Error while loading clients: ${error}`,
        });
      }

      const clientMapper = client => ({
        ...client,
        category: client?.category?.name,
        AgencyPartner: client?.agency?.name,
      });

      const { items, total } = mine ? data.getMyMediaplannerClients : data.getMediaplannerClients;
      return Ok({ items: items.map(clientMapper), total });
    } catch (error) {
      return Err({
        message: `Error while loading clients: ${error}`,
      });
    }
  }
  async getAllCategories(): Promise<Result<ClientCategory[], Failure>> {
    try {
      const { data, error } = await this._apollo.query<GetCategoriesQuery>({
        query: GetCategoriesDocument,
      });

      if (error) {
        return Err({
          message: "Can't load pages at this time.",
        });
      }

      const categories = data.getCategories;

      return Ok(categories);
    } catch (error) {
      return Err({
        message: `Can't load pages at this time: ${error}`,
      });
    }
  }
  async getAgencies(): Promise<Result<AgencyListItem[], Failure>> {
    try {
      const { data, error } = await this._apollo.query<GetAllAgenciesQuery>({
        query: GetAllAgenciesDocument,
      });

      if (error) {
        return Err({
          message: 'Error while loading agencies',
        });
      }

      return Ok(data.getMediaplannerAgencies);
    } catch (error) {
      return Err({
        message: `Error while loading agencies: ${error}`,
      });
    }
  }

  async search(searchKey: string, limit = 100): Promise<Result<Client[], Failure>> {
    try {
      const { data, error } = await this._apollo.query<GetAllClientsQuery, GetAllClientsQueryVariables>({
        query: GetAllClientsDocument,
        variables: {
          input: {
            limit,
            offset: 0,
            sortBy: ClientSortBy.Name,
            sortDirection: SortDirection.Asc,
            ...(searchKey ? { searchKey } : {}),
          },
        },
      });

      if (error) {
        return Err({
          message: `Error while loading clients: ${error}`,
        });
      }

      const { items } = data.getMediaplannerClients;
      return Ok(items.map(clientMapper));
    } catch (err) {
      return Err({ message: "Can't find clients at this time" });
    }
  }

  async getById(clientId: string): Promise<Result<Client, Failure>> {
    try {
      const { data, error } = await this._apollo.query<GetClientByIdQuery, GetClientByIdQueryVariables>({
        query: GetClientByIdDocument,
        variables: {
          id: clientId,
        },
      });

      if (error) {
        return Err({
          message: `Error while loading clients: ${error}`,
        });
      }

      const client = data.getMediaplannerClient;
      return Ok(clientMapper(client));
    } catch (err) {
      return Err({ message: "Can't find clients at this time" });
    }
  }

  async getRecentClients(limit = 4): Promise<Result<Client[], Failure>> {
    try {
      const { data, error } = await this._apollo.query<GetRecentClientsQuery, GetRecentClientsQueryVariables>({
        query: GetRecentClientsDocument,
        variables: {
          input: {
            limit,
            sortBy: ClientSortBy.UpdatedAt,
            sortDirection: SortDirection.Desc,
          },
        },
      });

      if (error) {
        return Err({
          message: `Error while loading clients: ${error}`,
        });
      }

      const client = [...data.editable.items, ...data.nonEditable.items].slice(0, limit);
      return Ok(client.map(clientMapper));
    } catch (err) {
      return Err({ message: "Can't find clients at this time" });
    }
  }

  async submitNewClient(client: NewClient): Promise<Result<SubmitNewClientOk, Failure>> {
    try {
      const [clientAddress = {}] = client.locations;

      const { data } = await this._apollo.mutate<CreateClientMutation, CreateClientMutationVariables>({
        mutation: CreateClientDocument,
        variables: {
          input: {
            name: client.name,
            agencyId: client.AgencyPartner,
            phone: client.Phone,
            categoryId: client.category.id,
            url: client.WURL,
            ...(clientAddress.address
              ? {
                  address: [
                    {
                      address: clientAddress.address,
                      label: clientAddress.label,
                      lat: clientAddress.lat,
                      lon: clientAddress.lon,
                      ...(clientAddress.state ? { state: clientAddress.state } : {}),
                      ...(clientAddress.city ? { state: clientAddress.city } : {}),
                      ...(clientAddress.zip ? { state: clientAddress.zip } : {}),
                    },
                  ],
                }
              : {}),
          },
        },
      });

      return Ok({ id: data.createMediaplannerClient.id, name: data.createMediaplannerClient.name });
    } catch (error) {
      return Err({ message: (error && error.message) || `Can't create client at this time` });
    }
  }

  async getPurchaseHistory(id: string): Promise<Result<RevenueData, Failure>> {
    try {
      const { data, error } = await this._apollo.query<
        GetClientPurchaseHistoryQuery,
        GetClientPurchaseHistoryQueryVariables
      >({
        query: GetClientPurchaseHistoryDocument,
        variables: {
          clientId: id,
        },
      });

      if (error) {
        return Err({
          message: 'Unable to fetch active products.',
        });
      }

      const result = mapActiveProductsData(data.activeClientProductCategoriesComponent);

      return Ok(result);
    } catch (error) {
      return Err({
        message: `Unable to fetch active products.`,
      });
    }
  }

  // LOGOS
  async updateClientLogo(
    clientId: Scalars['UUID'],
    clientLogo: Scalars['URL'],
  ): Promise<Result<Scalars['URL'], Failure>> {
    try {
      const { data } = await this._apollo.mutate<UpdateClientLogoMutation, UpdateClientLogoMutationVariables>({
        mutation: UpdateClientLogoDocument,
        variables: {
          input: {
            id: clientId,
            logo: clientLogo,
          },
        },
      });

      return Ok(data.updateMediaplannerClient.logo);
    } catch (error) {
      return Err({ message: (error && error.message) || `Can't create client at this time` });
    }
  }

  async removeClientLogo(clientId: Scalars['UUID']): Promise<Result<Scalars['UUID'], Failure>> {
    try {
      const { data } = await this._apollo.mutate<UpdateClientLogoMutation, UpdateClientLogoMutationVariables>({
        mutation: UpdateClientLogoDocument,
        variables: {
          input: {
            id: clientId,
            logo: null,
          },
        },
      });

      return Ok(data.updateMediaplannerClient.id);
    } catch (error) {
      return Err({ message: (error && error.message) || `Can't create client at this time` });
    }
  }

  async updateClientGeoSelections(
    id: string,
    geoSelections: AnyObject,
  ): Promise<Result<UpdateClientGeoSelectionsMutation['updateMediaplannerClient'], Failure>> {
    try {
      const { data } = await this._apollo.mutate<
        UpdateClientGeoSelectionsMutation,
        UpdateClientGeoSelectionsMutationVariables
      >({
        mutation: UpdateClientGeoSelectionsDocument,
        variables: {
          id,
          geoSelections,
        },
      });

      return Ok(data.updateMediaplannerClient);
    } catch (error) {
      return Err({ message: (error && error.message) || `Can't update client geo selections at this time` });
    }
  }

  async updateClientLocations(
    id: string,
    address: MediaplannerLocationInput[],
  ): Promise<Result<UpdateClientLocationsMutation['updateMediaplannerClient'], Failure>> {
    try {
      const { data } = await this._apollo.mutate<UpdateClientLocationsMutation, UpdateClientLocationsMutationVariables>(
        {
          mutation: UpdateClientLocationsDocument,
          variables: {
            id,
            address,
          },
        },
      );

      return Ok(data.updateMediaplannerClient);
    } catch (error) {
      return Err({ message: (error && error.message) || `Can't update client locations at this time` });
    }
  }

  async removeClientGeoSelections(
    id: string,
  ): Promise<Result<UpdateClientGeoSelectionsMutation['updateMediaplannerClient'], Failure>> {
    try {
      const { data } = await this._apollo.mutate<
        UpdateClientGeoSelectionsMutation,
        UpdateClientGeoSelectionsMutationVariables
      >({
        mutation: UpdateClientGeoSelectionsDocument,
        variables: {
          id,
          geoSelections: {
            cityList: [],
            countyList: [],
            stateList: [],
            dmaList: [],
            zipList: [],
          },
        },
      });

      return Ok(data.updateMediaplannerClient);
    } catch (error) {
      return Err({ message: (error && error.message) || `Can't update client geo selections at this time` });
    }
  }

  async updateInfo(
    client: {
      url: string;
      categoryId: string;
      phone: string;
    },
    id: string,
  ): Promise<Result<UpdateClientInfoMutation['updateMediaplannerClient'], Failure>> {
    try {
      const { data } = await this._apollo.mutate<UpdateClientInfoMutation, UpdateClientInfoMutationVariables>({
        mutation: UpdateClientInfoDocument,
        variables: {
          id,
          url: client.url,
          category: client.categoryId,
          phone: client.phone,
        },
      });

      return Ok(data.updateMediaplannerClient);
    } catch (error) {
      return Err({ message: (error && error.message) || `Can't update client geo selections at this time` });
    }
  }

  async getClientGeoById(
    clientId: Scalars['UUID'],
  ): Promise<Result<GetClientGeoByIdQuery['getMediaplannerClient']['geoSelections'], Failure>> {
    try {
      const { data } = await this._apollo.query<GetClientGeoByIdQuery, GetClientGeoByIdQueryVariables>({
        query: GetClientGeoByIdDocument,
        variables: {
          clientId,
        },
      });

      return Ok(data.getMediaplannerClient.geoSelections);
    } catch (error) {
      return Err({ message: (error && error.message) || `Can't update client geo selections at this time` });
    }
  }
}


import Vue from 'vue';
import debounce from 'lodash.debounce';
import CsvUploadDialog from '@/components/CsvUploadDialog.vue';
import MapComponent from '@/shared/legacy/map/map.vue';
import { ProductGeoFence, UnsafeAny } from '@/shared/types';
import { GeocodeServiceContract } from '@/injectables';
import { Services } from '@/injectables/tokens';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const addressToLatLong: any = {};

interface GeoFenceObject {
  lat: string;
  lon: string;
  address: string;
}

export default Vue.extend({
  name: 'GeoFence',

  components: { MapComponent, CsvUploadDialog },

  inject: ['showSnackbar'],

  useInjectable: [Services.Geocode],

  props: {
    productId: {
      type: String,
      required: true,
    },
    flight: {
      type: Object,
      required: true,
    },
    dialog: {
      type: Boolean,
      default: false,
    },
    isChangeDisabled: {
      type: Boolean,
      default: false,
    },
  },

  mounted(): void {
    if (this.geofences?.length >= 1) {
      this.geofences.forEach(fence => {
        if (fence.address && fence.lat && fence.lon && fence.radius && fence.unitType) {
          addressToLatLong[fence.address] = {
            lat: fence.lat,
            lon: fence.lon,
            radius: fence.radius,
            unitType: fence.unitType,
          };
        }
      });
    } else {
      this.redrawFenceMap();
    }

    const [fence] = this.geofences;
    this.setActiveFence(fence?.id);
  },

  data: (): {
    addressSearch: string;
    fetchingLocations: boolean;
    locationAutocompleteList: object[];
    localAddress: string;
    unitTypes: string[];
    mapCircle: object | null;
    expanded: boolean;
    mapReady: boolean;
    activeFenceId: string;
    activeFenceHasChanged: boolean;
    openFileUpload: boolean;
    bulkAddressLoading: boolean;
    invalidAddresses: { address: string; error?: string }[];
    showInvalidAddresses: boolean;
    publicPath: string;
  } => ({
    publicPath: process.env.BASE_URL,
    showInvalidAddresses: false,
    invalidAddresses: [],
    bulkAddressLoading: false,
    activeFenceHasChanged: false,
    activeFenceId: '0',
    expanded: false,
    mapReady: false,
    localAddress: '',
    mapCircle: null,
    unitTypes: ['Kilometers', 'Meters', 'Miles', 'Feet'],
    addressSearch: '',
    fetchingLocations: false,
    locationAutocompleteList: [],
    openFileUpload: false,
  }),

  watch: {
    invalidAddresses(arr): void {
      if (arr?.length) {
        this.showInvalidAddresses = true;
      }
    },
    activeFenceId(): void {
      this.activeFenceHasChanged = true;
      setTimeout(() => {
        this.activeFenceHasChanged = false;
      }, 400);
    },
    localAddress(address: string): void {
      if (address) {
        this.setGeoFenceAddress(address);
      }
    },
    unitType(val): void {
      if (!this.activeFenceHasChanged) {
        if (val === 'Kilometers') {
          this.radius = 0.4;
        } else if (val === 'Miles') {
          this.radius = 1;
        } else if (val === 'Meters') {
          this.radius = 400;
        } else if (val === 'Feet') {
          this.radius = 1200;
        }
      }
    },
    addressSearch(val: string): void {
      if (!val || val.length < 5) {
        return;
      }
      this.fetchingLocations = true;
      this.fetchAutocompleteApi(val);
    },
  },

  methods: {
    redrawFenceMap(): void {
      const map = this.$refs[this.mapCacheKey]?.Get();
      if (map) {
        map.Redraw();
      }
    },
    activateBulkUploadDialog(): void {
      this.openFileUpload = true;
    },
    clearInvalidAddresses(): void {
      this.showInvalidAddresses = false;
      this.invalidAddresses = [];
    },
    async applyUploadedFile(addresses: { address: string; radius: string }[] = []): Promise<void> {
      if (!addresses.length) return;
      const geocodeService: GeocodeServiceContract = this.geocodeService;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const fetchAddress = async (address: string, radius: string): Promise<any> => {
        const { isErr, unwrap } = await geocodeService.searchByLocation(encodeURIComponent(address));
        if (isErr()) throw new Error();
        const [addressData] = unwrap();
        return { address: addressData, radius }; // assumes first item is best match
      };
      const invalidAddresses = [];
      // TODO: Replace UnsafeAny with actual type
      const validateAndMapFences = (fences: { address: any; radius: string }[]): ProductGeoFence[] => {
        const filtered = [];
        const existingAddresses: string[] = this.geofences.map(i => i.address);
        let duplicate = false;

        fences.forEach(item => {
          const { address: f } = item;
          duplicate = existingAddresses.includes(f.address?.label);
          if (!f.error && f?.address?.label && f?.position?.lat && f?.position?.lng && !duplicate) filtered.push(item);
          else invalidAddresses.push(f);
        });

        if (duplicate) {
          this.$store.dispatch('showSnackbar', { content: 'Geofence already selected', color: 'warning' });
        }

        return filtered.map(item => {
          const { address: f, radius } = item;
          addressToLatLong[f.address.label] = {
            lat: f.position.lat,
            lon: f.position.lng,
          };
          return {
            address: f.address.label,
            lat: f.position.lat,
            lon: f.position.lng,
            radius,
            unitType: 'Miles',
            custom: false,
          };
        });
      };
      this.bulkAddressLoading = true;
      const geofences = [];
      const chunkSize = 5;
      for (let i = 0; i < addresses.length; i += chunkSize) {
        const chunk = addresses.slice(i, i + chunkSize);
        geofences.push(...await Promise.all(chunk.map(async ({ address, radius }) => fetchAddress(address, radius))));
        await new Promise(resolve => setTimeout(resolve, 1000)); // wait a second because HereApi has RPS 5
      }

      const fencesToAdd = validateAndMapFences(geofences);
      this.bulkAddressLoading = false;
      if (fencesToAdd?.length) {
        const mapped = fencesToAdd.map((fence, i) => {
          return { ...fence, id: (Date.now() + i * 10000).toString() };
        });
        const newFlight = { ...this.flight };

        const flightGeoFences = newFlight.market?.geoSelections?.addressList || [];
        flightGeoFences.push(...mapped);
        this.$emit('update-flight', newFlight);
        const [lastFence] = mapped.slice(-1);
        this.setActiveFence(lastFence.id);
        if (invalidAddresses?.length) {
          this.invalidAddresses = invalidAddresses;
        }
      }
    },
    setActiveFence(fenceId?): void {
      if (this.activeFenceId === fenceId) {
        return;
      }
      if (!fenceId) {
        if (this.geofences.length) {
          this.activeFenceId = this.geofences[0].id;
        } else {
          this.activeFenceId = '0';
        }
      } else {
        this.activeFenceId = fenceId;
      }
      this.setMapView({ fromChip: true });
    },
    deleteFence(fenceId): void {
      const map = this.$refs[this.mapCacheKey]?.Get();
      const fenceIndex = this.geofences.findIndex(fence => fence.id === fenceId);
      if (fenceIndex !== -1) {
        map.RemoveLayer(`circle_${this.geofences[fenceIndex].address}`);
        const geoFences = [...(this.flight.market?.geoSelections?.addressList || [])];
        const updatedGeofence = geoFences.filter(f => f.id !== fenceId);

        const newFlight = { ...this.flight };
        newFlight.market.geoSelections.addressList = updatedGeofence;
        this.$emit('update-flight', newFlight);

        if (this.activeFenceId === fenceId) {
          this.setActiveFence();
        } else {
          this.setMapView({ fromChip: true });
        }
      }
    },
    convertedRadius(radius: number, unitType: string): number {
      let circleRadius = radius || 0;
      switch (unitType) {
        case 'Kilometers':
          circleRadius *= 1000;
          break;
        case 'Meters':
          circleRadius *= 1;
          break;
        case 'Miles':
          circleRadius *= 1609.34;
          break;
        case 'Feet':
          circleRadius *= 0.3048;
          break;
        default:
          circleRadius = 0;
          break;
      }
      return circleRadius;
    },
    addNewGeofence(): ProductGeoFence {
      const geoFence = {
        id: Date.now().toString(),
        address: '',
        lat: null,
        lon: null,
        radius: 400,
        unitType: 'Meters',
      };
      const newFlight = { ...this.flight };
      const flightGeoFences = [...(newFlight?.market?.geoSelections?.addressList || [])];
      flightGeoFences.push(geoFence);
      newFlight.market.geoSelections.addressList = flightGeoFences;
      this.$emit('update-flight', newFlight);

      return geoFence;
    },
    updateExistingGeoFence(fence) {
      const geoFences = [...(this.flight.market?.geoSelections?.addressList || [])];
      const fenceIndex = geoFences.findIndex(f => f.id === fence.id);
      const updatedGeoFence = {
        ...geoFences[fenceIndex],
        ...fence,
      };

      geoFences[fenceIndex] = updatedGeoFence;
      const newFlight = { ...this.flight };
      newFlight.market.geoSelections.addressList = geoFences;
      this.$emit('update-flight', newFlight);
    },
    async setGeoFenceAddress(newAddress: string | GeoFenceObject): Promise<void> {
      const existingAddresses: string[] = this.geofences.map(i => i.address);
      const newAddressValue = typeof newAddress === 'string' ? newAddress : newAddress?.address;

      if (existingAddresses.includes(newAddressValue)) {
        this.$store.dispatch('showSnackbar', { content: 'Geofence already selected', color: 'warning' });
        return;
      }

      const addressIndex = this.items.findIndex(a => a.address === newAddress);
      let address, lat, lon;
      if (addressIndex !== -1) {
        address = newAddress;
        lat = this.items[addressIndex].lat;
        lon = this.items[addressIndex].lon;
      } else if (typeof newAddress === 'object') {
        address = newAddress.address;
        lat = newAddress.lat;
        lon = newAddress.lon;
      }
      const newFence = this.addNewGeofence();
      const updatedGeoFence: ProductGeoFence = {
        ...newFence,
        address: address,
        ...(lat ? { lat } : null),
        ...(lon ? { lon } : null),
      };
      addressToLatLong[updatedGeoFence.address] = {
        lat: updatedGeoFence.lat,
        lon: updatedGeoFence.lon,
      };
      this.updateExistingGeoFence(updatedGeoFence);

      this.localAddress = '';
      this.setActiveFence(updatedGeoFence.id);
    },
    fetchAutocompleteApi: debounce(async function (query: string) {
      const geocodeService: GeocodeServiceContract = this.geocodeService;
      const { isErr, unwrapErr, unwrap } = await geocodeService.searchByLocation(encodeURIComponent(query));
      if (isErr()) {
        this.showSnackbar(unwrapErr().message, 'error');
        return;
      }
      this.locationAutocompleteList = unwrap();
      this.fetchingLocations = false;
    }, 500),
    onMapReady(): void {
      const map = this.$refs[this.mapCacheKey]?.Get();

      if (!map || !map.host || !map.leaflet) {
        // eslint-disable-next-line no-console
        console.error('map component did not load correctly', map);
        return;
      }

      this.mapReady = true;
      map.ClearMap();
      map.SetView([40, -100], 4);

      if (this.geofences.every(fence => fence.address && addressToLatLong[fence.address])) {
        this.setMapView({ fromInit: true });
      } else {
        this.setMapView();
      }
    },
    debounceMapMoved(): void {
      clearTimeout(this.onMapMovedTimer);
      this.onMapMovedTimer = setTimeout(() => {
        this.onMapMoved();
      }, 400);
    },
    onMapMoved(): void {
      const map = this.$refs[this.mapCacheKey]?.Get();
      if (!this.mapReady || !map || !map.host || !map.leaflet) {
        // eslint-disable-next-line no-console
        console.log('map not ready');
        return;
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
    setMapView: debounce(function (ctx: any): void {
      const map = this.$refs[this.mapCacheKey]?.Get();
      if (!this.mapReady || !map || !map.leaflet) {
        // eslint-disable-next-line no-console
        console.log('map not ready');
        return;
      }
      let hasFences = false;
      this.geofences.forEach(fence => {
        if (fence?.address) {
          hasFences = true;
          const { radius, unitType } = fence;
          const geo = addressToLatLong[fence.address];
          let circle = geo.mapCircle;
          if (circle) {
            circle.setRadius(this.convertedRadius(radius, unitType));
          } else {
            circle = map.CreateCircleLayer(
              geo.lat,
              geo.lon,
              this.convertedRadius(radius, unitType),
              `circle_${fence.address}`,
            );
            addressToLatLong[fence.address].mapCircle = circle;
          }
          if (fence.id === this.activeFenceId) {
            circle.setStyle({
              fillOpacity: 0.4,
              color: this.$vuetify.theme.themes.light.primary,
              fillColor: this.$vuetify.theme.themes.light.primary,
              weight: 3,
            });
          } else {
            circle.setStyle({
              fillOpacity: 0.1,
              color: this.$vuetify.theme.themes.light.primary,
              fillColor: this.$vuetify.theme.themes.light.primary,
              weight: 1,
            });
          }
        }
      });
      if (hasFences) {
        const paddingOptions = { paddingTopLeft: [20, 20], paddingBottomRight: [20, 20] };
        map.FitAllLayers({ force: true, animate: false, ...paddingOptions });
      }
    }, 200),
  },

  computed: {
    geofences(): ProductGeoFence[] {
      return this.flight.market.geoSelections.addressList || [];
    },
    openDialog: {
      get(): boolean {
        return this.dialog;
      },
      set(val: boolean): void {
        if (!val) {
          this.$emit('close-dialog');
        }
      },
    },
    selectedMap(): string {
      // '381cd47b-1090-4580-b7bb-7058f89205f5'
      return this.$store.state.map.defaultMap;
    },
    activeGeoFence(): ProductGeoFence {
      if (this.activeFenceId && this.geofences && Array.isArray(this.geofences)) {
        const geoFenceIndex = this.geofences.findIndex(fence => fence.id === this.activeFenceId);
        if (geoFenceIndex !== -1) {
          return this.geofences[geoFenceIndex];
        }
      }
      return { id: '0', unitType: 'Meters', radius: 400, address: '', lat: 0, lon: 0 };
    },
    unitOptions(): { min: number; max: number; step: number } {
      let options;
      switch (this.unitType) {
        case 'Kilometers':
          options = { min: 0.1, max: 40, step: 0.1 };
          break;
        case 'Meters':
          options = { min: 100, max: 40000, step: 100 };
          break;
        case 'Miles':
          options = { min: 0.5, max: 25, step: 0.5 };
          break;
        case 'Feet':
          options = { min: 300, max: 132000, step: 300 };
          break;
      }
      return options;
    },
    unitType: {
      get(): string {
        return this.activeGeoFence?.unitType;
      },
      set(newUnit: string): void {
        const updatedGeoFence = { ...this.activeGeoFence, unitType: newUnit };
        this.updateExistingGeoFence(updatedGeoFence);

        this.setMapView({ fromUnits: true });
      },
    },
    radius: {
      get(): number {
        return this.activeGeoFence?.radius;
      },
      set(newRadius: number): void {
        const updatedGeoFence = { ...this.activeGeoFence, radius: newRadius };
        this.updateExistingGeoFence(updatedGeoFence);

        this.setMapView({ fromRadius: true });
      },
    },
    items(): object[] {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const matches: any[] = [];
      this.locationAutocompleteList.forEach(entry => {
        if (entry.position && entry.address && entry.address.label) {
          matches.push({
            text: entry.address.label,
            address: entry.address.label,
            lat: entry.position.lat,
            lon: entry.position.lng,
          });
          if (!addressToLatLong[entry.address.label]) {
            addressToLatLong[entry.address.label] = {
              lat: entry.position.lat,
              lon: entry.position.lng,
            };
          }
        }
      });
      return matches;
    },
    mapCacheKey(): string {
      return `_geoFenceMap_${this.dataCacheKey}_${this.productId}_${this.flight.id}`;
    },
    mapRenderCacheKey(): string {
      return `_geoFenceMap_${this.dataCacheKey}_${this.isDarkMode ? 'dark' : 'light'}_${this.productId}_${
        this.flight.id
      }`;
    },
    dataCacheKey(): string {
      return `proposal_geofences`;
    },
  },
});

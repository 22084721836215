
import { ConfiguredProduct } from '@/shared/types';
import Vue, { PropType } from 'vue';

import CreativePreview from '@/entities/instantIO/ui/templates/CreativePreview.vue';

export default Vue.extend({
  name: 'CreativesCarouselImage',

  components: { CreativePreview },

  props: {
    product: {
      type: Object as PropType<ConfiguredProduct>,
      required: true,
    },
    creativeNumber: {
      type: Number,
      required: true,
    },
    creativeProp: {
      type: Object,
      required: true,
    },
    isChangeDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data: (): {
    expanded: boolean;
  } => ({
    expanded: true,
  }),

  methods: {
    validLink(link: string): string {
      return link.startsWith('http://') || link.startsWith('https://') ? link : `//${link}`;
    },
    deleteCreative(): void {
      this.$emit('delete-creative');
    },
    updateCreativeProperty(value: string | number, property: string): void {
      if (this?.[property] !== undefined || this?.[property] !== null) {
        this[property] = value;
      }
    },
  },

  computed: {
    url: {
      get(): string {
        return this.creative?.url || '';
      },
      set(link: string): void {
        this.$emit('update-creative', { ...this.creativeProp, url: link });
      },
    },
    postText: {
      get(): string {
        return this.creative?.postText || '';
      },
      set(postText: string): void {
        this.$emit('update-creative', { ...this.creativeProp, postText });
      },
    },
    headline: {
      get(): string {
        return this.creative?.headline || '';
      },
      set(headline: string): void {
        this.$emit('update-creative', { ...this.creativeProp, headline });
      },
    },
    description: {
      get(): string {
        return this.creative?.description || '';
      },
      set(description: string): void {
        this.$emit('update-creative', { ...this.creativeProp, description });
      },
    },
    creativeLink: {
      get(): string {
        return this.creative?.creativeLink || '';
      },
      set(link: string): void {
        this.$emit('update-creative', { ...this.creativeProp, creativeLink: link });
      },
    },
    creative() {
      return this.creativeProp;
    },
  },
});

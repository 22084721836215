
import Vue from 'vue';

export default Vue.extend({
  name: 'ShowInfoCard',

  props: {
    clientName: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    dates: {
      type: Array,
      required: true,
    },
    budget: {
      type: Number,
      default: 0,
    },
    inColumn: {
      type: Boolean,
      default: false,
    },
  },
});


import { ConfiguredCreative, ConfiguredProduct, ProductFlight, FormConfig, FlightConfig } from '@/shared/types';
import Vue, { PropType } from 'vue';

import WrapperWithTooltip from '@/components/WrapperWithTooltip.vue';
import CreativePreview from '../../../entities/instantIO/ui/templates/CreativePreview.vue';
import CreativeCarouselImage from '@/entities/creative/ui/creative-carousel-image.vue';
import { Models } from '@/injectables/tokens';
import { CreativeModelContract, CreativeType } from '@/injectables';

export default Vue.extend({
  name: 'CreativeCard',

  useInjectable: [Models.Creatives],

  components: { WrapperWithTooltip, CreativePreview, CreativeCarouselImage },

  props: {
    product: {
      type: Object as PropType<ConfiguredProduct>,
      required: true,
    },
    creativeConfig: {
      type: Object,
      required: true,
    },
    creativeNumber: {
      type: Number,
      required: true,
    },
    creativeProp: {
      type: Object,
      required: true,
    },
    isChangeDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data: (): {
    expanded: boolean;
  } => ({
    expanded: true,
  }),

  methods: {
    isExpanded(index): boolean {
      return this.expandedIndex === index && this.expanded;
    },
    toggleExpansion(index): void {
      if (this.expandedIndex !== index) {
        this.expandedIndex = index;
        this.expanded = true;
      } else {
        this.expanded = !this.expanded;
      }
    },
    productHasCreative(product): boolean {
      const productCreativeConfig = this.creativeConfigs.find(c => c?.id === product.id);
      return parseInt(productCreativeConfig?.creativesLimit) === 0 || product?.creatives?.length > 0;
    },

    flightDates(flight: ProductFlight): string[] {
      const [start = 'n/a', end = 'n/a'] = [flight.startDate, flight.endDate];
      return [start, end];
    },
    creativeHasProperty(prop: string, label?: string): boolean {
      return this.formConfig.some(c => c.PropertyId === prop && (!label || c.label === label));
    },
    validLink(link: string): string {
      return link.startsWith('http://') || link.startsWith('https://') ? link : `//${link}`;
    },
    deleteCreative(): void {
      this.$emit('delete-creative');
    },
    updateCreativeProperty(value: string | number, property: string): void {
      if (this?.[property]) {
        this[property] = value;
      }
    },
    addCarouselItem(): void {
      this.$emit('add-carousel-item');
    },
    updateImageItem(creative, i) {
      const creatives = this.creative?.creatives?.map(el => el);
      creatives.splice(i, 1, { ...creative });
      this.$emit('update-creative', { ...this.creativeProp, creatives });
    },
    deleteImageItem(i) {
      const creatives = this.creative?.creatives
        ?.filter((_, id) => i !== id)
        ?.map((creative, i) => ({
          ...creative,
          index: i + 1,
        }));
      this.$emit('update-creative', { ...this.creativeProp, creatives });
    },
  },

  computed: {
    showCarousel(): boolean {
      return (
        (this[Models.Creatives] as CreativeModelContract).getCreativeType(this.creativeProp) === CreativeType.Carousel
      );
    },
    formConfig() {
      return this.creativeConfig?.FormConfig || [];
    },
    flights() {
      return this.product.flights;
    },
    productName() {
      return this.product?.name;
    },
    activeProductCreatives(): ConfiguredCreative[] {
      return this.product?.creatives ?? [];
    },
    activeProductCreativeFormConfig(): FormConfig[] {
      if (!this.product?.id || !this.creativeConfigs?.length) return [];
      const correspondingCreative = this.creativeConfigs.find(c => c?.id === this.product.id);
      return correspondingCreative?.FormConfig || [];
    },
    canAddNewCreative(): boolean {
      if (this.isChangeDisabled || !this.product?.id || !this.creativeConfigs?.length) return false;
      const correspondingCreative = this.creativeConfigs.find(c => c?.id === this.product.id);
      return correspondingCreative?.creativesLimit > this.activeProductCreatives.length;
    },
    canAddNewCarouselItem(): boolean {
      return (this.creativeProp?.creatives?.length || 0) < 10;
    },
    productHasFlights(): boolean {
      return Array.isArray(this.flights) && this.flights.length > 0;
    },
    parsedFlights() {
      return this.flights;
    },
    adTypes() {
      const flightId = this.selectedFlights?.[0];
      if (!flightId) return [];
      const flight = this.product?.flights?.find((flight: ProductFlight) => flight.id === flightId);
      if (!flight) return [];
      const flightConfig = this.product?.flightConfigs?.find(
        (flightConfig: FlightConfig) => flightConfig.targetingOption === flight.targetingOption,
      );
      return flightConfig?.adFormatList || [];
    },
    selectedFlights: {
      get(): number[] {
        return (Array.isArray(this.creative.flightIds) ? this.creative.flightIds : [this.creative.flightIds]).filter(Boolean);
      },
      set(val: number[]) {
        const value = (Array.isArray(val) ? val : [val]).filter(Boolean);
        this.$emit('update-creative', {
          ...this.creativeProp,
          flightIds: value,
          creatives: null,
        });
      },
    },
    adType: {
      get(): number {
        return Array.isArray(this.creativeProp?.selectedAdFormatList)
          ? this.creativeProp?.selectedAdFormatList[0]
          : this.creativeProp?.selectedAdFormatList;
      },
      set(val: number[]) {
        const value = Array.isArray(val) ? val : [val];
        this.$emit('update-creative', {
          ...this.creativeProp,
          creatives: null,
          selectedAdFormatList: value,
        });
      },
    },
    url: {
      get(): { label: string; value: string } {
        const foundFormConfig = this.formConfig?.find(c => c.PropertyId === 'url');
        const label = foundFormConfig?.label || 'Landing Page';
        const value = this.creative?.url || '';
        return { label, value };
      },
      set(link: string): void {
        this.$emit('update-creative', { ...this.creativeProp, url: link });
      },
    },
    notes: {
      get(): { label: string; value: string } {
        const foundFormConfig = this.formConfig?.find(c => c.PropertyId === 'notes');
        const label = foundFormConfig?.label || 'Notes';
        const value = this.creative?.notes || '';
        return { label, value };
      },
      set(notes: string): void {
        this.$emit('update-creative', { ...this.creativeProp, notes });
      },
    },
    postText: {
      get(): { label: string; value: string } {
        const foundFormConfig = this.formConfig?.find(c => c.PropertyId === 'postText');
        const label = foundFormConfig?.label || 'Post Text';
        const value = this.creative?.postText || '';
        return { label, value };
      },
      set(postText: string): void {
        this.$emit('update-creative', { ...this.creativeProp, postText });
      },
    },
    headline: {
      get(): { label: string; value: string } {
        const foundFormConfig = this.formConfig?.find(c => c.PropertyId === 'headline');
        const label = foundFormConfig?.label || 'Headline';
        const value = this.creative?.headline || '';
        return { label, value };
      },
      set(headline: string): void {
        this.$emit('update-creative', { ...this.creativeProp, headline });
      },
    },
    description: {
      get(): { label: string; value: string } {
        const foundFormConfig = this.formConfig?.find(c => c.PropertyId === 'description');
        const label = foundFormConfig?.label || 'Description';
        const value = this.creative?.description || '';
        return { label, value };
      },
      set(description: string): void {
        this.$emit('update-creative', { ...this.creativeProp, description });
      },
    },
    socialLink: {
      get(): { label: string; value: string } {
        const foundFormConfig = this.formConfig?.find(c => c.PropertyId === 'socialLink');
        const label = foundFormConfig?.label || 'Social Page URL';
        const value = this.creative?.socialLink || '';
        return { label, value };
      },
      set(link: string): void {
        this.$emit('update-creative', { ...this.creativeProp, socialLink: link });
      },
    },
    creativeLink: {
      get(): { label: string; value: string } {
        const foundFormConfig = this.formConfig?.find(c => c.PropertyId === 'creativeLink');
        const label = foundFormConfig?.label || 'Media';
        const value = this.creative?.creativeLink || '';
        return { label, value };
      },
      set(link: string): void {
        this.$emit('update-creative', { ...this.creativeProp, creativeLink: link });
      },
    },
    customMargin: {
      get(): { label: string; value: number } {
        const foundFormConfig = this.formConfig?.find(c => c.PropertyId === 'customMargin');
        const label = foundFormConfig?.label || 'Custom Margin';
        const value = this.creative?.customMargin || 0;
        return { label, value };
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      set(percent: any): void {
        // is there a max percent?
        if (!isNaN(percent)) {
          const parsed = parseFloat(percent);
          this.$emit('update-creative', { ...this.creativeProp, customMargin: parsed });
        }
      },
    },
    creative() {
      return this.creativeProp;
    },
  },
});

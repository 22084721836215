
import Vue from 'vue';

import WrapperWithTooltip from '@/components/WrapperWithTooltip.vue';
import { Client } from '@/shared/types';
import { Models, Services } from '@/injectables/tokens';
import { ClientServiceContract, NewProposalModelContract, ValidationServiceContract } from '@/injectables';
import DatePickerMenu from '@/components/DatePickerMenu.vue';
import DateRangePickerWithQuickPeriods from '@/components/Proposal/DatePickerWithQuickPeriods.vue';
import { mapState } from 'vuex';
import { Modules } from '@/store';

export default Vue.extend({
  name: 'EditableInfoCard',

  inject: ['$confirm'],

  useInjectable: [Models.NewProposal, Services.Client],

  components: { WrapperWithTooltip, DatePickerMenu, DateRangePickerWithQuickPeriods },
  props: {
    client: {
      type: Object,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    dates: {
      type: Array,
      required: true,
    },
    value: {
      type: Boolean,
      default: false,
    },
    disabledClientSelection: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.getRecentClients();
  },
  watch: {
    nameSearch(val: string): void {
      if (!val) {
        this.$refs.clientSelectDialog.resetValidation();
        this.hasSearched = false;
      }
      if (val?.length >= 3 && this.clientFieldFocused) {
        this.searchClient(val);
      }
    },
  },
  data() {
    return {
      returnedClients: [],
      advertiserUnavailableText: 'Please check with admin to use this advertiser',
      nameSearch: '',
      clientFieldFocused: false,
      hasSearched: false,
      autoKey: 1,
      clientRecommendations: [],
      loadingClients: false,
    };
  },
  computed: {
    ...mapState(Modules.Agency, ['currentAgencyInfo']),
    canCreateClient() {
      return this.currentAgencyInfo.canCreateClient;
    },
    maxProposalLength(): string {
      return this.toDateString((this.newProposalEntity as NewProposalModelContract).maxProposalLengthDays);
    },
    latestProposalStart(): string {
      return this.toDateString((this.newProposalEntity as NewProposalModelContract).maxProposalFutureStartDays);
    },
    clients(): Client[] {
      if (!this.hasSearched || !this.nameSearch) {
        const campaignClient = this.clientRecommendations.find(c => c.id === this.client.id);

        if (!campaignClient && this.client?.id) {
          return [...this.clientRecommendations, this.client];
        }

        return this.clientRecommendations;
      }
      return this.returnedClients;
    },
    rules() {
      const validationService: ValidationServiceContract = this.$container.get(Services.Validation);

      return {
        required: validationService.requiredValidatorFactory(),
        maxLength: validationService.maxLengthValidatorFactory(),
      };
    },
  },
  methods: {
    createNewClient() {
      this.$refs.clientField.blur();
      this.$emit('create-new-client');
    },
    async changeUserConfirmation() {
      return (
        await this.$confirm.show({
          title: 'Change client',
          body: `This will permanently reset all selections. Are you sure?`,
          confirmText: 'Yes',
          cancelText: 'Cancel',
        })
      ).confirmed;
    },
    async changeClient(val) {
      if (val?.name === this.client.name) {
        return;
      }
      const old = this.client;

      if (this.client?.name && !(await this.changeUserConfirmation())) {
        // big workaround
        this.nameSearch = old.name;
        this.$set(this.$refs.clientField, 'lazySearch', old.name);
        this.$set(this.$refs.clientField, 'lazyValue', old);
        this.$set(this.$refs.clientField, 'selectedValue', [old]);
        this.autoKey += 1;

        return;
      }
      if (!val) {
        this.hasSearched = false;
      }

      this.$emit('update:client', val);
    },
    toDateString(days = 100): string {
      return new Date(new Date().setDate(new Date().getDate() + days)).toISOString();
    },
    checkEditable(e, client): void {
      if (client.editable) {
        return;
      } else {
        e.preventDefault();
        e.stopPropagation();
      }
    },
    resetSearch(persist = false): void {
      if (this.$refs?.clientSelectDialog && !persist) {
        this.$refs.clientSelectDialog.reset();
      }
      this.returnedClients = [];
      this.hasSearched = false;
    },
    async searchClient(query: string) {
      this.loadingClients = true;
      const { isErr, unwrap, unwrapErr } = await (this[Services.Client] as ClientServiceContract).search(query);
      this.loadingClients = false;
      if (isErr()) {
        return this.$log('error', 'client/search error', unwrapErr());
      }
      this.hasSearched = true;
      this.returnedClients = unwrap();
    },
    async getRecentClients() {
      const { unwrapOr } = await (this[Services.Client] as ClientServiceContract).getRecentClients();
      this.clientRecommendations = unwrapOr([]);
    },
  },
});

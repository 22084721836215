
import Vue, { PropType } from 'vue';

export enum MButtonVariants {
  Contained = 'contained', // eslint-disable-line no-unused-vars
  Outlined = 'outlined', // eslint-disable-line no-unused-vars
  Text = 'text', // eslint-disable-line no-unused-vars
}

export enum MButtonSize {
  Small = 'small', // eslint-disable-line no-unused-vars
  Medium = 'medium', // eslint-disable-line no-unused-vars
  Large = 'large', // eslint-disable-line no-unused-vars
}

const variants = [MButtonVariants.Contained, MButtonVariants.Outlined, MButtonVariants.Text];

const sizes = [MButtonSize.Small, MButtonSize.Medium, MButtonSize.Large];

export default Vue.extend({
  name: 'm-button',

  props: {
    variant: {
      type: String as PropType<MButtonVariants>,
      validator: (v: MButtonVariants) => variants.includes(v),
      default: () => MButtonVariants.Contained,
    },
    size: {
      type: String as PropType<MButtonSize>,
      validator: (v: MButtonSize) => sizes.includes(v),
      default: () => MButtonSize.Medium,
    },
  },

  computed: {
    isOutlined(): boolean {
      return this.variant === MButtonVariants.Outlined;
    },
    isText(): boolean {
      return this.variant === MButtonVariants.Text;
    },
    isRounded(): boolean {
      return [MButtonVariants.Contained, MButtonVariants.Outlined].includes(this.variant);
    },
    isLarge(): boolean {
      return this.size === MButtonSize.Medium;
    },
    isXLarge(): boolean {
      return this.size === MButtonSize.Large;
    },
    isSmall(): boolean {
      return this.size === MButtonSize.Small;
    },
  },
});

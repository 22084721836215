import { OutputSlide, UnsafeAny } from '@/shared/legacy/classes';
import { OutputLayoutTypeGql } from '@/app/graphql';
export enum SlideGroupType {
  Before = 'BEFORE_PRODUCTS',
  After = 'AFTER_PRODUCTS',
  Products = 'PRODUCTS',
}
export enum ImageKeys {
  UltimateLayout = 'ultimateLayout',
  GenericSecondLayout = 'genericSecondLayout',
  CustomLayout = 'customLayout',
  ClientLogo = 'clientLogo',
}

export interface OutputModelContract {
  /**
   * return sorted array
   * use PropertyId to sorting
   * @param slides array of slides
   */
  sortSlidesByName<T>(slides: ({ name?: string } & T)[]): T[];

  flattenSlides(slides: { id: string; readonly?: boolean; slides?: UnsafeAny[] }[]): OutputSlide[];

  getSlideMarkedByOrderGroup(slides, slideGroup: SlideGroupType): OutputSlide;

  getSlidesByGroup(slides: OutputSlide[], slideGroup: SlideGroupType): OutputSlide[];

  // set flags for slides group
  getMarkedSlides(slides: OutputSlide[], slideGroup: SlideGroupType): OutputSlide[];

  getSlideGroup(slide: OutputSlide): SlideGroupType;

  isDynamicColorOutput(layout: { type: OutputLayoutTypeGql }): boolean;
  isGenericSecond(layout: { type: OutputLayoutTypeGql }): boolean;
  getColorStyles(colorScheme): Record<string, string>;
  getOutputImage(key: ImageKeys): string;
}

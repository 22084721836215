import * as Types from '../_types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type ProductCreativeFragment = {
  creatives?: Array<{
    id: string;
    index?: number | null;
    url?: string | null;
    creativeLink?: string | null;
    customMargin?: number | null;
    omsNumber?: string | null;
    notes?: string | null;
    vastTag?: string | null;
    socialLink?: string | null;
    postText?: string | null;
    headline?: string | null;
    description?: string | null;
    selectedAdFormatList?: Record<string, any> | null;
    flights?: Array<{ id: string } | null> | null;
    creatives?: Array<{
      id: string;
      index?: number | null;
      url?: string | null;
      creativeLink?: string | null;
      customMargin?: number | null;
      omsNumber?: string | null;
      notes?: string | null;
      vastTag?: string | null;
      socialLink?: string | null;
      postText?: string | null;
      headline?: string | null;
      description?: string | null;
    }> | null;
  } | null> | null;
  questionnaire?: Array<{ id: string; index: number; answer: string; questionnaireId: string } | null> | null;
};

export const ProductCreativeFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductCreative' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MediaplannerProduct' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creatives' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'index' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'creativeLink' } },
                { kind: 'Field', name: { kind: 'Name', value: 'customMargin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'omsNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
                { kind: 'Field', name: { kind: 'Name', value: 'vastTag' } },
                { kind: 'Field', name: { kind: 'Name', value: 'socialLink' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'headline' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'selectedAdFormatList' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'flights' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'creatives' },
                  name: { kind: 'Name', value: 'children' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'index' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'creativeLink' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'customMargin' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'omsNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'vastTag' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'socialLink' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postText' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'headline' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'questionnaire' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'index' } },
                { kind: 'Field', name: { kind: 'Name', value: 'answer' } },
                { kind: 'Field', name: { kind: 'Name', value: 'questionnaireId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProductCreativeFragment, unknown>;


import Vue from 'vue';
import { ClientCategory } from '@/shared/types';
import CategoryItem from './categoryItem.vue';
import { Nullable, UnsafeAny } from '@/shared/types';
import { BaseDialog } from '@/shared/ui';
import SearchField from '@/shared/ui/components/search-field.vue';

export default Vue.extend({
  name: 'CategoryPicker',

  data: (): {
    dialog: boolean;
    searchTerm: string;
    selected: string;
    showSearch: boolean;
    REQUIRED_CHARACTERS: number;
  } => ({
    dialog: true,
    searchTerm: '',
    showSearch: true,
    selected: '',
    REQUIRED_CHARACTERS: 1,
  }),

  components: {
    CategoryItem,
    BaseDialog,
    SearchField,
  },

  methods: {
    setCategory(cat: ClientCategory): void {
      if (!cat) this.$emit('set-category', '');
      else {
        this.selected = { name: cat.name, id: cat.id };
        this.$emit('close');
        this.$emit('set-category', { name: cat.name, id: cat.id });
        this.$emit('regional-address', cat.RegionalAddress);
      }
    },
    getGroupedCategories(groupedCats: UnsafeAny, category: ClientCategory): object {
      const catNames = category.name.split('^');
      let current = groupedCats;

      catNames.forEach((name, i, arr) => {
        const last = i === arr.length - 1;
        if (!('categories' in current)) current.categories = {};
        if (last) {
          current.categories[name] = category;
          return groupedCats;
        }
        if (!(name in current.categories)) {
          current.categories[name] = { title: name };
        }
        current = current.categories[name];
      });
      return groupedCats;
    },
  },

  computed: {
    allCategories(): Array<object> {
      return this.$store.state['client'].categories?.list || [];
    },
    isCategoryLoading(): boolean {
      return this.$store.state['client'].loadingCategories;
    },
    filteredCategories() {
      const searchCategories = (cat): Nullable<string> => {
        let matchString = cat.name.toLowerCase();
        if (cat.MyDSKeywords) matchString += cat.MyDSKeywords.toLowerCase();
        if (matchString.indexOf(term) > -1) {
          return cat;
        }
        return null;
      };
      if (this.isCategoryLoading) {
        return [];
      }
      const term = this.searchTerm ? this.searchTerm.toLowerCase() : null;

      if (term && term.length > this.REQUIRED_CHARACTERS - 1) {
        return this.allCategories.filter(searchCategories);
      }

      return this.allCategories;
    },
    results(): Array<object> {
      let results = this.filteredCategories;
      results = results.reduce(this.getGroupedCategories, {});
      return results.categories ? Object.values(results.categories) : [];
    },
  },
});


import Vue from 'vue';
import debounce from 'lodash.debounce';
import MapComponent from '@/shared/legacy/map/map.vue';
import MapPopup from './MapPopup.vue';
import PolygonSelectionDropdowns from '../PolygonSelectionDropdowns.vue';
import DateRangePickerWithQuickPeriods from '@/components/Proposal/DatePickerWithQuickPeriods.vue';
import MapAddressSearch from '../MapAddressSearch.vue';
import BulkLoader from '../BulkLoaderCSV.vue';
import { GeoObj, UnsafeAny } from '@/shared/types';
import { Models, Services } from '@/injectables/tokens';
import {
  GeocodeServiceContract,
  PolygonData,
  PolygonsServiceContract,
  LoggerContract,
  ProductModelContract,
} from '@/injectables';
import { Nullable } from '@/shared/types';
import { uniqBy } from 'lodash';

const addressToLatLong: UnsafeAny = {};

const polygonStore: PolygonData = {
  states: {},
  cities: {},
  dmas: {},
  zips: {},
  counties: {},
  congressionalDistricts: {},
};

const PopupComponentClass = Vue.extend(MapPopup);

let mapMovedTimer, loadPolygonTimer, popupComponentInstance, popup;

interface OptionMapGeoSelection {
  name: string;
  key: string;
}

export default Vue.extend({
  name: 'OptionMap',

  inject: ['showSnackbar'],

  useInjectable: [Services.Geocode, Models.Product, Services.Polygons],

  props: {
    forAvailsStandalone: {
      type: Boolean,
      default: false,
    },
    height: {
      type: Number,
    },
    controlBarWidth: {
      type: Number,
      default: 800,
    },
    basicLegend: {
      type: Boolean,
      default: false,
    },
    displayMapPopup: {
      type: Boolean,
      default: true,
    },
    productId: {
      type: String,
    },
    zips: {
      type: Array,
    },
    states: {
      type: Array,
    },
    cities: {
      type: Array,
    },
    dmas: {
      type: Array,
    },
    counties: {
      type: Array,
    },
    districts: {
      type: Array,
    },
    isChangeDisabled: {
      type: Boolean,
      default: false,
    },
    districtNames: {
      type: Array,
      default: () => [],
    },
    dates: {
      type: Array,
    },
    ottForecasting: {
      type: Boolean,
      default: false,
    },
    hasDateSelector: {
      type: Boolean,
      default: false,
    },
    shortSearchInput: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    MapComponent,
    PolygonSelectionDropdowns,
    MapAddressSearch,
    BulkLoader,
    DateRangePickerWithQuickPeriods,
  },

  data: (): {
    expandedDropdown: string;
    legend: string;
    polygonSelection: { name: string; key: string; searchType: string; available: boolean } | null;
    polygonTypes: { name: string; key: string; searchType: string; available: boolean }[];
    mapReady: boolean;
    polygonsLoading: boolean;
    mapWidth: number;
    showMap: boolean;
    showCSVDialog: boolean;
    mapMoveFor: UnsafeAny;
  } => ({
    showMap: false,
    mapWidth: 600,
    expandedDropdown: '',
    legend: '',
    polygonsLoading: false,
    mapReady: false,
    polygonTypes: [
      { name: 'Zip', key: 'zips', searchType: 'zip', available: false },
      { name: 'City', key: 'cities', searchType: 'city', available: false },
      { name: 'DMA', key: 'dmas', searchType: 'dma', available: false },
      { name: 'State', key: 'states', searchType: 'state', available: false },
      { name: 'County', key: 'counties', searchType: 'county', available: false },
      {
        name: 'CongressionalDistrict',
        key: 'congressionalDistricts',
        searchType: 'congressional',
        available: false,
      },
    ],
    polygonSelection: { name: 'DMA', key: 'dmas', searchType: 'dma', available: true },
    showCSVDialog: false,
    mapMoveFor: null,
  }),

  mounted(): void {
    popupComponentInstance = new PopupComponentClass();

    popupComponentInstance.$mount();

    if (Array.isArray(this.selectedStates) && this.selectedStates.length) {
      this.setPolygonSelection({ name: 'State', key: 'states', available: true });
    } else if (Array.isArray(this.selectedDmas) && this.selectedDmas.length) {
      this.setPolygonSelection({ name: 'Dma', key: 'dmas', available: true });
    } else if (Array.isArray(this.selectedCounties) && this.selectedCounties.length) {
      this.setPolygonSelection({ name: 'county', key: 'counties', available: true });
    } else if (Array.isArray(this.selectedCities) && this.selectedCities.length) {
      this.setPolygonSelection({ name: 'City', key: 'cities', available: true });
    } else if (Array.isArray(this.selectedZips) && this.selectedZips.length) {
      this.setPolygonSelection({ name: 'Zip', key: 'zips', available: true });
    }

    this.$nextTick(() => (this.showMap = true));
  },

  watch: {
    polygonSelection(): void {
      this.RenderMap({ from: 'polygonSelection' });
    },
    shouldRedrawPolygons(val: boolean): void {
      if (val) {
        this.RenderMap();
      }
    },
  },

  computed: {
    selectedMap(): string {
      return this.$store.state.map.defaultMap;
    },
    address(): { address: string; lat: number; lon: number; text: string } {
      return this.$store.state['newProposal'].newProposal.market.address;
    },
    // ok to share this state with main/additional maps?
    shouldRedrawPolygons: {
      get(): boolean {
        return this.$store.state['newProposal'].shouldRedrawPolygons;
      },
      set(status: boolean): void {
        this.$store.dispatch('newProposal/setShouldRedrawPolygons', status);
      },
    },
    allSelectedPolygons(): string[] {
      return [
        ...this.selectedCities,
        ...this.selectedZips,
        ...this.selectedDmas,
        ...this.selectedStates,
        ...this.selectedCounties,
        ...this.selectedDistricts,
      ];
    },
    selectedStates: {
      get(): GeoObj[] {
        return this.states;
      },
      set(stateArr: GeoObj[]): void {
        this.$emit('set-geo-selections', { target: 'states', targetArray: stateArr });
      },
    },
    selectedCities: {
      get(): GeoObj[] {
        return this.cities;
      },
      set(cityArr: GeoObj[]): void {
        this.$emit('set-geo-selections', { target: 'cities', targetArray: cityArr });
      },
    },
    selectedZips: {
      get(): GeoObj[] {
        return this.zips;
      },
      set(zipArr: GeoObj[]): void {
        this.$emit('set-geo-selections', { target: 'zips', targetArray: zipArr });
      },
    },
    selectedCounties: {
      get(): GeoObj[] {
        return this.counties;
      },
      set(countyArr: GeoObj[]): void {
        this.$emit('set-geo-selections', { target: 'counties', targetArray: countyArr });
      },
    },
    selectedDmas: {
      get(): GeoObj[] {
        return this.dmas;
      },
      set(dmaArr: GeoObj[]): void {
        this.$emit('set-geo-selections', { target: 'dmas', targetArray: dmaArr });
      },
    },
    selectedDistricts: {
      get(): GeoObj[] {
        return this?.districts || [];
      },
      set(districtArr: GeoObj[]): void {
        this.$emit('set-geo-selections', { target: 'districts', targetArray: districtArr });
      },
    },
  },

  methods: {
    startBulkUpload(): void {
      if (!this.forAvailsStandalone && this.polygonSelection?.key !== 'zips') {
        this.cleanGeoSelections(['states', 'cities', 'counties', 'dmas']);
        this.$emit('geo-type-changed');
      }

      this.showCSVDialog = true;
    },
    cleanGeoSelections(geoList: string[]): void {
      geoList.forEach(geo => {
        this.updateSelectedPolygons({ polygon: geo, list: [] });
      });
    },
    addZips(zips) {
      const parsedZips = zips.map(z => ({ key: `ZIP_${z}`, name: z }));

      const selectedKeys = new Set(this.selectedZips.map(el => el.key));
      const hasDuplicates = parsedZips.some((z: { key: string; name: string }) => selectedKeys.has(z.key));

      if (hasDuplicates) {
        this.$store.dispatch('showSnackbar', { content: 'Some zips already selected', color: 'warning' });
      }

      const newZipList = uniqBy([...this.selectedZips, ...parsedZips], 'key');
      this.updateSelectedPolygons({ polygon: 'zips', list: newZipList });
      this.setPolygonSelection({ name: 'Zip', key: 'zips', available: true });
    },
    adjustMap: debounce(function (): void {
      const mapRef = `optionMap${this.productId}`;
      this.showMap = false;
      setTimeout(() => {
        this.showMap = true;
      }, 1000);
      setTimeout(() => {
        const map = this.$refs[mapRef]?.Get();
        if (map) {
          map.Redraw();
        }
      }, 1100);
      setTimeout(() => {
        this.debounceLoadPolygons();
      }, 2000);
    }, 500),
    setPolygonSelection(selectionObj: { name: string; key: string; available: boolean }): void {
      this.setPolygonTypeByKey(selectionObj.key, selectionObj.available);
      if (selectionObj?.key === 'cities') {
        this.setMapView({ fromToggleCity: true });
      } else if (selectionObj?.key === 'zips') {
        this.setMapView({ fromToggleZip: true });
      } else if (selectionObj?.key === 'dmas') {
        this.setMapView({ fromToggleDma: true });
      } else if (selectionObj?.key === 'counties') {
        this.setMapView({ fromToggleCounty: true });
      } else if (selectionObj?.key === 'states') {
        this.setMapView({ fromToggleState: true });
      } else if (selectionObj?.key === 'congressionalDistricts') {
        this.setMapView({ fromToggleDistrict: true });
      }
    },
    updateSelectedPolygons({ polygon, list }: { polygon: string; list: UnsafeAny[] }): void {
      if (polygon === 'zips') {
        this.selectedZips = [...list];
      } else if (polygon === 'cities') {
        this.selectedCities = [...list];
      } else if (polygon === 'dmas') {
        this.selectedDmas = [...list];
      } else if (polygon === 'counties') {
        this.selectedCounties = [...list];
      } else if (polygon === 'states') {
        this.selectedStates = [...list];
      } else if (polygon === 'congressionalDistricts') {
        this.selectedDistricts = [...list];
      }
    },
    calculateMapWidth(): void {
      const container = document.getElementById('option-map-container');

      if (container?.clientWidth) {
        this.mapWidth = container.clientWidth;
      }
    },
    isActiveLayer(polygonName: string): boolean {
      return this.polygonSelection?.key === polygonName;
    },
    isExpanded(polygonName: string): boolean {
      return this.expandedDropdown === polygonName;
    },
    setPolygonTypeByKey(key, available) {
      this.polygonSelection = { ...this.polygonTypes.find((x: UnsafeAny) => x.key === key), available };
    },
    togglePolygonSelection(polygonName: string): void {
      this.setPolygonTypeByKey(polygonName, true);
      if (polygonName === 'cities') {
        this.setMapView({ fromToggleCity: true });
      } else if (polygonName === 'zips') {
        this.setMapView({ fromToggleZip: true });
      } else if (polygonName === 'dmas') {
        this.setMapView({ fromToggleDma: true });
      } else if (polygonName === 'states') {
        this.setMapView({ fromToggleState: true });
      } else {
        this.setMapView({ fromToggleDistrict: true });
      }
    },
    resizeMap(): void {
      this.calculateMapWidth();

      this.debounceMapMoved();
    },
    debounceMapMoved(): void {
      if (mapMovedTimer) {
        clearTimeout(mapMovedTimer);
      }
      mapMovedTimer = setTimeout(() => {
        this.onMapMoved();
      }, 400);
    },
    debounceLoadPolygons(options: object): void {
      if (loadPolygonTimer) {
        clearTimeout(loadPolygonTimer);
      }
      loadPolygonTimer = setTimeout(() => {
        this.LoadActivePolygons(options);
      }, 500);
    },
    onMapMoved(): void {
      const mapRef = `optionMap${this.productId}`;

      const map = this.$refs[mapRef]?.Get();

      if (!this.mapReady || !map || !map.host || !map.leaflet) {
        // eslint-disable-next-line no-console
        console.log('map not ready');
        return;
      }

      this.debounceLoadPolygons({ from: 'onMapMoved', userPick: this.mapMoveFor });
    },
    moveToSearchedGeo(val) {
      if (!val?.address && !val?.lat && !val?.lon) {
        return;
      }

      addressToLatLong[val.address] = {
        lat: val.lat,
        lon: val.lon,
      };

      const mapRef = `optionMap${this.productId}`;

      const map = this.$refs[mapRef]?.Get();

      if (!map || !map.host) {
        // eslint-disable-next-line no-console
        console.error('map component did not load correctly', map);
        return;
      }

      this.mapMoveFor = val;

      map.ClearMap();

      this.setMapView({
        fromInit: true,
        forceFetchPolygons: true,
        userPick: val,
      });
    },
    async onMapReady(): Promise<void> {
      const mapRef = `optionMap${this.productId}`;

      const map = this.$refs[mapRef]?.Get();

      if (!map || !map.host) {
        // eslint-disable-next-line no-console
        console.error('map component did not load correctly', map);
        return;
      }

      this.mapReady = true;

      this.calculateMapWidth();

      map.ClearMap();

      map.SetView([40, -100], 7);

      if (this.address?.address && addressToLatLong[this.address.address]) {
        this.setMapView({ fromInit: true });

        setTimeout(() => {
          this.debounceLoadPolygons({ from: 'onMapReady' });
        }, 1000);
      } else if (this.address?.address?.length) {
        const geocodeService: GeocodeServiceContract = this.geocodeService;

        const location = encodeURIComponent(this.address.address);

        const { isErr, unwrapErr, unwrap } = await geocodeService.searchByLocation(location);

        if (isErr()) return this.showSnackbar(unwrapErr().message, 'error');

        unwrap().forEach(entry => {
          if (entry.position && entry.address && entry.address.label) {
            addressToLatLong[entry.address.label] = {
              lat: entry.position.lat,
              lon: entry.position.lng,
            };
          }
        });

        this.setMapView({ fromInit: true });

        setTimeout(() => {
          this.debounceLoadPolygons({ from: 'onMapReady' });
        }, 1000);
      }
    },
    setMapView: debounce(function (
      {
        fromInit,
        fromToggleDistrict,
        fromToggleZip,
        fromToggleCounty,
        fromToggleCity,
        fromToggleDma,
        fromToggleState,
        fromToggleNational,
        forceFetchPolygons = false,
      },
      retries = 10,
    ): void {
      if (fromInit && (!this.address?.address || !addressToLatLong?.[this.address.address])) {
        // eslint-disable-next-line no-console
        console.log('no position for address', this.address);
        return;
      }

      const mapRef = `optionMap${this.productId}`;

      const map = this.$refs[mapRef]?.Get();

      if (!map || !map.host || !map.leaflet) {
        if (retries > 0) {
          setTimeout(() => {
            this.setMapView(
              {
                fromInit,
                fromToggleDistrict,
                fromToggleZip,
                fromToggleCounty,
                fromToggleCity,
                fromToggleDma,
                fromToggleState,
                fromToggleNational,
                forceFetchPolygons,
              },
              retries,
            );
          }, 1000);
          return;
        }
        // eslint-disable-next-line no-console
        console.log('map not ready');
        return;
      }

      const zoomValue = {
        states: 7,
        dmas: 8,
        congressionalDistricts: 9,
        counties: 9,
        cities: 11,
        zips: 12,
      };

      const defaultZoom = 11;

      if (fromInit) {
        const { lat, lon } = addressToLatLong[this.address.address];

        map.SetView([lat, lon], zoomValue[this?.polygonSelection?.key] || defaultZoom);
      }

      if (forceFetchPolygons) {
        this.debounceMapMoved();
      }

      if (fromToggleState) {
        map.SetZoom(6);
      }

      if (fromToggleNational) {
        map.SetZoom(4);
      }

      if (fromToggleDma) {
        map.SetZoom(8);
      }

      if (fromToggleDistrict) {
        map.SetZoom(7);
      }

      if (fromToggleCounty) {
        map.SetZoom(9);
      }

      if (fromToggleCity) {
        map.SetZoom(11);
      }

      if (fromToggleZip) {
        map.SetZoom(12);
      }
    },
    200),
    async LoadActivePolygons(options: object): Promise<void> {
      if (!this.mapReady) {
        setTimeout(() => {
          this.debounceLoadPolygons(options);
        }, 250);
        return;
      }

      const mapRef = `optionMap${this.productId}`;

      const map = this.$refs[mapRef]?.Get();

      if (!map || !map.host || !map.leaflet) {
        // eslint-disable-next-line no-console
        console.error('map component did not load correctly', map);
        return;
      }

      const b = map.GetBounds();

      if (!b) {
        // eslint-disable-next-line no-console
        console.error('map component error', map);
        return;
      }

      const s = b.getSouth();

      const w = b.getWest();

      const n = b.getNorth();

      const e = b.getEast();

      const boundsParameters = {
        minLat: s,
        maxLat: n,
        minLon: w,
        maxLong: e,
        zips: false,
        cities: false,
        dmas: false,
        states: false,
        counties: false,
        congressionalDistricts: false,
      };

      const zoom = map.GetZoom();

      const selectedLayer = this.polygonSelection?.key || 'dmas';

      const allowedPolygons: string[] = [];

      if (zoom <= 8) {
        allowedPolygons.push('states', 'dmas', 'congressionalDistricts');
      } else if (zoom > 8 && zoom < 11) {
        allowedPolygons.push('dmas', 'cities', 'counties', 'congressionalDistricts');
      } else {
        allowedPolygons.push('cities', 'zips');
      }

      if (allowedPolygons.includes(selectedLayer)) {
        boundsParameters[selectedLayer] = true;

        this.polygonsLoading = true;

        this.mapMoveFor = null;

        const { isErr, unwrap, unwrapErr } = await (this.polygonsService as PolygonsServiceContract).getPolygonData(
          boundsParameters,
        );

        this.polygonsLoading = false;

        if (isErr()) {
          const { message = '' } = unwrapErr();

          const logger: LoggerContract = this.$container.get('logger');

          logger.print('error', 'getPolygonData', message);

          return;
        }

        const polygonData = unwrap();

        if (polygonData.states) {
          polygonStore.states = { ...polygonData.states, ...polygonStore.states };
        }

        if (polygonData.cities) {
          polygonStore.cities = { ...polygonData.cities, ...polygonStore.cities };
        }

        if (polygonData.dmas) {
          polygonStore.dmas = { ...polygonData.dmas, ...polygonStore.dmas };
        }

        if (polygonData.congressionalDistricts) {
          polygonStore.congressionalDistricts = {
            ...polygonData.congressionalDistricts,
            ...polygonStore.congressionalDistricts,
          };
        }

        if (polygonData.zips) {
          polygonStore.zips = { ...polygonData.zips, ...polygonStore.zips };
        }

        if (polygonData.counties) {
          polygonStore.counties = { ...polygonData.counties, ...polygonStore.counties };
        }

        // TODO: populate this.availableZips and cie
        this.RenderMap({ ...options, from: 'getPolygonData' });
      }
    },
    RenderLayer(map: UnsafeAny, layerName: string, options: UnsafeAny): void {
      const store = polygonStore[layerName];

      if (!store) {
        // eslint-disable-next-line no-console
        console.error('RenderLayer error, layer unavailable', layerName, polygonStore);
        return;
      }

      const polygonIDs = Object.keys(store);

      if (polygonIDs && polygonIDs.length === 0) {
        return;
      }

      let isActiveLayer = false;

      if (this.polygonSelection?.key === layerName) {
        isActiveLayer = true;
      }

      const layer = map.CreateLayer(layerName);

      const backgroundSelectedPolyStyle = {
        fillOpacity: 0.1,
        color: this.$vuetify.theme.themes.light.primary,
        fillColor: this.$vuetify.theme.themes.light.primary,
        weight: 0.5,
      };

      const selectablePolyStyle = {
        fillOpacity: 0.1,
        color: '#888888',
        fillColor: '#FFFFFF',
        weight: 2,
      };

      const selectableHoverPolyStyle = {
        fillOpacity: 0.5,
        color: '#888888',
        fillColor: '#FFFFFF',
        weight: 2,
      };

      const selectedPolyStyle = {
        fillOpacity: 0.4,
        color: this.$vuetify.theme.themes.light.primary,
        fillColor: this.$vuetify.theme.themes.light.primary,
        weight: 2,
      };

      const selectedHoverStyle = {
        fillOpacity: 0.6,
        color: this.$vuetify.theme.themes.light.primary,
        fillColor: this.$vuetify.theme.themes.light.primary,
        weight: 4,
      };

      let userHasSearchedFor: Nullable<string> = null;

      if (options.userPick) {
        userHasSearchedFor = options.userPick.address || options.userPick.text;
      }

      let userSelectPolygonPicked = false;

      polygonIDs.forEach(key => {
        const p: UnsafeAny = store[key];
        if (!p || !p.name || !p.wkt) {
          // console.log('skipping polygon', layerName, key, 'not valid', polygonStore);
          return;
        }

        let isSelected = this.allSelectedPolygons.some(polygon => {
          return polygon.key === p.key || (polygon?.key === polygon?.name && p?.name === polygon?.name);
        });

        if (!isSelected && !isActiveLayer) {
          // only render selected polygons on background layers
          return;
        }
        const cleanKey = key => {
          if (typeof key !== 'string') return '';
          return key
            .replace('DMA_', '')
            .replace('STAT_', '')
            .replace('CNTY_', '')
            .replace('CITY_', '')
            .replace('ZIP_', '')
            .replace('CONGRESSIONALDIST_', '');
        };
        const polygonKey = cleanKey(p.key);
        // if dma, see it's in the name
        if (!isSelected && userHasSearchedFor === polygonKey && !userSelectPolygonPicked) {
          this.toggleSelectedPolygons(layerName, p);

          isSelected = true;

          userSelectPolygonPicked = true;
        }

        const polygonLayer = map.AddToLayer(layer);

        map.SetWKT(polygonLayer, p.wkt, true);
        if (!isActiveLayer) {
          polygonLayer.setStyle(backgroundSelectedPolyStyle);
          return;
        }

        if (isSelected) {
          polygonLayer.setStyle(selectedPolyStyle);
        } else {
          polygonLayer.setStyle(selectablePolyStyle);
        }

        polygonLayer
          .on('click', () => {
            if (this.isChangeDisabled) return;
            this.toggleSelectedPolygons(layerName, p);
            isSelected = !isSelected;
            if (isSelected) {
              polygonLayer.setStyle(selectedHoverStyle);
            } else {
              polygonLayer.setStyle(selectableHoverPolyStyle);
            }
          })
          .on('mousemove', () => {
            if (isSelected) {
              polygonLayer.setStyle(selectedHoverStyle);
            } else {
              polygonLayer.setStyle(selectableHoverPolyStyle);
            }
            if (popup && this.legend !== p.name) {
              map.ClosePopup(popup);
            }
            this.legend = p.name;
          })
          .on(
            'mousemove',
            debounce(function (e: UnsafeAny) {
              if (this.displayMapPopup && popupComponentInstance && this.legend === p.name) {
                Object.keys(p).forEach(polygonKey => {
                  if (['wkt', 'key'].includes(polygonKey)) return;
                  popupComponentInstance[polygonKey] = p[polygonKey];
                });

                const cleanGeoType = {
                  dmas: 'DMA',
                  cities: 'City',
                  zips: 'Zip',
                  counties: 'County',
                  states: 'State',
                };

                popupComponentInstance.geoType = cleanGeoType[layerName];

                popup = map.CreatePopup(popupComponentInstance?.$el || p.name, e.latlng, {
                  offset: [2, 4],
                  autoPan: false,
                });
              }
            }, 1000).bind(this),
          )
          .on('mouseout', () => {
            if (isSelected) {
              polygonLayer.setStyle(selectedPolyStyle);
            } else {
              polygonLayer.setStyle(selectablePolyStyle);
            }
            this.legend = '';
          });
      });
    },
    RenderMap(options: UnsafeAny): void {
      try {
        options = options || {};

        options.autoZoom = options.autoZoom || false;

        options.retries = options.retries || 10;

        const mapRef = `optionMap${this.productId}`;

        const map = this.$refs[mapRef]?.Get();
        if (!map || !map.host || !map.leaflet) {
          if (options.retries > 0) {
            setTimeout(() => {
              options.retries -= 1;

              this.RenderMap(options);
            }, 1000);
            return;
          }
          // eslint-disable-next-line no-console
          console.error('map component did not load correctly', map);

          return;
        }

        if (this.shouldRedrawPolygons) {
          this.shouldRedrawPolygons = false;
        }

        map.ClearMap();
        // create a layer for each polygon type
        // default order is states > dmas > cities > zips
        // but promote the active selection on top

        ['states', 'dmas', 'counties', 'cities', 'zips', 'congressionalDistricts'].forEach((s: string) => {
          const sel = this.polygonTypes.find((x: UnsafeAny) => x.key === s);

          sel.available = false;

          const store = polygonStore[s];

          // TODO: we should check if we have polygons of this type actually visible in the current view
          if (store && Object.keys(store).length > 0) {
            sel.available = true;
          }
        });

        if (!this.polygonSelection) {
          ['states', 'dmas', 'counties', 'cities', 'zips', 'congressionalDistricts'].forEach((s: string) => {
            const sel = this.polygonTypes.find((x: UnsafeAny) => x.key === s);

            if (sel.available) {
              this.setPolygonTypeByKey(s, true);
            }
          });
        }

        if (!this.polygonSelection) {
          // eslint-disable-next-line no-console
          console.info('RenderMap: no polygon available');
          return;
        }

        switch (this.polygonSelection?.key) {
          case 'states':
            this.RenderLayer(map, 'dmas', options);
            this.RenderLayer(map, 'congressionalDistricts', options);
            this.RenderLayer(map, 'counties', options);
            this.RenderLayer(map, 'cities', options);
            this.RenderLayer(map, 'zips', options);
            this.RenderLayer(map, 'states', options);
            break;
          case 'dmas':
            this.RenderLayer(map, 'states', options);
            this.RenderLayer(map, 'congressionalDistricts', options);
            this.RenderLayer(map, 'counties', options);
            this.RenderLayer(map, 'cities', options);
            this.RenderLayer(map, 'zips', options);
            this.RenderLayer(map, 'dmas', options);
            break;
          case 'congressionalDistricts':
            this.RenderLayer(map, 'states', options);
            this.RenderLayer(map, 'dmas', options);
            this.RenderLayer(map, 'counties', options);
            this.RenderLayer(map, 'cities', options);
            this.RenderLayer(map, 'zips', options);
            this.RenderLayer(map, 'congressionalDistricts', options);
            break;
          case 'counties':
            this.RenderLayer(map, 'states', options);
            this.RenderLayer(map, 'dmas', options);
            this.RenderLayer(map, 'congressionalDistricts', options);
            this.RenderLayer(map, 'cities', options);
            this.RenderLayer(map, 'zips', options);
            this.RenderLayer(map, 'counties', options);
            break;
          case 'cities':
            this.RenderLayer(map, 'states', options);
            this.RenderLayer(map, 'dmas', options);
            this.RenderLayer(map, 'congressionalDistricts', options);
            this.RenderLayer(map, 'counties', options);
            this.RenderLayer(map, 'zips', options);
            this.RenderLayer(map, 'cities', options);
            break;
          case 'zips':
            this.RenderLayer(map, 'states', options);
            this.RenderLayer(map, 'dmas', options);
            this.RenderLayer(map, 'congressionalDistricts', options);
            this.RenderLayer(map, 'counties', options);
            this.RenderLayer(map, 'cities', options);
            this.RenderLayer(map, 'zips', options);
            break;
          default:
            // eslint-disable-next-line no-console
            console.error('RenderMap: unhandled polygon selection', this.polygonSelection);
            break;
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error('SetPolygonLayerCity', err);
      }
    },
    setNationalCampaign(): void {
      this.setPolygonTypeByKey('states', true);

      const list = (this.productEntity as ProductModelContract).statesAndDistrictsNumber.map(({ state, short }) => ({
        name: state,
        key: short,
      }));

      this.setMapView({ fromToggleNational: true });

      if (!this.forAvailsStandalone) {
        this.cleanGeoSelections(['zips', 'cities', 'counties', 'dmas']);
      }

      this.updateSelectedPolygons({ polygon: 'states', list });
    },
    toggleSelectedPolygons(layerName: string, polygon: { name: string; key: string }) {
      if (layerName === 'states') {
        const stateSelection = {
          name: polygon?.name,
          key: polygon?.key,
        };

        const selectedStates = [...this.selectedStates];

        let updatedSelectedStates: OptionMapGeoSelection[] = [];

        if (selectedStates.some(state => state?.key === stateSelection?.key)) {
          updatedSelectedStates = selectedStates.filter(state => state?.key !== stateSelection?.key);
        } else {
          updatedSelectedStates.push(...selectedStates, stateSelection);
        }

        this.selectedStates = updatedSelectedStates;
      } else if (layerName === 'counties') {
        const countySelection = { name: polygon?.name, key: polygon?.key };

        const selectedCounties = [...this.selectedCounties];

        const existingCounty = selectedCounties.find(c => c?.key === countySelection?.key);

        let updatedSelectedCounties: OptionMapGeoSelection[] = [];

        if (existingCounty) {
          updatedSelectedCounties = selectedCounties.filter(county => county?.key !== countySelection?.key);
        } else {
          updatedSelectedCounties.push(...selectedCounties, countySelection);
        }

        this.selectedCounties = updatedSelectedCounties;
      } else if (layerName === 'cities') {
        const citySelection = {
          name: polygon?.name,
          key: polygon?.key,
        };

        const selectedCities = [...this.selectedCities];

        let updatedSelectedCities: OptionMapGeoSelection[] = [];

        if (selectedCities.some(city => city?.key === citySelection?.key)) {
          updatedSelectedCities = selectedCities.filter(city => city?.key !== citySelection?.key);
        } else {
          updatedSelectedCities.push(...selectedCities, citySelection);
        }

        this.selectedCities = updatedSelectedCities;
      } else if (layerName === 'zips') {
        const zipSelection = { name: polygon?.name, key: polygon?.key };

        const selectedZips = [...this.selectedZips];

        const existingZip = selectedZips.find(z => z?.key === zipSelection?.key);

        let updatedSelectedZips: OptionMapGeoSelection[] = [];

        if (existingZip) {
          updatedSelectedZips = selectedZips.filter(zip => zip?.key !== zipSelection?.key);
        } else {
          updatedSelectedZips.push(...selectedZips, zipSelection);
        }

        this.selectedZips = updatedSelectedZips;
      } else if (layerName === 'dmas') {
        const dmaSelection = { name: polygon?.name.toUpperCase(), key: polygon?.key };

        const selectedDmas = [...this.selectedDmas];

        const existingDma = selectedDmas.find(d => d?.key === dmaSelection?.key);

        let updatedSelectedDmas: { name: string; key: string }[] = [];

        if (existingDma) {
          updatedSelectedDmas = selectedDmas.filter(dma => dma?.key !== dmaSelection?.key);
        } else {
          updatedSelectedDmas.push(...selectedDmas, dmaSelection);
        }

        this.selectedDmas = updatedSelectedDmas;
      } else if (layerName === 'congressionalDistricts') {
        const districtSelection = { name: polygon?.name, key: polygon?.key };

        const selectedDistricts = [...this.selectedDistricts];

        const existingDma = selectedDistricts.find(d => d?.key === districtSelection?.key);

        let updatedSelectedDistricts: { name: string; key: string }[] = [];

        if (existingDma) {
          updatedSelectedDistricts = selectedDistricts.filter(district => district?.key !== districtSelection?.key);
        } else {
          updatedSelectedDistricts.push(...selectedDistricts, districtSelection);
        }
        this.selectedDistricts = updatedSelectedDistricts;
      }
    },
  },
});

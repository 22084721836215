
import Vue, { PropType } from 'vue';

import { ConfiguredProduct } from '@/shared/types';

import WrapperWithTooltip from '@/components/WrapperWithTooltip.vue';
import ProductInfoDialog from '@/components/ProductInfoDialog.vue';
import { BaseProductModelContract } from '@/injectables';
import { Models } from '@/injectables/tokens';

export default Vue.extend({
  name: 'ShowProductCard',

  useInjectable: [Models.BaseProduct],

  components: { WrapperWithTooltip, ProductInfoDialog },

  props: {
    items: {
      type: Array as PropType<ConfiguredProduct[]>,
      required: true,
    },
    campaignDuration: {
      type: Number,
    },
  },
  methods: {
    productIcon(product: ConfiguredProduct): string {
      return (this[Models.BaseProduct] as BaseProductModelContract).categoryIconAndColor(product.category as any).icon;
    },
  },
});


import Vue, { PropType } from 'vue';

export enum OldButtonVariants {
  Tonal = 'tonal', // eslint-disable-line no-unused-vars
  Outlined = 'outlined', // eslint-disable-line no-unused-vars
  Text = 'text', // eslint-disable-line no-unused-vars
  Plain = 'plain', // eslint-disable-line no-unused-vars
  General = 'general', // eslint-disable-line no-unused-vars
}

export enum OldButtonSize {
  Small = 'small', // eslint-disable-line no-unused-vars
  Medium = 'medium', // eslint-disable-line no-unused-vars
  Large = 'large', // eslint-disable-line no-unused-vars
}

export enum OldButtonBorderRadius {
  Tile = 'tile', // eslint-disable-line no-unused-vars
  Small = 'small', // eslint-disable-line no-unused-vars
  Medium = 'medium', // eslint-disable-line no-unused-vars
  Large = 'large', // eslint-disable-line no-unused-vars
  Pill = 'pill', // eslint-disable-line no-unused-vars
}

const variants = [
  OldButtonVariants.Tonal,
  OldButtonVariants.Outlined,
  OldButtonVariants.Text,
  OldButtonVariants.Plain,
  OldButtonVariants.General,
];

const sizes = [OldButtonSize.Small, OldButtonSize.Medium, OldButtonSize.Large];

const borderRadiuses = [
  OldButtonBorderRadius.Tile,
  OldButtonBorderRadius.Small,
  OldButtonBorderRadius.Medium,
  OldButtonBorderRadius.Large,
  OldButtonBorderRadius.Pill,
];

export default Vue.extend({
  name: 'p-button',

  props: {
    variant: {
      type: String as PropType<OldButtonVariants>,
      validator: (v: OldButtonVariants) => variants.includes(v),
      default: () => OldButtonVariants.Tonal,
    },
    size: {
      type: String as PropType<OldButtonSize>,
      validator: (v: OldButtonSize) => sizes.includes(v),
      default: () => OldButtonSize.Medium,
    },
    borderRadius: {
      type: String as PropType<OldButtonBorderRadius>,
      validator: (v: OldButtonBorderRadius) => borderRadiuses.includes(v),
      default: () => OldButtonBorderRadius.Medium,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: 'primary',
    },
  },

  computed: {
    isOutlined(): boolean {
      return this.variant === OldButtonVariants.Outlined;
    },
    isText(): boolean {
      return this.variant === OldButtonVariants.Text;
    },
    isRounded(): boolean {
      return OldButtonBorderRadius.Pill === this.borderRadius || this.$attrs.icon !== undefined;
    },
    isXLarge(): boolean {
      return this.size === OldButtonSize.Large;
    },
    isSmall(): boolean {
      return this.size === OldButtonSize.Small;
    },
    isPlain() {
      return this.variant === OldButtonVariants.Plain;
    },
    isTile() {
      return this.borderRadius === OldButtonBorderRadius.Tile;
    },
    isGeneral() {
      return this.variant === OldButtonVariants.General;
    },
    customHeight() {
      if (this.isGeneral) return '40px';
      return null;
    },
    radiusValue(): string {
      if (this.isRounded || this.isTile) return;
      const sizes = {
        [OldButtonBorderRadius.Small]: '2px',
        [OldButtonBorderRadius.Medium]: '4px',
        [OldButtonBorderRadius.Large]: '8px',
      };
      return `border-radius: ${sizes[this.borderRadius]}`;
    },
  },
});

import axios from 'axios';
import { Err, Ok, Result } from '@sniptt/monads/build';
import { injectable, inject } from 'inversify';
import { LogoWithSize } from '@/shared/types';

import { Failure } from '@/injectables/failure';
import { ImageFromStorage, ImageObject } from '@/injectables';

@injectable()
export class UploadService {
  constructor(@inject('axios') private readonly _axios = axios) {}

  async uploadImage(imageFile: File): Promise<Result<ImageObject, Failure>> {
    try {
      const _formData = new FormData();
      _formData.append('file', imageFile);

      const { data } = await this._axios.post('/api/upload/output', _formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (data?.error) {
        return Err({
          message: data.error,
        });
      }

      // if server response with non array, return an error
      if (!Array.isArray(data)) {
        return Err({
          message: `Unexpected response from the server while uploading image: ${imageFile.name}`,
        });
      }

      const imgObj = { img: '', imgWidth: 0, imgHeight: 0 };
      const promises = data.map(async (img: ImageFromStorage) => {
        if (img.key === 'original' && img?.asyncLocation) {
          let test;
          const imageLoadPromise = new Promise(resolve => {
            test = new Image();
            test.src = img.asyncLocation;
            test.onload = resolve;
          });
          imgObj.img = img.asyncLocation;
          await imageLoadPromise;
          imgObj.imgHeight = test.height;
          imgObj.imgWidth = test.width;
        }
      });
      await Promise.all(promises);
      return Ok(imgObj);
    } catch (error) {
      return Err({
        message: `Error while loading image: ${imageFile.name}`,
      });
    }
  }

  async uploadLogo(logo: File): Promise<Result<LogoWithSize, Failure>> {
    try {
      // collect form data with the logo file
      const _formData = new FormData();
      _formData.append('file', logo);

      // make the request with file
      const { data } = await this._axios.post('/api/upload/logo', _formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (data?.error) {
        return Err({
          message: data.error,
        });
      }

      // if server response with non array, return an error
      if (!Array.isArray(data)) {
        return Err({
          message: `Unexpected response from the server while uploading logo: ${logo.name}`,
        });
      }

      // otherwise handle the uploaded file
      const logoObj = { logo: '', avtr: '', logoWidth: 360, logoHeight: 0 };
      const promises = data.map(async (img: ImageFromStorage) => {
        if (img.key === 'avtr') {
          logoObj.avtr = img.url;
        } else if (img.key === 'logo') {
          let test;
          const imageLoadPromise = new Promise(resolve => {
            test = new Image();
            test.src = img.url;
            test.onload = resolve;
          });
          logoObj.logo = img.url;
          await imageLoadPromise;
          logoObj.logoHeight = test.height;
        }
      });
      await Promise.all(promises);
      return Ok(logoObj);
    } catch (error) {
      return Err({
        message: `Couldn't upload logo: ${logo.name}`,
      });
    }
  }
}

import * as Types from '../../../../../app/graphql/_types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type BulkSaveGlobalProductConfigsMutationVariables = Types.Exact<{
  agencyId: Types.Scalars['UUID'];
  globalProductConfigIds: Array<Types.InputMaybe<Types.Scalars['UUID']>> | Types.InputMaybe<Types.Scalars['UUID']>;
  childAgencyIds?: Types.InputMaybe<
    Array<Types.InputMaybe<Types.Scalars['UUID']>> | Types.InputMaybe<Types.Scalars['UUID']>
  >;
}>;

export type BulkSaveGlobalProductConfigsMutation = { result?: boolean | null };

export const BulkSaveGlobalProductConfigsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'bulkSaveGlobalProductConfigs' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'agencyId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'globalProductConfigIds' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'childAgencyIds' } },
          type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'result' },
            name: { kind: 'Name', value: 'bulkSaveGlobalProductConfigs' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'agencyId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'agencyId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'globalProductConfigIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'globalProductConfigIds' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'childAgencyIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'childAgencyIds' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BulkSaveGlobalProductConfigsMutation, BulkSaveGlobalProductConfigsMutationVariables>;

import 'reflect-metadata';
import { injectable } from 'inversify';
import { BaseProductModel } from '../../base-product/implementations';

import { PackageProductModelContract } from '../contracts';
import { UnsafeAny } from '@/shared/legacy/classes';

@injectable()
export class PackageProductModel extends BaseProductModel implements PackageProductModelContract {
  budgetInfo(flights, budget) {
    const flightBudget = flights.reduce((acc, el) => acc + el.budget, 0);

    const unlockedFlightCount = flights.reduce((acc, el) => (el.isLocked || el.isChanged ? acc : acc + 1), 0);
    const unlockedBudget = flights.reduce((acc, el) => (el.isLocked || el.isChanged ? acc : acc + el.budget), 0);

    return { budgetDiff: budget - flightBudget, flightBudget, unlockedFlightCount, unlockedBudget };
  }

  packageBudgetInfo(products) {
    const productsBudget = products.reduce((acc, el) => acc + el.budget, 0);

    const unlockedProductsCount = products.reduce((acc, el) => (el.isLocked ? acc : acc + 1), 0);
    const unlockedBudget = products.reduce((acc, el) => (el.isLocked || el.isChanged ? acc : acc + el.budget), 0);

    return { productsBudget, unlockedProductsCount, unlockedBudget };
  }

  flightsConfigured(product, skipMapValidation = false): boolean {
    if (!this.hasFlights(product)) return false;

    const { flightConfigs = [] } = product;
    const hasPlatform = this.hasPlatform(flightConfigs);
    const isGeofence = this.isGeofence(flightConfigs);

    return product.flights.every(f => {
      return (
        (!isGeofence || this.mapConfigured(f) || skipMapValidation) &&
        (!hasPlatform || f?.selectedPlatform?.length) &&
        f?.targetingOption?.length > 0 &&
        this.flightHasBudget(f)
      );
    });
  }

  productIsConfigured(product): boolean {
    if (!product) return false;
    if (!product.name) return false;

    if (this.isPaidSearchProduct(product)) {
      return this.paidSearchConfigured(product);
    }
    if (this.isXMLProduct(product)) {
      return this.xmlConfiguredProduct(product);
    }

    const skipMapValidation = true;
    return this.flightsConfigured(product, skipMapValidation);
  }

  getUpdatedProduct(product) {
    if (this.isPaidSearchProduct(product) || this.isXMLProduct(product)) return product;
    if (this.fixedRateConfig(product)) {
      const flightsBudget = Math.max(
        product.flights.reduce((acc, el) => acc + el.budget, 0),
        product.minSpend,
      );
      return {
        ...product,
        budget: flightsBudget,
        isLocked: true,
      };
    }

    const { budget, flights } = product;
    if ((flights || []).some(el => this.isFlightRateTypeQuote(el) || this.isFlightRateTypeCpm(el))) {
      const sBudget = flights.filter(el => el.isLocked).reduce((acc, el) => acc + el.budget, 0);

      if (flights.every(el => el.isLocked)) {
        return {
          ...product,
          budget: sBudget,
          isLocked: true,
        };
      }
      return {
        ...product,
        budget: budget < sBudget ? sBudget : budget,
        isLocked: false,
      };
    }

    return product;
  }

  getNewFlight(product) {
    const index = this.getLastIndex(product.flights || []);
    const flight = {
      isLocked: false,
      id: 'temp_' + Date.now(),
      index: index + 1,
      budget: product.budget - this.productFlightTotalAllocated(product),
      rateType: null,
      rate: 0,
      dates: [],
      ...(!this.hasPlatform(product.flightConfigs) && { selectedPlatform: '' }),
    };

    return flight;
  }

  getProductFromProductConfig(productConfig) {
    const configDefaults = product => {
      let propertyToReturn = {};
      if (this.isXMLProduct(product)) {
        propertyToReturn = { broadcastInfo: { link: '', broadcast: [] } };
      } else if (this.isPaidSearchProduct(product)) {
        propertyToReturn = { keywords: { list: [], summary: {}, keymetrics: '' } };
      } else {
        propertyToReturn = { flights: [] };
      }
      return propertyToReturn;
    };

    const {
      name,
      minSpend,
      keyMetric,
      keyMetricMultiplier,
      description,
      type,
      category,
      recommendedBudget,
      flightType,
      fulfillmentMethod,
    } = productConfig;
    return {
      id: 'temp_' + productConfig.id,
      name,
      type,
      category,
      minSpend,
      flightType,
      description,
      keyMetric,
      keyMetricMultiplier,
      budget: 0,
      recommendedBudget,
      fulfillmentMethod,
      minDays: productConfig.minDays || 0,
      productConfigId: productConfig.id,
      index: Date.now(),
      isLocked: false,
      ...(productConfig.rateRangeList ? { rateRangeList: productConfig.rateRangeList } : {}),
      ...configDefaults(productConfig),
      flightConfigs: productConfig.flightConfigs,
      isChanged: false,
      ...this.optional(productConfig.cpcMargin, 'cpcMargin'),
      ...this.optional(productConfig.omsAccountNumber, 'omsAccountNumber'),
      ...this.optional(productConfig.omsName, 'omsName'),
      ...this.optional(productConfig.ioRecipientEmail, 'ioRecipientEmail'),
      ...this.optional(productConfig.slideType, 'slideType'),
      ...this.optional(productConfig.IORecipientEmail, 'IORecipientEmail'),
      ...this.optional(productConfig.rateRangeList, 'rateRangeList'),
      ...this.optional(productConfig.noXmlFlag, 'noXmlFlag'),
    };
  }

  getCleanPackage(product, forProposal = false, removeDsId = false) {
    return {
      ...(product.id ? { id: product.id } : {}),
      name: product.name,
      budget: product.budget || product.recommendedBudget,
      description: product.description,
      products: this.getCleanProducts(product.products, forProposal, removeDsId),
    };
  }

  getRecalculationPreparedFlights(flights, budget) {
    const { unlockedFlightCount, flightBudget } = this.budgetInfo(flights, budget);
    if (flightBudget > budget) {
      return flights.map(flight => ({ ...flight, isChanged: false, isLocked: false }));
    }

    if (unlockedFlightCount === 1) {
      return flights.map(flight => ({ ...flight, isChanged: false }));
    }

    return flights;
  }

  getRecalculationPreparedProducts(products) {
    const { unlockedProductsCount } = this.packageBudgetInfo(products);

    if (unlockedProductsCount === 1) {
      return products.map(product => ({
        ...product,
        isChanged: false,
      }));
    }

    return products.map(product => ({
      ...product,
      ...(product.flights && product.flights.length
        ? { flights: this.getRecalculationPreparedFlights(product.flights, product.budget) }
        : {}),
    }));
  }
}

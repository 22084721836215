import * as Types from '../../../../../app/graphql/_types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type ResetPackageTemplateSlideMutationVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
  agencyId?: Types.InputMaybe<Types.Scalars['UUID']>;
}>;

export type ResetPackageTemplateSlideMutation = {
  resetPackageTemplateSlide?: {
    id?: string | null;
    name?: string | null;
    type?: Types.OutputSlideType | null;
    weight?: number | null;
    layoutId: string;
    visibility?: Types.SlideVisibility | null;
    unsavedChanges?: boolean | null;
    orderBeforeProducts?: boolean | null;
    orderAfterProducts?: boolean | null;
    isProduct?: boolean | null;
    userModified?: boolean | null;
    defaultOrder?: number | null;
    slideSourceId?: string | null;
    slideVariable?: string | null;
    custom?: boolean | null;
    templateVariation?: Record<string, any> | null;
    colors?: Record<string, any> | null;
    images?: Record<string, any> | null;
    textItems?: Record<string, any> | null;
    shapes?: Record<string, any> | null;
    map?: Record<string, any> | null;
    charts?: Record<string, any> | null;
    order?: number | null;
    _id?: string | null;
    defaultSlide?: boolean | null;
    readonly?: boolean | null;
    fromLibrary?: boolean | null;
  } | null;
};

export const ResetPackageTemplateSlideDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'resetPackageTemplateSlide' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'agencyId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resetPackageTemplateSlide' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'agencyId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'agencyId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'weight' } },
                { kind: 'Field', name: { kind: 'Name', value: 'layoutId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'visibility' } },
                { kind: 'Field', name: { kind: 'Name', value: 'unsavedChanges' } },
                { kind: 'Field', name: { kind: 'Name', value: 'orderBeforeProducts' } },
                { kind: 'Field', name: { kind: 'Name', value: 'orderAfterProducts' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isProduct' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userModified' } },
                { kind: 'Field', name: { kind: 'Name', value: 'defaultOrder' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slideSourceId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slideVariable' } },
                { kind: 'Field', name: { kind: 'Name', value: 'custom' } },
                { kind: 'Field', name: { kind: 'Name', value: 'templateVariation' } },
                { kind: 'Field', name: { kind: 'Name', value: 'colors' } },
                { kind: 'Field', name: { kind: 'Name', value: 'images' } },
                { kind: 'Field', name: { kind: 'Name', value: 'textItems' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shapes' } },
                { kind: 'Field', name: { kind: 'Name', value: 'map' } },
                { kind: 'Field', name: { kind: 'Name', value: 'charts' } },
                { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'defaultSlide' } },
                { kind: 'Field', name: { kind: 'Name', value: 'readonly' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fromLibrary' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ResetPackageTemplateSlideMutation, ResetPackageTemplateSlideMutationVariables>;

import { Container } from 'inversify';
import { ActionTree } from 'vuex';
import { AdProductUser } from '@/shared/types';

import { Services } from '@/injectables/tokens';
import { AuthServiceContract, LoggerContract, LocalStorageServiceContract, AgencyServiceContract } from '@/injectables';

import { AuthState } from './state';
import { RootState } from '../root/state';

export const actions = (container: Container): ActionTree<AuthState, RootState> => {
  const authService = container.get<AuthServiceContract>(Services.Auth);
  const localStorageService = container.get<LocalStorageServiceContract>(Services.LocalStorage);
  const logger = container.get<LoggerContract>('logger');

  return {
    resetAuthCheck({ commit, dispatch, state }) {
      commit('AUTH_CHECKED', false);

      if (state.user !== null) {
        dispatch(
          'showSnackbar',
          { content: 'Unable to authorize request, you will need to sign in again', color: 'error' },
          { root: true },
        );
      }
    },
    async getAgencyRights({ commit }) {
      const { isErr, unwrap } = await authService.getUserAgency();
      if (isErr()) {
        return;
      }
      commit('SET_AVAILABLE_AGENCIES', unwrap());
    },
    async checkIfAuth({ commit, dispatch }): Promise<AdProductUser | null> {
      commit('CHECK_AUTH', true);
      let resolve;
      commit(
        'SET_AUTH_PROMISE',
        new Promise(res => {
          resolve = res;
        }),
      );
      const { isErr, unwrapErr, unwrap } = await authService.checkIfAuth();
      commit('AUTH_CHECKED', true);
      commit('CHECK_AUTH', false);
      if (isErr()) {
        logger.print('error', 'store.auth.actions.checkIfAuth', unwrapErr().message);
        commit('AUTH_CHECKED', false);
        return null;
      }
      await dispatch('getAgencyRights');
      resolve();
      const _user = unwrap();
      commit('SET_USER', _user);
      return _user;
    },

    async signoutUser({ commit, state, dispatch }): Promise<void> {
      const token = localStorageService.getItem('mp_c360_refresh');

      const { isErr, unwrapErr } = await authService.signOut(token);

      if (isErr()) {
        logger.print('error', 'store.auth.actions.signoutUser', unwrapErr().message);
        return;
      }

      localStorageService.removeItem('mp_c360_refresh');

      commit('SET_USER', null);
      const alert = {
        type: 'info',
        dismissible: true,
        message: "Please wait while we're logging you out...",
      };
      dispatch('setAlert', alert, { root: true });
    },
  };
};

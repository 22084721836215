var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{style:({ backgroundImage: 'url(' + _vm.exportBackgroundImage + ')', 'z-index': _vm.zIndex }),attrs:{"id":"geo-fence-map-container"}},[_c('map-component',{key:_vm.mapRenderCacheKey,ref:_vm.mapCacheKey,attrs:{"mapId":_vm.mapCacheKey,"mapTilerMapId":_vm.selectedMap,"mapTilerKey":_vm.mapTilerKey,"height":_vm.height,"width":_vm.width,"config":{
      keyboard: false,
      dragging: false,
      zoomControl: false,
      boxZoom: false,
      doubleClickZoom: false,
      scrollWheelZoom: false,
      tap: false,
      touchZoom: false,
      gestureHandling: false,
    }},on:{"ready":_vm.onMapReady}}),_c('div',{staticClass:"mapLegendHost"},[(!_vm.hideLegend && _vm.legend)?_c('div',{staticClass:"mapLegend"},[_vm._v(_vm._s(_vm.legend))]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
import { Module } from 'vuex';
import { state, ClientState } from './state';
import { getters } from './getters';
import { mutations } from './mutations';
import { actions } from './actions';
import { Container } from 'inversify';
import { RootState } from '../root/state';

interface ClientModuleFactoryReturn {
  module: Module<ClientState, RootState>;
}

export const clientModule = (container: Container): ClientModuleFactoryReturn => {
  return {
    module: {
      namespaced: true,
      state: state(),
      getters: getters(container),
      mutations: mutations(),
      actions: actions(container),
    },
  };
};

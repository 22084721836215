
import Vue from 'vue';
import { Proposal, Client } from '@/shared/types';

import ClientRecentProposals from '@/components/Proposals/ClientRecentProposals.vue';
import ClientNotes from '@/components/Clients/ClientNotes.vue';
import PieChart from '@/components/PieChart.vue';
import ClientActiveCampaigns from '@/components/Proposals/ClientActiveCampaigns.vue';
import { Placeholder } from '@/shared/ui';

export default Vue.extend({
  name: 'ClientSummary',

  components: { ClientActiveCampaigns, Placeholder, ClientRecentProposals, PieChart, ClientNotes },

  props: {
    loadingActiveProducts: {
      type: Boolean,
      required: true,
    },
  },

  data: (): {
    saveNotesLoading: boolean;
  } => ({
    saveNotesLoading: false,
  }),

  computed: {
    recentProposals(): Proposal[] {
      if (this.$store.state['proposal'].proposals?.list?.length) {
        return this.$store.state['proposal'].proposals.list;
      }
      return [];
    },
    recentProposalsLoading(): boolean {
      return this.$store.state['proposal'].loadingAllProposals;
    },
    recentProposalsHasData(): boolean {
      return this.recentProposalsLoading || (!this.recentProposalsLoading && this.recentProposals.length);
    },
    proposalsSummary() {
      return this.$store.state['proposal'].proposalsSummaryByClient;
    },
    proposalsSummaryLoading(): boolean {
      return this.$store.state['proposal'].proposalsSummaryByClientLoading;
    },
    proposalsSummaryHasData(): boolean {
      return (
        this.proposalsSummaryLoading ||
        (!this.proposalsSummaryLoading && Object.keys(this.proposalsSummary)?.length > 0)
      );
    },
    activeClient(): Client {
      return this.$store.getters['client/activeClient'];
    },
    clientPurchaseHistory() {
      return this.$store.state['client'].activeClientPurchaseHistory;
    },
  },

  methods: {
    async saveClientNotes(notes: string): Promise<void> {
      this.saveNotesLoading = true;
      await this.$store.dispatch('client/saveClientNotes', { clientId: this.activeClient.id, notes });
      this.saveNotesLoading = false;
    },
  },
});

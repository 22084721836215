
import Vue from 'vue';
import CsvUploader from '@/components/Basic/Uploader/CsvUploader.vue';
import { Models } from '@/injectables/tokens';
import { FilesModelContract, GeoModelContract } from '@/injectables';
import BulkZipList from '@/entities/instantIO/ui/templates/BulkZipList.vue';

export default Vue.extend({
  name: 'bulkLoaderCSV',

  useInjectable: [Models.Files, Models.Geo],

  components: { CsvUploader, BulkZipList },
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    uploadLimit: {
      type: Number,
      default: 500,
    },
  },
  data: (): {
    file: File;
    parsing: boolean;
    zipCodes: Array<string>;
  } => ({
    file: null,
    parsing: false,
    zipCodes: [],
  }),
  watch: {
    invalid(array) {
      if (this.file && array.length !== 0) {
        this.$store.dispatch('showSnackbar', { content: 'Invalid file template', color: 'error' });
      }
    },
  },
  computed: {
    mainText() {
      return '.CSV files only smaller than 2MB. Single column with zip code list. No title cell';
    },
    valid() {
      return this.zipCodes.filter(zipCode => (this[Models.Geo] as GeoModelContract).isZipCodeValid(zipCode));
    },
    invalid() {
      return this.zipCodes.filter(zipCode => !(this[Models.Geo] as GeoModelContract).isZipCodeValid(zipCode));
    },
    isFileLoaded() {
      return this.zipCodes.length !== 0 && this.file;
    },
    addDisabled() {
      return this.parsing || this.invalid.length !== 0 || this.zipCodes.length === 0;
    },
  },
  methods: {
    onFilePicked(file: File): void {
      this.parsing = true;
      this.file = file;
      (this[Models.Files] as FilesModelContract)
        .parseZipsFromFile(file, this.uploadLimit)
        .then(items => {
          this.zipCodes = items;
        })
        .catch(() => {
          this.$store.dispatch('showSnackbar', { content: 'Invalid file template', color: 'error' });
        })
        .finally(() => {
          this.inputKey = Date.now();
          this.parsing = false;
        });
    },
    onClose(): void {
      if (this.parsing) return;
      this.$emit('close-modal');
      this.deleteFile();
    },
    addZips() {
      this.$emit('add-zips', this.zipCodes);
      this.onClose();
    },
    deleteFile() {
      this.file = null;
      this.zipCodes = [];
    },
  },
});

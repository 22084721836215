import { Editor } from '@tiptap/core';
import 'reflect-metadata';
import { TextEditorModelContract, ToolbarItems, TextType, ImageSize } from '@/injectables';
import { injectable } from 'inversify';

import StarterKit from '@tiptap/starter-kit';

// import Heading from '@tiptap/extension-heading';
import Paragraph from '@tiptap/extension-paragraph';
import TextAlign from '@tiptap/extension-text-align';

import Underline from '@tiptap/extension-underline';
import TextStyle from '@tiptap/extension-text-style';
import { Color } from '@tiptap/extension-color';
import ListItem from '@tiptap/extension-list-item';
import Link from '@tiptap/extension-link';
import { CustomHeader, EditableImage } from '../extensions';

@injectable()
export class TextEditorModel implements TextEditorModelContract {
  getTextTypesConfig(): TextType[] {
    return [
      {
        text: 'Header 1',
        value: 'header_1',
      },
      {
        text: 'Header 2',
        value: 'header_2',
      },
      {
        text: 'Header 3',
        value: 'header_3',
      },
      {
        text: 'Text',
        value: 'text',
      },
    ];
  }

  getAlignTypesConfig(): ToolbarItems[] {
    return [
      { icon: 'mdi-format-align-left', value: 'left', shortCut: 'Ctrl+Shift+L', tooltip: 'Left' },
      { icon: 'mdi-format-align-center', value: 'center', shortCut: 'Ctrl+Shift+E', tooltip: 'Center' },
      { icon: 'mdi-format-align-right', value: 'right', shortCut: 'Ctrl+Shift+R', tooltip: 'Right' },
    ];
  }

  getTextStylesConfig(): ToolbarItems[] {
    return [
      { icon: 'mdi-format-bold', value: 'bold', shortCut: 'ctrl+B', tooltip: 'Bold' },
      { icon: 'mdi-format-italic', value: 'italic', shortCut: 'ctrl+I', tooltip: 'Italic' },
      { icon: 'mdi-format-underline', value: 'underline', shortCut: 'ctrl+U', tooltip: 'Underline' },
    ];
  }

  getListItemsConfig(): ToolbarItems[] {
    return [
      { icon: 'mdi-format-list-numbered', value: 'numbered', shortCut: 'ctrl+Shift+7', tooltip: 'Numbered' },
      { icon: 'mdi-format-list-bulleted', value: 'bulleted', shortCut: 'ctrl+Shift+8', tooltip: 'Bulleted' },
    ];
  }

  getInsertionsItemsConfig(): ToolbarItems[] {
    return [
      { icon: 'mdi-file-image-plus-outline', value: 'image', tooltip: 'Add an image' },
      { icon: 'mdi-link-variant-plus', value: 'link', tooltip: 'Add a link' },
    ];
  }

  getImageSizes(): ImageSize[] {
    return [
      { text: '1/6', value: '1' },
      { text: '1/3', value: '2' },
      { text: '1/2', value: '3' },
      { text: '2/3', value: '4' },
      { text: '3/4', value: '5' },
      { text: 'Full', value: '6' },
    ];
  }

  createEditor(content = '', editable = true): Editor {
    return new Editor({
      extensions: [
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        StarterKit as any,
        Underline,
        TextStyle,
        Color,
        ListItem,
        EditableImage.configure({
          HTMLAttributes: {
            class: 'image__m',
          },
        }),
        Link.configure({
          openOnClick: !editable,
        }),
        CustomHeader.configure({
          levels: [1, 2, 3],
        }),
        Paragraph,
        TextAlign.configure({
          types: ['heading', 'paragraph', 'image'],
        }),
      ],
      content,
      editable,
    });
  }
}

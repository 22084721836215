
import Vue, { PropType } from 'vue';
import { Models, Services } from '@/injectables/tokens';
import { SegmentItem, TargetingSegmentsModelContract, UIUtilsServiceContract } from '@/injectables';

export default Vue.extend({
  name: 'DemographicGroup',

  useInjectable: [Services.UI, Models.TargetingSegments],

  props: {
    value: {
      type: Array as PropType<SegmentItem[]>,
      default: () => [],
    },
    items: {
      type: Array as PropType<SegmentItem[]>,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    formatBigText: {
      type: Boolean,
      default: false,
    },
    noDataText: {
      type: String,
      default: 'Any',
    },
    showSubCategory: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    selection: {
      get(): number[] {
        return this.value;
      },
      set(val: number[]) {
        this.$emit('input', val);
      },
    },
    showNoItems(): boolean {
      if (this.readonly) return this.value.length === 0;
      return this.items.length === 0;
    },
  },
  methods: {
    getText(item: SegmentItem) {
      const prefix =
        this.showSubCategory && item.segmentSubCategory
          ? (this[Models.TargetingSegments] as TargetingSegmentsModelContract).getReadableSubCategory(
              item.segmentSubCategory,
            ) + ': '
          : '';

      return prefix + this.formatText(item.name);
    },
    formatText(item: string) {
      if (this.formatBigText) {
        return this.bigNumbersRange(item);
      }
      return item;
    },
    bigNumbersRange(range: string) {
      const [start, end] = range.split('-');
      const firstValue = start ? start.replace(/\D+/g, '') : null;
      const secondValue = end ? end.replace(/\D+/g, '') : null;

      if (!firstValue && !secondValue) {
        return '-';
      }

      if (!secondValue && firstValue) {
        // firstValue is <number>+
        const [minimumValue] = firstValue.split('+');
        return `$${this.bigNumberFormatter(Number(minimumValue))}+`;
      }

      return `
      $${this.bigNumberFormatter(Number(firstValue))}
      - 
      $${this.bigNumberFormatter(Number(secondValue))}`;
    },
    bigNumberFormatter(number: number): string | number {
      return (this.uiUtilsService as UIUtilsServiceContract).bigNumberFormatter(number);
    },
  },
});


import Vue from 'vue';

export default Vue.extend({
  name: 'donut',
  props: {
    height: {
      type: Number,
      default: ((window.innerWidth - 24) * 9) / 16,
    },
    chart: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    cssVars(): any {
      const vars = {
        '--chart-offset': (1 - this.donutChart.config.percent / 100) * this.donutChart.config.circum,
        '--chart-circum': this.donutChart.config.circum,
      };
      return vars;
    },
    donutChart(): {
      style: string;
      config: {
        circum: number;
        percent: number;
        size: number;
        fgColor: string;
        bgColor: string;
        stroke: number;
        radius: number;
        center: number;
      };
    } {
      const {
        percent = 0.55,
        bgColor = '#f2f2f2',
        fgColor = '#ec2d00',
        props = { index: 40, y: '46%', x: '73%', h: '36.5%', w: '36.5%' },
      } = this.chart;
      const heightAsNumber = parseFloat(props.h);
      const responsiveSize = (heightAsNumber / 100) * this.height;
      const center = responsiveSize / 2 + 1;
      const stroke = Math.floor(responsiveSize / 16);
      const radius = responsiveSize - center - stroke;
      const style = `z-index: ${props.index}; position: absolute; top: ${props.y}; left: ${props.x}`;
      const config = {
        circum: responsiveSize * 2.75,
        size: responsiveSize,
        percent,
        fgColor,
        bgColor,
        stroke,
        radius,
        center,
      };
      return { style, config };
    },
  },
});

export interface Route {
  name: string;
  icon: string;
  subItems?: {
    name: string;
  }[];
}

export const routeClient: Route = {
  name: 'Clients',
  icon: 'Users_Group',
};

export const routeAgencyDashboard: Route = {
  name: 'Agency Dashboard',
  icon: 'Settings_Future',
};

export const routeClientWithSubItems: Route = {
  name: 'Clients',
  icon: 'Users_Group',
  subItems: [
    {
      name: 'All Clients',
    },
    {
      name: 'My Clients',
    },
  ],
};

export const routeCampaigns: Route = {
  name: 'Proposals',
  icon: 'Checkbox_Check',
};

export const routeCampaignsWithSubItems: Route = {
  name: 'Campaigns',
  icon: 'Checkbox_Check',
  subItems: [
    {
      name: 'Proposal',
    },
    {
      name: `Instant IO`,
    },
  ],
};

export const routes: {
  summary: Route;
  clients: Route;
  products: Route;
  campaigns: Route;
  tools: Route;
  agencyDashboard?: Route;
} = {
  summary: {
    name: 'Summary',
    icon: 'Chart_Pie',
  },
  clients: routeClientWithSubItems,
  products: {
    name: 'Products',
    icon: 'Qr_Code',
  },
  campaigns: routeCampaigns,
  tools: {
    name: 'Tools',
    icon: 'Slider_03',
    subItems: [
      {
        name: 'Avails Calculator',
      },
      {
        name: 'Keyword Planner',
      },
    ],
  },
};

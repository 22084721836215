
import Vue from 'vue';

import { Models, Services } from '@/injectables/tokens';

import { DateRangePicker } from '@/shared/ui';
import {
  BaseProductModelContract,
  BudgetModelContract,
  CalendarServiceContract,
  FlightModelContract,
  InstantModelContract,
  ValidationServiceContract,
} from '@/injectables';

import MoneyField from '@/shared/ui/components/money-field.vue';

export default Vue.extend({
  name: 'SmartMailerFlight',

  inject: ['showSnackbar'],

  useInjectable: [Services.Calendar, Services.Validation, Models.Flight, Models.Budget],

  props: {
    flight: {
      type: Object,
      required: true,
    },
    flightConfigs: {
      type: Array,
      required: true,
    },
    productMinDays: {
      type: Number,
      default: 0,
    },
    productMaxSpend: {
      type: Number,
      default: 0,
    },
    productMinSpend: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isCostPerProduct: {
      type: Boolean,
      default: false,
    },
  },

  components: { DateRangePicker, MoneyField },

  data(): {
    initialRateValue: number;
    valid: boolean;
    datesMenu: boolean;
    rateFieldKey: number;
  } {
    return {
      initialRateValue: 0,
      valid: true,
      datesMenu: false,
      rateFieldKey: 0,
    };
  },
  mounted(): void {
    this.initialRateValue = this.rate;
  },

  computed: {
    rules() {
      const validationService: ValidationServiceContract = this.validationService;

      return {
        required: validationService.requiredValidatorFactory(),
        requiredMultiple: validationService.maxLengthValidatorFactory(),
        minRange: validationService.minRangeValidatorFactory(),
        minBudget: validationService.minBudgetValidatorFactory(),
      };
    },
    oneThousandDays(): string {
      const [from = new Date()] = this.range;
      return (this.calendarService as CalendarServiceContract).dateAfter(1000, new Date(from)).toISOString();
    },
    budget(): string | number {
      return this.flight.budget;
    },
    targetingOption: {
      get(): string {
        return this.flight.targetingOption;
      },
      set(val: string) {
        const config = this.findConfig(val);

        const newBudget = this.quantity
          ? (this[Models.Budget] as BudgetModelContract).getBudgetFromQuantity(
              this.quantity,
              config.rate,
              config.rateType,
            )
          : config.rate;
        const newFlight = {
          ...this.flight,
          targetingOption: val,
          rateType: config.rateType,
          budget: newBudget,
          rate: config.rate,
          isLocked: true,
          flightCategory: config.flightCategory,
        };
        this.initialRateValue = config.rate;
        this.updateFlight(newFlight);
      },
    },
    flightBudgetLocked() {
      return this.flight.isLocked;
    },
    targetingOptions() {
      return this.flightConfigs.filter(f => f?.targetingOption).map(f => f.targetingOption) || [];
    },
    currentConfig() {
      if (this.targetingOption) {
        return this.findConfig(this.targetingOption);
      }
      return {};
    },
    range: {
      get(): string[] {
        return [this.flight.startDate, this.flight.endDate].filter(Boolean);
      },
      set(val: string[]) {
        const newFlight = { ...this.flight, startDate: val[0], endDate: val[1] };
        this.updateFlight(newFlight);
      },
    },
    rateType() {
      return this.currentConfig?.rateType || this.flightConfigs.map(c => c.rateType)[0] || 'CPM';
    },
    rate: {
      get(): string | number {
        return this.flight.rate;
      },
      set(val: string | number) {
        const newFlight = { ...this.flight, rate: val };
        this.updateFlight(newFlight);
      },
    },
    defaultRate() {
      if (this.currentConfig) {
        return this.currentConfig.rate || 0;
      }
      return 0;
    },
    isAdmin(): boolean {
      return this.$store.getters['auth/isAgencyAdmin'];
    },
    quantity() {
      return (this[Models.Budget] as BudgetModelContract).getQuantityFromBudget(this.budget, this.rate, this.rateType);
    },
  },
  methods: {
    dateFormatter(date: Date) {
      return (this.calendarService as CalendarServiceContract).dateFormatter(date);
    },
    validateRate(e) {
      const value = e?.target?.value;

      const { markupMax: max, markupMin: min } = this.flightConfigs[0];

      const { isErr, unwrapErr, unwrap } = (this.flightEntity as FlightModelContract).validateRate(
        value,
        this.defaultRate,
        this.initialRateValue,
        { min, max },
        this.isAdmin,
      );

      if (isErr()) {
        const { message, rate } = unwrapErr();
        this.showSnackbar(message, 'warning');
        this.rate = rate;
        // TODO: this.rateFieldKey is used as hack to rerender field.
        // Need to rework validate flow with field
        this.rateFieldKey = this.rateFieldKey + 1;
        return;
      }

      this.rate = unwrap();
    },

    updateFlight(flight) {
      this.$emit('update-flight', flight);
    },
    findConfig(val) {
      return this.flightConfigs.find(c => c?.targetingOption === val);
    },
    quantityUpdated(e) {
      const newVal = e.target.value;
      const newBudget = (this[Models.Budget] as BudgetModelContract).getBudgetFromQuantity(
        newVal,
        this.rate,
        this.rateType,
      );
      this.updateFlight({
        ...this.flight,
        budget: newBudget,
      });
    },
  },
});


import Vue from 'vue';
import campaign from '@/pages/campaign';
import { filter as formatDate } from '@/app/filters/format-date';
import format from 'date-fns/format';
// import add from 'date-fns/add';
// import parseISO from 'date-fns/parseISO';
// import sub from 'date-fns/sub';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import BlackoutPeriodEditDialog from '@/widgets/flight/blackout-period-edit-dialog.vue';
import { DarkPeriod, DarkPeriodData, DarkPeriodDataSingle } from '@/shared/types';
import { Models } from '@/injectables/tokens';
import { DateModelContract } from '@/injectables';

export default Vue.extend({
  name: 'advancedOptionsDialog',

  useInjectable: [Models.Date],

  components: { BlackoutPeriodEditDialog },

  data: (): {
    showAdvancedOptionsDialog: boolean;
    abTestFlag: boolean;
    darkPeriodList: DarkPeriod[];
    darkPeriod: {
      data: DarkPeriodData;
      dialog: boolean;
      active: DarkPeriod | null;
      activeData: DarkPeriodDataSingle | null;
    };
  } => ({
    showAdvancedOptionsDialog: false,
    abTestFlag: false,
    darkPeriodList: [],
    darkPeriod: {
      data: [],
      dialog: false,
      active: null,
      activeData: null,
    },
  }),
  props: {
    flight: {
      type: Object,
      required: true,
    },
    isPackageProduct: {
      type: Boolean,
      default: false,
    },
    isChangeDisabled: {
      type: Boolean,
      default: false,
    },
  },

  mounted() {
    this.resetState();
  },

  computed: {
    campaign() {
      return campaign;
    },
    unsavedChange(): boolean {
      return (
        this.abTestFlag !== this.flight?.advancedOptions?.abTestFlag ||
        !isEqual(this.darkPeriodList, this.flight?.advancedOptions?.darkPeriodList)
      );
    },
    minFlightDate(): string {
      return format(new Date(this.flight.startDate), 'yyyy-MM-dd');
    },
    maxFlightDate(): string {
      return format(new Date(this.flight.endDate), 'yyyy-MM-dd');
    },
  },

  methods: {
    getDefaultDataFor(blackout: DarkPeriod): DarkPeriodDataSingle {
      return {
        datePickerOpen: false,
        dates: {
          start: formatDate(blackout.startDate),
          end: formatDate(blackout.endDate),
        },
      };
    },
    resetState(): void {
      this.abTestFlag = this.flight?.advancedOptions?.abTestFlag || false;

      const dateModel: DateModelContract = this[Models.Date];

      const periodsFromFlight = (this.flight?.advancedOptions?.darkPeriodList || []).map((blackout: DarkPeriod) => {
        // Receives ISO string - 2019-09-18T19:00:52Z
        // Subtracting 1 day to show the correct date in the date picker
        // TODO: This was causing issues with blackout period validation. May need to be re-enabled
        // later? Unless Compulse already adds 24hrs? Otherwise it's possible to create a 0
        // seconds long campaign
        // const endDate = sub(parseISO(blackout.endDate), {
        //   days: 1,
        // }).toISOString();
        // Expects ISO date string - 2019-09-18
        return {
          index: blackout.index,
          startDate: dateModel.dateToFormatDateString(blackout.startDate),
          endDate: dateModel.dateToFormatDateString(blackout.endDate),
        };
      });

      this.refreshPeriods(periodsFromFlight);
    },
    openAdvancedOptionsDialog(): void {
      this.resetState();
      this.showAdvancedOptionsDialog = true;
    },
    cancelAdvancedOptions(): void {
      this.showAdvancedOptionsDialog = false;
    },
    saveAdvancedOptions(): void {
      if (this.unsavedChange) {
        const dateModel: DateModelContract = this[Models.Date];
        this.$emit('update', {
          abTestFlag: this.abTestFlag,
          darkPeriodList: this.darkPeriodList.map((blackout: DarkPeriod) => {
            // Receives ISO date string - 2019-09-18
            // Adding 1 day to send the correct date to BE
            // TODO: This was causing issues with blackout period validation. May need to be re-enabled
            // later? Unless Compulse already adds 24hrs? Otherwise it's possible to create a 0
            // seconds long campaign
            // const endDate = add(parseISO(blackout.endDate), {
            //   days: 1,
            // }).toISOString();
            // Expects ISO string - 2019-09-18T19:00:52Z
            return {
              index: blackout.index,
              startDate: dateModel.ISOStringFromFormatDateString(blackout.startDate),
              endDate: dateModel.ISOStringFromFormatDateString(blackout.endDate),
            };
          }),
        });
      }
      this.showAdvancedOptionsDialog = false;
    },
    updateActivePeriod(dates: [string, string]): void {
      /**
       * Make sure we put the smallest date in startDate and the biggest date in endDate, otherwise
       * the intersection check will only work in one direction. Plus BE will return an error
       */
      dates.sort();

      this.darkPeriod.active.startDate = dates[0];
      this.darkPeriod.active.endDate = dates[1];

      this.darkPeriod.activeData.dates.start = formatDate(dates[0]);
      this.darkPeriod.activeData.dates.end = formatDate(dates[1]);
    },
    refreshPeriods(blackouts: DarkPeriod[]): void {
      const localBlackouts = cloneDeep(blackouts);
      const localBlackoutsData: DarkPeriodData = {};

      let newIndex = 0;
      localBlackouts.forEach(blackout => {
        // Index should always be sequential. This breaks after deletion
        newIndex += 1;
        blackout.index = newIndex;

        localBlackoutsData[newIndex] = this.getDefaultDataFor(blackout);
      });

      this.darkPeriodList = localBlackouts;
      this.darkPeriod.data = localBlackoutsData;
    },
    addPeriod(): void {
      const blackouts = this.darkPeriodList;
      const blackout = {
        index: blackouts.length + 1,
        startDate: null,
        endDate: null,
      };
      this.editPeriod(blackout);
    },
    editPeriod(blackout: DarkPeriod): void {
      this.darkPeriod.dialog = true;
      this.darkPeriod.active = cloneDeep(blackout);
      if (this.darkPeriod.data[blackout.index]) {
        this.darkPeriod.activeData = cloneDeep(this.darkPeriod.data[blackout.index]);
      } else {
        this.darkPeriod.activeData = this.getDefaultDataFor(blackout);
      }
    },
    cancelEditPeriod(): void {
      this.darkPeriod.dialog = false;
      this.darkPeriod.active = null;
      this.darkPeriod.activeData = null;
    },
    confirmEditPeriod(): void {
      const blackouts = this.darkPeriodList;
      const actualIndex = blackouts.findIndex(b => b.index === this.darkPeriod.active?.index);
      if (actualIndex !== -1) {
        blackouts[actualIndex] = this.darkPeriod.active;
        this.darkPeriod.data[this.darkPeriod.active.index] = this.darkPeriod.activeData;
      } else {
        blackouts.push(this.darkPeriod.active);
      }
      this.refreshPeriods(blackouts);
      this.cancelEditPeriod();
    },
    deletePeriod(blackout: DarkPeriod): void {
      const blackouts = cloneDeep(this.darkPeriodList);
      const actualIndex = blackouts.findIndex(b => b.index === blackout.index);
      blackouts.splice(actualIndex, 1);
      this.refreshPeriods(blackouts);
    },
  },
});

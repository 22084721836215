import 'reflect-metadata';
import { Err, Ok, Result } from '@sniptt/monads';
import { Failure } from '@/injectables/failure';

import { Service } from '../../../service';
import { ProposalFiltredByType, QuickPeriod, QuickPeriodCustomDates, CalendarServiceContract } from '@/injectables';

import { Proposal, RevenueData, ProposalsSummaryData } from '@/shared/types';
import { injectable } from 'inversify';
import { PaginationOptions } from '@/shared/types/pagination';

import Fuse from 'fuse.js';

import { addSeconds, endOfDay, isAfter, isBefore, startOfDay } from 'date-fns';

import { Filters } from '@/store/root/state';

@injectable()
export class ProposalService extends Service {
  // TODO use injection instead of calendarService argument
  quickPeriods({
    startDate,
    calendarService,
    customDates = 'range',
  }: {
    startDate?: string | Date;
    calendarService: CalendarServiceContract;
    customDates: QuickPeriodCustomDates;
  }): Result<Array<QuickPeriod>, Failure & Array<QuickPeriod>> {
    const { dateAfter, yearAfter, dateBefore, getFirstDayOfNextMonth, addMonthsToDate } = calendarService;

    const initialDate = startDate ? new Date(startDate) : dateAfter(1, new Date());

    const firstDayOfNextMonth = getFirstDayOfNextMonth(new Date());

    const custom = {
      text: `Custom Date${customDates === 'range' ? 's' : ''}`,
      customDates,
    };

    const periods: QuickPeriod[] = ['range', 'single'].includes(customDates) ? [custom] : [];

    const nextDaysPeriods = [30, 60, 90];

    const nextMonthsPeriods = [1, 3, 6];

    nextDaysPeriods.forEach(days => {
      const start = initialDate.toISOString();
      const getEnd = dateAfter(days, initialDate);
      const end = getEnd.toISOString();

      const periodToAdd = {
        text: `${days} Days`,
        value: [start, end],
      };

      periods.push(periodToAdd);
    });

    nextMonthsPeriods.forEach(months => {
      const getEnd = dateBefore(1, addMonthsToDate(firstDayOfNextMonth, months));

      const start = firstDayOfNextMonth.toISOString();

      const end = getEnd.toISOString();

      const periodToAdd = {
        text: 'Next ' + (months > 1 ? months + ' Months' : 'Month'),
        value: [start, end],
      };

      periods.push(periodToAdd);
    });

    periods.push({
      text: '1 Year',
      value: [initialDate.toISOString(), dateBefore(1, yearAfter(1, initialDate)).toISOString()],
    });

    return Ok(periods);
  }

  async search(query: string, filters?: Filters, options?: PaginationOptions): Promise<Result<Proposal[], Failure>> {
    try {
      const { limit } = options || { limit: null };
      const { data } = await this._axios.post('/api/proposals', { ...filters, proposalName: query, limit });
      if (data.error) {
        return Err({
          message: `Error while searching proposals: ${data.error}`,
        });
      }
      return Ok(data);
    } catch (error) {
      return Err({
        message: `Error while searching proposals: ${error}`,
      });
    }
  }

  getScoredProposals(proposals: Proposal[], query: string): Proposal[] {
    const getWhereScoreMost = (scored, score) =>
      scored.filter(x => x.score !== undefined && x.score < score).map(x => x.item);

    const searchTerm = query;
    const options = {
      includeScore: true,
      ignoreLocation: true,
      fieldNormWeight: 1,
      keys: ['name'],
    };

    const resultFuse = new Fuse(proposals, options);

    const scoredArray = resultFuse.search(searchTerm) || [];

    return [...getWhereScoreMost(scoredArray, 0.9)];
  }

  filter(
    {
      startDate: proposalStartDate,
      endDate: proposalEndDate,
      LastModifiedDate: proposalModifiedDate,
      status: proposalStatus,
    }: ProposalFiltredByType,
    filters?: Filters,
  ): boolean {
    const { dates, status } = filters;
    const checkFromDate = (proposalDate, filterFrom): boolean => {
      return !filterFrom || isAfter(new Date(proposalDate), addSeconds(startOfDay(new Date(filterFrom)), -1));
    };
    const checkToDate = (proposalDate, filterTo): boolean => {
      return !filterTo || isBefore(new Date(proposalDate), addSeconds(endOfDay(new Date(filterTo)), 1));
    };
    const filterByStartDate = (): boolean => {
      return checkFromDate(proposalStartDate, dates?.start?.from) && checkToDate(proposalStartDate, dates?.start?.to);
    };
    const filterByEndDate = (): boolean => {
      return checkFromDate(proposalEndDate, dates?.end?.from) && checkToDate(proposalEndDate, dates?.end?.to);
    };
    const filterByModifiedDate = (): boolean => {
      return (
        checkFromDate(proposalModifiedDate, dates?.modified?.from) &&
        checkToDate(proposalModifiedDate, dates?.modified?.to)
      );
    };
    const filterByStatus = (): boolean => (status ? status === proposalStatus : true);
    return filterByStartDate() && filterByEndDate() && filterByModifiedDate() && filterByStatus();
  }

  async getProposalsRevenueData(): Promise<Result<RevenueData, Failure>> {
    try {
      const now = new Date();
      const today = now.toLocaleDateString('en-US');
      const tzoffset = now.getTimezoneOffset();
      const params = {
        today,
        offset: tzoffset,
      };

      const { data } = await this._axios.get('/api/proposals/totalRevenueSummary', { params });

      if (data.error) {
        return Err({
          message: `Unable to fetch revenue data`,
        });
      }

      return Ok(data);
    } catch (error) {
      return Err({
        message: `Unable to fetch revenue data`,
      });
    }
  }

  async getProposalsSummary(from: string, to: string): Promise<Result<ProposalsSummaryData, Failure>> {
    try {
      const params = {
        startMonth: from,
        endMonth: to,
      };

      const { data } = await this._axios.get('/api/proposals/summary', { params });

      if (data.error) {
        return Err({
          message: `Unable to fetch proposals summary`,
        });
      }

      return Ok(data);
    } catch (error) {
      return Err({
        message: `Unable to fetch proposals summary`,
      });
    }
  }
}

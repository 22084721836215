import { injectable } from 'inversify';
import { Err, Ok, Result } from '@sniptt/monads';

import { Category, CategoryServiceContract } from '@/injectables';
import { Failure } from '@/injectables/failure';
import { Service } from '@/injectables/service';

@injectable()
export class CategoryService extends Service implements CategoryServiceContract {
  async getAll(): Promise<Result<Category[], Failure>> {
    try {
      const url = '/api/product/categories';
      const { data } = await this._axios.get(url);
      return Ok(data);
    } catch (error) {
      return Err({
        message: `Error while loading categories, error: ${error}`,
      });
    }
  }
}

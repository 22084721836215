import { LocalStorageServiceContract } from '@/injectables';
import { injectable } from 'inversify';

@injectable()
export class LocalStorageService implements LocalStorageServiceContract {
  setItem(key: string, value: string): void {
    try {
      localStorage.setItem(key, value);
    } catch (error) {
      throw new Error('Failed to set item to localStorage. Message: ' + error);
    }
  }

  getItem(key: string): string | null {
    try {
      return localStorage.getItem(key) ?? null;
    } catch (error) {
      throw new Error('Failed to get item from localStorage. Message: ' + error);
    }
  }

  removeItem(key: string): void {
    try {
      localStorage.removeItem(key);
    } catch (error) {
      throw new Error('Failed to delete item from localStorage. Message: ' + error);
    }
  }
}

import 'reflect-metadata';
import { injectable } from 'inversify';
import { ChartModelContract, Status } from '@/injectables';
import { ProposalStatusData, ProposalStatusMonthObj, UnsafeAny } from '@/shared/types';
import { EChartsOption } from 'echarts/types/dist/shared';

@injectable()
export class ChartModel implements ChartModelContract {
  proposalStatusesToTrack: Status[] = [Status.InProgress, Status.Approved, Status.Sold];

  uniqueElementsFromChartData(grouping: string, chartData: ProposalStatusMonthObj[]): string[] {
    const groupElement = `By${grouping}List`;

    return chartData
      .filter(el => el[groupElement])
      .map(el => el[groupElement])
      .flat()
      .map(el => el[grouping])
      .filter((el, i, a) => a.indexOf(el) === i)
      .map(el => (el.includes('@') ? el.slice(0, el.indexOf('@')) : el)); // return only agency names, or user names without full email
  }

  managementChartLegend({
    chartData,
    activityTracking = false,
    byAgency = false,
  }: {
    chartData: ProposalStatusMonthObj[];
    activityTracking?: boolean;
    byAgency?: boolean;
  }): string[] {
    return activityTracking
      ? this.uniqueElementsFromChartData(byAgency ? 'Agency' : 'User', chartData)
      : this.proposalStatusesToTrack;
  }

  mapRawMonthlyChartDataY({
    chartData,
    byUser = false,
    byCount = false,
    priceToNumberFormatter,
  }: {
    chartData: ProposalStatusMonthObj[];
    priceToNumberFormatter: (price: number) => number;
    byUser?: boolean;
    byCount?: boolean;
  }): EChartsOption[] {
    const defaultLineOptions = {
      type: 'line',
      showSymbol: false,
      animation: true,
      smooth: true,
    };

    const activityTracking = chartData.some(el => el.ByAgencyList);

    const chartDataByType = activityTracking
      ? this.uniqueElementsFromChartData(byUser ? 'User' : 'Agency', chartData)
      : this.proposalStatusesToTrack;

    const result = chartDataByType.map(heading => ({
      ...defaultLineOptions,
      name: heading,
      data: [],
    }));

    chartData.forEach((monthObj: ProposalStatusMonthObj) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
      const { Date = '', Month = '', ...chartHeadings } = monthObj;

      const returnRevenueOrCountObj = (chartDataItem: ProposalStatusData): { value: number; label: UnsafeAny } => {
        const numberOfProposalsObj = {
          value: Number(chartDataItem?.Count || '0'),
          label: {
            show: true,
            color: '#555',
            position: 'top',
          },
        };

        const totalRevenueObj = {
          value: priceToNumberFormatter(chartDataItem?.Revenue || 0),
          label: {
            show: false,
          },
        };

        return !byCount ? numberOfProposalsObj : totalRevenueObj;
      };

      if (activityTracking) {
        if (!byUser) {
          (chartHeadings.ByAgencyList ?? []).forEach(el => {
            const resultIndex = result.findIndex(heading => heading.name === el.Agency);
            result[resultIndex] && result[resultIndex].data.push(returnRevenueOrCountObj(el));
          });
        } else {
          (chartHeadings.ByUserList ?? []).forEach((el: ProposalStatusData) => {
            const resultIndex = result.findIndex(
              heading => heading.name === (el.User.includes('@') ? el.User.slice(0, el.User.indexOf('@')) : el.User),
            );

            result[resultIndex] && result[resultIndex].data.push(returnRevenueOrCountObj(el));
          });
        }
      } else {
        this.proposalStatusesToTrack
          .map(status => chartHeadings[status])
          .forEach((el, i) => {
            result[i].data.push(returnRevenueOrCountObj(el));
          });
      }
    });

    return result;
  }

  mapRawMonthlyChartDataX({
    chartData,
    dateParser,
    dateFormatter,
  }: {
    chartData: ProposalStatusMonthObj[];
    dateParser: (month: string, dateFormat: string, date: Date) => Date;
    dateFormatter: (parsedDate: Date, dateFormat: string) => string;
  }): string[] {
    return chartData.map(monthObj => {
      const parsed = dateParser(monthObj.Month, 'yyyy-MM', new Date());

      if (isNaN(parsed.getTime())) {
        return 'Wrong Date';
      }

      return dateFormatter(parsed, 'MMM, yyyy');
    });
  }

  mapRawDaylyChartDataX({
    chartData,
    dateParser,
    dateFormatter,
  }: {
    chartData: ProposalStatusMonthObj[];
    dateParser: (month: string, dateFormat: string, date: Date) => Date;
    dateFormatter: (parsedDate: Date, dateFormat: string) => string;
  }): string[] {
    return chartData.map(dayObj => {
      const parsed = dateParser(dayObj.Date, 'yyyy-MM-dd', new Date());

      if (isNaN(parsed.getTime())) {
        return 'Wrong Date';
      }

      return dateFormatter(parsed, 'MM/dd');
    });
  }
}

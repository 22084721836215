import { Err, Ok, Result } from '@sniptt/monads/build';
import { ProposalsSummaryData, RevenueData, UnsafeAny } from '@/shared/types';

import { Failure } from '@/injectables/failure';

import { MediaplannerProposalStatus, ProposalOrderBy, SortDirection } from '@/app/graphql/_types';

import { ProposalService as RestProposalService } from './http';
import { addDays } from 'date-fns';

import { arrayRangToObject, mapActiveProductsData } from '../utils';

import {
  GetProposalsDocument,
  GetProposalsQuery,
  GetProposalsQueryVariables,
} from '../graphql/queries/get-all-proposals.generated';
import {
  GetEndingCampaignsDocument,
  GetEndingCampaignsQuery,
  GetEndingCampaignsQueryVariables,
} from '../graphql/queries/get-ending-campaigns.generated';
import {
  PerformanceChartDocument,
  PerformanceChartQuery,
  PerformanceChartQueryVariables,
} from '../graphql/queries/get-performance-chart.generated';
import {
  GetProposalsQueueQueryVariables,
  GetProposalsQueueDocument,
  GetProposalsQueueQuery,
} from '../graphql/queries/get-proposals-queue.generated';
import {
  DeleteProposalDocument,
  DeleteProposalMutation,
  DeleteProposalMutationVariables,
} from '../graphql/mutations/delete-proposal.generated';
import {
  CloneProposalDocument,
  CloneProposalMutation,
  CloneProposalMutationVariables,
} from '../graphql/mutations/clone-proposal.generated';
import { ProposalServiceContract, SummaryByClient, UpdateProposalStatusInput } from '../contracts';
import {
  UpdateProposalStatusDocument,
  UpdateProposalStatusMutation,
  UpdateProposalStatusMutationVariables,
} from '../graphql/mutations/update-proposal-status.generated';
import {
  ActiveProductCategoriesDocument,
  ActiveProductCategoriesQuery,
} from '../graphql/queries/get-acive-products.generated';
import {
  GetProposalByIdDocument,
  GetProposalByIdQuery,
  GetProposalByIdQueryVariables,
} from '../graphql/queries/get-proposal-by-id.generated';
import {
  GetClientCampaignsDocument,
  GetClientCampaignsQuery,
  GetClientCampaignsQueryVariables,
} from '../graphql/queries/get-clients-active-campaigns.generated';
import {
  GetClientProposalsDocument,
  GetClientProposalsQuery,
  GetClientProposalsQueryVariables,
} from '../graphql/queries/get-client-propolsals.generated';
import { SendIoEmailDocument, SendIoEmailQuery, SendIoEmailQueryVariables } from '../graphql/queries/send-io.generated';
import {
  GetSharedProposalOutputDocument,
  GetSharedProposalOutputQuery,
  GetSharedProposalOutputQueryVariables,
} from '../graphql/queries/get-shared-proposal-output.generated';
import {
  SendOrderDocument,
  SendOrderMutation,
  SendOrderMutationVariables,
} from '../graphql/queries/send-order.generated';
import {
  GetProposalCreativesDocument,
  GetProposalCreativesQuery,
  GetProposalCreativesQueryVariables,
} from '../graphql/queries/get-proposal-creatives.generated';

export class ProposalService extends RestProposalService implements ProposalServiceContract {
  _proposalsSortMapper = {
    clientName: ProposalOrderBy.ClientName,
    'client.name': ProposalOrderBy.ClientName,
    name: ProposalOrderBy.Name,
    updatedAgo: ProposalOrderBy.UpdatedAt,
    budget: ProposalOrderBy.Budget,
    startDate: ProposalOrderBy.CampaignStartDate,
    endDate: ProposalOrderBy.CampaignEndDate,
    CampaignEndDate: ProposalOrderBy.CampaignEndDate,
    status: ProposalOrderBy.Status,
    'createdBy.email': ProposalOrderBy.CreatedBy,
  };

  async getShareOutputContext(payload: {
    proposalId: string;
  }): Promise<Result<GetSharedProposalOutputQuery['getPublicProposalOutput'], Failure>> {
    try {
      const { data } = await this._apollo.mutate<GetSharedProposalOutputQuery, GetSharedProposalOutputQueryVariables>({
        mutation: GetSharedProposalOutputDocument,
        variables: {
          proposalId: payload.proposalId,
        },
      });
      return Ok(data.getPublicProposalOutput);
    } catch (error) {
      return Err({ message: "Can't get proposal output at this time" });
    }
  }

  async getEndingCampaigns({
    desc,
    sortBy,
  }: {
    desc: Boolean;
    sortBy: string;
  }): Promise<Result<GetEndingCampaignsQuery['getMediaplannerProposals']['items'], Failure>> {
    try {
      const now = new Date();

      const start = addDays(now, -1).toDateString();
      const end = addDays(now, 31).toDateString();
      const { data, error } = await this._apollo.query<GetEndingCampaignsQuery, GetEndingCampaignsQueryVariables>({
        query: GetEndingCampaignsDocument,
        variables: {
          limit: 5,
          status: [MediaplannerProposalStatus.Sold],
          endDateRange: { start, end },
          orderBy: this._proposalsSortMapper[sortBy] || ProposalOrderBy.CampaignEndDate,
          sortDirection: desc ? SortDirection.Desc : SortDirection.Asc,
        },
      });

      if (error) {
        return Err({
          message: "Can't load ending campaigns at this time.",
        });
      }

      // TODO: need to recheck return null for now but should []
      const proposals = data.getMediaplannerProposals?.items;

      return Ok(proposals);
    } catch (error) {
      return Err({
        message: `Can't load ending campaigns at this time: ${error}`,
      });
    }
  }

  async getAdminQueueProposals({
    desc,
    sortBy,
  }: {
    desc: Boolean;
    sortBy: string;
  }): Promise<Result<GetProposalsQueueQuery['getMediaplannerProposals']['items'], Failure>> {
    try {
      const { data, error } = await this._apollo.query<GetProposalsQueueQuery, GetProposalsQueueQueryVariables>({
        query: GetProposalsQueueDocument,
        variables: {
          limit: 5,
          orderBy: this._proposalsSortMapper[sortBy] || ProposalOrderBy.UpdatedAt,
          sortDirection: desc ? SortDirection.Desc : SortDirection.Asc,
          status: [
            MediaplannerProposalStatus.ClientApproved,
            MediaplannerProposalStatus.UnderReview,
            MediaplannerProposalStatus.SubmittedForReview,
          ],
        },
      });

      if (error) {
        return Err({
          message: "Can't load proposals queue at this time.",
        });
      }

      const proposals = data.getMediaplannerProposals?.items;

      return Ok(proposals);
    } catch (error) {
      return Err({
        message: `Can't load proposals queue at this time: ${error}`,
      });
    }
  }

  async getAllProposals({
    offset,
    limit,
    sortBy,
    desc,
    searchTerm,
    filters = {},
  }: {
    limit: number;
    offset: number;
    desc: Boolean;
    sortBy: string;
    searchTerm: string;
    filters: UnsafeAny;
  }): Promise<Result<GetProposalsQuery['getMediaplannerProposals'], Failure>> {
    try {
      const { start, end, modified } = filters.dates || {};
      const { data, error } = await this._apollo.query<GetProposalsQuery, GetProposalsQueryVariables>({
        query: GetProposalsDocument,
        variables: {
          limit,
          offset,
          orderBy: this._proposalsSortMapper[sortBy] || ProposalOrderBy.UpdatedAt,
          sortDirection: desc ? SortDirection.Desc : SortDirection.Asc,
          ...(searchTerm ? { searchKey: searchTerm } : {}),
          ...(start?.range?.length ? { startDateRange: arrayRangToObject(start.range) } : {}),
          ...(end?.range?.length ? { endDateRange: arrayRangToObject(end.range) } : {}),
          ...(modified?.range?.length ? { updateDateRange: arrayRangToObject(modified.range) } : {}),
          ...(filters?.status?.length ? { status: filters?.status } : {}),
        },
      });

      if (error) {
        return Err({
          message: "Can't load proposals at this time.",
        });
      }

      return Ok(data.getMediaplannerProposals);
    } catch (error) {
      return Err({
        message: `Can't load proposals at this time: ${error}`,
      });
    }
  }

  async getProposalsSummary(from: string, to: string): Promise<Result<ProposalsSummaryData, Failure>> {
    try {
      const { data, error } = await this._apollo.query<PerformanceChartQuery, PerformanceChartQueryVariables>({
        query: PerformanceChartDocument,
        variables: { start: from, end: to },
      });
      if (error) {
        return Err({
          message: 'Unable to fetch proposals summary for active client.',
        });
      }

      return Ok(data);
    } catch (error) {
      return Err({
        message: `Unable to fetch proposals summary for active client: ${error}`,
      });
    }
  }

  async getProposalsRevenueData(): Promise<Result<RevenueData, Failure>> {
    try {
      const { data, error } = await this._apollo.query<ActiveProductCategoriesQuery>({
        query: ActiveProductCategoriesDocument,
      });

      if (error) {
        return Err({
          message: 'Unable to fetch active products.',
        });
      }

      const result = mapActiveProductsData(data.activeProductCategoriesComponent);

      return Ok(result);
    } catch (error) {
      return Err({
        message: `Unable to fetch active products.`,
      });
    }
  }

  async deleteProposal(
    id: string,
  ): Promise<Result<{ message: string; proposal: DeleteProposalMutation['deleteMediaplannerProposal'] }, Failure>> {
    try {
      const { data } = await this._apollo.mutate<DeleteProposalMutation, DeleteProposalMutationVariables>({
        mutation: DeleteProposalDocument,
        variables: { id },
      });

      const result = {
        proposal: data.deleteMediaplannerProposal,
        message: 'Proposal deleted successfully.',
      };

      return Ok(result);
    } catch (error) {
      return Err({
        message: "Can't delete proposal at this time.",
      });
    }
  }

  async cloneExistingProposal(
    proposalPpid: string,
  ): Promise<Result<CloneProposalMutation['duplicateMediaplannerProposal'], Failure>> {
    try {
      const { data } = await this._apollo.mutate<CloneProposalMutation, CloneProposalMutationVariables>({
        mutation: CloneProposalDocument,
        variables: { id: proposalPpid },
      });

      const result = data.duplicateMediaplannerProposal;

      return Ok(result);
    } catch (error) {
      return Err({
        message: "Can't clone proposal at this time.",
      });
    }
  }

  async updateProposalStatus(
    input: UpdateProposalStatusInput,
  ): Promise<Result<UpdateProposalStatusMutation['updateMediaplannerProposalStatus'], Failure>> {
    try {
      const { newStatus, proposalPropertyId } = input;
      const { data } = await this._apollo.mutate<UpdateProposalStatusMutation, UpdateProposalStatusMutationVariables>({
        mutation: UpdateProposalStatusDocument,
        variables: {
          input: {
            id: proposalPropertyId,
            status: newStatus,
          },
        },
      });

      return Ok(data.updateMediaplannerProposalStatus);
    } catch (error) {
      return Err({
        message: "Can't update proposal status at this time.",
      });
    }
  }

  async getProposalByProposalId(
    proposalPropertyId: string,
  ): Promise<Result<GetProposalByIdQuery['getMediaplannerProposal'], Failure>> {
    try {
      const { data, error } = await this._apollo.query<GetProposalByIdQuery, GetProposalByIdQueryVariables>({
        query: GetProposalByIdDocument,
        variables: {
          id: proposalPropertyId,
        },
      });

      if (error) {
        return Err({
          message: "Can't update proposal status at this time.",
        });
      }

      const proposal = data.getMediaplannerProposal;

      return Ok(proposal);
    } catch (error) {
      return Err({
        message: "Can't update proposal status at this time.",
      });
    }
  }

  async getProposalCreatives(
    proposalId: string,
  ): Promise<Result<GetProposalCreativesQuery['getMediaplannerProposal'], Failure>> {
    try {
      const { data, error } = await this._apollo.query<GetProposalCreativesQuery, GetProposalCreativesQueryVariables>({
        query: GetProposalCreativesDocument,
        variables: {
          id: proposalId,
        },
      });

      if (error) {
        return Err({
          message: "Can't get proposal creatives at this time.",
        });
      }

      const proposal = data.getMediaplannerProposal;

      return Ok(proposal);
    } catch (error) {
      return Err({
        message: "Can't get proposal creatives at this time.",
      });
    }
  }

  async getProposalsSummaryByActiveClient(clientId: string): Promise<Result<SummaryByClient[], Failure>> {
    try {
      const { data, error } = await this._apollo.query<GetClientCampaignsQuery, GetClientCampaignsQueryVariables>({
        query: GetClientCampaignsDocument,
        variables: {
          id: clientId,
        },
      });

      if (error) {
        return Err({
          message: "Can't get client campaigns at this time.",
        });
      }

      const result = data.getClientCampaigns.reduce((acc, curr) => {
        if (!acc.length) {
          acc.push({
            TagName: curr.analyticsType,
            CampaignList: [curr],
          });
          return acc;
        }

        for (let i = 0; i < acc.length; i++) {
          if (acc[i] && acc[i].TagName === curr.analyticsType) {
            acc[i].CampaignList.push(curr);
            return acc;
          }
        }

        acc.push({
          TagName: curr.analyticsType,
          CampaignList: [curr],
        });

        return acc;
      }, []);

      return Ok(result as unknown as SummaryByClient[]);
    } catch (error) {
      return Err({
        message: "Can't get client campaigns at this time.",
      });
    }
  }

  async getProposalsByClient({
    clientId,
    offset,
    limit,
    sortBy,
    desc,
    searchTerm,
    filters = {},
  }: {
    clientId: string;
    limit: number;
    offset: number;
    desc: Boolean;
    sortBy: string;
    searchTerm: string;
    filters: UnsafeAny;
  }): Promise<Result<GetClientProposalsQuery['getMediaplannerProposals'], Failure>> {
    try {
      const { start, end, modified } = filters.dates || {};
      const { data, error } = await this._apollo.query<GetClientProposalsQuery, GetClientProposalsQueryVariables>({
        query: GetClientProposalsDocument,
        variables: {
          clientId,
          limit,
          offset,
          orderBy: this._proposalsSortMapper[sortBy] || ProposalOrderBy.UpdatedAt,
          sortDirection: desc ? SortDirection.Desc : SortDirection.Asc,
          ...(searchTerm ? { searchKey: searchTerm } : {}),
          ...(start?.range?.length ? { startDateRange: arrayRangToObject(start.range) } : {}),
          ...(end?.range?.length ? { endDateRange: arrayRangToObject(end.range) } : {}),
          ...(modified?.range?.length ? { updateDateRange: arrayRangToObject(modified.range) } : {}),
          ...(filters?.status?.length ? { status: filters?.status } : {}),
        },
      });

      if (error) {
        return Err({
          message: "Can't load proposals at this time.",
        });
      }

      return Ok(data.getMediaplannerProposals);
    } catch (error) {
      return Err({
        message: `Can't load proposals at this time: ${error}`,
      });
    }
  }

  async sendIO(proposalId: string, email: string): Promise<Result<SendIoEmailQuery['sendIOEmail'], Failure>> {
    try {
      const { data, error } = await this._apollo.query<SendIoEmailQuery, SendIoEmailQueryVariables>({
        query: SendIoEmailDocument,
        variables: {
          proposalId,
          email,
        },
      });

      if (error) {
        return Err({
          message: "Can't send email at this time.",
        });
      }

      const proposal = data.sendIOEmail;

      return Ok(proposal);
    } catch (error) {
      return Err({
        message: "Can't send email at this time.",
      });
    }
  }

  async sendOrder(proposalId: string): Promise<Result<SendOrderMutation['sendProposalToPIO'], Failure>> {
    try {
      const { data } = await this._apollo.mutate<SendOrderMutation, SendOrderMutationVariables>({
        mutation: SendOrderDocument,
        variables: {
          proposalId,
        },
      });

      return Ok(data.sendProposalToPIO);
    } catch (error) {
      return Err({
        message: "Can't send email at this time.",
      });
    }
  }
}


import Vue, { PropType } from 'vue';
import { Models } from '@/injectables/tokens';

import ProductCardTemplate from '@/entities/instantIO/ui/templates/ProductCardTemplate.vue';
import ProductCardColumn from '@/entities/instantIO/ui/templates/ProductCardColumn.vue';
import ProductStatus from '@/entities/instantIO/ui/templates/ProductStatus.vue';
import WrapperWithTooltip from '@/components/WrapperWithTooltip.vue';
import ProductInfoDialog from '@/components/ProductInfoDialog.vue';
import { ConfiguredProduct } from '@/shared/types';
import { BaseProductModelContract, InstantModelContract } from '@/injectables';

export default Vue.extend({
  name: 'ProductIterator',

  useInjectable: [Models.InstantIO, Models.BaseProduct],

  components: { ProductCardTemplate, ProductCardColumn, ProductStatus, WrapperWithTooltip, ProductInfoDialog },
  props: {
    product: {
      type: Object as PropType<ConfiguredProduct>,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    campaignDuration: {
      type: Number,
    },
  },
  computed: {
    productIcon(): string {
      return (this[Models.BaseProduct] as BaseProductModelContract).categoryIconAndColor(this.product.category).icon;
    },
    productKPI() {
      return (this.instantIOEntity as InstantModelContract).productKPI(this.product);
    },
    productKeyMetric() {
      return (this.instantIOEntity as InstantModelContract).productKeyMetric(this.product);
    },
    isSetupConfigured() {
      return (this.instantIOEntity as InstantModelContract).productIsConfigured(this.product);
    },
    isCreativesConfigured() {
      return true;
    },
  },

  methods: {
    getReadableCategory(category) {
      return (this[Models.BaseProduct] as BaseProductModelContract).getReadableCategory(category);
    },
  },
});


import Vue from 'vue';
export default Vue.extend({
  name: 'SlideElementActions',

  props: {
    showDotMenu: {
      type: Boolean,
      required: true,
    },
    // TODO: use list of items from slide model instead of using v-if
    isEditable: {
      type: Boolean,
      required: false,
    },
  },
});


import Vue from 'vue';

import { TextEditorModelContract, TextType, ToolbarItems, ImageSize } from '@/injectables';
import { Models } from '@/injectables/tokens';

import IconColorPicker from '@/components/TextEditor/IconColorPicker.vue';
import IconSelector from '@/components/TextEditor/IconSelector.vue';
import AddUrlDialog from '@/components/TextEditor/AddUrlDialog.vue';
import ImagePicker from '@/components/TextEditor/ImagePicker.vue';

export default Vue.extend({
  name: 'EditorTools',

  useInjectable: [Models.TextEditor],

  components: {
    IconColorPicker,
    IconSelector,
    AddUrlDialog,
    ImagePicker,
  },

  props: {
    activeElement: {
      type: Object,
      required: true,
    },
  },

  data: (): {
    urlDialogOpened: boolean;
    imageDialogOpened: boolean;
  } => ({
    urlDialogOpened: false,
    imageDialogOpened: false,
  }),

  computed: {
    // get items
    textTypes(): TextType[] {
      return (this.textEditorEntity as TextEditorModelContract).getTextTypesConfig();
    },
    alignItems(): ToolbarItems[] {
      return (this.textEditorEntity as TextEditorModelContract).getAlignTypesConfig();
    },
    textStylesItems(): ToolbarItems[] {
      return (this.textEditorEntity as TextEditorModelContract).getTextStylesConfig();
    },
    listsItems(): ToolbarItems[] {
      return (this.textEditorEntity as TextEditorModelContract).getListItemsConfig();
    },
    insertionItems(): ToolbarItems[] {
      return (this.textEditorEntity as TextEditorModelContract).getInsertionsItemsConfig();
    },
    imageSizeItems(): ImageSize[] {
      return (this.textEditorEntity as TextEditorModelContract).getImageSizes();
    },
    // change
    selectedAlign: {
      get(): number {
        const [active] = Object.entries(this.activeElement.align)
          .filter(([, isSelected]) => isSelected)
          .flat();
        return this.alignItems.findIndex(el => el.value === active);
      },
      set(newValue: number) {
        this.$emit('change-align', this.alignItems[newValue].value);
      },
    },
    selectedStyle: {
      get() {
        return this.textStylesItems
          .map((el, i) => (this.activeElement.styles[el.value] ? i : -1))
          .filter(el => el !== -1);
      },
      set(newValue: Array<number>) {
        const changes = [
          ...newValue.filter(el => !this.selectedStyle.includes(el)).map(el => this.textStylesItems[el].value),
          ...this.selectedStyle.filter(el => !newValue.includes(el)).map(el => this.textStylesItems[el].value),
        ];
        this.$emit('change-style', changes);
      },
    },
    selectedTextType: {
      get(): ToolbarItems {
        const [active] = Object.entries(this.activeElement.textType)
          .filter(([, isSelected]) => isSelected)
          .flat();
        return this.textTypes.find(el => el.value === active);
      },
      set(newValue: ToolbarItems) {
        const [type, level] = newValue.value.split('_');
        this.$emit('change-text-type', { type, level: parseInt(level || '0', 10) });
      },
    },
    currentColor: {
      get(): string {
        return this.activeElement.color;
      },
      set(color: string) {
        this.$emit('change-color', color);
      },
    },
    selectedList: {
      get() {
        const [active] = Object.entries(this.activeElement.list)
          .filter(([, isSelected]) => isSelected)
          .flat();
        const index = this.listsItems.findIndex(el => el.value === active);
        return index !== -1 ? index : null;
      },
      set(newValue: number) {
        let listIndex = newValue;
        if (newValue == null) {
          listIndex = this.selectedList;
        }
        this.$emit('set-list', this.listsItems[listIndex].value);
      },
    },
    selectedInsertions: {
      get(): number {
        if (this.activeElement.insertions.link || this.urlDialogOpened) return 1;
        if (this.imageDialogOpened) return 0;
        return -1;
      },
    },
    selectedImageSize: {
      get(): ImageSize {
        return this.imageSizeItems.find(el => el.value === this.activeElement.insertions.imageSize);
      },
      set(newValue: ImageSize) {
        this.$emit('change-image-size', newValue.value);
      },
    },
  },
});

import * as Types from '../../../../../app/graphql/_types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type GetClientPurchaseHistoryQueryVariables = Types.Exact<{
  clientId: Types.Scalars['UUID'];
}>;

export type GetClientPurchaseHistoryQuery = {
  activeClientProductCategoriesComponent: Array<{ count: number; budget: number; category: string }>;
};

export const GetClientPurchaseHistoryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetClientPurchaseHistory' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'clientId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activeClientProductCategoriesComponent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'clientId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                { kind: 'Field', name: { kind: 'Name', value: 'budget' } },
                { kind: 'Field', name: { kind: 'Name', value: 'category' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetClientPurchaseHistoryQuery, GetClientPurchaseHistoryQueryVariables>;

import 'reflect-metadata';

import { Err, Ok, Result } from '@sniptt/monads';
import { Failure } from '@/injectables/failure';

import { injectable } from 'inversify';

import { ProductService as RestProductService } from './http';

import { GetProductResult, MinimizedProduct } from '@/shared/types';
import {
  GetProductsDocument,
  GetProductsQuery,
  GetProductsQueryVariables,
} from '../graphql/queries/get-products.generated';
import {
  GetGlobalProductsDocument,
  GetGlobalProductsQuery,
  GetGlobalProductsQueryVariables,
} from '../graphql/queries/get-global-products.generated';
import {
  HideMediaplannerProductDocument,
  HideMediaplannerProductMutation,
  HideMediaplannerProductMutationVariables,
} from '../graphql/mutations/hide-product.generated';
import { formattedFlightConfig, formattedProductOption, mergeProduct } from '../utils';
import { GeoSelection, ProductServiceContract } from '../contracts';
import {
  GetProductConfigByIdDocument,
  GetProductConfigByIdQuery,
  GetProductConfigByIdQueryVariables,
} from '../graphql/queries/get-product-config-by-id.generated';
import {
  UpdateProductConfigMutation,
  UpdateProductConfigDocument,
  UpdateProductConfigMutationVariables,
} from '../graphql/mutations/update-product-config.generated';
import {
  CreateProductConfigDocument,
  CreateProductConfigMutation,
  CreateProductConfigMutationVariables,
} from '../graphql/mutations/create-product-config.generated';
import {
  RecalculateProductsBudgetDocument,
  RecalculateProductsBudgetQuery,
  RecalculateProductsBudgetQueryVariables,
} from '../graphql/queries/recalculate-products-budgets.generated';
import { ProductConfigType, ProductSlideType, SolutionInput, Scalars, ProductConfigCategory } from '@/app/graphql';
import {
  GetProductConfigsDocument,
  GetProductConfigsQuery,
  GetProductConfigsQueryVariables,
} from '../graphql/queries/get-product-configs.generated';
import {
  GetProductAndPackageConfigsDocument,
  GetProductAndPackageConfigsQuery,
  GetProductAndPackageConfigsQueryVariables,
} from '../graphql/queries/get-product-and-package-configs.generated';
import {
  GetXandrOttAvailsDocument,
  GetXandrOttAvailsQuery,
  GetXandrOttAvailsQueryVariables,
} from '../graphql/queries/get-avails.generated';
import {
  GetProductConfigByTypeDocument,
  GetProductConfigByTypeQuery,
  GetProductConfigByTypeQueryVariables,
} from '../graphql/queries/get-product-config-by-type.generated';
import {
  DeleteMediaplannerProductDocument,
  DeleteMediaplannerProductMutation,
  DeleteMediaplannerProductMutationVariables,
} from '../graphql/mutations/delete-product.generated';
import {
  UpdateProductConfigWithChildrenDocument,
  UpdateProductConfigWithChildrenMutation,
  UpdateProductConfigWithChildrenMutationVariables,
} from '@/injectables/services/product/graphql/mutations/update-product-config-with-children.generated';
import {
  CreateProductConfigWithChildrenDocument,
  CreateProductConfigWithChildrenMutation,
  CreateProductConfigWithChildrenMutationVariables,
} from '@/injectables/services/product/graphql/mutations/create-product-config-with-children.generated';
import {
  DeleteProductConfigWithChildrenDocument,
  DeleteProductConfigWithChildrenMutation,
  DeleteProductConfigWithChildrenMutationVariables,
} from '@/injectables/services/product/graphql/mutations/delete-product-config-with-children.generated';
import {
  BulkSaveGlobalProductConfigsDocument,
  BulkSaveGlobalProductConfigsMutation,
  BulkSaveGlobalProductConfigsMutationVariables,
} from '@/injectables/services/product/graphql/mutations/bulk-save-global-product-configs.generated';
@injectable()
export class ProductService extends RestProductService implements ProductServiceContract {
  // TODO: move into another apropriate service
  async getOTTAvails(input: {
    zips: GeoSelection[];
    congressionalDistricts: GeoSelection[];
    counties: GeoSelection[];
    dmas: GeoSelection[];
    states: GeoSelection[];
    cities: GeoSelection[];
    segments?: string[];
    startDate: string;
    endDate: string;
  }): Promise<Result<GetXandrOttAvailsQuery['getXandrOTTAvails'], Failure>> {
    try {
      const preparedInput = {
        ...input,
        zips: input?.zips?.map(z => z?.key?.replace(/ZIP_/g, '')),
        counties: input?.counties?.map(c => c?.key?.replace(/CNTY_/g, '')),
        dmas: input?.dmas?.map(d => d?.key?.replace(/DMA_/g, '')),
        states: input?.states?.map(s => s?.key?.replace(/STAT_/g, '')),
        cities: input?.cities?.map(c => c?.key.replace(/CITY_/g, '')),
        congressionalDistricts: input?.congressionalDistricts?.map(cd => cd?.key),
      };
      const { data, error } = await this._apollo.query<GetXandrOttAvailsQuery, GetXandrOttAvailsQueryVariables>({
        query: GetXandrOttAvailsDocument,
        variables: { input: preparedInput },
      });

      if (error) {
        return Err({
          message: "Can't get avails at this time.",
        });
      }
      const { getXandrOTTAvails } = data;

      return Ok(getXandrOTTAvails);
    } catch (error) {
      return Err({
        message: `Can't get avails at this time: ${error}`,
      });
    }
  }

  async getProducts({
    agencyPropertyId,
    level,
  }: {
    agencyPropertyId: string;
    level: string;
  }): Promise<Result<GetProductResult, Failure>> {
    try {
      const isAgency = level.toLowerCase() === 'agency';
      if (isAgency) {
        return this.getAgencyProducts(agencyPropertyId);
      }
      return this.getGlobalProducts();
    } catch (error) {
      return Err({
        message: `Can't get products at this time: ${error}`,
      });
    }
  }

  async getGlobalProducts(): Promise<Result<GetProductResult, Failure>> {
    try {
      const { data, error } = await this._apollo.query<GetGlobalProductsQuery, GetGlobalProductsQueryVariables>({
        query: GetGlobalProductsDocument,
      });

      if (error) {
        return Err({
          message: "Can't get products at this time.",
        });
      }
      const { products } = data;

      return Ok({ CanHavePackages: false, products });
    } catch (error) {
      return Err({
        message: `Can't get products at this time: ${error}`,
      });
    }
  }

  async getAgencyProducts(id: string): Promise<Result<GetProductResult, Failure>> {
    try {
      const { data, error } = await this._apollo.query<GetProductsQuery, GetProductsQueryVariables>({
        query: GetProductsDocument,
        variables: { id },
      });

      if (error) {
        return Err({
          message: "Can't get products at this time.",
        });
      }
      const { CanHavePackages, products, packages } = data;

      return Ok({
        CanHavePackages: CanHavePackages?.canHavePackages || false,
        products: [...products, ...packages],
      });
    } catch (error) {
      return Err({
        message: `Can't get products at this time: ${error}`,
      });
    }
  }

  async toggleHideProduct(productId: string): Promise<Result<MinimizedProduct, Failure>> {
    try {
      const { data } = await this._apollo.mutate<
        HideMediaplannerProductMutation,
        HideMediaplannerProductMutationVariables
      >({
        mutation: HideMediaplannerProductDocument,
        variables: { id: productId },
      });

      return Ok(data.mediaplannerProductConfigToggleVisibility);
    } catch (error) {
      return Err({
        message: "Can't update product at this time.",
      });
    }
  }

  async getProductConfigById(id: string, isGlobal): Promise<Result<any, Failure>> {
    try {
      const { data } = await this._apollo.query<GetProductConfigByIdQuery, GetProductConfigByIdQueryVariables>({
        query: GetProductConfigByIdDocument,
        variables: { id, agencyId: isGlobal ? null : undefined },
      });

      return Ok(data.product);
    } catch (error) {
      return Err({
        message: "Can't get product at this time.",
      });
    }
  }

  async updateProductConfig(
    product,
    agencyId,
  ): Promise<Result<UpdateProductConfigMutation['updatedProduct'], Failure>> {
    try {
      const agencyObject = agencyId && agencyId !== 'Global' ? {} : { agencyId: null };
      const flightConfigs: UpdateProductConfigMutationVariables['input']['flightConfigs'] =
        product.targetingOptions.map(option => ({
          id: option.id,
          ...formattedFlightConfig(option, product),
        }));
      const input: UpdateProductConfigMutationVariables['input'] = {
        ...agencyObject,
        id: product.id,
        ...formattedProductOption(product),
        isHidden: product.isHidden,
        flightConfigs,
      };

      const { data } = await this._apollo.mutate<UpdateProductConfigMutation, UpdateProductConfigMutationVariables>({
        mutation: UpdateProductConfigDocument,
        variables: { input },
      });

      return Ok(data.updatedProduct);
    } catch (error) {
      return Err({
        message: "Can't update product at this time.",
      });
    }
  }

  async updateProductConfigWithChildren(
    product,
    agencyId,
    childAgencyIds: string[] = [],
    replace = false,
  ): Promise<Result<UpdateProductConfigWithChildrenMutation['updatedProduct'], Failure>> {
    try {
      const agencyObject = agencyId && agencyId !== 'Global' ? {} : { agencyId: null };
      const flightConfigs: UpdateProductConfigMutationVariables['input']['flightConfigs'] =
        product.targetingOptions.map(option => ({
          id: option.id,
          ...formattedFlightConfig(option, product),
        }));
      const input: UpdateProductConfigMutationVariables['input'] = {
        ...agencyObject,
        id: product.id,
        ...formattedProductOption(product),
        isHidden: product.isHidden,
        flightConfigs,
      };

      const { data } = await this._apollo.mutate<
        UpdateProductConfigWithChildrenMutation,
        UpdateProductConfigWithChildrenMutationVariables
      >({
        mutation: UpdateProductConfigWithChildrenDocument,
        variables: { input, childAgencyIds, replace: replace },
      });

      return Ok(data.updatedProduct);
    } catch (error) {
      return Err({
        message: "Can't update product at this time.",
      });
    }
  }

  async addProductsFromCompulseTo(
    agencyId: string,
    globalProductConfigIds: string[],
    childAgencyIds: string[] = [],
  ): Promise<Result<Boolean, Failure>> {
    try {
      const { data } = await this._apollo.mutate<
        BulkSaveGlobalProductConfigsMutation,
        BulkSaveGlobalProductConfigsMutationVariables
      >({
        mutation: BulkSaveGlobalProductConfigsDocument,
        variables: { agencyId, globalProductConfigIds, childAgencyIds },
      });
      return Ok(!!data.result);
    } catch (error) {
      return Err({
        message: "Can't add products at this time.",
      });
    }
  }

  async createProductConfig(
    product,
    agencyId,
  ): Promise<Result<CreateProductConfigMutation['createdProduct'], Failure>> {
    try {
      const agencyObject = agencyId && agencyId !== 'Global' ? {} : { agencyId: null };

      const flightConfigs: CreateProductConfigMutationVariables['input']['flightConfigs'] =
        product.targetingOptions.map(option => ({
          ...agencyObject,
          ...formattedFlightConfig(option, product),
        }));
      const input: CreateProductConfigMutationVariables['input'] = {
        ...agencyObject,
        ...formattedProductOption(product),
        isHidden: false,
        slideType: ProductSlideType.Default,
        type: ProductConfigType.Regular,
        category: ProductConfigCategory.Other,
        flightConfigs,
      };

      const { data } = await this._apollo.mutate<CreateProductConfigMutation, CreateProductConfigMutationVariables>({
        mutation: CreateProductConfigDocument,
        variables: { input },
      });

      return Ok(data.createdProduct);
    } catch (error) {
      return Err({
        message: "Can't create product at this time.",
      });
    }
  }

  async createProductConfigWithChildren(
    product,
    agencyId,
    childAgencyIds: string[] = [],
  ): Promise<Result<CreateProductConfigWithChildrenMutation['createdProduct'], Failure>> {
    try {
      const agencyObject = agencyId && agencyId !== 'Global' ? {} : { agencyId: null };

      const flightConfigs: CreateProductConfigMutationVariables['input']['flightConfigs'] =
        product.targetingOptions.map(option => ({
          ...agencyObject,
          ...formattedFlightConfig(option, product),
        }));
      const input: CreateProductConfigMutationVariables['input'] = {
        ...agencyObject,
        ...formattedProductOption(product),
        isHidden: false,
        slideType: ProductSlideType.Default,
        type: ProductConfigType.Regular,
        category: ProductConfigCategory.Other,
        flightConfigs,
      };

      const { data } = await this._apollo.mutate<
        CreateProductConfigWithChildrenMutation,
        CreateProductConfigWithChildrenMutationVariables
      >({
        mutation: CreateProductConfigWithChildrenDocument,
        variables: { input, childAgencyIds },
      });

      return Ok(data.createdProduct);
    } catch (error) {
      return Err({
        message: "Can't create product at this time.",
      });
    }
  }

  async getConfigs(agencyId: Scalars['UUID']): Promise<Result<any[], Failure>> {
    try {
      const { data, error } = await this._apollo.query<GetProductConfigsQuery, GetProductConfigsQueryVariables>({
        query: GetProductConfigsDocument,
        variables: { id: agencyId },
      });

      if (error) {
        return Err({
          message: "Can't get products at this time.",
        });
      }
      const { products } = data;

      return Ok(products);
    } catch (error) {
      return Err({
        message: `Can't get products at this time: ${error}`,
      });
    }
  }

  async getConfigsForProposal(agencyId: Scalars['UUID']): Promise<Result<any[], Failure>> {
    try {
      const { data, error } = await this._apollo.query<
        GetProductAndPackageConfigsQuery,
        GetProductAndPackageConfigsQueryVariables
      >({
        query: GetProductAndPackageConfigsDocument,
        variables: { id: agencyId },
      });

      if (error) {
        return Err({
          message: "Can't get products at this time.",
        });
      }
      const { products, packages } = data;

      return Ok([...products, ...packages]);
    } catch (error) {
      return Err({
        message: `Can't get products at this time: ${error}`,
      });
    }
  }

  async recalculateBudgets(
    products: any[],
    budget: number,
  ): Promise<Result<{ products: any[]; newBudget: number }, Failure>> {
    try {
      const solutions: SolutionInput[] = products.map(product => ({
        id: product.id,
        budget: product.budget || 0,
        isChanged: product.isChanged || false,
        isLocked: product.isLocked || false,
        minSpend: product.minSpend || 0,
        ...(product.flights && product.flights.length
          ? {
              flights: product.flights.map(flight => ({
                id: flight.id,
                budget: flight.budget,
                isLocked: flight.isLocked,
                isChanged: flight.isChanged || false,
              })),
            }
          : {}),
      }));

      const { data, error } = await this._apollo.query<
        RecalculateProductsBudgetQuery,
        RecalculateProductsBudgetQueryVariables
      >({
        query: RecalculateProductsBudgetDocument,
        variables: {
          input: {
            totalBudget: budget,
            solutions,
          },
        },
      });

      if (error) {
        return Err({
          message: error.message,
        });
      }
      const updatedProducts = data.distributeBudget.products;
      const mergedProducts = mergeProduct(updatedProducts, products);
      return Ok({ products: mergedProducts, newBudget: data.distributeBudget.updatedBudget });
    } catch (error) {
      return Err({
        message: error.message || "Can't recalculate budgets at this time.",
      });
    }
  }

  async getProductConfigByType(
    type: ProductConfigType,
    agencyId?: string,
  ): Promise<Result<GetProductConfigByTypeQuery['getMediaplannerProductConfigByType'][0], Failure>> {
    try {
      const { data } = await this._apollo.query<GetProductConfigByTypeQuery, GetProductConfigByTypeQueryVariables>({
        query: GetProductConfigByTypeDocument,
        variables: { type, agencyId },
      });

      const [config] = data.getMediaplannerProductConfigByType;

      return Ok(config);
    } catch (error) {
      return Err({
        message: "Can't get product at this time.",
      });
    }
  }

  async deleteProductConfig(productId: string, agencyId?: string): Promise<Result<boolean, Failure>> {
    try {
      const agencyObject = agencyId && agencyId !== 'Global' ? {} : { agencyId: null };
      const { data } = await this._apollo.mutate<
        DeleteMediaplannerProductMutation,
        DeleteMediaplannerProductMutationVariables
      >({
        mutation: DeleteMediaplannerProductDocument,
        variables: { id: productId, ...agencyObject },
      });

      const result = !!data?.deleteMediaplannerProductConfig?.id;

      return Ok(result);
    } catch (error) {
      return Err({
        message: "Can't delete product at this time.",
      });
    }
  }

  async deleteProductConfigWithChildren(
    productId: string,
    agencyId?: string,
    childAgencyIds: string[] = [],
  ): Promise<Result<boolean, Failure>> {
    try {
      const agencyObject = agencyId && agencyId !== 'Global' ? {} : { agencyId: null };
      const { data } = await this._apollo.mutate<
        DeleteProductConfigWithChildrenMutation,
        DeleteProductConfigWithChildrenMutationVariables
      >({
        mutation: DeleteProductConfigWithChildrenDocument,
        variables: { id: productId, childAgencyIds, ...agencyObject },
      });

      const result = !!data?.deleteProductConfigWithChildren?.id;

      return Ok(result);
    } catch (error) {
      return Err({
        message: "Can't delete product at this time.",
      });
    }
  }
}

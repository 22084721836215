
import Vue from 'vue';

import { debounce, uniqBy } from 'lodash';

import { GeoModelContract, GeocodeServiceContract, ProductModelContract } from '@/injectables';
import { Services, Models } from '@/injectables/tokens';
import BulkZipsUpload from '@/features/instant-io/ui/BulkZipsUploader.vue';

export default Vue.extend({
  name: 'EditableGeoSelectionsCard',

  inject: ['$confirm', 'showSnackbar'],

  useInjectable: [Services.Geocode, Models.Geo, Models.Product],

  components: {
    BulkZipsUpload,
  },

  props: {
    selection: {
      type: Array,
      required: true,
    },
    currentGeoType: {
      type: String,
      required: true,
    },
    bulkUploadAvailable: {
      type: Boolean,
      default: false,
    },
    wrap: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    bypassConfirm: {
      type: Boolean,
      default: false,
    },
    singleElementUpdate: {
      type: Boolean,
      default: false,
    },
    geoNameFormatter: {
      type: Function,
      default: (el: String) => el,
    },
    excludeGeo: {
      type: Array,
      default: () => [],
    },
    showRequiredMessage: {
      type: Boolean,
      default: false,
    },
    isNationalCampaign: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    address(address: string): void {
      if (address) {
        this.addGeo(address);
        this.fetchedItems = [];
        this.$nextTick(() => this.$refs?.mapAddressSearch?.reset());
      }
    },
    addressSearch(val: string): void {
      if (!val || val.length < 3) {
        return;
      }
      this.fetchingLocations = true;
      this.fetchAutocompleteApi(val);
    },
  },

  data() {
    const stateGeoTypeValue = 'STATE';
    const nationalCampaignGeoTypeValue = 'NATIONAL';
    return {
      address: '',
      addressSearch: '',
      stateGeoTypeValue,
      nationalCampaignGeoTypeValue,
      geoTypes: [
        { text: 'State', value: stateGeoTypeValue, realGeoType: stateGeoTypeValue },
        { text: 'DMA', value: 'DMA', realGeoType: 'DMA' },
        { text: 'County', value: 'COUNTY', realGeoType: 'COUNTY' },
        { text: 'City', value: 'CITY', realGeoType: 'CITY' },
        { text: 'Zip', value: 'ZIP' },
        { text: 'National', value: nationalCampaignGeoTypeValue, realGeoType: stateGeoTypeValue },
      ],
      fetchedItems: [],
      fetchingLocations: false,
      isBulkUpload: false,
      bulkUploadedZips: [],
    };
  },
  computed: {
    items(): object[] {
      return this.fetchedItems.map(entry => {
        const name = (this[Models.Geo] as GeoModelContract).geoNameWithState(entry);

        return {
          ...entry,
          address: entry.name,
          text: name,
        };
      });
    },
    isZipSelection(): boolean {
      return this.currentGeoType === 'ZIP';
    },
    availableGeoTypes(): string[] {
      return this.geoTypes.filter(geo => !this.excludeGeo.includes(geo.value));
    },
  },
  methods: {
    setNationalCampaign(): void {
      const list = (this.productEntity as ProductModelContract).statesAndDistrictsNumber.map(({ state, short }) => ({
        name: state,
        key: short,
      }));

      this.$emit('update', uniqBy([...list], 'key'));
    },
    async showConfirm() {
      return (
        await this.$confirm.show({
          title: 'Change geo type',
          body: `This will permanently reset all geo selections. Are you sure?`,
          confirmText: 'Yes',
          cancelText: 'Cancel',
        })
      ).confirmed;
    },
    async changeGeoType(val): Promise<boolean | void> {
      const old = this.currentGeoType;
      if (!this.bypassConfirm && this.selection.length && !(await this.showConfirm())) {
        // big workaround
        this.$set(this.$refs.geoSelector, 'lazyValue', old);
        this.$set(this.$refs.geoSelector, 'selectedItems', [old]);
        return;
      }

      this.$emit('change-geo-type', val);

      if (val === this.nationalCampaignGeoTypeValue) {
        this.setNationalCampaign();
      }

      return true;
    },
    fetchAutocompleteApi: debounce(async function (query: string) {
      const geocodeService: GeocodeServiceContract = this.geocodeService;
      const { isErr, unwrapErr, unwrap } = await geocodeService.searchByLocationWithGeoType(query, this.currentGeoType);
      this.fetchingLocations = false;

      if (isErr()) {
        this.showSnackbar(unwrapErr().message, 'error');
        return;
      }
      this.fetchedItems = unwrap();
    }, 500),
    addGeo(item) {
      const geoTypeMap = {
        dma: 'DMA',
        city: 'CITY',
        zip: 'ZIP',
        state: 'STAT',
        county: 'CNTY',
      };

      const newItem = {
        key: `${geoTypeMap[item.type]}_${item.key}`,
        name: item.name,
      };

      if (this.selection.find(geo => geo.key === newItem.key)) {
        return;
      }

      if (this.singleElementUpdate) this.$emit('add-geo', newItem);
      else this.$emit('update', [...this.selection, newItem]);
    },
    addBulkZips() {
      const zips = this.bulkUploadedZips.map(zip => ({ name: zip, key: `ZIP_${zip}` }));
      if (this.singleElementUpdate) this.$emit('add-geo', zips);
      else this.$emit('update', uniqBy([...this.selection, ...zips], 'key'));
      this.isBulkUpload = false;
    },
    deleteGeo(item) {
      const itemName = (this[Models.Geo] as GeoModelContract).cleanGeoString(item);

      const filtered = this.selection.filter(geo => {
        const geoAddress = (this[Models.Geo] as GeoModelContract).cleanGeoString(geo);

        return geoAddress !== itemName;
      });

      if (this.singleElementUpdate) this.$emit('remove-geo', item);
      else this.$emit('update', filtered);
    },
  },
});

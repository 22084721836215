import 'reflect-metadata';
import { MathServiceContract } from '@/injectables';
import { injectable } from 'inversify';

@injectable()
export class MathService implements MathServiceContract {
  convertPercentStringToInt(percent: string): number {
    const num = parseInt(percent);
    if (isNaN(num)) return 0;
    if (num < 0) return 0;
    if (num > 100) return 1;
    return num / 100;
  }
}

import { ApolloClient, NormalizedCacheObject } from '@apollo/client/core';
import { AxiosInstance } from 'axios';
import { inject, injectable } from 'inversify';

@injectable()
export class Service {
  protected _axios: AxiosInstance;
  protected _apollo: ApolloClient<NormalizedCacheObject>;

  constructor(@inject('axios') axios: AxiosInstance, @inject('apollo') apollo?: ApolloClient<NormalizedCacheObject>) {
    this._axios = axios;
    this._apollo = apollo;
  }
}


import Vue from 'vue';
import { BaseDialog } from '@/shared/ui';

export default Vue.extend({
  name: 'InstantIOBaseModal',

  components: { BaseDialog },

  props: {
    iconInsteadOfAction: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Boolean,
      default: false,
    },
    saveDisabledMessage: {
      type: String,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
});

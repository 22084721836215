import * as Types from '../../../../../app/graphql/_types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { ProductConfigFragmentFragmentDoc } from '../../../../../app/graphql/fragments/product-config.fragment.generated';
export type GetProductConfigsQueryVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
}>;

export type GetProductConfigsQuery = {
  products?: Array<{
    id: string;
    name?: string | null;
    type?: Types.ProductConfigType | null;
    category?: Types.ProductConfigCategory | null;
    agencyId?: string | null;
    description?: Record<string, any> | null;
    minDays?: number | null;
    minSpend?: number | null;
    recommendedBudget?: number | null;
    flightType?: Types.ProductConfigFlightType | null;
    rateRangeList?: Record<string, any> | null;
    keyMetric?: Types.KeyMetric | null;
    keyMetricMultiplier?: number | null;
    isHidden?: boolean | null;
    isMonthly?: boolean | null;
    cpcMargin?: number | null;
    omsAccountNumber?: string | null;
    omsName?: string | null;
    IORecipientEmail?: string | null;
    fulfillmentMethod?: Types.ProductConfigFullfillmentMethod | null;
    questions?: Array<{ id: string; required: boolean; text: string; index: number } | null> | null;
    flightConfigs?: Array<{
      id: string;
      targetingOption?: string | null;
      flightCategory?: Types.FlightCategory | null;
      adFormatList?: Record<string, any> | null;
      rate?: number | null;
      rateType?: Types.RateType | null;
      markupMin?: number | null;
      markupMax?: number | null;
      platformOption?: Array<string | null> | null;
      mapType?: Types.MapType | null;
      advancedOptions?: {
        autoRenewList?: Array<string | null> | null;
        abTestFlag?: boolean | null;
        darkPeriodList?: Array<{ index: number; startDate: string; endDate: string } | null> | null;
      } | null;
    } | null> | null;
  } | null> | null;
};

export const GetProductConfigsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getProductConfigs' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'products' },
            name: { kind: 'Name', value: 'getMediaplannerProductConfigs' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'agencyId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProductConfigFragment' } }],
            },
          },
        ],
      },
    },
    ...ProductConfigFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetProductConfigsQuery, GetProductConfigsQueryVariables>;

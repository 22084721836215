
import Vue, { PropType } from 'vue';

import { Services, Models } from '@/injectables/tokens';

import { logout } from '../model/logout';
import { LocalStorageServiceContract } from '@/injectables';
import { C360ModelContract, EnvServiceContract } from '../../../injectables';

export default Vue.extend({
  name: 'LogoutFeature',

  useInjectable: [Services.Env, Services.LocalStorage, Models.C360],

  props: {
    logoutFn: {
      type: Function as PropType<() => Promise<void>>,
      required: true,
    },
  },

  computed: {
    whiteLabeledEnv(): boolean {
      const url = window.location.host;

      return (this[Services.Env] as EnvServiceContract).isWhiteLabeledEnv(url);
    },
  },

  methods: {
    currentEnv() {
      return (this[Services.Env] as EnvServiceContract).getEnvironmentName(true);
    },
    async logout() {
      await logout(this.$container, this.logoutFn);

      (this[Services.LocalStorage] as LocalStorageServiceContract).setItem('mp_c360_access', null);

      if (this.whiteLabeledEnv) {
        const link = (this.c360Entity as C360ModelContract).getC360LoginLink(this.currentEnv());

        window.location.href = link;
      }
    },
  },
});

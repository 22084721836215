import { Module } from 'vuex';

import { state, ManagementState } from './state';
import { actions } from './actions';
import { mutations } from './mutation';
import { Container } from 'inversify';
import { RootState } from '../root/state';

interface ManagementModuleFactoryReturn {
  module: Module<ManagementState, RootState>;
}

export const managementModule = (container: Container): ManagementModuleFactoryReturn => {
  return {
    module: {
      namespaced: true as const,
      state: state(),
      mutations: mutations(),
      actions: actions(container),
    },
  };
};

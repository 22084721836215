import { PluginObject } from 'vue';

export const logFunction = (level: 'info' | 'warning' | 'error', src: string, message?: string): void => {
  try {
    let color = 'DarkGray';
    switch (level) {
      case 'info':
        color = 'LightSkyBlue';
        break;
      case 'warning':
        color = 'GoldenRod';
        break;
      case 'error':
        color = 'BrickRed';
        break;
      default:
        break;
    }
    const timestamp = new Date(Date.now());
    const msg = `[${level.toUpperCase()}], ${timestamp} ${src} ${
      typeof message !== 'undefined' ? JSON.stringify(message) : ''
    }\r\n`;
    if (level === 'error') {
      // eslint-disable-next-line no-console
      console.error(msg);
    } else {
      // eslint-disable-next-line no-console
      console.log(`%c${msg}`, `color:${color}`);
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('log error', err);
  }
};

const loggerPlugin: PluginObject<{}> = {
  install(Vue): void {
    Vue.prototype.$log = logFunction;
  },
};

export default loggerPlugin;

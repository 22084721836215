import * as Types from '../../../../../app/graphql/_types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { ProposalTableFragmentFragmentDoc } from '../../../../../app/graphql/fragments/proposal.fragment.generated';
export type CloneProposalMutationVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
}>;

export type CloneProposalMutation = {
  duplicateMediaplannerProposal?: {
    id: string;
    name?: string | null;
    budget?: number | null;
    status: string;
    updatedAt?: string | null;
    campaignStartDate?: string | null;
    campaignEndDate?: string | null;
    startDate?: string | null;
    endDate?: string | null;
    client?: { id: string; name: string; logo?: string | null } | null;
    createdBy?: { email: string } | null;
    updatedBy?: { email: string } | null;
  } | null;
};

export const CloneProposalDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'cloneProposal' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'duplicateMediaplannerProposal' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProposalTableFragment' } }],
            },
          },
        ],
      },
    },
    ...ProposalTableFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<CloneProposalMutation, CloneProposalMutationVariables>;

import 'reflect-metadata';
import {
  TrackCreateProposalProps,
  TrackerContract,
  TrackGraphqlErrorProps,
  TrackInstantIoDefaultProps,
  TrackKeywordPlannerProps,
  TrackOttAvailsProps,
  TrackProposalDownloadRequestedProps,
  TrackProposalLinkCopiedProps,
  TrackProposalStatusChangeProps,
  TrackUserLoginProps,
} from '@/injectables';
import { Mixpanel } from 'mixpanel-browser';
import { inject, injectable } from 'inversify';
import { MeQuery } from '@/injectables/services/auth/graphql/queries/me.generated';
import { AgencyType } from '@/app/graphql';

const preparedInstantCampaign = instantIO => {
  const products = instantIO?.selectedProducts;
  return {
    client_name: instantIO?.client?.name,
    campaign_name: instantIO?.name,
    campaign_budget: products?.length
      ? products?.reduce((acc, curr) => {
          return acc + curr?.budget;
        }, 0)
      : 0,
    products: products?.length ? products?.map(product => product?.name) : [],
    products_w_budgets: products?.length
      ? products?.map(product => ({ name: product?.name, budget: product?.budget }))
      : [],
  };
};

const getConversionTracking = proposalOrInstantIO => {
  const { conversion, visits } = proposalOrInstantIO?.conversionTracking || {};
  const trackVisits = visits?.trackVisits || false;
  const trackConversion = conversion?.trackConversion || false;
  const numberOfPages = conversion?.websites?.filter(Boolean)?.length || null;

  return {
    track_website_visits: trackVisits,
    track_conversions: trackConversion,
    ...(trackConversion ? { number_of_pages_with_conversion_tracking: numberOfPages } : {}),
  };
};

const instantCampaignWithConversionTracking = instantIO => {
  return {
    ...preparedInstantCampaign(instantIO),
    ...getConversionTracking(instantIO),
  };
};

export const preparedProposal = proposal => {
  return {
    client_name: proposal?.client?.name,
    proposal_name: proposal?.name,
    proposal_id: proposal?.id,
    products: proposal?.products?.map(p => p?.name) || [],
    goal: proposal?.goal,
    budget: proposal?.budget,
  };
};

export const proposalWithConversionTracking = proposal => {
  return {
    ...preparedProposal(proposal),
    ...getConversionTracking(proposal),
  };
};

@injectable()
export class Tracker implements TrackerContract {
  constructor(@inject('mixpanel') private _mixpanel: Mixpanel) {}

  _domain = window.location.host;

  /**
   * Tracks user logins.
   */
  trackUserLogin: TrackUserLoginProps = ({ email, id }) => {
    this._mixpanel.track('Media Planner Login - AH', {
      distinct_id: id,
      email,
      domain: this._domain,
    });
  };

  /**
   * Tracks creating proposal.
   */
  trackCreateProposal: TrackCreateProposalProps = ({
    userId,
    proposalName,
    proposalId,
    clientName,
    goal,
    budget,
    createdAt,
    clientCategory,
  }) => {
    this._mixpanel.track('Proposal created', {
      domain: this._domain,
      client_name: clientName,
      proposal_id: proposalId,
      proposal_name: proposalName,
      goal,
      budget: Number(budget),
      user_date: createdAt,
      distinct_id: userId,
      client_industry: clientCategory,
    });
  };

  /**
   * Proposal status change triggered.
   */
  trackProposalStatusChange: TrackProposalStatusChangeProps = ({
    proposal,
    userId,
    updatedAt,
    from,
    to,
    clientCategory,
  }) => {
    this._mixpanel.track('Proposal status changed', {
      ...proposalWithConversionTracking(proposal),
      from_status: from,
      to_status: to,
      user_date: updatedAt,
      distinct_id: userId,
      client_industry: clientCategory,
    });
  };

  /**
   * Ott Avails triggered.
   */
  trackOttAvails: TrackOttAvailsProps = ({ createdAt, userId }) => {
    this._mixpanel.track('OTT avails', {
      domain: this._domain,
      user_date: createdAt,
      distinct_id: userId,
    });
  };

  /**
   * Keyword planner triggered.
   */
  trackKeywordPlanner: TrackKeywordPlannerProps = ({ createdAt, userId }) => {
    this._mixpanel.track('Keywords planner search triggered', {
      domain: this._domain,
      user_date: createdAt,
      distinct_id: userId,
    });
  };

  /**
   * Proposal download requested triggered.
   */
  trackProposalDownloadRequested: TrackProposalDownloadRequestedProps = ({
    userId,
    type,
    proposalId,
    createdAt,
    clientCategory,
  }) => {
    this._mixpanel.track('Proposal deck downloaded', {
      type,
      proposal_id: proposalId,
      user_date: createdAt,
      distinct_id: userId,
      client_industry: clientCategory,
    });
  };

  /**
   * Proposal link copied triggered.
   */
  trackProposalLinkCopied: TrackProposalLinkCopiedProps = ({ proposalId, userId, createdAt, clientCategory }) => {
    this._mixpanel.track('Proposal deck shared', {
      proposal_id: proposalId,
      user_date: createdAt,
      distinct_id: userId,
      client_industry: clientCategory,
    });
  };

  /**
   * Graphql error triggered.
   */
  trackGraphqlError: TrackGraphqlErrorProps = ({ httpCode, graphqlCode, graphqlMessage, userId }) => {
    this._mixpanel.track('HTTP error', {
      http_code: httpCode,
      graphql_code: graphqlCode,
      graphql_message: graphqlMessage,
      distinct_id: userId,
    });
  };

  /**
   * Instant IO page visited.
   */
  trackInstantIoPageVisited = () => {
    this._mixpanel.track('Instant IO opened');
  };

  /**
   * Instant IO downloaded.
   */
  trackInstantIoDownloaded: TrackInstantIoDefaultProps = ({ campaign }) => {
    const data = instantCampaignWithConversionTracking(campaign);
    this._mixpanel.track('Instant IO downloaded', {
      ...data,
    });
  };

  /**
   * Instant IO submitted.
   */
  trackInstantIoSubmitted: TrackInstantIoDefaultProps = ({ campaign }) => {
    const data = instantCampaignWithConversionTracking(campaign);
    this._mixpanel.track('Instant IO submitted', {
      ...data,
    });
  };

  /**
   * Instant IO saved.
   */
  trackInstantIoSaved: TrackInstantIoDefaultProps = ({ campaign }) => {
    const data = preparedInstantCampaign(campaign);
    this._mixpanel.track('Instant IO saved', {
      ...data,
    });
  };

  /**
   * Access token refreshed.
   */
  accessTokenRefreshed = () => {
    this._mixpanel.track('Access token refreshed');
  };

  /**
   * Register Mixpanel super properties
   */
  /* eslint-disable camelcase */
  registerSuperProperties = ({ user }: { user: MeQuery['me'] }) => {
    const agencyType = user?.agency?.type;

    let agencyName = null;
    let stationName = null;
    let subagencyName = null;
    let agency_id = null;
    let station_id = null;
    let subagency_id = null;

    if (agencyType === AgencyType.Agency) {
      agencyName = user?.agency?.name;
      agency_id = user?.agency?.id;
    } else if (agencyType === AgencyType.Station) {
      agencyName = user?.agency?.parent?.name;
      stationName = user?.agency?.name;
      agency_id = user?.agency?.parent?.id;
      station_id = user?.agency?.id;
    } else if (agencyType === AgencyType.Subagency) {
      agencyName = user?.agency?.parent?.name;
      subagencyName = user?.agency?.name;
      agency_id = user?.agency?.parent?.id;
      subagency_id = user?.agency?.id;
    }

    this._mixpanel.register({
      user_id: user.id,
      user_name: user.name,
      agency_user: user.email,
      user_email: user.email, // By design: https://extendtv.atlassian.net/browse/PIT-4007
      domain: this._domain,
      agency_id,
      agency_name: agencyName,
      station_id,
      station_name: stationName,
      subagency_id,
      subagency_name: subagencyName,
    });
  };
  /* eslint-enable camelcase */
}


import Vue, { PropType } from 'vue';

import { BaseProductModelContract, MediaplannerProductConfig, ValidationServiceContract } from '@/injectables';
import { Models, Services } from '@/injectables/tokens';

import { FormFragment } from '../contracts';
import { ProductConfigFullfillmentMethod } from '@/app/graphql';

export default Vue.extend({
  name: 'EditProduct.Fulfillment',
  useInjectable: [Models.BaseProduct],

  props: {
    product: {
      type: Object as PropType<MediaplannerProductConfig>,
      required: true,
    },
    isValid: {
      type: Boolean,
      default: false,
    },
  },

  mounted() {
    this.$emit('update:is-valid', this.$refs.form?.validate());
  },

  data() {
    const { fulfillmentMethod: method, omsName, omsAccountNumber, IORecipientEmail } = this.product;
    return {
      method,
      omsName,
      omsAccountNumber,
      IORecipientEmail,
      localFulfillmentMethod: method,
      dangerousEdit: false,
    };
  },

  computed: {
    changeFulfillmentDisabled(): boolean {
      return this.product?.fulfillmentMethod === ProductConfigFullfillmentMethod.Compulse;
    },
    ProductConfigFullfillmentMethod() {
      return ProductConfigFullfillmentMethod;
    },
    updatedProductPart(): FormFragment {
      return {
        fulfillmentMethod: this.method,
        omsName: this.omsName,
        omsAccountNumber: this.omsAccountNumber,
        IORecipientEmail: this.IORecipientEmail,
      };
    },
    rules() {
      const validationService: ValidationServiceContract = this.$container.get(Services.Validation);
      return {
        required: validationService.requiredValidatorFactory(),
        email: validationService.emailValidatorFactory(),
        maxLength: validationService.maxLengthValidatorFactory(),
      };
    },
    fulfillmentMethods() {
      return Object.values(ProductConfigFullfillmentMethod).map(key => ({
        value: key,
        text: this.getReadableFullfillmentMethod(key),
        disabled:
          this.localFulfillmentMethod !== ProductConfigFullfillmentMethod.Compulse &&
          key === ProductConfigFullfillmentMethod.Compulse,
      }));
    },
  },
  methods: {
    getReadableFullfillmentMethod(ProductConfigFullfillmentMethod) {
      return (this[Models.BaseProduct] as BaseProductModelContract).getReadableFullfillmentMethod(
        ProductConfigFullfillmentMethod,
      );
    },
  },

  watch: {
    updatedProductPart: {
      handler(next) {
        if (next?.fulfillmentMethod) {
          const fromCompulse = this.product.fulfillmentMethod === this.ProductConfigFullfillmentMethod.Compulse;
          const toCompulse = next.fulfillmentMethod === this.ProductConfigFullfillmentMethod.Compulse;
          this.dangerousEdit = fromCompulse && !toCompulse;
        }
        this.$emit('update:fulfillment', this.updatedProductPart);
      },
      deep: true,
      immediate: true,
    },
  },
});

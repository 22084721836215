import { CreateHelpPageInput } from '@/app/graphql';
import { GetPagesQuery } from '../graphql/queries/get-pages.generated';
import { HelpPage as CommonPage, NewHelpPage } from '@/injectables/services';
type HelpPage = GetPagesQuery['getPages'][0];
export type MapGraphqlPageToCommon = (page: HelpPage) => CommonPage;

export type MapCommonPageToGraphqlPage = (page: CommonPage) => HelpPage;

export type MapCommonNewPageToGraphqlPage = (page: NewHelpPage) => CreateHelpPageInput;

/**
 * Map graphql page type to common page type
 */
export const mapGraphqlPageToCommon: MapGraphqlPageToCommon = function (page: HelpPage) {
  return {
    Index: String(page.index),
    PropertyId: page.id,
    Title: page.title,
    PageContent: page.pageContent,
    ...(page?.pageList ? { PageList: page?.pageList.map(mapGraphqlPageToCommon) } : {}),
  };
};

/**
 * Map common page type to graphql page type
 */
export const mapCommonPageToGraphqlPage: MapCommonPageToGraphqlPage = function (page: CommonPage) {
  return {
    index: Number(page.Index),
    id: page.PropertyId,
    title: page.Title,
    pageContent: page.PageContent,
    // pageList: page.PageList.map(mapCommonPageToGraphqlPage),
    ...(page?.PageList ? { pageList: page?.PageList.map(mapCommonPageToGraphqlPage) } : {}),
  };
};

/**
 * Map common NewHelpPage type to graphql page type
 */
export const mapCommonNewPageToGraphqlPage: MapCommonNewPageToGraphqlPage = function (page: NewHelpPage) {
  return {
    index: Number(page.Index),
    title: page.Title,
    pageContent: page.PageContent,
  };
};

import { UnsafeAny } from '@/shared/types';
import { AgencyState } from '../agency/state';
import { ProposalState } from '../proposal/state';
import { AuthState } from '../auth/state';

interface FiltersDates {
  from?: string;
  to?: string;
}

export interface Filters {
  [key: string]: string | Record<string, FiltersDates>;
  dates?: {
    start?: FiltersDates;
    end?: FiltersDates;
    modified?: FiltersDates;
  };
}

export interface RootState {
  newProposal: UnsafeAny;
  agency: AgencyState;
  proposal: ProposalState;
  auth: AuthState;
  snackbarContent: string;
  snackbarTimeout: number;
  snackbarColor: string;
  snackbarWithLoader: boolean;
  nextUrl: string;
  alert: Alert;
  searchTerm: string;
  filters: Filters;
  activeAgency: { name: string; PropertyId: string };
  activeSubAgency: { name: string; PropertyId: string };
}

export interface Alert {
  show?: boolean;
  type: string;
  message: string;
  dismissible?: boolean;
}

export const state = (): RootState => ({
  searchTerm: '',
  filters: {},
  activeAgency: { name: '', PropertyId: '' },
  activeSubAgency: { name: '', PropertyId: '' },
  snackbarContent: '',
  snackbarColor: 'red',
  snackbarTimeout: 3500,
  snackbarWithLoader: false,
  nextUrl: '',
  alert: {
    show: false,
    type: 'error',
    message: '',
    dismissible: true,
  },
  newProposal: null,
  auth: null,
  agency: null,
  proposal: null,
});

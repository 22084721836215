
import Vue, { PropType } from 'vue';

import { ProposalServiceContract, QuickPeriod, CalendarServiceContract, DateModelContract } from '@/injectables';
import { Models, Services } from '@/injectables/tokens';

import DatePickerMenu from '@/components/DatePickerMenu.vue';
import { DateRangePicker } from '@/shared/ui';

export default Vue.extend({
  name: 'DatePickerWithQuickPeriods',

  useInjectable: [Services.Proposal, Services.Calendar, Models.Date],

  components: {
    DateRangePicker,
    DatePickerMenu,
  },

  props: {
    /**
     * Min date for datepicker.
     */
    min: {
      type: String,
      default: new Date().toISOString(),
    },

    /**
     * Max date for datepicker.
     */
    max: {
      type: String,
      default: new Date(new Date().setDate(new Date().getDate() + 100)).toISOString(),
    },

    value: {
      type: Array as PropType<string[]>,
      required: true,
    },

    customDates: {
      type: String,
      default: 'none',
    },

    isForStart: {
      type: Boolean,
      default: false,
    },

    label: {
      type: String,
      default: 'Date',
    },

    useLabel: {
      type: Boolean,
      default: true,
    },

    hideBorder: {
      type: Boolean,
      default: false,
    },

    width: {
      type: String,
    },

    allowClear: {
      type: Boolean,
      default: false,
    },

    /**
     * Rules for default slotted text-field
     */
    rules: {
      type: Array,
      default: () => [],
    },

    appendIcon: {
      type: String,
      default: 'arrow_drop_down',
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data(): {
    isOpen: boolean;
    dates: string[];
  } {
    const dates = this.value || [];
    return {
      dates,
      isOpen: false,
    };
  },

  watch: {
    value(arr: string[]): void {
      this.dates = arr;
    },
    dates(datesArr: string[]): void {
      if ((this.allowClear && datesArr.length === 0) || datesArr.length === 2) {
        this.completeCustomDateSelection();
      }
    },
  },

  computed: {
    dateValue(): string {
      const dateModel: DateModelContract = this[Models.Date];
      const isSingle = this.customDates === 'single';

      // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
      const [start = '', end = ''] = this.dates;

      return end
        ? this.$options.filters[isSingle ? 'formatDate' : 'formatDateRange'](
            (isSingle ? [end] : this.dates).map(dateModel.dateToFormatDateString),
          )
        : end;
    },
    quickDates(): QuickPeriod[] {
      const { quickPeriods } = this.proposalService as ProposalServiceContract;

      const { unwrap } = quickPeriods({
        calendarService: this.calendarService as CalendarServiceContract,
        customDates: this.customDates,
        startDate: this.dates?.[0] || '',
      });

      return unwrap();
    },
    oneThousandDays(): string {
      const [from = new Date()] = this.value;
      return (this.calendarService as CalendarServiceContract).dateAfter(1000, new Date(from)).toISOString();
    },
  },

  methods: {
    setFocus(): void {
      this.isOpen = true;
    },
    setSingleDate(singleDate: string): void {
      const updatedDates = [this.dates[this.isForStart ? 1 : 0], singleDate];

      this.setSortedDates(updatedDates);
    },
    setSortedDates(dates: string[]): void {
      if (dates.length === 2) {
        const sorted = [...dates].sort((a, b) => Date.parse(a) - Date.parse(b));

        this.dates = sorted;
      } else {
        this.dates = dates;
      }
    },
    completeCustomDateSelection(): void {
      this.isOpen = false;

      this.$emit('input', this.dates);
    },
  },
});

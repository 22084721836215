import Vue from 'vue';
// TODO: use method from injectables
// Format price:
// - input <number> -> return formatted string
// - input 0 or null -> return "$ 0"
// - input <string> -> return <string> ("" or "none")
// - prices starts with $ char
// - thousands separator is a comma (2500 -> $2,500)
// - if decimalPointDigits = 0 update to whole numbers (500.00 -> $500)
export const formatPriceFilter = (value: number | string | null, decimalPointDigits = 0): string => {
  if (typeof value === 'string') {
    return value;
  }
  if (!value) {
    return '$ 0';
  }
  let result = `$${Math.trunc(value)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
  if (decimalPointDigits) {
    result = `${result}.${(value % 1).toFixed(decimalPointDigits).toString().replace('0.', '')}`;
  }
  return result;
};

Vue.filter('formatPrice', formatPriceFilter);


import { GeoObj, UnsafeAny } from '@/shared/types';
import Vue from 'vue';

export default Vue.extend({
  name: 'PolygonSelectionDropdowns',

  inject: ['$confirm'],

  props: {
    forAvailsStandalone: {
      type: Boolean,
      default: false,
    },
    isChangeDisabled: {
      type: Boolean,
      default: false,
    },
    hasNationalCampaign: {
      type: Boolean,
      default: false,
    },
    zips: {
      type: Array,
      default: () => [],
    },
    cities: {
      type: Array,
      default: () => [],
    },
    dmas: {
      type: Array,
      default: () => [],
    },
    counties: {
      type: Array,
      default: () => [],
    },
    states: {
      type: Array,
      default: () => [],
    },
    districts: {
      type: Array,
      default: () => [],
    },
    hasDistricts: {
      type: Boolean,
      default: false,
    },
    layerSelection: {
      type: Object,
      default: null,
    },
    width: {
      type: Number,
      default: 800,
    },
  },

  data: (): {
    expandedDropdown: string;
    isShowedGeoTypeList: boolean;
    isShowedGeoArray: boolean;
    showCSVDialog: boolean;
  } => ({
    expandedDropdown: '',
    isShowedGeoTypeList: false,
    isShowedGeoArray: false,
    showCSVDialog: false,
  }),

  computed: {
    allPolygons(): UnsafeAny[] {
      return [
        {
          order: 1,
          label: 'State',
          value: 'states',
          selections: this.selectedStates,
        },
        {
          order: 2,
          label: 'DMA',
          value: 'dmas',
          selections: this.selectedDmas,
        },
        {
          order: 3,
          label: 'County',
          value: 'counties',
          selections: this.selectedCounties,
        },
        {
          order: 4,
          label: 'City',
          value: 'cities',
          selections: this.selectedCities,
        },
        {
          order: 5,
          label: 'Zip',
          value: 'zips',
          selections: this.selectedZips,
        },
        ...(this.hasDistricts
          ? [
              {
                order: 6,
                label: 'Con. Districts',
                value: 'congressionalDistricts',
                selections: this.selectedDistricts,
              },
            ]
          : []),
      ];
    },
    dropdownWidth(): number {
      const columns = 10;
      return Math.max(this.width / columns - 10, 200);
    },
    hasSelectedGeos(): boolean {
      return (
        this.selectedStates?.length > 0 ||
        this.selectedCities?.length > 0 ||
        this.selectedZips?.length > 0 ||
        this.selectedDmas?.length > 0 ||
        this.selectedCounties?.length > 0 ||
        this.selectedDistricts?.length > 0
      );
    },
    polygonSelection: {
      get(): { name: string; key: string; available: boolean } | null {
        return this.layerSelection;
      },
      set(selectionObj: { name: string; key: string; available: boolean }): void {
        this.$emit('set-polygon-selection', selectionObj);
      },
    },
    selectedStates: {
      get(): GeoObj[] {
        return this.states;
      },
      set(stateArr: GeoObj[]): void {
        this.$emit('update-selected', { polygon: 'states', list: stateArr });
      },
    },
    selectedCities: {
      get(): GeoObj[] {
        return this.cities;
      },
      set(cityArr: GeoObj[]): void {
        this.$emit('update-selected', { polygon: 'cities', list: cityArr });
      },
    },
    selectedZips: {
      get(): GeoObj[] {
        return this.zips;
      },
      set(zipArr: GeoObj[]): void {
        this.$emit('update-selected', { polygon: 'zips', list: zipArr });
      },
    },
    selectedDistricts: {
      get(): GeoObj[] {
        return this.districts;
      },
      set(districtArr: GeoObj[]): void {
        this.$emit('update-selected', { polygon: 'congressionalDistricts', list: districtArr });
      },
    },
    selectedCounties: {
      get(): GeoObj[] {
        return this.counties;
      },
      set(countyArr: GeoObj[]): void {
        this.$emit('update-selected', { polygon: 'counties', list: countyArr });
      },
    },
    selectedDmas: {
      get(): GeoObj[] {
        return this.dmas;
      },
      set(dmaArr: GeoObj[]): void {
        this.$emit('update-selected', { polygon: 'dmas', list: dmaArr });
      },
    },
    activeGeotype() {
      return this.polygonSelection?.key;
    },
    activeGeotypeName() {
      const name = this.allPolygons.find(polygon => polygon.value === this.polygonSelection?.key)?.label;
      if (name) return name;
      return this.polygonSelection?.key;
    },
  },

  methods: {
    async changeGeoTypeForNationalCampaign(): Promise<void> {
      const isConfirmed = this.forAvailsStandalone ? true : await this.confirmChangingGeoType();

      if (!isConfirmed) {
        return;
      }

      this.$emit('set-national-campaign');
    },
    async changeGeoTypeForBulkZipUpload(): Promise<void> {
      const isConfirmed =
        this.forAvailsStandalone || this.polygonSelection?.key === 'zips' ? true : await this.confirmChangingGeoType();

      if (!isConfirmed) {
        return;
      }

      this.$emit('open-upload-modul');
    },
    async confirmChangingGeoType(): Promise<boolean> {
      if (!this.hasSelectedGeos) return true;

      const { confirmed } = await this.$confirm.show({
        title: 'Change geo type',
        body: 'This will permanently reset all geo selections. Are you sure?',
        confirmText: 'Yes',
        cancelText: 'Cancel',
      });
      return confirmed;
    },
    // TODO: Replace UnsafeAny with actual types
    selectionKey(polygonLabel: string, selection: UnsafeAny): string {
      return selection?.key || polygonLabel + new Date().toISOString();
    },
    // TODO: Replace UnsafeAny with actual types
    polygonSelectionString(polygonLabel: string, selection: UnsafeAny): string {
      return selection?.name || polygonLabel;
    },
    cleanCounty(countyName: string): string {
      const countyArr = countyName.split('_');
      const [stateName, ...countyNameArr] = countyArr;
      return `${countyNameArr.join(' ')}, ${stateName}`;
    },
    isActiveLayer(polygonName: string): boolean {
      return this.polygonSelection?.key === polygonName;
    },
    removeAll(polygonName): void {
      if (polygonName === 'zips') {
        this.selectedZips = [];
      } else if (polygonName === 'cities') {
        this.selectedCities = [];
      } else if (polygonName === 'states') {
        this.selectedStates = [];
      } else if (polygonName === 'dmas') {
        this.selectedDmas = [];
      } else if (polygonName === 'counties') {
        this.selectedCounties = [];
      } else if (polygonName === 'congressionalDistricts') {
        this.selectedDistricts = [];
      }
      this.toggleActivePolygon(polygonName);
      setTimeout(() => this.$emit('render-map'), 200);
    },
    isExpanded(polygonName: string): boolean {
      return this.expandedDropdown === polygonName;
    },
    togglePolygonSelection(polygonName: string): void {
      if (polygonName === 'cities') {
        this.polygonSelection = { name: 'City', key: 'cities', available: true };
      } else if (polygonName === 'zips') {
        this.polygonSelection = { name: 'Zip', key: 'zips', available: true };
      } else if (polygonName === 'dmas') {
        this.polygonSelection = { name: 'DMA', key: 'dmas', available: true };
      } else if (polygonName === 'counties') {
        this.polygonSelection = { name: 'County', key: 'counties', available: true };
      } else if (polygonName === 'states') {
        this.polygonSelection = { name: 'State', key: 'states', available: true };
      } else {
        this.polygonSelection = { name: 'Con. Districts', key: 'congressionalDistricts', available: true };
      }

      if (!this.forAvailsStandalone) {
        this.$emit('geo-type-changed');
      }
    },
    async toggleActivePolygon(polygonName: string): Promise<void> {
      if (this.polygonSelection?.key === polygonName) {
        if (this.expandedDropdown === polygonName) {
          this.expandedDropdown = '';
        } else {
          this.expandedDropdown = polygonName;
        }
      } else {
        const isConfirmed = this.forAvailsStandalone ? true : await this.confirmChangingGeoType();

        if (!isConfirmed) {
          return;
        }

        this.expandedDropdown = '';
        this.togglePolygonSelection(polygonName);
      }
    },
    removeSelection(polygon: UnsafeAny, layerName: string): void {
      let updatedPolygon = { name: '', key: '' };
      if (layerName === 'cities') {
        updatedPolygon = {
          name: polygon?.name,
          key: polygon?.key,
        };
      } else if (layerName === 'states') {
        updatedPolygon = {
          name: polygon?.name,
          key: polygon?.key,
        };
      } else if (layerName === 'dmas') {
        updatedPolygon = {
          name: polygon?.name,
          key: polygon?.key,
        };
      } else if (layerName === 'counties') {
        updatedPolygon = {
          name: polygon?.name,
          key: polygon?.key,
        };
      } else if (layerName === 'zips') {
        updatedPolygon = {
          name: polygon?.name,
          key: polygon?.key,
        };
      } else if (layerName === 'congressionalDistricts') {
        updatedPolygon = {
          name: polygon?.name,
          key: polygon?.key,
        };
      } else {
        updatedPolygon = {
          name: polygon?.name,
          key: polygon?.key,
        };
      }

      this.$emit('toggle-selected-polygons', { layer: layerName, updatedPolygon });
      this.$nextTick(() => this.$emit('render-map'));
    },
  },
});

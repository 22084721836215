import * as Types from '../../../../../app/graphql/_types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type GetSemKeywordsQueryVariables = Types.Exact<{
  input: Types.KeywordIdeasInput;
}>;

export type GetSemKeywordsQuery = {
  getKeywordsStatistics: Array<{
    keyword?: string | null;
    searchVolume?: number | null;
    competition?: number | null;
    competitionLevel?: string | null;
    averageCpc?: number | null;
    recommendLevel?: number | null;
    isSelected?: boolean | null;
    isCustom?: boolean | null;
  }>;
};

export const GetSemKeywordsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getSemKeywords' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'KeywordIdeasInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getKeywordsStatistics' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'keyword' } },
                { kind: 'Field', name: { kind: 'Name', value: 'searchVolume' } },
                { kind: 'Field', name: { kind: 'Name', value: 'competition' } },
                { kind: 'Field', name: { kind: 'Name', value: 'competitionLevel' } },
                { kind: 'Field', name: { kind: 'Name', value: 'averageCpc' } },
                { kind: 'Field', name: { kind: 'Name', value: 'recommendLevel' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isSelected' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isCustom' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetSemKeywordsQuery, GetSemKeywordsQueryVariables>;

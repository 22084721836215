import { ProposalStatusMonthObj } from '@/shared/types';

export interface ManagementState {
  proposalStatusChartData: ProposalStatusMonthObj[];
  userAgencyActivityChartData: ProposalStatusMonthObj[];
  proposalProductsChartData: ProposalStatusMonthObj[];
}

export const defaultManagement: ManagementState = {
  proposalStatusChartData: [],
  userAgencyActivityChartData: [],
  proposalProductsChartData: [],
};

export const state = (): ManagementState => ({
  ...defaultManagement,
});

import { GetterTree } from 'vuex';
import { Client } from '@/shared/types';

import { Nullable, UnsafeAny } from '@/shared/types';
import { ClientState } from './state';
import { Container } from 'inversify';
import { LoggerContract } from '@/injectables';

// we have to keep getters any for this version of vuex
// see: https://github.com/vuejs/vuex/issues/1756
export const getters = (container: Container): GetterTree<ClientState, UnsafeAny> => {
  const logger = container.get<LoggerContract>('logger');
  return {
    activeClient: (state): Nullable<Client> => {
      try {
        if (!state.clients.loaded && !state.activeClient) {
          return null;
        }
        const clientInList = state.clients?.list?.find(c => c.id === state.activeClientId);
        if (state.activeClient) {
          return state.activeClient;
        } else {
          return clientInList || null;
        }
      } catch (err) {
        logger.print('error', 'store.client.getters.activeClient', err);
        throw err;
      }
    },
  };
};

/* eslint-disable no-unused-vars */
import { AgencyInfo } from '@/shared/types';
import { MutationTree } from 'vuex';

import { AgencyState } from './state';

export enum MutationTypes {
  SET_CURRENT_AGENCY_INFO = 'SET_CURRENT_AGENCY_INFO',
  SET_AGENCY_INFO_LOADING = 'SET_AGENCY_INFO_LOADING',
}

export const mutations = (): MutationTree<AgencyState> => ({
  [MutationTypes.SET_CURRENT_AGENCY_INFO](_state, _info: AgencyInfo): void {
    _state.currentAgencyInfo = { ..._info };
  },
  [MutationTypes.SET_AGENCY_INFO_LOADING](_state, _loading: boolean): void {
    _state.agencyInfoLoading = _loading;
  },
});

import { NewProposalState } from './types';
import { Models } from '@/injectables/tokens';
import { Container } from 'inversify';
import { CampaignModelContract } from '@/injectables/models';

export const state = (container: Container): NewProposalState => {
  const campaignModel = container.get<CampaignModelContract>(Models.Campaign);

  return {
    isProposalMarketUpdated: false,
    changeStatusNotificationOptions: { value: false, path: '' },
    dialogIsActive: false,
    isFirstProposal: false,
    // newProposal
    saveProgressLoading: false,
    awaitBudgetCheckLoading: false,
    recommendationsLoading: false,
    updatingToIncreasedRecommendationsLoading: false,
    clientDefaultsLoading: false,
    hasReceivedRecommendations: false,
    newProposal: {
      nextStatuses: [],
      startDate: '',
      endDate: '',
      id: '',
      status: '',
      createdBy: { email: '' },
      updatedBy: { email: '' },
      // increasedRecommendations: null,
      name: '',
      goal: '',
      agencyId: '',
      agency: { name: '' },
      contracts: [],
      secondaryGoal: '',
      budget: 0,
      products: [],
      demographics: [],
      audience: [],
      goLiveWitoutRetargeting: true,
      conversionTracking: campaignModel.getDefaultTrackingConfiguration(),
      market: {
        address: null,
        census: {},
        geoSelections: {
          cityList: [],
          zipList: [],
          dmaList: [],
          stateList: [],
          countyList: [],
        },
      },
    },
    shouldRedrawPolygons: false,
    censusLoading: false,
    inProgressLoading: false,
    initialMarket: {
      address: null,
      census: {},
      geoSelections: {
        cityList: [],
        zipList: [],
        dmaList: [],
        stateList: [],
        countyList: [],
      },
    },
    updateProductsLoading: false,
    // product flights
    fetchProductConfigsLoading: false,
    productConfigs: { list: [], loaded: false },
    loadingCustomKeywords: false,
    loadingKeywordsSummary: false,
    loadingKeywords: false,
    // finalize/creatives
    generatedContracts: [],
    loadingCreativeConfig: false,
    updateCreativeLoading: false,
    creativeConfigs: { list: [], loaded: false },
    addContractLoading: false,
    getContractLoading: false,
    isLoadingProposalStatuses: true,
  };
};

/**
 * MUI 1.3.1 color theme.
 */
export const theme = {
  // Main colors
  primary: '#F5006A',
  accent: '#F5006A',
  error: '#E12719',
  info: '#2E8EFF',
  success: '#3FBC56',
  warning: '#FE6F32',
  white: '#FFFFFF',
  black: '#212121',

  // Text
  'text-primary': '#212121',
  'text-secondary': '#474747',
  'text-disabled': '#9E9E9E',

  // Background
  'bg-paper': '#F7F7F7',
  'bg-default': '#FFFFFF',
  'bg-proposal': '#eceff1',

  // Other
  snackbar: '#323232',
};


import Vue from 'vue';
export default Vue.extend({
  name: 'Questionnaire',
  props: {
    questionnaire: {
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    questionText(): string {
      const markRequiredQuestion = question => {
        if (question.required) {
          return `${question.text}*`;
        } else {
          return question.text;
        }
      };
      return markRequiredQuestion(this.questionnaire.question);
    },
  },
});


import Vue from 'vue';

import { Models, Services } from '@/injectables/tokens';

export default Vue.extend({
  name: 'FlightCard',

  inject: ['showSnackbar'],

  useInjectable: [Services.Calendar, Services.Validation, Models.Flight],

  props: {
    flightNumber: {
      type: Number,
      required: true,
    },
    budget: {
      type: Number,
      required: true,
    },
    isLocked: {
      type: Boolean,
      required: false,
    },
    loading: {
      type: Boolean,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    flightImpressions: {
      type: String,
      default: null,
    },
    lockDisabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    configName(): string {
      return this.flightNumber ? `Flight ${this.flightNumber}` : '';
    },
    budgetLock() {
      return this.isLocked;
    },
    showImpressions() {
      return this.flightImpressions !== null;
    },
  },
});

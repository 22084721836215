import { nextTick } from 'vue';
import VueRouter from 'vue-router';

export const afterEachRoute = (router: VueRouter) => {
  router.afterEach(async to => {
    await nextTick();
    if (!to.meta) return;
    document.title = to.meta.title;
  });
};

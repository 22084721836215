
import Vue from 'vue';

import UrlField from '@/entities/instantIO/ui/templates/UrlField.vue';

export default Vue.extend({
  name: 'ConversionTracking',

  inject: ['$confirm'],

  components: { UrlField },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    trackVisits: {
      type: Boolean,
      default: false,
    },
    campaignTracking: {
      type: Boolean,
      default: false,
    },
    trackWebsite: {
      type: String,
      default: '',
    },
    conversionWebsites: {
      type: Array,
      default: () => [],
    },
  },

  watch: {
    async baseDomain(value) {
      if (value !== undefined) {
        await this.validateForm();
      }
    },
  },

  data() {
    return {
      conversionInputNumber: 5,
    };
  },
  computed: {
    trackVisitsValue: {
      get(): boolean {
        return this.trackVisits;
      },
      async set(value: boolean) {
        this.trackWebsiteValue = '';
        this.$emit('update:trackVisits', value);
        await this.validateForm();
      },
    },
    campaignTrackingValue: {
      get(): boolean {
        return this.campaignTracking;
      },
      async set(value: boolean) {
        this.conversionWebsitesValue = [];
        this.$emit('update:campaignTracking', value);
        await this.validateForm();
      },
    },
    trackWebsiteValue: {
      get(): string {
        return this.trackWebsite;
      },
      set(value: string) {
        this.$emit('update:trackWebsite', value);
      },
    },
    conversionWebsitesValue: {
      get(): string[] {
        const baseArray = Array.from({ length: this.conversionInputNumber }, () => '');
        const urlNumber = this.conversionWebsites.length;
        const addItemNumber = Math.max(this.conversionInputNumber - urlNumber);
        return this.conversionWebsites.concat(baseArray.slice(0, addItemNumber));
      },
      async set(value: string[]) {
        this.$emit('update:conversionWebsites', value);
        await this.validateForm();
      },
    },
    baseDomain() {
      try {
        if (this.trackWebsiteValue) {
          return this.parseHostname(this.trackWebsiteValue);
        }
        const domainNames = [...this.conversionWebsitesValue].map(url => this.parseHostname(url)).filter(Boolean);

        if (!domainNames.length) return '';

        return domainNames[0];
      } catch (e) {
        return '';
      }
    },
  },
  methods: {
    async setFirst(value: string) {
      this.trackWebsiteValue = value;
    },
    setWebsite(index: number, value: string) {
      const current = this.conversionWebsitesValue.slice();
      current[index] = value;
      this.conversionWebsitesValue = current;
    },
    parseHostname(url: string): string {
      try {
        if (url.includes('://') === false) {
          return new URL('http://' + url).hostname;
        }
        return new URL(url).hostname;
      } catch (e) {
        return '';
      }
    },
    async validateForm() {
      await this.$nextTick();
      this.$refs.urlForm.validate();
    },
  },
});

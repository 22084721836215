
import Vue from 'vue';
import { AdProductUser, NewProposalRoute, Proposal } from '@/shared/types';
import { LocalStorageServiceContract, UXUtilsServiceContract } from '@/injectables';
import { Services } from '@/injectables/tokens';
import { MediaplannerProposalStatus } from '@/app/graphql';

export default Vue.extend({
  name: 'ProposalActionsMenu',

  inject: ['$confirm'],

  props: {
    readOnly: {
      type: Boolean,
      default: false,
    },
    proposal: {
      type: Object,
      required: true,
    },
    returnToClientProposals: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String, // queue or recent
      default: 'recent',
    },
  },

  data: (): {
    menuOpened: boolean;
  } => ({
    menuOpened: false,
  }),

  computed: {
    isDeleteVisible(): boolean {
      return this.proposal.status !== MediaplannerProposalStatus.Sold;
    },
    user(): AdProductUser {
      return this.$store.state.auth.user;
    },
    isAgencyAdmin(): boolean {
      return this.$store.getters['auth/isAgencyAdmin'];
    },
  },

  methods: {
    duplicateProposal(): void {
      this.$store
        .dispatch('proposal/cloneExistingProposal', { proposalToCloneId: this.proposal.id, delay: 1000 })
        .then(res => {
          if (res && res?.id) {
            this.$emit('refetch-proposals');
          } else {
            throw new Error('Unable to duplicate proposal at this time');
          }
        })
        .catch(err => {
          this.$store.dispatch('showSnackbar', {
            content: err,
            color: 'error',
          });
          this.$log('warning', 'proposals/duplicateProposal', err);
        });
    },
    async deleteProposal(): Promise<void> {
      const { confirmed } = await this.$confirm.show({
        title: 'Delete Proposal',
        body: `This will permanently delete ${this.proposal.name || 'this proposal'}. Are you sure?`,
        confirmText: 'Yes',
        cancelText: 'No',
      });
      if (!confirmed) return;
      this.$store
        .dispatch('proposal/deleteProposal', this.proposal.id)
        .then(res => {
          if (res?.id) {
            this.$emit('refetch-proposals');
          } else {
            throw new Error('Unable to delete proposal at this time');
          }
        })
        .catch(err => {
          throw new Error(err);
        });
    },
    async resetOutput(): Promise<void> {
      const { confirmed } = await this.$confirm.show({
        title: 'Reset output',
        body: 'Are you sure that you want to revert all changes made to this output?',
        confirmText: 'Confirm',
        cancelText: 'Cancel',
      });

      if (!confirmed) return;

      this.$store
        .dispatch('output/resetOutput', { proposalId: this.proposal.id })
        .then(result => {
          if (result) {
            this.$store.dispatch('showSnackbar', {
              content: 'Output has been reset successfully',
              color: 'success',
            });
          }
        })
        .catch(err => {
          this.$store.dispatch('showSnackbar', {
            content: err,
            color: 'error',
          });
        });
    },
    getVisitedRoute({ id }: Proposal): NewProposalRoute {
      const storageService: LocalStorageServiceContract = this.$container.get(Services.LocalStorage);
      const uxService: UXUtilsServiceContract = this.$container.get(Services.UX);

      const { email } = this.user;

      return uxService.getProposalStepForUser({
        email,
        proposalId: id,
        storageService,
      });
    },
    editUrl(proposal): { name: string; params: { id: string; proposalId: string } } {
      const route = this.getVisitedRoute(proposal);
      const name = route ? route.name : 'proposalMarket';
      return {
        name,
        params: {
          id: proposal.client?.id,
          proposalId: proposal.id,
        },
      };
    },
    async handleNavToEditUrl(proposal): Promise<void> {
      this.$router.push(this.editUrl(proposal));
    },
  },
});

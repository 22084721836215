export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AccountNumber: string;
  BigInt: number;
  Byte: number;
  CountryCode: unknown;
  Cuid: unknown;
  Currency: unknown;
  DID: unknown;
  Date: string;
  DateTime: string;
  Duration: unknown;
  EmailAddress: string;
  GUID: unknown;
  HSL: unknown;
  HSLA: unknown;
  HexColorCode: string;
  Hexadecimal: number;
  IBAN: unknown;
  IPv4: string;
  IPv6: string;
  ISBN: unknown;
  ISO8601Duration: unknown;
  JSON: Record<string, any>;
  JSONObject: Record<string, any>;
  JWT: string;
  Latitude: number;
  LocalDate: string;
  LocalEndTime: string;
  LocalTime: string;
  Locale: string;
  Long: number;
  Longitude: number;
  MAC: string;
  NegativeFloat: number;
  NegativeInt: number;
  NonEmptyString: string;
  NonNegativeFloat: number;
  NonNegativeInt: number;
  NonPositiveFloat: number;
  NonPositiveInt: number;
  ObjectID: string;
  PhoneNumber: string;
  Port: string;
  PositiveFloat: number;
  PositiveInt: number;
  PostalCode: string;
  RGB: string;
  RGBA: string;
  RoutingNumber: unknown;
  SafeInt: number;
  Time: string;
  TimeZone: string;
  Timestamp: string;
  URL: string;
  USCurrency: unknown;
  UUID: string;
  UnsignedFloat: unknown;
  UnsignedInt: unknown;
  UtcOffset: unknown;
  Void: void;
};

export type AddAgencyLibrarySlideInput = {
  agencyId?: InputMaybe<Scalars['UUID']>;
  isShared?: InputMaybe<Scalars['Boolean']>;
  slide?: InputMaybe<CreateOutputPreparedSlideInput>;
};

export type AddContractInput = {
  agencyId?: InputMaybe<Scalars['UUID']>;
  proposalId: Scalars['UUID'];
  url: Scalars['URL'];
};

export type AddUserLibrarySlideInput = {
  agencyId?: InputMaybe<Scalars['UUID']>;
  slide?: InputMaybe<CreateOutputPreparedSlideInput>;
};

export type AdvancedOptionsInput = {
  abTestFlag?: InputMaybe<Scalars['Boolean']>;
  autoRenewList?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  darkPeriodList?: InputMaybe<Array<InputMaybe<DarkPeriodInput>>>;
};

export enum AgencyType {
  Agency = 'AGENCY',
  Station = 'STATION',
  Subagency = 'SUBAGENCY',
}

export type AnySlideInput = {
  _id?: InputMaybe<Scalars['String']>;
  charts?: InputMaybe<Scalars['JSON']>;
  colors?: InputMaybe<Scalars['JSON']>;
  custom?: InputMaybe<Scalars['Boolean']>;
  defaultOrder?: InputMaybe<Scalars['Int']>;
  defaultSlide?: InputMaybe<Scalars['Boolean']>;
  fromLibrary?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['UUID']>;
  images?: InputMaybe<Scalars['JSON']>;
  isProduct?: InputMaybe<Scalars['Boolean']>;
  label?: InputMaybe<Scalars['String']>;
  layoutId?: InputMaybe<Scalars['UUID']>;
  linkedProduct?: InputMaybe<Scalars['String']>;
  map?: InputMaybe<Scalars['JSON']>;
  name: Scalars['NonEmptyString'];
  order?: InputMaybe<Scalars['Int']>;
  orderAfterProducts?: InputMaybe<Scalars['Boolean']>;
  orderBeforeProducts?: InputMaybe<Scalars['Boolean']>;
  readonly?: InputMaybe<Scalars['Boolean']>;
  shapes?: InputMaybe<Scalars['JSON']>;
  slideSourceId?: InputMaybe<Scalars['UUID']>;
  slideVariable?: InputMaybe<Scalars['String']>;
  templateVariation?: InputMaybe<Scalars['JSON']>;
  textItems?: InputMaybe<Scalars['JSON']>;
  type?: InputMaybe<OutputSlideType>;
  userModified?: InputMaybe<Scalars['Boolean']>;
  visibility?: InputMaybe<SlideVisibility>;
  weight?: InputMaybe<Scalars['Int']>;
};

export enum AudienceSegmentOrderBy {
  IsMain = 'isMain',
  Name = 'name',
  SegmentCategory = 'segmentCategory',
  SegmentSubCategory = 'segmentSubCategory',
}

export type AvailsInput = {
  audienceSegments?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>;
  endDate: Scalars['Date'];
  geoSelections?: InputMaybe<MediaplannerDefaultGeoSelectionsInput>;
  productName: Scalars['String'];
  startDate: Scalars['Date'];
  totalAvails: Scalars['String'];
};

export type AvailsPdfInput = {
  demographics?: InputMaybe<DemographicsInput>;
  endDate: Scalars['Date'];
  geoSelections?: InputMaybe<MediaplannerDefaultGeoSelectionsInput>;
  productName: Scalars['String'];
  startDate: Scalars['Date'];
  totalAvails: Scalars['String'];
};

export enum AvailsProduct {
  Audio = 'AUDIO',
  Display = 'DISPLAY',
  Ott = 'OTT',
  Video = 'VIDEO',
}

export type BroadcastInfoInput = {
  broadcast?: InputMaybe<Array<InputMaybe<BroadcastInfoItemInput>>>;
  link?: InputMaybe<Scalars['String']>;
};

export type BroadcastInfoItemInput = {
  days?: InputMaybe<Array<InputMaybe<Weekdays>>>;
  endTime: Scalars['DateTime'];
  programName: Scalars['String'];
  rating: Scalars['PositiveFloat'];
  spend: Scalars['PositiveInt'];
  spots: Scalars['PositiveInt'];
  startTime: Scalars['DateTime'];
  station: Scalars['String'];
};

export type BudgetDistributionInput = {
  solutions: Array<InputMaybe<SolutionInput>>;
  totalBudget: Scalars['Int'];
};

export type ChangeProposalSlideLayoutInput = {
  agencyId?: InputMaybe<Scalars['UUID']>;
  layoutId: Scalars['UUID'];
  proposalId: Scalars['UUID'];
};

export enum ClientSortBy {
  Agency = 'agency',
  Category = 'category',
  Name = 'name',
  UpdatedAt = 'updatedAt',
}

export type ConversionInput = {
  trackConversion: Scalars['Boolean'];
  websites?: InputMaybe<Array<Scalars['URL']>>;
};

export type ConversionTrackingInput = {
  conversion?: InputMaybe<ConversionInput>;
  visits?: InputMaybe<VisitsInput>;
};

export type CreateBlankSlideInput = {
  agencyId?: InputMaybe<Scalars['UUID']>;
  slides?: InputMaybe<Array<AnySlideInput>>;
};

export type CreateCategoryInput = {
  name: Scalars['String'];
  regionalAddress: Scalars['Boolean'];
  websiteReady: Scalars['Boolean'];
};

export type CreateHelpPageInput = {
  index: Scalars['Int'];
  pageContent: Scalars['JSON'];
  parentId?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

export type CreateLayoutInput = {
  basedOnLayout?: InputMaybe<OutputLayoutTypeGql>;
  image: Scalars['String'];
  isDefault: Scalars['Boolean'];
  name: Scalars['String'];
};

export type CreateMediaplannerClientInput = {
  address?: InputMaybe<Array<InputMaybe<MediaplannerLocationInput>>>;
  agencyId?: InputMaybe<Scalars['UUID']>;
  buzzboardProspectId?: InputMaybe<Scalars['String']>;
  buzzboardRecommendedDailyBudget?: InputMaybe<Scalars['Int']>;
  categoryId: Scalars['NonEmptyString'];
  email?: InputMaybe<Scalars['String']>;
  name: Scalars['NonEmptyString'];
  phone: Scalars['String'];
  url?: InputMaybe<Scalars['URL']>;
};

export type CreateMediaplannerFlightConfigInput = {
  adFormatList?: InputMaybe<Scalars['JSON']>;
  advancedOptions?: InputMaybe<AdvancedOptionsInput>;
  agencyId?: InputMaybe<Scalars['UUID']>;
  flightCategory?: InputMaybe<FlightCategory>;
  index?: InputMaybe<Scalars['Int']>;
  mapType?: InputMaybe<MapType>;
  markupMax?: InputMaybe<Scalars['Float']>;
  markupMin?: InputMaybe<Scalars['Float']>;
  platformOption?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  rate: Scalars['Float'];
  rateType?: InputMaybe<RateType>;
  targetingOption?: InputMaybe<Scalars['String']>;
};

export type CreateMediaplannerInstantCampaignInput = {
  agencyId?: InputMaybe<Scalars['UUID']>;
  audienceSegments?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>;
  clientId: Scalars['UUID'];
  conversionTracking?: InputMaybe<ConversionTrackingInput>;
  endDate?: InputMaybe<Scalars['Date']>;
  goLiveWitoutRetargeting?: InputMaybe<Scalars['Boolean']>;
  market?: InputMaybe<MediaplannerMarketInput>;
  name: Scalars['String'];
  pixelRequest?: InputMaybe<Scalars['Boolean']>;
  products?: InputMaybe<Array<InputMaybe<CreateMediaplannerProductInput>>>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export type CreateMediaplannerPackageInput = {
  agencyId: Scalars['UUID'];
  budget: Scalars['Float'];
  description?: InputMaybe<Scalars['String']>;
  index?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  products?: InputMaybe<Array<CreateMediaplannerProductInput>>;
  proposalId?: InputMaybe<Scalars['UUID']>;
};

export type CreateMediaplannerProductConfigInput = {
  IORecipientEmail?: InputMaybe<Scalars['String']>;
  agencyId?: InputMaybe<Scalars['UUID']>;
  calcMinSpend?: InputMaybe<Scalars['Int']>;
  category: ProductConfigCategory;
  cpcMargin?: InputMaybe<Scalars['Float']>;
  creativesLimit?: InputMaybe<Scalars['Int']>;
  description: MediaplannerProductConfigDescriptionInput;
  flightConfigs?: InputMaybe<Array<InputMaybe<CreateMediaplannerFlightConfigInput>>>;
  flightType?: InputMaybe<ProductConfigFlightType>;
  fulfillmentMethod: ProductConfigFullfillmentMethod;
  isHidden: Scalars['Boolean'];
  isMonthly?: InputMaybe<Scalars['Boolean']>;
  keyMetric?: InputMaybe<KeyMetric>;
  keyMetricMultiplier?: InputMaybe<Scalars['Float']>;
  minDays: Scalars['Int'];
  minSpend: Scalars['Int'];
  name: Scalars['String'];
  omsAccountNumber?: InputMaybe<Scalars['String']>;
  omsName?: InputMaybe<Scalars['String']>;
  rateRangeList?: InputMaybe<Scalars['JSON']>;
  recommendedBudget: Scalars['Int'];
  slideType: ProductSlideType;
  type: ProductConfigType;
};

export type CreateMediaplannerProductInput = {
  IORecipientEmail?: InputMaybe<Scalars['String']>;
  broadcastInfo?: InputMaybe<BroadcastInfoInput>;
  budget: Scalars['Int'];
  calcMinSpend?: InputMaybe<Scalars['Int']>;
  category: ProductConfigCategory;
  cpcMargin?: InputMaybe<Scalars['Float']>;
  creatives?: InputMaybe<Array<InputMaybe<UpdateProductCreativeInput>>>;
  creativesLimit?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<MediaplannerProductDescriptionInput>;
  flightConfigs?: InputMaybe<Array<CreateMediaplannerFlightConfigInput>>;
  flightType?: InputMaybe<Scalars['String']>;
  flights?: InputMaybe<Array<CreateProductFlightInput>>;
  fulfillmentMethod: ProductConfigFullfillmentMethod;
  geoSelections?: InputMaybe<MediaplannerProductGeoSelectionsInput>;
  index?: InputMaybe<Scalars['Int']>;
  isEditable?: InputMaybe<Scalars['Boolean']>;
  isHidden?: InputMaybe<Scalars['Boolean']>;
  isLocked?: InputMaybe<Scalars['Boolean']>;
  isMonthly?: InputMaybe<Scalars['Boolean']>;
  keyMetric?: InputMaybe<KeyMetric>;
  keyMetricMultiplier?: InputMaybe<Scalars['Float']>;
  keywords?: InputMaybe<KeywordsInfoInput>;
  minDays?: InputMaybe<Scalars['Int']>;
  minSpend?: InputMaybe<Scalars['Int']>;
  name: Scalars['NonEmptyString'];
  noXmlFlag?: InputMaybe<Scalars['Boolean']>;
  omsAccountNumber?: InputMaybe<Scalars['String']>;
  omsName?: InputMaybe<Scalars['String']>;
  productConfigId: Scalars['UUID'];
  questionnaire?: InputMaybe<Array<InputMaybe<QuestionnaireAnswersInput>>>;
  rateRangeList?: InputMaybe<Scalars['JSON']>;
  recommendedBudget: Scalars['Int'];
  targetingOptions?: InputMaybe<Array<InputMaybe<MediaplannerProductTargetingOptionInput>>>;
  type?: InputMaybe<ProductConfigType>;
};

export type CreateMediaplannerProposalInput = {
  agencyId?: InputMaybe<Scalars['UUID']>;
  audienceSegments?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>;
  budget: Scalars['Int'];
  campaignEndDate: Scalars['Date'];
  campaignStartDate: Scalars['Date'];
  clientId: Scalars['UUID'];
  conversionTracking?: InputMaybe<ConversionTrackingInput>;
  endDate?: InputMaybe<Scalars['Date']>;
  goLiveWitoutRetargeting?: InputMaybe<Scalars['Boolean']>;
  goal: Scalars['String'];
  market?: InputMaybe<MediaplannerMarketInput>;
  name: Scalars['String'];
  packages?: InputMaybe<Array<CreateMediaplannerPackageInput>>;
  pioOpportunityId?: InputMaybe<Scalars['NonNegativeInt']>;
  pixelRequest?: InputMaybe<Scalars['Boolean']>;
  products?: InputMaybe<Array<CreateMediaplannerProductInput>>;
  secondaryGoal?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export type CreateOutputPreparedSlideInput = {
  charts?: InputMaybe<Scalars['JSON']>;
  colors?: InputMaybe<Scalars['JSON']>;
  custom?: InputMaybe<Scalars['Boolean']>;
  defaultOrder?: InputMaybe<Scalars['Int']>;
  images?: InputMaybe<Scalars['JSON']>;
  isProduct?: InputMaybe<Scalars['Boolean']>;
  label: Scalars['String'];
  layoutId: Scalars['UUID'];
  linkedProduct?: InputMaybe<Scalars['String']>;
  map?: InputMaybe<Scalars['JSON']>;
  name: Scalars['NonEmptyString'];
  orderAfterProducts?: InputMaybe<Scalars['Boolean']>;
  orderBeforeProducts?: InputMaybe<Scalars['Boolean']>;
  shapes?: InputMaybe<Scalars['JSON']>;
  slideSourceId?: InputMaybe<Scalars['String']>;
  slideVariable?: InputMaybe<Scalars['String']>;
  templateVariation?: InputMaybe<Scalars['JSON']>;
  textItems?: InputMaybe<Scalars['JSON']>;
  userModified?: InputMaybe<Scalars['Boolean']>;
  visibility: SlideVisibility;
  weight?: InputMaybe<Scalars['Int']>;
};

export type CreateProductCreativeInput = {
  agencyId?: InputMaybe<Scalars['UUID']>;
  creativeLink?: InputMaybe<Scalars['String']>;
  customMargin?: InputMaybe<Scalars['Float']>;
  description?: InputMaybe<Scalars['String']>;
  flightIds?: InputMaybe<Array<Scalars['UUID']>>;
  headline?: InputMaybe<Scalars['String']>;
  index?: InputMaybe<Scalars['Int']>;
  notes?: InputMaybe<Scalars['String']>;
  omsNumber?: InputMaybe<Scalars['String']>;
  parentId?: InputMaybe<Scalars['UUID']>;
  postText?: InputMaybe<Scalars['String']>;
  productId: Scalars['UUID'];
  selectedAdFormatList?: InputMaybe<Scalars['JSON']>;
  socialLink?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
  vastTag?: InputMaybe<Scalars['String']>;
};

export type CreateProductFlightInput = {
  adFormatList?: InputMaybe<Scalars['JSON']>;
  advancedOptions?: InputMaybe<AdvancedOptionsInput>;
  agencyId?: InputMaybe<Scalars['UUID']>;
  audienceSegments?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>;
  autoRenewType?: InputMaybe<FlightAutoRenewType>;
  budget?: InputMaybe<Scalars['Int']>;
  contextualKeywords?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  flightCategory?: InputMaybe<FlightCategory>;
  id?: InputMaybe<Scalars['UUID']>;
  index?: InputMaybe<Scalars['Int']>;
  isLocked?: InputMaybe<Scalars['Boolean']>;
  mapType?: InputMaybe<MapType>;
  market?: InputMaybe<Scalars['JSON']>;
  platformOption?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  rate?: InputMaybe<Scalars['Float']>;
  rateType?: InputMaybe<RateType>;
  selectedPlatform?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  targetingOption?: InputMaybe<Scalars['String']>;
};

export type CreateProductSlideWithChildrenTemplateInput = {
  agencyId: Scalars['UUID'];
  layoutId?: InputMaybe<Scalars['UUID']>;
  productConfigId: Scalars['UUID'];
};

export enum CronStatuses {
  Failed = 'FAILED',
  Succeeded = 'SUCCEEDED',
}

export type CustomKeywordsInput = {
  agencyId?: InputMaybe<Scalars['UUID']>;
  geoSelection?: InputMaybe<GoogleGeoSelectionsInput>;
  keywords?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  productConfigId?: InputMaybe<Scalars['UUID']>;
};

export type DarkPeriodInput = {
  endDate: Scalars['DateTime'];
  index: Scalars['Int'];
  startDate: Scalars['DateTime'];
};

export type DemographicInput = {
  demo: Scalars['String'];
  list?: InputMaybe<Array<InputMaybe<DemographicSubItemInput>>>;
};

export type DemographicSubItemInput = {
  isSelected: Scalars['Boolean'];
  property: Scalars['String'];
  value: Scalars['Int'];
};

export type DemographicsInput = {
  list?: InputMaybe<Array<InputMaybe<DemographicInput>>>;
  targeted: Scalars['Int'];
  totalPopulation: Scalars['Int'];
};

export enum FlightAutoRenewType {
  Flight = 'Flight',
  MonthToMonth = 'MonthToMonth',
}

export enum FlightCategory {
  Advplus = 'ADVPLUS',
  Advplusshop = 'ADVPLUSSHOP',
  Amazonprem = 'AMAZONPREM',
  Amazonvalue = 'AMAZONVALUE',
  Ammnrt = 'AMMNRT',
  At = 'AT',
  Atrt = 'ATRT',
  Brandaware = 'BRANDAWARE',
  Bumper = 'BUMPER',
  Can = 'CAN',
  Context = 'CONTEXT',
  Conversion = 'CONVERSION',
  Cs = 'CS',
  Ctvpreroll = 'CTVPREROLL',
  Deploy = 'DEPLOY',
  Displayrt = 'DISPLAYRT',
  Dynamicctv = 'DYNAMICCTV',
  Emarkt = 'EMARKT',
  Engage = 'ENGAGE',
  Fbrt = 'FBRT',
  Foottrafdis = 'FOOTTRAFDIS',
  Foottrafvid = 'FOOTTRAFVID',
  Geowfta = 'GEOWFTA',
  Geowoftadis = 'GEOWOFTADIS',
  Geowoftavid = 'GEOWOFTAVID',
  Histlocwfta = 'HISTLOCWFTA',
  Hulu = 'HULU',
  Kwt = 'KWT',
  Leadgen = 'LEADGEN',
  Matchback = 'MATCHBACK',
  Mn = 'MN',
  Mnrt = 'MNRT',
  Mnw = 'MNW',
  Native = 'NATIVE',
  Netflix = 'NETFLIX',
  Nonskipvid = 'NONSKIPVID',
  Other = 'OTHER',
  Overlay = 'OVERLAY',
  Political = 'POLITICAL',
  Prem = 'PREM',
  Reblast = 'REBLAST',
  Rt = 'RT',
  Shopping = 'SHOPPING',
  Skipvid = 'SKIPVID',
  Sm = 'SM',
  Smprem = 'SMPREM',
  Smvalue = 'SMVALUE',
  Spa = 'SPA',
  Stdwftadis = 'STDWFTADIS',
  Stdwftavid = 'STDWFTAVID',
  Stdwoftadis = 'STDWOFTADIS',
  Stdwoftavid = 'STDWOFTAVID',
  Stream = 'STREAM',
  Superprem = 'SUPERPREM',
  Supervalue = 'SUPERVALUE',
  Traffic = 'TRAFFIC',
  Twitch = 'TWITCH',
  Value = 'VALUE',
}

export type GeoIntegrationsInput = {
  cities?: InputMaybe<Array<Scalars['String']>>;
  congressionalDistricts?: InputMaybe<Array<Scalars['String']>>;
  counties?: InputMaybe<Array<Scalars['String']>>;
  dmacodes?: InputMaybe<Array<Scalars['String']>>;
  dmas?: InputMaybe<Array<Scalars['String']>>;
  outermarkets?: InputMaybe<Array<Scalars['String']>>;
  states?: InputMaybe<Array<Scalars['String']>>;
  zips?: InputMaybe<Array<Scalars['String']>>;
};

export type GeoLocationsInput = {
  cities?: InputMaybe<Array<Scalars['String']>>;
  congressionalDistricts?: InputMaybe<Array<Scalars['String']>>;
  counties?: InputMaybe<Array<Scalars['String']>>;
  dmas?: InputMaybe<Array<Scalars['String']>>;
  endDate: Scalars['Date'];
  product?: InputMaybe<AvailsProduct>;
  segments?: InputMaybe<Array<Scalars['String']>>;
  startDate: Scalars['Date'];
  states?: InputMaybe<Array<Scalars['String']>>;
  zips?: InputMaybe<Array<Scalars['String']>>;
};

export type GetDefaultSlideTemplateInput = {
  layoutId: Scalars['UUID'];
  name: Scalars['String'];
};

export type GetFilledSlidesInput = {
  agencyId?: InputMaybe<Scalars['UUID']>;
  proposalId: Scalars['UUID'];
  slides: Array<AnySlideInput>;
};

export type GetLibrarySlideInput = {
  agencyId: Scalars['UUID'];
  slideId: Scalars['UUID'];
};

export type GetMediaplannerClientsInput = {
  agencyId?: InputMaybe<Scalars['UUID']>;
  categoryId?: InputMaybe<Scalars['UUID']>;
  limit?: InputMaybe<Scalars['Int']>;
  modifiedDateRange?: InputMaybe<MediaplannerDateRangeInput>;
  offset?: InputMaybe<Scalars['Int']>;
  searchKey?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<ClientSortBy>;
  sortDirection?: InputMaybe<SortDirection>;
};

export type GetPackageSlideTemplateInput = {
  agencyId?: InputMaybe<Scalars['UUID']>;
  layoutId?: InputMaybe<Scalars['UUID']>;
  packageConfigId: Scalars['UUID'];
};

export type GetProductSlideTemplateInput = {
  agencyId?: InputMaybe<Scalars['UUID']>;
  layoutId?: InputMaybe<Scalars['UUID']>;
  productConfigId: Scalars['UUID'];
};

export type GetSlideLibraryInput = {
  agencyId: Scalars['UUID'];
  layout?: InputMaybe<Scalars['String']>;
  proposalId?: InputMaybe<Scalars['UUID']>;
};

export type GetTemplateLibraryInput = {
  agencyId?: InputMaybe<Scalars['UUID']>;
  layoutId: Scalars['UUID'];
};

export type GoogleGeoSelectionsInput = {
  cities?: InputMaybe<Array<Scalars['String']>>;
  counties?: InputMaybe<Array<Scalars['String']>>;
  dmas?: InputMaybe<Array<Scalars['String']>>;
  states?: InputMaybe<Array<Scalars['String']>>;
  zips?: InputMaybe<Array<Scalars['String']>>;
};

export enum InstantCampaignOrderBy {
  Budget = 'budget',
  ClientName = 'clientName',
  CreatedBy = 'createdBy',
  EndDate = 'endDate',
  Name = 'name',
  StartDate = 'startDate',
  Status = 'status',
  UpdatedAt = 'updatedAt',
}

export enum InstantCampaignStatus {
  Inprogress = 'INPROGRESS',
  Sold = 'SOLD',
}

export enum KeyMetric {
  Cards = 'CARDS',
  Clicks = 'CLICKS',
  Emails = 'EMAILS',
  Impressions = 'IMPRESSIONS',
  ReachAndFrequency = 'REACH_AND_FREQUENCY',
  Spots = 'SPOTS',
  Views = 'VIEWS',
}

export type KeywordIdeasInput = {
  agencyId?: InputMaybe<Scalars['UUID']>;
  geoSelection?: InputMaybe<GoogleGeoSelectionsInput>;
  keywords: Array<InputMaybe<Scalars['String']>>;
  limit?: InputMaybe<Scalars['Int']>;
  productConfigId?: InputMaybe<Scalars['UUID']>;
  url: Scalars['String'];
};

export type KeywordPlannerInput = {
  budget: Scalars['Int'];
  endDate?: InputMaybe<Scalars['Date']>;
  geoSelections?: InputMaybe<MediaplannerProductGeoSelectionsInput>;
  keywordStatistics?: InputMaybe<Array<InputMaybe<KeywordStatisticsInput>>>;
  startDate?: InputMaybe<Scalars['Date']>;
  url: Scalars['String'];
};

export type KeywordStatisticsInput = {
  averageCpc?: InputMaybe<Scalars['Float']>;
  competition?: InputMaybe<Scalars['Int']>;
  competitionLevel?: InputMaybe<Scalars['String']>;
  isCustom?: InputMaybe<Scalars['Boolean']>;
  isSelected?: InputMaybe<Scalars['Boolean']>;
  keyword?: InputMaybe<Scalars['String']>;
  recommendLevel?: InputMaybe<Scalars['Int']>;
  searchVolume?: InputMaybe<Scalars['Int']>;
};

export type KeywordSummaryInput = {
  budget?: InputMaybe<Scalars['Int']>;
  keywords?: InputMaybe<Array<InputMaybe<KeywordStatisticsInput>>>;
};

export type KeywordsInfoInput = {
  keymetrics?: InputMaybe<Scalars['String']>;
  list?: InputMaybe<Array<InputMaybe<KeywordStatisticsInput>>>;
  summary?: InputMaybe<KeywordsSummaryInput>;
};

export type KeywordsSummaryInput = {
  lowerClicks?: InputMaybe<Scalars['Int']>;
  lowerCpc?: InputMaybe<Scalars['Float']>;
  numKeywords?: InputMaybe<Scalars['Int']>;
  totalClicks?: InputMaybe<Scalars['Int']>;
  totalCpc?: InputMaybe<Scalars['Float']>;
  upperClicks?: InputMaybe<Scalars['Int']>;
  upperCpc?: InputMaybe<Scalars['Float']>;
};

export enum MapType {
  Polygon = 'POLYGON',
  Radius = 'RADIUS',
}

export type MediaplannerAgencyAddressInput = {
  address1?: InputMaybe<Scalars['String']>;
  address2?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  lat: Scalars['Latitude'];
  lon: Scalars['Longitude'];
  state: Scalars['String'];
  zip: Scalars['PostalCode'];
};

export type MediaplannerAgencyDefaultLayoutInput = {
  agencyId?: InputMaybe<Scalars['UUID']>;
  defaultLayoutId: Scalars['UUID'];
};

export type MediaplannerAgencyInfoInput = {
  address?: InputMaybe<MediaplannerAgencyAddressInput>;
  agencyId?: InputMaybe<Scalars['UUID']>;
  description?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
};

export type MediaplannerDateRangeInput = {
  end: Scalars['Date'];
  start: Scalars['Date'];
};

export type MediaplannerDefaultGeoSelectionsInput = {
  cityList?: InputMaybe<Array<MediaplannerDefaultGeoSelectionsItemInput>>;
  countyList?: InputMaybe<Array<MediaplannerDefaultGeoSelectionsItemInput>>;
  dmaList?: InputMaybe<Array<MediaplannerDefaultGeoSelectionsItemInput>>;
  stateList?: InputMaybe<Array<MediaplannerDefaultGeoSelectionsItemInput>>;
  zipList?: InputMaybe<Array<MediaplannerDefaultGeoSelectionsItemInput>>;
};

export type MediaplannerDefaultGeoSelectionsItemInput = {
  key?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type MediaplannerLocationInput = {
  address?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  county?: InputMaybe<Scalars['String']>;
  label?: InputMaybe<Scalars['String']>;
  lat?: InputMaybe<Scalars['Float']>;
  lon?: InputMaybe<Scalars['Float']>;
  state?: InputMaybe<Scalars['String']>;
  zip?: InputMaybe<Scalars['String']>;
};

export type MediaplannerMarketInput = {
  customList?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  demographics?: InputMaybe<Scalars['JSON']>;
  enthusiastList?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  geoSelections?: InputMaybe<MediaplannerDefaultGeoSelectionsInput>;
  lifestyleList?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  occupationList?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  searchHistoryList?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  shoppingList?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type MediaplannerPackageConfigToggleVisibilityInput = {
  agencyId?: InputMaybe<Scalars['UUID']>;
  packageId: Scalars['UUID'];
};

export type MediaplannerProductConfigDescriptionInput = {
  short?: InputMaybe<Scalars['String']>;
};

export type MediaplannerProductDescriptionChildInput = {
  content?: InputMaybe<Scalars['String']>;
  heading?: InputMaybe<Scalars['String']>;
};

export type MediaplannerProductDescriptionInput = {
  highlights?: InputMaybe<Array<InputMaybe<MediaplannerProductDescriptionChildInput>>>;
  main?: InputMaybe<MediaplannerProductDescriptionChildInput>;
  short?: InputMaybe<Scalars['String']>;
};

export type MediaplannerProductGeoSelectionsInput = {
  cityList?: InputMaybe<Array<MediaplannerDefaultGeoSelectionsItemInput>>;
  countyList?: InputMaybe<Array<MediaplannerDefaultGeoSelectionsItemInput>>;
  dmaList?: InputMaybe<Array<MediaplannerDefaultGeoSelectionsItemInput>>;
  stateList?: InputMaybe<Array<MediaplannerDefaultGeoSelectionsItemInput>>;
};

export type MediaplannerProductTargetingOptionInput = {
  adFormatList?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  rate?: InputMaybe<Scalars['String']>;
  rateType?: InputMaybe<RateType>;
  targetingOption?: InputMaybe<Scalars['String']>;
};

export type MediaplannerProposalBooleansInput = {
  agencyId?: InputMaybe<Scalars['UUID']>;
  conversionTracking?: InputMaybe<ConversionTrackingInput>;
  goLiveWitoutRetargeting?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['UUID'];
  pixelRequest?: InputMaybe<Scalars['Boolean']>;
};

export enum MediaplannerProposalStatus {
  Approved = 'Approved',
  ClientApproved = 'ClientApproved',
  ClosedLost = 'ClosedLost',
  InProgress = 'InProgress',
  Sold = 'Sold',
  SubmittedForReview = 'SubmittedForReview',
  UnderReview = 'UnderReview',
}

export type MediaplannerRateParamsInput = {
  markupMax?: InputMaybe<Scalars['Float']>;
  markupMin?: InputMaybe<Scalars['Float']>;
};

export type OutputLayoutInfoInput = {
  layoutId: Scalars['UUID'];
  packageConfigId?: InputMaybe<Scalars['UUID']>;
  productConfigId?: InputMaybe<Scalars['UUID']>;
};

export enum OutputLayoutTypeGql {
  Ballys = 'BALLYS',
  Custom = 'CUSTOM',
  Ferocious = 'FEROCIOUS',
  Ferociousdigital = 'FEROCIOUSDIGITAL',
  Genericblue = 'GENERICBLUE',
  Genericdark = 'GENERICDARK',
  Genericlight = 'GENERICLIGHT',
  Genericsecond = 'GENERICSECOND',
  Johnsonmedia = 'JOHNSONMEDIA',
  Mediaagent = 'MEDIAAGENT',
  Nexstar = 'NEXSTAR',
  Realitymarketing = 'REALITYMARKETING',
  Sinclairalternative = 'SINCLAIRALTERNATIVE',
  Sinclar = 'SINCLAR',
  Skyline = 'SKYLINE',
  Talonmedia = 'TALONMEDIA',
  Today = 'TODAY',
  Todaymedia = 'TODAYMEDIA',
  Ultimate = 'ULTIMATE',
}

export enum OutputSlideType {
  Prepared = 'PREPARED',
  Product = 'PRODUCT',
  Proposal = 'PROPOSAL',
  Template = 'TEMPLATE',
}

export enum ProductCategoriesWithoutFlights {
  Linear = 'LINEAR',
  Radio = 'RADIO',
  Search = 'SEARCH',
}

export enum ProductConfigCategory {
  Ctv = 'CTV',
  Digaud = 'DIGAUD',
  Digvid = 'DIGVID',
  Display = 'DISPLAY',
  Email = 'EMAIL',
  Geofen = 'GEOFEN',
  Linear = 'LINEAR',
  Other = 'OTHER',
  Ott = 'OTT',
  Package = 'PACKAGE',
  Radio = 'RADIO',
  Search = 'SEARCH',
  Social = 'SOCIAL',
  Test = 'TEST',
  Video = 'VIDEO',
}

export enum ProductConfigFlightType {
  Fixedrate = 'FIXEDRATE',
  Social = 'SOCIAL',
  Standard = 'STANDARD',
  Variablerate = 'VARIABLERATE',
}

export enum ProductConfigFullfillmentMethod {
  Compulse = 'COMPULSE',
  Inhouse = 'INHOUSE',
  Oms = 'OMS',
}

export enum ProductConfigType {
  AudienceExtensionDisplay = 'AUDIENCE_EXTENSION_DISPLAY',
  AudienceExtensionVideo = 'AUDIENCE_EXTENSION_VIDEO',
  CompulseDigitalVideo = 'COMPULSE_DIGITAL_VIDEO',
  CompulseOtt = 'COMPULSE_OTT',
  Ctv = 'CTV',
  DigitalAudio = 'DIGITAL_AUDIO',
  DigitalVideo = 'DIGITAL_VIDEO',
  Display = 'DISPLAY',
  EmailMarketing = 'EMAIL_MARKETING',
  Geofence = 'GEOFENCE',
  GeofenceDisplay = 'GEOFENCE_DISPLAY',
  GeofenceVideo = 'GEOFENCE_VIDEO',
  GoogleSearch = 'GOOGLE_SEARCH',
  Linear = 'LINEAR',
  Meta = 'META',
  NewsonOtt = 'NEWSON_OTT',
  OoDisplay = 'OO_DISPLAY',
  OoVideo = 'OO_VIDEO',
  Radio = 'RADIO',
  Regular = 'REGULAR',
  RideshareOtt = 'RIDESHARE_OTT',
  SmartMailer = 'SMART_MAILER',
  SportsOtt = 'SPORTS_OTT',
  StandardDigitalVideo = 'STANDARD_DIGITAL_VIDEO',
  StandardOtt = 'STANDARD_OTT',
  TargetedEmail = 'TARGETED_EMAIL',
  VideoPreRoll = 'VIDEO_PRE_ROLL',
  Youtube = 'YOUTUBE',
  YoutubeTrueView = 'YOUTUBE_TRUE_VIEW',
}

export enum ProductSlideType {
  Amzctv = 'AMZCTV',
  Audext = 'AUDEXT',
  Default = 'DEFAULT',
  Digaud = 'DIGAUD',
  Digdis = 'DIGDIS',
  Digvid = 'DIGVID',
  Email = 'EMAIL',
  Geofen = 'GEOFEN',
  Linear = 'LINEAR',
  Natadv = 'NATADV',
  Newson = 'NEWSON',
  Outrch = 'OUTRCH',
  Perftv = 'PERFTV',
  Prgdis = 'PRGDIS',
  Prgvid = 'PRGVID',
  Radio = 'RADIO',
  Ridshr = 'RIDSHR',
  Search = 'SEARCH',
  Smrtml = 'SMRTML',
  Social = 'SOCIAL',
  Stdott = 'STDOTT',
  Straud = 'STRAUD',
  Youtub = 'YOUTUB',
}

export enum ProductXmlFormat {
  Aos = 'AOS',
  Osi = 'OSI',
}

export enum ProposalOrderBy {
  Budget = 'budget',
  CampaignEndDate = 'campaignEndDate',
  CampaignStartDate = 'campaignStartDate',
  ClientName = 'clientName',
  CreatedBy = 'createdBy',
  Name = 'name',
  Status = 'status',
  UpdatedAt = 'updatedAt',
}

export type ProposalSlideInput = {
  _id?: InputMaybe<Scalars['String']>;
  agencyId?: InputMaybe<Scalars['UUID']>;
  charts?: InputMaybe<Scalars['JSON']>;
  colors?: InputMaybe<Scalars['JSON']>;
  custom?: InputMaybe<Scalars['Boolean']>;
  defaultOrder?: InputMaybe<Scalars['Int']>;
  defaultSlide?: InputMaybe<Scalars['Boolean']>;
  fromLibrary?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['UUID']>;
  images?: InputMaybe<Scalars['JSON']>;
  isProduct?: InputMaybe<Scalars['Boolean']>;
  layoutId: Scalars['UUID'];
  linkedProduct?: InputMaybe<Scalars['String']>;
  map?: InputMaybe<Scalars['JSON']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  orderAfterProducts?: InputMaybe<Scalars['Boolean']>;
  orderBeforeProducts?: InputMaybe<Scalars['Boolean']>;
  readonly?: InputMaybe<Scalars['Boolean']>;
  shapes?: InputMaybe<Scalars['JSON']>;
  slideSourceId?: InputMaybe<Scalars['String']>;
  slideVariable?: InputMaybe<Scalars['String']>;
  templateVariation?: InputMaybe<Scalars['JSON']>;
  textItems?: InputMaybe<Scalars['JSON']>;
  type?: InputMaybe<OutputSlideType>;
  unsavedChanges?: InputMaybe<Scalars['Boolean']>;
  userId?: InputMaybe<Scalars['UUID']>;
  userModified?: InputMaybe<Scalars['Boolean']>;
  visibility?: InputMaybe<SlideVisibility>;
  weight?: InputMaybe<Scalars['Int']>;
};

export type QuestionnaireAnswersInput = {
  answer: Scalars['String'];
  id?: InputMaybe<Scalars['UUID']>;
  index: Scalars['Int'];
  questionnaireId: Scalars['UUID'];
};

export enum RateType {
  Costper = 'COSTPER',
  Cpc = 'CPC',
  Cpm = 'CPM',
  Flatrate = 'FLATRATE',
  Monthly = 'MONTHLY',
  Quote = 'QUOTE',
}

export type RemoveContractInput = {
  agencyId?: InputMaybe<Scalars['UUID']>;
  id: Scalars['UUID'];
  proposalId: Scalars['UUID'];
};

export type ResetProposalOutputInput = {
  agencyId?: InputMaybe<Scalars['UUID']>;
  proposalId: Scalars['UUID'];
};

export enum SchedulerTaskType {
  Ahagencies = 'AHAGENCIES',
  Ahclients = 'AHCLIENTS',
  Ahstations = 'AHSTATIONS',
  Ahsubagencies = 'AHSUBAGENCIES',
  Ahusers = 'AHUSERS',
  Segments = 'SEGMENTS',
}

export enum SegmentCategory {
  AdvancedDemographic = 'ADVANCED_DEMOGRAPHIC',
  AudienceProfiles = 'AUDIENCE_PROFILES',
  Demographic = 'DEMOGRAPHIC',
  Enthusiast = 'ENTHUSIAST',
  Occupation = 'OCCUPATION',
  Other = 'OTHER',
  SearchHistory = 'SEARCH_HISTORY',
  Services = 'SERVICES',
  Shopping = 'SHOPPING',
}

export enum SegmentSubCategory {
  Age = 'AGE',
  Auto = 'AUTO',
  ClothingShoesJewelry = 'CLOTHING_SHOES_JEWELRY',
  Education = 'EDUCATION',
  Electronics = 'ELECTRONICS',
  Ethnicity = 'ETHNICITY',
  Family = 'FAMILY',
  Gender = 'GENDER',
  Grocery = 'GROCERY',
  HomeGarden = 'HOME_GARDEN',
  HomeOwnership = 'HOME_OWNERSHIP',
  HomeType = 'HOME_TYPE',
  HomeValue = 'HOME_VALUE',
  Housing = 'HOUSING',
  Income = 'INCOME',
  LengthOfResidence = 'LENGTH_OF_RESIDENCE',
  LifeEvent = 'LIFE_EVENT',
  MusicMoviesGames = 'MUSIC_MOVIES_GAMES',
  RelationshipStatus = 'RELATIONSHIP_STATUS',
  Religion = 'RELIGION',
  ToolsIndustrial = 'TOOLS_INDUSTRIAL',
  Travel = 'TRAVEL',
}

export type SetNewPasswordInput = {
  id: Scalars['UUID'];
  password: Scalars['String'];
  token: Scalars['String'];
};

export type SetProductFlightConfigInput = {
  flightConfigId: Scalars['UUID'];
  flightId: Scalars['UUID'];
};

export enum SlideVisibility {
  Deleted = 'DELETED',
  Hidden = 'HIDDEN',
  Visible = 'VISIBLE',
}

export type SolutionInput = {
  budget: Scalars['Int'];
  calcMinSpend?: InputMaybe<Scalars['Int']>;
  flights?: InputMaybe<Array<InputMaybe<SolutionItemInput>>>;
  id?: InputMaybe<Scalars['UUID']>;
  isChanged: Scalars['Boolean'];
  isLocked: Scalars['Boolean'];
  isMonthly?: InputMaybe<Scalars['Boolean']>;
  minSpend: Scalars['Int'];
};

export type SolutionItemInput = {
  budget: Scalars['Int'];
  id?: InputMaybe<Scalars['UUID']>;
  isChanged: Scalars['Boolean'];
  isLocked: Scalars['Boolean'];
};

export enum SortDirection {
  Asc = 'asc',
  Desc = 'desc',
}

export type UpdateBlankSlideInput = {
  agencyId?: InputMaybe<Scalars['UUID']>;
  id: Scalars['UUID'];
  slides?: InputMaybe<Array<UpdateTemplateSlideInput>>;
};

export type UpdateCategoryInput = {
  id: Scalars['UUID'];
  name?: InputMaybe<Scalars['String']>;
  regionalAddress?: InputMaybe<Scalars['Boolean']>;
  websiteReady?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateHelpPageInput = {
  id: Scalars['UUID'];
  index?: InputMaybe<Scalars['Int']>;
  pageContent?: InputMaybe<Scalars['JSON']>;
  parentId?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateLayoutColorScheme = {
  accent?: InputMaybe<Scalars['String']>;
  border?: InputMaybe<Scalars['String']>;
  card?: InputMaybe<Scalars['String']>;
  cardTextBody?: InputMaybe<Scalars['String']>;
  cardTextHeading?: InputMaybe<Scalars['String']>;
  cardTextSubheading?: InputMaybe<Scalars['String']>;
  mapLayer?: InputMaybe<Scalars['String']>;
  primary?: InputMaybe<Scalars['String']>;
  textBody?: InputMaybe<Scalars['String']>;
  textHeading?: InputMaybe<Scalars['String']>;
  textSubheading?: InputMaybe<Scalars['String']>;
};

export type UpdateLibrarySlideInput = {
  agencyId?: InputMaybe<Scalars['UUID']>;
  slide: UpdateTemplateSlideInput;
  slideLibraryId: Scalars['UUID'];
};

export type UpdateMediaplannerAgencyInput = {
  adminIoOnly?: InputMaybe<Scalars['Boolean']>;
  adminReviewEnabled?: InputMaybe<Scalars['Boolean']>;
  agencyId: Scalars['UUID'];
  audienceSegmentLimit?: InputMaybe<Scalars['Int']>;
  canCreateClient?: InputMaybe<Scalars['Boolean']>;
  canGenerateContracts?: InputMaybe<Scalars['Boolean']>;
  canHavePackages?: InputMaybe<Scalars['Boolean']>;
  canSetConversionTracking?: InputMaybe<Scalars['Boolean']>;
  canSetPixelRequestForInstantCampaign?: InputMaybe<Scalars['Boolean']>;
  congressionalDistrictAvailsEnabled?: InputMaybe<Scalars['Boolean']>;
  dynamicColorsLayoutEnabled?: InputMaybe<Scalars['Boolean']>;
  instantIOEnabled?: InputMaybe<Scalars['Boolean']>;
  ioEmailEnabled?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['NonEmptyString']>;
  placementEnabled?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateMediaplannerClientInput = {
  address?: InputMaybe<Array<MediaplannerLocationInput>>;
  agencyId?: InputMaybe<Scalars['UUID']>;
  buzzboardProspectId?: InputMaybe<Scalars['String']>;
  buzzboardRecommendedDailyBudget?: InputMaybe<Scalars['Int']>;
  categoryId?: InputMaybe<Scalars['NonEmptyString']>;
  contactEmail?: InputMaybe<Scalars['String']>;
  contactName?: InputMaybe<Scalars['String']>;
  contactPhone?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  geoSelections?: InputMaybe<MediaplannerDefaultGeoSelectionsInput>;
  id: Scalars['UUID'];
  logo?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['URL']>;
};

export type UpdateMediaplannerFlightConfigInput = {
  adFormatList?: InputMaybe<Scalars['JSON']>;
  advancedOptions?: InputMaybe<AdvancedOptionsInput>;
  flightCategory?: InputMaybe<FlightCategory>;
  id?: InputMaybe<Scalars['UUID']>;
  index?: InputMaybe<Scalars['Int']>;
  mapType?: InputMaybe<MapType>;
  markupMax?: InputMaybe<Scalars['Float']>;
  markupMin?: InputMaybe<Scalars['Float']>;
  platformOption?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  rate: Scalars['Float'];
  rateType?: InputMaybe<RateType>;
  targetingOption?: InputMaybe<Scalars['String']>;
};

export type UpdateMediaplannerInstantCampaignInput = {
  agencyId?: InputMaybe<Scalars['UUID']>;
  audienceSegments?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>;
  conversionTracking?: InputMaybe<ConversionTrackingInput>;
  endDate: Scalars['Date'];
  goLiveWitoutRetargeting?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['UUID'];
  market?: InputMaybe<MediaplannerMarketInput>;
  name: Scalars['String'];
  pixelRequest?: InputMaybe<Scalars['Boolean']>;
  products?: InputMaybe<Array<InputMaybe<UpdateMediaplannerProductInput>>>;
  startDate: Scalars['Date'];
};

export type UpdateMediaplannerPackageInput = {
  agencyId?: InputMaybe<Scalars['UUID']>;
  budget?: InputMaybe<Scalars['Float']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['UUID']>;
  index?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  products?: InputMaybe<Array<InputMaybe<UpdateMediaplannerProductInput>>>;
};

export type UpdateMediaplannerProductConfigInput = {
  IORecipientEmail?: InputMaybe<Scalars['String']>;
  agencyId?: InputMaybe<Scalars['UUID']>;
  calcMinSpend?: InputMaybe<Scalars['Int']>;
  category?: InputMaybe<ProductConfigCategory>;
  cpcMargin?: InputMaybe<Scalars['Float']>;
  creativesLimit?: InputMaybe<Scalars['Int']>;
  description: MediaplannerProductDescriptionInput;
  flightConfigs?: InputMaybe<Array<InputMaybe<UpdateMediaplannerFlightConfigInput>>>;
  flightType?: InputMaybe<ProductConfigFlightType>;
  fulfillmentMethod?: InputMaybe<ProductConfigFullfillmentMethod>;
  id: Scalars['UUID'];
  isHidden?: InputMaybe<Scalars['Boolean']>;
  isMonthly?: InputMaybe<Scalars['Boolean']>;
  keyMetric?: InputMaybe<KeyMetric>;
  keyMetricMultiplier?: InputMaybe<Scalars['Float']>;
  minDays?: InputMaybe<Scalars['Int']>;
  minSpend?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  omsAccountNumber?: InputMaybe<Scalars['String']>;
  omsName?: InputMaybe<Scalars['String']>;
  rateRangeList?: InputMaybe<Scalars['JSON']>;
  recommendedBudget?: InputMaybe<Scalars['Int']>;
  slideType?: InputMaybe<ProductSlideType>;
  type?: InputMaybe<ProductConfigType>;
};

export type UpdateMediaplannerProductInput = {
  IORecipientEmail?: InputMaybe<Scalars['String']>;
  broadcastInfo?: InputMaybe<BroadcastInfoInput>;
  budget: Scalars['Int'];
  calcMinSpend?: InputMaybe<Scalars['Int']>;
  category: ProductConfigCategory;
  cpcMargin?: InputMaybe<Scalars['Float']>;
  creatives?: InputMaybe<Array<InputMaybe<UpdateProductCreativeInput>>>;
  creativesLimit?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<MediaplannerProductDescriptionInput>;
  flightConfigs?: InputMaybe<Array<InputMaybe<UpdateMediaplannerFlightConfigInput>>>;
  flightType?: InputMaybe<Scalars['String']>;
  flights?: InputMaybe<Array<InputMaybe<UpdateProductFlightInput>>>;
  fulfillmentMethod: ProductConfigFullfillmentMethod;
  geoSelections?: InputMaybe<MediaplannerProductGeoSelectionsInput>;
  id?: InputMaybe<Scalars['UUID']>;
  index?: InputMaybe<Scalars['Int']>;
  isChanged?: InputMaybe<Scalars['Boolean']>;
  isEditable?: InputMaybe<Scalars['Boolean']>;
  isHidden?: InputMaybe<Scalars['Boolean']>;
  isLocked?: InputMaybe<Scalars['Boolean']>;
  isMonthly?: InputMaybe<Scalars['Boolean']>;
  keyMetric?: InputMaybe<KeyMetric>;
  keyMetricMultiplier?: InputMaybe<Scalars['Float']>;
  keywords?: InputMaybe<KeywordsInfoInput>;
  minDays?: InputMaybe<Scalars['Int']>;
  minSpend?: InputMaybe<Scalars['Int']>;
  name: Scalars['NonEmptyString'];
  noXmlFlag?: InputMaybe<Scalars['Boolean']>;
  omsAccountNumber?: InputMaybe<Scalars['String']>;
  omsName?: InputMaybe<Scalars['String']>;
  productConfigId: Scalars['UUID'];
  questionnaire?: InputMaybe<Array<InputMaybe<QuestionnaireAnswersInput>>>;
  rateRangeList?: InputMaybe<Scalars['JSON']>;
  recommendedBudget: Scalars['Int'];
  targetingOptions?: InputMaybe<Array<InputMaybe<MediaplannerProductTargetingOptionInput>>>;
  type?: InputMaybe<ProductConfigType>;
};

export type UpdateMediaplannerProposalAudienceSegmentInput = {
  agencyId?: InputMaybe<Scalars['UUID']>;
  audienceSegments?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>;
  id: Scalars['UUID'];
};

export type UpdateMediaplannerProposalInfoInput = {
  agencyId?: InputMaybe<Scalars['UUID']>;
  campaignEndDate: Scalars['Date'];
  campaignStartDate: Scalars['Date'];
  goLiveWitoutRetargeting?: InputMaybe<Scalars['Boolean']>;
  goal: Scalars['String'];
  id: Scalars['UUID'];
  name: Scalars['String'];
  pixelRequest?: InputMaybe<Scalars['Boolean']>;
  secondaryGoal?: InputMaybe<Scalars['String']>;
};

export type UpdateMediaplannerProposalMarketInput = {
  agencyId?: InputMaybe<Scalars['UUID']>;
  id: Scalars['UUID'];
  market?: InputMaybe<MediaplannerMarketInput>;
};

export type UpdateMediaplannerProposalSolutionsInput = {
  agencyId?: InputMaybe<Scalars['UUID']>;
  budget: Scalars['Int'];
  id: Scalars['UUID'];
  packages: Array<UpdateMediaplannerSolutionsPackageInput>;
  products: Array<UpdateMediaplannerProductInput>;
};

export type UpdateMediaplannerProposalStatusInput = {
  agencyId?: InputMaybe<Scalars['UUID']>;
  id: Scalars['UUID'];
  status: MediaplannerProposalStatus;
};

export type UpdateMediaplannerSolutionsPackageInput = {
  agencyId?: InputMaybe<Scalars['UUID']>;
  budget?: InputMaybe<Scalars['Float']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['UUID']>;
  index?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  parentPackageConfigId?: InputMaybe<Scalars['UUID']>;
  products?: InputMaybe<Array<InputMaybe<UpdateMediaplannerProductInput>>>;
  proposalId?: InputMaybe<Scalars['UUID']>;
};

export type UpdatePackageSlideTemplateInput = {
  agencyId?: InputMaybe<Scalars['UUID']>;
  id?: InputMaybe<Scalars['UUID']>;
  layoutId?: InputMaybe<Scalars['UUID']>;
  packageConfigId?: InputMaybe<Scalars['UUID']>;
  slides: Array<UpdateTemplateSlideInput>;
};

export type UpdateProductCreativeInput = {
  agencyId?: InputMaybe<Scalars['UUID']>;
  children?: InputMaybe<Array<InputMaybe<UpdateProductCreativeInput>>>;
  creativeLink?: InputMaybe<Scalars['String']>;
  customMargin?: InputMaybe<Scalars['Float']>;
  description?: InputMaybe<Scalars['String']>;
  flightIds?: InputMaybe<Array<Scalars['UUID']>>;
  headline?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  index?: InputMaybe<Scalars['Int']>;
  notes?: InputMaybe<Scalars['String']>;
  omsNumber?: InputMaybe<Scalars['String']>;
  parentId?: InputMaybe<Scalars['UUID']>;
  postText?: InputMaybe<Scalars['String']>;
  selectedAdFormatList?: InputMaybe<Scalars['JSON']>;
  socialLink?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
  vastTag?: InputMaybe<Scalars['String']>;
};

export type UpdateProductFlightInput = {
  adFormatList?: InputMaybe<Scalars['JSON']>;
  advancedOptions?: InputMaybe<AdvancedOptionsInput>;
  audienceSegments?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>;
  autoRenewType?: InputMaybe<FlightAutoRenewType>;
  budget?: InputMaybe<Scalars['Int']>;
  contextualKeywords?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  flightCategory?: InputMaybe<FlightCategory>;
  id?: InputMaybe<Scalars['UUID']>;
  index?: InputMaybe<Scalars['Int']>;
  isChanged?: InputMaybe<Scalars['Boolean']>;
  isLocked?: InputMaybe<Scalars['Boolean']>;
  mapType?: InputMaybe<MapType>;
  market?: InputMaybe<Scalars['JSON']>;
  platformOption?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  rate?: InputMaybe<Scalars['Float']>;
  rateType?: InputMaybe<RateType>;
  selectedPlatform?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  targetingOption?: InputMaybe<Scalars['String']>;
};

export type UpdateProductSlideTemplateInput = {
  agencyId?: InputMaybe<Scalars['UUID']>;
  id?: InputMaybe<Scalars['UUID']>;
  layoutId?: InputMaybe<Scalars['UUID']>;
  productConfigId?: InputMaybe<Scalars['UUID']>;
  slides: Array<UpdateTemplateSlideInput>;
};

export type UpdateProductSlideWithChildrenTemplateInput = {
  agencyId: Scalars['UUID'];
  id?: InputMaybe<Scalars['UUID']>;
  layoutId?: InputMaybe<Scalars['UUID']>;
  productConfigId?: InputMaybe<Scalars['UUID']>;
  slides: Array<UpdateTemplateSlideInput>;
};

export type UpdateProposalOutputSlidesInput = {
  agencyId?: InputMaybe<Scalars['UUID']>;
  outputId: Scalars['UUID'];
  slides: Array<ProposalSlideInput>;
};

export type UpdateSlideTemplateLibraryInput = {
  agencyId?: InputMaybe<Scalars['UUID']>;
  id?: InputMaybe<Scalars['UUID']>;
  layoutId?: InputMaybe<Scalars['UUID']>;
  slides: Array<UpdateTemplateSlideInput>;
};

export type UpdateTemplateSlideInput = {
  _id?: InputMaybe<Scalars['String']>;
  charts?: InputMaybe<Scalars['JSON']>;
  colors?: InputMaybe<Scalars['JSON']>;
  custom?: InputMaybe<Scalars['Boolean']>;
  defaultOrder?: InputMaybe<Scalars['Int']>;
  defaultSlide?: InputMaybe<Scalars['Boolean']>;
  fromLibrary?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['UUID']>;
  images?: InputMaybe<Scalars['JSON']>;
  isProduct?: InputMaybe<Scalars['Boolean']>;
  label?: InputMaybe<Scalars['String']>;
  layoutId?: InputMaybe<Scalars['UUID']>;
  linkedProduct?: InputMaybe<Scalars['String']>;
  map?: InputMaybe<Scalars['JSON']>;
  name: Scalars['NonEmptyString'];
  order?: InputMaybe<Scalars['Int']>;
  orderAfterProducts?: InputMaybe<Scalars['Boolean']>;
  orderBeforeProducts?: InputMaybe<Scalars['Boolean']>;
  readonly?: InputMaybe<Scalars['Boolean']>;
  shapes?: InputMaybe<Scalars['JSON']>;
  slideSourceId?: InputMaybe<Scalars['UUID']>;
  slideVariable?: InputMaybe<Scalars['String']>;
  templateVariation?: InputMaybe<Scalars['JSON']>;
  textItems?: InputMaybe<Scalars['JSON']>;
  type?: InputMaybe<OutputSlideType>;
  userModified?: InputMaybe<Scalars['Boolean']>;
  visibility: SlideVisibility;
  weight?: InputMaybe<Scalars['Int']>;
};

export type VisitsInput = {
  trackVisits: Scalars['Boolean'];
  website?: InputMaybe<Scalars['URL']>;
};

export enum Weekdays {
  Friday = 'Friday',
  Monday = 'Monday',
  Saturday = 'Saturday',
  Sunday = 'Sunday',
  Thursday = 'Thursday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
}

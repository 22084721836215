
import Vue from 'vue';

export default Vue.extend({
  name: 'BaseDialog',

  props: {
    /**
     * Model value. Used for two way data binding.
     */
    value: {
      type: Boolean,
      required: true,
    },

    /**
     * Title of the modal.
     */
    title: {
      type: String,
      required: true,
    },

    /**
     * Width of the dialog.
     * Default is 694, but may be customizable.
     */
    width: {
      type: [Number, String],
      default: 694,
    },

    /**
     * Height of the dialog.
     * Default is 493, but may be customizable.
     * content height will be decrease to 70( if it has only title block) or 135 ( if it has title and actions block)
     */
    height: {
      type: [Number, String],
      default: 493,
    },
    /**
     * max height of dialog
     */
    maxHeight: {
      type: [Number, String],
      default: '100vh',
    },
    /**
     * Count cols of the aside col.
     * Default is 4 but may be customizable.
     */
    asideCols: {
      type: [Number, String],
      default: 4,
    },

    /**
     * minimal size of aside column
     * Default is 270px  but can be customizable
     */
    asideMinWidth: {
      type: [Number, String],
      default: '270px',
    },

    /**
     * Count cols of the content col.
     * Default is 8, but may be customizable.
     */
    contentCols: {
      type: [Number, String],
      default: 8,
    },
    /**
     * Hide the aside part of component.
     * Don't use contentCols for that it will equal 12*/
    contentOnly: {
      type: Boolean,
      default: false,
    },
    /**
     * Determines if overflowY should be hidden/auto/scroll for content slot
     */
    contentOverflowY: {
      type: String,
      default: 'auto',
    },
    /**
     * Enable fullscreen modal mode
     */
    fullscreen: {
      type: Boolean,
      default: false,
    },
    /**
     * Change content background color to gray(--v-bg-paper-base)
     */
    coloredBackground: {
      type: Boolean,
      default: false,
    },
    featureInProgress: {
      type: Boolean,
      default: false,
    },
    inProgressConfig: {
      type: Object,
      default: () => ({}),
    },
    hideBottomSpacer: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    // Note, we use styles here to display v-text as scrollable element, according to vuetify docs.
    bodyWidth() {
      return this.wrapSizeValue(this.width);
    },
    bodyHeight() {
      return this.wrapSizeValue(this.height);
    },
    wrappedMaxHeight() {
      return this.wrapSizeValue(this.maxHeight);
    },
    wrappedAsideMinWidth() {
      return this.wrapSizeValue(this.asideMinWidth);
    },
    contentColsByTypeOfModal() {
      return this.contentOnly ? 12 : this.contentCols;
    },
    calculatedContentHeight() {
      const bodyHeight = this.fullscreen ? '100vh' : this.bodyHeight;
      const totalHeightDecreasing = this.titleHeight + (this.$scopedSlots.bottomActions ? this.bottomActionsHeight : 0);
      return { height: `calc(min(${bodyHeight}, ${this.wrappedMaxHeight}) - ${totalHeightDecreasing}px)` };
    },
    titleHeight() {
      return 70;
    },
    bottomActionsHeight() {
      return 65;
    },
    wrappedTitleHeight() {
      return this.wrapSizeValue(this.titleHeight);
    },
    wrappedBottomActionsHeight() {
      return this.wrapSizeValue(this.bottomActionsHeight);
    },
  },

  methods: {
    close() {
      this.$emit('input', false);
    },
    wrapSizeValue(value: string | number) {
      if (typeof value === 'number') {
        return `${value}px`;
      }
      return value;
    },
  },
});

import clonedeep from 'lodash.clonedeep';
import {
  ConfiguredFlight,
  ConfiguredProduct,
  KeywordSummary,
  ProductConfig,
  ProposalDemographics,
  OTTStandaloneMarket,
  SemKeyword,
  GeoObj,
  UnsafeAny,
  PackageConfig,
  Product,
  Package,
} from '@/shared/types';
import { ActionTree } from 'vuex';
import { MutationTypes } from './mutations';
import { ProductModuleState } from './state';
import {
  NewProposalModelContract,
  ProductModelContract,
  PackageServiceContract,
  KeywordsServiceContract,
  KeywordsModelContract,
  LoggerContract,
  ProductServiceContract,
  UIUtilsServiceContract,
  PackageProductModelContract,
  GeoModelContract,
} from '@/injectables';
import { RootState } from '../root/state';

interface ActionsInjection {
  productService: ProductServiceContract;
  packageService: PackageServiceContract;
  keywordsService: KeywordsServiceContract;
  geoModel: GeoModelContract;
  logger: LoggerContract;
  uiUtilsService: UIUtilsServiceContract;
  newProposalModel: NewProposalModelContract;
  productModel?: ProductModelContract;
  keywordsModel: KeywordsModelContract;
  packageProductModel: PackageProductModelContract;
}

export const actions = ({
  productService,
  packageService,
  keywordsService,
  geoModel,
  logger,
  newProposalModel,
  keywordsModel,
  packageProductModel,
}: ActionsInjection): ActionTree<ProductModuleState, RootState> => ({
  resetOTTMarket({ commit }): void {
    const emptyMarket: OTTStandaloneMarket = {
      geoSelections: {
        cityList: [],
        zipList: [],
        dmaList: [],
        stateList: [],
        countyList: [],
      },
      demographics: newProposalModel.demographicSelections(),
      enthusiastList: [],
      lifestyleList: [],
      occupationList: [],
      searchHistoryList: [],
      shoppingList: [],
      customList: [],
    };
    commit(MutationTypes.SET_OTT_MARKET, emptyMarket);
  },

  setGeoSelections({ commit, state }, { target, targetArray }: { target: string; targetArray: GeoObj[] }): void {
    const geoSelections = clonedeep(state.OTTMarket.geoSelections);
    try {
      geoSelections[target] = targetArray;
      commit(MutationTypes.SET_OTT_MARKET_GEO_SELECTIONS, geoSelections);
    } catch (err) {
      logger.print('error', 'store.product.actions.setGeoSelections', err);
      throw err;
    }
  },

  setDemographics({ commit }, demographics: ProposalDemographics): void {
    try {
      commit(MutationTypes.SET_OTT_MARKET_DEMOGRAPHICS, demographics);
    } catch (err) {
      logger.print('error', 'store.product.actions.setDemographics', err);
      throw err;
    }
  },

  setEnthusiasts({ commit }, enthusiasts: { name: string; isSelected: boolean }[]): void {
    try {
      commit(MutationTypes.SET_OTT_MARKET_ENTHUSIASTS, enthusiasts);
    } catch (err) {
      logger.print('error', 'store.product.actions.setEnthusiasts', err);
      throw err;
    }
  },

  setLifestyle({ commit }, lifestyle: { name: string; isSelected: boolean }[]): void {
    try {
      commit(MutationTypes.SET_OTT_MARKET_LIFESTILE, lifestyle);
    } catch (err) {
      logger.print('error', 'store.product.actions.setLifestyle', err);
      throw err;
    }
  },

  setOccupation({ commit }, occupation: { name: string; isSelected: boolean }[]): void {
    try {
      commit(MutationTypes.SET_OTT_MARKET_OCCUPATION, occupation);
    } catch (err) {
      logger.print('error', 'store.product.actions.setOccupation', err);
      throw err;
    }
  },

  setSearchHistory({ commit }, searchHistory: { name: string; isSelected: boolean }[]): void {
    try {
      commit(MutationTypes.SET_OTT_MARKET_SEARCH_HISTORY, searchHistory);
    } catch (err) {
      logger.print('error', 'store.product.actions.setSearchHistory', err);
      throw err;
    }
  },

  setShopping({ commit }, shopping: { name: string; isSelected: boolean }[]): void {
    try {
      commit(MutationTypes.SET_OTT_MARKET_SHOPPING, shopping);
    } catch (err) {
      logger.print('error', 'store.product.actions.setShopping', err);
      throw err;
    }
  },

  setCustom({ commit }, custom: { name: string; isSelected: boolean }[]): void {
    try {
      commit(MutationTypes.SET_OTT_MARKET_CUSTOM, custom);
    } catch (err) {
      logger.print('error', 'store.product.actions.setCustom', err);
      throw err;
    }
  },

  // @to-graphql
  async getProducts({ commit, rootState }): Promise<void> {
    try {
      commit(MutationTypes.SET_PRODUCTS_LOADING, true);
      const level = rootState.activeAgency?.name === 'Global' ? 'Global' : 'Agency';
      const agencyPropertyId = rootState.auth.user.Agency;

      const { isErr, unwrap, unwrapErr } = await productService.getProducts({ agencyPropertyId, level });
      if (isErr()) {
        throw new Error(unwrapErr().message);
      }
      const data = unwrap();
      const { products = [], CanHavePackages = false } = data;
      commit(MutationTypes.SET_PRODUCTS, products);
      commit(MutationTypes.SET_CAN_HAVE_PACKAGES, CanHavePackages);
      return;
    } catch (err) {
      logger.print('error', 'store.product.actions.getProducts', err);
      throw err;
    } finally {
      commit(MutationTypes.SET_PRODUCTS_LOADING, false);
    }
  },

  setProductsLoading({ commit }, status: boolean): void {
    commit(MutationTypes.SET_PRODUCTS_LOADING, status);
  },

  clearKeywords({ commit }): void {
    commit(MutationTypes.SET_KEYWORDS, []);
    commit(MutationTypes.SET_KEYWORDS_SUMMARY, null);
  },
  async getKeywordsSummary(
    { commit },
    { keywords, budget }: { budget: number; keywords: SemKeyword[] },
  ): Promise<KeywordSummary> {
    try {
      commit(MutationTypes.LOADING_KEYWORDS_SUMMARY, true);
      const { isErr, unwrap } = await keywordsService.getKeywordsSummary({
        keywords,
        budget,
      });
      if (isErr()) {
        return null;
      }
      const result = unwrap();
      commit(MutationTypes.SET_KEYWORDS_SUMMARY, result);
      return result;
    } catch (err) {
      logger.print('error', 'store.newProposal.actions.getKeywordsSummary', err);
      throw err;
    } finally {
      commit(MutationTypes.LOADING_KEYWORDS_SUMMARY, false);
    }
  },
  // TODO: move into separate module or use directly from the apropriate service
  async getSemKeywords(
    { commit, dispatch },
    {
      keywords,
      budget,
      geoSelection,
      clientUrl,
      productConfigId,
    }: {
      keywords?: string[];
      budget: number;
      geoSelection;
      agencyPropertyId: string;
      clientUrl?;
      string;
      productConfigId?: string;
    },
  ): Promise<void> {
    try {
      commit(MutationTypes.SET_LOADING_KEYWORDS, true);
      const updatedGeoSelection = geoModel.flatGeoForGeoService(geoSelection);
      const { isErr, unwrap, unwrapErr } = await keywordsService.getSemKeywords({
        keywords,
        geoSelection: updatedGeoSelection,
        ...(clientUrl && { clientUrl }),
        ...(productConfigId && { productConfigId }),
      });

      if (isErr()) {
        const { message = 'Received an unexpected response. Please try again later.' } = unwrapErr();
        logger.print('error', 'store.product.actions.getSemKeywords', message);
        return;
      }

      const result = unwrap();

      if (!result || !Array.isArray(result)) {
        return;
      }
      await dispatch('getKeywordsSummary', { keywords: result, budget });

      commit(MutationTypes.SET_KEYWORDS, result);
    } catch (err) {
      logger.print('error', 'store.product.actions.getSemKeywords', err);
      throw err;
    } finally {
      commit(MutationTypes.SET_LOADING_KEYWORDS, false);
    }
  },

  // TODO: move into separate module or use directly from the apropriate service
  async getCustomKeywords(
    { commit, rootState, state, dispatch },
    { keywords, budget }: { keywords: string[]; budget: number },
  ): Promise<SemKeyword[]> {
    try {
      const agencyPropertyId = rootState.activeAgency?.PropertyId;
      commit(MutationTypes.SET_LOADING_CUSTOM_KEYWORDS, true);
      const { isErr, unwrap, unwrapErr } = await keywordsService.getCustomKeywords({
        agencyPropertyId,
        keywords,
      });

      if (isErr()) {
        const { message = 'Received an unexpected response. Please try again later.' } = unwrapErr();
        logger.print('error', 'store.product.actions.getCustomKeywords', message);
        return [];
      }
      const result = unwrap();
      if (!result || !Array.isArray(result)) {
        return [];
      }
      const allKeywords = [...state.keywords, ...result];
      await dispatch('getKeywordsSummary', { keywords: allKeywords, budget });
      commit(MutationTypes.SET_KEYWORDS, allKeywords);
      return allKeywords;
    } catch (err) {
      logger.print('error', 'store.product.actions.getCustomKeywords', err);
      throw err;
    } finally {
      commit(MutationTypes.SET_LOADING_CUSTOM_KEYWORDS, false);
    }
  },

  // TODO: move into separate module or use directly from the apropriate service
  toggleSelectedKeyword(
    { commit, dispatch, state },
    { keyword, budget }: { keyword: string; budget: number },
  ): Promise<KeywordSummary> {
    const result = [...state.keywords];
    const index = result.findIndex(item => item.keyword === keyword);
    if (index > -1) {
      result.splice(index, 1, { ...result[index], isSelected: !result[index].isSelected });
    }
    dispatch('getKeywordsSummary', { keywords: result, budget });

    commit(MutationTypes.SET_KEYWORDS, result);
    return result as UnsafeAny;
  },

  setPackageProduct({ state, commit }, productId) {
    const product = state.products.find(product => product.id === productId) || {};
    commit(MutationTypes.SET_PACKAGE_PRODUCT, { ...product });
  },
  // @to-graphql
  async updatePackageSettings({ rootState }, { product, agencyId = rootState.auth.user.Agency }): Promise<boolean> {
    if (!product) {
      logger.print('error', 'Incorrect input data (savePackageSettings)');
      return false;
    }
    const cleanPackage = packageProductModel.getCleanPackage(product, false, true);

    const { isErr, unwrapErr } = await packageService.updatePackage({ ...cleanPackage, agencyId });

    if (isErr()) {
      const { message } = unwrapErr();
      logger.print('error', 'store.product.actions.savePackageSettings', message);
      return false;
    }
    return true;
  },

  // @to-graphql
  async createPackageSettings({ rootState }, { product, agencyId = rootState.auth.user.Agency }): Promise<boolean> {
    if (!product) {
      logger.print('error', 'Incorrect input data (savePackageSettings)');
      return false;
    }
    const cleanPackage = packageProductModel.getCleanPackage(product, false, true);

    const { isErr, unwrapErr } = await packageService.createPackage({ ...cleanPackage, agencyId });

    if (isErr()) {
      const { message } = unwrapErr();
      logger.print('error', 'store.product.actions.savePackageSettings', message);
      return false;
    }
    return true;
  },

  // @to-graphql
  async removePackageSettings({ dispatch, rootState }, { packagePropertyId }): Promise<boolean> {
    const agencyPropertyId = rootState.activeAgency.PropertyId;
    const { isErr, unwrapErr } = await packageService.removePackageSettings(agencyPropertyId, packagePropertyId);
    if (isErr()) {
      const { message } = unwrapErr();
      logger.print('error', 'store.product.actions.removePackageSettings', message);
      return false;
    }
    await dispatch('getProducts');
    return true;
  },

  // @to-graphql
  async fetchProductConfigs({ commit, state }, { agencyId, force }): Promise<(ProductConfig | PackageConfig)[]> {
    if (!agencyId) {
      commit(MutationTypes.SET_PRODUCT_CONFIGS, []);
      return [];
    }
    if (state.productConfigs?.loaded && !force) {
      return state.productConfigs.list;
    }
    commit(MutationTypes.SET_FETCH_PRODUCT_CONFIGS_LOADING, true);
    const { isErr, unwrapErr, unwrap } = await productService.getConfigs(agencyId);
    if (isErr()) {
      const { message } = unwrapErr();
      logger.print('error', 'store.product.actions.fetchProductConfigs', message);
      commit(MutationTypes.SET_FETCH_PRODUCT_CONFIGS_LOADING, false);
      return [];
    }
    const configs = unwrap();
    commit(MutationTypes.SET_PRODUCT_CONFIGS, configs);
    commit(MutationTypes.SET_FETCH_PRODUCT_CONFIGS_LOADING, false);
    return configs;
  },

  updatePackageBudget({ commit, state }, budget: number): void {
    if (typeof budget === 'string') {
      budget = parseInt(budget, 10);
    }
    if (budget !== state.newPackage.budget) {
      commit(MutationTypes.SET_PACKAGE_BUDGET, budget);
    }
  },

  updatePackageDescription({ commit }, val: string) {
    commit(MutationTypes.SET_PACKAGE_DESCRIPTION, val);
  },

  updatePackageName({ commit }, val: string) {
    commit(MutationTypes.SET_PACKAGE_NAME, val);
  },

  updateProductBudget({ commit }, { budget, productId }: { budget: number; productId: string }): void {
    if (typeof budget === 'string') {
      budget = parseInt(budget, 10);
    }
    commit(MutationTypes.UPDATE_PACKAGE_PRODUCT_BUDGET, { _budget: budget, _productId: productId });
  },

  async updatePackageProducts(
    { commit },
    { products = [] }: { products: ConfiguredProduct[]; bypassRecalc?: boolean; sendUpdate?: boolean },
  ): Promise<void> {
    const sorted = [...products].sort((a, b) => a.index - b.index);
    commit(MutationTypes.SET_PACKAGE_PRODUCTS, sorted);
  },

  togglePackageProductLocked({ commit }, { status, productId }: { status: boolean; productId: string }): void {
    commit(MutationTypes.TOGGLE_PACKAGE_PRODUCT_LOCKED, { _status: status, _productId: productId });
  },

  // PACKAGE
  addNewFlight({ commit, state }, productId: string): ConfiguredFlight {
    const product: Product = state.newPackage.products.find(product => product.id === productId);
    if (!product) return;
    if (!product.hasOwnProperty('flights')) return; // not a flighted Product
    const productFlights = [...product.flights];
    const maxIndex = Math.max(
      productFlights.length,
      Math.max(...productFlights.filter(p => p.index).map(p => p.index)),
    );
    const flight: ConfiguredFlight = {
      isLocked: false,
      index: maxIndex + 1,
      budget: 0,
      rateType: null,
      rate: 0,
      startDate: '',
      endDate: '',
      id: 'temp_' + Date.now(),
    };
    productFlights.push(flight);
    commit(MutationTypes.SET_PACKAGE_PRODUCT_FLIGHTS, { productId, flights: productFlights });
    return flight;
  },

  removeFlight({ commit, state }, { productId, flightId }: { productId: string; flightId: string }): void {
    const product: Product = state.newPackage.products.find(product => product.id === productId);
    if (product) {
      const productFlights = product.flights.filter(productFlight => flightId !== productFlight.id);
      if (packageProductModel.fixedRateConfig(product)) {
        // smart mailer
        const newBudgetForProduct = Math.max(
          productFlights.reduce((total, flight) => total + flight.budget, 0),
          product.minSpend,
        );
        if (product.budget !== newBudgetForProduct) {
          commit(MutationTypes.UPDATE_PACKAGE_PRODUCT_BUDGET, {
            _budget: newBudgetForProduct,
            _productId: productId,
          });
        }
      }
      commit(MutationTypes.SET_PACKAGE_PRODUCT_FLIGHTS, { productId, flights: productFlights });
    }
  },

  updateExistingFlight(
    { commit, state },
    {
      productId,
      flight,
      recalculateProducts,
    }: {
      productId: string;
      flight: ConfiguredFlight;
      recalculateProducts: boolean;
    },
  ): ConfiguredFlight {
    const product = state.newPackage.products.find(product => product.id === productId);
    if (!product) return;
    const flights = [...product.flights];
    const flightIndex = flights.findIndex(f => f.id === flight.id);
    if (flightIndex === -1) return flight;
    const updatedFlight = {
      ...flights[flightIndex],
      ...flight,
      isChanged: recalculateProducts,
    };

    flights[flightIndex] = updatedFlight;

    commit(MutationTypes.SET_PACKAGE_PRODUCT_FLIGHTS, { productId, flights });
    return updatedFlight;
  },

  // TODO: move into separate module or use directly from the service
  async getSemKeywordsForPackage(
    { commit, dispatch },
    {
      keywords,
      productId,
      budget,
    }: { productId: string; clientId: string; useClientUrl: boolean; budget: number; keywords?: string[] },
  ): Promise<void> {
    try {
      commit(MutationTypes.SET_LOADING_KEYWORDS, true);

      const { isErr, unwrap, unwrapErr } = await keywordsService.getSemKeywords({
        keywords,
        geoSelection: {},
      });

      if (isErr()) {
        const { message } = unwrapErr();
        logger.print('info', 'store.product.actions.getSemKeywordsForPackage', message);
        return;
      }
      const result = unwrap();
      if (result && Array.isArray(result)) {
        const summary = await dispatch('getKeywordsSummaryForPackage', { keywords: result, budget });
        commit(MutationTypes.SET_GENERATED_KEYWORDS_TO_PACKAGE, { keywords: result, productId, summary });
        return;
      }
      dispatch(
        'showSnackbar',
        { content: 'Received an unexpected response. Please try again later.', color: 'error' },
        { root: true },
      );
    } catch (err) {
      logger.print('error', 'store.product.actions.getSemKeywordsForPackage', err);
    } finally {
      commit(MutationTypes.SET_LOADING_KEYWORDS, false);
    }
  },

  // TODO: move into separate module or use directly from the service
  async getKeywordsSummaryForPackage(
    { commit },
    { keywords, budget }: { budget: number; keywords: SemKeyword[] },
  ): Promise<KeywordSummary> {
    try {
      commit(MutationTypes.SET_LOADING_CUSTOM_KEYWORDS, true);

      const { isErr, unwrap } = await keywordsService.getKeywordsSummary({
        keywords,
        budget,
      });
      if (isErr()) {
        return null;
      }
      return unwrap() || null;
    } catch (err) {
      logger.print('error', 'store.product.actions.getKeywordsSummaryForPackage', err);
      throw err;
    } finally {
      commit(MutationTypes.SET_LOADING_CUSTOM_KEYWORDS, false);
    }
  },

  resetKeywordsForPackage({ commit }, productId: string): void {
    const emptySummary = keywordsModel.getEmptyKeywordsSummary();
    commit(MutationTypes.SET_GENERATED_KEYWORDS_TO_PACKAGE, { keywords: [], productId, summary: emptySummary });
  },

  async toggleSelectedKeywordForPackage(
    { commit, state, dispatch },
    { productId, keyword, budget }: { productId: string; keyword: string; budget: number; toPackage: boolean },
  ): Promise<KeywordSummary> {
    const product: Product = state.newPackage.products.find(product => product.id === productId);
    if (product) {
      const kwObj = product.keywords;
      const updatedKeywords = [];
      if (kwObj?.list) {
        kwObj.list.forEach(kw => {
          if (kw.keyword === keyword) {
            kw.isSelected = !kw.isSelected;
          }
          updatedKeywords.push(kw);
        });
      }
      const summary = await dispatch('getKeywordsSummaryForPackage', { keywords: updatedKeywords, budget });
      commit(MutationTypes.SET_GENERATED_KEYWORDS_TO_PACKAGE, { productId, keywords: updatedKeywords, summary });
      return summary;
    }
  },

  // TODO: move into separate module or use directly from the service
  async getCustomKeywordsForPackage(
    { commit, rootState, dispatch },
    { keywords, product, budget }: { keywords: string[]; product: Product; budget: number },
  ): Promise<UnsafeAny> {
    try {
      const defaultResponse = { keywords: [], summary: keywordsModel.getEmptyKeywordsSummary() };

      const productId = product.id;

      if (!product) {
        return defaultResponse;
      }

      const agencyPropertyId = rootState.activeAgency?.PropertyId;

      commit(MutationTypes.SET_LOADING_CUSTOM_KEYWORDS, true);
      const { isErr, unwrap, unwrapErr } = await keywordsService.getCustomKeywords({
        agencyPropertyId,
        keywords,
      });
      if (isErr()) {
        const { message } = unwrapErr();
        logger.print('info', 'store.product.actions.getCustomKeywordsForPackage', message);
        return defaultResponse;
      }

      const result = unwrap();

      if (result && Array.isArray(result)) {
        const allKeywords = [...product.keywords.list, ...result];
        const summary = await dispatch('getKeywordsSummaryForPackage', { keywords: allKeywords, budget });
        commit(MutationTypes.SET_GENERATED_KEYWORDS_TO_PACKAGE, { keywords: allKeywords, productId, summary });
        return { keywords: allKeywords, summary };
      }
      dispatch(
        'showSnackbar',
        { content: 'Received an unexpected response. Please try again later.', color: 'error' },
        { root: true },
      );
      return defaultResponse;
    } catch (err) {
      logger.print('error', 'store.product.actions.getCustomKeywordsForPackage', err);
      throw err;
    } finally {
      commit(MutationTypes.SET_LOADING_CUSTOM_KEYWORDS, false);
    }
  },
});

import { Route } from '@/entities/user/config/routes';
import { MenuItemRoute } from '../contracts';

const settings = {
  Summary: {
    pathName: 'home',
    authorization: ['client', 'canView', 'canEdit', 'isAdmin'],
    activeChildRoutes: ['home'],
    exact: true,
    disabled: false,
  },
  Clients: {
    pathName: 'clients',
    authorization: ['client', 'canView', 'canEdit', 'isAdmin'],
    activeChildRoutes: ['clientProposals', 'clientInfo', 'clientSummary', 'clients', 'createClient'],
    disabled: false,
  },
  'All Clients': {
    pathName: 'clients',
    authorization: ['client', 'canView', 'canEdit', 'isAdmin'],
    activeChildRoutes: ['clientProposals', 'clientInfo', 'clientSummary', 'clients', 'createClient'],
    disabled: false,
  },
  'My Clients': {
    pathName: 'myClients',
    authorization: ['client', 'canView', 'canEdit', 'isAdmin'],
    activeChildRoutes: ['clientProposals', 'clientInfo', 'clientSummary', 'clients', 'createClient'],
    disabled: false,
  },
  Products: {
    pathName: 'products',
    authorization: ['canEdit', 'isAdmin'],
    activeChildRoutes: ['products'],
    disabled: false,
  },
  Campaigns: {
    pathName: 'campaigns',
  },
  Proposals: {
    pathName: 'proposals',
    exact: true,
    disabled: false,
  },
  Proposal: {
    pathName: 'proposals',
    exact: true,
    disabled: false,
  },
  'Instant IO': {
    pathName: 'instantCampaigns',
    exact: true,
    disabled: false,
  },
  Tools: {
    pathName: 'tools',
  },
  'Avails Calculator': {
    pathName: 'availsCalculator',
    exact: true,
    disabled: false,
  },
  'Keyword Planner': {
    pathName: 'keywordPlanner',
    exact: true,
    disabled: false,
  },
  'Agency Dashboard': {
    pathName: 'agency-dashboard',
    authorization: ['isAdmin'],
    exact: true,
    disabled: false,
  },
};

export const mapRoute = (route: Route): MenuItemRoute => {
  const configuration = settings[route.name] ?? route;
  return {
    ...route,
    ...configuration,
    id: configuration.pathName,
    title: route.name,
    ...(route.subItems ? { subItems: route.subItems.map(mapRoute) } : {}),
  };
};

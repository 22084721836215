
import Vue from 'vue';

export default Vue.extend({
  name: 'MoneyField',

  props: {
    /**
     * Selected item. Used for v-model.
     */
    value: {
      type: Number,
      required: true,
    },

    rules: {
      type: Array,
      default: () => [],
    },
    min: {
      type: [Number, String],
    },
    max: {
      type: [Number, String],
      default: 9999999,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    step: {
      type: [Number, String],
      default: 25,
    },
    message: {
      type: String,
      default: 'Unlock this flight to edit the budget',
    },
  },
  watch: {
    value(val) {
      this.localBudget = val;
    },
  },
  data() {
    const defaultBudget = this.value || this.min || 0;
    return {
      budgetIsFocused: false,
      localBudget: defaultBudget,
    };
  },

  computed: {
    formatedValue(): string {
      return this.$options.filters.formatPrice(this.value);
    },
  },
  methods: {
    handleFocus(): void {
      this.budgetIsFocused = true;
      this.localBudget = this.value;
    },
    updateLocalBudget(e): void {
      try {
        this.localBudget = parseInt(e, 10);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('error', 'money-field.updateLocalBudget', e, err);
      }
    },
    onBudgetEdit(): void {
      if (this.readonly) return;
      if (this.min && this.localBudget < this.min) {
        this.localBudget = this.min;
        return;
      }
      if (this.max && this.localBudget > this.max) {
        this.localBudget = this.max;
        return;
      }
      this.budgetIsFocused = false;

      if (this.value === this.localBudget) return;

      this.$emit('input', this.localBudget);
    },
  },
});

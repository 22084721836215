import 'reflect-metadata';

import { PluginFunction } from 'vue';
import { Mixpanel } from 'mixpanel-browser';
import { Container } from 'inversify';
import { AxiosInstance } from 'axios';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client/core';

import { Models, Services } from '@/injectables/tokens';
import {
  CategoryServiceContract,
  CategoryService,
  LocalTracker,
  Tracker,
  TrackerContract,
  EnumsServiceContract,
  EnumsService,
  LocalUploadService,
  UploadServiceContract,
  GeocodeService,
  GeocodeServiceContract,
  AgencyService,
  AgencyServiceContract,
  EnvService,
  EnvServiceContract,
  LoggerContract,
  CensusModel,
  CensusModelContract,
  AuthServiceContract,
  AuthService,
  ClientService,
  ClientServiceContract,
  ValidationService,
  ValidationServiceContract,
  ManagementService,
  ManagementServiceContract,
  FileServiceContract,
  FileService,
  CreativeService,
  CreativeServiceContract,
  KeywordsService,
  KeywordsServiceContract,
  ProductService,
  ProductServiceContract,
  TaxonomyService,
  TaxonomyServiceContract,
  PackageService,
  PackageServiceContract,
  ContractService,
  ContractServiceContract,
  NewProposalService,
  NewProposalServiceContract,
  PolygonsService,
  PolygonsServiceContract,
  UIUtilsService,
  UIUtilsServiceContract,
  CalendarService,
  CalendarServiceContract,
  OutputService,
  OutputServiceContract,
  UXUtilsService,
  UXUtilsServiceContract,
  LocalStorageService,
  LocalStorageServiceContract,
  UnwrapErrorMessageMapper,
  unwrapErrorMessage,
  ProposalService,
  ProposalServiceContract,
  HelpSectionService,
  HelpSectionServiceContract,
  FlightModel,
  FlightModelContract,
  FilesModel,
  FilesModelContract,
  KeywordsModel,
  KeywordsModelContract,
  PackageModelContract,
  PackageModel,
  StatusModel,
  StatusModelContract,
  HelpSectionModel,
  HelpSectionModelContract,
  NotificationsContract,
  NotificationModel,
  SlideModel,
  SlideModelContract,
  ChartModel,
  ChartModelContract,
  ProductModel,
  ProductModelContract,
  NewProposalModel,
  NewProposalModelContract,
  OutputEditorModel,
  OutputEditorModelContract,
  TextEditorModel,
  TextEditorModelContract,
  OutputModel,
  OutputModelContract,
  BudgetModelContract,
  BudgetModel,
  ErrorsModelContract,
  ErrorsModel,
  InstantModelContract,
  InstantModel,
  BaseProductModelContract,
  BaseProductModel,
  S3UploadService,
  CreativeModelContract,
  CreativeModel,
  C360ModelContract,
  C360Model,
  RouterModelContract,
  RouterModel,
  PackageProductModelContract,
  PackageProductModel,
  BroadcastParserService,
  BroadcastParserServiceContract,
  ProposalProductModel,
  ProposalProductModelContract,
  DateModelContract,
  DateModel,
  InstantIOServiceContract,
  InstantIOService,
  GeoModelContract,
  GeoModel,
  TargetingSegmentsServiceContract,
  TargetingSegmentsService,
  TargetingSegmentsModelContract,
  TargetingSegmentsModel,
  CampaignServiceContract,
  CampaignService,
  CampaignModelContract,
  CampaignModel,
  ColorModelContract,
  ColorModel,
} from '@/injectables';

export interface ConstantDeps {
  mixpanel: Mixpanel | null;
  logger: LoggerContract;
  axios: AxiosInstance;
  apollo: ApolloClient<NormalizedCacheObject>;
}

export const createContainer = ({ mixpanel, logger, axios, apollo }: ConstantDeps) => {
  const container = new Container();

  const envService = new EnvService();
  const isLocal = envService.isLocalDev();

  // Services
  container.bind<EnvServiceContract>(Services.Env).to(EnvService);
  container.bind<TrackerContract>(Services.Tracker).to(mixpanel ? Tracker : LocalTracker);
  container.bind<FileServiceContract>(Services.File).to(FileService);
  container.bind<AgencyServiceContract>(Services.Agency).to(AgencyService);
  container.bind<ValidationServiceContract>(Services.Validation).to(ValidationService);
  container.bind<CategoryServiceContract>(Services.Category).to(CategoryService);
  container.bind<EnumsServiceContract>(Services.Enums).to(EnumsService);
  container.bind<CalendarServiceContract>(Services.Calendar).to(CalendarService);
  container.bind<ProposalServiceContract>(Services.Proposal).to(ProposalService);
  container.bind<AuthServiceContract>(Services.Auth).to(AuthService);
  container.bind<ClientServiceContract>(Services.Client).to(ClientService);
  container.bind<ManagementServiceContract>(Services.Management).to(ManagementService);
  container.bind<OutputServiceContract>(Services.Output).to(OutputService);
  container.bind<TaxonomyServiceContract>(Services.Taxonomy).to(TaxonomyService);
  container.bind<UIUtilsServiceContract>(Services.UI).to(UIUtilsService);
  container.bind<UXUtilsServiceContract>(Services.UX).to(UXUtilsService);
  container.bind<UploadServiceContract>(Services.Upload).to(S3UploadService);
  container.bind<NewProposalServiceContract>(Services.NewProposal).to(NewProposalService);
  container.bind<ProductServiceContract>(Services.Product).to(ProductService);
  container.bind<ContractServiceContract>(Services.Contract).to(ContractService);
  container.bind<GeocodeServiceContract>(Services.Geocode).to(GeocodeService);
  container.bind<PackageServiceContract>(Services.Package).to(PackageService);
  container.bind<LocalStorageServiceContract>(Services.LocalStorage).to(LocalStorageService);
  container.bind<KeywordsServiceContract>(Services.Keywords).to(KeywordsService);
  container.bind<CreativeServiceContract>(Services.Creative).to(CreativeService);
  container.bind<HelpSectionServiceContract>(Services.HelpSection).to(HelpSectionService);
  container.bind<PolygonsServiceContract>(Services.Polygons).to(PolygonsService);
  container.bind<BroadcastParserServiceContract>(Services.BroadcastParser).to(BroadcastParserService);
  container.bind<InstantIOServiceContract>(Services.InstantIO).to(InstantIOService);
  container.bind<TargetingSegmentsServiceContract>(Services.TargetingSegments).to(TargetingSegmentsService);
  container.bind<CampaignServiceContract>(Services.Campaign).to(CampaignService);

  // Entities
  container.bind<StatusModelContract>(Models.Status).to(StatusModel);
  container.bind<PackageModelContract>(Models.Package).to(PackageModel);
  container.bind<ProductModelContract>(Models.Product).to(ProductModel);
  container.bind<FlightModelContract>(Models.Flight).to(FlightModel);
  container.bind<NotificationsContract>(Models.Notification).to(NotificationModel);
  container.bind<FilesModelContract>(Models.Files).to(FilesModel);
  container.bind<NewProposalModelContract>(Models.NewProposal).to(NewProposalModel);
  container.bind<ChartModelContract>(Models.Chart).to(ChartModel);
  container.bind<TextEditorModelContract>(Models.TextEditor).to(TextEditorModel);
  container.bind<OutputEditorModelContract>(Models.OutputEditor).to(OutputEditorModel);
  container.bind<CensusModelContract>(Models.Census).to(CensusModel);
  container.bind<KeywordsModelContract>(Models.Keywords).to(KeywordsModel);
  container.bind<HelpSectionModelContract>(Models.HelpSection).to(HelpSectionModel);
  container.bind<SlideModelContract>(Models.Slide).to(SlideModel);
  container.bind<BudgetModelContract>(Models.Budget).to(BudgetModel);
  container.bind<OutputModelContract>(Models.Output).to(OutputModel);
  container.bind<ErrorsModelContract>(Models.Errors).to(ErrorsModel);
  container.bind<InstantModelContract>(Models.InstantIO).to(InstantModel);
  container.bind<PackageProductModelContract>(Models.PackageProduct).to(PackageProductModel);
  container.bind<ProposalProductModelContract>(Models.ProposalProduct).to(ProposalProductModel);
  container.bind<BaseProductModelContract>(Models.BaseProduct).to(BaseProductModel);
  container.bind<CreativeModelContract>(Models.Creatives).to(CreativeModel);
  container.bind<C360ModelContract>(Models.C360).to(C360Model);
  container.bind<RouterModelContract>(Models.Router).to(RouterModel);
  container.bind<DateModelContract>(Models.Date).to(DateModel);
  container.bind<GeoModelContract>(Models.Geo).to(GeoModel);
  container.bind<TargetingSegmentsModelContract>(Models.TargetingSegments).to(TargetingSegmentsModel);
  container.bind<CampaignModelContract>(Models.Campaign).to(CampaignModel);
  container.bind<ColorModelContract>(Models.Colors).to(ColorModel);

  // Constant deps
  container.bind<Mixpanel | null>('mixpanel').toConstantValue(mixpanel);
  container.bind<LoggerContract>('logger').toConstantValue(logger);
  container.bind<AxiosInstance>('axios').toConstantValue(axios);
  container.bind<ApolloClient<NormalizedCacheObject>>('apollo').toConstantValue(apollo);
  container.bind<UnwrapErrorMessageMapper>('unwrapErrorMessage').toFunction(unwrapErrorMessage);

  const install: PluginFunction<{}> = Vue => {
    Vue.prototype.$container = container;
  };

  return { container, install };
};

const taxonomyHelpers = {
  getTitle(cat: string): string {
    return cat.split('^').pop();
  },
  fullTitle(cat: string): string {
    return cat.split('^').join('\xa0\xa0>\xa0\xa0');
  },
  getCatByName(cat: string, allcats: any[]): any[] {
    return allcats.filter(dscat => dscat.name === cat);
  },
};

export default taxonomyHelpers;

import { NewProposalParams, NewProposalRoute, NewProposalStep, UnsafeAny } from '@/shared/types';

import { Container } from 'inversify';
import {
  BaseProductModel,
  LoggerContract,
  NewProposalModelContract,
  ProposalProductModelContract,
} from '@/injectables';

import { GetterTree } from 'vuex';
import { NewProposalState } from './types';
import { Models } from '@/injectables/tokens';

export const getters = (container: Container): GetterTree<NewProposalState, UnsafeAny> => {
  const logger = container.get<LoggerContract>('logger');
  const proposalProductModel = container.get<ProposalProductModelContract>(Models.ProposalProduct);
  const newProposalModel = container.get<NewProposalModelContract>(Models.NewProposal);
  const baseProductModel = container.get<BaseProductModel>(Models.BaseProduct);

  return {
    // newProposal routes
    currentNewProposalStep:
      () =>
      (routeName: string): number => {
        const steps = {
          proposalMarket: 1,
          proposalSolutions: 2,
          proposalSummary: 3,
          proposalFinalize: 4,
        };
        return steps[routeName] || 1;
      },
    proposalPreviousStep:
      (state, getters) =>
      (routeName: string, params: NewProposalParams): { route: NewProposalRoute } => {
        const previousStep = getters
          .newProposalSteps({ routeName, params })
          .find(step => step.number === getters.currentNewProposalStep(routeName) - 1);
        return { route: previousStep.route };
      },
    proposalNextStep:
      (state, getters) =>
      (routeName: string, params: NewProposalParams): { route: NewProposalRoute; canProgress: boolean } => {
        const nextStep = getters
          .newProposalSteps({ routeName, params })
          .find(step => step.number === getters.currentNewProposalStep(routeName) + 1);
        const canProgress = !!nextStep && !nextStep.disabled;
        return { route: nextStep.route, canProgress };
      },
    newProposalSteps:
      (state, getters) =>
      ({ routeName, params }: { routeName: string; params: NewProposalParams }): NewProposalStep[] => {
        const steps = [
          {
            number: 1,
            name: 'Market',
            route: { name: 'proposalMarket', params },
            active: false,
            disabled: false,
            completed: false,
          },
          {
            number: 2,
            name: 'Solutions',
            route: { name: 'proposalSolutions', params },
            active: false,
            disabled: false,
            completed: false,
          },
          {
            number: 3,
            name: 'Summary',
            route: { name: 'proposalSummary', params },
            active: false,
            disabled: false,
            completed: false,
          },
          {
            number: 4,
            name: 'Finalize',
            route: { name: 'proposalFinalize', params },
            active: false,
            disabled: false,
            completed: false,
          },
        ];
        const market = state.newProposal?.market;
        const isGeoSelectionsReady =
          market?.geoSelections &&
          Object.keys(market.geoSelections).length &&
          Object.keys(market.geoSelections).some(
            key => Array.isArray(market.geoSelections[key]) && market.geoSelections[key].length,
          );
        const products = state.newProposal?.products;
        const isProductsReady =
          products?.length > 0 && products.every(p => proposalProductModel.productIsConfigured(p));
        const hasUallocatedBudget = !!getters['newProposalBudgetUnallocated'];
        const hasCampaignDates = state.newProposal?.startDate?.length && state.newProposal?.endDate?.length;
        const stepIsDisabled = (step): boolean => {
          if (step.number === 2) {
            return (
              (step.number > getters.currentNewProposalStep(routeName) && !isGeoSelectionsReady) || !hasCampaignDates
            );
          }
          if ([3, 4].includes(step.number)) {
            return !(isProductsReady && isGeoSelectionsReady && hasCampaignDates) || hasUallocatedBudget;
          }

          return step.number > getters.currentNewProposalStep;
        };
        steps.forEach(step => {
          if (step.number === getters.currentNewProposalStep(routeName)) step.active = true;
          else if (stepIsDisabled(step)) step.disabled = true;
          else if (step.number < getters.currentNewProposalStep(routeName)) step.completed = true;
        });
        return steps;
      },
    // newProposal
    changeStatusNotificationOptions: (state): { value: boolean; path: string } => {
      return state.changeStatusNotificationOptions;
    },
    isProposalMarketUpdated: (state): boolean => {
      return state.isProposalMarketUpdated;
    },
    numberOfDaysInProposal: (state): number => {
      try {
        if (!state.newProposal?.endDate || !state.newProposal?.startDate) {
          return 0;
        }
        const start = new Date(state.newProposal.startDate);
        const end = new Date(state.newProposal.endDate);
        const currentDays = (end.getTime() - start.getTime()) / (1000 * 3600 * 24);
        return currentDays;
      } catch (err) {
        logger.print('error', 'store.newProposal.getters.numberOfDaysInProposal', err);
        return 0;
      }
    },
    canGetRecommendations: (state): boolean => {
      const proposal = state.newProposal;
      return !!(proposal.budget > 0 && proposal?.goal.length && proposal.startDate && proposal.endDate);
    },
    targetedPopulation: (state, getters): number => {
      if (!state.newProposal.market?.census?.real || !getters.totalPopulation || !state.newProposal.demographics)
        return 0;

      return Number(
        newProposalModel.calculateTargetedPopulation({
          demoArray: state.newProposal.market.census.real,
          total: getters.totalPopulation,
          selectedDemographics: state.newProposal.demographics,
        }),
      );
    },
    totalPopulation: (state): number => {
      return Number(state.newProposal.market.census.totalPopulation);
    },
    newProposalBudgetUnallocated: (state): number => {
      return baseProductModel.getUnallocatedBudget(state.newProposal.products, state.newProposal.budget);
    },
    proposalBudgetMax: (): number => {
      return 999999999;
    },
    canAddNewProduct: (state): boolean => {
      return (
        !state.newProposal.products.length ||
        state.newProposal.products.every(product => product.hasOwnProperty('name'))
      );
    },
  };
};

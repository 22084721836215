export const injectStyle = (url: string): void => {
  const link = document.createElement('link');
  link.setAttribute('rel', 'stylesheet');
  link.setAttribute('href', url);
  document.head.appendChild(link);
};

export const loadCss = (fonts: string[]) => {
  const _fonts = encodeURIComponent(fonts.join('|'));
  injectStyle(`https://fonts.googleapis.com/css?family=${_fonts}&display=swap`);
  injectStyle('https://cdn.jsdelivr.net/npm/@mdi/font@6.5.95/css/materialdesignicons.min.css');
  injectStyle('https://fonts.googleapis.com/icon?family=Material+Icons');
};


import Vue from 'vue';
import { InputValidationRule } from 'vuetify';

import { ValidationServiceContract } from '@/injectables';
import { Services } from '@/injectables/tokens';

import { FormFragment } from '../contracts';

export default Vue.extend({
  name: 'EditProduct.RateParameters',

  useInjectable: [Services.Upload, Services.Agency, Services.Validation, Services.Geocode],

  props: {
    isValid: {
      type: Boolean,
      default: false,
    },
    rateLimits: {
      type: Object,
    },
  },

  mounted() {
    this.lowerLimit = this.rateLimits.min;
    this.upperLimit = this.rateLimits.max;
  },

  data(): {
    lowerLimit: number;
    upperLimit: number;
  } {
    return {
      lowerLimit: 0,
      upperLimit: 0,
    };
  },

  computed: {
    min: {
      get(): number {
        return parseInt((this.lowerLimit * 100).toFixed());
      },
      set(v: string): void {
        const parsed = parseInt(v);

        if (Number.isNaN(parsed)) return;

        this.lowerLimit = parsed / 100;
      },
    },
    max: {
      get(): number {
        return parseInt((this.upperLimit * 100).toFixed());
      },
      set(v: string): void {
        const parsed = parseInt(v);

        if (Number.isNaN(parsed)) return;

        this.upperLimit = parsed / 100;
      },
    },
    rules(): Record<string, InputValidationRule> {
      const validationService: ValidationServiceContract = this.validationService;

      const rate = validationService.isBetweenValidatorFactory({
        from: 0,
        to: 100,
        messages: {
          min: 'Please select valid rate value. Must be more than',
          max: 'Please select valid rate value. Must be less than',
        },
      });

      return {
        rate,
        isNumberValidatorFactory: validationService.isNumberValidatorFactory(),
      };
    },
    updateRateParameters(): FormFragment {
      return {
        lowerLimit: this.lowerLimit,
        upperLimit: this.upperLimit,
      };
    },
  },

  watch: {
    updateRateParameters: {
      handler() {
        this.$emit('update:rateParameters', this.updateRateParameters);
      },
      deep: true,
      immediate: true,
    },
  },
});

import Vue from 'vue';
import Vuetify from 'vuetify';
import { theme as sbg } from '@/app/themes/sbg';
import { theme as old } from '@/app/themes/deprecated';
import { c360ThemeColors } from '@c360/ui/src/plugins/theme';

import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify);

const vuetify = new Vuetify({
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    dark: false,
    options: { customProperties: true },
    themes: {
      light: { ...old, ...sbg, ...c360ThemeColors },
      dark: {
        background: '#272727',
        surface: '#1e1e1e',
        primary: '#bb86fc',
        secondary: '#3700b3',
        accent: '#03dac6',
        error: '#cf6679',
      },
    },
  },
});

export default vuetify;

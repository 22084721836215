
import Vue, { PropType } from 'vue';

export enum WSelectVariants {
  Contained = 'contained', // eslint-disable-line no-unused-vars
  Outlined = 'outlined', // eslint-disable-line no-unused-vars
  Text = 'text', // eslint-disable-line no-unused-vars
}

export enum WSelectSize {
  Small = 'small', // eslint-disable-line no-unused-vars
  Medium = 'medium', // eslint-disable-line no-unused-vars
  Large = 'large', // eslint-disable-line no-unused-vars
}

export enum WSelectColors {
  Primary = 'primary', // eslint-disable-line no-unused-vars
}

const variants = [WSelectVariants.Contained, WSelectVariants.Outlined, WSelectVariants.Text];

const sizes = [WSelectSize.Small, WSelectSize.Medium, WSelectSize.Large];

const colors = Object.values(WSelectColors);

export default Vue.extend({
  name: 'w-select',

  props: {
    variant: {
      type: String as PropType<WSelectVariants>,
      validator: (v: WSelectVariants) => variants.includes(v),
      default: () => WSelectVariants.Contained,
    },
    size: {
      type: String as PropType<WSelectSize>,
      validator: (v: WSelectSize) => sizes.includes(v),
      default: () => WSelectSize.Medium,
    },
    color: {
      type: String as PropType<WSelectColors>,
      validator: (v: WSelectColors) => colors.includes(v),
      default: () => 'primary',
    },
    height: {
      type: [String, Number],
      default: 40,
    },
  },

  computed: {
    isOutlined(): boolean {
      return this.variant === WSelectVariants.Outlined;
    },
    isText(): boolean {
      return this.variant === WSelectVariants.Text;
    },
    isRounded(): boolean {
      return [WSelectVariants.Contained, WSelectVariants.Outlined].includes(this.variant);
    },
    isLarge(): boolean {
      return false;
    },
    isXLarge(): boolean {
      return this.size === WSelectSize.Large;
    },
    isSmall(): boolean {
      return this.size === WSelectSize.Small;
    },
  },
});

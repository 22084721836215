const fs = require('fs');

enum types { // eslint-disable-line no-unused-vars
  Info = 'INFO', // eslint-disable-line no-unused-vars
  Warning = 'WARNING', // eslint-disable-line no-unused-vars
  Error = 'ERROR', // eslint-disable-line no-unused-vars
}

// todo: env flag to log full error details/trace instead of just message

const log = (level: types, src: string, message?: any) => {
  try {
    const timestamp = new Date();
    let details = '';
    if (message) {
      if (typeof message === 'string') {
        details = message;
      } else if (message instanceof Error) {
        if (message.message && typeof message.message === 'string') {
          details = message.message;
        } else {
          const obj = {};
          Object.getOwnPropertyNames(message).forEach((key: string) => {
            obj[key] = message[key];
          });
          details = JSON.stringify(obj);
        }
      } else if (message.Error && typeof message.Error === 'string') {
        details = message.Error;
      } else {
        details = JSON.stringify(message);
      }
      if (details.length > 300) {
        details = `${details.substr(0, 300)}...`;
      }
    }
    const msg = `\r\n[${(level || types.Error).toUpperCase()}] ${timestamp.toUTCString()} - ${src} ${details}`;

    fs.appendFile('log.txt', msg, (err: any) => {
      if (err) throw err;
    });
    if (level === types.Error) {
      // eslint-disable-next-line no-console
      console.error(msg);
    } else {
      // eslint-disable-next-line no-console
      console.log(msg);
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('log error', err);
  }
};

export default {
  log,
  types,
};

import 'reflect-metadata';
import { inject, injectable } from 'inversify';
import {
  CreativeModelContract,
  InstantModelContract,
  InstantCampaign,
  ConfiguredProductIO,
  Questionnaire,
  ProductModelContract,
  CampaignModelContract,
  KeywordsModelContract,
} from '@/injectables';
import { BaseProductModel } from '../../base-product/implementations';
import { v4 as uuidv4 } from 'uuid';

import { ProductFlight, Product, UnsafeAny, CreativeConfig, ConfiguredCreativeIO, FormConfig } from '@/shared/types';
import { cloneDeep, isObject } from 'lodash';
import { Models } from '@/injectables/tokens';
import { ProductConfigCategory, ProductConfigType } from '@/app/graphql';

@injectable()
export class InstantModel extends BaseProductModel implements InstantModelContract {
  @inject(Models.Creatives) private readonly _creatives: CreativeModelContract;
  @inject(Models.Product) private readonly _products: ProductModelContract;
  @inject(Models.Campaign) private readonly _campaign: CampaignModelContract;
  @inject(Models.Keywords) private readonly _keywords: KeywordsModelContract;

  flightBudgetRecalculation(product: ConfiguredProductIO): ConfiguredProductIO {
    const splitDivide = (div, dev) => {
      const modulo = div % dev;
      const devResult = div / dev;

      const others = parseInt(devResult.toString(), 10);

      const moduloToFifty = others % 50;

      return [others + modulo + moduloToFifty * (dev - 1), others - moduloToFifty];
    };

    const { flights = [], budget: budget } = product;

    const { budgetDiff, flightBudget, unlockedBudget, unlockedFlightCount } = this.budgetInfo(product);
    if (budget < flightBudget - unlockedBudget) {
      const unlocked = flights.map(pr => ({ ...pr, isLocked: false }));
      return this.flightBudgetRecalculation({ ...product, flights: unlocked });
    }
    const [first, others] = splitDivide(unlockedBudget + budgetDiff, unlockedFlightCount);

    let firstFound = false;

    let recalculated: ProductFlight[] = [];
    for (let c = flights.length - 1; c >= 0; c--) {
      const flight = { ...flights[c] };
      if (!flight.isLocked && !flight.isChanged) {
        flight.budget = firstFound ? others : first;
        firstFound = true;
      }
      // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
      const { isChanged, ...fl } = flight as ProductFlight;
      recalculated = [fl, ...recalculated];
    }

    return { ...product, flights: recalculated };
  }

  budgetInfo(product: ConfiguredProductIO) {
    const { flights, budget } = product;
    const flightBudget = flights.reduce((acc, el) => acc + el.budget, 0);

    const unlockedFlightCount = flights.reduce((acc, el) => (el.isLocked || el.isChanged ? acc : acc + 1), 0);
    const unlockedBudget = flights.reduce((acc, el) => (el.isLocked || el.isChanged ? acc : acc + el.budget), 0);

    return { budgetDiff: budget - flightBudget, flightBudget, unlockedFlightCount, unlockedBudget };
  }

  flightsConfigured(product: ConfiguredProductIO): boolean {
    if (!this.hasFlights(product)) return false;

    const { flightConfigs = [] } = product;
    const hasPlatform = this.hasPlatform(flightConfigs);
    const isGeofence = this.isGeofence(flightConfigs);

    return product.flights.every(f => {
      return (
        (!isGeofence || this.mapConfigured(f)) &&
        (!hasPlatform || f?.selectedPlatform?.length) &&
        f?.targetingOption?.length > 0 &&
        this.flightHasBudget(f) &&
        this._keywords.flightKeywordsConfigured(f) &&
        f?.startDate &&
        f?.endDate
      );
    });
  }

  productIsConfigured(product): boolean {
    if (!product) return false;
    if (!product.name) return false;

    if (this.isPaidSearchProduct(product)) {
      return this.paidSearchConfigured(product);
    }
    if (this.isXMLProduct(product)) {
      return this.xmlConfiguredProduct(product);
    }

    return this.flightsConfigured(product);
  }

  getUpdatedProduct(product): ConfiguredProductIO {
    if (this.isPaidSearchProduct(product)) {
      return product;
    }

    if (this.fixedRateConfig(product)) {
      const flightsBudget = Math.max(
        product.flights.reduce((acc, el) => acc + el.budget, 0),
        product.minSpend,
      );
      return {
        ...product,
        budget: flightsBudget,
        isLocked: true,
      };
    }
    const { budget, flights } = product;
    if (
      (flights || []).some(
        el => this.isFlightRateTypeQuote(el) || this.isFlightRateTypeCpm(el) || this.isCostPerFlightRate(el),
      )
    ) {
      const sBudget = flights.filter(el => el.isLocked).reduce((acc, el) => acc + el.budget, 0);

      if (flights.every(el => el.isLocked)) {
        return {
          ...product,
          budget: sBudget,
          isLocked: true,
        };
      }
      return this.flightBudgetRecalculation({
        ...product,
        budget: budget < sBudget ? sBudget : budget,
        isLocked: false,
      });
    }

    return this.flightBudgetRecalculation(product);
  }

  getNewFlight(product, { budget, dates, geoSelections, locations, demographics = [], audience = [] }): ProductFlight {
    const productFlights = [...(product?.flights || [])];
    const flight: ProductFlight = {
      isLocked: false,
      id: uuidv4(),
      index: productFlights.length + 1,
      budget: budget,
      rateType: null,
      rate: 0,
      startDate: dates[0],
      endDate: dates[1],
      ...(!this.hasPlatform(product.flightConfigs) && { selectedPlatform: '' }),
    };
    if (this.isGeofence(product.flightConfigs)) {
      let geoSelections = { addressList: [] };

      if (productFlights.length) {
        const sorted = [...productFlights].sort((a, b) => b.index - a.index);

        const [mostRecent] = sorted;

        if (mostRecent?.market?.geoSelections) {
          geoSelections = cloneDeep(mostRecent.market.geoSelections);
        }
      }

      if (!geoSelections?.addressList?.length && locations?.length && locations.some(l => l?.address)) {
        const mapped = locations
          .filter(l => l.address)
          .map((l, i) => {
            return {
              id: (Date.now() + i * 10000).toString(),
              address: l.address,
              radius: '400',
              unitType: 'Meters',
              ...(l?.lat && l?.lon ? { lat: l.lat, lon: l.lon } : {}),
            };
          });

        geoSelections = { addressList: [...mapped] };
      }

      flight.market = { geoSelections: cloneDeep({ addressList: geoSelections.addressList }) };
    } else {
      flight.market = null;
      flight.demographics = null;
      flight.audience = null;
    }

    return flight;
  }

  getNewProduct(product, { budget, dates, locations, geoSelections, audience, demographics }, index) {
    const creativesConfig = this._creatives.getCreativeConfig(product);
    const configDefaults = (selection): {} | { flights: ProductFlight[] } => {
      let propertyToReturn = {};

      if (this.isXMLProduct(selection)) {
        propertyToReturn = { broadcastInfo: { link: '', broadcast: [] } };
      } else if (this.isPaidSearchProduct(selection)) {
        propertyToReturn = { keywords: { list: [], summary: {}, keymetrics: '' } };
      } else {
        propertyToReturn = { flights: [] };
      }

      return propertyToReturn;
    };

    const {
      name,
      dsId,
      minSpend,
      keyMetric,
      keyMetricMultiplier,
      description,
      type,
      category,
      recommendedBudget,
      flightType,
      isMonthly,
      fulfillmentMethod,
    } = product;
    const newProduct = {
      id: 'temp_' + product.id,
      name,
      dsId,
      type,
      category,
      minSpend,
      flightType,
      description,
      keyMetric,
      keyMetricMultiplier,
      budget: budget,
      recommendedBudget,
      fulfillmentMethod,
      minDays: product.minDays || 0,
      productConfigId: product.id,
      index,
      isMonthly,
      isLocked: false,
      creativesConfig: creativesConfig,
      creatives: [],
      ...(product.rateRangeList ? { rateRangeList: product.rateRangeList } : {}),
      ...configDefaults(product),
      flightConfigs: product.flightConfigs,
      isChanged: false,
      ...this.optional(product.cpcMargin, 'cpcMargin'),
      ...this.optional(product.omsAccountNumber, 'omsAccountNumber'),
      ...this.optional(product.omsName, 'omsName'),
      ...this.optional(product.ioRecipientEmail, 'ioRecipientEmail'),
      ...this.optional(product.slideType, 'slideType'),
      ...this.optional(product.IORecipientEmail, 'IORecipientEmail'),
      ...this.optional(product.rateRangeList, 'rateRangeList'),
      ...this.optional(product.noXmlFlag, 'noXmlFlag'),
      ...this.optional(product.questions, 'questions'),
    };

    if (newProduct?.flights) {
      newProduct.flights = [
        this.getNewFlight(newProduct, { budget, dates, locations, geoSelections, audience, demographics }),
      ];
    }

    return newProduct;
  }

  getNewCreative(product: Product, creativesConfig: Omit<CreativeConfig, 'index'>): ConfiguredCreativeIO {
    const configs = creativesConfig.FormConfig || [];
    const { flights, creatives = [] } = product;
    if (!configs.length) return { index: 0, id: uuidv4() }; // fake index for now

    const newCreative: ConfiguredCreativeIO = { index: 0, id: uuidv4() };
    configs.forEach(({ DataType, PropertyId }) => {
      if (PropertyId === 'flightIds') {
        newCreative[PropertyId] = [];
      } else {
        newCreative[PropertyId] = DataType === 'string' ? '' : 0;
      }
    });

    const productHasOnlyOneFlight = flights?.length === 1;
    const productHasOnlyOneCreative = creatives?.length === 0;

    if (productHasOnlyOneCreative) {
      newCreative.flightIds = flights?.map(flight => flight.id);
    } else if (productHasOnlyOneFlight) {
      newCreative.flightIds = [flights[0].id];
      newCreative.selectedAdFormatList = [];
    }

    return { ...newCreative, index: creatives.length + 1 };
  }

  getNewCreativeChild(creative): ConfiguredCreativeIO {
    const configs = this._creatives.getChildConfigs(creative) || [];
    const creatives = creative?.creatives || [];
    if (!configs.length) return { index: 0, id: uuidv4(), parentId: creative.id }; // fake index for now
    const newCreative: ConfiguredCreativeIO = { index: 0, id: uuidv4(), parentId: creative.id };
    configs.forEach(c => {
      const propertyName = c.PropertyId;
      const dataDefault = c.DataType === 'string' ? '' : 0;
      newCreative[propertyName] = dataDefault;
    });
    const index = Math.max(...creatives.map(c => c.index), 0);
    return { ...newCreative, index: index + 1 };
  }

  getNewQuestionnaire(questions: Questionnaire[]) {
    const emptyQuestionnaire = questions.map(q => {
      return {
        ...q,
        question: {
          text: q.text,
        },
        answer: '',
        questionnaireId: q.id,
      };
    });

    return emptyQuestionnaire;
  }

  creativeConfigured(
    product: ConfiguredProductIO,
    isQuestionnaire = this.canHaveQuestionnaire(product) || false,
  ): boolean {
    if (isQuestionnaire || product.category === ProductConfigCategory.Search) {
      const questionnaire = product.questionnaire;

      if (!questionnaire) return false;
      // TODO: check only required questions (now all required)
      const requiredQuestionWithNoAnswer = questionnaire.find(q => !q.answer);
      return !requiredQuestionWithNoAnswer;
    }

    if (product.category === ProductConfigCategory.Social) {
      return (
        product.creatives.length !== 0 &&
        product.creatives.every(c => {
          const { selectedAdFormatList } = c;
          const adType = selectedAdFormatList?.[0] || null;
          return (
            (c.postText && c.headline && c.description && c.socialLink && c.url) ||
            adType?.toLowerCase()?.includes('carousel')
          );
        })
      );
    }

    return product.creativesConfig.creativesLimit === 0 || product.creatives.length !== 0;
  }

  getDownloadIOFormat(campaign: InstantCampaign, geoType) {
    const updated: UnsafeAny = { ...campaign };
    const geoMappers = {
      zip: el => el,
      city: el => el,
      dma: el => el,
      state: el => el,
      county: el => el,
    };
    const geo = geoType.toLowerCase();
    updated.geoSelections = { [geo + 'List']: geoMappers[geo](updated.geoSelections) };
    updated.selectedProducts = updated.selectedProducts.map(product => ({
      ...product,
      creatives: product.creatives.map(creative =>
        Object.fromEntries(Object.entries(creative).filter(([, value]) => Boolean(value) || value === 0)),
      ),
    }));

    updated.audienceSegments = [...updated.audience, ...updated.demographics].filter(isObject);

    return updated;
  }

  formatInstantCampaign(campaign) {
    const { id, market, startDate, endDate, client, audienceSegments, conversionTracking, products, ...otherCampaign } =
      campaign;
    const updatedProducts = products.map(product => ({
      ...product,
      ...(product.flights ? { flights: product.flights?.map(flight => this._prepareFlightSegments(flight)) } : {}),
      creatives: product.creatives.map(creative => {
        const { flights, ...cleanCreative } = creative;
        return { ...cleanCreative, ...(flights ? { flightIds: flights.map(({ id }) => id) } : {}) };
      }),
      creativesConfig: this._creatives.getCreativeConfig(product),
    }));
    const { geoSelections = {} } = market || {};
    const [geoType, cleanGeoSelections] = Object.entries(geoSelections).find(
      ([, value]) => value && Array.isArray(value) && value.length,
    ) || ['DMA', []];

    const { audience = [], demographics = [] } = this._targetingSegments.getGroupSegments(audienceSegments);
    const cleanGeoType = geoType.replace('List', '').toUpperCase();

    return {
      id,
      campaign: {
        ...otherCampaign,
        conversionTracking: { ...this._campaign.getDefaultTrackingConfiguration(), ...(conversionTracking || {}) },
        geoSelections: cleanGeoSelections,
        demographics,
        audience,
        dates: [startDate, endDate],
        selectedProducts: updatedProducts,
      },
      client,
      geoType:
        cleanGeoType === 'STATE' &&
        Array.isArray(cleanGeoSelections) &&
        cleanGeoSelections?.length === this._products.statesAndDistrictsNumber?.length
          ? 'NATIONAL'
          : cleanGeoType,
    };
  }

  getPreparedCampaign(campaign, client, geoType, canTrack = false) {
    const { name, audience, demographics, pixelRequest, goLiveWitoutRetargeting, conversionTracking } = campaign;
    const [startDate, endDate] = campaign.dates;
    const products = this.getCleanProducts(campaign.selectedProducts, false, true, [
      'isChanged',
      'creativesConfig',
      'questions',
      'calcMinSpend',
    ]);
    const audienceSegmentIds = [...demographics, ...audience]
      .filter(segment => typeof segment !== 'number')
      .map(segment => segment.id);

    const geo = geoType.toLowerCase();
    // TODO: rethink it
    const preparedGeoType = geo === 'national' ? 'state' : geo;

    const newMarket = { geoSelections: { [preparedGeoType + 'List']: campaign?.geoSelections || [] } };

    const isTrackable = products.some(product => this.isTrackableProduct(product));

    return {
      name,
      startDate,
      endDate,
      pixelRequest,
      goLiveWitoutRetargeting,
      conversionTracking: canTrack && isTrackable ? conversionTracking : null,
      clientId: client.id,
      products,
      market: newMarket,
      audienceSegments: audienceSegmentIds,
    };
  }

  getFlightImpressions(flight: ProductFlight): string {
    const rate = flight.rate === 0 ? 1 : flight.rate;
    const impressions = flight.budget ? (+flight.budget / +rate) * 1000 : 0;
    return `${Math.round(impressions)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
  }

  getProductAfterGeoChanged(product: ConfiguredProductIO): ConfiguredProductIO {
    if (product.type === ProductConfigType.GoogleSearch) {
      return { ...product, keywords: { list: [], summary: {} } };
    }
    return product;
  }

  instantCampaignDuplicateDisabled(campaign: InstantCampaign): boolean {
    const issueDate = '2024-08-23T00:00:00.000Z'; // FIX for issue https://extendtv.atlassian.net/browse/PROD-8642 all campaigns before this date have issue
    const campaignIso = new Date(campaign.createdAt).toISOString();
    return issueDate > campaignIso;
  }
}

import 'reflect-metadata';
import { injectable } from 'inversify';
import { ProductModelContract, ProductFulfillmentMethods, TAvailsProduct } from '@/injectables';
import { UnsafeAny } from '@/shared/types';
import { FlightConfig, ProductConfig } from '@/shared/types';
import { CongressionalDistrictObj } from '@/shared/types';
import { AvailsProduct, ProductConfigCategory, ProductConfigFlightType, ProductConfigType } from '@/app/graphql';

@injectable()
export class ProductModel implements ProductModelContract {
  // TODO: replace UnsafeAny with actual types here
  private _source: UnsafeAny;

  public fulfillmentMethodsForEdit = [
    ProductFulfillmentMethods.Inhouse,
    ProductFulfillmentMethods.Compulse,
    ProductFulfillmentMethods.Oms,
  ];

  public fulfillmentMethodsForCreate = [ProductFulfillmentMethods.Inhouse, ProductFulfillmentMethods.Oms];

  // TODO: replace UnsafeAny with actual types here
  setProductData(source: UnsafeAny) {
    this._source = source;
  }

  get isMinSpendLocked(): boolean {
    const xmlTypes = [ProductConfigType.Linear, ProductConfigType.Radio];
    return xmlTypes.includes(this._source.type);
  }

  isChangeMarginAvailable(isGlobal: boolean) {
    return (
      (this._source.category === ProductConfigCategory.Social ||
        this._source.type === ProductConfigType.GoogleSearch ||
        this._source.type === ProductConfigType.Youtube) &&
      !isGlobal
    );
  }

  statesAndDistrictsNumber = [
    {
      state: 'Alabama',
      short: 'STAT_AL',
      districts: 7,
    },
    {
      state: 'Alaska',
      short: 'STAT_AK',
      districts: 1,
    },
    {
      state: 'Arizona',
      short: 'STAT_AZ',
      districts: 9,
    },
    {
      state: 'Arkansas',
      short: 'STAT_AR',
      districts: 4,
    },
    {
      state: 'California',
      short: 'STAT_CA',
      districts: 52,
    },
    {
      state: 'Colorado',
      short: 'STAT_CO',
      districts: 8,
    },
    {
      state: 'Connecticut',
      short: 'STAT_CT',
      districts: 5,
    },
    {
      state: 'Delaware',
      short: 'STAT_DE',
      districts: 1,
    },
    {
      state: 'District of Columbia',
      short: 'STAT_DC',
      districts: 0,
    },
    {
      state: 'Florida',
      short: 'STAT_FL',
      districts: 28,
    },
    {
      state: 'Georgia',
      short: 'STAT_GA',
      districts: 14,
    },
    {
      state: 'Hawaii',
      short: 'STAT_HI',
      districts: 2,
    },
    {
      state: 'Idaho',
      short: 'STAT_ID',
      districts: 2,
    },
    {
      state: 'Illinois',
      short: 'STAT_IL',
      districts: 17,
    },
    {
      state: 'Indiana',
      short: 'STAT_IN',
      districts: 9,
    },
    {
      state: 'Iowa',
      short: 'STAT_IA',
      districts: 4,
    },
    {
      state: 'Kansas',
      short: 'STAT_KS',
      districts: 4,
    },
    {
      state: 'Kentucky',
      short: 'STAT_KY',
      districts: 6,
    },
    {
      state: 'Louisiana',
      short: 'STAT_LA',
      districts: 6,
    },
    {
      state: 'Maine',
      short: 'STAT_ME',
      districts: 2,
    },
    {
      state: 'Maryland',
      short: 'STAT_MD',
      districts: 8,
    },
    {
      state: 'Massachusetts',
      short: 'STAT_MA',
      districts: 9,
    },
    {
      state: 'Michigan',
      short: 'STAT_MI',
      districts: 13,
    },
    {
      state: 'Minnesota',
      short: 'STAT_MN',
      districts: 8,
    },
    {
      state: 'Mississippi',
      short: 'STAT_MS',
      districts: 4,
    },
    {
      state: 'Missouri',
      short: 'STAT_MO',
      districts: 8,
    },
    {
      state: 'Montana',
      short: 'STAT_MT',
      districts: 2,
    },
    {
      state: 'Nebraska',
      short: 'STAT_NE',
      districts: 3,
    },
    {
      state: 'Nevada',
      short: 'STAT_NV',
      districts: 4,
    },
    {
      state: 'New Hampshire',
      short: 'STAT_NH',
      districts: 2,
    },
    {
      state: 'New Jersey',
      short: 'STAT_NJ',
      districts: 12,
    },
    {
      state: 'New Mexico',
      short: 'STAT_NM',
      districts: 3,
    },
    {
      state: 'New York',
      short: 'STAT_NY',
      districts: 26,
    },
    {
      state: 'North Carolina',
      short: 'STAT_NC',
      districts: 14,
    },
    {
      state: 'North Dakota',
      short: 'STAT_ND',
      districts: 1,
    },
    {
      state: 'Ohio',
      short: 'STAT_OH',
      districts: 15,
    },
    {
      state: 'Oklahoma',
      short: 'STAT_OK',
      districts: 5,
    },
    {
      state: 'Oregon',
      short: 'STAT_OR',
      districts: 6,
    },
    {
      state: 'Pennsylvania',
      short: 'STAT_PA',
      districts: 17,
    },
    {
      state: 'Rhode Island',
      short: 'STAT_RI',
      districts: 2,
    },
    {
      state: 'South Carolina',
      short: 'STAT_SC',
      districts: 7,
    },
    {
      state: 'South Dakota',
      short: 'STAT_SD',
      districts: 1,
    },
    {
      state: 'Tennessee',
      short: 'STAT_TN',
      districts: 9,
    },
    {
      state: 'Texas',
      short: 'STAT_TX',
      districts: 38,
    },
    {
      state: 'Utah',
      short: 'STAT_UT',
      districts: 4,
    },
    {
      state: 'Vermont',
      short: 'STAT_VT',
      districts: 1,
    },
    {
      state: 'Virginia',
      short: 'STAT_VA',
      districts: 11,
    },
    {
      state: 'Washington',
      short: 'STAT_WA',
      districts: 10,
    },
    {
      state: 'West Virginia',
      short: 'STAT_WV',
      districts: 2,
    },
    {
      state: 'Wisconsin',
      short: 'STAT_WI',
      districts: 8,
    },
    {
      state: 'Wyoming',
      short: 'STAT_WY',
      districts: 1,
    },
  ];

  stateNameByShort(short: string): string {
    const foundStateObj = this.statesAndDistrictsNumber.find(el => el.short === short);

    return foundStateObj?.state || '';
  }

  congressionalDistricts(): CongressionalDistrictObj[] {
    return this.statesAndDistrictsNumber.reduce((totalArr, stateObj) => {
      const { state, short, districts } = stateObj;
      for (let i = 1; i <= districts; i++) {
        const districtName = `${state} ${i < 10 && districts !== 1 ? '0' : ''}${districts === 1 ? 99 : i}`;
        const shortName = `${short}_${districts === 1 ? 99 : i}`;

        totalArr.push({ name: districtName, short: shortName });
      }
      return totalArr;
    }, []);
  }
  agenciesWithCongressionalDistricts = ['Katz_Media_Group'];

  ottProductIds = ['71', '131'];

  isProductOptionAvailable(type: ProductConfigType): boolean {
    const ProductIds = [ProductConfigType.GoogleSearch];
    return ProductIds.includes(type);
  }

  isRateConfigurable(product: ProductConfig, flightsConfig: FlightConfig[]): boolean {
    const checkProductFlightType = product.flightType === ProductConfigFlightType.Social;
    const checkPlatformOption = !flightsConfig.some(f => f.platformOption);
    const checkRateTypeInflight = !flightsConfig.some(f => f.rateType);
    return !(checkProductFlightType && checkPlatformOption && checkRateTypeInflight);
  }

  getEmptyProduct() {
    return {
      name: '',
      minSpend: 0,
      recommendedBudget: 0,
      description: '',
      category: 'Other',
      flightConfigs: [
        {
          index: Math.random().toString(16),
          targetingOption: '',
          rateType: 'CPC',
          rate: 0,
        },
      ],
      fulfillmentMethod: ProductFulfillmentMethods.Inhouse,
    };
  }

  defaultMarkup = { min: 0.2, max: 0.2 };

  getProductMarkup(product): { min: number; max: number } {
    if (!product || !product.flightConfigs || !Array.isArray(product.flightConfigs) || !product.flightConfigs.length) {
      return this.defaultMarkup;
    }
    const { markupMin: min, markupMax: max } = product.flightConfigs[0];
    const minValidated = typeof min === 'number' ? min : this.defaultMarkup.min;
    const maxValidated = typeof max === 'number' ? max : this.defaultMarkup.max;

    return { min: minValidated, max: maxValidated };
  }

  availsAvailableProducts(): TAvailsProduct[] {
    return [
      { id: '1', name: 'Standard CTV', category: ProductConfigCategory.Ctv, availsProduct: AvailsProduct.Ott },
      {
        id: '2',
        name: 'Programmatic Display',
        category: ProductConfigCategory.Display,
        availsProduct: AvailsProduct.Display,
      },
      {
        id: '3',
        name: 'Programmatic Video',
        category: ProductConfigCategory.Video,
        availsProduct: AvailsProduct.Video,
      },
      { id: '4', name: 'Digital Audio', category: ProductConfigCategory.Digaud, availsProduct: AvailsProduct.Audio },
    ];
  }
}

import { injectable } from 'inversify';
import { Err, Ok, Result } from '@sniptt/monads';
import { Failure } from '@/injectables/failure';

import { SemKeywordsForProducts, KeywordsServiceContract } from '@/injectables';

import { SemKeyword, KeywordSummary } from '@/shared/types';

import {
  GetSemKeywordsQuery,
  GetSemKeywordsDocument,
  GetSemKeywordsQueryVariables,
} from '../graphql/queries/get-sem-keywords.generated';
import {
  GetCustomKeywordsDocument,
  GetCustomKeywordsQuery,
  GetCustomKeywordsQueryVariables,
} from '../graphql/queries/get-custom-keywords.generated';
import {
  GetKeywordsSummaryDocument,
  GetKeywordsSummaryQuery,
  GetKeywordsSummaryQueryVariables,
} from '../graphql/queries/get-keywords-summary.generated';
import { Service } from '@/injectables/service';

@injectable()
export class KeywordsService extends Service implements KeywordsServiceContract {
  keywordEntryLimit = 300;
  ideasLimit = 20;

  fakeUrl = 'https://google.com';

  async getSemKeywords(params: SemKeywordsForProducts): Promise<Result<SemKeyword[], Failure>> {
    try {
      const preparedGeoInput = {
        // zips: params.geoSelection?.zips?.map(z => z?.key.replace(/ZIP_/g, '')),
        counties: params.geoSelection?.counties?.map(c => c?.key.replace(/CNTY_/g, '')),
        dmas: params.geoSelection?.dmas?.map(d => d?.key.replace(/DMA_/g, '')),
        states: params.geoSelection?.states?.map(s => s?.key.replace(/STAT_/g, '')),
        cities: params.geoSelection?.cities?.map(c => c?.key.replace(/CITY_/g, '')),
      };
      const { data, error } = await this._apollo.query<GetSemKeywordsQuery, GetSemKeywordsQueryVariables>({
        query: GetSemKeywordsDocument,
        variables: {
          input: {
            url: params.clientUrl || this.fakeUrl,
            limit: this.ideasLimit,
            keywords: params.keywords,
            productConfigId: params.productConfigId,
            geoSelection: preparedGeoInput,
          },
        },
      });

      if (error) {
        return Err({
          message: "Can't get keywords at this time.",
        });
      }

      const { getKeywordsStatistics } = data;

      const result = getKeywordsStatistics.map(k => {
        return {
          keyword: k.keyword || '',
          competition: k.competition || 0,
          competitionLevel: k.competitionLevel || '0',
          averageCpc: k.averageCpc || 0,
          searchVolume: k.searchVolume || 0,
          isSelected: k.isSelected || false,
          recommendLevel: k.recommendLevel || 0,
          isCustom: k.isCustom || false,
        };
      });

      return Ok(result);
    } catch (e) {
      return Err({
        message: `Can't get keywords at this time. ${e}`,
      });
    }
  }

  async getCustomKeywords({
    keywords,
  }: {
    agencyPropertyId: string;
    keywords: string[];
  }): Promise<Result<SemKeyword[], Failure>> {
    try {
      const { data, error } = await this._apollo.query<GetCustomKeywordsQuery, GetCustomKeywordsQueryVariables>({
        query: GetCustomKeywordsDocument,
        variables: {
          input: {
            keywords,
          },
        },
      });

      if (error) {
        return Err({
          message: "Can't get custom keywords at this time.",
        });
      }

      const { getCustomKeywords } = data;

      const result = getCustomKeywords.map(k => {
        return {
          keyword: k.keyword || '',
          competition: k.competition || 0,
          competitionLevel: k.competitionLevel || '0',
          averageCpc: k.averageCpc || 0,
          searchVolume: k.searchVolume || 0,
          isSelected: true,
          recommendLevel: k.recommendLevel || 0,
          isCustom: true,
        };
      });

      return Ok(result);
    } catch (error) {
      return Err({
        message: `Can't get custom keywords at this time. ${error}`,
      });
    }
  }

  async getKeywordsSummary({
    keywords,
    budget,
  }: {
    keywords: SemKeyword[];
    budget: number;
  }): Promise<Result<KeywordSummary, Failure>> {
    try {
      const { data, error } = await this._apollo.query<GetKeywordsSummaryQuery, GetKeywordsSummaryQueryVariables>({
        query: GetKeywordsSummaryDocument,
        variables: {
          input: {
            keywords,
            budget,
          },
        },
      });

      if (error) {
        return Err({
          message: "Can't get keyword summary at this time.",
        });
      }

      const { getKeywordsSummary } = data;

      const result = {
        numKeywords: getKeywordsSummary.numKeywords || 0,
        totalCpc: getKeywordsSummary.totalCpc || 0,
        totalClicks: getKeywordsSummary.totalClicks || 0,
        lowerCpc: getKeywordsSummary.lowerCpc || 0,
        upperCpc: getKeywordsSummary.upperCpc || 0,
        lowerClicks: getKeywordsSummary.lowerClicks || 0,
        upperClicks: getKeywordsSummary.upperClicks || 0,
      };

      return Ok(result);
    } catch (error) {
      return Err({
        message: `Can't get keyword summary at this time. ${error}`,
      });
    }
  }
}

<template>
  <div class="hidden-slide"></div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'HiddenSlide',
});
</script>

<style scoped>
.hidden-slide {
  pointer-events: none;
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: grey;
  opacity: 0.3;
  z-index: 99;
}
</style>

/* eslint-disable no-unused-vars */
import Vue from 'vue';
import Vuex, { Store } from 'vuex';
import { authModule } from './auth';
import { clientModule } from './client';
import { productModule } from './product';
import { proposalModule } from './proposal';
import { managementModule } from './management';
import { newProposalModule } from './newProposal';
import { outputModule } from './output/index';
import map from './map';
import { agencyModule } from './agency';
import { enumsModule } from './enums';
import { outputTextEditorModule } from './outputTextEditor';
import { Container } from 'inversify';
import { loadingModule } from './loading';
import { RootState, state } from './root/state';
import { mutations } from './root/mutations';
import { actions } from './root/actions';
import { notificationsModule } from './notifications';
import { targetingSegmentsModule } from './targetingSegments';

Vue.use(Vuex);

interface CreateStoreResult {
  store: Store<RootState>;
}

export enum Modules {
  Auth = 'auth',
  Agency = 'agency',
  Status = 'status',
  Client = 'client',
  Management = 'management',
  Map = 'map',
  NewProposal = 'newProposal',
  Product = 'product',
  Proposal = 'proposal',
  Output = 'output',
  OutputTextEditor = 'outputTextEditor',
  Enums = 'enums',
  Loading = 'loading',
  Notifications = 'notifications',
  TargetingSegments = 'targetingSegments',
}

export const createStore = (container: Container): CreateStoreResult => {
  const { module: auth } = authModule(container);
  const { module: agency } = agencyModule(container);
  const { module: client } = clientModule(container);
  const { module: output } = outputModule(container);
  const { module: management } = managementModule(container);
  const { module: proposal } = proposalModule(container);
  const { module: newProposal } = newProposalModule(container);
  const { module: product } = productModule(container);
  const { module: outputTextEditor } = outputTextEditorModule();
  const { module: targetingSegments } = targetingSegmentsModule(container);

  const enums = enumsModule();
  const loading = loadingModule();
  const notifications = notificationsModule();

  return {
    store: new Vuex.Store({
      state: state(),
      mutations: mutations(),
      actions: actions(),
      modules: {
        [Modules.Auth]: auth,
        [Modules.Agency]: agency,
        [Modules.Client]: client,
        [Modules.Management]: management,
        [Modules.Map]: map,
        [Modules.NewProposal]: newProposal,
        [Modules.Product]: product,
        [Modules.Proposal]: proposal,
        [Modules.Output]: output,
        [Modules.OutputTextEditor]: outputTextEditor,
        [Modules.Enums]: enums,
        [Modules.Loading]: loading,
        [Modules.Notifications]: notifications,
        [Modules.TargetingSegments]: targetingSegments,
      },
    }),
  };
};


import Vue, { PropType } from 'vue';
import { ConfiguredProduct, ConfiguredFlight, ProductConfig } from '@/shared/types';
import { Models, Services } from '@/injectables/tokens';
import { InstantModelContract } from '@/injectables';

import FlightCard from '@/entities/instantIO/ui/templates/FlightCard.vue';

import BasicFlight from '@/entities/instantIO/ui/product-configuration/BasicFlight.vue';
import NoRateFlight from '@/entities/instantIO/ui/product-configuration/NoRateFlight.vue';
import PlatformFlight from '@/entities/instantIO/ui/product-configuration/PlatformFlight.vue';
import SmartMailerFlight from '@/entities/instantIO/ui/product-configuration/SmartMailerFlight.vue';
import Geofence from '@/entities/instantIO/ui/product-configuration/geofence.vue';

import AdvancedOptionsDialog from '@/components/Proposal/Options/AdvancedOptionsDialog.vue';
export default Vue.extend({
  name: 'ProductOptions',

  useInjectable: [Services.Validation, Models.InstantIO],

  components: {
    FlightCard,
    NoRateFlight,
    PlatformFlight,
    SmartMailerFlight,
    Geofence,
    AdvancedOptionsDialog,
    BasicFlight,
  },
  props: {
    productConfig: {
      type: Object as PropType<ProductConfig>,
      required: true,
    },
    flight: {
      type: Object as PropType<ConfiguredFlight>,
      required: true,
    },
    flightNumber: {
      type: [Number, String],
      required: true,
    },
    product: {
      type: Object as PropType<ConfiguredProduct>,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: (): {
    openConfigMap: boolean;
  } => ({
    openConfigMap: false,
  }),

  computed: {
    fixedRateConfig() {
      return (this.instantIOEntity as InstantModelContract).fixedRateConfig(this.product);
    },
    isCostPerFlightRate() {
      return (this.instantIOEntity as InstantModelContract).isCostPerFlightRate(this.flight);
    },
    isPaidSearch(): boolean {
      return (this.instantIOEntity as InstantModelContract).isPaidSearchProduct(this.product);
    },
    isBasicFlight() {
      return (
        !this.isCostPerFlightRate &&
        !this.fixedRateConfig &&
        !this.variableConfig &&
        this.isRateConfigurable &&
        !this.isPlatformConfig
      );
    },
    isRateConfigurable(): boolean {
      return (this.instantIOEntity as InstantModelContract).isRateConfigurable(this.product);
    },
    isPlatformConfig() {
      return (this.instantIOEntity as InstantModelContract).hasPlatform(this.product.flightConfigs);
    },
    isGeofence() {
      return (this.instantIOEntity as InstantModelContract).isGeofence(this.product.flightConfigs);
    },
    isCpmRateType() {
      return this.flight?.rateType === 'CPM' || this.flight?.rateType === null;
    },
    flightImpressions() {
      if (this.isCpmRateType) {
        const impressions = (this.instantIOEntity as InstantModelContract).getFlightImpressions(this.flight);
        return `${impressions} Impressions`;
      } else {
        return null;
      }
    },
    currentConfig() {
      if (this.flight?.targetingOption) {
        return this.findConfig(this.flight.targetingOption);
      }
      return {};
    },
    productMinDays(): number {
      return this.product?.minDays || 0;
    },
    productMinSpend() {
      return this.product.minSpend;
    },
    productMaxSpend() {
      const lockedBudget = this.product.flights.reduce((acc, el) => (el.isLocked ? acc + el.budget : acc), 0);
      const diff = this.product.budget - lockedBudget;
      return diff > 0 ? diff : 0;
    },
    productBudgetLimit(): number {
      return this.flight?.isLocked ? this.flight.budget + this.productMaxSpend : this.productMaxSpend;
    },
    flightConfigs() {
      return this.product?.flightConfigs || [];
    },
    mapAvailable() {
      return !this.isGeofence;
    },
    flightGeoCount() {
      if (!this.flight?.market?.geoSelections) return undefined;
      const {
        zipList = [],
        countyList = [],
        stateList = [],
        dmaList = [],
        cityList = [],
      } = this.flight?.market?.geoSelections || {};
      const number = [zipList, countyList, stateList, dmaList, cityList].reduce(
        (acc, curr) => acc + curr?.length || 0,
        0,
      );
      return number || undefined;
    },
  },
  methods: {
    updateFlight(flight) {
      this.$emit('update-flight', flight);
    },
    switchLock() {
      const { isLocked } = this.flight;
      this.updateFlight({ ...this.flight, isLocked: !isLocked });
    },
    updateAdvancedOptions(advancedOptions) {
      this.updateFlight({ ...this.flight, advancedOptions });
    },
    findConfig(val) {
      return this.flightConfigs.find(c => c?.targetingOption === val);
    },
  },
});

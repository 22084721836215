
import Vue from 'vue';

export default Vue.extend({
  name: 'IconColorPicker',

  props: {
    value: {
      type: String,
      required: true,
    },
  },

  computed: {
    color: {
      get(): string {
        return this.value;
      },
      set(color: string) {
        this.$emit('input', color);
      },
    },
  },
});

import { Err, Ok, Result } from '@sniptt/monads';
import { injectable } from 'inversify';
import { DefaultBuzzboardResponse, ClientInsights, ClientProspect } from '@/shared/types';

import { Failure } from '@/injectables/failure';

import { Service } from '../../../service';

@injectable()
export class ClientService extends Service {
  clientRecommendedProposalBudgets({
    dailySpend,
    numOfProposalDays,
  }: {
    dailySpend: number;
    numOfProposalDays: number;
  }): number[] {
    const staticBudgets = [5000, 7500, 10000, 25000, 50000, 75000, 100000, 125000, 150000, 175000, 200000];

    const spendAcrossDates = dailySpend * numOfProposalDays;

    const roundedUp = Math.ceil(spendAcrossDates / 1000) * 1000;

    const higherBudgetRecommendations = staticBudgets.filter(b => b > roundedUp).sort((a, b) => a - b);

    return [...(roundedUp ? [roundedUp] : []), ...higherBudgetRecommendations].slice(0, 4);
  }

  // Buzzboard
  async getProspect(clientPropertyId: string): Promise<Result<ClientProspect, Failure>> {
    try {
      const { data } = await this._axios.get(`/api/buzzboard/getProspect?clientPropertyId=${clientPropertyId}`);
      if (data.error) {
        return Err({
          message: `Error while loading buzzboard prospect: ${data.error}`,
        });
      }
      return Ok(data);
    } catch (error) {
      const { data } = error.response || {};
      return Err({
        message: `Error while loading buzzboard prospect: ${data.error}`,
      });
    }
  }

  async getInsights(clientPropertyId: string): Promise<Result<ClientInsights, Failure>> {
    try {
      const { data } = await this._axios.get(`/api/buzzboard/getInsights?clientPropertyId=${clientPropertyId}`);
      if (data.error) {
        return Err({
          message: `Error while loading buzzboard insights: ${data.error}`,
        });
      }
      return Ok(data);
    } catch (error) {
      const { data } = error.response || {};
      return Err({
        message: `Error while loading buzzboard insights: ${data.error}`,
      });
    }
  }

  async refreshProspect(clientPropertyId: string): Promise<Result<DefaultBuzzboardResponse, Failure>> {
    try {
      const { data } = await this._axios.post('/api/buzzboard/refreshProspect', { clientPropertyId });
      if (!data.success) {
        const message = data?.message || '';
        const errorData = message.substring(message.indexOf('{'), message.lastIndexOf('}') + 1);
        let errorMessage = 'Invalid client data';
        const parsedErrorData = JSON.parse(errorData);

        if (parsedErrorData && parsedErrorData?.errors) {
          errorMessage = '';
          for (const error of Object.values(parsedErrorData.errors)) {
            errorMessage = `${errorMessage} ${error}`;
          }
        }

        return Err({
          message: `Error while refreshing buzzboard prospect: ${errorMessage}`,
        });
      }
      if (data.error) {
        return Err({
          message: `Error while refreshing buzzboard prospect: ${data.error}`,
        });
      }
      return Ok(data);
    } catch (error) {
      return Err({
        message: `Error while refreshing buzzboard prospect: ${error}`,
      });
    }
  }
}

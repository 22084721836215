import * as Types from '../../../../../app/graphql/_types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type GetPagesQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetPagesQuery = {
  getPages?: Array<{
    id: string;
    title: string;
    pageContent: Record<string, any>;
    index: number;
    pageList?: Array<{ id: string; title: string; pageContent: Record<string, any>; index: number } | null> | null;
  } | null> | null;
};

export const GetPagesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPages' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getPages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pageContent' } },
                { kind: 'Field', name: { kind: 'Name', value: 'index' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageList' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'pageContent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'index' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetPagesQuery, GetPagesQueryVariables>;


import Vue, { PropType } from 'vue';
import { Route } from '@/entities/user/config/routes';
import { MenuItemRoute } from '@/features/user-menu';

import { C360AsideMenu } from '@c360/ui';
import { mapGetters } from 'vuex';
import { TrackerContract } from '@/injectables';
import { Services } from '@/injectables/tokens';

interface SideBarData {
  rail: boolean;
}

export default Vue.extend({
  name: 'SideBar',

  components: { C360AsideMenu },

  useInjectable: [Services.Tracker],

  props: {
    routes: {
      type: Array as PropType<Route[]>,
      default: () => [],
    },
    currentRouteName: {
      type: String,
      required: true,
    },
  },
  data(): SideBarData {
    return {
      rail: true,
    };
  },

  computed: {
    ...mapGetters({ sideBarItems: 'auth/sideBarItems' }),
    items(): MenuItemRoute[] {
      return this.sideBarItems;
    },
  },

  methods: {
    navigate(route) {
      if (route.id === 'instantCampaigns' && this.tracker) {
        (this.tracker as TrackerContract).trackInstantIoPageVisited();
      }

      this.$router.push({ name: route.id });
    },
  },
});


import Vue from 'vue';
import debounce from 'lodash.debounce';
import { UnsafeAny } from '@/shared/types';
import { Services, Models } from '@/injectables/tokens';
import { GeoModelContract, GeocodeServiceContract } from '@/injectables';

const addressToLatLong: UnsafeAny = {};
const mappedStateAddresses: UnsafeAny = {};

export default Vue.extend({
  name: 'MapAddressSearch',

  inject: ['$confirm', 'showSnackbar'],

  useInjectable: [Services.Geocode, Models.Geo],

  props: {
    productId: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: 'Type location here',
    },
    width: {
      type: [Number, String],
      default: 280,
    },
    currentGeoType: {
      type: String,
      required: true,
    },
  },

  data: (): {
    address: string;
    addressSearch: string;
    fetchingLocations: boolean;
    locationAutocompleteList: object[];
  } => ({
    address: '',
    addressSearch: '',
    fetchingLocations: false,
    locationAutocompleteList: [],
  }),

  computed: {
    toolbarWidth(): number {
      return Number(this.width) + 30;
    },
    items(): object[] {
      const matches: UnsafeAny[] = [];
      this.locationAutocompleteList.forEach(entry => {
        const type = entry?.type || entry.administrativeAreaType;
        if (entry.lat && entry.long && entry.key && entry.name) {
          const name = (this[Models.Geo] as GeoModelContract).geoNameWithState(entry);

          matches.push({
            text: name,
            address: entry.key,
            lat: entry.lat,
            lon: entry.long,
            type: type,
            key: entry.key,
          });
          if (!addressToLatLong[entry.key]) {
            addressToLatLong[entry.key] = {
              lat: entry.lat,
              lon: entry.long,
            };
          }
        }
      });

      return [...matches];
    },
  },

  watch: {
    address(address: string): void {
      if (address) {
        this.setAddress(address);
        this.$nextTick(() => this.$refs?.mapAddressSearch?.reset());
      }
    },
    addressSearch(val: string): void {
      if (!val || val.length < 3) {
        return;
      }
      this.fetchingLocations = true;
      this.fetchAutocompleteApi(val);
    },
  },

  methods: {
    fetchAutocompleteApi: debounce(async function (query: string) {
      const geocodeService: GeocodeServiceContract = this.geocodeService;

      const { isErr, unwrapErr, unwrap } = await geocodeService.searchByLocationWithGeoType(query, this.currentGeoType);

      if (isErr()) {
        this.showSnackbar(unwrapErr().message, 'error');

        return;
      }

      this.locationAutocompleteList = unwrap();

      this.fetchingLocations = false;
    }, 500),
    async setAddress(address: string): Promise<void> {
      const addressObj = this.items.find(a => a.address === address);

      if (addressObj) {
        if (this.productId) {
          addressObj.productId = this.productId;
        }

        this.$emit('search-geo', addressObj);

        this.$store.dispatch('newProposal/setMarketAddress', addressObj).catch(err => {
          // eslint-disable-next-line no-console
          console.error(err);
        });
      } else {
        // eslint-disable-next-line no-console
        console.log('where did it go?');
      }
    },
  },
});

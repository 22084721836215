
import Vue from 'vue';
import { format, max, min, parseISO } from 'date-fns';

import { Models } from '@/injectables/tokens';
import { AdminReviewDisabledStatuses, AdminReviewEnabledStatuses, StatusModelContract } from '@/injectables';

import { DateRangePicker } from '@/shared/ui';

import { C360Icon } from '@c360/ui';
import { InstantCampaignStatus } from '@/app/graphql';

type DateType = {
  key: string;
  from: string;
  to: string;
  title: string;
  range: [];
  nextFieldRef: string;
  nextFieldRefTrigger?: string;
};

export default Vue.extend({
  name: 'CampaignsTableFilters',

  components: { DateRangePicker, C360Icon },

  props: {
    filters: {
      type: Object,
    },
    searchTerm: {
      type: String,
      default: '',
    },
    skipStatusFilter: {
      type: Boolean,
      default: false,
    },
    customStatusFilterValues: {
      type: Object,
      default: () => ({}),
    },
    isInstantIO: {
      type: Boolean,
      default: false,
    },
  },

  data: (): {
    statusFilter: string[];
    dates: {
      [key: string]: DateType;
    };
    showFiltersMenu: boolean;
    localFilters: object;
  } => ({
    statusFilter: [],
    showFiltersMenu: false,
    localFilters: {},
    dates: {
      start: {
        key: 'start',
        from: '',
        to: '',
        title: 'Start date range',
        range: [],
        nextFieldRef: 'endDateRangePicker',
      },
      end: {
        key: 'end',
        from: '',
        to: '',
        title: 'End date range',
        range: [],
        nextFieldRef: 'modifiedDateRangePicker',
      },
      modified: {
        key: 'modified',
        from: '',
        to: '',
        title: 'Modified date range',
        range: [],
        nextFieldRef: 'statusRef',
        nextFieldRefTrigger: 'activateMenu',
      },
    },
  }),

  watch: {
    filters: {
      handler(filters: Record<string, any>) {
        this.mergeFilters(filters);
      },
      deep: true,
    },
  },

  mounted() {
    this.mergeFilters(this.filters);
  },

  computed: {
    statuses(): { [key: string]: string } {
      if (Object.keys(this.customStatusFilterValues).length !== 0) {
        return this.customStatusFilterValues;
      }
      if (this.isInstantIO) {
        return InstantCampaignStatus;
      }
      if (this.adminReviewEnabled) {
        return AdminReviewEnabledStatuses;
      }
      return AdminReviewDisabledStatuses;
    },
    adminReviewEnabled(): boolean {
      return this.$store.state.agency.currentAgencyInfo.adminReviewEnabled;
    },
    filterStatuses(): Array<{ text: string; value: string }> {
      const statusEntity: StatusModelContract = this.$container.get(Models.Status);
      return (
        this.statuses &&
        Object.entries(this.statuses).map(([key, value]: [string, string]) => ({
          text: statusEntity.prettyStatus(value, this.adminReviewEnabled).full,
          value: key,
        }))
      );
    },
    searchKey: {
      get() {
        return this.searchTerm;
      },
      set(val: string) {
        this.$emit('update:search', val);
      },
    },
  },

  methods: {
    emitEvent(): void {
      this.$emit('update:filters', this.localFilters);
    },
    onDateRangeInput(dateRange: DateType, dates: string[]) {
      const [from = '', to = ''] = dates;
      dateRange.from = from;
      dateRange.to = to;
    },
    clearDateRange(dateRange): void {
      dateRange.from = '';
      dateRange.to = '';
      dateRange.range = [];
    },
    clearFilters(): void {
      this.statusFilter = [];
      this.clearDateRange(this.dates.start);
      this.clearDateRange(this.dates.end);
      this.clearDateRange(this.dates.modified);
      this.applyFilters();
    },
    dateFormatter(date: Date) {
      return format(new Date(date), 'MMM dd, yyyy');
    },
    computedDateFormatted(date): string {
      try {
        return date ? format(parseISO(date), 'MMM dd, yyyy') : '';
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('error', (this.isInstantIO ? 'instantIos' : 'proposals') + '.computedDateFormatted', date, err);
        return '';
      }
    },
    applyFilters(): void {
      this.showFiltersMenu = false;
      this.localFilters = {
        dates: {
          start: { ...this.dates.start },
          end: { ...this.dates.end },
          modified: { ...this.dates.modified },
        },
        status: this.statusFilter,
      };
      this.emitEvent();
    },
    getMinDateFrom(dateRangeKey): string {
      return dateRangeKey === 'end' && this.dates.start.from ? this.dates.start.from : '';
    },
    getMaxDateFrom(dateRangeKey): string {
      try {
        if (dateRangeKey === 'start' && this.dates.end.to) {
          if (this.dates.start.to) {
            return format(min([parseISO(this.dates.end.to), parseISO(this.dates.start.to)]), 'yyyy-MM-dd');
          }
          return this.dates.end.to;
        }
        return this.dates[dateRangeKey].to;
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('error', (this.isInstantIO ? 'instantIos' : 'proposals') + '.getMaxDateFrom', err);
        return '';
      }
    },
    getMinDateTo(dateRangeKey): string {
      try {
        if (dateRangeKey === 'end' && this.dates.start.from) {
          if (this.dates.end.from) {
            return format(max([parseISO(this.dates.start.from), parseISO(this.dates.end.from)]), 'yyyy-MM-dd');
          }
          return this.dates.start.from;
        }
        return this.dates[dateRangeKey].from;
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('error', (this.isInstantIO ? 'instantIos' : 'proposals') + '.getMinDateTo', err);
        return '';
      }
    },
    mergeFilters(filters: Record<string, any>) {
      this.dates = Object.fromEntries(
        Object.entries(this.dates).map(([key, value]: [string, object]) => [key, { ...value, ...filters.dates[key] }]),
      );
      this.statusFilter = filters.status;
    },
  },
});


import { UnsafeAny } from '@/shared/types';
import Vue, { PropType } from 'vue';
import TextEditor from './TextEditor.vue';
import { AlignmentType } from '@/shared/legacy/classes';

const simpleThrottle = (func, timeFrame) => {
  let lastTime = 0;
  return (...args) => {
    const now = Date.now();
    if (now - lastTime >= timeFrame) {
      func(...args);
      lastTime = now;
    }
  };
};

export default Vue.extend({
  name: 'ActiveElementEditor',
  components: {
    TextEditor,
  },
  props: {
    element: {
      type: Object,
      required: true,
    },
    baseElement: {
      type: Object,
      required: true,
    },
    slideId: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: 'images',
    },
    positionIncrement: {
      type: Number,
      default: 1,
    },
    fontSizes: {
      type: Array as PropType<number[]>,
      default: () => [],
    },
    forceHideMenu: {
      type: Boolean,
      default: false,
    },
  },
  data: (): {
    isResizing: boolean;
    isRotating: boolean;
    resizeDirection: string;
    positions: UnsafeAny;
    resizeHelpers: UnsafeAny;
    newTop: number;
    newLeft: number;
    newWidth: number;
    newHeight: number;
    newRotate: string;
    localCursor: string;
    isAlignToolMenuOpen: boolean;
  } => ({
    localCursor: '',
    positions: {
      clientX: undefined,
      clientY: undefined,
      movementX: 0,
      movementY: 0,
      centerX: undefined,
      centerY: undefined,
      width: undefined,
      height: undefined,
      clickDegrees: undefined,
    },
    resizeHelpers: {
      centerX: undefined,
      centerY: undefined,
      width: undefined,
      height: undefined,
      clientX: undefined,
      clientY: undefined,
      initRadians: undefined,
      movementX: undefined,
      movementY: undefined,
      cosFraction: undefined,
      sinFraction: undefined,
      minWidth: 1,
      minHeight: 1,
    },
    resizeDirection: '',
    newTop: null,
    newLeft: null,
    newWidth: null,
    newHeight: null,
    newRotate: null,
    isResizing: false,
    isRotating: false,
    isAlignToolMenuOpen: false,
  }),
  watch: {
    baseElement(): void {
      this.logElement();
    },
    autoFitText(val: boolean): void {
      if (val) this.resizeText();
    },
    isResizing(val: boolean): void {
      if (val && this.resizeDirection) this.localCursor = this.getCursor(this.resizeDirection);
      else this.localCursor = '';
    },
  },
  computed: {
    isTable(): boolean {
      return this.baseElement.props?.table;
    },
    cursorClass(): string {
      if (this.type === 'textItems') return '';
      return 'cursor-move';
    },
    elementZIndex(): number {
      const defaultIndex = 10;
      const style = this.element?.style;

      if (!style) return defaultIndex;

      if (typeof style?.['z-index'] === 'number') return style['z-index'];

      const styleArray = style.split(';');

      const indexProperty = styleArray.find(s => s.includes('z-index'));

      if (!indexProperty) return defaultIndex;

      const [indexValue] = indexProperty.split('z-index').filter(Boolean);
      return Number(indexValue);
    },
    autoFitText(): boolean {
      return this.baseElement.props?.autoFit || false;
    },
    dynamicLinkLabel(): string {
      return this.baseElement?.dynamicLink?.label || null;
    },
    showDragTool(): boolean {
      if (this.type === 'textItems') return true;
      return false;
    },
    isCircle(): boolean {
      return this.baseElement?.props?.circle;
    },
    isTriangle(): boolean {
      return this.baseElement?.props?.triangle;
    },
    shapeStyle(): UnsafeAny {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
      const { transform, ...shapeStyle } = this.element.style;

      const style = { ...shapeStyle, height: '100%', width: '100%', top: 0, left: 0 };

      if (this.isCircle) style['border-radius'] = '50%';

      return { style, ...(this.element.nestedElementStyle && { nestedElementStyle: this.element.nestedElementStyle }) };
    },
  },
  methods: {
    async resizeText(): Promise<void> {
      let newFontSize = this.baseElement.props.size;

      let overflow = this.checkOverflow();
      const maxSize = this.fontSizes[this.fontSizes.length - 1];

      while (!overflow && newFontSize < maxSize) {
        newFontSize = this.updatedFontSize(newFontSize, true);

        const updatedElement = {
          ...this.baseElement,
          props: { ...this.baseElement.props, size: newFontSize },
        };

        await this.handleUpdate(updatedElement, 'updateTextResize', true);
        overflow = this.checkOverflow();
      }

      const updatedElement = {
        ...this.baseElement,
        props: { ...this.baseElement.props, size: this.updatedFontSize(newFontSize) },
      };

      this.handleUpdate(updatedElement, 'updateTextResize', true);
    },
    checkOverflow(): boolean {
      const parent = this.$refs?.textEditor?.$el;
      if (!parent) return true;

      const { offsetHeight, scrollHeight, offsetWidth, scrollWidth } = parent || {};

      const isOverflow = offsetHeight < scrollHeight || offsetWidth < scrollWidth;

      return isOverflow;
    },
    updatedFontSize(currentFontSize: number, increase = false): number {
      if (currentFontSize) {
        let incIndex = this.fontSizes.indexOf(currentFontSize);

        if (incIndex === -1) {
          const closestSize = this.fontSizes.reduce((prev, curr) =>
            Math.abs(curr - currentFontSize) < Math.abs(prev - currentFontSize) ? curr : prev,
          );

          incIndex = this.fontSizes.indexOf(closestSize);
        }

        incIndex += increase ? 1 : -1;

        if (this.fontSizes[incIndex]) {
          return this.fontSizes[incIndex];
        }

        return currentFontSize;
      }
    },
    getCursor(defaultDirection: string): string {
      const rotations = ['nw', 'n', 'ne', 'e', 'se', 's', 'sw', 'w'];
      const initIndex = rotations.findIndex(r => r === defaultDirection);
      const rotate = Math.abs(this.baseElement.props?.rotate) || 0;
      const radians = (rotate * Math.PI) / 180;
      const step = Math.ceil(radians / (Math.PI * 0.25));

      return this.localCursor || `${rotations[(initIndex + step) % rotations.length]}-resize`;
    },
    logElement(): void {
      if (!this.$store.getters['output/isLocalDev']) return;
      // eslint-disable-next-line no-console
      console.log(JSON.stringify(this.baseElement, null, 2));
    },
    elementStyle(style): string {
      const {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
        opacity = 1,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
        background = 'transparent',
        // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
        border = null,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
        'border-radius': borderRadius,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
        'clip-path': clipPath,
        ...otherStyle
      } = style;
      if (this.isCircle || this.isTriangle) return otherStyle;
      else if (this.baseElement.value) return style;
      return { ...style, border: 'inherit' };
    },
    clearDefaultProps(): void {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
      const { bold, fontFamily, color, ...props } = this.baseElement?.props;
      const newElement = {
        ...this.baseElement,
        props: {
          ...props,
        },
      };

      this.handleUpdate(newElement, 'updateText');
    },
    async updateTaggedText({ html, json }): Promise<void> {
      const updatedElement = {
        ...this.baseElement,
        htmlValue: html,
        jsonValue: json,
      };

      this.handleUpdate(updatedElement, 'updateText');
    },
    editText(): void {
      if (this.type === 'textItems') {
        this.$nextTick(() => {
          if (this.$refs?.editableText) {
            this.$refs.editableText.focus();
            window.getSelection().selectAllChildren(this.$refs.editableText);
          }
        });
      }
    },
    isEditingText() {
      // Prevent issue where trying to "delete" text you're editing destroys the entire element
      return document.activeElement.classList.contains('ProseMirror');
    },
    handleKeyup(e): void {
      if (!this.isEditingText() && e?.key === 'Delete') {
        this.$emit('delete-element');
      } else if (this.autoFitText) {
        this.autoFitText && simpleThrottle(this.resizeText(), 125);
      }
    },
    getDegrees(x, y): number {
      const radians = Math.atan2(x - this.positions.centerX, y - this.positions.centerY);
      return Math.round(radians * (180 / Math.PI) * -1);
    },
    rotateMouseDown(e): void {
      if (this.isResizing) return;
      this.isRotating = true;
      e.preventDefault();
      e.stopPropagation();

      const bindings = this.$refs.editElement.getBoundingClientRect();
      this.positions.width = this.$refs.editElement.clientWidth;
      this.positions.height = this.$refs.editElement.clientHeight;
      this.positions.centerX = bindings.left + this.positions.width / 2;
      this.positions.centerY = bindings.top + this.positions.height / 2 + window.scrollY;
      const initialDegrees = this.baseElement.props?.rotate || 0;
      this.positions.clickDegrees = Number(this.getDegrees(e.pageX, e.pageY)) - Number(initialDegrees);

      document.onmousemove = this.elementRotate;
      document.onmouseup = this.closeDragElement;
    },
    elementRotate(e): void {
      const degrees = this.getDegrees(e.pageX, e.pageY) - this.positions.clickDegrees;
      const updatedElement = {
        ...this.baseElement,
        props: {
          ...this.baseElement.props,
          rotate: degrees,
        },
      };
      this.handleUpdate(updatedElement, 'updateRotation');
    },
    alignMouseDown(type: AlignmentType): void {
      // We handle this in the parent as it needs to effect other elements (ie, to choose which one to align to)
      this.$emit('beginAlignmentProcess', type);
    },
    dragMouseDown(e, fromTool): void {
      if (this.isResizing || this.isRotating || (this.type === 'textItems' && !fromTool)) return;
      e.preventDefault();
      this.positions.clientX = e.clientX;
      this.positions.clientY = e.clientY;
      document.onmousemove = this.elementMove;
      document.onmouseup = this.closeDragElement;
    },
    resizeMouseDown(e, direction: string): void {
      if (this.isRotating) return;
      e.preventDefault();
      this.resizeDirection = direction;
      this.isResizing = true;

      this.resizeHelpers.centerX = this.$refs.editElement.offsetLeft;
      this.resizeHelpers.centerY = this.$refs.editElement.offsetTop;
      this.resizeHelpers.clientX = e.clientX;
      this.resizeHelpers.clientY = e.clientY;
      this.resizeHelpers.width = this.$refs.editElement.clientWidth;
      this.resizeHelpers.height = this.$refs.editElement.clientHeight;
      const initRotate = this.baseElement.props?.rotate || 0;
      this.resizeHelpers.initRadians = (initRotate * Math.PI) / 180;
      this.resizeHelpers.sinFraction = Math.sin(this.resizeHelpers.initRadians);
      this.resizeHelpers.cosFraction = Math.cos(this.resizeHelpers.initRadians);

      document.onmousemove = this.elementResize;
      document.onmouseup = this.closeDragElement;
    },
    elementResize(e): void {
      e.preventDefault();
      this.resizeHelpers.movementX = e.clientX - this.resizeHelpers.clientX;
      this.resizeHelpers.movementY = e.clientY - this.resizeHelpers.clientY;
      let rotatedWDiff =
        this.resizeHelpers.cosFraction * this.resizeHelpers.movementX +
        this.resizeHelpers.sinFraction * this.resizeHelpers.movementY;
      let rotatedHDiff =
        this.resizeHelpers.cosFraction * this.resizeHelpers.movementY -
        this.resizeHelpers.sinFraction * this.resizeHelpers.movementX;

      this.newLeft = this.resizeHelpers.centerX;
      this.newTop = this.resizeHelpers.centerY;
      this.newWidth = this.resizeHelpers.width;
      this.newHeight = this.resizeHelpers.height;

      const currentRatio = this.resizeHelpers.width / this.resizeHelpers.height;
      let heightDiff, widthDiff;

      const yHandler = (top = false) => {
        const multiplier = top ? -1 : 1;
        this.newHeight = this.resizeHelpers.height + multiplier * rotatedHDiff;

        if (this.newHeight < this.resizeHelpers.minHeight) {
          this.newHeight = this.resizeHelpers.minHeight;
          rotatedHDiff = this.resizeHelpers.height - this.resizeHelpers.minHeight;
        }

        heightDiff = this.newHeight - this.resizeHelpers.height;
        this.newTop -= 0.5 * heightDiff * (1 - multiplier * this.resizeHelpers.cosFraction);
        this.newLeft += 0.5 * (-1 * multiplier) * heightDiff * this.resizeHelpers.sinFraction;
      };

      const xHandler = (left = false, retainAspectRatio = false) => {
        const multiplier = left ? -1 : 1;
        this.newWidth = retainAspectRatio
          ? this.newHeight * currentRatio
          : this.resizeHelpers.width + multiplier * rotatedWDiff;

        if (this.newWidth < this.resizeHelpers.minWidth) {
          this.newWidth = this.resizeHelpers.minWidth;
          rotatedWDiff = this.resizeHelpers.minWidth - this.resizeHelpers.width;
        }

        widthDiff = this.newWidth - this.resizeHelpers.width;
        this.newTop -= 0.5 * (-1 * multiplier) * widthDiff * this.resizeHelpers.sinFraction;
        this.newLeft -= 0.5 * widthDiff * (1 - multiplier * this.resizeHelpers.cosFraction);
      };

      switch (this.resizeDirection) {
        case 'nw':
          yHandler(true);
          xHandler(true, true);
          break;
        case 'n':
          yHandler(true);
          break;
        case 'ne':
          yHandler(true);
          xHandler(false, true);
          break;
        case 'e':
          xHandler();
          break;
        case 'se':
          yHandler();
          xHandler(false, true);
          break;
        case 's':
          yHandler();
          break;
        case 'sw':
          yHandler();
          xHandler(true, true);
          break;
        case 'w':
          xHandler(true);
          break;
        default:
          // eslint-disable-next-line no-console
          console.log('ActiveElementEditor', 'No resize direction set');
          break;
      }

      this.$refs.editElement.style.width = this.newWidth + 'px';
      this.$refs.editElement.style.height = this.newHeight + 'px';
      this.$refs.editElement.style.top = this.newTop + 'px';
      this.$refs.editElement.style.left = this.newLeft + 'px';
      this.autoFitText && simpleThrottle(this.resizeText(), 125);
    },
    elementMove(e): void {
      e.preventDefault();
      this.positions.movementX = this.positions.clientX - e.clientX;
      this.positions.movementY = this.positions.clientY - e.clientY;
      this.positions.clientX = e.clientX;
      this.positions.clientY = e.clientY;
      // set the element's new position:
      if (this.$refs?.editElement) {
        const xDelta = this.$refs.editElement.offsetLeft - this.positions.movementX;
        const yDelta = this.$refs.editElement.offsetTop - this.positions.movementY;
        this.newLeft = xDelta;
        this.newTop = yDelta;
        this.$refs.editElement.style.top = this.newTop + 'px';
        this.$refs.editElement.style.left = this.newLeft + 'px';
      }
    },
    closeDragElement(): void {
      setTimeout(() => this.updateElementPosition(), 100);
      this.resizeDirection = '';
      this.isResizing = false;
      this.isRotating = false;
      document.onmouseup = null;
      document.onmousemove = null;
    },
    resetPositions(): void {
      this.newTop = null;
      this.newLeft = null;
      this.newWidth = null;
      this.newHeight = null;
    },
    updateElementPosition(skipPercentConversion = false): void {
      // slide has been deleted
      if (!this.$store.getters['output/allLocalSlides'].find(s => s._id === this.slideId)) return;
      let { newTop = null, newLeft = null, newWidth = null, newHeight = null } = this;
      if (!skipPercentConversion && newTop && this.$refs?.editElement) {
        newTop = this.convertToPercent({ value: this.newTop }) + '%';
        this.$refs.editElement.style.top = newTop;
      }
      if (!skipPercentConversion && newLeft && this.$refs?.editElement) {
        newLeft = this.convertToPercent({ value: this.newLeft, isHorizontal: true }) + '%';
        this.$refs.editElement.style.left = newLeft;
      }
      if (!skipPercentConversion && newWidth && this.$refs?.editElement) {
        newWidth = this.convertToPercent({ value: this.newWidth, isHorizontal: true, isAdjustDimensions: true }) + '%';
        this.$refs.editElement.style.width = newWidth;
      }
      if (!skipPercentConversion && newHeight && this.$refs?.editElement) {
        newHeight = this.convertToPercent({ value: this.newHeight, isAdjustDimensions: true }) + '%';
        this.$refs.editElement.style.height = newHeight;
      }

      const updatedElement = {
        ...this.baseElement,
        props: {
          ...this.baseElement.props,
          ...(newLeft && { x: newLeft }),
          ...(newTop && { y: newTop }),
          ...(newWidth && { w: newWidth }),
          ...(newHeight && { h: newHeight }),
        },
      };

      if (
        Object.entries(this.baseElement.props).sort().toString() ===
        Object.entries(updatedElement.props).sort().toString()
      ) {
        return;
      }
      this.handleUpdate(updatedElement, 'updateElementPosition');
    },
    handleUpdate(updatedElement, context, preventReset = false): void {
      this.$store
        .dispatch('output/updateSlideElement', {
          type: this.type,
          slideId: this.slideId,
          value: updatedElement,
        })
        .then(() => !preventReset && this.resetPositions())
        .catch(err => {
          // eslint-disable-next-line no-console
          console.error('ActiveElementEditor', context, err);
        });
    },
    // helper method to convert pixels to percentage, seems to work, needed for how we store this info
    convertToPercent({
      isHorizontal = false,
      value,
      isAdjustDimensions = false,
      shouldRoundToIncrement = true,
    }: {
      isHorizontal?: boolean;
      value: number;
      isAdjustDimensions?: boolean;
      shouldRoundToIncrement?: boolean;
    }): number {
      const { width, height } = this.$parent.$el.getBoundingClientRect();

      const parentValue = isHorizontal ? width : height;

      const minPercent = -50;
      const maxPercent = 150;

      let newPercent = (value / parentValue) * 100;

      newPercent = newPercent <= minPercent ? minPercent : newPercent >= maxPercent ? maxPercent : newPercent;

      const rounded = shouldRoundToIncrement
        ? Math.round(newPercent / this.positionIncrement) * this.positionIncrement
        : newPercent;

      return isAdjustDimensions ? Math.max(1, rounded) : rounded;
    },
    // basically just for displaying text properly when slide isn't fullscreen
    transformHelper(val: number): number {
      const widthPercent = this.width / window.innerWidth;
      return val * widthPercent;
    },
  },
  mounted(): void {
    document.addEventListener('keyup', this.handleKeyup);
    this.logElement();
    this.autoFitText && simpleThrottle(this.resizeText(), 125);
  },
  beforeDestroy(): void {
    document.removeEventListener('keyup', this.handleKeyup);
    this.updateElementPosition();
    this.$emit('unmount');
  },
});

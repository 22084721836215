// import { GetCategoriesDocument, GetCategoriesQuery } from '../graphql/queries/get-all-categories.generated';
import { Failure } from '@/injectables/failure';
import { Err, Ok, Result } from '@sniptt/monads/build';
import { AgencyServiceContract, RestAgencyService } from '../..';
import { GetLogoDocument, GetLogoQuery, GetLogoQueryVariables } from '../graphql/query/get-logo.generated';
import { SetLogoDocument, SetLogoMutation, SetLogoMutationVariables } from '../graphql/mutation/set-logo.generated';
import { UnsafeAny } from '@/shared/legacy/classes';
import { AgencyAddress } from '@/shared/types';
import { GetAddressDocument, GetAddressQuery, GetAddressQueryVariables } from '../graphql/query/get-address.generated';
import {
  SetAddressDocument,
  SetAddressMutation,
  SetAddressMutationVariables,
} from '../graphql/mutation/set-address.generated';
import { omit } from 'lodash';
import {
  GetAgencyInfoDocument,
  GetAgencyInfoQuery,
  GetAgencyInfoQueryVariables,
} from '../graphql/query/get-agency-info.generated';
import {
  GetDescriptionDocument,
  GetDescriptionQuery,
  GetDescriptionQueryVariables,
} from '../graphql/query/get-description.generated';
import {
  SetDescriptionDocument,
  SetDescriptionMutation,
  SetDescriptionMutationVariables,
} from '../graphql/mutation/set-descritpion.generated';
import {
  GetAgencyRightsDocument,
  GetAgencyRightsQuery,
  GetAgencyRightsQueryVariables,
} from '../graphql/query/get-agencies-rights.generated';
import {
  GetContactsDocument,
  GetContactsQuery,
  GetContactsQueryVariables,
} from '../graphql/query/get-contacts.generated';
import {
  SetContactsDocument,
  SetContactsMutation,
  SetContactsMutationVariables,
} from '../graphql/mutation/set-contacts.generated';
import {
  GetAgencyDefaultLayoutDocument,
  GetAgencyDefaultLayoutQuery,
  GetAgencyDefaultLayoutQueryVariables,
} from '../graphql/query/get-agency-default-layout.generated';
import { AgencyType, UpdateMediaplannerAgencyInput } from '@/app/graphql';
import {
  SetDefaultLayoutDocument,
  SetDefaultLayoutMutation,
  SetDefaultLayoutMutationVariables,
} from '../graphql/mutation/set-default-layout.generated';
import {
  GetUsersByAgencyDocument,
  GetUsersByAgencyQuery,
  GetUsersByAgencyQueryVariables,
} from '../graphql/query/get-users-by-agency.generated';
import {
  UpdateAgencyFlagsDocument,
  UpdateAgencyFlagsMutation,
  UpdateAgencyFlagsMutationVariables,
} from '../graphql/mutation/update-agency-flags.generated';

export class AgencyService extends RestAgencyService implements AgencyServiceContract {
  async getLogo(id: string): Promise<Result<string, Failure>> {
    try {
      const { data, error } = await this._apollo.query<GetLogoQuery, GetLogoQueryVariables>({
        query: GetLogoDocument,
        variables: {
          id,
        },
      });
      if (error) {
        return Err({ message: "Can't get logo at this time" });
      }

      return Ok(data.getMediaplannerAgency.logo);
    } catch (error) {
      return Err({ message: "Can't get logo at this time" });
    }
  }

  async updateLogo(agencyId: string, logo: string): Promise<Result<string, Failure>> {
    try {
      const { data } = await this._apollo.mutate<SetLogoMutation, SetLogoMutationVariables>({
        mutation: SetLogoDocument,
        variables: {
          info: { agencyId, logo },
        },
      });

      return Ok(data.updateMediaplannerAgencyInfo.logo);
    } catch (error) {
      return Err({ message: "Can't update logo at this time" });
    }
  }

  async removeLogo(agencyId: string): Promise<Result<string, Failure>> {
    try {
      const { data } = await this._apollo.mutate<SetLogoMutation, SetLogoMutationVariables>({
        mutation: SetLogoDocument,
        variables: {
          info: { agencyId, logo: '' },
        },
      });

      return Ok(data.updateMediaplannerAgencyInfo.logo);
    } catch (error) {
      return Err({ message: "Can't remove logo at this time" });
    }
  }

  async getAddress(agencyId: string): Promise<Result<AgencyAddress, Failure>> {
    try {
      const { data, error } = await this._apollo.query<GetAddressQuery, GetAddressQueryVariables>({
        query: GetAddressDocument,
        variables: {
          id: agencyId,
        },
      });

      if (error) {
        return Err({ message: "Can't get agency address at this time" });
      }

      const address = data.getMediaplannerAgency.address || {};

      return Ok(Object.fromEntries(Object.entries(omit(address, '__typename')).filter(([, value]) => value !== null)));
    } catch (error) {
      return Err({ message: "Can't get agency address at this time" });
    }
  }

  async setAddress(agencyId: string, address: UnsafeAny): Promise<Result<AgencyAddress, Failure>> {
    try {
      const { data } = await this._apollo.mutate<SetAddressMutation, SetAddressMutationVariables>({
        mutation: SetAddressDocument,
        variables: {
          info: { agencyId, address },
        },
      });

      return Ok(data.updateMediaplannerAgencyInfo.address);
    } catch (error) {
      return Err({ message: "Can't update agency address at this time" });
    }
  }

  async getDescription(agencyId: string): Promise<Result<GetDescriptionQuery['getMediaplannerAgency'], Failure>> {
    try {
      const { data, error } = await this._apollo.query<GetDescriptionQuery, GetDescriptionQueryVariables>({
        query: GetDescriptionDocument,
        variables: {
          id: agencyId,
        },
      });

      if (error) {
        return Err({ message: "Can't get agency description at this time" });
      }

      const description = data.getMediaplannerAgency || {};

      return Ok(description);
    } catch (error) {
      return Err({ message: "Can't get agency description at this time" });
    }
  }

  async setDescription(
    agencyId: string,
    description: string,
  ): Promise<Result<SetDescriptionMutation['updateMediaplannerAgencyInfo'], Failure>> {
    try {
      const { data } = await this._apollo.mutate<SetDescriptionMutation, SetDescriptionMutationVariables>({
        mutation: SetDescriptionDocument,
        variables: {
          info: { agencyId, description },
        },
      });

      return Ok(data.updateMediaplannerAgencyInfo);
    } catch (error) {
      return Err({ message: "Can't update agency description at this time" });
    }
  }

  async getContacts(agencyId: string): Promise<Result<GetContactsQuery['getMediaplannerAgency'], Failure>> {
    try {
      const { data, error } = await this._apollo.query<GetContactsQuery, GetContactsQueryVariables>({
        query: GetContactsDocument,
        variables: {
          id: agencyId,
        },
      });

      if (error) {
        return Err({ message: "Can't get agency description at this time" });
      }

      const description = data.getMediaplannerAgency || {};

      return Ok(description);
    } catch (error) {
      return Err({ message: "Can't get agency description at this time" });
    }
  }

  async setContacts(
    agencyId: string,
    contacts: { email?: string; phone?: string },
  ): Promise<Result<SetContactsMutation['updateMediaplannerAgencyInfo'], Failure>> {
    try {
      const { data } = await this._apollo.mutate<SetContactsMutation, SetContactsMutationVariables>({
        mutation: SetContactsDocument,
        variables: {
          info: { agencyId, ...contacts },
        },
      });

      return Ok(data.updateMediaplannerAgencyInfo);
    } catch (error) {
      return Err({ message: "Can't update agency description at this time" });
    }
  }

  async getAgencyInfo(agencyId: string): Promise<Result<GetAgencyInfoQuery['getMediaplannerAgency'], Failure>> {
    try {
      const { data, error } = await this._apollo.query<GetAgencyInfoQuery, GetAgencyInfoQueryVariables>({
        query: GetAgencyInfoDocument,
        variables: {
          id: agencyId,
        },
      });

      if (error) {
        return Err({ message: "Can't get agency inf at this time" });
      }

      return Ok(data.getMediaplannerAgency);
    } catch (error) {
      return Err({ message: "Can't get agency info at this time" });
    }
  }

  async getAgencyRights(): Promise<Result<GetAgencyRightsQuery['getAvailableAgencies'], Failure>> {
    try {
      const { data, error } = await this._apollo.query<GetAgencyRightsQuery, GetAgencyRightsQueryVariables>({
        query: GetAgencyRightsDocument,
      });

      if (error) {
        return Err({ message: "Can't get agency inf at this time" });
      }

      return Ok(data.getAvailableAgencies);
    } catch (error) {
      return Err({ message: "Can't get agency info at this time" });
    }
  }
  async getDefaultLayout(
    agencyId: string,
  ): Promise<Result<GetAgencyDefaultLayoutQuery['getMediaplannerAgency'], Failure>> {
    try {
      const { data, error } = await this._apollo.query<
        GetAgencyDefaultLayoutQuery,
        GetAgencyDefaultLayoutQueryVariables
      >({
        query: GetAgencyDefaultLayoutDocument,
        variables: {
          id: agencyId,
        },
      });

      if (error) {
        return Err({ message: "Can't get agency inf at this time" });
      }

      return Ok(data.getMediaplannerAgency);
    } catch (error) {
      return Err({ message: "Can't get agency info at this time" });
    }
  }

  async setDefaultLayout(
    defaultLayout: string,
  ): Promise<Result<SetDefaultLayoutMutation['updateMediaplannerAgencyDefaultLayout'], Failure>> {
    try {
      const { data } = await this._apollo.mutate<SetDefaultLayoutMutation, SetDefaultLayoutMutationVariables>({
        mutation: SetDefaultLayoutDocument,
        variables: {
          input: { defaultLayoutId: defaultLayout },
        },
      });

      return Ok(data.updateMediaplannerAgencyDefaultLayout);
    } catch (error) {
      return Err({ message: "Can't update agency description at this time" });
    }
  }

  async getUsersByAgencyId(
    agencyId: string,
    type: AgencyType,
  ): Promise<Result<{ id: string; name: string; email: string }[], Failure>> {
    try {
      const { data } = await this._apollo.query<GetUsersByAgencyQuery, GetUsersByAgencyQueryVariables>({
        query: GetUsersByAgencyDocument,
        variables: {
          agencyId,
          type,
        },
      });

      return Ok(data.getUsersByAgencyId);
    } catch (error) {
      return Err({ message: "Can't get users at this time" });
    }
  }

  async updateAgencyFlags(
    agencyId: string,
    flags: Omit<UpdateMediaplannerAgencyInput, 'name' | 'agencyId'>,
  ): Promise<Result<UpdateAgencyFlagsMutation['updateMediaplannerAgency'], Failure>> {
    try {
      const { data } = await this._apollo.mutate<UpdateAgencyFlagsMutation, UpdateAgencyFlagsMutationVariables>({
        mutation: UpdateAgencyFlagsDocument,
        variables: {
          input: { agencyId, ...flags },
        },
      });

      return Ok(data.updateMediaplannerAgency);
    } catch (error) {
      return Err({ message: "Can't update agency permissions" });
    }
  }
}

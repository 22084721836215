import { MediaplannerProposalStatus } from '@/app/graphql';

const AdminReviewEnabledStatuses = {
  ...MediaplannerProposalStatus,
};

type MediaplannerProposalStatusKey = keyof typeof MediaplannerProposalStatus;
const validKeys: MediaplannerProposalStatusKey[] = ['Approved', 'ClosedLost', 'InProgress', 'Sold'];

type AdminReviewDisabledStatusesType = {
  [K in (typeof validKeys)[number]]?: MediaplannerProposalStatus;
};
const AdminReviewDisabledStatuses: AdminReviewDisabledStatusesType = Object.fromEntries(
  validKeys.map(key => [key, MediaplannerProposalStatus[key]]),
);

export { MediaplannerProposalStatus as Status, AdminReviewEnabledStatuses, AdminReviewDisabledStatuses };

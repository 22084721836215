import { Service } from '@/injectables/service';
import { BroadcastParserServiceContract } from '@/injectables';
import { Err, Ok, Result } from '@sniptt/monads';
import { Failure } from '../../../failure';
import { injectable } from 'inversify';
import { ProductXmlFormat, Scalars } from '@/app/graphql';
import {
  GetBroadcastInfoFromXmlDocument,
  GetBroadcastInfoFromXmlQuery,
  GetBroadcastInfoFromXmlQueryVariables,
} from '../graphql/query/broadcast-info-from-xml.generated';

@injectable()
export class BroadcastParserService extends Service implements BroadcastParserServiceContract {
  async parseXmlBroadcastFile(
    link: Scalars['URL'],
    xmlFormat: ProductXmlFormat,
  ): Promise<Result<GetBroadcastInfoFromXmlQuery['broadcastInfoFromXML'], Failure>> {
    try {
      const { data, error } = await this._apollo.query<
        GetBroadcastInfoFromXmlQuery,
        GetBroadcastInfoFromXmlQueryVariables
      >({
        query: GetBroadcastInfoFromXmlDocument,
        variables: {
          link,
          xmlFormat,
        },
      });

      if (error) {
        return Err({
          message: "Can't get broadcast info at this time.",
        });
      }
      return Ok(data.broadcastInfoFromXML);
    } catch (error) {
      return Err({
        message: `Can't get broadcast info at this time: ${error}`,
      });
    }
  }
}


import Vue from 'vue';
import { Client } from '@/shared/types';

import { TaxonomyServiceContract } from '@/injectables';
import { Services } from '@/injectables/tokens';
import { Routes } from '@/router/routes';

import ClientInfo from '@/pages/client/info.vue';
import ClientSummary from '@/pages/client/summary.vue';
import ClientInstantIOs from '@/pages/client/instant-ios.vue';
import Proposals from '@/pages/client/proposals.vue';
import ClientLogo from '@/components/Proposal/ClientLogo.vue';
import WrapperWithTooltip from '@/components/WrapperWithTooltip.vue';
import NavigationButtons from '@/components/NavigationButtons.vue';

type TabNames = 'clientSummary' | 'clientProposals' | 'clientInstantIOs' | 'clientInfo';

export default Vue.extend({
  name: 'ClientInfoDialog',

  inject: ['showSnackbar'],

  props: {
    clientId: {
      type: String,
    },
    routeTab: {
      type: String,
      default: 'summary',
    },
  },

  components: {
    ClientLogo,
    ClientInfo,
    ClientSummary,
    ClientInstantIOs,
    Proposals,
    WrapperWithTooltip,
    NavigationButtons,
  },

  data: (): {
    currentTab: TabNames;
    removeLogoLoading: boolean;
    tabMap: {
      [routeTab: string]: TabNames;
    };
    loadingActiveProducts: boolean;
    campaignDropdown: boolean;
  } => ({
    tabMap: {
      proposals: 'clientProposals',
      info: 'clientInfo',
      summary: 'clientSummary',
    },
    currentTab: 'clientSummary',
    removeLogoLoading: false,
    loadingActiveProducts: false,
    campaignDropdown: false,
  }),

  computed: {
    tabs(): { name: string; pathName: string; disabled: boolean }[] {
      return [
        {
          name: 'Summary',
          pathName: 'clientSummary',
          disabled: false,
        },
        {
          name: 'Proposals',
          pathName: 'clientProposals',
          disabled: false,
        },
        {
          name: 'Info',
          pathName: 'clientInfo',
          disabled: false,
        },
      ];
    },
    client(): Client {
      return this.$store.getters['client/activeClient'];
    },
    clientName(): string {
      return this.client?.name ?? '';
    },
    friendlyCategory(): string {
      if (!this.client || !this.client.category) return '';
      const taxonomyService: TaxonomyServiceContract = this.$container.get(Services.Taxonomy);
      return taxonomyService.fullTitle(this.client.category.name);
    },
    newProposal(): boolean {
      return this.$route.matched.some(record => record.path.includes('new-proposal'));
    },
  },

  async mounted(): Promise<void> {
    if (this.tabMap[this.routeTab]) {
      this.updateTab(this.tabMap[this.routeTab]);
    }
    await this.getClientById();
  },

  watch: {
    clientId(val) {
      if (val && this.tabMap[this.routeTab]) {
        this.updateTab(this.tabMap[this.routeTab]);
      }
    },
  },

  methods: {
    updateTab(value) {
      this.$emit('update-route', value);
      this.currentTab = value;
    },
    closeDialog(): void {
      this.$emit('close-client-info-dialog');
    },
    navigateBack(): void {
      if (window.history.length > 2) {
        this.$router.go(-1);
      } else {
        this.$router.push({ name: Routes.Home });
      }
    },
    async onRemoveLogo() {
      this.removeLogoLoading = true;
      const success = await this.$store.dispatch('client/removeClientLogo', this.clientId);
      if (!success) {
        this.removeLogoLoading = false;
        return this.showSnackbar('Unable to remove logo at this time');
      }
      this.showSnackbar('Logo has been removed', 'success');
      this.removeLogoLoading = false;
      this.$emit('refresh');
    },
    async getClientById() {
      this.loadingActiveProducts = true;
      await Promise.all([
        this.$store
          .dispatch('client/getClientById', this.clientId)
          .catch(err => this.$log('error', 'clientInfoDialog/getClientById/getClientById', err)),
        this.$store
          .dispatch('client/getPurchaseHistory', { id: this.clientId })
          .catch(err => this.$log('error', 'clientInfoDialog/getClientById/getPurchaseHistory', err)),
        this.$store
          .dispatch('proposal/getProposalsSummaryByActiveClient', { clientId: this.clientId })
          .catch(err => this.$log('error', 'clientInfoDialog/getClientById/getProposalsSummaryByActiveClient', err)),
      ]);
      this.loadingActiveProducts = false;
    },
  },
});

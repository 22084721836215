
import Vue from 'vue';

interface Data {
  windowWidth: number;
}

export default Vue.extend({
  name: 'WindowResizeProvider',

  data: (): Data => ({
    windowWidth: window.innerWidth,
  }),

  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },
  },

  mounted() {
    this.onResize();
    window.addEventListener('resize', this.onResize);
  },

  beforeDestroy(): void {
    window.removeEventListener('resize', this.onResize);
  },
});


import Vue from 'vue';
import { DataTableHeader } from 'vuetify';
import { AdProductUser } from '@/shared/types';
import { formatDistanceToNow } from 'date-fns';

import { DateModelContract, StatusModelContract } from '@/injectables';
import { Models, Services } from '@/injectables/tokens';

import InstantIoActionsMenu from '@/features/instant-io/ui/instant-io-actions.vue';
import WrapperWithTooltip from '@/components/WrapperWithTooltip.vue';
import ReassignCampaignModal from '@/features/reassign-campaign-modal/ReassignCampaignModal.vue';
import ProposalStatusSelector from '@/components/ProposalStatusSelector.vue';

export default Vue.extend({
  name: 'InstantIoTable',

  inject: ['$confirm', 'showSnackbar'],

  useInjectable: [Models.NewProposal, Models.Status, Models.Date, Services.Tracker, Services.Calendar, Models.Status],

  components: {
    InstantIoActionsMenu,
    WrapperWithTooltip,
    ReassignCampaignModal,
    ProposalStatusSelector,
  },

  props: {
    data: {
      type: Array,
      required: true,
    },
    agencyInfo: {
      type: Object,
      required: true,
    },
    isAgencyAdmin: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    byClient: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    search: {
      type: String,
    },
    total: {
      type: Number,
      default: 0,
    },
    options: {
      type: Object,
    },
  },
  data: (): {
    defaultHeaders: DataTableHeader[];
    reassignCampaignModalProps: {
      dialog: boolean;
      campaignId: string;
      campaignName?: string;
      createdBy;
    };
  } => ({
    defaultHeaders: [
      { text: '', width: '50px', align: 'center', value: 'client.logo', sortable: false, filterable: false },
      { text: 'Client', width: '200px', align: 'start', value: 'client.name', filterable: true },
      { text: 'Campaign Name', width: '200px', value: 'name', filterable: true },
      { text: 'Budget', width: '100px', value: 'budget', filterable: false, sortable: true },
      { text: 'Start Date', width: '125px', value: 'startDate', filterable: false },
      { text: 'End Date', width: '125px', value: 'endDate', filterable: false },
      { text: 'Last updated', width: '180px', value: 'updatedAt', filterable: false },
      { text: 'User', value: 'createdBy.email', width: '175px', filterable: false },
      { text: 'Status', width: '120px', value: 'status', sortable: true, filterable: false },
      { text: '', align: 'end', value: 'actions', width: '65px', sortable: false, filterable: false },
    ],
    reassignCampaignModalProps: {
      dialog: false,
      campaignId: '',
      campaignName: '',
      createdBy: null,
    },
  }),

  computed: {
    headers(): DataTableHeader[] {
      const headerFilter = item => {
        const byClient = this.$props.byClient;

        return (
          (this.isAgencyAdmin && item.value === 'createdBy.email') ||
          (!byClient && item.value !== 'createdBy.email') ||
          (item.value !== 'client.name' && item.value !== 'createdBy.email')
        );
      };
      return this.defaultHeaders.filter(headerFilter);
    },
    hasCreatedByColumn() {
      return this.headers.some(item => item && item.value !== 'createdBy.email');
    },
    user(): AdProductUser {
      return this.$store.state.auth.user;
    },
    instantIOs() {
      return this.data.map(p => {
        return {
          ...p,
          updatedAgo: formatDistanceToNow(new Date(p.updatedAt), { addSuffix: true }),
        };
      });
    },
  },

  methods: {
    prettyStatus(status: string) {
      const statusEntity: StatusModelContract = this.statusEntity;
      return statusEntity.prettyStatus(status, this.agencyInfo.adminReviewEnabled).short;
    },
    openReassignDialog({
      id,
      campaignName,
      createdBy,
    }: {
      id: string | number;
      campaignName: string;
      createdBy;
    }): void {
      this.reassignCampaignModalProps.dialog = true;
      this.reassignCampaignModalProps.campaignId = id;
      this.reassignCampaignModalProps.campaignName = campaignName;
      this.reassignCampaignModalProps.createdBy = createdBy;
    },
    getCampaignBudget(campaign): number {
      const budget = campaign?.products?.reduce((acc, product) => {
        return acc + product?.budget;
      }, 0);

      return budget || 0;
    },
    openEditInstantIoModal({ id }): void {
      this.$emit('open-edit-instant-io-modal', id);
    },
    dateToString(date: string): string {
      if (!date) return '';
      return (this.dateEntity as DateModelContract).dateToFormatDateString(date);
    },
    openClientInfoDialog(id: string): void {
      this.$emit('open-client-info', id);
    },
  },
});

import Vue from 'vue';
import MButton from './MButton.vue';
import WButton from './WButton.vue';
import OldButton from './OldButton.vue';
import WSelect from './WSelect.vue';
import WAutocomplete from './WAutocomplete.vue';
import MultiSelectButton from './MultiSelectButton.vue';

Vue.component('m-button', MButton);
Vue.component('w-button', WButton);
Vue.component('w-select', WSelect);
Vue.component('w-autocomplete', WAutocomplete);
Vue.component('p-button', OldButton);
Vue.component('multi-select-button', MultiSelectButton);

<template>
  <div>
    <div v-if="!isEditing" :class="textClasses" class="cursor-pointer" @click="edit">
      {{ text }}
    </div>
    <v-text-field
      v-else
      v-model="editableText"
      autofocus
      hide-details
      single-line
      class="text-edit"
      append-icon="mdi-check"
      @click:append="save"
      @blur="cancel"
    />
  </div>
</template>

<script>
export default {
  name: 'EditableText',
  props: {
    text: {
      type: String,
      required: true,
    },
    textClasses: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      editableText: this.text,
      isEditing: false,
    };
  },
  watch: {
    text(newVal) {
      this.editableText = newVal;
    },
  },
  methods: {
    edit() {
      if (this.disabled) return;

      this.isEditing = true;
      this.editableText = this.text;
    },
    save() {
      this.$emit('update:text', this.editableText);
      this.isEditing = false;
    },
    cancel() {
      this.editableText = this.text;
      this.isEditing = false;
    },
  },
};
</script>

<style scoped>
.text-edit input {
  margin-right: 10px;
}
</style>

import Vue from 'vue';
import commonPkg from '@/shared/legacy/isomorphic';

const { datesHelpers } = commonPkg;

export const filter = (value: string | null): string => {
  if (!value) {
    return '-';
  }

  return datesHelpers.getRemainingTime(value.slice(0, 10));
};

Vue.filter('formatTimeRemaining', filter);

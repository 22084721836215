import 'reflect-metadata';

import { ProposalStatusChartData } from '@/shared/types';
import { injectable } from 'inversify';
import { Err, Ok, Result } from '@sniptt/monads';

import { ManagementServiceContract } from '@/injectables';
import { Failure } from '@/injectables/failure';

import { Service } from '@/injectables/service';

@injectable()
export class ManagementService extends Service implements ManagementServiceContract {
  async fetchProposalStatusChartData(start: string, end: string): Promise<Result<ProposalStatusChartData, Failure>> {
    try {
      const url = '/api/management/proposalStatusData';

      const { data } = await this._axios.post(url, { start, end });

      return Ok(data);
    } catch (error) {
      return Err(error);
    }
  }

  async fetchUserAgencyActivityChartData(
    start: string,
    end: string,
    agencyId?: string,
    isMonthly?: boolean,
  ): Promise<Result<ProposalStatusChartData, Failure>> {
    try {
      const url = '/api/management/userAgencyActivityData';

      const { data } = await this._axios.post(url, { start, end, isMonthly, ...(agencyId && { agencyId }) });

      return Ok(data);
    } catch (error) {
      return Err(error);
    }
  }
}

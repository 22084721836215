import mixpanel from 'mixpanel-browser';
import { EnvServiceContract } from '@/injectables';
import { Nullable } from '@/shared/types';

/**
 * Creates the instance of mixpanel.
 *
 * @param EnvService    Service to access some env related data.
 */
export const createMixpanel = (EnvService: EnvServiceContract): Nullable<mixpanel.Mixpanel> => {
  if (!EnvService.isMixpanelTracking()) return null;

  const { VUE_APP_MIXPANEL_ID: mixPanelId } = process.env;

  if (mixPanelId) {
    mixpanel.init(mixPanelId);
    return mixpanel;
  }

  return null;
};

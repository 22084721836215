/* eslint-disable no-unused-vars */
export enum Routes {
  // Common
  Home = 'home',
  Signin = 'signin',
  Login = 'login',
  Error = 'error',
  AHLogin = 'ahlogin',

  // Client
  Clients = 'clients',
  Client = 'client',
  ClientSummary = 'clientSummary',
  ClientInfo = 'clientInfo',
  ClientProposals = 'clientProposals',
  ClientProposalOutput = 'proposalOutput',
  MyClients = 'myClients',

  // Proposal
  Proposals = 'proposals',
  Proposal = 'proposal',
  ProposalMarket = 'proposalMarket',
  ProposalSolutions = 'proposalSolutions',
  ProposalSummary = 'proposalSummary',
  ProposalFinalize = 'proposalFinalize',

  // Instant Campaign
  InstantCampaigns = 'instantCampaigns',

  // Product
  Products = 'products',

  // Output
  ManageLayouts = 'manageLayouts',
  ManageProductSlides = 'manageProductSlides',
  ManagePackageSlides = 'managePackageSlides',
  ManageLibrarySlide = 'manageLibrarySlide',
  CreateLibrarySlide = 'createLibrarySlide',
  ManageTemplateSlide = 'manageTemplateSlide',
  CreateTemplateSlide = 'createTemplateSlide',
  SharedOutput = 'sharedOutput',
  ExportOutput = 'exportOutput',
  ShortOutput = 'shortOutput',

  // Contract
  GetContract = 'getContract',

  // Ott
  SharedOtt = 'sharedOtt',

  ResetPassword = 'resetPassword',

  // standalone
  AvailsCalculator = 'availsCalculator',
  KeywordPlanner = 'keywordPlanner',

  // Campaign
  Campaign = 'campaign',

  // Agency Hub
  AgencyDashboard = 'agency-dashboard',
}

export const RoutesWithoutAlert = [
  Routes.ManageLayouts,
  Routes.ManageProductSlides,
  Routes.ManagePackageSlides,
  Routes.ManageLibrarySlide,
  Routes.CreateLibrarySlide,
  Routes.ManageTemplateSlide,
  Routes.CreateTemplateSlide,

  Routes.SharedOutput,
  Routes.ExportOutput,
  Routes.ShortOutput,
  Routes.SharedOtt,

  Routes.ClientProposalOutput,
];

import * as Types from '../_types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { TargetingSegmentFragmentDoc } from './campaign.fragment.generated';
export type ProductFragmentFragment = {
  id: string;
  dsId?: string | null;
  name: string;
  index?: number | null;
  fulfillmentMethod: Types.ProductConfigFullfillmentMethod;
  isEditable?: boolean | null;
  isHidden?: boolean | null;
  isLocked?: boolean | null;
  minDays?: number | null;
  minSpend?: number | null;
  budget: number;
  omsAccountNumber?: string | null;
  omsName?: string | null;
  category: Types.ProductConfigCategory;
  description?: Record<string, any> | null;
  recommendedBudget?: number | null;
  flightType?: string | null;
  type?: Types.ProductConfigType | null;
  IORecipientEmail?: string | null;
  rateRangeList?: Record<string, any> | null;
  noXmlFlag?: boolean | null;
  keyMetric?: Types.KeyMetric | null;
  keyMetricMultiplier?: number | null;
  productConfigId: string;
  isMonthly?: boolean | null;
  calcMinSpend?: number | null;
  keywords?: {
    list?: Array<{
      keyword?: string | null;
      searchVolume?: number | null;
      competition?: number | null;
      competitionLevel?: string | null;
      averageCpc?: number | null;
      recommendLevel?: number | null;
      isSelected?: boolean | null;
      isCustom?: boolean | null;
    } | null> | null;
    summary?: {
      numKeywords?: number | null;
      totalCpc?: number | null;
      totalClicks?: number | null;
      lowerCpc?: number | null;
      upperCpc?: number | null;
      lowerClicks?: number | null;
      upperClicks?: number | null;
    } | null;
  } | null;
  broadcastInfo?: {
    link?: string | null;
    broadcast?: Array<{
      days?: Array<Types.Weekdays | null> | null;
      startTime: string;
      endTime: string;
      spots: number;
      spend: number;
      rating: number;
      programName: string;
      station: string;
    } | null> | null;
  } | null;
  geoSelections?: {
    cityList?: Array<{ key?: string | null; name?: string | null }> | null;
    countyList?: Array<{ key?: string | null; name?: string | null }> | null;
    dmaList?: Array<{ key?: string | null; name?: string | null }> | null;
    stateList?: Array<{ key?: string | null; name?: string | null }> | null;
  } | null;
  questionnaire?: Array<{ id: string; index: number; answer: string; questionnaireId: string } | null> | null;
  flights?: Array<{
    id: string;
    index?: number | null;
    budget?: number | null;
    startDate?: string | null;
    endDate?: string | null;
    rate?: number | null;
    rateType?: Types.RateType | null;
    selectedPlatform?: string | null;
    isLocked?: boolean | null;
    platformOption?: Array<string | null> | null;
    targetingOption?: string | null;
    flightCategory?: Types.FlightCategory | null;
    market?: Record<string, any> | null;
    mapType?: Types.MapType | null;
    contextualKeywords?: Array<string | null> | null;
    advancedOptions?: {
      autoRenewList?: Array<string | null> | null;
      abTestFlag?: boolean | null;
      darkPeriodList?: Array<{ index: number; startDate: string; endDate: string } | null> | null;
    } | null;
    audienceSegments?: Array<{
      id: string;
      name: string;
      externalId: string;
      description?: string | null;
      segmentCategory: Types.SegmentCategory;
      segmentSubCategory?: Types.SegmentSubCategory | null;
      isMain: boolean;
    }> | null;
  } | null> | null;
  flightConfigs?: Array<{
    id: string;
    markupMax?: number | null;
    markupMin?: number | null;
    rateType?: Types.RateType | null;
    rate?: number | null;
    adFormatList?: Record<string, any> | null;
    targetingOption?: string | null;
    flightCategory?: Types.FlightCategory | null;
    platformOption?: Array<string | null> | null;
    mapType?: Types.MapType | null;
    advancedOptions?: {
      autoRenewList?: Array<string | null> | null;
      abTestFlag?: boolean | null;
      darkPeriodList?: Array<{ index: number; startDate: string; endDate: string } | null> | null;
    } | null;
  } | null> | null;
};

export type ProductContractsFragment = { contract?: { id: string; url: string } | null };

export const ProductFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MediaplannerProduct' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dsId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'index' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fulfillmentMethod' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isEditable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isHidden' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minDays' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minSpend' } },
          { kind: 'Field', name: { kind: 'Name', value: 'budget' } },
          { kind: 'Field', name: { kind: 'Name', value: 'omsAccountNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'omsName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recommendedBudget' } },
          { kind: 'Field', name: { kind: 'Name', value: 'flightType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'IORecipientEmail' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rateRangeList' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'keywords' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'list' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'keyword' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'searchVolume' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'competition' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'competitionLevel' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'averageCpc' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'recommendLevel' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isSelected' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isCustom' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'summary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'numKeywords' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalCpc' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalClicks' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lowerCpc' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'upperCpc' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lowerClicks' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'upperClicks' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'broadcastInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'broadcast' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'days' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'startTime' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'endTime' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'spots' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'spend' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rating' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'programName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'station' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'link' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'noXmlFlag' } },
          { kind: 'Field', name: { kind: 'Name', value: 'keyMetric' } },
          { kind: 'Field', name: { kind: 'Name', value: 'keyMetricMultiplier' } },
          { kind: 'Field', name: { kind: 'Name', value: 'productConfigId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isMonthly' } },
          { kind: 'Field', name: { kind: 'Name', value: 'calcMinSpend' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'geoSelections' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cityList' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'countyList' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'dmaList' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'stateList' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'questionnaire' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'index' } },
                { kind: 'Field', name: { kind: 'Name', value: 'answer' } },
                { kind: 'Field', name: { kind: 'Name', value: 'questionnaireId' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'flights' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'index' } },
                { kind: 'Field', name: { kind: 'Name', value: 'budget' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rateType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'selectedPlatform' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
                { kind: 'Field', name: { kind: 'Name', value: 'platformOption' } },
                { kind: 'Field', name: { kind: 'Name', value: 'targetingOption' } },
                { kind: 'Field', name: { kind: 'Name', value: 'flightCategory' } },
                { kind: 'Field', name: { kind: 'Name', value: 'market' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mapType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contextualKeywords' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'advancedOptions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'autoRenewList' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'abTestFlag' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'darkPeriodList' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'index' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'audienceSegments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'TargetingSegment' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'flightConfigs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'markupMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'markupMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rateType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'advancedOptions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'autoRenewList' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'abTestFlag' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'darkPeriodList' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'index' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'adFormatList' } },
                { kind: 'Field', name: { kind: 'Name', value: 'targetingOption' } },
                { kind: 'Field', name: { kind: 'Name', value: 'flightCategory' } },
                { kind: 'Field', name: { kind: 'Name', value: 'platformOption' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mapType' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProductFragmentFragment, unknown>;
export const ProductContractsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductContracts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MediaplannerProduct' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contract' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProductContractsFragment, unknown>;

import * as Types from '../../../../../app/graphql/_types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type PerformanceChartQueryVariables = Types.Exact<{
  start: Types.Scalars['Date'];
  end: Types.Scalars['Date'];
}>;

export type PerformanceChartQuery = {
  SummaryList: Array<{ month: string; totalCount: number; signedCount: number; signedCost: number; totalCost: number }>;
};

export const PerformanceChartDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'performanceChart' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'start' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'end' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'SummaryList' },
            name: { kind: 'Name', value: 'performanceComponent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'start' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'end' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'month' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'signedCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'signedCost' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCost' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PerformanceChartQuery, PerformanceChartQueryVariables>;

import { injectable } from 'inversify';
import { Err, Ok, Result } from '@sniptt/monads';
import { LogoWithSize } from '@/shared/types';

import { ImageObject, UploadServiceContract } from '@/injectables';
import { Failure } from '@/injectables/failure';

const mockLogoUrl =
  'https://images.unsplash.com/photo-1658242356534-9935f4e9aaed?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80';

@injectable()
export class LocalUploadService implements UploadServiceContract {
  async uploadImage(imageFile: File): Promise<Result<ImageObject, Failure>> {
    const rand = Math.floor(Math.random() * 10) + 1;
    const err = rand < 5;
    if (err) {
      return Err({
        message: `Error while local uploading image: ${imageFile.name}`,
      });
    }
    return Ok({
      img: mockLogoUrl,
      imgWidth: 200,
      imgHeight: 200,
    });
  }

  async uploadLogo(logo: File): Promise<Result<LogoWithSize, Failure>> {
    const rand = Math.floor(Math.random() * 10) + 1;
    const err = rand < 5;
    if (err) {
      return Err({
        message: `Error while local uploading logo: ${logo.name}`,
      });
    }
    return Ok({
      avtr: mockLogoUrl,
      logo: mockLogoUrl,
      logoWidth: 200,
      logoHeight: 200,
    });
  }

  async getImageInfo(image: string): Promise<Result<{ width: number; height: number }, Failure>> {
    const img = new Image();
    img.src = image;
    return new Promise(resolve => {
      img.onerror = () => {
        resolve(Err({ message: 'Link is not image' }));
      };
      img.onload = () => {
        const { width, height } = img;
        resolve(Ok({ width, height }));
      };
    });
  }
  async uploadXML(file: File): Promise<Result<string, Failure>> {
    return Ok(
      'https://imgresize-sbgds-dev.s3.amazonaws.com/Top Tint of Savannah Gen. Campaign (1)_cvv3NZb5pJ9Y92tDxCJAY9_cAnaT1v7HxYrdMkUTDShHh.xml',
    );
  }
}

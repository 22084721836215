import 'reflect-metadata';

import { Service } from '@/injectables/service';
import { Err, Ok, Result } from '@sniptt/monads';
import { Failure } from '@/injectables/failure';

import { injectable } from 'inversify';

@injectable()
export class PackageService extends Service {
  async removePackageSettings(agencyId: string, packageId: string): Promise<Result<boolean, Failure>> {
    try {
      await this._axios.delete(
        `/api/removePackageSettings?agencyPropertyId=${agencyId}&packagePropertyId=${packageId}`,
      );
      return Ok(true);
    } catch (error) {
      return Err({
        message: error,
      });
    }
  }
}

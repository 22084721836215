import { EnvServiceContract } from '@/injectables';
import { Services } from '@/injectables/tokens';
import { Container } from 'inversify';

export const logout = async (container: Container, logoutFn: () => Promise<void>) => {
  const envService = container.get<EnvServiceContract>(Services.Env);
  await logoutFn();

  if (!envService.isWhiteLabeledEnv(window.location.hostname)) {
    window.location.href = '/login';
  }
};

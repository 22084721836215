
import Vue from 'vue';
import { DataTableHeader } from 'vuetify';
import { NewProposalRoute, AdProductUser } from '@/shared/types';

import {
  Status,
  UXUtilsServiceContract,
  StatusModelContract,
  CalendarServiceContract,
  LocalStorageServiceContract,
} from '@/injectables';
import { Models, Services } from '@/injectables/tokens';
import { Routes } from '@/router/routes';

import WrapperWithTooltip from '@/components/WrapperWithTooltip.vue';
import { Placeholder } from '@/shared/ui';
import { debounce } from 'lodash';

export default Vue.extend({
  name: 'ClientRecentProposals',

  components: { WrapperWithTooltip, Placeholder },

  inject: ['$confirm'],

  useInjectable: [Services.Calendar, Services.LocalStorage, Services.UX, Models.Status],

  props: {
    data: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    agencyInfo: {
      type: Object,
      required: true,
    },
    clientId: {
      type: String,
      required: true,
    },
    isAgencyAdmin: {
      type: Boolean,
      default: false,
    },
  },

  data: (): {
    headers: DataTableHeader[];
    limit: number;
    options: {
      sortBy: string;
      sortDesc: boolean;
    };
  } => ({
    headers: [
      { text: 'Proposal Name', width: '26%', value: 'name', sortable: true },
      { text: 'Start Date', width: '20%', value: 'startDate', sortable: true },
      { text: 'End Date', width: '20%', value: 'endDate', sortable: true },
      { text: 'Budget', width: '20%', value: 'budget', sortable: true },
      { text: 'Status', width: '150px', value: 'status', sortable: true },
    ],
    limit: 15,
    options: {
      sortBy: 'updatedAgo',
      sortDesc: true,
    },
  }),

  computed: {
    user(): AdProductUser {
      return this.$store.state.auth.user;
    },
    height(): string {
      return '288';
    },
    adminReviewEnabled(): boolean {
      return this.agencyInfo.adminReviewEnabled;
    },
  },

  methods: {
    async saveStatus({ proposalId, newStatus }: { proposalId: string; newStatus: string }): Promise<void> {
      this.$store.dispatch('proposal/triggerRefetchProposals');

      try {
        await this.$store.dispatch('proposal/updateProposalStatus', { proposalPropertyId: proposalId, newStatus });
      } catch (err) {
        this.$log('error', 'ClientRecentProposals/saveStatus', err);
        this.$store.dispatch('showSnackbar', { content: 'change status failed', color: 'error' });
      }
    },
    async updateSort(sortBy) {
      if (this.options.sortBy === sortBy) return;
      this.options.sortBy = sortBy;

      await this.getRecentProposals();
    },
    async updateSortType(desc) {
      if (this.options.sortDesc === desc) return;
      this.options.sortDesc = desc;

      await this.getRecentProposals();
    },
    async navigateToNewProposal(proposal): Promise<void> {
      const redirectToStep = (link: Routes): void => {
        this.$router.push({ name: link, params: { id: proposal.client.id, proposalId: proposal.id || '' } });
      };
      const visitedRoute = this.getVisitedRoute(proposal.id);

      if ([Status.Sold, Status.ClosedLost].includes(proposal.status)) {
        redirectToStep(Routes.ProposalSummary);
      } else if (
        this.agencyInfo.adminReviewEnabled &&
        proposal.status === Status.SubmittedForReview &&
        this.isAgencyAdmin
      ) {
        const { confirmed } = await this.$confirm.show({
          title: 'Begin Reviewing Proposal?',
          body: "This will change the proposal status to 'Under Review'",
          confirmText: 'Yes',
          cancelText: 'Cancel',
        });

        if (!confirmed) {
          redirectToStep(Routes.ProposalSummary);
          return;
        }

        await this.saveStatus({
          proposalId: proposal.id,
          newStatus: Status.UnderReview,
        });

        redirectToStep(Routes.ProposalSolutions);
      } else if (visitedRoute) {
        redirectToStep(visitedRoute.name);
        return;
      } else {
        redirectToStep(Routes.ProposalMarket);
      }
    },
    getVisitedRoute(proposalId: string): NewProposalRoute {
      const storageService: LocalStorageServiceContract = this.$container.get(Services.LocalStorage);

      const { email } = this.user;

      return (this.uxUtilsService as UXUtilsServiceContract).getProposalStepForUser({
        email,
        proposalId,
        storageService,
      });
    },
    getDateWithTimezoneOffset(date: Date | string): Date | string | null {
      if (!date) return null;

      return (
        (this.calendarService as CalendarServiceContract).dateWithTimezoneOffset(new Date(date)).toISOString() || null
      );
    },
    prettyStatus(status: string) {
      const statusEntity: StatusModelContract = this.statusEntity;
      return statusEntity.prettyStatus(status, this.adminReviewEnabled).short;
    },
    getRecentProposals: debounce(function (
      { limit = this.limit, sortBy = this.options.sortBy, desc = this.options.sortDesc } = this.options,
    ) {
      this.$store.dispatch('proposal/getProposalsByActiveClient', {
        clientId: this.clientId,
        limit,
        sortBy,
        desc,
      });
    },
    300),
  },

  mounted() {
    this.getRecentProposals();
  },
});


import Vue from 'vue';

export default Vue.extend({
  name: 'Alert',

  props: {
    show: {
      type: Boolean,
      required: true,
    },
    dismissible: {
      type: Boolean,
      required: true,
    },
    type: {
      type: Boolean,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
  },
});

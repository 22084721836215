import * as Types from '../_types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type ColorSchemeFragment = {
  textHeading?: string | null;
  textSubheading?: string | null;
  textBody?: string | null;
  cardTextHeading?: string | null;
  cardTextSubheading?: string | null;
  cardTextBody?: string | null;
  accent?: string | null;
  card?: string | null;
  border?: string | null;
  mapLayer?: string | null;
  primary?: string | null;
};

export const ColorSchemeFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ColorScheme' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'LayoutColorScheme' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'textHeading' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textSubheading' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textBody' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cardTextHeading' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cardTextSubheading' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cardTextBody' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'card' } },
          { kind: 'Field', name: { kind: 'Name', value: 'border' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mapLayer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primary' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ColorSchemeFragment, unknown>;

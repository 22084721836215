export interface EnvServiceContract {
  isDevelopment(): boolean;
  isStaging(): boolean;
  isProduction(): boolean;
  isLocalDev(): boolean;
  isMixpanelTracking(): boolean;
  getEnvironment(): string;

  /**
   * Returns variable value from the process.env or throw error.
   *
   * @param variable    The variable name.
   * @param converter   The function for converting var value into desired type.
   */
  getOrThrow<T>(variable: string, converter: (v: string) => T): T;

  /**
   * return current env name
   */
  getEnvironmentName(short?: boolean): Environment;

  isWhiteLabeledEnv(url: string): boolean;
}

export enum Environment {
  // eslint-disable-next-line no-unused-vars
  Development = 'dev',
  // eslint-disable-next-line no-unused-vars
  Production = 'prod',
  // eslint-disable-next-line no-unused-vars
  Staging = 'staging',
  // eslint-disable-next-line no-unused-vars
  ShortStaging = 'stg',
  // eslint-disable-next-line no-unused-vars
  Local = 'local',
}

import * as Types from '../../../../../app/graphql/_types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type GetMediaplannerInstantCampaignsQueryVariables = Types.Exact<{
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']>;
  sortDirection?: Types.InputMaybe<Types.SortDirection>;
  orderBy?: Types.InputMaybe<Types.InstantCampaignOrderBy>;
  searchKey?: Types.InputMaybe<Types.Scalars['String']>;
  endDateRange?: Types.InputMaybe<Types.MediaplannerDateRangeInput>;
  startDateRange?: Types.InputMaybe<Types.MediaplannerDateRangeInput>;
  updateDateRange?: Types.InputMaybe<Types.MediaplannerDateRangeInput>;
  statuses?: Types.InputMaybe<
    Array<Types.InputMaybe<Types.InstantCampaignStatus>> | Types.InputMaybe<Types.InstantCampaignStatus>
  >;
  clientId?: Types.InputMaybe<Types.Scalars['UUID']>;
}>;

export type GetMediaplannerInstantCampaignsQuery = {
  getMediaplannerInstantCampaigns?: {
    total: number;
    limit: number;
    list?: Array<{
      id: string;
      name?: string | null;
      status?: Types.InstantCampaignStatus | null;
      startDate?: string | null;
      endDate?: string | null;
      updatedAt?: string | null;
      createdAt?: string | null;
      client?: { id: string; name: string; logo?: string | null } | null;
      createdBy?: { email: string } | null;
      products?: Array<{ budget: number }> | null;
    } | null> | null;
  } | null;
};

export const GetMediaplannerInstantCampaignsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getMediaplannerInstantCampaigns' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortDirection' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'SortDirection' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'InstantCampaignOrderBy' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'searchKey' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'endDateRange' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'MediaplannerDateRangeInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'startDateRange' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'MediaplannerDateRangeInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'updateDateRange' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'MediaplannerDateRangeInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'statuses' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'InstantCampaignStatus' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'clientId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getMediaplannerInstantCampaigns' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortDirection' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortDirection' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'searchKey' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'searchKey' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDateRange' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'endDateRange' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'modifiedDateRange' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'updateDateRange' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDateRange' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'startDateRange' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'statuses' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'statuses' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'clientId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'list' },
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'client' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdBy' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'email' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'products' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'budget' } }],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limit' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetMediaplannerInstantCampaignsQuery, GetMediaplannerInstantCampaignsQueryVariables>;

import { Service } from '@/injectables/service';
import { CampaignServiceContract } from '../contracts';
import { GetShortIdDocument, GetShortIdQuery } from '../graphql/queries/getCampaignByShortId.generated';
import { Err, Ok, Result } from '@sniptt/monads/build';
import { Failure } from '@/injectables/failure';

export class CampaignService extends Service implements CampaignServiceContract {
  async getCampaignByShortId(id: number): Promise<Result<GetShortIdQuery['getShortId'], Failure>> {
    try {
      const { data, error } = await this._apollo.query<GetShortIdQuery>({
        query: GetShortIdDocument,
        variables: { id },
      });

      if (error) {
        return Err({
          message: "Can't get campaign at this time.",
        });
      }

      return Ok(data.getShortId);
    } catch (error) {
      return Err({
        message: `Can't get campaign at this time: ${error}`,
      });
    }
  }
}

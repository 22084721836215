
import Vue from 'vue';
import { DataTableHeader } from 'vuetify';
import { AdProductUser, NewProposalRoute, Nullable, Proposal } from '@/shared/types';
import { formatDistanceToNow } from 'date-fns';

import {
  DateModelContract,
  LocalStorageServiceContract,
  NewProposalModelContract,
  ProposalServiceContract,
  Status,
  StatusModelContract,
  TrackerContract,
  UXUtilsServiceContract,
} from '@/injectables';
import { Models, Services } from '@/injectables/tokens';
import { Routes } from '@/router/routes';

import ProposalActionsMenu from '@/components/Proposals/ProposalActionsMenu.vue';
import WrapperWithTooltip from '@/components/WrapperWithTooltip.vue';
import ProposalStatusSelector from '@/components/ProposalStatusSelector.vue';
import ReassignCampaignModal from '../../features/reassign-campaign-modal/ReassignCampaignModal.vue';

export default Vue.extend({
  name: 'ProposalsTable',

  inject: ['$confirm', 'showSnackbar'],

  useInjectable: [
    Models.NewProposal,
    Models.Status,
    Models.Date,
    Services.Tracker,
    Services.Calendar,
    Services.Proposal,
  ],

  components: {
    ProposalActionsMenu,
    WrapperWithTooltip,
    ProposalStatusSelector,
    ReassignCampaignModal,
  },

  props: {
    data: {
      type: Array,
      required: true,
    },
    agencyInfo: {
      type: Object,
      required: true,
    },
    isAgencyAdmin: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    byClient: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    search: {
      type: String,
    },
    total: {
      type: Number,
      default: 0,
    },
    options: {
      type: Object,
    },
  },
  data: (): {
    defaultHeaders: DataTableHeader[];
    loadingUpdateStatusProposal: string;
    reassignCampaignModalProps: {
      dialog: boolean;
      campaignId: string;
      campaignName?: string;
      createdBy;
    };
  } => ({
    reassignCampaignModalProps: {
      dialog: false,
      campaignId: '',
      campaignName: '',
      createdBy: null,
    },
    loadingUpdateStatusProposal: '',
    defaultHeaders: [
      { text: '', width: '50px', align: 'center', value: 'client.logo', sortable: false, filterable: false },
      { text: 'Client', width: '200px', align: 'start', value: 'client.name', filterable: true },
      { text: 'Proposal Name', width: '200px', value: 'name', filterable: true },
      { text: 'Budget', width: '100px', value: 'budget', filterable: false },
      { text: 'Start Date', width: '125px', value: 'campaignStartDate', filterable: false },
      { text: 'End Date', width: '125px', value: 'campaignEndDate', filterable: false },
      { text: 'Last updated', width: '180px', value: 'updatedAgo', filterable: false },
      { text: 'Status', width: '150px', value: 'status', filterable: false },
      { text: 'User', value: 'createdBy.email', width: '175px', filterable: false },
      { text: '', align: 'end', value: 'actions', width: '65px', sortable: false, filterable: false },
    ],
  }),

  computed: {
    headers(): DataTableHeader[] {
      const headerFilter = item => {
        const byClient = this.$props.byClient;

        return (
          (this.isAgencyAdmin && item.value === 'createdBy.email') ||
          (!byClient && item.value !== 'createdBy.email') ||
          (item.value !== 'client.name' && item.value !== 'createdBy.email')
        );
      };
      return this.defaultHeaders.filter(headerFilter);
    },
    hasCreatedByColumn() {
      return this.headers.some(item => item && item.value !== 'createdBy.email');
    },
    searchTerm(): string {
      if (typeof this.search === 'undefined') {
        return this.$store.state.searchTerm;
      }

      return this.search;
    },
    user(): AdProductUser {
      return this.$store.state.auth.user;
    },
    adminReviewEnabled(): boolean {
      return this.agencyInfo.adminReviewEnabled;
    },
    proposals() {
      return this.data.map(p => {
        return {
          ...p,
          updatedAgo: formatDistanceToNow(new Date(p.updatedAt), { addSuffix: true }),
        };
      });
    },
  },

  methods: {
    openReassignDialog({
      id,
      campaignName,
      createdBy,
    }: {
      id: string | number;
      campaignName: string;
      createdBy;
    }): void {
      this.reassignCampaignModalProps.dialog = true;
      this.reassignCampaignModalProps.campaignId = id;
      this.reassignCampaignModalProps.campaignName = campaignName;
      this.reassignCampaignModalProps.createdBy = createdBy;
    },
    dateToString(date: string): string {
      return (this.dateEntity as DateModelContract).dateToFormatDateString(date);
    },
    proposalIsChangeDisabled(proposalStatus: Status): boolean {
      return (this.newProposalEntity as NewProposalModelContract).newProposalIsChangeDisabled({
        proposalStatus,
        isAgencyAdmin: this.isAgencyAdmin,
      });
    },
    availableStatusTransitions(proposal: Proposal): string[] {
      if (proposal.status === Status.Approved) {
        return [Status.InProgress];
      }

      return proposal.nextStatuses || [];
    },
    getVisitedRoute(proposalId: string): NewProposalRoute {
      const storageService: LocalStorageServiceContract = this.$container.get(Services.LocalStorage);
      const uxService: UXUtilsServiceContract = this.$container.get(Services.UX);

      const { email } = this.user;

      return uxService.getProposalStepForUser({
        email,
        proposalId,
        storageService,
      });
    },
    async navigateToNewProposal(proposal): Promise<void> {
      const redirectToStep = (link: Routes): void => {
        this.$router.push({ name: link, params: { id: proposal.client?.id, proposalId: proposal.id || '' } });
      };

      const visitedRoute = this.getVisitedRoute(proposal.id);

      if ([Status.Sold, Status.ClosedLost].includes(proposal.status)) {
        redirectToStep(Routes.ProposalSummary);
      } else if (
        this.agencyInfo.adminReviewEnabled &&
        proposal.status === Status.SubmittedForReview &&
        this.isAgencyAdmin
      ) {
        const { confirmed } = await this.$confirm.show({
          title: 'Begin Reviewing Proposal?',
          body: "This will change the proposal status to 'Under Review'",
          confirmText: 'Yes',
          cancelText: 'Cancel',
        });

        if (!confirmed) {
          redirectToStep(Routes.ProposalSummary);
          return;
        }

        await this.saveStatus({
          propertyId: proposal.id,
          newStatus: Status.UnderReview,
        });

        redirectToStep(Routes.ProposalSolutions);
      } else if (visitedRoute) {
        redirectToStep(visitedRoute.name);
        return;
      } else {
        redirectToStep(Routes.ProposalMarket);
      }
    },
    marketHasBeenSet(market): boolean {
      return (
        market?.geoSelections &&
        Object.keys(market.geoSelections).length &&
        Object.keys(market.geoSelections).some(
          key => Array.isArray(market.geoSelections[key]) && market.geoSelections[key].length,
        )
      );
    },
    closeClientInfoDialog(): void {
      this.$emit('close-client-info');
    },
    openClientInfoDialog(id: string): void {
      this.$emit('open-client-info', id);
    },
    clientLogo(proposal): string | null {
      return proposal?.client.logo;
    },
    async saveStatus({
      propertyId,
      oldStatus,
      newStatus,
    }: {
      propertyId: string;
      newStatus: string;
      oldStatus: string;
    }): Promise<boolean> {
      const alertIfError = (isOk: boolean) => {
        if (!isOk) {
          this.$log(
            'error',
            'ProposalsTable/saveStatus',
            `Unable to update proposal status for ${propertyId} to ${newStatus}`,
          );
          this.showSnackbar('Update status failed');
        }
      };

      let skipStatusUpdate = false;
      let updatedProposal: Nullable<Proposal> = null;
      this.loadingUpdateStatusProposal = propertyId;

      if (this.agencyInfo.adminReviewEnabled && newStatus === Status.ClientApproved) {
        updatedProposal = await this.$store.dispatch('proposal/updateProposalStatus', {
          proposalPropertyId: propertyId,
          newStatus,
        });

        alertIfError(!!updatedProposal);

        const submittedStatus = Status.SubmittedForReview;

        const prettyStatus = (status: Status): string =>
          (this.statusEntity as StatusModelContract).prettyStatus(status, this.adminReviewEnabled).short;

        const { confirmed } = await this.$confirm.show({
          title: 'Submit for Admin Approval?',
          body: `This will change the proposal status to ${prettyStatus(submittedStatus)}`,
          confirmText: 'Yes',
          cancelText: 'No',
        });

        if (confirmed) {
          newStatus = submittedStatus;
        } else {
          skipStatusUpdate = true;
        }
      }

      this.$store.dispatch('proposal/triggerRefetchProposals');

      if (!skipStatusUpdate) {
        updatedProposal = await this.$store.dispatch('proposal/updateProposalStatus', {
          proposalPropertyId: propertyId,
          newStatus,
        });

        alertIfError(!!updatedProposal);
      }

      if (!updatedProposal) return false;

      const { id } = this.$store.state.auth.user;
      const clientCategory = updatedProposal?.client?.category?.name?.split('^')?.join(', ');

      (this.tracker as TrackerContract).trackProposalStatusChange({
        proposal: updatedProposal,
        userId: id,
        updatedAt: new Date().toISOString(),
        from: oldStatus,
        to: newStatus,
        clientCategory,
      });

      this.loadingUpdateStatusProposal = '';

      this.$emit('refetch-proposals');

      return !!updatedProposal;
    },
    prettyStatus(status: string) {
      const statusEntity: StatusModelContract = this.statusEntity;
      return statusEntity.prettyStatus(status, this.adminReviewEnabled).short;
    },
  },
});

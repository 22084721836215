import 'reflect-metadata';
import { CalendarServiceContract, BudgetModelContract } from '@/injectables';
import { id, injectable } from 'inversify';
import { RateType } from '@/app/graphql';

@injectable()
export class BudgetModel implements BudgetModelContract {
  getMonthlySpend({
    budget,
    startDate,
    endDate,
    calendarService,
  }: {
    budget: number;
    startDate: string;
    endDate: string;
    calendarService: CalendarServiceContract;
  }): number {
    const daysAsMonth = 6;

    const diffInMonths = calendarService.getDifferenceInMonths(new Date(endDate), new Date(startDate));
    const diffInDays = calendarService.getDifferenceInCalendarDays(new Date(endDate), new Date(startDate)) + 1;

    if (!diffInMonths) {
      return budget;
    }

    const remainingDays = diffInDays - diffInMonths * 31;
    const totalMonths = remainingDays >= daysAsMonth ? diffInMonths + 1 : diffInMonths;

    return Math.ceil(budget / totalMonths);
  }

  formatPrice(value: string | number, decimalPointDigits = 0): string {
    if (typeof value === 'string') {
      return value;
    }
    if (!value) {
      return '$ 0';
    }
    let result = `$${Math.trunc(value)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
    if (decimalPointDigits) {
      result = `${result}.${(value % 1).toFixed(decimalPointDigits).toString().replace('0.', '')}`;
    }
    return result;
  }

  getBudgetFromQuantity(quantity, rate, rateType: RateType): number {
    if (rateType === RateType.Costper || rateType === RateType.Flatrate) {
      return Math.round(quantity * (rate || 1));
    }
    return Math.round((quantity / 1000) * (rate || 1));
  }

  getQuantityFromBudget(budget, rate, rateType: RateType): number {
    if (rateType === RateType.Costper || rateType === RateType.Flatrate) {
      return Math.round(budget / (rate || 1));
    }
    return Math.round((budget / (rate || 1)) * 1000);
  }
}


import Vue from 'vue';

export default Vue.extend({
  name: 'PageEditNameField',

  props: {
    value: {
      type: String,
      required: true,
    },
  },

  data(): {
    pageNameCopy: string;
  } {
    const name = this.value;
    return {
      pageNameCopy: name,
    };
  },

  methods: {
    cancelInput() {
      this.$emit('close', false);
    },
    saveInput() {
      this.$emit('input', this.pageNameCopy);
      this.$emit('close', false);
    },
  },
});

import 'reflect-metadata';
import { injectable } from 'inversify';
import { ColorModelContract, ColorType } from '@/injectables';
import { UnsafeAny } from '@/shared/types';

@injectable()
export class ColorModel implements ColorModelContract {
  // logic from https://gist.github.com/dkaraush/65d19d61396f5f3cd8ba7d1b4b3c9432
  multiplyMatrices(A, B): [number, number, number] {
    return [
      A[0] * B[0] + A[1] * B[1] + A[2] * B[2],
      A[3] * B[0] + A[4] * B[1] + A[5] * B[2],
      A[6] * B[0] + A[7] * B[1] + A[8] * B[2],
    ];
  }
  oklch2oklab([l, c, h]) {
    return [l, isNaN(h) ? 0 : c * Math.cos((h * Math.PI) / 180), isNaN(h) ? 0 : c * Math.sin((h * Math.PI) / 180)];
  }
  oklab2oklch([l, a, b]): [number, number, number] {
    return [
      l,
      Math.sqrt(a ** 2 + b ** 2),
      Math.abs(a) < 0.0002 && Math.abs(b) < 0.0002 ? NaN : ((((Math.atan2(b, a) * 180) / Math.PI) % 360) + 360) % 360,
    ];
  }
  rgb2srgbLinear(rgb) {
    return rgb.map(c =>
      Math.abs(c) <= 0.04045 ? c / 12.92 : (c < 0 ? -1 : 1) * ((Math.abs(c) + 0.055) / 1.055) ** 2.4,
    );
  }
  srgbLinear2rgb(rgb) {
    return rgb.map(c =>
      Math.abs(c) > 0.0031308 ? (c < 0 ? -1 : 1) * (1.055 * Math.abs(c) ** (1 / 2.4) - 0.055) : 12.92 * c,
    );
  }
  oklab2xyz = lab => {
    const LMSg = this.multiplyMatrices(
      [
        1, 0.3963377773761749, 0.2158037573099136, 1, -0.1055613458156586, -0.0638541728258133, 1, -0.0894841775298119,
        -1.2914855480194092,
      ],
      lab,
    );
    const LMS = LMSg.map(val => val ** 3);
    return this.multiplyMatrices(
      [
        1.2268798758459243, -0.5578149944602171, 0.2813910456659647, -0.0405757452148008, 1.112286803280317,
        -0.0717110580655164, -0.0763729366746601, -0.4214933324022432, 1.5869240198367816,
      ],
      LMS,
    );
  };
  xyz2oklab(xyz): [number, number, number] {
    const LMS = this.multiplyMatrices(
      [
        0.819022437996703, 0.3619062600528904, -0.1288737815209879, 0.0329836539323885, 0.9292868615863434,
        0.0361446663506424, 0.0481771893596242, 0.2642395317527308, 0.6335478284694309,
      ],
      xyz,
    );
    const LMSg = LMS.map(val => Math.cbrt(val));
    return this.multiplyMatrices(
      [
        0.210454268309314, 0.7936177747023054, -0.0040720430116193, 1.9779985324311684, -2.4285922420485799,
        0.450593709617411, 0.0259040424655478, 0.7827717124575296, -0.8086757549230774,
      ],
      LMSg,
    );
  }
  xyz2rgbLinear(xyz) {
    return this.multiplyMatrices(
      [
        3.2409699419045226, -1.537383177570094, -0.4986107602930034, -0.9692436362808796, 1.8759675015077202,
        0.04155505740717559, 0.05563007969699366, -0.20397695888897652, 1.0569715142428786,
      ],
      xyz,
    );
  }
  rgbLinear2xyz(rgb) {
    return this.multiplyMatrices(
      [
        0.41239079926595934, 0.357584339383878, 0.1804807884018343, 0.21263900587151027, 0.715168678767756,
        0.07219231536073371, 0.01933081871559182, 0.11919477979462598, 0.9505321522496607,
      ],
      rgb,
    );
  }

  rgb2oklch(rgb: [number, number, number]): [number, number, number] {
    const srgbLinear = this.rgb2srgbLinear(rgb);
    const xyz = this.rgbLinear2xyz(srgbLinear);
    const oklab = this.xyz2oklab(xyz);
    return this.oklab2oklch(oklab);
  }

  oklch2Rgb(color) {
    const oklab = this.oklch2oklab(color);
    const xyz = this.oklab2xyz(oklab);
    const rgb = this.xyz2rgbLinear(xyz);
    return this.srgbLinear2rgb(rgb);
  }

  hex2Rgb(hex: string): [number, number, number] {
    const hexValue = hex.replace(/^#/, '');
    return [
      parseInt(hexValue.substring(0, 2), 16) / 255,
      parseInt(hexValue.substring(2, 4), 16) / 255,
      parseInt(hexValue.substring(4, 6), 16) / 255,
    ];
  }
  any2Oklch({ color, type }: { color: UnsafeAny; type: ColorType }): [number, number, number] {
    if (type === ColorType.hex) {
      return this.rgb2oklch(this.hex2Rgb(color));
    }
    return [undefined, undefined, undefined];
  }

  getGeneratedColors({
    baseColor,
    numberOfColors = 6,
    type,
  }: {
    baseColor: string;
    numberOfColors?: number;
    type: ColorType;
  }): string[] {
    const [, , h = 249] = this.any2Oklch({ color: baseColor, type });
    const lMain = 0.7753;
    const cMain = 0.1232;
    const maxWithOneL = 12;
    const colsNumber = Math.min(numberOfColors, maxWithOneL);
    const baseRate = Math.round(360 / colsNumber);

    return [
      ...Array.from({ length: numberOfColors }, (_, i) => {
        const l = lMain - Math.floor(i / maxWithOneL) * 0.1;
        const number = i % maxWithOneL;
        return `rgb(${this.oklch2Rgb([l, cMain, h + baseRate * number])
          .map(c => Math.round(c * 255))
          .join(',')})`;
      }),
    ];
  }
}

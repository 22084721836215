import { Client, NewClient, ClientInsights, ClientProspect } from '@/shared/types';
import { MutationTree } from 'vuex';
import { ClientState } from './state';
import { omit } from 'lodash';

export const mutations = (): MutationTree<ClientState> => ({
  SET_CLIENTS_NOT_LOADED(_state): void {
    _state.clients.loaded = false;
  },
  LOADING_AGENCIES(_state, _status): void {
    _state.loadingAgencies = _status;
  },
  SET_NEW_CLIENT_LOADING(_state, _status: boolean): void {
    _state.newClientLoading = _status;
  },
  RESET_NEW_CLIENT(_state): void {
    const emptyNewClient: NewClient = {
      AgencyPartner: '',
      name: '',
      category: { name: '', id: '' },
      WURL: '',
      Phone: '',
      locations: [],
    };
    _state.newClient = emptyNewClient;
  },
  SET_CLIENT_LOGO(_state, { clientLogo, clientId }): void {
    if (_state.activeClient.id === clientId) _state.activeClient.logo = clientLogo;
  },
  SET_UPDATE_LOGO_LOADING(_state, _status): void {
    _state.updateLogoLoading = _status;
  },
  SET_NEW_CLIENT(_state, _newClient): void {
    _state.newClient = _newClient;
  },
  SET_UPDATE_CLIENT_LOADING(_state, _status): void {
    _state.updateClientLoading = _status;
  },
  SET_ACTIVE_CLIENT_ID(_state, _PropertyId: string | null): void {
    _state.activeClientId = _PropertyId;
  },
  SET_ACTIVE_CLIENT(_state, _client: Client): void {
    const defaultGeo = {
      cityList: [],
      zipList: [],
      dmaList: [],
      stateList: [],
      countyList: [],
    };
    _state.activeClient = _client;
    if (_state.activeClient && !_state.activeClient.geoSelections) {
      _state.activeClient['geoSelections'] = defaultGeo;
    }
  },
  SET_ACTIVE_CLIENT_LOCATIONS(_state, _address): void {
    _state.activeClient.address = _address;
  },
  SET_ACTIVE_CLIENT_GEO_SELECTIONS(_state, _geoSelections): void {
    _state.activeClient.geoSelections = omit(_geoSelections, '__typename');
  },
  SET_ACTIVE_CLIENT_INFO(_state, _info): void {
    _state.activeClient.url = _info.url;
    _state.activeClient.category = _info.category;
    _state.activeClient.phone = _info.phone;
    _state.activeClient.updatedAt = _info.updatedAt;
  },
  SET_ACTIVE_CLIENT_NOTES(_state, _notes: string): void {
    if (_state.activeClient) {
      _state.activeClient.notes = _notes;
    }
  },
  SET_ALL_AGENCIES(_state, _agencies): void {
    _state.allAgencies = { list: _agencies, loaded: true };
  },
  SET_CLIENTS(_state, _data: Array<Client>): void {
    _state.clients = { loaded: true, list: [..._data] };
  },
  LOADING_CLIENTS(_state, _status: boolean): void {
    _state.loadingClients = _status;
  },
  LOADING_CLIENT(_state, _status: boolean): void {
    _state.loadingClient = _status;
  },
  LOADING_CATEGORIES(_state, _status: boolean): void {
    _state.loadingCategories = _status;
  },
  SET_CATEGORIES(_state, _categories: Array<string>): void {
    _state.categories = { loaded: true, list: _categories };
  },
  SET_PURCHASE_HISTORY(_state, _ph): void {
    _state.activeClientPurchaseHistory = _ph;
  },
  SET_ACTIVE_CLIENT_INSIGHTS(_state, _insights: ClientInsights): void {
    _state.activeClientInsights = _insights;
  },
  SET_ACTIVE_CLIENT_PROSPECT(_state, _prospect: ClientProspect): void {
    _state.activeClientProspect = _prospect;
  },
  SET_ACTIVE_CLIENT_INSIGHTS_LOADING(_state, _value: boolean): void {
    _state.activeClientInsightsLoading = _value;
  },
  SET_ACTIVE_CLIENT_PROSPECT_LOADING(_state, _value: boolean): void {
    _state.activeClientProspectLoading = _value;
  },
  SET_ACTIVE_CLIENT_PROSPECT_REFRESHING(_state, _value: boolean): void {
    _state.activeClientProspectRefreshing = _value;
  },
});

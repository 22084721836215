import * as Types from '../../../../../app/graphql/_types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { TableProductFragmentFragmentDoc } from '../../../../../app/graphql/fragments/product-config.fragment.generated';
export type GetGlobalProductsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetGlobalProductsQuery = {
  products?: Array<{
    id: string;
    agencyId?: string | null;
    type?: Types.ProductConfigType | null;
    name?: string | null;
    category?: Types.ProductConfigCategory | null;
    minDays?: number | null;
    minSpend?: number | null;
    recommendedBudget?: number | null;
    isHidden?: boolean | null;
    description?: Record<string, any> | null;
  } | null> | null;
};

export const GetGlobalProductsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getGlobalProducts' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'products' },
            name: { kind: 'Name', value: 'getMediaplannerGlobalProductConfigs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'TableProductFragment' } }],
            },
          },
        ],
      },
    },
    ...TableProductFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetGlobalProductsQuery, GetGlobalProductsQueryVariables>;


import { DateModelContract } from '@/injectables';
import { Models } from '@/injectables/tokens';

import Vue from 'vue';

export default Vue.extend({
  name: 'DateRangePicker',

  useInjectable: [Models.Date],

  props: {
    /**
     * Reactive value used for v-model.
     */
    value: {
      type: Array,
      required: true,
    },

    /**
     * Function to format date before put it into from and to slots.
     */
    dateFormatter: {
      type: Function,
    },

    /**
     * If true the picker will have From and To slots.
     * If false then only one From slot.
     */
    full: {
      type: Boolean,
      default: false,
    },

    /**
     * Min date for datepicker.
     */
    min: {
      type: String,
      default: new Date().toISOString(),
    },

    /**
     * Max date for datepicker.
     */
    max: {
      type: String,
      default: new Date(new Date().setDate(new Date().getDate() + 100)).toISOString(),
    },

    /**
     * Is picker disabled?
     */
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isOpen: false,
    };
  },

  computed: {
    selectedRange() {
      const [from, to] = this.cleanValue;

      return {
        from,
        to,
      };
    },
    formattedRange() {
      const [from, to] = this.value;
      if (!this.dateFormatter) return '';
      if (!from || !to) return '';
      return `${this.dateFormatter(from)} - ${this.dateFormatter(to)}`;
    },
    cleanValue() {
      const dateModel: DateModelContract = this[Models.Date];

      const [from, to] = this.value;
      return [from, to].filter(Boolean).map(date => date && dateModel.dateToFormatDateString(date));
    },
  },

  methods: {
    /* public */
    focus() {
      this.isOpen = true;
    },
    clear() {
      this.$emit('input', []);
      this.$emit('clear');
    },
    close() {
      this.clear();
      this.isOpen = false;
      this.$emit('close');
    },
    onInput(dates: string[]) {
      if (this.disabled) return;
      const dateModel: DateModelContract = this[Models.Date];

      this.$emit('input', dates.map(dateModel.ISOStringFromFormatDateString));
    },
    formatDate(date: string): string {
      const dateModel: DateModelContract = this[Models.Date];

      const formatter = this.dateFormatter || dateModel.dateToFormatDateString;
      return formatter(date);
    },
  },

  watch: {
    value(next: string[]) {
      if (next.length >= 2) {
        this.isOpen = false;
        this.$emit('complete-selection');
      }
    },
    isOpen(next) {
      if (next === false && this.value.length < 2) {
        this.clear();
      }
    },
  },
});


import Vue from 'vue';
import { C360Icon } from '@c360/ui';

export default Vue.extend({
  name: 'UserMenu',

  components: {
    C360Icon,
  },

  props: {
    currentAgency: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    subEntity() {
      if (!this.currentAgency?.parent) return '';
      return this.currentAgency.name;
    },
    agency() {
      if (this.currentAgency?.parent) return this.currentAgency.parent.name;
      return this.currentAgency.name;
    },
  },
});

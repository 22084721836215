import * as Types from '../_types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type TargetingSegmentFragment = {
  id: string;
  name: string;
  externalId: string;
  description?: string | null;
  segmentCategory: Types.SegmentCategory;
  segmentSubCategory?: Types.SegmentSubCategory | null;
  isMain: boolean;
};

export const TargetingSegmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TargetingSegment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AudienceSegment' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'externalId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'segmentCategory' } },
          { kind: 'Field', name: { kind: 'Name', value: 'segmentSubCategory' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isMain' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TargetingSegmentFragment, unknown>;

import {
  KeywordSummary,
  ProductConfig,
  ProposalDemographics,
  ProposalGeoSelections,
  OTTStandaloneMarket,
  SemKeyword,
} from '@/shared/types';
import Vue from 'vue';
import { MutationTree } from 'vuex';
import { ProductModuleState } from './state';
import { Product } from '@/shared/types';

/* eslint-disable no-unused-vars */
export enum MutationTypes {
  SET_PRODUCTS = 'SET_PRODUCTS',
  SET_CAN_HAVE_PACKAGES = 'SET_CAN_HAVE_PACKAGES',
  SET_PRODUCTS_LOADING = 'SET_PRODUCTS_LOADING',
  SET_LOADING_KEYWORDS = 'SET_LOADING_KEYWORDS',
  SET_KEYWORDS = 'SET_KEYWORDS',
  SET_KEYWORDS_SUMMARY = 'SET_KEYWORDS_SUMMARY',
  LOADING_KEYWORDS_SUMMARY = 'LOADING_KEYWORDS_SUMMARY',
  SET_LOADING_CUSTOM_KEYWORDS = 'SET_LOADING_CUSTOM_KEYWORDS',
  SET_OTT_MARKET_GEO_SELECTIONS = 'SET_OTT_MARKET_GEO_SELECTIONS',
  SET_OTT_MARKET_DEMOGRAPHICS = 'SET_OTT_MARKET_DEMOGRAPHICS',
  SET_OTT_MARKET_ENTHUSIASTS = 'SET_OTT_MARKET_ENTHUSIASTS',
  SET_OTT_MARKET_LIFESTILE = 'SET_OTT_MARKET_LIFESTILE',
  SET_OTT_MARKET_OCCUPATION = 'SET_OTT_MARKET_OCCUPATION',
  SET_OTT_MARKET_CUSTOM = 'SET_OTT_MARKET_CUSTOM',
  SET_OTT_MARKET_SEARCH_HISTORY = 'SET_OTT_MARKET_SEARCH_HISTORY',
  SET_OTT_MARKET_SHOPPING = 'SET_OTT_MARKET_SHOPPING',
  SET_OTT_MARKET = 'SET_OTT_MARKET',
  UPDATE_PRODUCT_SETTINGS = 'UPDATE_PRODUCT_SETTINGS',
  SET_PRODUCT_CONFIGS = 'SET_PRODUCT_CONFIGS',
  SET_FETCH_PRODUCT_CONFIGS_LOADING = 'SET_FETCH_PRODUCT_CONFIGS_LOADING',
  SET_PACKAGE_BUDGET = 'SET_PACKAGE_BUDGET',
  SET_PACKAGE_PRODUCTS = 'SET_PACKAGE_PRODUCTS',
  TOGGLE_PACKAGE_PRODUCT_LOCKED = 'TOGGLE_PACKAGE_PRODUCT_LOCKED',
  UPDATE_PACKAGE_PRODUCT_BUDGET = 'UPDATE_PACKAGE_PRODUCT_BUDGET',
  SET_PACKAGE_PRODUCT_FLIGHTS = 'SET_PACKAGE_PRODUCT_FLIGHTS',
  SET_GENERATED_KEYWORDS_TO_PACKAGE = 'SET_GENERATED_KEYWORDS_TO_PACKAGE',
  SET_UPDATE_PRODUCTS_LOADING = 'SET_UPDATE_PRODUCTS_LOADING',
  SET_PACKAGE_UPDATE_LOADING = 'SET_PACKAGE_UPDATE_LOADING',
  SET_XML = 'SET_XML',
  SET_NO_XML = 'SET_NO_XML',
  SET_PACKAGE_PRODUCT = 'SET_PACKAGE_PRODUCT',
  SET_PACKAGE_DESCRIPTION = 'SET_PACKAGE_DESCRIPTION',
  SET_PACKAGE_NAME = 'SET_PACKAGE_NAME',
  UPDATE_PACKAGE_WAS_CHANGED = 'UPDATE_PACKAGE_WAS_CHANGED',
  UPDATE_PRODUCT_IN_PACKAGE = 'UPDATE_PRODUCT_IN_PACKAGE',
}

export const mutations = (): MutationTree<ProductModuleState> => ({
  [MutationTypes.SET_PRODUCTS](_state, _products: Product[]): void {
    _state.products = _products;
  },
  [MutationTypes.SET_CAN_HAVE_PACKAGES](_state, _canHavePackages: boolean): void {
    _state.agencyCanHavePackages = _canHavePackages;
  },
  [MutationTypes.SET_PRODUCTS_LOADING](_state, _status: boolean): void {
    _state.productsLoading = _status;
  },
  [MutationTypes.SET_KEYWORDS](_state, _keywords: SemKeyword[]): void {
    _state.keywords = _keywords;
  },
  [MutationTypes.SET_KEYWORDS_SUMMARY](_state, _summary: KeywordSummary): void {
    _state.keywordsSummary = _summary;
  },
  [MutationTypes.LOADING_KEYWORDS_SUMMARY](_state, _status: boolean): void {
    _state.loadingKeywordsSummary = _status;
  },
  [MutationTypes.SET_LOADING_KEYWORDS](_state, _status: boolean): void {
    _state.loadingKeywords = _status;
  },
  [MutationTypes.SET_LOADING_CUSTOM_KEYWORDS](_state, _status: boolean): void {
    _state.loadingCustomKeywords = _status;
  },
  [MutationTypes.SET_OTT_MARKET_GEO_SELECTIONS](_state, _geoSelections: ProposalGeoSelections): void {
    _state.OTTMarket.geoSelections = _geoSelections;
  },
  [MutationTypes.SET_OTT_MARKET_DEMOGRAPHICS](_state, _demographics: ProposalDemographics): void {
    _state.OTTMarket.demographics = _demographics;
  },
  [MutationTypes.SET_OTT_MARKET_ENTHUSIASTS](_state, _enthusiasts: { name: string; isSelected: boolean }[]): void {
    _state.OTTMarket.enthusiastList = _enthusiasts;
  },
  [MutationTypes.SET_OTT_MARKET_LIFESTILE](_state, _lifestyle: { name: string; isSelected: boolean }[]): void {
    _state.OTTMarket.lifestyleList = _lifestyle;
  },
  [MutationTypes.SET_OTT_MARKET_OCCUPATION](_state, _occupation: { name: string; isSelected: boolean }[]): void {
    _state.OTTMarket.occupationList = _occupation;
  },
  [MutationTypes.SET_OTT_MARKET_SEARCH_HISTORY](_state, _searchHistory: { name: string; isSelected: boolean }[]): void {
    _state.OTTMarket.searchHistoryList = _searchHistory;
  },
  [MutationTypes.SET_OTT_MARKET_SHOPPING](_state, _shopping: { name: string; isSelected: boolean }[]): void {
    _state.OTTMarket.shoppingList = _shopping;
  },

  [MutationTypes.SET_OTT_MARKET_CUSTOM](_state, _custom: { name: string; isSelected: boolean }[]): void {
    _state.OTTMarket.customList = _custom;
  },
  [MutationTypes.SET_OTT_MARKET](_state, _market: OTTStandaloneMarket): void {
    _state.OTTMarket = _market;
  },
  [MutationTypes.SET_FETCH_PRODUCT_CONFIGS_LOADING](_state, _status): void {
    _state.fetchProductConfigsLoading = _status;
  },
  [MutationTypes.SET_PRODUCT_CONFIGS](_state, _productConfigs: ProductConfig[]): void {
    _state.productConfigs = { list: _productConfigs, loaded: true };
  },
  [MutationTypes.SET_PACKAGE_BUDGET](_state, _budget: number): void {
    _state.newPackage.budget = _budget;
  },
  [MutationTypes.SET_PACKAGE_PRODUCTS](_state, _productArray): void {
    Vue.set(_state.newPackage, 'products', _productArray);
  },
  [MutationTypes.TOGGLE_PACKAGE_PRODUCT_LOCKED](_state, { _status, _productId }): void {
    const productIndex = _state.newPackage.products.findIndex(product => product.id === _productId);
    if (productIndex !== -1) {
      Vue.set(_state.newPackage.products[productIndex], 'isLocked', _status);
    }
  },
  [MutationTypes.UPDATE_PACKAGE_PRODUCT_BUDGET](_state, { _budget, _productId }): void {
    const productIndex = _state.newPackage.products.findIndex(product => product.id === _productId);
    if (productIndex !== -1) {
      Vue.set(_state.newPackage.products[productIndex], 'budget', _budget);
    }
  },
  [MutationTypes.UPDATE_PACKAGE_WAS_CHANGED](_state, { productId, status }): void {
    const productIndex = _state.newPackage.products.findIndex(product => product.id === productId);
    if (productIndex !== -1) {
      Vue.set(_state.newPackage.products[productIndex], 'isChanged', status);
    }
  },
  [MutationTypes.SET_PACKAGE_PRODUCT_FLIGHTS](_state, { productId, flights }) {
    const productIndex = _state.newPackage.products.findIndex(product => product.id === productId);
    if (productIndex === -1) return;
    Vue.set(_state.newPackage.products[productIndex], 'flights', flights);
  },
  [MutationTypes.SET_GENERATED_KEYWORDS_TO_PACKAGE](
    _state,
    { keywords, productId, summary }: { keywords: SemKeyword[]; productId: string; summary: KeywordSummary },
  ): void {
    const productIndex = _state.newPackage.products.findIndex(product => product.id === productId);
    if (productIndex !== -1) {
      const kwObj = { list: keywords, summary };
      Vue.set(_state.newPackage.products[productIndex], 'keywords', kwObj);
    }
  },
  [MutationTypes.SET_XML](
    _state,
    { broadcast, productId, link }: { broadcast: any[]; productId: string; link: string },
  ): void {
    const productIndex = _state.newPackage.products.findIndex(product => product.id === productId);
    if (productIndex !== -1) {
      const broadcastInfo = { broadcast, link };
      Vue.set(_state.newPackage.products[productIndex], 'broadcastInfo', broadcastInfo);
      Vue.set(_state.newPackage.products[productIndex], 'noXmlFlag', false);
    }
  },
  [MutationTypes.SET_NO_XML](_state, productId: string): void {
    const productIndex = _state.newPackage.products.findIndex(product => product.id === productId);
    if (productIndex !== -1) {
      Vue.set(_state.newPackage.products[productIndex], 'noXmlFlag', true);
    }
  },
  [MutationTypes.SET_UPDATE_PRODUCTS_LOADING](_state, _status: boolean): void {
    _state.updateProductsLoading = _status;
  },
  [MutationTypes.SET_PACKAGE_PRODUCT](_state, product) {
    const defaultPackageProduct = {
      products: [],
      category: '',
      description: '',
      isEditable: true,
      isHidden: false,
      minDays: 0,
      minSpend: 0,
      name: '',
      productId: '',
      recommendedBudget: 0,
    };
    const mergedProduct = { ...defaultPackageProduct, ...product };
    _state.newPackage = { ...mergedProduct, budget: mergedProduct.budget };
  },
  [MutationTypes.SET_PACKAGE_DESCRIPTION](_state, description) {
    _state.newPackage.description = description;
  },
  [MutationTypes.SET_PACKAGE_NAME](_state, packageName) {
    _state.newPackage.name = packageName;
  },
  [MutationTypes.UPDATE_PRODUCT_IN_PACKAGE](_state, product) {
    const productIndex = _state.newPackage.products.findIndex(pr => pr.id === product.id);
    if (productIndex !== -1) {
      _state.newPackage.products.splice(productIndex, 1, product);
    }
  },
});

import { Failure } from '@/injectables/failure';
import { Err, Ok, Result } from '@sniptt/monads/build';
import { NewProposalService as RestNewProposalService } from './http';

import { NewProposalServiceContract } from '../contracts';
import {
  CreateProposalDocument,
  CreateProposalMutation,
  CreateProposalMutationVariables,
} from '../graphql/mutations/create-new-proposal.generated';
import { PreparedProposalMarket, ProposalInfo } from '@/shared/types';
import {
  SetProposalMarketDocument,
  SetProposalMarketMutation,
  SetProposalMarketMutationVariables,
} from '../graphql/mutations/set-proposal-market.generated';
import {
  UpdateProposalInfoDocument,
  UpdateProposalInfoMutation,
  UpdateProposalInfoMutationVariables,
} from '../graphql/mutations/update-proposal-info.generated';
import { pick } from 'lodash';
import {
  UpdateProposalSolutionsDocument,
  UpdateProposalSolutionsMutation,
  UpdateProposalSolutionsMutationVariables,
} from '../graphql/mutations/update-proposal-solutions.generated';
import {
  UpdateProposalBudgetDocument,
  UpdateProposalBudgetMutation,
  UpdateProposalBudgetMutationVariables,
} from '../graphql/mutations/update-proposal-budget.generated';
import {
  GetRecommendationsDocument,
  GetRecommendationsQuery,
  GetRecommendationsQueryVariables,
} from '../graphql/queries/get-recomendations.generated';
import {
  SetProposalCreatedByIdDocument,
  SetProposalCreatedByIdMutation,
  SetProposalCreatedByIdMutationVariables,
} from '../graphql/mutations/set-proposal-created-by.generated';
import {
  UpdateProposalFlagsDocument,
  UpdateProposalFlagsMutation,
  UpdateProposalFlagsMutationVariables,
} from '../graphql/mutations/update-proposal-flags.generated';

export class NewProposalService extends RestNewProposalService implements NewProposalServiceContract {
  async createProposal(
    newProposalData: CreateProposalMutationVariables['input'],
  ): Promise<Result<{ id: string; name?: string; createdBy?: { email: string } }, Failure>> {
    try {
      const { data } = await this._apollo.mutate<CreateProposalMutation, CreateProposalMutationVariables>({
        mutation: CreateProposalDocument,
        variables: {
          input: newProposalData,
        },
      });
      return Ok(data.createMediaplannerProposal);
    } catch (error) {
      return Err({ message: "Can't create proposal at this time." });
    }
  }

  async setMarketData(
    proposalId: string,
    market: PreparedProposalMarket,
    audienceSegments: string[],
  ): Promise<Result<SetProposalMarketMutation['updateMediaplannerProposalMarket'], Failure>> {
    try {
      const cleanMarket = pick(market, ['geoSelections']);
      const { data, errors } = await this._apollo.mutate<SetProposalMarketMutation, SetProposalMarketMutationVariables>(
        {
          mutation: SetProposalMarketDocument,
          variables: {
            market: {
              id: proposalId,
              market: cleanMarket,
            },
            segments: {
              id: proposalId,
              audienceSegments,
            },
          },
        },
      );
      if (errors) {
        return Err({ message: "Can't update proposal market at this time." });
      }
      return Ok(data.updateMediaplannerProposalMarket);
    } catch (error) {
      return Err({ message: "Can't update proposal market at this time." });
    }
  }

  async sendProposal(
    id: string,
    info: ProposalInfo,
  ): Promise<Result<UpdateProposalInfoMutation['updateMediaplannerProposalInfo'], Failure>> {
    try {
      const { startDate, endDate, ...others } = info;
      const { data, errors } = await this._apollo.mutate<
        UpdateProposalInfoMutation,
        UpdateProposalInfoMutationVariables
      >({
        mutation: UpdateProposalInfoDocument,
        variables: {
          input: {
            id,
            ...others,
            campaignStartDate: startDate,
            campaignEndDate: endDate,
          },
        },
      });
      if (errors) {
        return Err({ message: "Can't update proposal market at this time." });
      }
      return Ok(data.updateMediaplannerProposalInfo);
    } catch (error) {
      return Err({ message: "Can't update proposal market at this time." });
    }
  }

  async changeProposalBudget(
    proposalId: string,
    budget: string,
  ): Promise<Result<UpdateProposalBudgetMutation['updateProposalBudget'], Failure>> {
    try {
      const { data, errors } = await this._apollo.mutate<
        UpdateProposalBudgetMutation,
        UpdateProposalBudgetMutationVariables
      >({
        mutation: UpdateProposalBudgetDocument,
        variables: {
          proposalId,
          budget: parseInt(budget, 10),
        },
      });
      if (errors) {
        return Err({ message: "Can't update proposal market at this time." });
      }
      return Ok(data.updateProposalBudget);
    } catch (error) {
      return Err({ message: "Can't update proposal market at this time." });
    }
  }

  async saveAllSelectedProducts(payload: {
    proposalId: string;
    proposalBudget: string;
    selectedProducts: [];
    selectedPackages: [];
  }): Promise<Result<UpdateProposalSolutionsMutation['updateMediaplannerSolutions'], Failure>> {
    const { proposalId, proposalBudget, selectedProducts, selectedPackages } = payload;
    try {
      const { data, errors } = await this._apollo.mutate<
        UpdateProposalSolutionsMutation,
        UpdateProposalSolutionsMutationVariables
      >({
        mutation: UpdateProposalSolutionsDocument,
        variables: {
          input: {
            id: proposalId,
            budget: parseInt(proposalBudget),
            products: selectedProducts || [],
            packages: selectedPackages || [],
          },
        },
      });
      if (errors) {
        return Err({ message: "Can't update proposal products at this time." });
      }
      return Ok(data.updateMediaplannerSolutions);
    } catch (error) {
      return Err({ message: "Can't update proposal products at this time." });
    }
  }

  async fetchProductRecommendations(
    proposalId: string,
  ): Promise<Result<GetRecommendationsQuery['getRecommendations'], Failure>> {
    try {
      const { data, errors } = await this._apollo.query<GetRecommendationsQuery, GetRecommendationsQueryVariables>({
        query: GetRecommendationsDocument,
        variables: {
          proposalId,
        },
      });
      if (errors) {
        return Err({ message: "Can't get product recommendations at this time." });
      }
      return Ok(data.getRecommendations);
    } catch (error) {
      return Err({ message: "Can't get product recommendations market at this time." });
    }
  }

  async setProposalCreatedBy({
    proposalId,
    userId,
  }: {
    proposalId: string;
    userId: string;
  }): Promise<Result<{ id: string }, Failure>> {
    try {
      const { data, errors } = await this._apollo.mutate<
        SetProposalCreatedByIdMutation,
        SetProposalCreatedByIdMutationVariables
      >({
        mutation: SetProposalCreatedByIdDocument,
        variables: {
          id: proposalId,
          userId,
        },
      });
      if (errors) {
        return Err({ message: "Can't update proposal at this time." });
      }
      return Ok(data.setProposalCreatedById);
    } catch (error) {
      return Err({ message: "Can't update proposal at this time." });
    }
  }

  async saveProposalActionFlag(
    payload: {
      proposalId: string;
    } & Omit<UpdateProposalFlagsMutation['setMediaplannerProposalBooleans'], 'id'>,
  ): Promise<Result<UpdateProposalFlagsMutation['setMediaplannerProposalBooleans'], Failure>> {
    try {
      const { proposalId, ...flags } = payload;
      const { data, errors } = await this._apollo.mutate<
        UpdateProposalFlagsMutation,
        UpdateProposalFlagsMutationVariables
      >({
        mutation: UpdateProposalFlagsDocument,
        variables: {
          input: {
            id: proposalId,
            ...flags,
          },
        },
      });
      if (errors) {
        return Err({ message: "Can't update proposal at this time." });
      }
      return Ok(data.setMediaplannerProposalBooleans);
    } catch (error) {
      return Err({ message: "Can't update proposal at this time." });
    }
  }
}

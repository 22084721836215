import 'reflect-metadata';
import { inject, injectable } from 'inversify';
import { ProductFulfillmentMethods, BaseProductModelContract, TargetingSegmentsModelContract } from '@/injectables';
import { ProductFlight, Product, ProductConfig, Package, PackageConfig } from '@/shared/types';
import {
  KeyMetric,
  MapType,
  ProductConfigCategory,
  ProductConfigFlightType,
  ProductConfigFullfillmentMethod,
  ProductConfigType,
  RateType,
} from '@/app/graphql';
import { cloneDeep, omit, pickBy } from 'lodash';
import { Models } from '@/injectables/tokens';

@injectable()
export class BaseProductModel implements BaseProductModelContract {
  public fulfillmentMethodsForCreate = [ProductFulfillmentMethods.Inhouse, ProductFulfillmentMethods.Oms];
  @inject(Models.TargetingSegments) _targetingSegments: TargetingSegmentsModelContract;

  fixedRateConfig(product: { flightType?: string }): boolean {
    const { flightType } = product || {};

    return flightType === ProductConfigFlightType.Fixedrate;
  }

  isCostPerFlightRate(flight: ProductFlight): boolean {
    return flight.rateType === RateType.Flatrate || flight.rateType === RateType.Costper;
  }

  isXMLProduct(product): boolean {
    const xmlTypes = [ProductConfigType.Linear, ProductConfigType.Radio];
    return xmlTypes.includes(product.type);
  }

  isChangeMarginAvailable(product: ProductConfig, isGlobal: boolean) {
    return (
      (this.isPaidSearchProduct(product) || this.isSocialProduct(product) || this.isYoutubeProduct(product)) &&
      !isGlobal
    );
  }
  isSocialProduct(product): boolean {
    return product.category === ProductConfigCategory.Social;
  }
  isPaidSearchProduct(product): boolean {
    return product.type === ProductConfigType.GoogleSearch;
  }

  isEmailMarketingProduct(product): boolean {
    return product?.type === ProductConfigType.EmailMarketing;
  }

  isDigitalAudioProduct(product): boolean {
    return product?.type === ProductConfigType.DigitalAudio;
  }

  canHaveQuestionnaire(product): boolean {
    const questionnaireProducts = [ProductConfigType.GoogleSearch, ProductConfigType.EmailMarketing];
    return questionnaireProducts.includes(product.type);
  }

  isProductOptionAvailable(product: Product | ProductConfig): boolean {
    const productFunctions = [this.isPaidSearchProduct];
    return productFunctions.some(callback => callback(product));
  }

  isRateConfigurable(product): boolean {
    const { flightConfigs } = product;
    const checkProductFlightType = product.flightType === 'Social';
    const checkPlatformOption = !flightConfigs.some(f => f.platformOption);
    const checkRateTypeInflight = !flightConfigs.some(f => f.rateType);
    return !(checkProductFlightType && checkPlatformOption && checkRateTypeInflight);
  }

  isFlightRateTypeQuote(flight): boolean {
    if (!flight?.rateType) return false;
    return this.isRateTypeQuote(flight.rateType);
  }
  isRateTypeQuote(rateType: string): boolean {
    return rateType === RateType.Quote;
  }
  isFlightRateTypeCpm(flight): boolean {
    if (!flight?.rateType) return false;
    return this.isRateTypeCpm(flight.rateType);
  }
  isRateTypeCpm(rateType: string): boolean {
    return rateType === RateType.Cpm;
  }

  isYoutubeProduct(product): boolean {
    return product.type === ProductConfigType.Youtube;
  }

  hasFlights(product: Product): boolean {
    if (!product) return false;
    if (!product.hasOwnProperty('flights') || !Array.isArray(product.flights)) return false;

    return product.flights.length > 0;
  }

  hasPlatform(flightConfigs = []) {
    return flightConfigs?.some(f => f?.platformOption?.length);
  }

  hasOTT(flightConfigs = []) {
    return flightConfigs?.some(c => c?.mapType === MapType.Polygon);
  }

  isGeofence(flightConfigs = []) {
    return flightConfigs?.some(f => f?.mapType === MapType.Radius);
  }

  isGeofenceProduct(product: Product): boolean {
    return this.isGeofence(product?.flightConfigs);
  }

  mapConfigured(flight): boolean {
    return (
      flight?.market?.geoSelections &&
      Object.keys(flight.market.geoSelections).length &&
      Object.keys(flight.market.geoSelections).some(
        key => Array.isArray(flight.market.geoSelections[key]) && flight.market.geoSelections[key].length,
      )
    );
  }

  noXmlConfiguredProduct(product): boolean {
    return Boolean(product.noXmlFlag);
  }

  xmlConfiguredProduct(product): boolean {
    return (
      (product?.broadcastInfo?.link && (product?.broadcastInfo?.broadcast || product?.broadcastInfo?.broadcastOld)) ||
      this.noXmlConfiguredProduct(product)
    );
  }

  paidSearchConfigured(product: Product): boolean {
    const keywords = product?.keywords?.list ?? [];
    return Boolean(keywords?.some(k => k.isSelected));
  }

  emailMarketingConfigured(product: Product): boolean {
    return (
      product?.flights?.length > 0 &&
      product.flights.every(f => {
        return f?.budget >= product.calcMinSpend;
      })
    );
  }

  smartMailerHasFlights(product) {
    return (
      product.type === ProductConfigType.SmartMailer &&
      product?.flights &&
      product.flights.length >= 1 &&
      product.flights.some(f => f?.budget > 0 && f.budget === f?.rate)
    );
  }

  flightHasBudget(flight: ProductFlight): boolean {
    return flight?.budget && !isNaN(flight.budget) && flight.budget > 0;
  }

  formatKpiNumber(value): string {
    return `${Math.round(value)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
  }

  // TODO reuse in store
  productKPI(product): string | number {
    const { keyMetric = null } = product;
    if (product?.keywords) {
      let keyMetrics = '0';
      if (product.keywords?.summary?.lowerClicks && product.keywords?.summary?.upperClicks) {
        keyMetrics = `${product.keywords.summary.lowerClicks.toLocaleString()} - ${product.keywords.summary.upperClicks.toLocaleString()}`;
      }

      return keyMetrics;
    }
    if (this.isXMLProduct(product) && product?.broadcastInfo?.broadcast) {
      return (product.broadcastInfo?.broadcast || []).reduce((acc, p) => (acc += Number.parseInt(p.spots)), 0) || '0';
    }

    if (this.isXMLProduct(product) && product?.broadcastInfo?.broadcastOld) {
      return (
        (product.broadcastInfo?.broadcastOld || []).reduce((acc, p) => (acc += Number.parseInt(p.SumSpots)), 0) || '0'
      );
    }

    const { flights = [] } = product;
    if (keyMetric === KeyMetric.Cards) {
      const numberOfCards = flights.reduce((acc, f) => {
        if (!f.targetingOption) {
          return 0;
        }
        // 250, 500, 1000
        return acc + parseInt(f.targetingOption.replace(',', ''), 10);
      }, 0);
      return numberOfCards.toLocaleString();
    }

    let kpi;
    if (
      product?.category === ProductConfigCategory.Social ||
      (product?.category === ProductConfigCategory.Video && keyMetric === KeyMetric.Clicks)
    ) {
      const kpiMultiplier = product?.name && product.name.toLowerCase() === 'youtube' ? 0.6 : 0.4;
      kpi = flights.reduce((acc, flight) => {
        if (!flight.budget || isNaN(flight.budget)) {
          return 0;
        }
        acc += +flight.budget * kpiMultiplier;
        return acc;
      }, 0);
    } else {
      kpi = flights.reduce((acc, flight) => {
        if (!flight.rate || !flight.budget || typeof flight.rate !== 'number' || isNaN(flight.budget)) {
          return 0;
        }
        acc += (+flight.budget / +flight.rate) * 1000;
        return acc;
      }, 0);
    }
    // TODO: Check do we need round kpi before calculate dif
    kpi = Math.round(kpi);
    // impressions should not return a range. what about views?
    if (!keyMetric || [KeyMetric.Impressions, KeyMetric.Views].includes(keyMetric)) {
      return this.formatKpiNumber(kpi);
    }
    const dif = kpi * 0.15;
    return `${this.formatKpiNumber(kpi - dif)} - ${this.formatKpiNumber(kpi + dif)}`;
  }

  productKeyMetric(product, abbreviate = false): string {
    const mapper: Record<KeyMetric, string> = {
      [KeyMetric.Impressions]: 'Impressions',
      [KeyMetric.Cards]: 'Cards',
      [KeyMetric.Clicks]: 'Clicks',
      [KeyMetric.ReachAndFrequency]: 'Reach and frequency',
      [KeyMetric.Spots]: 'Spots',
      [KeyMetric.Views]: 'Views',
      [KeyMetric.Emails]: 'Emails',
    };

    let { keyMetric = null } = product;

    if (!keyMetric && product.productConfig) {
      keyMetric = product.productConfig?.keyMetric;
    }
    keyMetric = mapper[keyMetric] || keyMetric;

    if (abbreviate && keyMetric === 'Impressions') {
      return 'Imps';
    }
    return keyMetric || 'Key Metric';
  }

  productFlightTotalAllocated(product): number {
    if (!product) return 0;
    if (!product.hasOwnProperty('flights')) return 0;
    return product.flights
      .filter(f => f && f.budget)
      .map(f => parseInt(String(f.budget), 10))
      .reduce((a, b) => a + b, 0);
  }

  getLastIndex(productList) {
    return Math.max(productList.length, Math.max(...productList.filter(p => p.index).map(p => p.index)));
  }

  getReadableCategory(category: ProductConfigCategory): string {
    const categoryMapper: Record<ProductConfigCategory, string> = {
      [ProductConfigCategory.Digaud]: 'Digital Audio',
      [ProductConfigCategory.Digvid]: 'Digital Video',
      [ProductConfigCategory.Display]: 'Display',
      [ProductConfigCategory.Email]: 'Email Marketing',
      [ProductConfigCategory.Geofen]: 'Geofence',
      [ProductConfigCategory.Linear]: 'Linear',
      [ProductConfigCategory.Other]: 'Other',
      [ProductConfigCategory.Ott]: 'OTT',
      [ProductConfigCategory.Ctv]: 'CTV',
      [ProductConfigCategory.Package]: 'Package',
      [ProductConfigCategory.Radio]: 'Radio',
      [ProductConfigCategory.Search]: 'Search',
      [ProductConfigCategory.Social]: 'Social',
      [ProductConfigCategory.Video]: 'Video',
      [ProductConfigCategory.Test]: 'Other',
    };

    return categoryMapper[category];
  }

  getReadableFullfillmentMethod(fullfillmentMethod: ProductConfigFullfillmentMethod): string {
    const categoryMapper: Record<ProductConfigFullfillmentMethod, string> = {
      [ProductConfigFullfillmentMethod.Inhouse]: 'InHouse',
      [ProductConfigFullfillmentMethod.Compulse]: 'Compulse',
      [ProductConfigFullfillmentMethod.Oms]: 'OMS',
    };
    return categoryMapper[fullfillmentMethod];
  }
  categoryIconAndColor(category: ProductConfigCategory): { icon: string; color: string } {
    let icon = '';
    let color = '';
    switch (category) {
      case ProductConfigCategory.Package:
        icon = 'mdi-widgets-outline';
        color = '#FF9100';
        break;
      case ProductConfigCategory.Display:
        icon = 'mdi-laptop';
        color = '#FF9100';
        break;
      case ProductConfigCategory.Ott:
        icon = 'connected_tv';
        color = '#FF9100';
        break;
      case ProductConfigCategory.Ctv:
        icon = 'connected_tv';
        color = '#FF9100';
        break;
      case ProductConfigCategory.Digvid:
        icon = 'smart_display';
        color = '#FF9100';
        break;
      case ProductConfigCategory.Search:
        icon = 'mdi-cash-multiple';
        color = '#00C853';
        break;
      case ProductConfigCategory.Social:
        icon = 'mdi-account-outline';
        color = '#00B0FF';
        break;
      case ProductConfigCategory.Email:
        icon = 'mdi-email-outline';
        color = '#FFC400';
        break;
      case ProductConfigCategory.Radio:
        icon = 'mdi-radio';
        color = '#2979FF';
        break;
      case ProductConfigCategory.Video:
        icon = 'mdi-movie';
        color = '#FF5252';
        break;
      case ProductConfigCategory.Linear:
        icon = 'mdi-television';
        color = '#AB47BC';
        break;
      case ProductConfigCategory.Digaud:
        icon = 'mdi-volume-high';
        color = '#AB47BC';
        break;
      case ProductConfigCategory.Geofen:
        icon = 'share_location';
        color = '#AB47BC';
        break;
      case ProductConfigCategory.Test:
        icon = 'mdi-flask-outline';
        color = '#FF9100';
        break;
      default:
        icon = 'mdi-thumb-up-outline';
        color = 'black';
        break;
    }
    return { icon, color };
  }

  public tempIdTester = new RegExp(/^temp\_.+/);

  isNullCallBack = item => item !== null;

  getCleanProducts(products, forProposal?, removeDsId?: boolean, customOmitProperties?: string[]) {
    const omitProperties =
      customOmitProperties ||
      (forProposal ? ['creatives', 'contracts', 'contract', 'calcMinSpend'] : ['dates', 'isChanged']);
    if (removeDsId) omitProperties.push('dsId');
    return products
      .filter(product => !product.isPlaceholder)
      .map(packageProduct => {
        const { id, flights, flightConfigs, creatives, questionnaire, ...productWithoutId } = packageProduct;
        return pickBy(
          omit(
            {
              ...productWithoutId,
              ...(questionnaire
                ? {
                    questionnaire: questionnaire.map(q => {
                      return {
                        index: q.index,
                        questionnaireId: q.questionnaireId,
                        answer: q.answer,
                      };
                    }),
                  }
                : {}),
              ...(creatives
                ? {
                    creatives: creatives.map(cr => {
                      const { creatives, ...creative } = cr;
                      return { ...creative, children: creatives || [] };
                    }),
                  }
                : {}),
              ...(id && !this.tempIdTester.test(id) ? { id } : {}),
              ...(flights && flights.length
                ? {
                    flights: flights
                      .map(flight => {
                        const { id, demographics = [], audience = [], ...flightWithoutId } = flight;
                        const audienceSegments = [...(demographics || []), ...(audience || [])]
                          .map(sg => sg?.id || null)
                          .filter(Boolean);
                        const flightToReturn = {
                          ...flightWithoutId,
                          ...(audienceSegments ? { audienceSegments } : {}),
                          ...(id && !this.tempIdTester.test(id) ? { id: id } : {}),
                        };
                        delete flightToReturn.audience;
                        delete flightToReturn.demographics;
                        return flightToReturn;
                      })
                      .map(flight => pickBy(omit(flight, omitProperties), this.isNullCallBack)),
                  }
                : {}),
              ...(flightConfigs && flightConfigs.length
                ? {
                    flightConfigs: flightConfigs
                      .map(flight => {
                        const { id: flightConfigId, ...flightWithoutId } = flight;
                        return {
                          ...flightWithoutId,
                          ...(flightConfigId && !this.tempIdTester.test(id) ? { id: flightConfigId } : {}),
                        };
                      })
                      .map(flight => pickBy(flight, this.isNullCallBack)),
                  }
                : { flightConfigs: [] }),
            },
            omitProperties,
          ),
          this.isNullCallBack,
        );
      });
  }

  _setIsChangedFalse = product => ({ ...product, isChanged: false });

  _isFlightDefaultDemographics = flight => {
    /**
     * INFO: This is the BE-facing definition of a flight with default demographics.
     * Notice we expect audience segments to be an empty array coming from the BE, a flight with no
     * demographics and no geo data is considered to have default demographics. This is different to
     * how it works in the FE, where explicit `nulls` are required and set. If in the future, this
     * req needs to change for whatever reason, this is the place to do it.
     */
    return (
      (flight.audienceSegments.length === 0 || flight.audienceSegments === null) &&
      (flight.market === null || flight.market?.geoSelections === null)
    );
  };

  _prepareFlightSegments = flight => {
    const baseFlight = { ...flight, ...this._targetingSegments.getGroupSegments(flight.audienceSegments) };
    const isFlightDefaultDemographics = this._isFlightDefaultDemographics(baseFlight);
    if (isFlightDefaultDemographics) {
      // INFO: This is the FE facing definition of a flight with default demographics
      baseFlight.audienceSegments = null;
      if (baseFlight.audience !== undefined) baseFlight.audience = null;
      if (baseFlight.demographics !== undefined) baseFlight.demographics = null;
    }
    return baseFlight;
  };

  sortAndCleanProductAndFlights(proposal) {
    const newProposal = cloneDeep(proposal);
    newProposal.products = newProposal.products
      .map(this._setIsChangedFalse)
      .sort((a, b) => a.index - b.index)
      .map(product => {
        if (product?.flights) {
          return {
            ...product,
            flights: product.flights
              .map(this._prepareFlightSegments)
              .map(this._setIsChangedFalse)
              .sort((a, b) => a.index - b.index),
            creatives: product?.creatives?.map(this.setFlightIdsList).sort((a, b) => a.index - b.index),
          };
        }
        return product;
      });
    return newProposal;
  }

  productBudgetMax(productId: string, budget: number, products: (Product | Package)[]): number {
    // this should account for isLocked products
    const productIndex = products.findIndex(product => product.id === productId);
    if (productIndex === -1) return 0;

    const activeProduct = products[productIndex];

    if (this.isPackage(activeProduct)) return activeProduct.budget;

    const proposalBudget = parseInt(String(budget), 10);
    const lockedProductBudgets = [];
    const unlockedProductBudgets = [];
    const allOtherProducts = products.filter(product => product.id !== productId);
    allOtherProducts.forEach(product => {
      if (
        this.isPackage(product) ||
        (product.isLocked && product.id) ||
        this.xmlConfiguredProduct(product) ||
        this.smartMailerHasFlights(product)
      ) {
        return lockedProductBudgets.push(product.budget);
      }
      if (product.id) {
        const lockedFlightBudget = (product?.flights || [])
          .filter(fl => fl.isLocked)
          .reduce((acc, flight) => acc + flight.budget, 0);
        unlockedProductBudgets.push(Math.max(product.calcMinSpend || product.minSpend, lockedFlightBudget));
      }
    });
    const sumOfOtherProductMinimumsAndLocked = [...lockedProductBudgets, ...unlockedProductBudgets].reduce(
      (a, b) => a + b,
      0,
    );
    const proposalBudgetLessOtherProductSum = proposalBudget - sumOfOtherProductMinimumsAndLocked;

    return Math.max(proposalBudgetLessOtherProductSum, activeProduct.calcMinSpend || activeProduct.minSpend);
  }

  getCalcMinSpend(product: Product, duration = 1) {
    const minSpend = product?.minSpend || 0;
    if (product.isMonthly) return minSpend * duration;

    return minSpend;
  }

  getCalcRecommendedSpend(product: Product, duration = 1) {
    const recommendedBudget = product?.recommendedBudget || 0;
    if (product.isMonthly) return recommendedBudget * duration;

    return recommendedBudget;
  }

  setFlightIdsList = creative => {
    const { flights } = creative;
    if (flights && Array.isArray(flights)) return { ...creative, flightIds: flights.map(fl => fl.id) };

    return creative;
  };

  // move to backend
  updateEmailMarketingFlight(flight: ProductFlight, productConfig: ProductConfig): ProductFlight {
    const secondaryOptions = productConfig?.rateRangeList || [];
    const item = this.getMarketingItem(flight.budget, secondaryOptions);
    if (!item) {
      return {
        ...flight,
        targetingOption: null,
        rate: 0,
      };
    }
    return {
      ...flight,
      targetingOption: item?.adFormat,
      rate: item.PitchRate,
    };
  }

  getMarketingItem(budget, variants) {
    const parsedBudget = parseInt(budget, 10);

    return variants.find(el => {
      if (!el.PitchRangeEnd) {
        return parsedBudget >= el.PitchRangeStart;
      }
      return parsedBudget >= el.PitchRangeStart && parsedBudget < el.PitchRangeEnd;
    });
  }

  isProduct(item: Product | Package): item is Product {
    return item.category !== ProductConfigCategory.Package;
  }

  isPackage(item: Product | Package): item is Package {
    return item.category === ProductConfigCategory.Package;
  }

  isProductConfig(item: ProductConfig | PackageConfig): item is ProductConfig {
    return item.category !== ProductConfigCategory.Package;
  }

  isPackageConfig(item: ProductConfig | PackageConfig): item is PackageConfig {
    return item.category === ProductConfigCategory.Package;
  }

  filterProducts(item: (Product | Package)[]): Product[] {
    return item.filter(this.isProduct);
  }

  filterPackages(item: (Product | Package)[]): Package[] {
    return item.filter(this.isPackage);
  }

  filterProductConfigs(item: (ProductConfig | PackageConfig)[]): ProductConfig[] {
    return item.filter(this.isProductConfig);
  }

  filterPackageConfigs(item: (ProductConfig | PackageConfig)[]): PackageConfig[] {
    return item.filter(this.isPackageConfig);
  }
  newProductBudget(
    budgetObj: { min: number; allocated?: number },
    newObj: { budget: number; products: Product[] },
  ): number {
    const proposalBudget = newObj.budget;
    let totalAllocated = newObj.products.map(product => product.budget).reduce((a, b) => a + b, 0);
    if (budgetObj.allocated) {
      totalAllocated += budgetObj.allocated;
    }
    const remainder = proposalBudget - totalAllocated;
    return Math.max(remainder, budgetObj.min);
  }

  canAddNewProduct({
    products,
    minSpend = 0,
    productBudget = 0,
    solutionBudget = 0,
  }: {
    products: Product[];
    minSpend: number;
    solutionBudget: number;
    productBudget: number;
  }): boolean {
    const solutionBudgetMin = this.getMinSolutionBudget(products);
    return solutionBudget - (solutionBudgetMin - productBudget) >= minSpend;
  }

  getMinSolutionBudget(products: Product[]): number {
    const lockedProductBudgets = [];
    const unlockedProductBudgets = [];
    (products || []).forEach(product => {
      if (!product || !product.id) return;
      if (product.isLocked) {
        lockedProductBudgets.push(product.budget || 0);

        return;
      }
      unlockedProductBudgets.push(product.calcMinSpend || product.minSpend || 0);
    });
    const sumOfProductMinimumsAndLocked = [...lockedProductBudgets, ...unlockedProductBudgets].reduce(
      (a, b) => a + b,
      0,
    );
    return sumOfProductMinimumsAndLocked;
  }

  allProductsLocked(products: (Product | Package)[]): boolean {
    const isEmptyList = !products.length;
    if (isEmptyList) return false;
    const productsList = this.filterProducts(products);

    if (!productsList.length) return true; // if all products are Packages

    return productsList.every(product => {
      return product.isLocked || this.xmlConfiguredProduct(product) || this.smartMailerHasFlights(product);
    });
  }

  getUnallocatedBudget(products: (Product | Package)[], budget: number): number {
    const allocated = products
      .filter(p => p?.id)
      .map(p => p?.budget || 0)
      .reduce((a, b) => a + b, 0);
    return Math.max(budget - allocated, 0);
  }

  budgetIsDisabled(product: Product): boolean {
    return product.isLocked || this.smartMailerHasFlights(product) || this.xmlConfiguredProduct(product);
  }

  optional(value, name) {
    return !(value === undefined || value === null) ? { [name]: value } : {};
  }

  private _trackableProductSet = new Set([
    ProductConfigCategory.Digvid,
    ProductConfigCategory.Display,
    ProductConfigCategory.Video,
    ProductConfigCategory.Ott,
    ProductConfigCategory.Ctv,
  ]);

  isTrackableProduct(product: Product): boolean {
    return this._trackableProductSet.has(product.category);
  }

  isProductCreativesReadyToSubmit(product: Product | Package): boolean {
    if (this.isPackage(product)) return (product?.products || []).every(el => this.isProductCreativesReadyToSubmit(el));

    const creatives = product?.creatives || [];

    if (!creatives.length) return true;
    if (product.category !== ProductConfigCategory.Social) return creatives.every(c => !c.unsaved);

    return creatives.every(c => {
      const { selectedAdFormatList } = c;
      const adType = selectedAdFormatList?.[0] || null;
      return (
        (!c.unsaved && c.postText && c.headline && c.description && c.socialLink && c.url) ||
        adType?.toLowerCase()?.includes('carousel')
      );
    });
  }

  noneTargetingSupport(product: Product): boolean {
    return (
      this.isXMLProduct(product) ||
      this.isPaidSearchProduct(product) ||
      this.isGeofenceProduct(product) ||
      this.isEmailMarketingProduct(product)
    );
  }
}

import {
  AnyOutputElement,
  OutputImage,
  OutputProps,
  OutputShape,
  OutputSlide,
  OutputSnapshot,
  OutputText,
} from '@/shared/legacy/classes';
import { ActionTree } from 'vuex';
import { OutputState } from './types';
import { Result, Ok, Err } from '@sniptt/monads';

import clonedeep from 'lodash.clonedeep';
import { Container } from 'inversify';
import { Models, Services } from '@/injectables/tokens';
import { SlideModelContract, OutputServiceContract, LoggerContract, OutputModelContract } from '@/injectables';
import { RootState } from '../root/state';
import { Scalars, SlideVisibility } from '@/app/graphql';
import { SlideLibrarySource } from '@/shared/types';

export const actions = (container: Container): ActionTree<OutputState, RootState> => {
  const logger = container.get<LoggerContract>('logger');
  const outputService = container.get<OutputServiceContract>(Services.Output);
  const outputModel = container.get<OutputModelContract>(Models.Output);
  const slideModel = container.get<SlideModelContract>(Models.Slide);

  return {
    saveOutputSnapshot({ state, commit, getters }): OutputSnapshot {
      const newSnapshot = {
        list: clonedeep(getters.allLocalSlides),
        activeSlide: state?.activeSlideId || 's-100', // should this alternative be hardcoded?
      };
      const existingHistory = [...state.actionHistory.slice(state.actionHistoryIndex, 10)];
      const updatedHistory = [newSnapshot, ...existingHistory];
      commit('SET_ACTION_HISTORY', updatedHistory);
      commit('SET_ACTION_HISTORY_INDEX', 0);
      return newSnapshot;
    },

    async undoAction({ commit, state, getters, dispatch }): Promise<void> {
      if (!getters.canUndoAction) return;
      const newIndex = state.actionHistoryIndex + 1;
      const foundSnapshot = state.actionHistory[newIndex];
      if (foundSnapshot?.list) {
        const newSlides = clonedeep(foundSnapshot.list);
        dispatch('cloneOutput', newSlides).then(() => {
          if (foundSnapshot?.activeSlide) {
            commit('SET_ACTIVE_SLIDE', foundSnapshot.activeSlide);
          }
        });
        commit('SET_ACTION_HISTORY_INDEX', newIndex);
        commit('SET_OUTPUT_HAS_BEEN_CHANGED', true);
      }
    },

    redoAction({ commit, state, getters, dispatch }): void {
      if (!getters.canRedoAction) return;
      const newIndex = state.actionHistoryIndex - 1;
      const foundSnapshot = state.actionHistory[newIndex];
      if (foundSnapshot?.list) {
        const newSlides = clonedeep(foundSnapshot.list);
        dispatch('cloneOutput', newSlides).then(() => {
          if (foundSnapshot?.activeSlide) {
            commit('SET_ACTIVE_SLIDE', foundSnapshot.activeSlide);
          }
        });
        commit('SET_ACTION_HISTORY_INDEX', newIndex);
        commit('SET_OUTPUT_HAS_BEEN_CHANGED', true);
      }
    },

    resizeScreen({ commit }, width): void {
      commit('RESIZE_SCREEN', width);
    },

    updateSlideElement(
      { commit, state, getters, dispatch },
      { type, slideId, value }: { type: string; slideId: string; value: AnyOutputElement },
    ): void {
      if (!state.outputSlides || !Array.isArray(state.outputSlides) || !state.outputSlides?.length) return;

      const slideToUpdateIndex = state.outputSlides.findIndex(slide => slide._id === slideId);
      if (slideToUpdateIndex !== -1) {
        const likeElements = (state.outputSlides[slideToUpdateIndex] as OutputSlide)[type]
          ? [...getters.forceArray((state.outputSlides[slideToUpdateIndex] as OutputSlide)[type])]
          : [];
        const elementIndex = likeElements.findIndex(el => el.id === value.id);
        if (elementIndex !== -1) {
          likeElements[elementIndex] = { ...value, userModified: true };
          commit('UPDATE_SLIDE_ELEMENTS', { index: slideToUpdateIndex, type, array: likeElements });
          commit('SET_OUTPUT_HAS_BEEN_CHANGED', true);
          dispatch('saveOutputSnapshot');
        }
        // TODO: rethink this scenario, occasionally we want to fire this update request
        // while the element technically no longer exists.
        // else {
        //   const errorMessage = 'Unable to update element';

        //    logger.print('info','store.output.actions.updateSlideElement', errorMessage);
        //   dispatch('showSnackbar', { content: errorMessage, color: 'warning' }, { root: true });
        // }
      } else {
        const errorMessage = 'Unable to locate slide to update';
        logger.print('info', 'store.output.actions.updateSlideElement', errorMessage);
        dispatch('showSnackbar', { content: errorMessage, color: 'warning' }, { root: true });
      }
    },

    updateSlideElementsIndexes(
      { commit, state, dispatch },
      { slideId, newArr }: { slideId: string; newArr: AnyOutputElement[] },
    ): void {
      const slideToUpdateIndex = state.outputSlides.findIndex(slide => slide._id === slideId);
      if (slideToUpdateIndex !== -1) {
        const updatedShapes: OutputShape[] = [];
        const updatedTextItems: OutputText[] = [];
        const updatedImages: OutputImage[] = [];

        const isTextElement = (element: AnyOutputElement): element is OutputText => {
          return 'value' in element;
        };

        const isImageElement = (element: AnyOutputElement): element is OutputImage => {
          return 'path' in element;
        };

        newArr.forEach(el => {
          if (isTextElement(el)) updatedTextItems.push(el);
          else if (isImageElement(el)) updatedImages.push(el);
          else updatedShapes.push(el);
        });
        commit('UPDATE_SLIDE_ELEMENTS', { index: slideToUpdateIndex, type: 'shapes', array: updatedShapes });
        commit('UPDATE_SLIDE_ELEMENTS', { index: slideToUpdateIndex, type: 'images', array: updatedImages });
        commit('UPDATE_SLIDE_ELEMENTS', {
          index: slideToUpdateIndex,
          type: 'textItems',
          array: updatedTextItems,
        });
        commit('SET_OUTPUT_HAS_BEEN_CHANGED', true);
        dispatch('saveOutputSnapshot');
      } else {
        const errorMessage = 'Unable to locate slide to update';
        logger.print('info', 'store.output.actions.updateSlideElementsIndexes', errorMessage);
        dispatch('showSnackbar', { content: errorMessage, color: 'warning' }, { root: true });
      }
    },

    removeSlideElement(
      { commit, state, getters, dispatch },
      { type, slideId, elementId }: { type: string; slideId: string; elementId: string },
    ): void {
      const slideToUpdateIndex = state.outputSlides.findIndex(slide => slide._id === slideId);
      if (slideToUpdateIndex !== -1) {
        const likeElements = (state.outputSlides[slideToUpdateIndex] as OutputSlide)[type]
          ? [...getters.forceArray((state.outputSlides[slideToUpdateIndex] as OutputSlide)[type])]
          : [];
        const updatedElements = likeElements.filter(el => el.id !== elementId);
        if (likeElements.length !== updatedElements.length) {
          commit('UPDATE_SLIDE_ELEMENTS', { index: slideToUpdateIndex, type, array: updatedElements });
          commit('SET_OUTPUT_HAS_BEEN_CHANGED', true);
          dispatch('saveOutputSnapshot');
        } else {
          const errorMessage = 'Unable to remove element';
          logger.print('info', 'store.output.actions.removeSlideElement', errorMessage);
          dispatch('showSnackbar', { content: errorMessage, color: 'warning' }, { root: true });
        }
      } else {
        const errorMessage = 'Unable to locate slide to update';
        logger.print('info', 'store.output.actions.removeSlideElement', errorMessage);
        dispatch('showSnackbar', { content: errorMessage, color: 'warning' }, { root: true });
      }
    },

    addSlideElement(
      { commit, state, getters, dispatch },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      { type, slideId, value }: { type: string; slideId: string; value: any },
    ): void {
      const slideToUpdateIndex = state.outputSlides.findIndex(slide => slide._id === slideId);
      if (slideToUpdateIndex !== -1) {
        const likeElements = (state.outputSlides[slideToUpdateIndex] as OutputSlide)[type]
          ? [...getters.forceArray((state.outputSlides[slideToUpdateIndex] as OutputSlide)[type])]
          : [];
        likeElements.push(value);
        commit('UPDATE_SLIDE_ELEMENTS', { index: slideToUpdateIndex, type, array: likeElements });
        commit('SET_OUTPUT_HAS_BEEN_CHANGED', true);
        dispatch('saveOutputSnapshot');
      } else {
        const errorMessage = 'Unable to locate slide to update';
        logger.print('info', 'store.output.actions.addSlideElement', errorMessage);
        dispatch('showSnackbar', { content: errorMessage, color: 'warning' }, { root: true });
      }
    },

    toggleImageUpload({ commit }, status: boolean): void {
      commit('TOGGLE_IMAGE_UPLOAD', status);
    },

    updateActiveSlide({ commit }, id: string): void {
      commit('SET_ACTIVE_SLIDE', id);
    },

    setActiveElementType({ state, commit }, type: string): void {
      if (state.activeElementType !== type) {
        commit('SET_ACTIVE_ELEMENT_TYPE', type);
      }
    },

    setActiveElementId({ state, commit }, id: string): void {
      if (state.activeElementId !== id) {
        commit('SET_ACTIVE_ELEMENT_ID', id);
      }
    },

    async getAvailableLayouts(
      { commit },
      { proposalId, agency }: { proposalId?: string; agency?: string },
    ): Promise<any> {
      if (!proposalId && !agency) {
        throw new Error('Must provide either proposal id or agency');
      }

      commit('SET_GET_LAYOUTS_LOADING', true);

      const params = proposalId ? { proposalId } : { agencyId: agency };

      const { isErr, unwrapErr, unwrap } = await outputService.getLayouts(params);

      if (isErr()) {
        const { message } = unwrapErr();
        logger.print('error', 'store.output.actions.getAvailableLayouts', message);
        commit('SET_GET_LAYOUTS_LOADING', false);
        return [];
      }

      const layouts = unwrap();

      commit('SET_AVAILABLE_PROPOSAL_LAYOUTS', layouts);
      commit('SET_GET_LAYOUTS_LOADING', false);
      return layouts;
    },

    async getLayoutAdditionalInfo({ commit }, { layout, productId }) {
      const { unwrap, unwrapErr, isErr } = await outputService.getLayoutAdditionalInfo(layout, productId);

      if (isErr()) {
        const { message } = unwrapErr();
        logger.print('error', 'store.output.actions.getOutput', message);
        commit('SET_GET_OUTPUT_LOADING', false);
        return;
      }

      const { blanks = [], colors = {}, dynamicLinks = null, colorScheme } = unwrap();
      commit('SET_OUTPUT_LAYOUT', layout);
      commit('SET_LAYOUT_COLORS', colorScheme);
      commit('SET_LOCAL_LAYOUT_COLORS', colorScheme);
      commit('SET_OUTPUT_BLANKS', outputModel.flattenSlides(blanks));
      commit('SET_AVAILABLE_DYNAMICS', dynamicLinks);
      commit('SET_OUTPUT_COLORS', colors);
    },

    async saveLayoutColorsScheme({ state, commit }, colors) {
      const layout = state.activeOutput.layout;
      commit('SAVE_LAYOUT_COLORS', true);
      const { unwrapErr, isErr } = await outputService.setColorScheme(layout, colors);
      commit('SAVE_LAYOUT_COLORS', false);

      if (isErr()) {
        const { message } = unwrapErr();
        logger.print('error', 'store.output.actions.getOutput', message);
        return;
      }
      commit('SET_LAYOUT_COLORS', colors);
      commit('SET_LOCAL_LAYOUT_COLORS', colors);
    },

    async getOutput({ commit, dispatch }, proposalId = ''): Promise<Result<OutputSlide[], []>> {
      if (!proposalId) {
        throw new Error('Insufficient information for fetching output slides');
      }

      commit('SET_GET_OUTPUT_LOADING', true);
      commit('SET_LOCAL_OUTPUT', []);

      const { isErr, unwrapErr, unwrap } = await outputService.getProposalOutput(proposalId);
      commit('SET_GET_OUTPUT_LOADING', false);

      if (isErr()) {
        const { message } = unwrapErr();
        logger.print('error', 'store.output.actions.getOutput', message);
        return Err([]);
      }

      const data = unwrap();

      const sorted = data?.slides.sort((a, b) => a.order - b.order);
      commit('SET_ACTIVE_OUTPUT_SLIDES', sorted);
      commit('SET_OUTPUT_LAYOUT', data?.layoutId);
      commit('SET_OUTPUT_COLORS', data?.colors ?? []);
      commit('SET_OUTPUT_ID', data?.id ?? null);
      commit('SET_AVAILABLE_DYNAMICS', data?.dynamicLinks ?? null);
      commit('SET_LAYOUT_COLORS', data.colorScheme);
      commit('SET_LOCAL_LAYOUT_COLORS', data.colorScheme);

      dispatch('getProposalBlanks', { proposalId });

      dispatch('cloneOutput');
      commit('SET_GET_OUTPUT_LOADING', false);
      // return Ok(sorted);
    },

    async getProposalBlanks({ commit }, { proposalId = '' }): Promise<Result<OutputSlide[], []>> {
      if (!proposalId) {
        throw new Error('Insufficient information for fetching output slides');
      }
      commit('SET_GET_OUTPUT_LOADING', true);

      const { isErr, unwrapErr, unwrap } = await outputService.getProposalBlankSlides(proposalId);

      commit('SET_GET_OUTPUT_LOADING', false);

      if (isErr()) {
        const { message } = unwrapErr();
        logger.print('error', 'store.output.actions.getOutput', message);
        return Err([]);
      }

      const cleanBlankSlide = outputModel.flattenSlides(unwrap());

      commit('SET_OUTPUT_BLANKS', cleanBlankSlide ?? []);
    },

    async getTemplateLibrary(
      { commit, rootState, rootGetters, dispatch },
      {
        agency = rootGetters['client/activeClient']?.AgencyPartner || rootState.auth.user.Agency,
        layout = '',
      }: {
        agency?: string;
        layout?: string;
      },
    ): Promise<Result<any[], []>> {
      commit('SET_GET_OUTPUT_LOADING', true);

      const { isErr, unwrapErr, unwrap } = await outputService.getTemplateLibrary(layout, agency);

      if (isErr()) {
        const { message } = unwrapErr();
        logger.print('error', 'store.output.actions.getOutput', message);
        commit('SET_GET_OUTPUT_LOADING', false);
        return Err([]);
      }

      const { id, slides } = unwrap();

      commit('SET_OUTPUT_LAYOUT', layout);
      commit('SET_ACTIVE_OUTPUT_SLIDES', slides);
      if (agency) commit('SET_OUTPUT_AGENCY', agency);
      commit('SET_OUTPUT_ID', id);
      dispatch('cloneOutput');
      commit('SET_GET_OUTPUT_LOADING', false);
      return Ok(slides);
    },

    async getProductSlides(
      { commit, rootState, rootGetters, dispatch },
      {
        agency = rootGetters['client/activeClient']?.AgencyPartner || rootState.auth.user.Agency,
        layout = '',
        productConfigId,
      }: {
        agency?: string;
        layout?: string;
        productConfigId: string;
      },
    ): Promise<Result<boolean, boolean>> {
      commit('SET_GET_OUTPUT_LOADING', true);

      const { isErr, unwrapErr, unwrap } = await outputService.getProductSlides(layout, agency, productConfigId);

      if (isErr()) {
        const { message } = unwrapErr();
        logger.print('error', 'store.output.actions.getOutput', message);
        commit('SET_GET_OUTPUT_LOADING', false);
        return Err(false);
      }

      const { id, layoutId: layoutFromBE, slides } = unwrap();

      commit('SET_OUTPUT_LAYOUT', layoutFromBE);
      commit('SET_ACTIVE_OUTPUT_SLIDES', slides);
      if (agency) commit('SET_OUTPUT_AGENCY', agency);
      commit('SET_OUTPUT_ID', id);
      dispatch('cloneOutput');
      commit('SET_GET_OUTPUT_LOADING', false);
      return Ok(true);
    },

    async getPackageSlides(
      { commit, rootState, rootGetters, dispatch },
      {
        agency = rootGetters['client/activeClient']?.AgencyPartner || rootState.auth.user.Agency,
        layout = '',
        packageConfigId,
      }: {
        agency?: string;
        layout?: string;
        packageConfigId: string;
      },
    ): Promise<Result<boolean, boolean>> {
      commit('SET_GET_OUTPUT_LOADING', true);

      const { isErr, unwrapErr, unwrap } = await outputService.getPackageSlides(layout, agency, packageConfigId);

      if (isErr()) {
        const { message } = unwrapErr();
        logger.print('error', 'store.output.actions.getOutput', message);
        commit('SET_GET_OUTPUT_LOADING', false);
        return Err(false);
      }

      const { id, layoutId: layoutFromBE, slides } = unwrap();

      commit('SET_OUTPUT_LAYOUT', layoutFromBE);
      commit('SET_ACTIVE_OUTPUT_SLIDES', slides);
      if (agency) commit('SET_OUTPUT_AGENCY', agency);
      commit('SET_OUTPUT_ID', id);
      dispatch('cloneOutput');
      commit('SET_GET_OUTPUT_LOADING', false);
      return Ok(true);
    },

    async cloneOutput({ commit, state, dispatch }, slides = state.activeOutput?.slides || []): Promise<void> {
      const mapped = [...slides]
        .sort((a, b) => a.order - b.order)
        .map((slide, i) => {
          const order = (i + 1) * 100;
          return {
            ...slide,
            order,
            _id: `s-${order}`,
            ...(!slide.visibility ? { visibility: SlideVisibility.Visible } : {}),
          };
        });

      commit('SET_LOCAL_OUTPUT', mapped);
      if (!state.actionHistory.length) {
        dispatch('saveOutputSnapshot');
      }
    },

    newSlideOrder({ state, getters }, count = 1): number {
      const { activeSlideId } = state;
      const endSlideOrder = activeSlideId
        ? getters.validateNumber({
            value: activeSlideId.replace('s-', ''),
            context: 'OutputEditToolbar/newSlideOrder',
          })
        : 100;
      return endSlideOrder - count; // slides given new order after adding new slide
    },

    toggleSlideVisibility({ commit, dispatch }, { slide, status }: { slide: OutputSlide; status?: string }): void {
      const newStatus =
        status || (slide?.visibility === SlideVisibility.Hidden ? SlideVisibility.Visible : SlideVisibility.Hidden);
      const updatedSlide = {
        ...slide,
        visibility: newStatus,
        userModified: slide?.userModified || status === SlideVisibility.Deleted,
      };
      commit('UPDATE_SLIDE', updatedSlide);
      commit('SET_OUTPUT_HAS_BEEN_CHANGED', true);
      dispatch('saveOutputSnapshot');
    },

    addSlide({ commit, state, getters, dispatch }, newSlides: OutputSlide[]): void {
      const slides = [...state.outputSlides];

      slides.push(...newSlides);

      const mapped = slides
        .sort((a: OutputSlide, b: OutputSlide) => (a.order || 0) - (b.order || 0))
        .map((slide, i) => {
          const order = (i + 1) * 100;
          return {
            ...slide,
            order,
            _id: `s-${order}`,
          };
        });
      commit('SET_LOCAL_OUTPUT', mapped);
      commit('SET_OUTPUT_HAS_BEEN_CHANGED', true);
      const newActiveSlide = getters.allLocalSlides.find((slide: OutputSlide) => slide.name === newSlides[0].name);
      const newActiveId = newActiveSlide?._id || 's-100';
      commit('SET_ACTIVE_SLIDE', newActiveId);
      dispatch('saveOutputSnapshot');
    },

    setSingleSlide({ commit, dispatch }, slide: OutputSlide): void {
      const slides = [slide];
      commit('SET_LOCAL_OUTPUT', slides);
      commit('SET_OUTPUT_HAS_BEEN_CHANGED', true);
      dispatch('saveOutputSnapshot');
    },

    resetActionHistory({ commit }): void {
      commit('SET_ACTION_HISTORY', []);
      commit('SET_ACTION_HISTORY_INDEX', 0);
    },

    reorderSlides(
      { commit, dispatch },
      { slides, updateLocalSlides = false }: { slides: OutputSlide[]; updateLocalSlides?: boolean },
    ): OutputSlide[] {
      const mapped = slideModel.reorderSlides({ slides });

      if (updateLocalSlides) {
        commit('SET_LOCAL_OUTPUT', mapped);
        commit('SET_OUTPUT_HAS_BEEN_CHANGED', true);
        dispatch('saveOutputSnapshot');
      }
      return mapped;
    },

    clearOutputHasChanged({ commit }): void {
      commit('SET_OUTPUT_HAS_BEEN_CHANGED', false);
    },

    setCurrentTextProps({ commit }, elementProps: OutputProps): void {
      // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
      const { x, y, h, w, link, index, table, ...textProps } = elementProps;

      commit('SET_CURRENT_TEXT_PROPS', textProps);
    },

    deleteSlide(
      { state, commit, dispatch },
      { slide, isTemplate = false }: { slide: OutputSlide; isTemplate: boolean },
    ): void {
      const slideIndex = state.outputSlides.findIndex(s => s.name === slide.name);
      if (slideIndex !== -1) {
        if (slide._id === state.activeSlideId) {
          const visibleSlides = state.outputSlides.filter(s => s.visibility !== SlideVisibility.Deleted);
          const visibleIndex = visibleSlides.findIndex(s => s.name === slide.name);
          const newActiveId =
            (visibleIndex === 0 ? visibleSlides[visibleIndex + 1]?._id : visibleSlides[visibleIndex - 1]?._id) ||
            's-100';
          commit('SET_ACTIVE_SLIDE', newActiveId);
        }

        if (!isTemplate && (slide.fromLibrary || (slide.custom && !slide.isProduct))) {
          const filteredSlides = [...state.outputSlides].filter((s, i) => i !== slideIndex);
          commit('SET_LOCAL_OUTPUT', filteredSlides);
        } else {
          dispatch('toggleSlideVisibility', { slide, status: SlideVisibility.Deleted });
        }
        commit('SET_OUTPUT_HAS_BEEN_CHANGED', true);
        dispatch('saveOutputSnapshot');
      } else {
        logger.print('error', 'store.output.actions.deleteSlide', 'slide was not located in outputSlides');
        dispatch('showSnackbar', { content: 'Unable to delete slide', color: 'error' }, { root: true });
      }
    },

    resetOutputAndProposal(
      { dispatch },
      {
        routeName,
        validRoutes = ['proposalMarket', 'proposalSolutions', 'proposalFinalize', 'proposalSummary', 'proposalOutput'],
      }: { routeName: string; validRoutes: string[] },
    ): void {
      if (!validRoutes.includes(routeName)) {
        dispatch('resetActiveOutput');
        dispatch('resetLibrarySlides');
        dispatch('newProposal/resetNewProposal', {}, { root: true });
      }
    },

    resetActiveOutput({ commit }): void {
      const emptyOutput = {
        agency: '',
        slides: [],
        layout: '',
        blanks: [],
        colors: null,
        PropertyId: null,
      };
      commit('SET_CURRENT_TEXT_PROPS', null);
      commit('SET_ACTIVE_OUTPUT', emptyOutput);
      commit('SET_OUTPUT_ID', null);
      commit('SET_ACTION_HISTORY', []);
      commit('SET_ACTION_HISTORY_INDEX', 0);
      commit('SET_AVAILABLE_PROPOSAL_LAYOUTS', []);
      commit('SET_LOCAL_OUTPUT', []);
    },

    resetLibrarySlides({ commit }): void {
      commit('SET_SLIDES_LOADED', false);
      commit('SET_USER_LIBRARY_SLIDES', []);
      commit('SET_AGENCY_LIBRARY_SLIDES', { slides: [], agencyId: null });
    },

    async saveOutput(
      { commit, state, getters, dispatch },
      outputId = state.activeOutput.PropertyId,
    ): Promise<OutputSlide[]> {
      if (!state.outputSlides?.length) {
        throw new Error('Insufficient information for updating output');
      }

      commit('SET_OUTPUT_LOADING', true);
      const reorderedSlides = slideModel.reorderSlides({ slides: getters.allLocalSlides });

      const mapped = reorderedSlides.map(s => ({ ...s, unsavedChanges: true }));

      const { isErr, unwrapErr, unwrap } = await outputService.updateProposalSlides(outputId, mapped);

      if (isErr()) {
        const { message } = unwrapErr();
        logger.print('error', 'store.output.actions.saveOutput', message);
        commit('SET_OUTPUT_LOADING', false);
        return [];
      }

      const data = unwrap();

      const { slides } = data;

      if (!slides) {
        commit('SET_OUTPUT_LOADING', false);
        throw new Error('Received unexpected response from server');
      }

      commit('SET_ACTIVE_OUTPUT_SLIDES', slides);
      commit('SET_OUTPUT_HAS_BEEN_CHANGED', false);
      dispatch('cloneOutput');

      commit('SET_OUTPUT_LOADING', false);
    },

    async saveTemplateOutput(
      { commit, state, getters, dispatch },
      {
        agency,
        layout,
        libraryId = state.activeOutput.PropertyId,
      }: {
        agency: Scalars['UUID'];
        layout: string;
        libraryId?: Scalars['UUID'];
      },
    ): Promise<any[]> {
      if (!state.outputSlides?.length) {
        throw new Error('Insufficient information for updating output');
      }

      commit('SET_OUTPUT_LOADING', true);

      const reorderedSlides = slideModel.reorderSlides({
        slides: getters.allLocalSlides,
      });

      const mapped = reorderedSlides.map(s => ({ ...s, unsavedChanges: true }));

      const { isErr, unwrapErr, unwrap } = await outputService.updateTemplateSlides({
        agency,
        layout,
        slides: mapped,
        libraryId,
      });

      if (isErr()) {
        const { message } = unwrapErr();
        logger.print('error', 'store.output.actions.saveOutput', message);
        commit('SET_OUTPUT_LOADING', false);
        return [];
      }

      const data = unwrap();

      const { id = '', slides } = data;

      if (!slides) {
        commit('SET_OUTPUT_LOADING', false);
        throw new Error('Received unexpected response from server');
      }
      commit('SET_OUTPUT_ID', id);
      commit('SET_ACTIVE_OUTPUT_SLIDES', slides);
      commit('SET_OUTPUT_HAS_BEEN_CHANGED', false);
      dispatch('cloneOutput');

      commit('SET_OUTPUT_LOADING', false);
      return slides;
    },

    async saveProductOutput(
      { commit, state, getters, dispatch },
      {
        layout,
        productConfigId,
      }: {
        layout: string;
        productConfigId: Scalars['UUID'];
      },
    ): Promise<any[]> {
      if (!state.outputSlides?.length) {
        throw new Error('Insufficient information for updating output');
      }

      commit('SET_OUTPUT_LOADING', true);

      const reorderedSlides = slideModel.reorderSlides({ slides: getters.allLocalSlides });

      const mapped = reorderedSlides.map(s => ({ ...s, unsavedChanges: true }));

      const { isErr, unwrapErr, unwrap } = await outputService.updateProductSlides({
        productConfigId,
        layout,
        slides: mapped,
      });

      if (isErr()) {
        const { message } = unwrapErr();
        logger.print('error', 'store.output.actions.saveOutput', message);
        commit('SET_OUTPUT_LOADING', false);
        return [];
      }

      const data = unwrap();

      const { id = '', slides } = data;

      if (!slides) {
        commit('SET_OUTPUT_LOADING', false);
        throw new Error('Received unexpected response from server');
      }
      commit('SET_OUTPUT_ID', id);
      commit('SET_ACTIVE_OUTPUT_SLIDES', slides);
      commit('SET_OUTPUT_HAS_BEEN_CHANGED', false);
      dispatch('cloneOutput');

      commit('SET_OUTPUT_LOADING', false);
      return slides;
    },

    async saveProductOutputToChildren(
      { commit, state, getters, dispatch },
      {
        layout,
        productConfigId,
        childAgencyIds,
        agencyId,
      }: {
        layout: string;
        productConfigId: Scalars['UUID'];
        childAgencyIds: Scalars['UUID'][];
        agencyId: Scalars['UUID'];
      },
    ): Promise<any[]> {
      if (!state.outputSlides?.length) {
        throw new Error('Insufficient information for updating output');
      }

      commit('SET_OUTPUT_LOADING', true);

      const reorderedSlides = slideModel.reorderSlides({ slides: getters.allLocalSlides });

      const mapped = reorderedSlides.map(s => ({ ...s, unsavedChanges: true }));

      const { isErr, unwrapErr, unwrap } = await outputService.updateProductSlidesWithChildren({
        productConfigId,
        layout,
        slides: mapped,
        agencyId,
        childAgencyIds,
      });

      if (isErr()) {
        const { message } = unwrapErr();
        logger.print('error', 'store.output.actions.saveOutput', message);
        commit('SET_OUTPUT_LOADING', false);
        return [];
      }

      const data = unwrap();

      const { id = '', slides } = data;

      if (!slides) {
        commit('SET_OUTPUT_LOADING', false);
        throw new Error('Received unexpected response from server');
      }
      commit('SET_OUTPUT_ID', id);
      commit('SET_ACTIVE_OUTPUT_SLIDES', slides);
      commit('SET_OUTPUT_HAS_BEEN_CHANGED', false);
      dispatch('cloneOutput');

      commit('SET_OUTPUT_LOADING', false);
      return slides;
    },

    async savePackageOutput(
      { commit, state, getters, dispatch },
      {
        layout,
        packageConfigId,
      }: {
        layout: string;
        packageConfigId: Scalars['UUID'];
      },
    ): Promise<any[]> {
      if (!state.outputSlides?.length) {
        throw new Error('Insufficient information for updating output');
      }

      commit('SET_OUTPUT_LOADING', true);

      const reorderedSlides = slideModel.reorderSlides({ slides: getters.allLocalSlides });

      const mapped = reorderedSlides.map(s => ({ ...s, unsavedChanges: true }));

      const { isErr, unwrapErr, unwrap } = await outputService.updatePackageSlides({
        packageConfigId,
        layout,
        slides: mapped,
      });

      if (isErr()) {
        const { message } = unwrapErr();
        logger.print('error', 'store.output.actions.saveOutput', message);
        commit('SET_OUTPUT_LOADING', false);
        return [];
      }

      const data = unwrap();

      const { id = '', slides } = data;

      if (!slides) {
        commit('SET_OUTPUT_LOADING', false);
        throw new Error('Received unexpected response from server');
      }
      commit('SET_OUTPUT_ID', id);
      commit('SET_ACTIVE_OUTPUT_SLIDES', slides);
      commit('SET_OUTPUT_HAS_BEEN_CHANGED', false);
      dispatch('cloneOutput');

      commit('SET_OUTPUT_LOADING', false);
      return slides;
    },

    async updateProposalLayout(
      { commit, dispatch },
      { proposalId, layout }: { layout: string; proposalId: Scalars['UUID'] },
    ): Promise<any | null> {
      if (!layout || !proposalId) {
        logger.print('error', 'store.output.actions.updateLayout', 'Insufficient information for updating layout');
        return null;
      }
      commit('SET_OUTPUT_LOADING', true);

      const { isErr, unwrapErr, unwrap } = await outputService.updateProposalLayout(proposalId, layout);
      if (isErr()) {
        const { message } = unwrapErr();
        logger.print('error', 'store.output.actions.updateLayout', message);
        commit('SET_OUTPUT_LOADING', false);
        return null;
      }
      const output = unwrap();
      const { slides = [], colors, colorScheme, layoutId: updatedLayout = 'generic_layout' } = output;
      const sorted = slides.sort((a, b) => (a.order || 0) - (b.order || 0));
      commit('SET_OUTPUT_ID', output.id);
      commit('SET_ACTIVE_OUTPUT_SLIDES', sorted);
      commit('SET_OUTPUT_LAYOUT', updatedLayout);

      dispatch('getProposalBlanks', { proposalId });
      if (colors) commit('SET_OUTPUT_COLORS', colors);
      commit('SET_LAYOUT_COLORS', colorScheme);
      commit('SET_LOCAL_LAYOUT_COLORS', colorScheme);
      dispatch('cloneOutput');
      commit('SET_OUTPUT_LOADING', false);
      return output;
    },

    async addSlideToLibrary(
      { commit, dispatch },
      { agencyId, slide, targetLibrary = 'user' }: { agencyId: string; slide: OutputSlide; targetLibrary: string },
    ): Promise<any | null> {
      if (!agencyId || !slide) {
        logger.print(
          'error',
          'store.output.actions.addSlideToLibrary',
          'Insufficient information for adding library slide',
        );
        return null;
      }
      commit('SET_OUTPUT_LOADING', true);
      const isAgencySlide = ['both', 'agency'].includes(targetLibrary);

      const isShared = targetLibrary === 'shared';

      const { isErr, unwrapErr, unwrap } = await outputService.addSlideToLibrary(slide, agencyId, {
        isAgencySlide,
        isShared,
      });

      if (isErr()) {
        const { message } = unwrapErr();
        logger.print('error', 'store.output.actions.addSlideToLibrary', message);
        commit('SET_OUTPUT_LOADING', false);
        return null;
      }

      const output = unwrap();

      if (targetLibrary === 'both') await dispatch('addSlideToLibrary', { agencyId, slide, targetLibrary: 'user' });
      commit('SET_OUTPUT_LOADING', false);
      return output;
    },

    async getLibrarySlides(
      { commit, state, rootState },
      { agencyId = rootState.auth.user.Agency, layout, force = false },
    ): Promise<any[]> {
      if (state.librarySlides.loaded && !force) return [...state.librarySlides.user, ...state.librarySlides.agency];
      commit('GET_LIBRARY_SLIDES_LOADING', true);
      const { isErr, unwrapErr, unwrap } = await outputService.getSlideLibrary(agencyId, layout);

      if (isErr()) {
        const { message } = unwrapErr();
        logger.print('error', 'store.output.actions.getLibrarySlides', message);
        commit('GET_LIBRARY_SLIDES_LOADING', false);
        return [];
      }

      const { userLibrary, agencyLibrary, sharedLibrary } = unwrap();

      const sortedUserSlides = outputModel.sortSlidesByName(userLibrary || []);

      const sortedAgencySlides = outputModel.sortSlidesByName(agencyLibrary || []);

      const sortedSharedSlides = outputModel.sortSlidesByName(sharedLibrary || []);

      commit('SET_SLIDES_LOADED', true);

      commit('SET_USER_LIBRARY_SLIDES', sortedUserSlides);

      commit('SET_AGENCY_LIBRARY_SLIDES', { slides: sortedAgencySlides, agencyId });

      commit('SET_SHARED_LIBRARY_SLIDES', sortedSharedSlides);

      commit('GET_LIBRARY_SLIDES_LOADING', false);
    },

    async getProductLibrarySlides(
      { commit, rootState, getters },
      { agencyId = rootState.auth.user.Agency, layout },
    ): Promise<any[]> {
      commit('SET_PRODUCT_LIBRARY_SLIDES_LOADING', true);
      const { isErr, unwrapErr, unwrap } = await outputService.getProductSlideLibrary(agencyId, layout);

      if (isErr()) {
        const { message } = unwrapErr();
        logger.print('error', 'store.output.actions.getLibrarySlides', message);
        commit('GET_LIBRARY_SLIDES_LOADING', false);
        return [];
      }

      const slides = unwrap();
      const sortedSlides = slides
        .sort((a, b) => a.productName.localeCompare(b.productName) || a.layoutId?.localeCompare(b.layoutId))
        .map(library => ({
          ...library,
          slides: library.slides.sort((a, b) => a.order - b.order),
        }));

      const flatLibrary = sortedSlides
        .map(el =>
          el.slides
            .filter(slide => !getters.isEmptySlide(slide))
            .map((slide, i, arr) => ({
              ...slide,
              productOrPackageConfigId: el.productOrPackageConfigId || null,
              label: arr.length === 1 ? el.productName : `${el.productName}&${i + 1}`,
              productSlideLabel: slide?.label,
            })),
        )
        .flat();

      commit('SET_PRODUCT_LIBRARY_SLIDES', flatLibrary);
      commit('SET_PRODUCT_LIBRARY_SLIDES_LOADING', false);

      return slides;
    },
    async fetchLibrarySlide(
      { commit, dispatch, rootState },
      { agencyId = rootState.auth.user.Agency, slideId },
    ): Promise<undefined> {
      commit('SET_GET_OUTPUT_LOADING', true);
      const { isErr, unwrapErr, unwrap } = await outputService.getLibrarySlide({ slideId, agencyId });

      if (isErr()) {
        const { message } = unwrapErr();
        logger.print('error', 'store.output.actions.fetchLibrarySlide', message);
        commit('SET_GET_OUTPUT_LOADING', false);
        return;
      }
      commit('SET_GET_OUTPUT_LOADING', false);

      const { slides = [], dynamicLinks = null, colors = {} } = unwrap() || {};

      commit('SET_ACTIVE_OUTPUT_SLIDES', slides);
      commit('SET_AVAILABLE_DYNAMICS', dynamicLinks);
      commit('SET_OUTPUT_COLORS', colors);
      dispatch('cloneOutput');
    },
    async fetchBlankSlide(
      { commit, dispatch, rootState },
      { agencyId = rootState.auth.user.Agency, slideId },
    ): Promise<undefined> {
      commit('SET_GET_OUTPUT_LOADING', true);
      const { isErr, unwrapErr, unwrap } = await outputService.getBlankSlide({ slideId, agencyId });

      if (isErr()) {
        const { message } = unwrapErr();
        logger.print('error', 'store.output.actions.fetchLibrarySlide', message);
        commit('SET_GET_OUTPUT_LOADING', false);
        return;
      }
      commit('SET_GET_OUTPUT_LOADING', false);

      const { slides = [] } = unwrap() || {};

      commit('SET_ACTIVE_OUTPUT_SLIDES', slides);
      dispatch('cloneOutput');
    },
    async addBlankSlide({ commit }, { slide }: { agencyId: string; slide: any }): Promise<any | null> {
      if (!slide) {
        logger.print(
          'error',
          'store.output.actions.addSlideToLibrary',
          'Insufficient information for adding library slide',
        );
        return null;
      }
      commit('SET_OUTPUT_LOADING', true);

      const { isErr, unwrapErr, unwrap } = await outputService.createBlankSlide([slide]);

      if (isErr()) {
        const { message } = unwrapErr();
        logger.print('error', 'store.output.actions.addSlideToLibrary', message);
        commit('SET_OUTPUT_LOADING', false);
        return null;
      }
      const output = unwrap();

      commit('SET_OUTPUT_LOADING', false);
      return output.slides;
    },
    async updateBlankSlide({ commit }, { slides, slideId }: { slides: any[]; slideId: string }): Promise<void> {
      if (!slides) {
        logger.print(
          'error',
          'store.output.actions.updateLibrarySlide',
          'Insufficient information for updating library slide',
        );
        return;
      }
      commit('SET_OUTPUT_LOADING', true);

      const { isErr, unwrapErr } = await outputService.updateBlankSlide(slideId, slides);

      if (isErr()) {
        const { message } = unwrapErr();
        logger.print('error', 'store.output.actions.updateLibrarySlide', message);
        commit('SET_OUTPUT_LOADING', false);
        return;
      }
      commit('SET_OUTPUT_LOADING', false);
      commit('SET_OUTPUT_HAS_BEEN_CHANGED', false);
    },
    async deleteBlankSlide({ commit }, { id }: { id: string }): Promise<boolean> {
      try {
        if (!id) {
          throw new Error('Insufficient information for deleting library slide');
        }
        commit('GET_LIBRARY_SLIDES_LOADING', true);
        const { isErr, unwrapErr } = await outputService.deleteBlankSlide(id);

        if (isErr()) {
          throw new Error(unwrapErr().message);
        }

        return true;
      } catch (err) {
        logger.print('error', 'store.output.actions.deleteLibrarySlide', err?.message || err);
      } finally {
        commit('GET_LIBRARY_SLIDES_LOADING', false);
      }
      return false;
    },
    async deleteLibrarySlide(
      { state, commit },
      { id, slideSource = SlideLibrarySource.USER }: { id: string; slideSource: SlideLibrarySource },
    ): Promise<boolean> {
      try {
        if (!id) {
          throw new Error('Insufficient information for deleting library slide');
        }
        commit('GET_LIBRARY_SLIDES_LOADING', true);
        const { isErr, unwrapErr } = await outputService.deleteSlideFromLibrary({
          slideId: id,
        });

        if (isErr()) {
          throw new Error(unwrapErr().message);
        }
        const updatedSlides = (state.librarySlides?.[slideSource] || []).filter(s => s?.slideLibraryId !== id);

        if (slideSource === SlideLibrarySource.AGENCY) {
          commit('SET_AGENCY_LIBRARY_SLIDES', { slides: updatedSlides });
        } else if (slideSource === SlideLibrarySource.USER) {
          commit('SET_USER_LIBRARY_SLIDES', updatedSlides);
        } else if (slideSource === SlideLibrarySource.SHARED) {
          commit('SET_SHARED_LIBRARY_SLIDES', updatedSlides);
        }

        return true;
      } catch (err) {
        logger.print('error', 'store.output.actions.deleteLibrarySlide', err?.message || err);
      } finally {
        commit('GET_LIBRARY_SLIDES_LOADING', false);
      }
      return false;
    },
    async deleteLibraryProductSlide(
      { state, commit },
      { id, productId }: { id: string; productId: SlideLibrarySource },
    ): Promise<boolean> {
      try {
        if (!id && !productId) {
          throw new Error('Insufficient information for deleting product library slide');
        }
        commit('SET_PRODUCT_LIBRARY_SLIDES_LOADING', true);
        const { isErr, unwrapErr } = await outputService.deleteProductSlide(id, productId);

        if (isErr()) {
          throw new Error(unwrapErr().message);
        }
        const updatedSlides = state.productLibrarySlides.filter(s => s?.id !== id);

        commit('SET_PRODUCT_LIBRARY_SLIDES', updatedSlides);
        commit('SET_PRODUCT_LIBRARY_SLIDES_LOADING', false);
        return true;
      } catch (err) {
        logger.print('error', 'store.output.actions.deleteProductSlide', err?.message || err);
      } finally {
        commit('SET_PRODUCT_LIBRARY_SLIDES_LOADING', false);
      }
      return false;
    },
    async updateLibrarySlide({ commit }, { slide }: { slide: OutputSlide }): Promise<void> {
      if (!slide) {
        logger.print(
          'error',
          'store.output.actions.updateLibrarySlide',
          'Insufficient information for updating library slide',
        );
        return;
      }
      commit('SET_OUTPUT_LOADING', true);

      const { isErr, unwrapErr } = await outputService.updateLibrarySlide(slide);

      if (isErr()) {
        const { message } = unwrapErr();
        logger.print('error', 'store.output.actions.updateLibrarySlide', message);
        commit('SET_OUTPUT_LOADING', false);
        return;
      }
      commit('SET_OUTPUT_LOADING', false);
      commit('SET_OUTPUT_HAS_BEEN_CHANGED', false);
    },
    // pit-1063
    async rebuildLibrarySlide({ commit, rootState }, slides): Promise<any[]> {
      if (!slides || !slides.length) {
        throw new Error('Insufficient information for updating library slide');
      }
      commit('SET_UPDATE_LIBRARY_SLIDE_LOADING', true);
      // pit-1063
      const proposalId = rootState.newProposal.newProposal.id;
      const { isErr, unwrapErr, unwrap } = await outputService.rebuildLibrarySlide({
        slides,
        proposalId,
      });
      commit('SET_UPDATE_LIBRARY_SLIDE_LOADING', false);

      if (isErr()) {
        throw new Error(unwrapErr().message);
      }
      return unwrap();
    },
    async resetOutput({ commit }, { proposalId }: { proposalId: string }): Promise<boolean> {
      try {
        if (!proposalId) {
          throw new Error('Insufficient information for resetting output');
        }
        commit('SET_OUTPUT_LOADING', true);
        const { isErr, unwrapErr } = await outputService.resetOutput(proposalId);
        if (isErr()) {
          throw new Error(unwrapErr().message);
        }
        return true;
      } catch (err) {
        logger.print('error', 'store.output.actions.resetOutput', err);
        throw new Error('Unable to reset output. Check console');
      } finally {
        commit('SET_OUTPUT_LOADING', false);
      }
    },
  };
};

import { render, staticRenderFns } from "./Campaign.vue?vue&type=template&id=05894356&scoped=true"
import script from "./Campaign.vue?vue&type=script&lang=ts"
export * from "./Campaign.vue?vue&type=script&lang=ts"
import style0 from "./Campaign.vue?vue&type=style&index=0&id=05894356&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05894356",
  null
  
)

export default component.exports
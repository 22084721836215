import 'reflect-metadata';
import { TaxonomyServiceContract } from '@/injectables';
import { injectable } from 'inversify';

@injectable()
export class TaxonomyService implements TaxonomyServiceContract {
  getTitle(cat: string): string {
    return cat.split('^').pop() || '';
  }

  fullTitle(cat: string): string {
    return cat.split('^').join('\xa0\xa0>\xa0\xa0');
  }
}

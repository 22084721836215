
import Vue from 'vue';
export default Vue.extend({
  name: 'ProductStatus',

  props: {
    header: {
      type: String,
      default: '',
    },
  },
});

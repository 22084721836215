import 'reflect-metadata';

import { Err, Ok, Result } from '@sniptt/monads';
import { Failure } from '@/injectables/failure';

import { injectable } from 'inversify';

import { PackageService as RestPackageService } from './http';

import { PackageServiceContract } from '../contracts';
import {
  CreatePackageConfigMutation,
  CreatePackageConfigMutationVariables,
  CreatePackageConfigDocument,
} from '../graphql/mutations/create-package-config.generated';
import {
  UpdatePackageConfigDocument,
  UpdatePackageConfigMutation,
  UpdatePackageConfigMutationVariables,
} from '../graphql/mutations/update-package-config.generated';
import {
  GetPackageConfigByIdDocument,
  GetPackageConfigByIdQuery,
  GetPackageConfigByIdQueryVariables,
} from '../graphql/queries/get-package-by-id.generated';
import { Scalars } from '@/app/graphql';
import {
  TogglePackageVisibilityDocument,
  TogglePackageVisibilityMutation,
  TogglePackageVisibilityMutationVariables,
} from '../graphql/mutations/toggle-package-visibility.generated';
@injectable()
export class PackageService extends RestPackageService implements PackageServiceContract {
  async createPackage(settings): Promise<Result<CreatePackageConfigMutation['package'], Failure>> {
    try {
      const { data, errors } = await this._apollo.mutate<
        CreatePackageConfigMutation,
        CreatePackageConfigMutationVariables
      >({
        mutation: CreatePackageConfigDocument,
        variables: {
          input: settings,
        },
      });

      if (errors) {
        return Err({ message: "Can't create package at this time" });
      }

      return Ok(data.package);
    } catch (error) {
      return Err({
        message: "Can't create package at this time",
      });
    }
  }

  async updatePackage(settings): Promise<Result<UpdatePackageConfigMutation['package'], Failure>> {
    try {
      const { data, errors } = await this._apollo.mutate<
        UpdatePackageConfigMutation,
        UpdatePackageConfigMutationVariables
      >({
        mutation: UpdatePackageConfigDocument,
        variables: {
          input: settings,
        },
      });

      if (errors) {
        return Err({ message: "Can't update package at this time" });
      }

      return Ok(data.package);
    } catch (error) {
      return Err({
        message: "Can't update package at this time",
      });
    }
  }

  async getPackageById(
    packageId: string,
  ): Promise<
    Result<Omit<GetPackageConfigByIdQuery['package'] | { productConfigId: string }, 'productConfig'>, Failure>
  > {
    try {
      const { data, error } = await this._apollo.query<GetPackageConfigByIdQuery, GetPackageConfigByIdQueryVariables>({
        query: GetPackageConfigByIdDocument,
        variables: {
          packageId,
        },
      });

      if (error) {
        return Err({ message: "Can't update package at this time" });
      }
      const fetchedPackage = data.package;

      return Ok({
        ...fetchedPackage,
        products: fetchedPackage.products.map(product => {
          const { productConfig, ...productWithoutProductConfig } = product;
          return { ...productWithoutProductConfig, productConfigId: productConfig?.id || '' };
        }),
      });
    } catch (error) {
      return Err({
        message: "Can't update package at this time",
      });
    }
  }

  async toggleHidePackage(
    packageId: Scalars['UUID'],
  ): Promise<Result<TogglePackageVisibilityMutation['package'], Failure>> {
    try {
      const { data, error } = await this._apollo.query<
        TogglePackageVisibilityMutation,
        TogglePackageVisibilityMutationVariables
      >({
        query: TogglePackageVisibilityDocument,
        variables: {
          input: {
            packageId,
          },
        },
      });

      if (error) {
        return Err({ message: "Can't update package at this time" });
      }

      return Ok(data.package);
    } catch (error) {
      return Err({
        message: "Can't update package at this time",
      });
    }
  }
}

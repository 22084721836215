import 'reflect-metadata';
import {
  TrackCreateProposalProps,
  TrackerContract,
  TrackProposalDownloadRequestedProps,
  TrackProposalLinkCopiedProps,
  TrackProposalStatusChangeProps,
  TrackUserLoginProps,
  LoggerContract,
  TrackOttAvailsProps,
  TrackGraphqlErrorProps,
  TrackKeywordPlannerProps,
  TrackInstantIoDefaultProps,
} from '@/injectables';
import { inject, injectable } from 'inversify';
import { proposalWithConversionTracking } from '@/injectables/integrations/tracker/implementations/prod';

@injectable()
export class LocalTracker implements TrackerContract {
  constructor(@inject('logger') private _logger: LoggerContract) {}

  trackGraphqlError: TrackGraphqlErrorProps = ({ httpCode, graphqlCode, graphqlMessage, userId }) => {
    const message = `HTTP error: ${httpCode}, ${graphqlCode}, ${graphqlMessage}, ${userId}`;
    this._logger.print('info', 'trackGraphqlError', message);
  };

  trackKeywordPlanner: TrackKeywordPlannerProps = ({ agency, createdAt, userId }) => {
    const message = `Keywords planner search triggered: ${agency}, ${createdAt}, ${userId}`;
    this._logger.print('info', 'trackKeywordPlanner', message);
  };

  trackOttAvails: TrackOttAvailsProps = ({ agency, createdAt, userId }) => {
    const message = `Ott Avails: ${agency}, ${createdAt}, ${userId}`;
    this._logger.print('info', 'trackOttAvails', message);
  };

  trackUserLogin: TrackUserLoginProps = ({ email, agency, id }) => {
    const message = `Track user login: ${email}, ${agency}, ${id}`;
    this._logger.print('info', 'trackUserLogin', message);
  };

  trackCreateProposal: TrackCreateProposalProps = ({
    userId,
    agency,
    proposalName,
    proposalId,
    clientName,
    goal,
    budget,
    createdAt,
  }) => {
    const message = `Proposal created: ${userId} ${agency} ${proposalName} ${proposalId} ${createdAt} ${clientName} ${goal} ${budget}`;
    this._logger.print('info', 'trackCreateProposal', message);
  };

  trackProposalStatusChange: TrackProposalStatusChangeProps = ({
    proposal,
    userId,
    updatedAt,
    from,
    to,
    clientCategory,
  }) => {
    const object = JSON.stringify(
      {
        ...proposalWithConversionTracking(proposal),
        from_status: from,
        to_status: to,
        user_date: updatedAt,
        distinct_id: userId,
        client_industry: clientCategory,
      },
      null,
      2,
    );
    const message = `Proposal status change triggered: ${object}`;
    this._logger.print('info', 'trackProposalStatusChange', message);
  };

  trackProposalDownloadRequested: TrackProposalDownloadRequestedProps = ({
    userId,
    type,
    proposalId,
    agency,
    createdAt,
  }) => {
    const message = `Download Requested triggered ${agency} ${userId} ${type} ${proposalId} ${createdAt}`;
    this._logger.print('info', 'trackProposalDownloadRequested', message);
  };

  trackProposalLinkCopied: TrackProposalLinkCopiedProps = ({ agency, proposalId, userId, createdAt }) => {
    const message = `Proposal Link Copied triggered: ${agency} ${userId} ${proposalId} ${createdAt}`;
    this._logger.print('info', 'trackProposalLinkCopied', message);
  };

  trackInstantIoPageVisited = () => {
    const message = `Instant IO page visited`;
    this._logger.print('info', 'trackInstantIoPageVisited', message);
  };

  trackInstantIoDownloaded: TrackInstantIoDefaultProps = ({ campaign }) => {
    const message = `Instant IO downloaded: ${JSON.stringify(campaign)}`;
    this._logger.print('info', 'trackInstantIoDownloaded', message);
  };

  trackInstantIoSaved: TrackInstantIoDefaultProps = ({ campaign }) => {
    const message = `Instant IO saved: ${JSON.stringify(campaign)}`;
    this._logger.print('info', 'trackInstantIoSaved', message);
  };

  accessTokenRefreshed = () => {
    this._logger.print('info', 'Access token refreshed');
  };

  registerSuperProperties = ({ user }) => {
    const message = `Super properties registered: ${JSON.stringify(user)}`;
    this._logger.print('info', 'registerSuperProperties', message);
  };

  trackInstantIoSubmitted: TrackInstantIoDefaultProps = ({ campaign }) => {
    const message = `Instant IO submitted: ${JSON.stringify(campaign)}`;
    this._logger.print('info', 'trackInstantIoSubmitted', message);
  };
}

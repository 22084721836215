import * as Types from '../../../../../app/graphql/_types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type FetchProposalStatusChartQueryVariables = Types.Exact<{
  start: Types.Scalars['Date'];
  end: Types.Scalars['Date'];
}>;

export type FetchProposalStatusChartQuery = {
  MonthList: Array<{
    Month: string;
    stats: Array<{ status: Types.MediaplannerProposalStatus; Revenue: number; Count: number } | null>;
  }>;
};

export const FetchProposalStatusChartDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchProposalStatusChart' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'start' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'end' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'MonthList' },
            name: { kind: 'Name', value: 'statusDataComponent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'start' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'end' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', alias: { kind: 'Name', value: 'Month' }, name: { kind: 'Name', value: 'month' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'stats' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'Revenue' },
                        name: { kind: 'Name', value: 'budget' },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'Count' },
                        name: { kind: 'Name', value: 'count' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchProposalStatusChartQuery, FetchProposalStatusChartQueryVariables>;

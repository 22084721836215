import 'reflect-metadata';
import { injectable } from 'inversify';
import merge from 'lodash.merge';
import { InterestListItem, ProposalDemographicsResponse } from '@/shared/types';

import { CensusModelContract, packAudienceArgs, unpackAudienceArgs } from '@/injectables';

@injectable()
export class CensusModel implements CensusModelContract {
  packAudience({
    enthusiastList,
    searchHistoryList,
    shoppingList,
    lifestyleList,
    occupationList,
    customList,
  }: packAudienceArgs): unpackAudienceArgs {
    const pack = (listObj = []) => (listObj || []).filter(e => e.isSelected).map(e => e.name);
    return {
      enthusiastList: pack(enthusiastList),
      searchHistoryList: pack(searchHistoryList),
      shoppingList: pack(shoppingList),
      lifestyleList: pack(lifestyleList),
      occupationList: pack(occupationList),
      customList: pack(customList),
    };
  }

  unpackAudience({
    enthusiastList,
    searchHistoryList,
    shoppingList,
    lifestyleList,
    occupationList,
    customList,
  }: unpackAudienceArgs): packAudienceArgs {
    const unpack = list => (list || []).map((name: string) => ({ isSelected: true, name }));
    return {
      enthusiastList: unpack(enthusiastList),
      searchHistoryList: unpack(searchHistoryList),
      shoppingList: unpack(shoppingList),
      lifestyleList: unpack(lifestyleList),
      occupationList: unpack(occupationList),
      customList: unpack(customList),
    };
  }

  prepareEntityNewData(allItems: string[], existingItems: InterestListItem[]): InterestListItem[] {
    const existing = existingItems.filter(i => i.isSelected).map(i => i.name);
    return allItems.map((item: string) => {
      const isSelected = existing.includes(item);
      return { name: item, isSelected };
    });
  }

  prepareDemographicsData(
    demoList: ProposalDemographicsResponse['list'],
    existingList: ProposalDemographicsResponse['list'],
  ): Array<{
    demo: string;
    list: {
      property: string;
      value: number;
    }[];
  }> {
    // we can't be sure received items have correct order
    return demoList.map(item => {
      const demoIndex = existingList.findIndex(el => el.demo === item.demo);
      return {
        demo: item.demo,
        list: item.list.map(listItem => {
          const listIndex = existingList[demoIndex].list.findIndex(el => el.property === listItem.property);
          return merge(existingList[demoIndex].list[listIndex], listItem);
        }),
      };
    });
  }
}


import Vue from 'vue';
import WrapperWithTooltip from '@/components/WrapperWithTooltip.vue';
import { Services } from '@/injectables/tokens';

export default Vue.extend({
  name: 'SegmentButton',

  useInjectable: [Services.UI],

  components: { WrapperWithTooltip },

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    segment: {
      type: Object,
      required: true,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
    formatNumber: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    formatName() {
      if (!this.formatNumber) return this.segment.name;
      return this.bigNumbersRange(this.segment.name);
    },
    bigNumberFormatter(number: number): string | number {
      return this[Services.UI].bigNumberFormatter(number);
    },
    bigNumbersRange(range: string) {
      const [start, end] = range.split('-');
      const firstValue = start ? start.replace(/\D+/g, '') : null;
      const secondValue = end ? end.replace(/\D+/g, '') : null;

      if (!firstValue && !secondValue) {
        return '-';
      }

      if (!secondValue && firstValue) {
        // firstValue is <number>+
        const [minimumValue] = firstValue.split('+');
        return `$${this.bigNumberFormatter(Number(minimumValue))}+`;
      }

      return `
        $${this.bigNumberFormatter(Number(firstValue))}
        - 
        $${this.bigNumberFormatter(Number(secondValue))}`;
    },
  },
});


import Vue from 'vue';

export default Vue.extend({
  name: 'OutlinedCard',

  props: {
    header: {
      type: String,
      default: '',
    },
  },
});


import Vue from 'vue';

const defaultOptions = {
  title: 'Are you sure?',
  body: '',
  confirmText: 'Yes',
  declineText: '',
  cancelText: 'No',
};

/**
 * Provides async dialog into wrapped part of component tree.
 */
export default Vue.extend({
  name: 'AsyncDialogProvider',

  provide() {
    return {
      /**
       * Consumer must inject this into component to use async dialog.
       *
       * Example:
       * ```js
       * export default {
       *   inject: ['$confirm'],
       * }
       * ```
       */
      $confirm: {
        show: (
          options: {
            title?: string;
            body?: string;
            confirmText?: string;
            declineText?: string;
            cancelText?: string;
          } = {},
        ): Promise<{ confirmed: boolean; declined: boolean; canceled: boolean }> => {
          this.open = true;
          this.options = {
            ...defaultOptions,
            ...options,
          };
          return new Promise(resolve => {
            this.resolvePromise = resolve;
          });
        },
      },
    };
  },

  data() {
    return {
      // reactive UI data
      open: false,
      options: {
        ...defaultOptions,
      },
      // internal private properties
      resolvePromise: undefined,
    };
  },

  methods: {
    _confirm() {
      this.open = false;
      this.resolvePromise({ confirmed: true, declined: false, canceled: false });
    },
    _decline() {
      this.open = false;
      this.resolvePromise({ confirmed: false, declined: true, canceled: false });
    },
    _cancel() {
      this.open = false;
      this.resolvePromise({ confirmed: false, declined: false, canceled: true });
    },
  },
});

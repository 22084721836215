import * as Types from '../../../../../app/graphql/_types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type AgencyActivityQueryVariables = Types.Exact<{
  start: Types.Scalars['Date'];
  end: Types.Scalars['Date'];
  isMonthly: Types.Scalars['Boolean'];
}>;

export type AgencyActivityQuery = {
  usageTrackerComponent: {
    DateList: Array<{
      Date: string;
      ByUserList: Array<{ User: string; Revenue: number; Count: number } | null>;
      ByAgencyList?: Array<{ Agency: string; Revenue: number; Count: number } | null> | null;
    } | null>;
    MonthList: Array<{
      Month: string;
      ByUserList: Array<{ User: string; Revenue: number; Count: number } | null>;
      ByAgencyList?: Array<{ Agency: string; Revenue: number; Count: number } | null> | null;
    } | null>;
  };
};

export const AgencyActivityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AgencyActivity' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'start' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'end' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'isMonthly' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'usageTrackerComponent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'start' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'end' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'DateList' },
                  name: { kind: 'Name', value: 'daily' },
                  directives: [
                    {
                      kind: 'Directive',
                      name: { kind: 'Name', value: 'skip' },
                      arguments: [
                        {
                          kind: 'Argument',
                          name: { kind: 'Name', value: 'if' },
                          value: { kind: 'Variable', name: { kind: 'Name', value: 'isMonthly' } },
                        },
                      ],
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', alias: { kind: 'Name', value: 'Date' }, name: { kind: 'Name', value: 'date' } },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'ByUserList' },
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              alias: { kind: 'Name', value: 'User' },
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              alias: { kind: 'Name', value: 'Revenue' },
                              name: { kind: 'Name', value: 'budget' },
                            },
                            {
                              kind: 'Field',
                              alias: { kind: 'Name', value: 'Count' },
                              name: { kind: 'Name', value: 'count' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'ByAgencyList' },
                        name: { kind: 'Name', value: 'agency' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              alias: { kind: 'Name', value: 'Agency' },
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              alias: { kind: 'Name', value: 'Revenue' },
                              name: { kind: 'Name', value: 'budget' },
                            },
                            {
                              kind: 'Field',
                              alias: { kind: 'Name', value: 'Count' },
                              name: { kind: 'Name', value: 'count' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'MonthList' },
                  name: { kind: 'Name', value: 'monthly' },
                  directives: [
                    {
                      kind: 'Directive',
                      name: { kind: 'Name', value: 'include' },
                      arguments: [
                        {
                          kind: 'Argument',
                          name: { kind: 'Name', value: 'if' },
                          value: { kind: 'Variable', name: { kind: 'Name', value: 'isMonthly' } },
                        },
                      ],
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', alias: { kind: 'Name', value: 'Month' }, name: { kind: 'Name', value: 'date' } },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'ByUserList' },
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              alias: { kind: 'Name', value: 'User' },
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              alias: { kind: 'Name', value: 'Revenue' },
                              name: { kind: 'Name', value: 'budget' },
                            },
                            {
                              kind: 'Field',
                              alias: { kind: 'Name', value: 'Count' },
                              name: { kind: 'Name', value: 'count' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'ByAgencyList' },
                        name: { kind: 'Name', value: 'agency' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              alias: { kind: 'Name', value: 'Agency' },
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              alias: { kind: 'Name', value: 'Revenue' },
                              name: { kind: 'Name', value: 'budget' },
                            },
                            {
                              kind: 'Field',
                              alias: { kind: 'Name', value: 'Count' },
                              name: { kind: 'Name', value: 'count' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AgencyActivityQuery, AgencyActivityQueryVariables>;

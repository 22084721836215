import 'reflect-metadata';
import { injectable } from 'inversify';

import { CampaignModelContract } from '@/injectables';
import { ConversionTracking } from '@/shared/types';

@injectable()
export class CampaignModel implements CampaignModelContract {
  getDefaultTrackingConfiguration(): ConversionTracking {
    return {
      visits: { trackVisits: false, website: '' },
      conversion: { trackConversion: false, websites: [] },
    };
  }
}

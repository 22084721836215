import { injectable } from 'inversify';
import { Err, Ok, Result } from '@sniptt/monads';
import { Failure } from '@/injectables/failure';

import { InstantIOServiceContract } from '@/injectables';
import { Service } from '@/injectables/service';
import {
  CreateInstantIoDocument,
  CreateInstantIoMutation,
  CreateInstantIoMutationVariables,
} from '../graphql/mutations/create-instant-io.generated';
import {
  UpdateInstantIoDocument,
  UpdateInstantIoMutation,
  UpdateInstantIoMutationVariables,
} from '../graphql/mutations/update-instant-io.generated';
import { omit } from 'lodash';
import { InstantCampaignOrderBy, InstantCampaignStatus, SortDirection } from '@/app/graphql';
import {
  GetInstantIoDocument,
  GetInstantIoQuery,
  GetInstantIoQueryVariables,
} from '../graphql/queries/get-instant-io.generated';
import {
  GetMediaplannerInstantCampaignsDocument,
  GetMediaplannerInstantCampaignsQuery,
  GetMediaplannerInstantCampaignsQueryVariables,
} from '../graphql/queries/get-insant-campaigns.generated';
import { arrayRangToObject } from '../../proposal/utils';
import {
  DeleteMediaplannerInstantCampaignDocument,
  DeleteMediaplannerInstantCampaignMutation,
  DeleteMediaplannerInstantCampaignMutationVariables,
} from '../graphql/mutations/delete-instant-io.generated';
import { SendIoDocument, SendIoQuery, SendIoQueryVariables } from '../graphql/queries/send-io.generated';
import {
  UpdateInstantStatusDocument,
  UpdateInstantStatusMutation,
  UpdateInstantStatusMutationVariables,
} from '../graphql/mutations/update-status.generated';
import {
  SendToPioDocument,
  SendToPioMutation,
  SendToPioMutationVariables,
} from '../graphql/mutations/send-to-pio.generated';
import {
  SetMediaplannerInstantCampaignCreatedByIdDocument,
  SetMediaplannerInstantCampaignCreatedByIdMutation,
  SetMediaplannerInstantCampaignCreatedByIdMutationVariables,
} from '../graphql/mutations/set-instant-io-created-by.generated';
import {
  DuplicateInstantIoDocument,
  DuplicateInstantIoMutation,
  DuplicateInstantIoMutationVariables,
} from '../graphql/mutations/duplicate-instant-io.generated';

@injectable()
export class InstantIOService extends Service implements InstantIOServiceContract {
  async updateOrCreateInstantIO(
    campaign,
    id,
  ): Promise<Result<CreateInstantIoMutation['createMediaplannerInstantCampaign'], Failure>> {
    if (!id) {
      return this.createInstantIo(campaign);
    }

    return this.updateInstantIo({ id, ...campaign });
  }

  async updateInstantIo(
    campaign: UpdateInstantIoMutationVariables['input'],
  ): Promise<Result<UpdateInstantIoMutation['updateMediaplannerInstantCampaign'], Failure>> {
    try {
      const cleanCampaign = omit(campaign, ['clientId']) as UpdateInstantIoMutationVariables['input'];
      const { data } = await this._apollo.mutate<UpdateInstantIoMutation, UpdateInstantIoMutationVariables>({
        mutation: UpdateInstantIoDocument,
        variables: {
          input: {
            ...cleanCampaign,
          },
        },
      });

      return Ok(data.updateMediaplannerInstantCampaign);
    } catch (error) {
      return Err({
        message: `Can't save instant io at this time. ${error}`,
      });
    }
  }

  async createInstantIo(
    campaign: CreateInstantIoMutationVariables['input'],
  ): Promise<Result<CreateInstantIoMutation['createMediaplannerInstantCampaign'], Failure>> {
    try {
      const { data } = await this._apollo.mutate<CreateInstantIoMutation, CreateInstantIoMutationVariables>({
        mutation: CreateInstantIoDocument,
        variables: {
          input: {
            ...campaign,
          },
        },
      });

      return Ok(data.createMediaplannerInstantCampaign);
    } catch (error) {
      return Err({
        message: `Can't save instant io at this time. ${error}`,
      });
    }
  }

  async getInstantIo(id: string): Promise<Result<GetInstantIoQuery['getMediaplannerInstantCampaign'], Failure>> {
    try {
      const { data, errors } = await this._apollo.query<GetInstantIoQuery, GetInstantIoQueryVariables>({
        query: GetInstantIoDocument,
        variables: {
          id,
        },
      });

      if (errors) {
        return Err({
          message: `Can't get instant io at this time.`,
        });
      }

      return Ok(data.getMediaplannerInstantCampaign);
    } catch (error) {
      return Err({
        message: `Can't get instant io at this time. ${error}`,
      });
    }
  }

  async getInstantCampaigns({
    limit,
    offset,
    desc,
    sortBy,
    searchTerm,
    filters,
    clientId,
  }): Promise<Result<GetMediaplannerInstantCampaignsQuery['getMediaplannerInstantCampaigns'], Failure>> {
    try {
      const mapper = {
        clientName: InstantCampaignOrderBy.ClientName,
        'client.name': InstantCampaignOrderBy.ClientName,
        name: InstantCampaignOrderBy.Name,
        updatedAgo: InstantCampaignOrderBy.UpdatedAt,
        budget: InstantCampaignOrderBy.Budget,
        startDate: InstantCampaignOrderBy.StartDate,
        endDate: InstantCampaignOrderBy.EndDate,
        'createdBy.email': InstantCampaignOrderBy.CreatedBy,
        status: InstantCampaignOrderBy.Status,
      };

      const statusFilterMapper = {
        Inprogress: InstantCampaignStatus.Inprogress,
        Sold: InstantCampaignStatus.Sold,
      };

      const parseStatuses = statuses => {
        if (!Array.isArray(statuses)) return [statusFilterMapper[statuses]];
        return statuses.map(status => statusFilterMapper[status]);
      };

      const { start, end, modified } = filters.dates || {};

      const { data } = await this._apollo.query<
        GetMediaplannerInstantCampaignsQuery,
        GetMediaplannerInstantCampaignsQueryVariables
      >({
        query: GetMediaplannerInstantCampaignsDocument,
        variables: {
          limit,
          offset,
          orderBy: mapper[sortBy] || InstantCampaignOrderBy.UpdatedAt,
          sortDirection: desc ? SortDirection.Desc : SortDirection.Asc,
          ...(searchTerm ? { searchKey: searchTerm } : {}),
          ...(start?.range?.length ? { startDateRange: arrayRangToObject(start.range) } : {}),
          ...(end?.range?.length ? { endDateRange: arrayRangToObject(end.range) } : {}),
          ...(modified?.range?.length ? { updateDateRange: arrayRangToObject(modified.range) } : {}),
          ...(filters?.status?.length ? { statuses: parseStatuses(filters.status) } : {}),
          ...(clientId ? { clientId } : {}),
        },
      });
      return Ok(data.getMediaplannerInstantCampaigns);
    } catch (error) {
      return Err({
        message: `Can't get instant io at this time. ${error}`,
      });
    }
  }

  async deleteInstantIo(
    id: string,
  ): Promise<Result<DeleteMediaplannerInstantCampaignMutation['deleteMediaplannerInstantCampaign'], Failure>> {
    try {
      const { data, errors } = await this._apollo.mutate<
        DeleteMediaplannerInstantCampaignMutation,
        DeleteMediaplannerInstantCampaignMutationVariables
      >({
        mutation: DeleteMediaplannerInstantCampaignDocument,
        variables: {
          id,
        },
      });

      if (errors) {
        return Err({
          message: `Can't delete instant io at this time.`,
        });
      }

      return Ok(data.deleteMediaplannerInstantCampaign);
    } catch (error) {
      return Err({
        message: `Can't delete instant io at this time. ${error}`,
      });
    }
  }

  async duplicateInstantIo(
    id: string,
  ): Promise<Result<DuplicateInstantIoMutation['duplicateMediaplannerInstantCampaign'], Failure>> {
    try {
      const { data, errors } = await this._apollo.mutate<
        DuplicateInstantIoMutation,
        DuplicateInstantIoMutationVariables
      >({
        mutation: DuplicateInstantIoDocument,
        variables: {
          id,
        },
      });

      if (errors) {
        return Err({
          message: `Can't duplicate instant io at this time.`,
        });
      }

      return Ok(data.duplicateMediaplannerInstantCampaign);
    } catch (error) {
      return Err({
        message: `Can't duplicate instant io at this time. ${error}`,
      });
    }
  }

  async sendIO(id: string): Promise<Result<SendIoQuery['sendIOinstantCampaignEmail'], Failure>> {
    try {
      const { data } = await this._apollo.query<SendIoQuery, SendIoQueryVariables>({
        query: SendIoDocument,
        variables: {
          id,
        },
      });
      return Ok(data.sendIOinstantCampaignEmail);
    } catch (error) {
      return Err({
        message: `Can't submit order at this time. ${error}`,
      });
    }
  }

  async updateStatus(
    id: string,
    status: InstantCampaignStatus,
  ): Promise<Result<UpdateInstantStatusMutation['setMediaplannerInstantCampaignStatus'], Failure>> {
    try {
      const { data } = await this._apollo.mutate<UpdateInstantStatusMutation, UpdateInstantStatusMutationVariables>({
        mutation: UpdateInstantStatusDocument,
        variables: {
          id,
          status,
        },
      });

      return Ok(data.setMediaplannerInstantCampaignStatus);
    } catch (error) {
      return Err({
        message: `Can't update instant io status at this time. ${error}`,
      });
    }
  }
  async sendOrder(id: string): Promise<Result<SendToPioMutation['sendInstantCampaignToPIO'], Failure>> {
    try {
      const { data } = await this._apollo.mutate<SendToPioMutation, SendToPioMutationVariables>({
        mutation: SendToPioDocument,
        variables: {
          id,
        },
      });

      return Ok(data.sendInstantCampaignToPIO);
    } catch (error) {
      return Err({
        message: `Can't update instant io status at this time. ${error}`,
      });
    }
  }

  async setCampaignCreatedBy({
    campaignId,
    userId,
  }: {
    campaignId: string;
    userId: string;
  }): Promise<Result<{ id: string }, Failure>> {
    try {
      const { data, errors } = await this._apollo.mutate<
        SetMediaplannerInstantCampaignCreatedByIdMutation,
        SetMediaplannerInstantCampaignCreatedByIdMutationVariables
      >({
        mutation: SetMediaplannerInstantCampaignCreatedByIdDocument,
        variables: {
          id: campaignId,
          userId,
        },
      });
      if (errors) {
        return Err({ message: "Can't update campaign at this time." });
      }
      return Ok(data.setMediaplannerInstantCampaignCreatedById);
    } catch (error) {
      return Err({ message: "Can't update campaign at this time." });
    }
  }
}

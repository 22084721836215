import 'reflect-metadata';
import { injectable } from 'inversify';
import { Ok, Result } from '@sniptt/monads';
import { Failure } from '@/injectables/failure';
import { DefaultAction, MediaPlannerNotification, NotificationsContract, Status } from '@/injectables';
import { Proposal } from '@/shared/types';

@injectable()
export class NotificationModel implements NotificationsContract {
  allNotifications({
    notifications = [],
    endingCampaigns,
  }: {
    notifications?: MediaPlannerNotification[];
    endingCampaigns?: Proposal[];
  }): Result<MediaPlannerNotification[], Failure & Array<MediaPlannerNotification>> {
    // temporary FE solution
    const mappedEndingCampaigns = this.endingCampaignNotifications(endingCampaigns);
    // should filter by expiry
    const mappedNotifications = [...notifications, ...mappedEndingCampaigns];

    return Ok(mappedNotifications);
  }

  endingCampaignNotifications(endingCampaigns: Proposal[]): Array<MediaPlannerNotification> {
    return endingCampaigns.map(({ id, name = 'Unnamed proposal', endDate, client }) => {
      return {
        id: id,
        expiry: endDate,
        message: `Ending soon: ${name}`,
        action: {
          type: 'router' as DefaultAction['type'],
          page: 'proposal summary',
          props: { clientId: client?.id, id },
        },
      };
    });
  }

  adminReviewNotifications({
    email,
    isAgencyAdmin = false,
    proposals,
  }: {
    email: string;
    isAgencyAdmin: boolean;
    proposals: Proposal[];
  }): Result<MediaPlannerNotification[], Failure & Array<MediaPlannerNotification>> {
    const proposalFilter = (p: Proposal): boolean => {
      const {
        createdBy: { email: createdByEmail },
        updatedBy: { email: updatedByEmail },
      } = p;

      const statusNotUpdatedByUser = updatedByEmail && updatedByEmail !== email;

      if (!isAgencyAdmin) {
        return createdByEmail === email && statusNotUpdatedByUser;
      }

      return [Status.SubmittedForReview].includes(p.status as Status) && statusNotUpdatedByUser;
    };

    const proposalMessage = ({ name, status }: { name: string; status: string }): string => {
      if ([Status.SubmittedForReview].includes(status as Status)) {
        return `${name} is ready for review!`;
      }
      if ([Status.Approved].includes(status as Status)) {
        return `${name} has been approved!`;
      }
      return `${name} needs some adjustments`;
    };

    return Ok(
      proposals.filter(proposalFilter).map(({ id, name = 'Unnamed proposal', endDate, client, status }) => {
        return {
          id,
          expiry: endDate,
          message: proposalMessage({ name, status }),
          action: {
            type: 'router' as DefaultAction['type'],
            page: [Status.Approved].includes(status as Status) ? 'proposal finalize' : 'proposal solutions',
            props: { clientId: client?.id, id },
            ...([Status.SubmittedForReview].includes(status as Status) && {
              triggerTargetStatus: Status.UnderReview,
            }),
          },
        };
      }),
    );
  }
}

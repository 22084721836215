import {
  UnsafeAny,
  Client,
  KeywordsGeo,
  ConfiguredCreative,
  ConfiguredProduct,
  KeywordSummary,
  ProductConfig,
  ProductGeoFence,
  Proposal,
  ProposalInfo,
  ProposalMarket,
  SemKeyword,
  GeoObj,
  ProductFlight,
  Product,
  Package,
  ConversionTracking,
} from '@/shared/types';
import merge from 'lodash.merge';
import { ActionTree } from 'vuex';
import clonedeep from 'lodash.clonedeep';
import { getProductByID } from '.';
import { NewProposalState } from './types';
import { Container } from 'inversify';
import {
  NewProposalServiceContract,
  NewProposalModelContract,
  CensusModelContract,
  ProposalServiceContract,
  KeywordsServiceContract,
  KeywordsModelContract,
  CreativeServiceContract,
  ProductServiceContract,
  LoggerContract,
  ProposalProductModelContract,
  GeoModelContract,
  TargetingSegmentsModelContract,
} from '@/injectables';
import { Models, Services } from '@/injectables/tokens';
import { MutationTypes } from './mutations';
import { MediaplannerProposalStatus, ProductConfigCategory, SegmentSubCategory } from '@/app/graphql';
import { CreateCreativeMutation } from '@/injectables/services/creative/graphql/mutations/create-creative.generated';
import { DeleteCreativeMutation } from '@/injectables/services/creative/graphql/mutations/delete-creative.generated';
import { UpdateCreativeMutation } from '@/injectables/services/creative/graphql/mutations/update-creative.generated';
import { UpdateProposalStatusMutation } from '@/injectables/services/proposal/graphql/mutations/update-proposal-status.generated';
import { isObject } from 'lodash';

// NOTE: we have to keep actions any for this version of vuex
// to appropriate type rootState and rootGetters
// see: https://github.com/vuejs/vuex/issues/1756
export const actions = (container: Container): ActionTree<NewProposalState, UnsafeAny> => {
  const ProposalProductModel = container.get<ProposalProductModelContract>(Models.ProposalProduct);
  const newProposalService = container.get<NewProposalServiceContract>(Services.NewProposal);
  const newProposalModel = container.get<NewProposalModelContract>(Models.NewProposal);
  const geoModel = container.get<GeoModelContract>(Models.Geo);
  const proposalService = container.get<ProposalServiceContract>(Services.Proposal);
  const censusEntity = container.get<CensusModelContract>(Models.Census);
  const keywordsService = container.get<KeywordsServiceContract>(Services.Keywords);
  const keywordsEntity = container.get<KeywordsModelContract>(Models.Keywords);
  const creativeService = container.get<CreativeServiceContract>(Services.Creative);
  const productService = container.get<ProductServiceContract>(Services.Product);
  const targetingSegmentsModel = container.get<TargetingSegmentsModelContract>(Models.TargetingSegments);
  const logger = container.get<LoggerContract>('logger');

  return {
    // newProposal Market
    setChangeStatusNotificationOptions({ commit }, payload: { value: boolean; path: string }) {
      commit(MutationTypes.SET_CHANGE_STATUS_NOTIFICATION_OPTIONS, payload);
    },

    setIsProposalMarketUpdated({ commit }, value: boolean): void {
      commit(MutationTypes.SET_IS_PROPOSAL_MARKET_UPDATED, value);
    },

    setMarketAddress({ commit }, address: { text: string; address: string; lat: number; lon: number }): void {
      commit(MutationTypes.SET_MARKET_ADDRESS, address);
    },

    setShouldRedrawPolygons({ commit }, status: boolean): void {
      commit(MutationTypes.SET_SHOULD_REDRAW_POLYGONS, status);
    },

    async toggleMarketDemographics(
      { rootState, dispatch },
      { demoName, listItem }: { demoName: string; listItem: string; productId?: string; sandbox?: boolean },
    ): Promise<void> {
      let demographics = null;
      demographics = clonedeep(rootState.product.OTTMarket.demographics);
      const demoIndex = demographics?.list.findIndex(item => item.demo === demoName);

      if (demoIndex !== -1) {
        const list = [...demographics.list[demoIndex].list];

        const listIndex = list.findIndex(item => item.property === listItem);

        if (listIndex !== -1) {
          list[listIndex].isSelected = !list[listIndex].isSelected;

          demographics.targeted = newProposalModel.calculateOTTTargetedPopulation({
            demoArray: demographics.list,
            total: demographics.totalPopulation,
          });

          dispatch('product/setDemographics', demographics, { root: true });
        }
      }
    },

    async updateMarketDemographics({ commit }, demographics): Promise<void> {
      commit(MutationTypes.SET_NEW_PROPOSAL_MARKET_DEMOGRAPHICS, demographics);
    },

    async updateMarketAudience({ commit }, audience): Promise<void> {
      commit(MutationTypes.SET_NEW_PROPOSAL_AUDIENCE, audience);
    },

    // TODO: @to-graphql, refactor
    async getCensus(
      { state, commit, dispatch },
      payload: {
        zips?: GeoObj[];
        cities?: GeoObj[];
        dmas?: GeoObj[];
        counties?: GeoObj[];
        states?: GeoObj[];
        congressionalDistricts?: GeoObj[];
      },
    ): Promise<void> {
      if (
        !payload.zips &&
        !payload.cities &&
        !payload.dmas &&
        !payload.counties &&
        !payload.states &&
        !payload.congressionalDistricts
      ) {
        throw new Error('Must first pass useable census param(s)');
      }
      try {
        commit(MutationTypes.SET_CENSUS_LOADING, true);
        const { isErr, unwrap, unwrapErr } = await newProposalService.getCensus(payload);

        if (isErr()) {
          const { message } = unwrapErr();
          if (message.includes('No matched locations')) {
            logger.print('error', 'store.newProposal.actions.getCensus', message);
          }

          // TODO: move this to error entities
          const formatMessage = (str: string): string => {
            if (str.length > 100) {
              return str.substring(0, 100) + '...';
            }
            return str;
          };

          dispatch('showSnackbar', { content: formatMessage(message), color: 'error' }, { root: true });
          return;
        }
        const data = unwrap();

        if (data?.demographics?.total && data?.demographics?.list && Array.isArray(data.demographics.list)) {
          const totalPopulation = data.demographics.total;
          const demoList = data.demographics.list;

          const targeted = newProposalModel.calculateTargetedPopulation({
            demoArray: demoList,
            total: totalPopulation,
            selectedDemographics: state.newProposal.demographics,
          });

          const mapDemoToCategory = (demo: string): string => {
            const demoMap = {
              Age: SegmentSubCategory.Age,
              Income: SegmentSubCategory.Income,
              Gender: SegmentSubCategory.Gender,
              Family: SegmentSubCategory.Family,
              Marital: SegmentSubCategory.Age,
              Home: SegmentSubCategory.Housing,
            };
            return demoMap[demo] || '';
          };

          const newDemographics = {
            totalPopulation,
            targeted: targeted,
            real: demoList,
            demoByCategory: demoList.map(({ demo, total }) => ({ demo, category: mapDemoToCategory(demo), total })),
            segmentDemoInfo: demoList.map(({ list }) => list).flat(),
          };
          commit(MutationTypes.SET_NEW_PROPOSAL_CENSUS, newDemographics);
        }
      } catch (err) {
        logger.print('error', 'store.newProposal.actions.getCensus', err);
        throw err;
      } finally {
        commit(MutationTypes.SET_CENSUS_LOADING, false);
      }
    },

    // TODO: @to-graphql, refactor
    // TODO: split action to two different ( sandbox: true and false)
    async getCensusOTTStandAlone(
      { commit, dispatch, rootState },
      payload: {
        zips?: GeoObj[];
        cities?: GeoObj[];
        dmas?: GeoObj[];
        counties?: GeoObj[];
        states?: GeoObj[];
        congressionalDistricts?: GeoObj[];
      },
    ): Promise<void> {
      if (
        !payload.zips &&
        !payload.cities &&
        !payload.dmas &&
        !payload.counties &&
        !payload.states &&
        !payload.congressionalDistricts
      ) {
        throw new Error('Must first pass useable census param(s)');
      }
      try {
        commit(MutationTypes.SET_CENSUS_LOADING, true);
        const { isErr, unwrap, unwrapErr } = await newProposalService.getCensus(payload);

        if (isErr()) {
          const { message } = unwrapErr();
          if (message.includes('No matched locations')) {
            logger.print('error', 'store.newProposal.actions.getCensus', message);
          }

          // TODO: move this to error entities
          const formatMessage = (str: string): string => {
            if (str.length > 100) {
              return str.substring(0, 100) + '...';
            }
            return str;
          };

          dispatch('showSnackbar', { content: formatMessage(message), color: 'error' }, { root: true });
          return;
        }
        const data = unwrap();
        const existingMarketData = rootState.product.OTTMarket;

        if (data?.demographics?.total && data?.demographics?.list && Array.isArray(data.demographics.list)) {
          const existingList = existingMarketData.demographics?.list?.length
            ? existingMarketData.demographics.list
            : [];

          const totalPopulation = data.demographics.total;
          const demoList = data.demographics.list;

          const updatedList = censusEntity.prepareDemographicsData(demoList, existingList);

          const targeted = newProposalModel.calculateOTTTargetedPopulation({
            demoArray: updatedList,
            total: totalPopulation,
          });

          const newDemographics = {
            totalPopulation,
            targeted,
            list: updatedList,
          };
          dispatch('product/setDemographics', newDemographics, { root: true });
        }

        const audienceAttr = {
          enthusiastList: {
            dispatchName: 'product/setEnthusiasts',
          },
          lifestyleList: {
            dispatchName: 'product/setLifestyle',
          },
          occupationList: {
            dispatchName: 'product/setOccupation',
          },
          searchHistoryList: {
            dispatchName: 'product/setSearchHistory',
          },
          shoppingList: {
            dispatchName: 'product/setShopping',
          },
          customList: {
            dispatchName: 'product/setCustom',
          },
        };
        Object.entries(audienceAttr).forEach(([key, { dispatchName }]) => {
          const list = data[key];
          if (list && Array.isArray(list)) {
            const newValue = censusEntity.prepareEntityNewData(list, existingMarketData[key]);
            dispatch(dispatchName, newValue, { root: true });
          }
        });
      } catch (err) {
        logger.print('error', 'store.newProposal.actions.getCensus', err);
        throw err;
      } finally {
        commit(MutationTypes.SET_CENSUS_LOADING, false);
      }
    },

    resetOTTDemographics({ dispatch, rootState }): void {
      const existingDemographics = rootState.product.OTTMarket.demographics;

      const updatedDemographics = {
        targeted: 0,
        totalPopulation: 0,
        list: existingDemographics.list.map(({ demo, list }) => ({
          demo,
          list: list.map(item => ({ ...item, value: 0 })),
        })),
      };
      dispatch('product/setDemographics', updatedDemographics, { root: true });
    },

    resetMarket({ commit, state }): void {
      commit(MutationTypes.SET_PROPOSAL_MARKET, { market: clonedeep(state.initialMarket) });
      commit(MutationTypes.SET_SHOULD_REDRAW_POLYGONS, true);
    },

    setProposalMarket(
      { commit },
      { market, initialMarket = false }: { market: ProposalMarket; initialMarket?: boolean },
    ): ProposalMarket {
      commit(MutationTypes.SET_PROPOSAL_MARKET, { market, initialMarket });
      return market;
    },

    async setClientDefaultMarket({ commit, dispatch, state, rootGetters }): Promise<Client | undefined> {
      try {
        commit(MutationTypes.SET_CLIENT_DEFAULTS_LOADING, true);
        const req = await dispatch(
          'client/updateClientDefault',
          {
            defaultName: 'market',
            defaultValue: { ...state.newProposal.market },
            clientId: rootGetters['client/activeClient'].id,
          },
          { root: true },
        );
        if (req && typeof req === 'object' && req.hasOwnProperty('PropertyId')) {
          dispatch('showSnackbar', { content: 'Market default has been saved', color: 'success' }, { root: true });
          return req;
        }
        dispatch('showSnackbar', { content: 'Unable to save market default', color: 'error' }, { root: true });
        throw new Error('Encountered an unexpected error while setting default market');
      } catch (err) {
        logger.print('error', 'store.newProposal.actions.setClientDefaultMarket', err);
      } finally {
        commit(MutationTypes.SET_CLIENT_DEFAULTS_LOADING, false);
      }
    },

    async setClientDefaultSolutions({ commit, dispatch, state, rootGetters }): Promise<Client | undefined> {
      try {
        commit(MutationTypes.SET_CLIENT_DEFAULTS_LOADING, true);
        const savedSolutions = {
          budget: state.newProposal.budget,
          ...(state.newProposal.startDate ? { startDate: state.newProposal.startDate } : null),
          ...(state.newProposal.endDate ? { endDate: state.newProposal.endDate } : null),
          ...(state.newProposal.goal?.length ? { goal: state.newProposal.goal } : null),
          productsList: [...state.newProposal.products].filter(product => product?.name),
        };
        const req = await dispatch(
          'client/updateClientDefault',
          {
            defaultName: 'solutions',
            defaultValue: savedSolutions,
            clientId: rootGetters['client/activeClient'].id,
          },
          { root: true },
        );
        if (req && typeof req === 'object' && req.hasOwnProperty('PropertyId')) {
          dispatch('showSnackbar', { content: 'Solutions default has been saved', color: 'success' }, { root: true });
          return req;
        }
        dispatch('showSnackbar', { content: 'Unable to save solutions default', color: 'error' }, { root: true });
        throw new Error('Encountered an unexpected error while setting default solutions');
      } catch (err) {
        logger.print('error', 'store.newProposal.actions.setClientDefaultSolutions', err);
      } finally {
        commit(MutationTypes.SET_CLIENT_DEFAULTS_LOADING, false);
      }
    },

    loadClientDefaultMarket({ rootGetters, dispatch, commit }): void {
      try {
        if (rootGetters['client/activeClient']?.Defaults?.market) {
          commit(MutationTypes.SET_CLIENT_DEFAULTS_LOADING, true);
          dispatch('setProposalMarket', {
            market: clonedeep(rootGetters['client/activeClient'].Defaults.market),
          }).then(() => commit(MutationTypes.SET_SHOULD_REDRAW_POLYGONS, true));
        }
      } catch (err) {
        logger.print('error', 'store.newProposal.actions.loadClientDefaultMarket', err);
        dispatch('showSnackbar', { content: 'Unable to retrieve default market', color: 'error' }, { root: true });
      } finally {
        commit(MutationTypes.SET_CLIENT_DEFAULTS_LOADING, false);
      }
    },

    setGeoSelections(
      { commit, dispatch, state },
      {
        target,
        targetArray,
        productId,
        flightId,
      }: {
        target: string;
        targetArray: GeoObj[];
        productId?: string;
        flightId?: string;
      },
    ) {
      const mapGeoSelectionName = (arrName: string, forProduct: boolean): string => {
        return forProduct ? geoModel.getGeoTypeList(arrName) : arrName;
      };

      const forProduct = !!(productId && flightId);

      if (forProduct) {
        const solutions = state.newProposal.products.map(s =>
          s.category === ProductConfigCategory.Package ? s.products : s,
        );

        const [product] = solutions
          .flat()
          .filter(p => p.id === productId)
          .filter(p => p.flights && p.flights.find(f => f.id === flightId));

        if (!product) {
          return logger.print('warning', `There is no product with id: ${productId}`);
        }

        const geoSelectionName = mapGeoSelectionName(target, forProduct);

        const foundFlight = clonedeep(product.flights.find(f => f.id === flightId));

        const geoSelections = clonedeep(foundFlight.market?.geoSelections || {});

        geoSelections[geoSelectionName] = targetArray;

        foundFlight.market = { ...(foundFlight?.market || {}), geoSelections };

        dispatch('updateExistingFlight', { productId, flight: foundFlight });

        return;
      }

      const geoSelections = clonedeep(state.newProposal.market?.geoSelections || {});

      const arrayToUpdateName = mapGeoSelectionName(target, forProduct);

      geoSelections[arrayToUpdateName] = targetArray;

      commit(MutationTypes.SET_NEW_PROPOSAL_MARKET_GEO_SELECTIONS, geoSelections);
    },

    // newProposal
    setAwaitBudgetCheckLoading({ commit }, status: boolean): void {
      commit(MutationTypes.SET_AWAIT_BUDGET_CHECK_LOADING, status);
    },

    resetNewProposal({ commit }): void {
      commit(MutationTypes.RESET_NEW_PROPOSAL);
    },

    // TODO: @to-graphql, refactor
    async setMarketData(
      { state, commit, rootState, dispatch },
      { proposalId = state.newProposal?.id, init = false },
    ): Promise<{ proposalId: Proposal['id'] } | null> {
      try {
        const rawMarket = state.newProposal.market;
        const demographics = state.newProposal.demographics;
        const audience = state.newProposal.audience;
        const geoSelections = rootState.client.activeClientGeoSelections;
        const audienceSegments = [...demographics, ...audience].filter(isObject).map(segment => segment.id);
        const proposalMarket = {
          ...rawMarket,

          ...(init && geoSelections && Object.keys(geoSelections).length ? { geoSelections } : {}),
        };
        // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
        const { address, ...market } = proposalMarket;

        commit(MutationTypes.SET_SAVE_PROGRESS_LOADING, true);

        const { isErr, unwrap, unwrapErr } = await newProposalService.setMarketData(
          proposalId,
          market,
          audienceSegments,
        );

        if (isErr()) {
          const { message } = unwrapErr();
          throw new Error(message);
        }

        const data = unwrap();

        if (init && proposalMarket?.geoSelections) {
          dispatch('setIsProposalMarketUpdated', true);
        }

        commit(MutationTypes.SET_IN_PROGRESS_PROPOSAL, {
          ...ProposalProductModel.sortAndCleanProductAndFlights(data),
        });

        const { id: updatedProposalId } = data;
        return { proposalId: updatedProposalId };
      } catch (err) {
        logger.print('error', 'store.newProposal.actions.setMarketData', err);
        return null;
      } finally {
        commit(MutationTypes.SET_SAVE_PROGRESS_LOADING, false);
      }
    },

    // TODO: @to-graphql
    async setInProgressProposal(
      { commit, state, dispatch },
      { proposalId }: { proposalId: string; forceUpdate?: boolean },
    ): Promise<(Proposal & { id?: string }) | null> {
      const newProposal = clonedeep(state.newProposal);
      const defaultMarket = clonedeep(state.initialMarket);
      commit(MutationTypes.SET_IN_PROGRESS_LOADING, true);
      const { isErr, unwrapErr, unwrap } = await proposalService.getProposalByProposalId(proposalId);
      if (isErr()) {
        const { message } = unwrapErr();
        logger.print('error', 'store.newProposal.actions.setInProgressProposal', message);
        commit(MutationTypes.SET_IN_PROGRESS_LOADING, false);
        return null;
      }
      const { audienceSegments, contracts = [], conversionTracking, ...proposal } = unwrap();
      const { demographics, audience } = targetingSegmentsModel.getGroupSegments(audienceSegments || []);
      const market = merge(
        {
          ...newProposal.market,
          ...defaultMarket,
        },
        { ...proposal.market },
      );
      const merged = merge(
        {
          ...newProposal,
        },
        { ...proposal },
      );
      const prefiledConversionTracking = {
        visits: { trackVisits: false, website: '' },
        conversion: { trackConversion: false, websites: [] },
        ...(conversionTracking || {}),
      };

      const proposalWithAudienceSegments = {
        ...merged,
        demographics,
        audience,
        contracts,
        conversionTracking: prefiledConversionTracking,
      };

      commit(
        MutationTypes.SET_IN_PROGRESS_PROPOSAL,
        ProposalProductModel.sortAndCleanProductAndFlights(proposalWithAudienceSegments),
      );
      if (proposalWithAudienceSegments?.products.length) {
        commit(MutationTypes.SET_HAS_RECEIVED_RECOMMENDATIONS, true);
      }
      dispatch('setProposalMarket', { market });

      commit(MutationTypes.SET_IN_PROGRESS_LOADING, false);
      return proposalWithAudienceSegments as any;
    },

    async getProposalCreatives({ commit, state }, { proposalId }: { proposalId: string }): Promise<void> {
      const newProposal = clonedeep(state.newProposal);
      commit(MutationTypes.SET_UPDATE_CREATIVE_LOADING, true);
      const { isErr, unwrapErr, unwrap } = await proposalService.getProposalCreatives(proposalId);
      commit(MutationTypes.SET_UPDATE_CREATIVE_LOADING, false);

      if (isErr()) {
        const { message } = unwrapErr();
        logger.print('error', 'store.newProposal.actions.getProposalCreatives', message);
        return;
      }
      const { products } = unwrap();

      const merged = {
        ...newProposal,
        products,
      };

      commit(MutationTypes.SET_IN_PROGRESS_PROPOSAL, ProposalProductModel.sortAndCleanProductAndFlights(merged));
    },

    // TODO: @to-graphql
    async changeProposalBudget({ state, commit }, budget: string): Promise<{ id: string } | null> {
      commit(MutationTypes.SET_SAVE_PROGRESS_LOADING, true);
      const proposalId = state.newProposal.id;
      const { isErr, unwrapErr, unwrap } = await newProposalService.changeProposalBudget(proposalId, budget);
      if (isErr()) {
        const error = unwrapErr();
        logger.print('error', 'store.newProposal.actions.sendProposal', error.message);
        commit(MutationTypes.SET_SAVE_PROGRESS_LOADING, false);
        return null;
      }
      const returnedProposal = unwrap();

      commit(MutationTypes.SET_IN_PROGRESS_PROPOSAL, {
        ...ProposalProductModel.sortAndCleanProductAndFlights(returnedProposal),
      });
      commit(MutationTypes.SET_SAVE_PROGRESS_LOADING, false);
      return returnedProposal;
    },

    // TODO: @to-graphql
    async sendProposal({ state, commit }, proposalInfo: ProposalInfo): Promise<{ id: string } | null> {
      commit(MutationTypes.SET_SAVE_PROGRESS_LOADING, true);
      const proposalId = state.newProposal.id;
      const { isErr, unwrapErr, unwrap } = await newProposalService.sendProposal(proposalId, proposalInfo);
      if (isErr()) {
        const error = unwrapErr();
        logger.print('error', 'store.newProposal.actions.sendProposal', error.message);
        commit(MutationTypes.SET_SAVE_PROGRESS_LOADING, false);
        return null;
      }
      const returnedProposal = unwrap();
      commit(MutationTypes.SET_IN_PROGRESS_PROPOSAL, {
        ...ProposalProductModel.sortAndCleanProductAndFlights(returnedProposal),
      });
      commit(MutationTypes.SET_SAVE_PROGRESS_LOADING, false);
      return returnedProposal;
    },

    updateNewProposalBudget({ commit, state }, budget: number): void {
      if (typeof budget === 'string') {
        budget = parseInt(budget, 10);
      }
      if (budget !== state.newProposal.budget) {
        commit(MutationTypes.SET_NEW_PROPOSAL_BUDGET, budget);
      }
    },

    updateNewProposalName({ commit, state }, newName: string): void {
      if (newName !== state.newProposal.name) {
        commit(MutationTypes.SET_NEW_PROPOSAL_NAME, newName);
      }
    },

    updateNewProposalGoal({ commit, state }, goal: string): void {
      if (goal !== state.newProposal.goal) {
        commit(MutationTypes.SET_NEW_PROPOSAL_GOAL, goal);
      }
    },

    updateNewProposalSecondaryGoal({ commit, state }, secondaryGoal: string): void {
      if (secondaryGoal !== state.newProposal.secondaryGoal) {
        commit(MutationTypes.SET_NEW_PROPOSAL_SECONDARY_GOAL, secondaryGoal);
      }
    },

    // TODO: @to-graphql
    async updateNewProposalStatus(
      { commit, dispatch },
      { proposalPropertyId, newStatus }: { proposalPropertyId: string; newStatus: MediaplannerProposalStatus },
    ): Promise<UpdateProposalStatusMutation['updateMediaplannerProposalStatus'] | boolean> {
      if (!proposalPropertyId || !newStatus) {
        throw new Error('Did not receive proper update information');
      }

      if (newStatus === MediaplannerProposalStatus.SubmittedForReview) {
        dispatch('output/getOutput', proposalPropertyId, { root: true });
      }

      commit(MutationTypes.SET_LOADING_PROPOSAL_STATUS, true);

      const { isErr, unwrapErr, unwrap } = await proposalService.updateProposalStatus({
        proposalPropertyId,
        newStatus,
      });
      commit(MutationTypes.SET_LOADING_PROPOSAL_STATUS, false);

      if (isErr()) {
        const error = unwrapErr();

        logger.print('error', 'store.newProposal.actions.updateNewProposalStatus', error.message);

        return false;
      }

      const data = unwrap();

      commit(MutationTypes.SET_IN_PROGRESS_PROPOSAL, data);

      return data;
    },

    // products
    async increaseToRecommendations(
      { state, rootState, commit, dispatch, getters },
      product: ConfiguredProduct,
    ): Promise<void> {
      try {
        const { newDays = 0, newBudget = 0 } = state.newProposal.increasedRecommendations;
        const currentDays = getters.numberOfDaysInProposal;
        const currentBudget = state.newProposal.budget;
        const shouldUpdateDays = typeof newDays === 'number' && newDays > 0 && newDays !== currentDays;
        const shouldUpdateBudget = typeof newBudget === 'number' && newBudget > currentBudget;
        commit(MutationTypes.SET_UPDATING_TO_INCREASED_RECOMMENDATIONS_LOADING, true);
        if (shouldUpdateBudget || shouldUpdateDays) {
          const proposalUpdateObj = {};
          if (shouldUpdateDays) {
            const daysToAdd =
              currentDays < newDays ? state.newProposal.increasedRecommendations.newDays - currentDays : 0;
            if (daysToAdd) {
              const addDays = (date: Date, days: number): Date => {
                const result = new Date(date);
                result.setDate(result.getDate() + days);
                return result;
              };
              const proposalEndDate = new Date(state.newProposal.endDate);
              const increasedEndDate = addDays(proposalEndDate, daysToAdd);
              const increasedEndDateISO = new Date(increasedEndDate).toISOString();
              const datesObj = {
                startDate: new Date(state.newProposal.startDate).toLocaleDateString('en-US'),
                endDate: new Date(increasedEndDateISO).toLocaleDateString('en-US'),
                proposalPpid: state.newProposal.id,
              };
              Object.assign(proposalUpdateObj, datesObj);
            }
          }
          if (shouldUpdateBudget) {
            Object.assign(proposalUpdateObj, { budget: newBudget });
          }
          await dispatch('sendProposal', proposalUpdateObj);
        }
        // TODO: handle for custom products selected already
        if (product) {
          const selected = [...state.newProposal.products];
          if (!selected.some(p => p.id === product.id)) {
            const newProduct = ProposalProductModel.getProductFromConfig(product, {
              proposalGeoSelections: state.newProposal.market.geoSelections,
              clientGeoSelections: rootState.client.activeClient.geoSelections,
              clientLocations: rootState.client.activeClient.address,
              startDate: state.newProposal.startDate,
              endDate: state.newProposal.endDate,
            });
            let updatedSelected = [];
            const isFlightedProduct = !!newProduct?.flights;

            updatedSelected = [...selected, newProduct];

            dispatch('updateProducts', { products: updatedSelected })
              .then(() => (isFlightedProduct ? dispatch('addNewFlight', product.id) : {}))
              .then(() => dispatch('removeProductIncreasedRecommendations'))
              .catch(err => {
                logger.print('error', 'store.newProposal.actions.increaseToRecommendations/addnew', err);
              });
          }
        } else {
          dispatch('fetchProductRecommendations', true);
        }
      } catch (err) {
        logger.print('error', 'store.newProposal.actions.increaseToRecommendations', err);
      } finally {
        commit(MutationTypes.SET_UPDATING_TO_INCREASED_RECOMMENDATIONS_LOADING, false);
      }
    },

    // TODO: replace with action from product module, remove this as duplicate
    async fetchProductConfigs(
      { commit, state },
      { agencyId = state.newProposal.agencyId }: { agencyId?: string },
    ): Promise<ProductConfig[] | undefined> {
      commit(MutationTypes.SET_FETCH_PRODUCT_CONFIGS_LOADING, true);

      const { isErr, unwrapErr, unwrap } = await productService.getConfigsForProposal(agencyId);

      if (isErr()) {
        const { message } = unwrapErr();

        logger.print('error', 'store.newProposal.actions.fetchProductConfigs', message);
        commit(MutationTypes.SET_FETCH_PRODUCT_CONFIGS_LOADING, false);

        return [];
      }
      const result = unwrap();

      commit(MutationTypes.SET_NEW_PROPOSAL_PRODUCT_CONFIGS, result);
      commit(MutationTypes.SET_FETCH_PRODUCT_CONFIGS_LOADING, false);

      return result;
    },

    // TODO: @to-graphql, refactor
    async fetchProductRecommendations({ commit, rootState, state, getters, dispatch }, force = false): Promise<void> {
      if (!getters.canGetRecommendations || (state.newProposal.products.length && !force)) {
        logger.print('info', 'store.newProposal.actions.fetchProductRecommendations has no propper data to continue');
        return;
      }

      commit(MutationTypes.SET_RECOMMENDATIONS_LOADING, true);

      const { id } = state.newProposal;

      const { isErr, unwrapErr, unwrap } = await newProposalService.fetchProductRecommendations(id);

      if (isErr()) {
        const { message } = unwrapErr();

        commit(MutationTypes.SET_RECOMMENDATIONS_LOADING, false);

        logger.print('error', 'store.newProposal.actions.fetchProductRecommendations', message);

        throw new Error(`Invalid response from server (fetchProductRecommendations): ${message}`);
      }

      const { currentRecommendations = null, increasedRecommendations = null } = unwrap();

      if (currentRecommendations) {
        const recommendedProducts = [];

        currentRecommendations.forEach((product, i) => {
          const cleanProduct = ProposalProductModel.getProductFromConfig(product.productConfig, {
            proposalGeoSelections: state.newProposal.market.geoSelections,
            clientGeoSelections: rootState.client.activeClient.geoSelections,
            clientLocations: rootState.client.activeClient.address,
            startDate: state.newProposal.startDate,
            endDate: state.newProposal.endDate,
          });
          cleanProduct.index = i + 1;
          cleanProduct.budget = product.budget;

          recommendedProducts.push(cleanProduct);
        });

        commit(MutationTypes.SET_PRODUCTS, recommendedProducts);
        commit(MutationTypes.SET_HAS_RECEIVED_RECOMMENDATIONS, true);

        state.newProposal.products.forEach(product => {
          dispatch('addNewFlight', product.id);
        });
      }

      if (increasedRecommendations) {
        commit(MutationTypes.SET_PRODUCT_INCREASED_RECOMMENDATIONS, increasedRecommendations);
      }

      commit(MutationTypes.SET_RECOMMENDATIONS_LOADING, false);
    },

    removeProductIncreasedRecommendations({ commit, state }): void {
      if (state.newProposal.increasedRecommendations) {
        commit(MutationTypes.REMOVE_PRODUCT_INCREASED_RECOMMENDATIONS);
      }
    },

    toggleProductLocked(
      { commit, dispatch, state },
      { status, productId }: { status: boolean; productId: string },
    ): void {
      commit(MutationTypes.TOGGLE_PRODUCT_LOCKED, { _status: status, _productId: productId });
      dispatch('updateProducts', { products: state.newProposal.products, bypassRecalc: true });
    },

    async updateProducts(
      { commit, dispatch },
      {
        products = [],
        onlyLocalUpdate = false,
        bypassRecalc = false,
        updateProposalBudget = false,
      }: {
        products: ConfiguredProduct[];
        onlyLocalUpdate: boolean;
        bypassRecalc: boolean;
        updateProposalBudget: boolean;
      },
    ): // TODO: Is this types is ok?
    Promise<Product[] | ConfiguredProduct[]> {
      // TODO: compare productArray to newProposal.products before commit
      const sorted = [...products].sort((a, b) => a.index - b.index);
      try {
        commit(MutationTypes.SET_UPDATE_PRODUCTS_LOADING, true);
        if (!onlyLocalUpdate) {
          let mapped = sorted;
          if (bypassRecalc) {
            mapped = sorted.map(p => {
              const { flights = null } = p;
              return {
                ...p,
                isChanged: true,
                ...(flights ? { flights: flights.map(f => ({ ...f, isChanged: true })) } : {}),
              };
            });
          }
          // need to calculate if proposal budget should change
          const productBudgetSum = mapped.map(p => p?.budget || 0).reduce((a, b) => a + b, 0);
          const res = await dispatch('sendSelectedProducts', {
            products: mapped,
            ...(updateProposalBudget ? { proposalBudget: productBudgetSum } : {}),
          });
          return res?.products || sorted;
        } else {
          commit(MutationTypes.SET_PRODUCTS, sorted);
          return sorted;
        }
      } catch (err) {
        logger.print(
          'error',
          'store.newProposal.actions.updateProducts',
          `Something went wrong while updating products: ${err}`,
        );
        commit(MutationTypes.SET_PRODUCTS, sorted);
        return sorted;
      } finally {
        commit(MutationTypes.SET_UPDATE_PRODUCTS_LOADING, false);
      }
    },

    async setProposalActions(
      { commit },
      {
        goLiveWitoutRetargeting,
        conversionTracking,
        pixelRequest,
      }: {
        proposalId: string;
        goLiveWitoutRetargeting?: boolean;
        conversionTracking?: ConversionTracking;
        pixelRequest?: boolean;
      },
    ): Promise<void> {
      if (goLiveWitoutRetargeting !== undefined)
        commit(MutationTypes.SET_PROPOSAL_ACTION_FLAGS, {
          goLiveWitoutRetargeting,
        });

      if (conversionTracking !== undefined)
        commit(MutationTypes.SET_PROPOSAL_ACTION_FLAGS, {
          conversionTracking,
        });

      if (pixelRequest !== undefined)
        commit(MutationTypes.SET_PROPOSAL_ACTION_FLAGS, {
          pixelRequest,
        });
    },

    async saveProposalActions({ state, rootState, commit }): Promise<boolean> {
      const { id: proposalId, conversionTracking, goLiveWitoutRetargeting, pixelRequest } = state.newProposal;

      const canSetConversion = rootState.agency?.currentAgencyInfo?.canSetConversionTracking;

      const isConversionAvailable =
        canSetConversion &&
        state.newProposal.products.some(p =>
          ProposalProductModel.isPackage(p)
            ? p?.products?.some(pr => ProposalProductModel.isTrackableProduct(pr))
            : ProposalProductModel.isTrackableProduct(p),
        );

      commit(MutationTypes.SET_SAVE_PROGRESS_LOADING, true);

      const { isErr, unwrapErr, unwrap } = await newProposalService.saveProposalActionFlag({
        proposalId,
        conversionTracking: isConversionAvailable ? conversionTracking : null,
        goLiveWitoutRetargeting,
        pixelRequest,
      });

      commit(MutationTypes.SET_SAVE_PROGRESS_LOADING, false);

      if (isErr()) {
        const error = unwrapErr();
        logger.print('error', 'store.newProposal.actions.sendProposal', error.message);
        return false;
      }

      const { conversionTracking: updConversionTracking, goLiveWitoutRetargeting: updGoLiveWitoutRetargeting } =
        unwrap();

      commit(MutationTypes.SET_PROPOSAL_ACTION_FLAGS, {
        conversionTracking: updConversionTracking,
        goLiveWitoutRetargeting: updGoLiveWitoutRetargeting,
      });
      return true;
    },

    // TODO: @to-graphql
    async sendSelectedProducts(
      { state, commit, getters, dispatch },
      {
        products = state.newProposal.products,
        bypassProductRecalc = false,
        productId,
        flightId,
        proposalBudget = state.newProposal.budget,
      },
    ): Promise<void> {
      const proposalId = state.newProposal?.id || null;

      if (!proposalId) {
        logger.print(
          'info',
          'store.newProposal.actions.sendSelectedProducts',
          'Insufficient data for saving selected products',
        );
        return null;
      }

      const emptyProducts = [];
      const withoutEmptyProducts = [];

      products.forEach(p => {
        if (p?.id && p?.name) withoutEmptyProducts.push(p);
        else emptyProducts.push(p);
      });

      const selectedProductsWithConfig =
        !products.length ||
        !state.productConfigs.loaded ||
        !state.productConfigs?.list?.length ||
        (products.length > 0 && products.every(p => p?.keyMetric))
          ? [...withoutEmptyProducts]
          : [...withoutEmptyProducts].map(p => {
              const foundConfig = ProposalProductModel.filterProductConfigs(state.productConfigs.list).find(
                c => c.id === p.id,
              );
              return {
                ...p,
                ...(foundConfig?.keyMetric ? { keyMetric: foundConfig.keyMetric } : {}),
                ...(foundConfig?.keyMetricMultiplier ? { keyMetricMultiplier: foundConfig.keyMetricMultiplier } : {}),
              };
            });

      const mapped: (Product | Package)[] = selectedProductsWithConfig.map(p => {
        // prevent product budgets from recalculating
        if ((bypassProductRecalc && !productId) || ProposalProductModel.xmlConfiguredProduct(p)) {
          p.isChanged = true;
        }
        if ((productId && productId === p.id && p?.flights) || ProposalProductModel.fixedRateConfig(p)) {
          p.isChanged = true;
          p.flights = p.flights.map(f => ({
            ...f,
            // if flightId is passed, all other flights should be isChanged: false to allow rebalance
            isChanged: f?.isChanged || (flightId && flightId === f.id),
          }));
        }
        return p;
      });

      const solutions = mapped.reduce(
        (acc, product) => {
          if (product.category === ProductConfigCategory.Package) {
            acc.packages.push(product);
            return acc;
          }
          acc.products.push(product);
          return acc;
        },
        { products: [], packages: [] },
      );
      commit(MutationTypes.SET_SAVE_PROGRESS_LOADING, true);
      const { isErr, unwrapErr, unwrap } = await newProposalService.saveAllSelectedProducts({
        proposalId,
        proposalBudget,
        selectedProducts: ProposalProductModel.getCleanProducts(solutions.products, true, true),
        selectedPackages: ProposalProductModel.getCleanPackages(solutions.packages),
      });

      if (isErr()) {
        const { message } = unwrapErr();

        commit(MutationTypes.SET_SAVE_PROGRESS_LOADING, false);

        logger.print('error', 'store.newProposal.actions.sendSelectedProducts', message);

        throw new Error(`Unabled to save selected products: ${message}`);
      }

      const result = unwrap();
      if (!result.id) {
        commit(MutationTypes.SET_SAVE_PROGRESS_LOADING, false);

        logger.print('info', 'store.newProposal.actions.sendSelectedProducts', JSON.stringify(result, null, 2));
        throw new Error('Received unexpected response from server');
      }

      const returnedProposal = { ...result, products: result.products };

      commit(MutationTypes.SET_IN_PROGRESS_PROPOSAL, {
        ...ProposalProductModel.sortAndCleanProductAndFlights(returnedProposal),
      });
      commit(MutationTypes.SET_SAVE_PROGRESS_LOADING, false);
    },

    async updateProductBudget(
      { state, dispatch },
      { budget, productId }: { budget: number; productId: string },
    ): Promise<number> {
      if (typeof budget === 'string') {
        budget = parseInt(budget, 10);
      }
      const products = [...state.newProposal.products];
      const productIndex = products.findIndex(p => p.id === productId);
      let productToUpdate = products[productIndex];

      if (productToUpdate.category === ProductConfigCategory.Package) return;

      if (productToUpdate && !isNaN(budget)) {
        const { flights = null } = productToUpdate;
        if (Array.isArray(flights) && flights.some(f => f?.isLocked)) {
          const sumOfLockedFlights = productToUpdate.flights.reduce((total, flight) => {
            if (flight?.isLocked) return total + flight.budget;
            return total;
          }, 0);
          if (sumOfLockedFlights > budget) {
            productToUpdate.flights = flights.map(f => ({ ...f, isLocked: false }));
          }
        }
        productToUpdate = { ...productToUpdate, budget: budget, isChanged: true };
        products.splice(productIndex, 1, productToUpdate);
        const returnedProducts = await dispatch('updateProducts', { products });
        const foundProduct = returnedProducts.find(p => p.id === productId);
        return foundProduct?.budget || budget;
      } else {
        throw new Error('newProposal/updateProductBudget unable to find product to update');
      }
    },

    // product flights
    addNewGeoFence(
      { commit, state },
      { productId, flightId }: { productId: string; flightId: string },
    ): ProductGeoFence {
      const geofenceProduct = getProductByID(state, productId);
      if (geofenceProduct) {
        const flightIndex = geofenceProduct?.flights.findIndex(flight => flight.id === flightId);
        if (flightIndex !== -1) {
          const geoFence = {
            id: Date.now().toString(),
            address: '',
            lat: null,
            lon: null,
            radius: 400,
            unitType: 'Meters',
          };
          const flightGeoFences = [
            ...(geofenceProduct?.flights[flightIndex]?.market?.geoSelections?.addressList || []),
          ];
          flightGeoFences.push(geoFence);
          commit(MutationTypes.SET_FLIGHT_GEO_FENCES, { productId, flightIndex, geoFences: flightGeoFences });
          return geoFence;
        }
      }
      return null;
    },

    addBulkGeoFences(
      { commit, state },
      { productId, geoFences, flightId }: { productId: string; flightId: string; geoFences: ProductGeoFence[] },
    ): ProductGeoFence {
      const geofenceProduct = getProductByID(state, productId);
      if (geofenceProduct) {
        const flightIndex = geofenceProduct?.flights.findIndex(flight => flight.id === flightId);
        if (flightIndex !== -1) {
          const mapped = geoFences.map((fence, i) => {
            return { ...fence, id: (Date.now() + i * 10000).toString() };
          });
          const flightGeoFences = geofenceProduct?.flights?.[flightIndex]?.market?.geoSelections?.addressList || [];
          flightGeoFences.push(...mapped);
          commit(MutationTypes.SET_FLIGHT_GEO_FENCES, { productId, flightIndex, geoFences: flightGeoFences });
          const [lastFence] = mapped.slice(-1);
          return lastFence;
        }
      }
    },

    updateExistingGeoFence(
      { commit, state },
      { productId, fence, flightId }: { flightId: string; productId: string; fence: ProductGeoFence },
    ): ProductGeoFence {
      const geofenceProduct = getProductByID(state, productId);
      if (geofenceProduct) {
        const flightIndex = geofenceProduct?.flights.findIndex(flight => flight.id === flightId);
        if (flightIndex !== -1) {
          const geoFences = [...(geofenceProduct?.flights?.[flightIndex]?.market?.geoSelections?.addressList || [])];
          const fenceIndex = geoFences.findIndex(f => f.id === fence.id);
          if (fenceIndex !== -1) {
            const updatedGeoFence = {
              ...geoFences[fenceIndex],
              ...fence,
            };

            geoFences[fenceIndex] = updatedGeoFence;
            commit(MutationTypes.SET_FLIGHT_GEO_FENCES, { productId, flightIndex, geoFences });
            return updatedGeoFence;
          }
        }
        // error
        return null;
      }
    },

    removeGeoFence(
      { commit, state },
      { productId, fenceId, flightId }: { productId: string; flightId: string; fenceId: string },
    ): void {
      const geofenceProduct = getProductByID(state, productId);
      if (geofenceProduct) {
        const flightIndex = geofenceProduct?.flights.findIndex(flight => flight.id === flightId);
        if (flightIndex !== -1) {
          const existingFences = [
            ...(geofenceProduct?.flights?.[flightIndex]?.market?.geoSelections?.addressList || []),
          ];
          const updatedFences = existingFences.filter(fence => fenceId !== fence.id);
          commit(MutationTypes.SET_FLIGHT_GEO_FENCES, { productId, flightIndex, geoFences: updatedFences });
        }
      }
    },

    addNewFlight({ commit, state, rootGetters }, productId: string): ProductFlight {
      const tempReg = new RegExp(/^temp\_/);
      const isTempId = tempReg.test(productId);
      const cleanId = isTempId ? productId.replace(tempReg, '') : productId;
      const products = ProposalProductModel.filterProducts(state.newProposal.products);
      const product = isTempId
        ? products.find(product => product.productConfigId === cleanId)
        : products.find(product => product.id === cleanId);

      const dateFormatter = (date: string): string => {
        const split = date.split('/');

        if (split.length > 2) {
          return [split[2], split[0], split[1]].join('-');
        }

        return date;
      };

      const allocatedFlightBudget = product.budget - (product.flights || []).reduce((acc, el) => acc + el.budget, 0);

      const flight = ProposalProductModel.getNewFlight(product, {
        dates: [dateFormatter(state.newProposal.startDate), dateFormatter(state.newProposal.endDate)],
        budget: allocatedFlightBudget,
        locations: rootGetters['client/activeClient']?.address,
        geoSelections: null,
        demographics: null,
        audience: null,
      });

      const productFlights: ProductFlight[] = [...(product?.flights || [])];

      if (!flight) return;
      productFlights.push(flight);
      commit(MutationTypes.SET_PRODUCT_FLIGHTS, { productId: product.id, flights: productFlights });
      return flight;
    },

    updateExistingFlight(
      { commit, state, dispatch },
      {
        productId,
        flight,
        sendUpdate = false,
        recalculateProducts = false,
        flightId = null,
      }: {
        productId: string;
        flight: ProductFlight;
        sendUpdate: boolean;
        recalculateProducts: boolean;
        flightId?: string;
      },
    ): ProductFlight {
      const product = getProductByID(state, productId);
      if (!product) return;
      const flights = [...product.flights];
      const flightIndex = flights.findIndex(f => f.id === flight.id);
      if (flightIndex !== -1) {
        const updatedFlight = {
          ...flights[flightIndex],
          ...flight,
          isChanged: true,
        };

        flights[flightIndex] = updatedFlight;
        if (
          (flights || []).some(
            el =>
              ProposalProductModel.isFlightRateTypeQuote(el) ||
              ProposalProductModel.isFlightRateTypeCpm(el) ||
              ProposalProductModel.isCostPerFlightRate(el),
          )
        ) {
          const sBudget = flights.filter(el => el.isLocked).reduce((acc, el) => acc + el.budget, 0);
          if (product.budget < sBudget) {
            commit(MutationTypes.UPDATE_PRODUCT_BUDGET, {
              _budget: sBudget,
              _productId: product.id,
            });
          }
          const _status = flights.every(el => el.isLocked);
          commit(MutationTypes.TOGGLE_PRODUCT_LOCKED, {
            _status: _status,
            _productId: product.id,
          });
        } else if (recalculateProducts || ProposalProductModel.fixedRateConfig(product)) {
          // smart mailer
          const newBudgetForProduct = Math.max(
            flights.reduce((total, flight) => total + flight.budget, 0),
            product.minSpend,
          );
          if (product.budget !== newBudgetForProduct) {
            commit(MutationTypes.UPDATE_PRODUCT_BUDGET, {
              _budget: newBudgetForProduct,
              _productId: product.id,
            });
          }
        }

        commit(MutationTypes.SET_PRODUCT_FLIGHTS, { productId, flights });
        if (sendUpdate)
          dispatch('sendSelectedProducts', {
            bypassProductRecalc: !recalculateProducts,
            productId,
            ...(flightId ? { flightId } : {}),
          });
        return updatedFlight;
      }
      return flight;
    },

    removeFlight({ commit, state, dispatch }, { productId, flightId }: { productId: string; flightId: string }): void {
      const product = getProductByID(state, productId);
      if (product) {
        const productFlights = product.flights.filter(productFlight => flightId !== productFlight.id);
        let bypassProductRecalc = true;
        if (ProposalProductModel.fixedRateConfig(product)) {
          // smart mailer
          const newBudgetForProduct = Math.max(
            productFlights.reduce((total, flight) => total + flight.budget, 0),
            product.minSpend,
          );
          if (product.budget !== newBudgetForProduct) {
            bypassProductRecalc = false;
            commit(MutationTypes.UPDATE_PRODUCT_BUDGET, { _budget: newBudgetForProduct, _productId: productId });
          }
        }
        commit(MutationTypes.SET_PRODUCT_FLIGHTS, { productId, flights: productFlights });
        dispatch('sendSelectedProducts', { bypassProductRecalc, productId });
      }
    },

    setProductGeo(
      { commit, state, dispatch },
      { productId, geoSelections }: { productId: string; geoSelections: KeywordsGeo },
    ): void {
      const product = getProductByID(state, productId);
      if (!product) return;
      const updatedProduct = { ...product, geoSelections: geoSelections };
      commit(MutationTypes.UPDATE_PRODUCT, updatedProduct);
      dispatch('sendSelectedProducts', { productId, bypassProductRecalc: true });
    },

    resetProductSettings({ commit, state, dispatch }, { productId }: { productId: string }): void {
      const product = getProductByID(state, productId);
      if (!product) return;
      const isFlightedProduct = !!product?.flights;
      if (ProposalProductModel.isPaidSearchProduct(product)) {
        dispatch('resetKeywords', productId);
      } else if (ProposalProductModel.isXMLProduct(product)) {
        commit(MutationTypes.SET_XML, { productId, broadcast: [], link: '', isLocked: false });
      } else if (isFlightedProduct) {
        commit(MutationTypes.SET_PRODUCT_FLIGHTS, { productId, flights: [] });
      }

      dispatch('sendSelectedProducts', { bypassProductRecalc: true, productId });
    },

    async toggleSelectedKeyword(
      { commit, state, dispatch },
      { productId, keyword, budget }: { productId: string; keyword: string; budget: number },
    ): Promise<KeywordSummary> {
      const product = getProductByID(state, productId);
      if (product) {
        const kwObj = product.keywords;
        const updatedKeywords = [];
        if (kwObj?.list) {
          kwObj.list.forEach(kw => {
            if (kw.keyword === keyword) {
              kw.isSelected = !kw.isSelected;
            }
            updatedKeywords.push(kw);
          });
        }
        const summary = await dispatch('getKeywordsSummary', { keywords: updatedKeywords, budget });
        commit(MutationTypes.SET_GENERATED_KEYWORDS, { productId, keywords: updatedKeywords, summary });
        dispatch('updateProducts', { products: state.newProposal.products, bypassRecalc: true });
        return summary;
      }
    },

    // TODO: @to-graphql, refactor
    async getCustomKeywords(
      { commit, rootState, state, dispatch },
      { keywords, productId, budget }: { keywords: string[]; productId: string; budget: number },
    ): Promise<object> {
      try {
        const agencyPropertyId = rootState.client?.activeClient?.AgencyPartner;
        commit(MutationTypes.LOADING_CUSTOM_KEYWORDS, true);

        const { isErr, unwrap, unwrapErr } = await keywordsService.getCustomKeywords({
          keywords,
          agencyPropertyId,
        });

        if (isErr()) {
          const { message = 'Received an unexpected response. Please try again later.' } = unwrapErr();
          logger.print('info', 'store.newProposal.actions.getCustomKeywords', message);
          return [];
        }
        const result = unwrap();

        if (!(result && Array.isArray(result))) {
          return [];
        }

        const product = getProductByID(state, productId);

        if (product) {
          const allKeywords = [...product.keywords.list, ...result];
          const summary = await dispatch('getKeywordsSummary', { keywords: allKeywords, budget });
          commit(MutationTypes.SET_GENERATED_KEYWORDS, { keywords: allKeywords, productId, summary });
          return { keywords: allKeywords, summary };
        }

        return { keywords: result, summary: keywordsEntity.getEmptyKeywordsSummary() };
      } catch (err) {
        logger.print('error', 'store.newProposal.actions.getCustomKeywords', err);
        throw err;
      } finally {
        commit(MutationTypes.LOADING_CUSTOM_KEYWORDS, false);
      }
    },

    // TODO: @to-graphql, refactor
    async getKeywordsSummary(
      { commit },
      { keywords, budget }: { budget: number; keywords: SemKeyword[] },
    ): Promise<KeywordSummary> {
      try {
        commit(MutationTypes.LOADING_KEYWORDS_SUMMARY, true);
        const { isErr, unwrap } = await keywordsService.getKeywordsSummary({
          keywords,
          budget,
        });
        if (isErr()) {
          return null;
        }
        return unwrap();
      } catch (err) {
        logger.print('error', 'store.newProposal.actions.getKeywordsSummary', err);
        throw err;
      } finally {
        commit(MutationTypes.LOADING_KEYWORDS_SUMMARY, false);
      }
    },

    // TODO: @to-graphql, refactor
    async getSemKeywords(
      { commit, state, dispatch },
      {
        productId,
        productConfigId,
        geoSelections,
        budget,
        keywords,
        clientUrl,
      }: {
        keywords?: string[];
        budget: number;
        productId: string;
        clientUrl: string;
        geoSelections: KeywordsGeo;
        productConfigId: string;
      },
    ): Promise<void> {
      try {
        const geoSelection = geoSelections || state.newProposal.market.geoSelections;
        const updatedGeoSelection = geoModel.flatGeoForGeoService(geoSelection);

        const defaultErrorMessage = 'Received an unexpected response. Please try again later.' as const;

        commit(MutationTypes.LOADING_KEYWORDS, true);
        const { isErr, unwrap, unwrapErr } = await keywordsService.getSemKeywords({
          keywords,
          productConfigId,
          clientUrl,
          geoSelection: updatedGeoSelection,
        });

        if (isErr()) {
          const { message = defaultErrorMessage } = unwrapErr();
          logger.print('info', 'store.newProposal.actions.getSemKeywords', message);
          return;
        }

        const result = unwrap();

        if (!(result && Array.isArray(result))) {
          dispatch('showSnackbar', { content: defaultErrorMessage, color: 'error' }, { root: true });
          return;
        }

        const summary = await dispatch('getKeywordsSummary', { keywords: result, budget });

        commit(MutationTypes.SET_GENERATED_KEYWORDS, { keywords: result, productId, summary });

        await dispatch('updateProducts', {
          products: state.newProposal.products,
          bypassRecalc: true,
        });
      } catch (err) {
        logger.print('error', 'store.newProposal.actions.getSemKeywords', err);
        throw err;
      } finally {
        commit(MutationTypes.LOADING_KEYWORDS, false);
      }
    },

    resetKeywords({ commit }, productId: string): void {
      const emptySummary = keywordsEntity.getEmptyKeywordsSummary();
      commit(MutationTypes.SET_GENERATED_KEYWORDS, { keywords: [], productId, summary: emptySummary });
    },

    async createNewCreative(
      { state, commit },
      { productId, creative = null }: { productId: string; creative: ConfiguredCreative; packagePropertyId?: string },
    ): Promise<CreateCreativeMutation['createProductCreative'] | null> {
      const agencyId = state.newProposal?.agencyId || null;

      if (!productId || !creative) {
        logger.print(
          'info',
          'store.newProposal.actions.createNewCreative',
          'Insufficient data for creating new creative',
        );
        return null;
      }
      commit(MutationTypes.SET_UPDATE_CREATIVE_LOADING, true);
      const { isErr, unwrapErr, unwrap } = await creativeService.create({
        productId,
        agencyId,
        ...creative,
      });

      if (isErr()) {
        const { message } = unwrapErr();
        logger.print('error', 'store.newProposal.actions.createNewCreative', message);
        commit(MutationTypes.SET_UPDATE_CREATIVE_LOADING, false);
        return null;
      }
      const product = unwrap();

      commit(MutationTypes.SET_IN_PROGRESS_PROPOSAL_PRODUCT_CREATIVES, { creatives: product.creatives, productId });
      commit(MutationTypes.SET_UPDATE_CREATIVE_LOADING, false);

      return product;
    },

    async sendUpdatedCreative(
      { state, commit },
      { productId, creative = null }: { productId: string; creative: ConfiguredCreative },
    ): Promise<UpdateCreativeMutation['updateProductCreative'] | null> {
      const agencyId = state.newProposal?.agencyId || null;

      if (!productId || !creative) {
        logger.print(
          'info',
          'store.newProposal.actions.sendUpdatedCreative',
          'Insufficient data for updating creative',
        );
        return null;
      }

      // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
      const { unsaved = false, ...partialCreative } = creative;
      commit(MutationTypes.SET_UPDATE_CREATIVE_LOADING, true);
      const { isErr, unwrapErr, unwrap } = await creativeService.update({
        ...partialCreative,
        agencyId,
      });

      if (isErr()) {
        const { message } = unwrapErr();
        logger.print('error', 'store.newProposal.actions.sendUpdatedCreative', message);
        commit(MutationTypes.SET_UPDATE_CREATIVE_LOADING, false);
        return null;
      }

      const product = unwrap();

      commit(MutationTypes.SET_IN_PROGRESS_PROPOSAL_PRODUCT_CREATIVES, { creatives: product.creatives, productId });
      commit(MutationTypes.SET_UPDATE_CREATIVE_LOADING, false);
      return product;
    },

    async removeCreative(
      { state, commit },
      { creativeId, productId }: { creativeId: string; agencyId: string; productId: string },
    ): Promise<DeleteCreativeMutation['deleteProductCreative'] | null> {
      const agencyId = state.newProposal?.agencyId || null;

      if (!creativeId) {
        logger.print('info', 'store.newProposal.actions.removeCreative', 'Insufficient data for removing creative');
        return null;
      }

      commit(MutationTypes.SET_UPDATE_CREATIVE_LOADING, true);
      const { isErr, unwrapErr, unwrap } = await creativeService.remove({
        creativeId,
        agencyId,
      });

      if (isErr()) {
        const { message } = unwrapErr();
        logger.print('error', 'store.newProposal.actions.removeCreative', message);
        commit(MutationTypes.SET_UPDATE_CREATIVE_LOADING, false);
        return null;
      }

      const product = unwrap();
      commit(MutationTypes.SET_IN_PROGRESS_PROPOSAL_PRODUCT_CREATIVES, { creatives: product.creatives, productId });
      commit(MutationTypes.SET_UPDATE_CREATIVE_LOADING, false);
      return product;
    },

    updateCreatives(
      { state, commit, dispatch },
      { productId, creatives = [] }: { productId: string; creatives: ConfiguredCreative[] },
    ): void {
      try {
        if (!productId) {
          logger.print('info', 'store.newProposal.actions.updateCreatives', 'Unable to find product to update');
          return;
        }
        commit(MutationTypes.SET_UPDATE_CREATIVE_LOADING, true);
        const selectedProducts = [...state.newProposal.products];
        for (const selectedProduct of selectedProducts) {
          if (selectedProduct.category === ProductConfigCategory.Package) {
            for (const product of selectedProduct.products) {
              if (product.id === productId) product.creatives = creatives;
            }
          } else {
            if (selectedProduct.id === productId) {
              selectedProduct.creatives = creatives;
            }
          }
        }
        commit(MutationTypes.SET_PRODUCTS, selectedProducts);
      } catch (err) {
        dispatch('showSnackbar', { content: err?.message || err, color: 'error' }, { root: true });
      } finally {
        commit(MutationTypes.SET_UPDATE_CREATIVE_LOADING, false);
      }
    },

    populateProposalData({ state, commit }, { proposal }): void {
      const currentState = state.newProposal;
      commit(MutationTypes.SET_IN_PROGRESS_PROPOSAL, { ...currentState, ...proposal });
    },
  };
};

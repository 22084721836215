import { injectable } from 'inversify';
import { Err, Ok, Result } from '@sniptt/monads';
import { AgencyAddress } from '@/shared/types';

import { Failure } from '@/injectables/failure';
import { FlightMarkup, MathService, LoggerContract, Logger, MathServiceContract } from '@/injectables';
import { Service } from '@/injectables/service';

interface LogoResponse {
  agencyLogo: {
    avtr: string;
    logo: string;
    PitchLogoWidth: string | number;
    PitchLogoHeight?: string | number;
  };
}

@injectable()
export class AgencyService extends Service {
  private _mathService: MathServiceContract = new MathService();
  logger: LoggerContract = new Logger();

  async getLogo(AgencyId: string): Promise<Result<string, Failure>> {
    try {
      const url = `/api/agency/getLogo?AgencyId=${AgencyId}`;
      const { data }: { data: LogoResponse } = await this._axios.post(url);
      const { logo } = data.agencyLogo;
      return Ok(logo);
    } catch (error) {
      return Err({
        message: `Error while loading logo for agency: ${AgencyId}`,
      });
    }
  }

  async updateLogo(AgencyId: string, agencyLogo: string): Promise<Result<string, Failure>> {
    try {
      const url = `/api/agency/updateLogo?AgencyId=${AgencyId}`;
      const { data }: { data: LogoResponse } = await this._axios.post(url, agencyLogo);
      const { logo } = data.agencyLogo;
      return Ok(logo);
    } catch (error) {
      return Err({
        message: `Error while updating logo for agency: ${AgencyId}`,
      });
    }
  }

  async removeLogo(AgencyId: string): Promise<Result<string, Failure>> {
    try {
      const url = `/api/agency/removeLogo?AgencyId=${AgencyId}`;
      const { data } = await this._axios.post(url);
      return Ok(data);
    } catch (error) {
      return Err({
        message: `Couldn't remove logo for agency: ${AgencyId}`,
      });
    }
  }

  async getMarkup(agencyId: string): Promise<Result<FlightMarkup, Failure>> {
    try {
      const url = `/api/agency/getMarkup?AgencyId=${agencyId}`;
      const { data } = await this._axios.get(url);
      const { markupMax, markupMin } = data;
      return Ok({
        markupMax: this._mathService.convertPercentStringToInt(markupMax),
        markupMin: this._mathService.convertPercentStringToInt(markupMin),
      });
    } catch (error) {
      return Err({
        message: `Can't load markup for Agency with id: ${agencyId}`,
      });
    }
  }

  async setMarkup(agencyId: string, upperLimit: number, lowerLimit: number): Promise<Result<FlightMarkup, Failure>> {
    try {
      const url = `/api/agency/setMarkup?AgencyId=${agencyId}&upperLimit=${upperLimit}&lowerLimit=${lowerLimit}`;
      const { data } = await this._axios.post(url);
      return Ok(data);
    } catch (error) {
      return Err({
        message: `Couldn't set markup for Agency with id: ${agencyId}`,
      });
    }
  }

  async getAddress(agencyId: string): Promise<Result<AgencyAddress, Failure>> {
    try {
      const url = `/api/agency/getAddress?AgencyId=${agencyId}`;

      const { data } = await this._axios.post(url);

      return Ok(data);
    } catch (error) {
      this.logger.print('warning', 'agencyService/getAddress', error);

      return Err({
        message: `Can't load address for Agency with id: ${agencyId}`,
      });
    }
  }

  async setAddress(agencyId: string, address: AgencyAddress): Promise<Result<AgencyAddress, Failure>> {
    try {
      const url = `/api/agency/setAddress?AgencyId=${agencyId}`;

      const { data } = await this._axios.post(url, { address });

      return Ok(data);
    } catch (error) {
      this.logger.print('warning', 'agencyService/setAddress', error);

      return Err({
        message: `Couldn't set address for Agency with id: ${agencyId}`,
      });
    }
  }
}

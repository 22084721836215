import axios, { AxiosInstance } from 'axios';

interface AxiosCallbacks {
  onUnauthorized(): Promise<void>;
  onError(message: string): Promise<void>;
  onStart(): void;
  onFinish(): void;
  navigateToErrorPage(error: string): void;
}

interface CreateAxiosResult {
  axios: AxiosInstance;
}

let callbacks: AxiosCallbacks;

const createAxios = (): CreateAxiosResult => {
  const _axios = axios.create({});

  /**
   * Handle cases when BE response that request is unauthorized.
   */
  _axios.interceptors.response.use(
    async response => {
      // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
      const { onUnauthorized, onFinish } = callbacks;

      onFinish();

      if (response.data?.auth === false) {
        // await onUnauthorized();
      }

      return response;
    },
    // Only fires on status code other than 200. Will work after updates on FE proxy
    async error => {
      // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
      const { onUnauthorized, onFinish, onError, navigateToErrorPage } = callbacks;

      // Need a better way to separate user actions
      const userActions = [
        'login',
        'logout',
        'create',
        'set',
        'update',
        'remove',
        'save',
        'rebuild',
        'reset',
        'initialElsy',
        'byProposalId',
      ];
      if (
        error.response &&
        error.response.config &&
        error.response.config.url &&
        userActions.some(a => error.response.config.url.includes(a))
      ) {
        await onError(error.response.data.error);
      }

      onFinish();

      if (error.response.data.auth === false && !error.response.config.url.includes('login')) {
        // await onUnauthorized();
      }

      const actionsNavigatedToErrorPage = ['byProposalId', 'getLibrarySlide'];

      if (
        error.response.status === 404 &&
        error.response.config &&
        error.response.config.url &&
        actionsNavigatedToErrorPage.some(a => error.response.config.url.includes(a))
      ) {
        navigateToErrorPage(error.response.status.toString());
      }

      // eslint-disable-next-line no-console
      console.log(error.response.status, error.response.data.error);
      return Promise.reject(error);
    },
  );

  _axios.interceptors.request.use(request => {
    callbacks.onStart();
    return request;
  });

  return { axios: _axios };
};

const setAxiosCallbacks = (newCallbacks: AxiosCallbacks) => {
  callbacks = { ...newCallbacks };
};

export { createAxios, setAxiosCallbacks };

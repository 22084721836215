
import Vue, { PropType } from 'vue';
import { Models, Services } from '@/injectables/tokens';
import { SegmentCategory } from '@/app/graphql';
import SegmentItem from './segmentItem.vue';
import SearchSection from './search-section.vue';
import { SegmentItem as SegmentItemType, TargetingSegmentsModelContract } from '@/injectables';

export default Vue.extend({
  name: 'TargetingSelector',

  useInjectable: [Models.InstantIO, Models.BaseProduct, Services.TargetingSegments, Models.TargetingSegments],

  components: { SegmentItem, SearchSection },

  props: {
    value: {
      type: Array as PropType<SegmentItemType[]>,
      default: () => [],
    },
  },
  data(): {
    tab: SegmentCategory;
    tabIndex: number;
    items: {
      tab: SegmentCategory | 'Search all segments';
      main: boolean;
      icon?: string;
    }[];
  } {
    return {
      tab: SegmentCategory.AdvancedDemographic,
      tabIndex: 0,
      items: [
        { main: true, tab: SegmentCategory.AdvancedDemographic },
        { main: true, tab: SegmentCategory.AudienceProfiles },
        { main: true, tab: SegmentCategory.Enthusiast },
        { main: true, tab: SegmentCategory.Occupation },
        { main: true, tab: SegmentCategory.SearchHistory },
        { main: true, tab: SegmentCategory.Shopping },
        { main: true, tab: SegmentCategory.Services },
        {
          tab: 'Search all segments',
          main: false,
          icon: 'search',
        },
      ],
    };
  },

  created() {
    this.$store.dispatch('targetingSegments/getTargeting');
  },

  computed: {
    mainCensusItems() {
      return this.items.filter(el => el.main);
    },
    targetItems() {
      return this.$store.state.targetingSegments.mainSegments;
    },
    segmentCategory() {
      return SegmentCategory;
    },
    disabled() {
      const audienceSegmentLimit = this.$store.state.agency.currentAgencyInfo.audienceSegmentLimit;
      if (!audienceSegmentLimit) return false;
      return this.value.filter(el => typeof el !== 'number').length >= audienceSegmentLimit;
    },
  },
  methods: {
    selectSearchedSegment(segments) {
      this.$emit('input', segments);
    },
    setSelection(selected: SegmentItemType[], category: SegmentCategory) {
      const listWithoutCategory = this.value.filter(el => el.segmentCategory !== category);
      this.$emit('input', [...listWithoutCategory, ...selected]);
    },
    getSelectedByCategory(category: SegmentCategory) {
      return this.value.filter(el => el.segmentCategory === category);
    },
    getItemsByCategory(category: SegmentCategory) {
      if (!this.targetItems) return [];
      return this.targetItems[category] || [];
    },
    changeTab(tab) {
      this.tab = tab;
      const index = this.items.findIndex(el => el.tab === tab);
      this.tabIndex = index !== -1 ? index : 0;
    },
    getSelectedNumber(tab) {
      return this.getSelectedByCategory(tab).length;
    },
    getReadableTabName(tab: SegmentCategory | 'Search all segments') {
      return (this[Models.TargetingSegments] as TargetingSegmentsModelContract).getReadableCategory(tab);
    },
  },
});

import { OldProduct } from '@/shared/types';
import { GetGlobalProductsQuery } from '../graphql/queries/get-global-products.generated';
import { ProductConfigCategory } from '@/app/graphql';

export type PopulateProductWithExtraData = (product: GetGlobalProductsQuery['products'][0]) => OldProduct;

// format types
export const formattedProductOption = product => {
  const {
    slideType,
    type,
    name,
    category,
    minDays,
    description,
    fulfillmentMethod,
    omsName,
    omsAccountNumber,
    IORecipientEmail,
    isMonthly = false,
  } = product;
  const formattedDescription = description.hasOwnProperty('short')
    ? description
    : {
        ...(description?.main ? { main: description.main } : {}),
        ...(description?.highlights ? { highlights: description.highlights } : {}),
        short: typeof description === 'string' ? description : '',
      };
  return {
    name,
    category: ProductConfigCategory[category],
    minSpend: parseInt(product.minSpend, 10),
    recommendedBudget: parseInt(product.recommendedBudget, 10),
    minDays,
    description: formattedDescription,
    ...(product.cpcMargin !== undefined ? { cpcMargin: product.cpcMargin } : {}),
    fulfillmentMethod,
    omsName,
    omsAccountNumber,
    IORecipientEmail,
    slideType,
    type,
    isMonthly,
  };
};

export const formattedFlightConfig = (option, { lowerLimit, upperLimit }) => {
  return {
    targetingOption: option.targetingOption,
    rate: Number(option.rate),
    adFormatList: option.adFormatList,
    rateType: option.rateType,
    markupMin: lowerLimit,
    markupMax: upperLimit,
  };
};

export const mergeProduct = (updatedProducts, products) => {
  const productMap = Object.fromEntries(
    updatedProducts.map(product => [
      product.id,
      {
        ...product,
        ...(product.flights && product.flights.length
          ? { flightsMap: Object.fromEntries(product.flights.map(flight => [flight.id, flight])) }
          : {}),
      },
    ]),
  );

  return products.map(el => ({
    ...el,
    budget: productMap[el.id].budget,
    isLocked: productMap[el.id].isLocked,
    isChanged: false,
    ...(el.flights && el.flights.length
      ? {
          flights: el.flights.map(flight => ({
            ...flight,
            budget: productMap[el.id].flightsMap[flight.id].budget,
            isLocked: productMap[el.id].flightsMap[flight.id].isLocked,
            isChanged: false,
          })),
        }
      : {}),
  }));
};


import Vue from 'vue';

import { Models, Services } from '@/injectables/tokens';

import { DateRangePicker } from '@/shared/ui';
import { CalendarServiceContract, ValidationServiceContract } from '@/injectables';

import MoneyField from '@/shared/ui/components/money-field.vue';

export default Vue.extend({
  name: 'PlatformFlight',

  inject: ['showSnackbar'],

  useInjectable: [Services.Calendar, Services.Validation, Models.Flight],

  props: {
    flight: {
      type: Object,
      required: true,
    },
    flightConfigs: {
      type: Array,
      required: true,
    },
    productMinDays: {
      type: Number,
      default: 0,
    },
    productMaxSpend: {
      type: Number,
      default: 0,
    },
    productMinSpend: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  components: { DateRangePicker, MoneyField },

  data(): {
    valid: boolean;
    datesMenu: boolean;
    rateFieldKey: number;
  } {
    return {
      valid: true,
      datesMenu: false,
      rateFieldKey: 0,
    };
  },

  computed: {
    rules() {
      const validationService: ValidationServiceContract = this.validationService;
      return {
        required: validationService.requiredValidatorFactory(),
        requiredMultiple: validationService.maxLengthValidatorFactory(),
        minRange: validationService.minRangeValidatorFactory(),
        minBudget: validationService.minBudgetValidatorFactory(),
      };
    },
    oneThousandDays(): string {
      const [from = new Date()] = this.range;
      return (this.calendarService as CalendarServiceContract).dateAfter(1000, new Date(from)).toISOString();
    },
    budget: {
      get(): string | number {
        return this.flight.budget;
      },
      set(val: string | number) {
        const newFlight = { ...this.flight, budget: val };
        this.updateFlight(newFlight);
      },
    },
    targetingOption: {
      get(): string {
        return this.flight.targetingOption;
      },
      set(val: string) {
        const flightBasics = this.flightConfigs.find(f => f.targetingOption === val) || null;

        const newFlight = { ...this.flight, targetingOption: val, flightCategory: flightBasics?.flightCategory };
        this.updateFlight(newFlight);
      },
    },
    flightBudgetLocked() {
      return this.flight.isLocked;
    },
    targetingOptions() {
      return this.flightConfigs.filter(f => f?.targetingOption).map(f => f.targetingOption) || [];
    },
    secondaryOptions() {
      return this.currentConfig?.adFormatList || [];
    },
    currentConfig() {
      if (!this.targetingOption) {
        return {};
      }
      return this.flightConfigs.find(c => c?.targetingOption === this.targetingOption);
    },
    range: {
      get(): string[] {
        return [this.flight.startDate, this.flight.endDate].filter(Boolean);
      },
      set(val: string[]) {
        const newFlight = { ...this.flight, startDate: val[0], endDate: val[1] };
        this.updateFlight(newFlight);
      },
    },
    platformList() {
      return this.currentConfig.platformOption || this.flightConfigs[0].platformOption || [];
    },
    selectedPlatform: {
      get(): string {
        return this.flight.selectedPlatform;
      },
      set(val: string) {
        const newFlight = { ...this.flight, selectedPlatform: val };
        this.updateFlight(newFlight);
      },
    },
  },
  methods: {
    dateFormatter(date: Date) {
      return (this.calendarService as CalendarServiceContract).dateFormatter(date);
    },
    updateFlight(flight) {
      this.$emit('update-flight', flight);
    },
  },
});

import 'reflect-metadata';

import { injectable } from 'inversify';
import { KeywordSummary } from '@/shared/types';
import { KeywordsModelContract } from '@/injectables';

@injectable()
export class KeywordsModel implements KeywordsModelContract {
  __maxAmountKeywords = 500;
  __targetFlightCategory = 'CONTEXT';

  flightKeywordsConfigured(f): boolean {
    if (f.contextualKeywords) {
      return f.contextualKeywords.length <= this.__maxAmountKeywords;
    }
    return true;
  }

  shouldShowContextualKeywords(flightConfig): boolean {
    return flightConfig.flightCategory === this.__targetFlightCategory;
  }

  getEmptyKeywordsSummary(): KeywordSummary {
    return {
      numKeywords: 0,
      totalCpc: 0,
      totalClicks: 0,
      lowerCpc: 0,
      upperCpc: 0,
      lowerClicks: 0,
      upperClicks: 0,
    };
  }
}

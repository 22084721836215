import { LoggerContract, TargetingSegmentsServiceContract } from '@/injectables';
import { UnsafeAny } from '@/shared/types';
import { ActionTree } from 'vuex';
import { TargetingSegmentsState } from './state';
import { Container } from 'inversify';
import { Services } from '@/injectables/tokens';
import { SegmentCategory, SegmentSubCategory } from '@/app/graphql';
import { groupBy } from 'lodash';
import { SegmentItem } from '@/injectables/services';

interface ActionsInjections {
  logger: LoggerContract;
  container: Container;
}

export const actions = ({ logger, container }: ActionsInjections) => {
  const targetingSegmentsService = container.get<TargetingSegmentsServiceContract>(Services.TargetingSegments);
  return {
    async getTargeting({ commit, state }): Promise<SegmentItem[] | null> {
      try {
        if (state.mainSegments) return;

        const { isErr, unwrap } = await targetingSegmentsService.getMainSegments();

        if (isErr()) {
          return null;
        }
        const response = unwrap();

        if (!response) return null;

        const sortSegments = (list, segmentSubCategory) => {
          if (segmentSubCategory === SegmentSubCategory.Income) {
            return list.sort((a, b) => {
              try {
                const aValue = Number(a.name.split('-')[0].replace(/\D+/g, ''));
                const bValue = Number(b.name.split('-')[0].replace(/\D+/g, ''));
                return aValue - bValue;
              } catch (e) {
                return a.name.localeCompare(b.name);
              }
            });
          }
          return list.sort((a, b) => a.name.localeCompare(b.name));
        };

        const demographics = response.filter(segment => segment.segmentCategory === SegmentCategory.Demographic);
        const groupDemographics = groupBy(demographics, 'segmentSubCategory');
        const sortedDemographics = Object.fromEntries(
          Object.entries(groupDemographics).map(([key, value]) => [key, sortSegments(value, key)]),
        );
        const mainSegments = response.filter(segment => segment.segmentCategory !== SegmentCategory.Demographic);
        const groupByCategory = groupBy(mainSegments, 'segmentCategory');
        const groupBySubCategory = Object.fromEntries(
          Object.entries(groupByCategory).map(([key, value]) => {
            const group = groupBy(value, 'segmentSubCategory');
            const groupValues = Object.entries(group).reduce((acc, [k, val], i) => {
              if ((k === null || k === 'null') && Array.isArray(val)) {
                return [...acc, ...val];
              }
              return [
                ...acc,
                {
                  id: i,
                  name: k,
                  segmentSubCategory: k,
                  children: sortSegments(val, k),
                },
              ];
            }, []);

            return [key, groupValues.sort((a, b) => a.name.localeCompare(b.name))];
          }),
        );

        commit('SET_MAIN_SEGMENTS', groupBySubCategory);
        commit('SET_DEMOGRAPHICS_SEGMENTS', sortedDemographics);
        return response;
      } catch (err) {
        logger.print('error', 'store.targetingSegments.actions.getTargeting', err);
        throw new Error('no data');
      }
    },
  } as ActionTree<TargetingSegmentsState, UnsafeAny>;
};

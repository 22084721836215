const eventCallback = (e: KeyboardEvent) => {
  const key = window.event ? e.keyCode : e.which;
  const systemButton = [8, 13, 27, 37, 39, 46]; // ['Backspace','Enter','Escape','Arrow Left','Arrow Right','del']
  if (systemButton.includes(key)) {
    return;
  }
  if ((!e.shiftKey && key >= 48 && key <= 57) || (key > 95 && key < 106)) {
    return;
  }
  e.preventDefault();
};

const plugin = {
  bind(el: HTMLElement) {
    el.addEventListener('keydown', eventCallback);
  },
  unbind(el: HTMLElement) {
    el.removeEventListener('keydown', eventCallback);
  },
};

export { plugin, eventCallback };

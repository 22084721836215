
import Vue, { PropType } from 'vue';
import { Models } from '@/injectables/tokens';
import SegmentButton from './segmentButton.vue';
import { SegmentItem, TargetingSegmentsModelContract } from '@/injectables';

export default Vue.extend({
  name: 'SegmentItem',

  useInjectable: [Models.InstantIO, Models.BaseProduct, Models.TargetingSegments],

  components: { SegmentButton },

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    selections: {
      type: Array as PropType<SegmentItem[]>,
      default: () => [],
    },
    segment: {
      type: Object,
      required: true,
    },
    formatNumber: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    hasGroupItems() {
      return Array.isArray(this.segment?.children) && this.segment.children.length > 0;
    },
  },
  methods: {
    getReadableSubCategory(category) {
      return (this[Models.TargetingSegments] as TargetingSegmentsModelContract).getReadableSubCategory(category);
    },
    isSegmentSelected(segment: SegmentItem) {
      return this.selections.some(s => s.id === segment.id);
    },
    hasSelectedChildren(segment: SegmentItem) {
      return segment.children.some(s => this.isSegmentSelected(s));
    },
  },
});


import Vue from 'vue';
const everyWithLastResult = (array, callback) => {
  for (const el of array) {
    const result = callback(el);
    if (result !== true) {
      return result;
    }
  }
  return true;
};
export default Vue.extend({
  name: 'BasicFileUploader',
  inject: ['showSnackbar'],
  props: {
    fileValidationFunctions: {
      type: Array,
      required: true,
    },
    fileType: { type: Array, required: true },
    mainText: {
      type: String,
      required: true,
    },
    hideContent: {
      type: Boolean,
      default: false,
    },
    blockedFileDialogByClick: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    fullActionsWidth: {
      type: Boolean,
      default: false,
    },
    dropUploading: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  data: (): {
    valid: Boolean;
    inputKey: number;
  } => ({
    valid: true,
    inputKey: Date.now(),
  }),
  computed: {
    icon() {
      return this.valid || this.dropUploading ? 'mdi-cloud-upload-outline' : 'mdi-cloud-off-outline';
    },
    iconColor() {
      return this.valid || this.dropUploading ? 'primary' : 'grey lighten-2';
    },
    invalidTextColor() {
      return this.valid || this.dropUploading ? 'grey--text lighten-2' : 'error--text';
    },
  },
  methods: {
    onFilePicked(fileList): void {
      if (!fileList.length) {
        return;
      }
      const [pickedFile] = fileList;
      this.inputKey = Date.now();
      const fileValidationResult = everyWithLastResult(this.fileValidationFunctions, func => func(pickedFile));
      if (typeof fileValidationResult === 'string') this.showSnackbar(fileValidationResult, 'error');
      if (fileValidationResult === true) this.$emit('upload-file', pickedFile);
      this.$refs.fileInputRef.value = '';
    },
    dragEnter(fileList): void {
      if (!fileList.length) {
        return;
      }
      const [pickedFile] = fileList;
      this.valid = this.fileType.includes(pickedFile.type);
      setTimeout(() => (this.valid = true), 2500);
    },
    openFileInput({ fromBasicComponent = false } = {}): void {
      if (this.blockedFileDialogByClick && fromBasicComponent) {
        return;
      }
      this.$refs.fileInputRef.click();
    },
  },
});

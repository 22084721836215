import { ActionTree } from 'vuex';
import { Container } from 'inversify';

import { AgencyState } from './state';
import { MutationTypes } from './mutation';
import { Services } from '@/injectables/tokens';
import { AgencyServiceContract, LoggerContract } from '@/injectables';
import { RootState } from '../root/state';

export const actions = (container: Container): ActionTree<AgencyState, RootState> => {
  const agencyService = container.get<AgencyServiceContract>(Services.Agency);
  const logger = container.get<LoggerContract>('logger');

  return {
    async getAgencyInfo({ commit }, AgencyId: string): Promise<null> {
      commit(MutationTypes.SET_AGENCY_INFO_LOADING, true);
      const { isErr, unwrapErr, unwrap } = await agencyService.getAgencyInfo(AgencyId);
      commit(MutationTypes.SET_AGENCY_INFO_LOADING, false);

      if (isErr()) {
        const error = unwrapErr();
        logger.print('error', 'store.actions.agency.getAgencyInfo', error.message);
        return;
      }

      const agencyInfo = unwrap();
      commit(MutationTypes.SET_CURRENT_AGENCY_INFO, agencyInfo);
    },
  };
};

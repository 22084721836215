import { NewProposalModelContract, ProductModelContract } from '@/injectables';

import {
  ConfiguredProduct,
  OTTStandaloneMarket,
  SemKeyword,
  CongressionalDistrictObj,
  ProductConfig,
  KeywordSummary,
  Product,
} from '@/shared/types';

export interface ProductModuleState {
  products: Product[];
  agencyCanHavePackages: boolean;
  productsLoading: boolean;
  updateProductsLoading: boolean;
  fetchProductConfigsLoading: boolean;
  productConfigs: { list: ProductConfig[]; loaded: boolean };
  newPackage: {
    budget: number;
    products: ConfiguredProduct[];
    category: string;
    description: { short: string };
    isEditable: boolean;
    isHidden: boolean;
    minDays: number;
    minSpend: number;
    name: string;
    productId: string;
    recommendedBudget: number;
  };
  keywords: SemKeyword[];
  keywordsSummary: KeywordSummary;
  loadingKeywords: boolean;
  loadingKeywordsSummary: boolean;
  loadingCustomKeywords: boolean;
  congressionalDistricts: CongressionalDistrictObj[];
  OTTMarket: OTTStandaloneMarket;
  productLoading: boolean;
  isLoadXML: boolean;
}

export const state = (
  newProposalModel: NewProposalModelContract,
  productEntity: ProductModelContract,
): ProductModuleState => ({
  products: [],
  agencyCanHavePackages: false,
  productsLoading: false,
  productLoading: false,
  updateProductsLoading: false,
  isLoadXML: false,
  fetchProductConfigsLoading: false,
  productConfigs: { list: [], loaded: false },
  newPackage: {
    budget: 0,
    products: [],
    category: '',
    description: { short: '' },
    isEditable: true,
    isHidden: false,
    minDays: 0,
    minSpend: 0,
    name: '',
    productId: '',
    recommendedBudget: 0,
  },
  keywords: [],
  keywordsSummary: null,
  loadingKeywordsSummary: false,
  loadingKeywords: false,
  loadingCustomKeywords: false,
  OTTMarket: {
    geoSelections: {
      cityList: [],
      zipList: [],
      dmaList: [],
      stateList: [],
      countyList: [],
    },
    demographics: newProposalModel.demographicSelections(),
    enthusiastList: [],
    lifestyleList: [],
    occupationList: [],
    searchHistoryList: [],
    shoppingList: [],
    customList: [],
  },
  congressionalDistricts: productEntity.congressionalDistricts(),
});

import { LoggerContract } from '@/injectables';
import { logFunction } from '@/app/plugins/logger';

export class Logger implements LoggerContract {
  constructor(private readonly _logFunction = logFunction) {}

  print(level: 'info' | 'warning' | 'error', src: string, message?: string): void {
    this._logFunction(level, src, message);
  }
}

import * as Types from '../_types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type TableProductFragmentFragment = {
  id: string;
  agencyId?: string | null;
  type?: Types.ProductConfigType | null;
  name?: string | null;
  category?: Types.ProductConfigCategory | null;
  minDays?: number | null;
  minSpend?: number | null;
  recommendedBudget?: number | null;
  isHidden?: boolean | null;
  description?: Record<string, any> | null;
};

export type ProductConfigFragmentFragment = {
  id: string;
  name?: string | null;
  type?: Types.ProductConfigType | null;
  category?: Types.ProductConfigCategory | null;
  agencyId?: string | null;
  description?: Record<string, any> | null;
  minDays?: number | null;
  minSpend?: number | null;
  recommendedBudget?: number | null;
  flightType?: Types.ProductConfigFlightType | null;
  rateRangeList?: Record<string, any> | null;
  keyMetric?: Types.KeyMetric | null;
  keyMetricMultiplier?: number | null;
  isHidden?: boolean | null;
  isMonthly?: boolean | null;
  cpcMargin?: number | null;
  omsAccountNumber?: string | null;
  omsName?: string | null;
  IORecipientEmail?: string | null;
  fulfillmentMethod?: Types.ProductConfigFullfillmentMethod | null;
  questions?: Array<{ id: string; required: boolean; text: string; index: number } | null> | null;
  flightConfigs?: Array<{
    id: string;
    targetingOption?: string | null;
    flightCategory?: Types.FlightCategory | null;
    adFormatList?: Record<string, any> | null;
    rate?: number | null;
    rateType?: Types.RateType | null;
    markupMin?: number | null;
    markupMax?: number | null;
    platformOption?: Array<string | null> | null;
    mapType?: Types.MapType | null;
    advancedOptions?: {
      autoRenewList?: Array<string | null> | null;
      abTestFlag?: boolean | null;
      darkPeriodList?: Array<{ index: number; startDate: string; endDate: string } | null> | null;
    } | null;
  } | null> | null;
};

export type EditableProductConfigFragmentFragment = {
  id: string;
  name?: string | null;
  type?: Types.ProductConfigType | null;
  category?: Types.ProductConfigCategory | null;
  agencyId?: string | null;
  description?: Record<string, any> | null;
  minDays?: number | null;
  minSpend?: number | null;
  recommendedBudget?: number | null;
  cpcMargin?: number | null;
  flightType?: Types.ProductConfigFlightType | null;
  isMonthly?: boolean | null;
  omsAccountNumber?: string | null;
  omsName?: string | null;
  IORecipientEmail?: string | null;
  fulfillmentMethod?: Types.ProductConfigFullfillmentMethod | null;
  flightConfigs?: Array<{
    id: string;
    targetingOption?: string | null;
    flightCategory?: Types.FlightCategory | null;
    adFormatList?: Record<string, any> | null;
    rate?: number | null;
    rateType?: Types.RateType | null;
    markupMin?: number | null;
    markupMax?: number | null;
    index: string;
  } | null> | null;
};

export const TableProductFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TableProductFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MediaplannerProductConfig' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'agencyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minDays' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minSpend' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recommendedBudget' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isHidden' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TableProductFragmentFragment, unknown>;
export const ProductConfigFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductConfigFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MediaplannerProductConfig' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'agencyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minDays' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minSpend' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recommendedBudget' } },
          { kind: 'Field', name: { kind: 'Name', value: 'flightType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rateRangeList' } },
          { kind: 'Field', name: { kind: 'Name', value: 'keyMetric' } },
          { kind: 'Field', name: { kind: 'Name', value: 'keyMetricMultiplier' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isHidden' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isMonthly' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cpcMargin' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'questions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                { kind: 'Field', name: { kind: 'Name', value: 'index' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'flightConfigs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'targetingOption' } },
                { kind: 'Field', name: { kind: 'Name', value: 'flightCategory' } },
                { kind: 'Field', name: { kind: 'Name', value: 'adFormatList' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rateType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'markupMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'markupMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'platformOption' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'advancedOptions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'autoRenewList' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'abTestFlag' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'darkPeriodList' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'index' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'mapType' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'omsAccountNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'omsName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'IORecipientEmail' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fulfillmentMethod' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProductConfigFragmentFragment, unknown>;
export const EditableProductConfigFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EditableProductConfigFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MediaplannerProductConfig' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'agencyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minDays' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minSpend' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recommendedBudget' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cpcMargin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'flightType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isMonthly' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cpcMargin' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'flightConfigs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', alias: { kind: 'Name', value: 'index' }, name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'targetingOption' } },
                { kind: 'Field', name: { kind: 'Name', value: 'flightCategory' } },
                { kind: 'Field', name: { kind: 'Name', value: 'adFormatList' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rateType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'markupMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'markupMax' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'omsAccountNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'omsName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'IORecipientEmail' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fulfillmentMethod' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EditableProductConfigFragmentFragment, unknown>;

import { ConfiguredProduct, MinimizedProduct, PackageConfig, ProductConfig } from '@/shared/types';
import { GetProductResult, OldProduct } from '@/shared/types';
import { Result } from '@sniptt/monads';
import { Failure } from '@/injectables/failure';
import { ProductFulfillmentMethods } from '@/injectables';
import { GetProductConfigByIdQuery } from '../graphql/queries/get-product-config-by-id.generated';
import { UpdateProductConfigMutation } from '../graphql/mutations/update-product-config.generated';
import { CreateProductConfigMutation } from '../graphql/mutations/create-product-config.generated';
import { ProductConfigType, RateType, Scalars } from '@/app/graphql';
import { GetXandrOttAvailsQuery } from '../graphql/queries/get-avails.generated';
import { GetProductConfigByTypeQuery } from '../graphql/queries/get-product-config-by-type.generated';

export type MediaplannerProductConfig = GetProductConfigByIdQuery['product'];

export const BeautifulRateType = {
  [RateType.Costper]: 'CostPer',
  [RateType.Cpc]: 'CPC',
  [RateType.Cpm]: 'CPM',
  [RateType.Flatrate]: 'FlatRate',
  [RateType.Monthly]: 'Monthly',
  [RateType.Quote]: 'Quote',
};
export interface ProductTargetingOption {
  targetingOption: string;
  rate: number;
  adTypes: string[];
  rateType: string;
}

export interface ProductSettings {
  productId?: string;
  name: string;
  minSpend: number;
  recommendedBudget: number;
  minDays: number;
  isHidden: boolean;
  agencyPropertyId: string;
  fulfillmentMethod: ProductFulfillmentMethods;
  OMSName: string;
  OMSAccountNumber: number;
  IORecipientEmail: string;
  targetingOptions: ProductTargetingOption[];
  cpcMargin?: number;
  lowerLimit: number;
  upperLimit: number;
  description?: {
    short: string;
  };
}

export type XmlReturnType = {
  ProductsList: ConfiguredProduct[];
};

export type getConfigsAgencyArg = {
  agencyPropertyId: string;
};

export interface GeoSelection {
  name: string;
  key: string;
}

export interface ProductServiceContract {
  getOTTAvails(payload: {
    zips: GeoSelection[];
    congressionalDistricts: GeoSelection[];
    counties: GeoSelection[];
    dmas: GeoSelection[];
    states: GeoSelection[];
    cities: GeoSelection[];
    segments?: string[];
    startDate: string;
    endDate: string;
  }): Promise<Result<GetXandrOttAvailsQuery['getXandrOTTAvails'], Failure>>;

  /**
   * Can fetch All product of two levels. Global and Agency products.
   * return array of products and canHavePackages flag
   */
  getProducts({
    agencyPropertyId,
    level,
  }: {
    agencyPropertyId: string;
    level: string;
  }): Promise<Result<GetProductResult, Failure>>;

  /**
   * Fetches global products
   */
  getGlobalProducts(): Promise<Result<GetProductResult, Failure>>;

  /**
   * Saves product configuration
   */
  createProductConfig(
    settings: ProductSettings,
    agencyId: string,
  ): Promise<Result<CreateProductConfigMutation['createdProduct'], Failure>>;

  createProductConfigWithChildren(
    settings: ProductSettings,
    agencyId: string,
    formData: string[],
  ): Promise<Result<CreateProductConfigMutation['createdProduct'], Failure>>;

  updateProductConfig(
    settings: ProductSettings,
    agencyId: string,
  ): Promise<Result<UpdateProductConfigMutation['updatedProduct'], Failure>>;

  updateProductConfigWithChildren(
    settings: ProductSettings,
    agencyId: string,
    formData: string[],
    replace: boolean,
  ): Promise<Result<UpdateProductConfigMutation['updatedProduct'], Failure>>;

  addProductsFromCompulseTo(
    agencyId: string,
    globalProductConfigIds: string[],
    childAgencyIds: string[],
  ): Promise<Result<Boolean, Failure>>;

  // TODO: Temporary optional
  /**
   * Toggle hide/unhide product configuration
   */
  toggleHideProduct?(productId: string): Promise<Result<MinimizedProduct, Failure>>;

  /**
   * Loads product configs.
   */
  getConfigs(agencyId: Scalars['UUID']): Promise<Result<(ProductConfig | PackageConfig)[], Failure>>;

  getConfigsForProposal(agencyId: Scalars['UUID']): Promise<Result<any[], Failure>>;

  getProductConfigById(productId: string, isGlobal: boolean): Promise<Result<OldProduct, Failure>>;

  recalculateBudgets(products: any[], budget: number): Promise<Result<{ products: any[]; newBudget: number }, Failure>>;

  getProductConfigByType?(
    type: ProductConfigType,
    agencyId?: string,
  ): Promise<Result<GetProductConfigByTypeQuery['getMediaplannerProductConfigByType'][0], Failure>>;

  deleteProductConfig?(productId: string, agencyId?: string): Promise<Result<boolean, Failure>>;
}

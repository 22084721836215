
import Vue from 'vue';

export default Vue.extend({
  name: 'KeywordHeader',
  props: {
    loadingKeywords: {
      type: Boolean,
    },
    keywordsSummary: {
      required: true,
    },
    generatedKeywords: {
      required: true,
    },
    budget: {
      required: true,
    },
    withoutPadding: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    clicks(): string {
      if (this.keywordsSummary?.lowerClicks && this.keywordsSummary?.upperClicks) {
        return `${this.$options.filters.formatNumber(
          this.keywordsSummary.lowerClicks,
        )} - ${this.$options.filters.formatNumber(this.keywordsSummary.upperClicks)}`;
      }
      const selectedKeywords = this.generatedKeywords.filter(keyword => keyword.isSelected);
      const estimatedCpc = selectedKeywords.map(keyword => parseFloat(keyword.averageCpc)).reduce((a, b) => a + b, 0);
      if (estimatedCpc > 0) {
        return this.$options.filters.formatNumber(Math.floor(this.budget / estimatedCpc));
      }
      return '0';
    },
    estimatedCpc(): string {
      if (this.keywordsSummary?.lowerCpc && this.keywordsSummary?.upperCpc) {
        return `${this.$options.filters.formatPrice(this.keywordsSummary.lowerCpc, 2)}
         - ${this.$options.filters.formatPrice(this.keywordsSummary.upperCpc, 2)}`;
      }
      const selectedKeywords = this.generatedKeywords.filter(keyword => keyword.isSelected);
      const mapped = selectedKeywords.map(keyword => parseFloat(keyword.averageCpc)).reduce((a, b) => a + b, 0);
      return `${this.$options.filters.formatPrice(mapped)}`;
    },
  },
});


// todo: create and export interface for this component
import Vue from 'vue';

const siteAppId = 'app';

export default {
  name: 'MapComponent',
  props: {
    mapId: String,
    mapTilerMapId: {
      type: String,
      default: '24753aa3-7a2d-4bb6-9370-e7d657b08efb',
    },
    mapTilerKey: {
      type: String,
      default: 'CCrAlH25DTP89c6iJsO3',
    },
    height: {
      type: Number,
      default: 600,
    },
    config: {
      type: Object,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      default: (): any => ({}),
    },
    minZoom: {
      type: Number,
      default: 4,
    },
  },
  watch: {
    height(): void {
      this.Resize();
    },
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data(): any {
    return {};
  },
  created(): void {
    // init singleton dom observer(s)
    if (typeof MutationObserver === 'function') {
      if (!this.$domAppObservers) {
        Vue.prototype.$domAppObservers = {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          addObserver: (cfg: any): any => {
            if (cfg.domObserver) {
              cfg.domObserver.disconnect();
            }
            cfg.config = { attributes: false, childList: true, subtree: true };
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            cfg.callback = (mutationList: any): any => {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              mutationList.forEach((mutation: any) => {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                mutation.removedNodes.forEach((node: any) => {
                  if (!(node instanceof HTMLElement) || node.tagName !== 'DIV') {
                    return;
                  }
                  const found = node.querySelectorAll(`#${cfg.mapId}`);
                  if (found && found.length > 0) {
                    const persistentMapContainer = document.getElementById(`persistent${cfg.mapId}`);
                    if (persistentMapContainer) {
                      persistentMapContainer.appendChild(found[0]);
                    }
                  }
                });

                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                mutation.addedNodes.forEach((node: any) => {
                  if (!(node instanceof HTMLElement) || node.tagName !== 'DIV') {
                    return;
                  }
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  let found: any = null;
                  if (node.matches(`#transient${cfg.mapId}`)) {
                    found = [node];
                  } else {
                    found = node.querySelectorAll(`#transient${cfg.mapId}`);
                  }
                  if (found && found.length > 0) {
                    const mapEl = document.getElementById(`${cfg.mapId}`);
                    if (mapEl) {
                      found[0].appendChild(mapEl);
                    }
                  }
                });
              });
            };

            const watched = document.getElementById(siteAppId);
            if (!watched) {
              // eslint-disable-next-line no-console
              console.log('domAppObserver: no dom to watch', `#${siteAppId}`);
              return null;
            }
            cfg.domObserver = new MutationObserver(cfg.callback);
            cfg.domObserver.observe(watched, cfg.config);
            return cfg;
          },
          init: (): void => {
            setInterval(() => {
              const list = Object.keys(this.$domAppObservers);
              list.forEach((k: string) => {
                const cfg = this.$domAppObservers[k];
                if (typeof cfg !== 'object' || !cfg.mapId) {
                  return;
                }
                const latest = this.$domAppObservers.addObserver(cfg);
                if (latest) {
                  this.$domAppObservers[cfg.mapId] = latest;
                }
              });
            }, 1000);
          },
        };
        if (this.$domAppObservers) {
          this.$domAppObservers.init();
        } else {
          // eslint-disable-next-line no-console
          console.log('failed to create $domAppObservers');
        }
      }
      if (this.$domAppObservers && !this.$domAppObservers[this.$props.mapId]) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let cfg: any = {
          mapId: this.$props.mapId,
        };
        cfg = this.$domAppObservers.addObserver(cfg);
        if (cfg) {
          this.$domAppObservers[this.$props.mapId] = cfg;
        }
      }
    }
  },
  mounted(): void {
    this.LoadExternals()
      .then(() => {
        this.RenderMap();
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((err: any) => {
        // eslint-disable-next-line no-console
        console.error(err);
      });
  },
  computed: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    componentAttributes(): any {
      return {
        class: 'mapContainer',
        style: { position: 'relative', height: `${this.$props.height}px` },
      };
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    leaflet(): any {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return (window as any).L as any;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    omnivore(): any {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return (window as any).omnivore as any;
    },
  },
  methods: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Get(): any {
      const ctx = this.GetSingleton();
      return {
        CreateLayer: this.CreateLayer,
        CreatePopup: this.CreatePopup,
        CreateCircleLayer: this.CreateCircleLayer,
        ClosePopup: this.ClosePopup,
        AddToLayer: this.AddToLayer,
        AddDivToLayer: this.AddDivToLayer,
        RemoveLayer: this.RemoveLayer,
        Redraw: this.Redraw,
        Resize: this.Resize,
        ClearMap: this.ClearMap,
        SetWKT: this.SetWKT,
        ParseTopo: this.ParseTopo,
        FlyTo: this.FlyTo,
        SetView: this.SetView,
        ZoomControlsOnRight: this.ZoomControlsOnRight,
        FitBounds: this.FitBounds,
        FitAllLayers: this.FitAllLayers,
        FitAllLayersByLatLong: this.FitAllLayersByLatLong,
        GetBounds: this.GetBounds,
        GetCenter: this.GetCenter,
        GetZoom: this.GetZoom,
        SetZoom: this.SetZoom,
        FlyToBounds: this.FlyToBounds,
        FlyToAllLayers: this.FlyToAllLayers,
        FlyToLayers: this.FlyToLayers,
        Height: this.$props.height,
        Id: this.$props.mapId,
        host: ctx.mapRef,
        dom: ctx.mapDom,
        initialized: ctx.initialized,
        layers: ctx.namedLayers,
        renderCacheKey: ctx.renderCacheKey,
        SetRenderCacheKey: this.SetRenderCacheKey,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        omnivore: (window as any).omnivore as any,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        leaflet: (window as any).L as any,
      };
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    GetSingleton(): any {
      const key = `mapContext_${this.$props.mapId}`;
      let ctx = window[key];
      if (!ctx) {
        ctx = {
          retries: 0,
          initialized: false,
          mapRef: null,
          mapDom: null,
          vueEl: null,
          namedLayers: {},
          allLayers: [],
          rootLayer: null,
          flying: false,
          renderCacheKey: null,
          lastBounds: null, // the actual visible bounds of the map
          lastWantedBounds: null, // what we want visible in the map, that the map will 'contain'
        };
        window[key] = ctx;
      }
      return ctx;
    },
    LoadExternals(): Promise<void> {
      return new Promise((resolve, reject) => {
        // don't be too smart for now, check if we have leaflet and omnivore already loaded or not
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const _window = window as any;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const L = _window.L as any;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const omnivore = _window.omnivore as any;
        if (L && L.MapboxGL && omnivore) {
          resolve();
          return;
        }

        // if on the same page, other maps could both start loading resources, have them to wait and retry
        const { loadingExternals, loadingExternalsDone } = _window;
        if (loadingExternalsDone) {
          // just in case
          resolve();
          return;
        }

        if (loadingExternals) {
          setTimeout(() => {
            this.LoadExternals()
              .then(() => {
                resolve();
              }) // eslint-disable-next-line @typescript-eslint/no-explicit-any
              .catch((err: any) => {
                // eslint-disable-next-line no-console
                console.error(err);
              });
          }, 10);
          return;
        }
        _window.loadingExternals = true;

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const insertNode = (s: string): Promise<any> => {
          return new Promise((onSuccess, onError) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            let ext: any = s.split('?')[0].split('.');
            ext = ext[ext.length - 1];
            if (ext === 'js') {
              const script = document.createElement('script');
              script.onerror = onError;
              script.onload = onSuccess;
              script.async = true;
              script.src = s;
              document.head.appendChild(script);
            } else if (ext === 'css') {
              const link = document.createElement('link');
              link.setAttribute('rel', 'stylesheet');
              link.setAttribute('href', s);
              link.onerror = onError;
              link.onload = onSuccess;
              document.head.appendChild(link);
            }
          });
        };

        const firstLoad = [
          'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.8.0/leaflet.js',
          'https://cdnjs.cloudflare.com/ajax/libs/mapbox-gl/1.13.1/mapbox-gl.min.js',
          'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.8.0/leaflet.css',
          'https://cdnjs.cloudflare.com/ajax/libs/mapbox-gl/1.13.1/mapbox-gl.min.css',
          'https://unpkg.com/leaflet-gesture-handling@1.2.2/dist/leaflet-gesture-handling.min.css',
        ];

        const secondLoad = [
          'https://cdnjs.cloudflare.com/ajax/libs/mapbox-gl-leaflet/0.0.15/leaflet-mapbox-gl.min.js',
          'https://cdnjs.cloudflare.com/ajax/libs/leaflet-omnivore/0.3.4/leaflet-omnivore.min.js',
          'https://unpkg.com/leaflet-gesture-handling@1.2.2/dist/leaflet-gesture-handling.min.js',
        ];

        const loadFirst = firstLoad
          .map((s: string) => {
            return insertNode(s);
          })
          .map(p => p.catch(e => e));

        Promise.all(loadFirst)
          .then(() => {
            const loadThen = secondLoad
              .map((s: string) => {
                return insertNode(s);
              })
              .map(p => p.catch(e => e));
            Promise.all(loadThen)
              .then(() => {
                delete _window.loadingExternals;
                _window.loadingExternalsDone = true;
                resolve();
              })
              .catch((err2: void) => {
                // eslint-disable-next-line no-console
                console.error('loadThen error', err2);
                reject(err2);
              });
          })
          .catch((err1: void) => {
            // eslint-disable-next-line no-console
            console.error('loadFirst error', err1);
            reject(err1);
          });
      });
    },
    RenderMap(): void {
      const appDomEl = document.getElementById(siteAppId);
      if (!appDomEl) {
        // eslint-disable-next-line no-console
        console.info('no #app dom', siteAppId);
        return;
      }

      const ctx = this.GetSingleton();

      let persistentMapContainer = document.getElementById(`persistent${this.$props.mapId}`);
      if (!persistentMapContainer) {
        persistentMapContainer = document.createElement('div');
        persistentMapContainer.id = `persistent${this.$props.mapId}`;
        persistentMapContainer.style.display = 'none';
        appDomEl.appendChild(persistentMapContainer);
        if (ctx.retries < 20) {
          setTimeout(this.RenderMap, 1000);
          ctx.retries += 1;
        } else {
          ctx.retries = 0;
          // eslint-disable-next-line no-console
          console.info('no persistent map dom', `persistent${this.$props.mapId}`);
        }
        return;
      }

      const transientMapContainer = document.getElementById(`transient${this.$props.mapId}`);
      if (!transientMapContainer) {
        if (ctx.retries < 20) {
          setTimeout(this.RenderMap, 1000);
          ctx.retries += 1;
        } else {
          ctx.retries = 0;
          // eslint-disable-next-line no-console
          console.info('no transient map dom', `persistent${this.$props.mapId}`);
        }
        return;
      }

      // update this value so the recycled map can always point to the latest vue component
      ctx.vueEl = this;

      let mapDom = document.getElementById(this.$props.mapId);
      if (!mapDom) {
        mapDom = document.createElement('div');
        mapDom.id = this.$props.mapId;
        mapDom.className = 'mapElement';
        mapDom.style.position = 'absolute';
        mapDom.style.height = `${this.$props.height}px`;
        mapDom.style.top = '0px';
        mapDom.style.bottom = '0px';
        mapDom.style.left = '0px';
        mapDom.style.right = '0px';
        persistentMapContainer.appendChild(mapDom);
        ctx.mapDom = mapDom;
        ctx.mapRef = null;
        ctx.initialized = false;
        ctx.retries = 0;
        ctx.namedLayers = {};
        ctx.allLayers = [];
      }
      transientMapContainer.className = 'mapContainer';
      transientMapContainer.appendChild(mapDom);

      let redrawing = false;
      if (!ctx.mapRef) {
        ctx.mapRef = this.leaflet.map(mapDom, {
          gestureHandling: true,
          zoomSnap: 1,
          minZoom: this.minZoom,
          maxZoom: 16,
          ...this.config,
        });
        ctx.mapRef.setMaxBounds([
          [4.49955, -167.276413],
          [76.162102, -48.23304],
        ]);
        this.AddRootLayer();
        ctx.mapRef
          .on('moveend', () => {
            // const v = (window as any)._VueInstance;
            const latestContext = this.GetSingleton();
            const newBounds = this.GetBounds();

            const latest = latestContext.lastBounds;
            latestContext.lastBounds = newBounds;
            latestContext.initialized = true;
            if (newBounds && latest && newBounds.equals(latest)) {
              // console.log('moveend', 'same bounds');
            } else if (latestContext.flying) {
              latestContext.flying = false;
              latestContext.vueEl.$emit('moveend', {
                mapId: this.$props.mapId,
                bounds: newBounds,
                flying: true,
                event: 'moveend',
              });
            } else {
              latestContext.vueEl.$emit('moveend', {
                mapId: this.$props.mapId,
                bounds: newBounds,
                flying: false,
                event: 'moveend',
              });
            }
          })
          .on('zoomend', () => {
            // zoomend seems to fire before moveend when flying
            const latestContext = this.GetSingleton();
            const newBounds = this.GetBounds();
            const latest = latestContext.lastBounds;
            latestContext.lastBounds = newBounds;
            if (newBounds && latest && newBounds.equals(latest)) {
              // console.log('zoomend', 'same bounds');
            } else if (latestContext.flying) {
              latestContext.flying = false;
              latestContext.vueEl.$emit('zoomend', {
                mapId: this.$props.mapId,
                bounds: newBounds,
                flying: true,
                event: 'zoomend',
              });
            } else {
              latestContext.vueEl.$emit('zoomend', {
                mapId: this.$props.mapId,
                bounds: newBounds,
                flying: false,
                event: 'zoomend',
              });
            }
          })
          .on('resize', () => {
            const latestContext = this.GetSingleton();
            const newBounds = this.GetBounds();
            const latest = latestContext.lastBounds;
            latestContext.lastBounds = newBounds;
            if (newBounds && latest && newBounds.equals(latest)) {
              // console.log('resize', 'same bounds');
            } else {
              latestContext.vueEl.$emit('resize', {
                mapId: this.$props.mapId,
                bounds: newBounds,
                flying: false,
                event: 'resize',
              });
            }
          });
      } else {
        ctx.mapRef.invalidateSize();
        redrawing = true;
      }
      setTimeout(() => {
        this.$emit('ready', { id: this.$props.mapId, redrawing });
      }, 20);
    },
    AddRootLayer(): void {
      // window.mapboxgl.accessToken =
      //   'pk.eyJ1IjoicmVnaXNhIiwiYSI6ImNsNXYxNmIzMTA1OXYzYnQybWc5bHp3YTkifQ.wQfHKAXaRRI6TmYErhbihw';
      const mapCfg = {
        attribution:
          '<a href="https://www.maptiler.com/copyright/" target="_blank">© MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">© OpenStreetMap</a>',
        // accessToken: 'pk.eyJ1IjoicmVnaXNhIiwiYSI6ImNsNXYxNmIzMTA1OXYzYnQybWc5bHp3YTkifQ.wQfHKAXaRRI6TmYErhbihw', <- mnapbox regis' personal account
        style: `https://api.maptiler.com/maps/${this.$props.mapTilerMapId}/style.json?key=${this.$props.mapTilerKey}`,
      };
      const ctx = this.GetSingleton();
      ctx.rootLayer = this.leaflet.mapboxGL(mapCfg).addTo(ctx.mapRef);
    },
    Redraw(): void {
      const ctx = this.GetSingleton();
      if (!ctx || !ctx.rootLayer) {
        // eslint-disable-next-line no-console
        console.log('map redraw, no map singleton', this.$props.mapId, ctx);
        return;
      }

      ctx.mapDom.style.height = `${this.$props.height}px`;
      setTimeout(() => {
        ctx.mapRef.invalidateSize();
        ctx.rootLayer.remove();
      }, 10);
      setTimeout(() => {
        this.$forceUpdate();
        this.ClearMap();
      }, 100);
      setTimeout(() => {
        this.AddRootLayer();
      }, 200);
    },
    Resize(): void {
      const ctx = this.GetSingleton();
      if (!ctx || !ctx.rootLayer) {
        // eslint-disable-next-line no-console
        console.log('map resize, no map singleton', this.$props.mapId, ctx);
        return;
      }
      ctx.mapDom.style.height = `${this.$props.height}px`;
      setTimeout(() => {
        ctx.mapRef.invalidateSize(true);
      }, 10);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    CreatePopup(text: any, latlng: any, options: any): any {
      const ctx = this.GetSingleton();
      return this.leaflet.popup(options).setLatLng(latlng).setContent(text).openOn(ctx.mapRef);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ClosePopup(popup: any): any {
      const ctx = this.GetSingleton();
      ctx.mapRef.closePopup(popup);
      return this;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    CreateLayer(name: string): any {
      try {
        const ctx = this.GetSingleton();
        const layer = this.leaflet.geoJson().addTo(ctx.mapRef);
        ctx.namedLayers[name] = layer;
        ctx.allLayers.push(layer);
        return layer;
      } catch (exp) {
        // eslint-disable-next-line no-console
        console.error('CreateLayer', exp, this.$props.mapId, name);
      }
      return null;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    AddToLayer(parent: any): any {
      try {
        const ctx = this.GetSingleton();
        const layer = this.leaflet.geoJson().addTo(parent);
        ctx.allLayers.push(layer);
        return layer;
      } catch (exp) {
        // eslint-disable-next-line no-console
        console.error('AddToLayer', exp, this.$props.mapId);
      }
      return null;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    AddDivToLayer(divIcon: any, lat: number, lon: number, parent: any): any {
      try {
        const ctx = this.GetSingleton();
        const icon = this.leaflet.divIcon(divIcon);
        const marker = this.leaflet.marker([lat, lon], { icon, riseOnHover: true, ...divIcon }).addTo(parent);
        ctx.allLayers.push(marker);
        return marker;
      } catch (exp) {
        // eslint-disable-next-line no-console
        console.error('AddDivToLayer', exp, this.$props.mapId);
      }
      return null;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    CreateCircleLayer(lat: number, lon: number, radius: number, name: string): any {
      try {
        const ctx = this.GetSingleton();
        const layer = this.leaflet.circle([lat, lon], radius).addTo(ctx.mapRef);
        // add rectangle to make 'fit bounds' work
        // const rectBounds = L.latLng(lat, lon).toBounds(radius);
        // this.leaflet.rectangle(rectBounds).addTo(ctx.mapRef);
        ctx.namedLayers[name] = layer;
        ctx.allLayers.push(layer);
        return layer;
      } catch (exp) {
        // eslint-disable-next-line no-console
        console.error('CreateCircleLayer', exp, this.$props.mapId, name);
      }
      return null;
    },
    RemoveLayer(name: string): void {
      try {
        const ctx = this.GetSingleton();
        if (ctx.namedLayers[name]) {
          // host.removeLayer(mapLayers[name]);
          ctx.namedLayers[name].remove();
          delete ctx.namedLayers[name];
        }
      } catch (exp) {
        // eslint-disable-next-line no-console
        console.error('RemoveLayer', exp, this.$props.mapId, name);
      }
    },
    ClearMap(): void {
      try {
        const ctx = this.GetSingleton();
        const list = Object.keys(ctx.namedLayers);
        list.forEach((k: string) => {
          ctx.namedLayers[k].remove();
          // host.removeLayer(mapLayers[k]);
          delete ctx.namedLayers[k];
        });

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ctx.allLayers.forEach((layer: any) => {
          layer.remove();
          // host.removeLayer(layer);
        });
        ctx.allLayers = [];
        // testing it does remove all layers
        // console.log('clear map', window[`mapContext_${this.$props.mapId}`]);
      } catch (exp) {
        // eslint-disable-next-line no-console
        console.error('ClearMap', exp, this.$props.mapId);
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ParseTopo(topo: any): any {
      try {
        return this.omnivore.topojson.parse(topo);
      } catch (exp) {
        // eslint-disable-next-line no-console
        console.error('ParseTopo', exp, this.$props.mapId);
        return null;
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    SetWKT(layer: any, wkt: string, removePoints: boolean): any {
      try {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const features: any[] = [];
        const filterLayer = this.leaflet.geoJson(null, {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          filter: (x: any) => {
            features.push(x);
            return false;
          },
        });
        this.omnivore.wkt.parse(wkt, null, filterLayer);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        features.forEach((f: any) => {
          if (f.type === 'GeometryCollection') {
            if (removePoints) {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              f.geometries = f.geometries.filter((geo: any) => {
                return geo.type !== 'Point' && geo.type !== 'LineString';
              });
            }
          }
          layer.addData(f);
        });
      } catch (exp) {
        // eslint-disable-next-line no-console
        console.error('SetWKT', exp, layer, wkt);
      }
      return layer;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    SetView(point: any, zoom: number, options: any): any {
      try {
        // console.log('SetView', point, zoom, options);
        const ctx = this.GetSingleton();
        const latlng = this.leaflet.latLng(point);
        if (!ctx.mapRef) {
          // eslint-disable-next-line no-console
          console.log('no map', this.$props.mapId);
          return ctx.mapRef;
        }
        if (!options) {
          options = {};
        }
        ctx.mapRef.setView(latlng, zoom, options);
        setTimeout(() => {
          options.duration = 0.01; // setView 'fails' to redraw the root layer when recycling the map, so using a fast flyTo instead
          ctx.mapRef.flyTo(latlng, zoom, options);
        }, 0.01);
      } catch (exp) {
        // eslint-disable-next-line no-console
        console.error('SetView', exp, point, zoom, options);
      }
      return this;
    },
    ZoomControlsOnRight(): void {
      const ctx = this.GetSingleton();
      ctx.mapRef.zoomControl.remove();
      this.leaflet.control
        .zoom({
          position: 'topright',
        })
        .addTo(ctx.mapRef);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    FlyTo(point: any, zoom: number, options: any): any {
      try {
        // console.log('FlyTo', point, zoom, options);

        const ctx = this.GetSingleton();
        const latlng = this.leaflet.latLng(point);
        ctx.mapRef.flyTo(latlng, zoom, options);
        ctx.flying = true;
        // todo: how to keep track of lastWantedBounds here?
      } catch (exp) {
        // eslint-disable-next-line no-console
        console.error('FlyTo', exp, point, zoom, options);
      }
      return this;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    FlyToBounds(bounds: any, options: any): any {
      try {
        const ctx = this.GetSingleton();
        if ((!bounds.equals(ctx.lastBounds) && !bounds.equals(ctx.lastWantedBounds)) || options.force) {
          ctx.mapRef.flyToBounds(bounds, options);
          ctx.lastBounds = bounds;
          ctx.lastWantedBounds = bounds;
          ctx.flying = true;
        }
      } catch (exp) {
        // eslint-disable-next-line no-console
        console.error('FlyToBounds', exp, bounds, options);
      }
      return this;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    FlyToLayers(layers: any[], options: any): any {
      try {
        // eslint-disable-next-line new-cap
        const group = new this.leaflet.featureGroup(layers);
        const bounds = group.getBounds();
        if (!options) {
          options = {};
        }
        if (typeof options.duration === 'undefined') {
          options.duration = 1.0;
        }
        if (bounds.isValid()) {
          // console.log('want to fly to', bounds);
          this.FlyToBounds(bounds, options);
        } else {
          // eslint-disable-next-line no-console
          console.log('FlyToLayers invalid bounds');
        }
      } catch (exp) {
        // eslint-disable-next-line no-console
        console.error('FlyToLayers', exp, options);
      }
      return this;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    FlyToAllLayers(options: any): any {
      try {
        const ctx = this.GetSingleton();
        // eslint-disable-next-line new-cap
        const group = new this.leaflet.featureGroup(Object.values(ctx.namedLayers));
        const bounds = group.getBounds();
        // console.log('FlyToAllLayers', group, bounds);
        if (!options) {
          options = {};
        }
        if (typeof options.duration === 'undefined') {
          options.duration = 1.0;
        }
        if (bounds.isValid()) {
          // console.log('want to fly to', bounds);
          this.FlyToBounds(bounds, options);
        } else {
          // eslint-disable-next-line no-console
          console.log('FlyToAllLayers invalid bounds');
        }
      } catch (exp) {
        // eslint-disable-next-line no-console
        console.error('FlyToAllLayers', exp, options);
      }
      return this;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    FitBounds(bounds: any, options: any): any {
      try {
        // console.log('FitBounds', bounds);
        const ctx = this.GetSingleton();
        ctx.mapRef.fitBounds(bounds, options);
        ctx.lastBounds = ctx.mapRef.getBounds();
        ctx.lastWantedBounds = bounds;
      } catch (exp) {
        // eslint-disable-next-line no-console
        console.error('FitBounds', exp, bounds);
      }
      return this;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    FitAllLayers(options: any): any {
      try {
        const ctx = this.GetSingleton();
        // eslint-disable-next-line new-cap
        const group = new this.leaflet.featureGroup(Object.values(ctx.namedLayers));
        const bounds = group.getBounds();
        if (bounds.isValid()) {
          this.FitBounds(bounds, options);
        } else {
          // eslint-disable-next-line no-console
          console.log('FitAllLayers invalid bounds');
        }
      } catch (exp) {
        // eslint-disable-next-line no-console
        console.error('FitAllLayers', exp);
      }
      return this;
    },
    FitAllLayersByLatLong(latLng: { lat: number; lng: number }[], options: any): any {
      try {
        const group = new this.leaflet.featureGroup();

        latLng.forEach(el => this.leaflet.marker([el.lat, el.lng]).addTo(group));

        const bounds = group.getBounds();
        if (bounds.isValid()) {
          this.FitBounds(bounds, options);
        } else {
          // eslint-disable-next-line no-console
          console.log('FitAllLayers invalid bounds');
        }
      } catch (exp) {
        // eslint-disable-next-line no-console
        console.error('FitAllLayers', exp);
      }
      return this;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    GetBounds(): any {
      try {
        const ctx = this.GetSingleton();
        return ctx.mapRef.getBounds();
      } catch (exp) {
        // eslint-disable-next-line no-console
        console.error('GetBounds', exp);
      }
      return null;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    GetZoom(): any {
      try {
        const ctx = this.GetSingleton();
        return ctx.mapRef.getZoom();
      } catch (exp) {
        // eslint-disable-next-line no-console
        console.error('GetZoom', exp);
      }
      return null;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    SetZoom(zoom: number, options: any): any {
      try {
        const ctx = this.GetSingleton();
        return ctx.mapRef.setZoom(zoom, options);
      } catch (exp) {
        // eslint-disable-next-line no-console
        console.error('SetZoom', exp);
      }
      return null;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    GetCenter(): any {
      try {
        const ctx = this.GetSingleton();
        return ctx.mapRef.getCenter();
      } catch (exp) {
        // eslint-disable-next-line no-console
        console.error('GetCenter', exp.message);
      }
      return null;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    SetRenderCacheKey(key: string): any {
      try {
        const ctx = this.GetSingleton();
        ctx.renderCacheKey = key;
        return ctx;
      } catch (exp) {
        // eslint-disable-next-line no-console
        console.error('SetRenderCacheKey', exp);
      }
      return null;
    },
  },
};

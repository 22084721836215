import { render, staticRenderFns } from "./ActiveElementEditor.vue?vue&type=template&id=8c41bc76&scoped=true"
import script from "./ActiveElementEditor.vue?vue&type=script&lang=ts"
export * from "./ActiveElementEditor.vue?vue&type=script&lang=ts"
import style0 from "./ActiveElementEditor.vue?vue&type=style&index=0&id=8c41bc76&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8c41bc76",
  null
  
)

export default component.exports
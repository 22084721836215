import * as Types from '../../../../../app/graphql/_types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type GetAvailsPdfQueryVariables = Types.Exact<{
  data: Types.AvailsPdfInput;
}>;

export type GetAvailsPdfQuery = { fileInfo: { message: string; url?: string | null } };

export const GetAvailsPdfDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAvailsPDF' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'AvailsPDFInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'fileInfo' },
            name: { kind: 'Name', value: 'getAvailsPDF' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'avails' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', alias: { kind: 'Name', value: 'url' }, name: { kind: 'Name', value: 'fileURL' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetAvailsPdfQuery, GetAvailsPdfQueryVariables>;

import { ActionTree } from 'vuex';
import { ManagementState } from './state';

import { MutationTypes } from './mutation';
import { Container } from 'inversify';
import { Services } from '@/injectables/tokens';
import { LoggerContract, ManagementServiceContract } from '@/injectables';
import { ProposalStatusMonthObj } from '@/shared/types';
import { differenceInCalendarMonths } from 'date-fns';
import { RootState } from '../root/state';

export const actions = (container: Container): ActionTree<ManagementState, RootState> => {
  const managementService = container.get<ManagementServiceContract>(Services.Management);
  const logger = container.get<LoggerContract>('logger');

  return {
    async getProposalStatusChartData(
      { commit },
      { start, end }: { start: string; end: string },
    ): Promise<ProposalStatusMonthObj[]> {
      if (!start || !end) {
        logger.print(
          'info',
          'store.managment.actions.getProposalStatusChartData',
          'Insufficient data for fetching proposal status chart data',
        );
        return [];
      }

      const { isErr, unwrapErr, unwrap } = await managementService.fetchProposalStatusChartData(start, end);

      if (isErr()) {
        const error = unwrapErr();
        logger.print('error', 'store.actions.management.getProposalStatusChartData', error.message);
        return [];
      }

      const chartData = unwrap();

      commit(MutationTypes.SET_PROPOSAL_STATUS_CHART_DATA, chartData.MonthList);

      return chartData.MonthList;
    },
    async getUserAgencyActivityChartData(
      { commit },
      { start, end, agencyId }: { start: string; end: string; agencyId?: string },
    ): Promise<ProposalStatusMonthObj[]> {
      if (!start || !end) {
        logger.print(
          'info',
          'store.managment.actions.getUserAgencyActivityChartData',
          'Insufficient data for fetching user agency activity chart data',
        );
        return [];
      }
      const isMonthly = differenceInCalendarMonths(new Date(end), new Date(start)) > 5;

      const { isErr, unwrapErr, unwrap } = await managementService.fetchUserAgencyActivityChartData(
        start,
        end,
        agencyId,
        isMonthly,
      );

      if (isErr()) {
        const error = unwrapErr();
        logger.print('error', 'store.actions.management.getUserAgencyActivityChartData', error.message);
        return [];
      }

      const { DateList = [], MonthList = [] } = unwrap();

      const returnedData = DateList.length ? DateList : MonthList;

      commit(MutationTypes.SET_USER_AGENCY_ACTIVITY_CHART_DATA, returnedData);
      return returnedData;
    },
    async getProposalProductsChartData(
      { commit },
      { start, end }: { start: string; end: string },
    ): Promise<ProposalStatusMonthObj[]> {
      if (!start || !end) {
        logger.print(
          'info',
          'store.managment.actions.getProposalStatusChartData',
          'Insufficient data for fetching proposal status chart data',
        );
        return [];
      }

      const { isErr, unwrapErr, unwrap } = await managementService.fetchProposalProductsChartData(start, end);

      if (isErr()) {
        const error = unwrapErr();
        logger.print('error', 'store.actions.management.getProposalStatusChartData', error.message);
        return [];
      }

      const chartData = unwrap();

      commit(MutationTypes.SET_PROPOSAL_PRODUCTS_CHART_DATA, chartData.MonthList);

      return chartData.MonthList;
    },
  };
};

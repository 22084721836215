
import Vue from 'vue';

import { Models, Services } from '@/injectables/tokens';

import { DateRangePicker } from '@/shared/ui';
import {
  BaseProductModelContract,
  BudgetModelContract,
  CalendarServiceContract,
  FlightModelContract,
  KeywordsModelContract,
  ValidationServiceContract,
} from '@/injectables';

import MoneyField from '@/shared/ui/components/money-field.vue';
import KeywordsInput from '@/shared/ui/components/keywords-input.vue';

export default Vue.extend({
  name: 'BasicFlight',

  inject: ['showSnackbar'],

  useInjectable: [
    Services.Calendar,
    Services.Validation,
    Models.Flight,
    Models.BaseProduct,
    Models.Budget,
    Models.Keywords,
  ],

  props: {
    flight: {
      type: Object,
      required: true,
    },
    flightConfigs: {
      type: Array,
      required: true,
    },
    currentConfig: {
      type: Object,
      required: true,
    },
    productMinDays: {
      type: Number,
      default: 0,
    },
    productMaxSpend: {
      type: Number,
      default: 0,
    },
    productMinSpend: {
      type: Number,
      default: 0,
    },
    isGeofence: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  components: { KeywordsInput, DateRangePicker, MoneyField },

  data(): {
    initialRateValue: number;
    valid: boolean;
    datesMenu: boolean;
    rateFieldKey: number;
  } {
    return {
      initialRateValue: 0,
      valid: true,
      datesMenu: false,
      rateFieldKey: 0,
    };
  },
  mounted(): void {
    this.initialRateValue = this.rate;
  },

  computed: {
    showContextualKeywords() {
      return (this[Models.Keywords] as KeywordsModelContract).shouldShowContextualKeywords(this.currentConfig);
    },
    rules() {
      const validationService: ValidationServiceContract = this.validationService;

      return {
        required: validationService.requiredValidatorFactory(),
        requiredMultiple: validationService.maxLengthValidatorFactory(),
        minRange: validationService.minRangeValidatorFactory(),
        minBudget: validationService.minBudgetValidatorFactory(),
      };
    },
    oneThousandDays(): string {
      const [from = new Date()] = this.range;
      return (this.calendarService as CalendarServiceContract).dateAfter(1000, new Date(from)).toISOString();
    },
    quantity() {
      return (this[Models.Budget] as BudgetModelContract).getQuantityFromBudget(this.budget, this.rate, this.rateType);
    },
    budget: {
      get(): number {
        return this.flight.budget;
      },
      set(val: string | number) {
        const newFlight = { ...this.flight, budget: val };
        if (this.isRateQuote) {
          newFlight.rate = val;
          newFlight.isLocked = true;
        }
        if (this.isRateCpm) {
          newFlight.isLocked = false;
        }
        this.updateFlight(newFlight);
      },
    },
    targetingOption: {
      get(): string {
        return this.flight.targetingOption;
      },
      set(val: string) {
        const config = this.findConfig(val);
        const newFlight = {
          ...this.flight,
          targetingOption: val,
          rateType: config.rateType,
          rate: config.rate,
          flightCategory: config?.flightCategory,
        };
        if ((this[Models.BaseProduct] as BaseProductModelContract).isFlightRateTypeQuote(config)) {
          newFlight.rate = this.budget;
          newFlight.isLocked = true;
        }
        if ((this[Models.BaseProduct] as BaseProductModelContract).isCostPerFlightRate(config)) {
          newFlight.isLocked = true;
          newFlight.budget = config.rate;
        }
        this.initialRateValue = config.rate;
        this.updateFlight(newFlight);
      },
    },
    flightBudgetLocked() {
      return this.flight.isLocked && !this.isRateCpm;
    },
    targetingOptions() {
      return this.flightConfigs.filter(f => f?.targetingOption).map(f => f.targetingOption) || [];
    },
    secondaryOptions() {
      return this.currentConfig?.adFormatList || [];
    },
    range: {
      get(): string[] {
        return [this.flight.startDate, this.flight.endDate].filter(Boolean);
      },
      set(val: string[]) {
        const newFlight = { ...this.flight, startDate: val[0], endDate: val[1] };
        this.updateFlight(newFlight);
      },
    },
    rateType() {
      if (!this.currentConfig?.targetingOption) return '';

      return this.currentConfig?.rateType || 'CPC';
    },
    rate: {
      get(): string | number {
        return this.flight.rate;
      },
      set(val: string | number) {
        const newFlight = { ...this.flight, rate: val };
        this.updateFlight(newFlight);
      },
    },
    defaultRate() {
      if (this.currentConfig) {
        return this.currentConfig.rate || 0;
      }
      return 0;
    },
    isAdmin(): boolean {
      return this.$store.getters['auth/isAgencyAdmin'];
    },
    isRateQuote(): boolean {
      return (this[Models.BaseProduct] as BaseProductModelContract).isFlightRateTypeQuote(this.flight);
    },
    isRateCpm(): boolean {
      return (this[Models.BaseProduct] as BaseProductModelContract).isFlightRateTypeCpm(this.flight);
    },
  },
  methods: {
    keywordsUpdated(contextualKeywords: string[]) {
      this.updateFlight({ ...this.flight, contextualKeywords });
    },
    dateFormatter(date: Date) {
      return (this.calendarService as CalendarServiceContract).dateFormatter(date);
    },
    quantityUpdated(e) {
      const newVal = e.target.value;
      const newBudget = (this[Models.Budget] as BudgetModelContract).getBudgetFromQuantity(
        newVal,
        this.rate,
        this.rateType,
      );
      this.updateFlight({
        ...this.flight,
        budget: newBudget,
        isLocked: true,
      });
    },
    validateRate(e) {
      const value = e?.target?.value;

      const { markupMax: max, markupMin: min } = this.flightConfigs[0];

      const { isErr, unwrapErr, unwrap } = (this.flightEntity as FlightModelContract).validateRate(
        value,
        this.defaultRate,
        this.initialRateValue,
        { min, max },
        this.isAdmin,
      );

      if (isErr()) {
        const { message, rate } = unwrapErr();
        this.showSnackbar(message, 'warning');
        this.rate = rate;
        // TODO: this.rateFieldKey is used as hack to rerender field.
        // Need to rework validate flow with field
        this.rateFieldKey = this.rateFieldKey + 1;
        return;
      }

      this.rate = unwrap();
    },

    updateFlight(flight) {
      this.$emit('update-flight', flight);
    },
    findConfig(val) {
      return this.flightConfigs.find(c => c?.targetingOption === val);
    },
  },
});


import Vue from 'vue';
import CsvUploadDialog from '@/components/CsvUploadDialog.vue';
import { SemKeyword } from '@/shared/types';
import { KeywordsServiceContract, GeoModelContract } from '@/injectables';
import { Services, Models } from '@/injectables/tokens';
import KeywordBody from '../../../entities/instantIO/ui/product-configuration/Keywords.vue';
import KeywordHeader from '@/components/keywords/KeywordHeader.vue';

export default Vue.extend({
  name: 'ProductSemKeyword',

  useInjectable: [Services.Keywords, Models.Geo],

  components: { CsvUploadDialog, KeywordBody, KeywordHeader },

  props: {
    product: {
      type: Object,
      required: true,
    },
    geoSelection: {
      type: Object,
    },
    budget: {
      type: Number,
      default: 1000,
    },
    dates: {
      type: Array,
      default: () => [],
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    agencyPropertyId: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data: (): {
    keywordIdeas: string[];
    customKeywords: string[];
    newKeywordIdea: string;
    newCustomKeyword: string;
    openFileUpload: boolean;
    publicPath: string;
    loading: {
      keywords: boolean;
      customKeywords: boolean;
      summary: boolean;
    };
  } => ({
    newCustomKeyword: '',
    newKeywordIdea: '',
    keywordIdeas: [],
    customKeywords: [],
    openFileUpload: false,
    publicPath: process.env.BASE_URL,
    loading: {
      keywords: false,
      customKeywords: false,
      summary: false,
    },
  }),

  watch: {
    async budget() {
      if (!this.generatedKeywords.length) return;

      const summary = await this.getKeywordsSummary(this.generatedKeywords);

      this.$emit('update-product', { ...this.product, keywords: { list: this.generatedKeywords, summary } });
    },
  },

  computed: {
    uploadHelperText(): string {
      return `CSV file smaller than 2MB. Max ${this.entryLimit} keywords allowed`;
    },
    entryLimit(): number {
      return !this.generatedKeywords?.length
        ? (this.keywordsService as KeywordsServiceContract).ideasLimit
        : (this.keywordsService as KeywordsServiceContract).keywordEntryLimit;
    },
    generatedKeywords(): SemKeyword[] {
      return this.product.keywords?.list || [];
    },
    keywordSummary(): SemKeyword[] {
      return this.product.keywords?.summary || null;
    },
    cleanGeo() {
      return (this[Models.Geo] as GeoModelContract).flatGeoForGeoService(this.geoSelection);
    },
  },

  methods: {
    updateNewKeywordIdea(value: string): void {
      this.newKeywordIdea = value;
    },
    updateNewCustomKeyword(value: string): void {
      this.newCustomKeyword = value;
    },
    clearKeywords(): void {
      this.keywordIdeas = [];
      this.$emit('update-product', { ...this.product, keywords: { list: [] } });
    },
    async getSemKeywords({ keywords, geoSelection, budget, agencyPropertyId }) {
      this.loading.keywords = true;
      const { isErr, unwrap, unwrapErr } = await this.keywordsService.getSemKeywords({
        agencyPropertyId,
        keywords,
        geoSelection,
        budget,
      });
      this.loading.keywords = false;

      if (isErr()) {
        const { message = 'Received an unexpected response. Please try again later.' } = unwrapErr();
        this.$log('error', 'store.product.actions.getSemKeywords', message);

        return [];
      }

      const result = unwrap();
      if (result && Array.isArray(result)) {
        return result;
      }
      return [];
    },
    async getCustomKeywords({ keywords }) {
      this.loading.customKeywords = true;

      const { isErr, unwrap, unwrapErr } = await (this.keywordsService as KeywordsServiceContract).getCustomKeywords({
        agencyPropertyId: this.agencyPropertyId,
        keywords,
      });
      this.loading.customKeywords = false;

      if (isErr()) {
        const { message = 'Received an unexpected response. Please try again later.' } = unwrapErr();
        this.$log('error', 'instantIO.getCustomKeywords', message);

        return [];
      }
      const result = unwrap();
      if (result && Array.isArray(result)) {
        const allKeywords = [...this.product.keywords, ...result];
        return allKeywords;
      }
      return [];
    },
    async getKeywordsSummary(keywords) {
      this.loading.summary = true;

      const { isErr, unwrap, unwrapErr } = await (this.keywordsService as KeywordsServiceContract).getKeywordsSummary({
        keywords,
        budget: this.budget,
      });
      this.loading.summary = false;

      if (isErr()) {
        const { message = 'Received an unexpected response. Please try again later.' } = unwrapErr();
        this.$log('error', 'instantIO.getKeywordSummary', message);

        return {};
      }
      return unwrap();
    },
    async generateKeywords(): Promise<void> {
      this.saveKeywordIdea();
      const payload = {
        keywords: this.keywordIdeas,
        geoSelection: this.cleanGeo || {},
        budget: this.budget,
      };
      const keywords = await this.getSemKeywords(payload);
      const summary = await this.getKeywordsSummary(keywords);
      this.$emit('update-product', { ...this.product, keywords: { list: keywords, summary } });
    },
    async fetchCustomKeywords(): Promise<void> {
      this.saveCustomKeyword();
      if (!this.customKeywords?.length) {
        return;
      }
      const payload = { keywords: this.customKeywords, geoSelection: this.cleanGeo };

      const result = await this.getCustomKeywords(payload);

      if (!result?.length) return;

      this.customKeywords = [];
      const keywords = [...this.generatedKeywords, ...result];
      const summary = await this.getKeywordsSummary(keywords);

      this.$emit('update-product', { ...this.product, keywords: { list: keywords, summary } });
    },
    toggleSelectedKeyword(keyword: string): void {
      const result = [...this.generatedKeywords];
      const index = result.findIndex(item => item.keyword === keyword);
      if (index === -1) return;

      result.splice(index, 1, { ...result[index], isSelected: !result[index].isSelected });
      this.$emit('update-product', { ...this.product, keywords: { list: result } });
    },
    removeCustomKeyword(keyword: string): void {
      const keywordIndex = this.customKeywords.indexOf(keyword);
      if (keywordIndex !== -1) {
        this.customKeywords.splice(keywordIndex, 1);
      }
    },
    removeIdea(idea: string): void {
      const ideaIndex = this.keywordIdeas.indexOf(idea);
      if (ideaIndex !== -1) {
        this.keywordIdeas.splice(ideaIndex, 1);
      }
    },
    saveCustomKeyword(): void {
      const trimmed = this.newCustomKeyword.trim();
      if (
        trimmed.length &&
        !this.customKeywords.includes(trimmed) &&
        !this.generatedKeywords.some(kw => kw.keyword === trimmed)
      ) {
        this.customKeywords.push(trimmed);
        this.newCustomKeyword = '';
      }
    },
    saveKeywordIdea(): void {
      const trimmed = this.newKeywordIdea.trim();
      if (trimmed.length && !this.keywordIdeas.includes(trimmed)) {
        this.keywordIdeas.push(trimmed);
        this.newKeywordIdea = '';
      }
    },
    applyUploadedFile(keywords: Array<string> = []): void {
      if (!keywords.length) return;
      let hasDuplicates = false;
      let hasMaxKeywords = false;

      if (this.generatedKeywords.length) {
        keywords.forEach(i => {
          if (this.customKeywords.length >= this.entryLimit) {
            hasMaxKeywords = true;
          } else if (this.customKeywords.indexOf(i) === -1) {
            this.customKeywords.push(i);
          } else {
            hasDuplicates = true;
          }
        });
      } else {
        keywords.forEach(i => {
          if (this.keywordIdeas.length >= this.entryLimit) {
            hasMaxKeywords = true;
          } else if (this.keywordIdeas.indexOf(i) === -1) {
            this.keywordIdeas.push(i);
          } else {
            hasDuplicates = true;
          }
        });
      }

      if (hasDuplicates || hasMaxKeywords) {
        const snackbarMessage = hasMaxKeywords
          ? `Only ${this.entryLimit} entries permitted at a time, some have been removed`
          : 'Some keywords already added';
        this.$store.dispatch('showSnackbar', { content: snackbarMessage, color: 'warning' });
      }
    },
  },
});

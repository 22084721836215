import { Result, Ok, Err } from '@sniptt/monads';
import { injectable } from 'inversify';

import { HelpSectionService as RestHelpSectionService } from './http';
import { Failure } from '@/injectables/failure';
import { HelpPage, NewHelpPage } from '../contracts';
import { GetPagesDocument, GetPagesQuery, GetPagesQueryVariables } from '../graphql/queries/get-pages.generated';
import {
  CreatePageDocument,
  CreatePageMutation,
  CreatePageMutationVariables,
} from '../graphql/mutations/create-page.generated';
import {
  DeletePageDocument,
  DeletePageMutation,
  DeletePageMutationVariables,
} from '../graphql/mutations/delete-page.generated';
import {
  UpdatePageDocument,
  UpdatePageMutation,
  UpdatePageMutationVariables,
} from '../graphql/mutations/update-page.generated';
import { mapCommonNewPageToGraphqlPage, mapCommonPageToGraphqlPage, mapGraphqlPageToCommon } from '../utils';

@injectable()
export class HelpSectionService extends RestHelpSectionService {
  async getPages(): Promise<Result<HelpPage[], Failure>> {
    try {
      const { data, error } = await this._apollo.query<GetPagesQuery, GetPagesQueryVariables>({
        query: GetPagesDocument,
      });

      if (error) {
        return Err({
          message: "Can't load pages at this time.",
        });
      }

      const pages = data.getPages.map(mapGraphqlPageToCommon);
      return Ok(pages);
    } catch (error) {
      return Err({
        message: `Can't load pages at this time: ${error}`,
      });
    }
  }

  async updatePage(page: HelpPage): Promise<Result<HelpPage, Failure>> {
    try {
      const input = mapCommonPageToGraphqlPage(page);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
      const { pageList, ...pageInput } = input;

      const { data } = await this._apollo.mutate<UpdatePageMutation, UpdatePageMutationVariables>({
        mutation: UpdatePageDocument,
        variables: { input: pageInput },
      });

      const result = mapGraphqlPageToCommon(data.updatePage);

      return Ok(result);
    } catch (error) {
      return Err({
        message: "Can't update page at this time.",
      });
    }
  }

  async createPage(page: NewHelpPage, pageId): Promise<Result<HelpPage, Failure>> {
    try {
      const input = {
        ...mapCommonNewPageToGraphqlPage(page),
        ...(pageId ? { parentId: pageId } : {}),
      };

      const { data } = await this._apollo.mutate<CreatePageMutation, CreatePageMutationVariables>({
        mutation: CreatePageDocument,
        variables: { input },
      });

      const result = mapGraphqlPageToCommon(data.createPage);

      return Ok(result);
    } catch (error) {
      return Err({
        message: "Can't create page at this time.",
      });
    }
  }

  async deletePage(pageId: string): Promise<Result<HelpPage, Failure>> {
    try {
      const { data } = await this._apollo.mutate<DeletePageMutation, DeletePageMutationVariables>({
        mutation: DeletePageDocument,
        variables: { id: pageId },
      });

      const result = mapGraphqlPageToCommon(data.deletePage);

      return Ok(result);
    } catch (error) {
      return Err({
        message: "Can't delete page at this time.",
      });
    }
  }
}


import Vue from 'vue';
import { pageActionItems } from '../config/page-actions-items';

export default Vue.extend({
  name: 'PageListActions',

  props: {
    addPageUnavailable: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Boolean,
      required: true,
    },
    disabledActions: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    showDialog: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean) {
        this.$emit('input', value);
      },
    },
    actionItems(): Array<{ icon: string; label: string; action: string; disabled?: boolean }> {
      return pageActionItems;
    },
  },

  methods: {
    hideDialog() {
      this.showDialog = false;
    },
  },
});

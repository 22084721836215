/* eslint-disable no-unused-vars */
export enum Category {
  DigitalAudio = 'Digital Audio',
  DigitalVideo = 'Digital Video',
  Display = 'Display',
  EmailMarketing = 'EmailMarketing',
  Geofence = 'Geofence',
  Linear = 'Linear',
  OTT = 'OTT',
  Radio = 'Radio',
  Search = 'Search',
  Social = 'Social',
  Video = 'Video',
  Other = 'Other',
  Package = 'Package',
}

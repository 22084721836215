/**
 * Old Mediaplanner color scheme.
 * TODO: remove after redesign.
 */
export const theme = {
  background: '#ECEFF1',
  red: '#FF5252',
  orange: '#FF9100',
  yellow: '#FEBC2C',
  purple: '#AB47BC',
  // black: '#1B1B1D',
  gray5: '#636366',
  gray4: '#8E8E93',
  gray3: '#AEAEB2',
  gray2: '#D1D1D6',
  gray1: '#F2F2F7',
  defaultRectangle: '#add8e6',
  defaultCircle: '#77dd77',
  defaultTriangle: '#ff6961',
  selectedText: '#c4c4c4',
};

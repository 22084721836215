
import Vue from 'vue';
export default Vue.extend({
  name: 'WrapperWithTooltip',
  props: {
    tooltipText: {
      default: '',
      type: String,
    },
    classes: {
      default: '',
      type: String,
    },
    wrapperClasses: {
      default: '',
      type: String,
    },
    tooltipTop: {
      default: false,
      type: Boolean,
    },
    tooltipBottom: {
      default: false,
      type: Boolean,
    },
    tooltipLeft: {
      default: false,
      type: Boolean,
    },
    tooltipRight: {
      default: false,
      type: Boolean,
    },
    stopPropagation: {
      default: true,
      type: Boolean,
    },
    nestedInputRef: {
      default: '',
      type: String,
    },
  },
  data: (): {
    tooltipDisabled: boolean;
  } => ({
    tooltipDisabled: true,
  }),
  methods: {
    checkTooltipVisibility() {
      this.$nextTick(() => {
        if (this.nestedInputRef) {
          if (
            this.$scopedSlots.default()[0]?.context?.$refs[this.nestedInputRef]?.$refs?.input?.scrollWidth >
            this.$scopedSlots.default()[0]?.context?.$refs[this.nestedInputRef]?.$refs?.input?.offsetWidth
          ) {
            this.tooltipDisabled = false;
          } else {
            this.tooltipDisabled = true;
          }
        } else {
          if (this.$refs?.textContainer?.scrollWidth > this.$refs?.textContainer?.offsetWidth) {
            this.tooltipDisabled = false;
          } else {
            this.tooltipDisabled = true;
          }
        }
      });
    },
    onClick(e) {
      if (this.stopPropagation) {
        e.preventDefault();
        e.stopPropagation();
      }

      this.$emit('item-clicked');
    },
  },
});

import 'reflect-metadata';
import { UIUtilsServiceContract } from '@/injectables';
import { injectable } from 'inversify';

@injectable()
export class UIUtilsService implements UIUtilsServiceContract {
  getUniqueId(): string {
    const uint32 = crypto.getRandomValues(new Uint32Array(1))[0];
    return uint32.toString(16);
  }

  bigNumberFormatter(value: number): number | string {
    let result: number | string = value;

    if (value >= 1000) {
      result = (value / 1000).toFixed(0).replace(/\.0$/, '') + 'K';
    }

    if (value >= 1000000) {
      result = (value / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
    }

    if (value >= 1000000000) {
      result = (value / 1000000000).toFixed(1).replace(/\.0$/, '') + 'B';
    }

    return result;
  }

  throttle(type: string, name: string, obj: Window & typeof globalThis): void {
    obj = obj || window;
    let running = false;
    const func = (): void => {
      if (running) {
        return;
      }
      running = true;
      requestAnimationFrame(() => {
        obj.dispatchEvent(new CustomEvent(name));
        running = false;
      });
    };
    obj.addEventListener(type, func);
  }
}

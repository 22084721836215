import { AgencyActivityQuery } from '../graphql/query/get-agency-activity-chart.generated';
import { FetchProposalProductsChartQuery } from '../graphql/query/get-proposal-products-count.generated';
import { FetchProposalStatusChartQuery } from '../graphql/query/get-proposal-status-chart.generated';
import { ProposalStatusChartData, ProposalStatusMonthObj } from '@/shared/types';

export type StatusChartDataMapped = (
  stat: FetchProposalStatusChartQuery['MonthList'],
) => ProposalStatusChartData['MonthList'];

export type ProposalProductsChartDataMapped = (
  stat: FetchProposalProductsChartQuery['MonthList'],
) => ProposalStatusChartData['MonthList'];

/**
 * Add not necessary fields to proposal (required in interfaces but not in actual use)
 */
export const statusChartDataMapped: StatusChartDataMapped = (
  stat: FetchProposalStatusChartQuery['MonthList'],
): ProposalStatusChartData['MonthList'] => {
  const reattachedList: ProposalStatusMonthObj[] = stat.map(el => {
    const reassignedStatuses: Record<string, { Revenue: number; Count: number }> = el.stats.reduce((acc, el) => {
      const { Revenue, Count } = el;
      acc[el.status] = { Revenue, Count };
      return acc;
    }, {});
    const { Month } = el;
    return { Month, ...reassignedStatuses };
  });
  return reattachedList;
};

export const proposalProductsChartDataMapped: ProposalProductsChartDataMapped = (
  stat: FetchProposalProductsChartQuery['MonthList'],
): ProposalStatusChartData['MonthList'] => {
  const reattachedList: ProposalStatusMonthObj[] = stat.map(el => {
    const reassignedStatuses: Record<string, { Revenue: number; Count: number }> = el.stats.reduce((acc, el) => {
      const { Count, middleBudget: Revenue } = el;
      acc[el.status] = { Count: Math.round(Count * 10) / 10, Revenue: Math.round(Revenue) };
      return acc;
    }, {});
    const { Month } = el;
    return { Month, ...reassignedStatuses };
  });
  return reattachedList;
};

export const filterZeroResults = (data): AgencyActivityQuery['usageTrackerComponent']['DateList'] => {
  const stat: { agency: Record<string, number>; users: Record<string, number> } = data.reduce(
    (acc, data) => {
      data.ByUserList.forEach(userData => {
        const user = userData.User;
        acc.users[user] = (acc.users[user] || 0) + userData.Revenue;
      });

      data.ByAgencyList.forEach(agencyData => {
        const agency = agencyData.Agency;
        acc.agency[agency] = (acc.agency[agency] || 0) + agencyData.Revenue;
      });
      return acc;
    },
    { users: {}, agency: {} },
  );

  const allUsers = Object.keys(stat?.users);
  const allAgencies = Object.keys(stat?.agency);

  // TODO: optimize it
  const addZeroUserValues = el => {
    for (const user of allUsers) {
      if (el.ByUserList?.find(u => u.User === user)) {
        continue;
      } else {
        el.ByUserList.push({ User: user, Revenue: 0, Count: 0 });
      }
    }
    return {
      ...el,
    };
  };

  const addZeroAgencyValues = el => {
    for (const agency of allAgencies) {
      if (el.ByAgencyList?.find(a => a.Agency === agency)) {
        continue;
      } else {
        el.ByAgencyList.push({ Agency: agency, Revenue: 0, Count: 0 });
      }
    }
    return {
      ...el,
    };
  };

  const zeroUserData = new Set(
    Object.entries(stat.users)
      .map(([key, value]) => (value ? undefined : key))
      .filter(Boolean),
  );
  const zeroAgencyData = new Set(
    Object.entries(stat.agency)
      .map(([key, value]) => (value ? undefined : key))
      .filter(Boolean),
  );

  const sliceNumber = 10;

  const whiteUserList = new Set(
    Object.entries(stat.users)
      .sort(([, valueA], [, valueB]) => valueB - valueA)
      .slice(0, sliceNumber)
      .map(([key]) => key),
  );
  const whiteAgencyList = new Set(
    Object.entries(stat.agency)
      .sort(([, valueA], [, valueB]) => valueB - valueA)
      .slice(0, sliceNumber)
      .map(([key]) => key),
  );

  return data
    .map(data => ({
      ...data,
      ByUserList: data.ByUserList.filter(
        userData => !zeroUserData.has(userData.User) && whiteUserList.has(userData.User),
      ).reduce((acc, el) => {
        const existingUserData = acc.find(userData => userData?.User === el?.User);
        if (existingUserData) {
          existingUserData.Revenue += el?.Revenue;
          existingUserData.Count += el?.Count;
          return acc;
        } else {
          return [...acc, el];
        }
      }, []),
      ByAgencyList: data.ByAgencyList.filter(
        userData => !zeroAgencyData.has(userData.Agency) && whiteAgencyList.has(userData.Agency),
      ).reduce((acc, el) => {
        const existingAgencyData = acc.find(userData => userData?.Agency === el?.Agency);
        if (existingAgencyData) {
          existingAgencyData.Revenue += el?.Revenue;
          existingAgencyData.Count += el?.Count;
          return acc;
        } else {
          return [...acc, el];
        }
      }, []),
    }))
    .map(addZeroUserValues)
    .map(addZeroAgencyValues);
};

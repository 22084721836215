import { Module } from 'vuex';

import { state, AgencyState } from './state';
import { actions } from './actions';
import { mutations } from './mutation';
import { Container } from 'inversify';
import { RootState } from '../root/state';

interface AgencyModuleFactoryReturn {
  module: Module<AgencyState, RootState>;
}

export const agencyModule = (container: Container): AgencyModuleFactoryReturn => {
  return {
    module: {
      namespaced: true as const,
      state: state(),
      mutations: mutations(),
      actions: actions(container),
    },
  };
};

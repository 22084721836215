import 'reflect-metadata';

import { Service } from '../../../service';
import { Err, Ok, Result } from '@sniptt/monads';
import { Failure } from '@/injectables/failure';

import { injectable } from 'inversify';

import { ProductSettings, XmlReturnType } from '@/injectables';

import { GetProductResult, Product } from '@/shared/types';

@injectable()
export class ProductService extends Service {
  async saveProductSettings(settings: ProductSettings, agencyId: string): Promise<Result<Product, Failure>> {
    try {
      const productSettings = {
        productId: settings.productId,
        name: settings.name,
        minSpend: settings.minSpend,
        recommendedBudget: settings.recommendedBudget,
        minDays: settings.minDays,
        tooltip: settings.description?.short,
        isHidden: settings.isHidden,
        agencyPropertyId: agencyId,
        ...(settings.hasOwnProperty('cpcMargin') ? { cpcMargin: settings.cpcMargin } : {}),
        fulfillmentMethod: settings.fulfillmentMethod,
        OMSName: settings.OMSName,
        OMSAccountNumber: settings.OMSAccountNumber,
        IORecipientEmail: settings.IORecipientEmail,
        targetingOptions: settings.targetingOptions.map(option => ({
          targetingOption: option.targetingOption,
          rate: option.rate,
          adFormatList: option.adTypes,
          rateType: option.rateType,
        })),
        lowerLimit: settings.lowerLimit,
        upperLimit: settings.upperLimit,
      };
      const { data } = await this._axios.post('/api/updateProductSettings', { productData: productSettings });
      return Ok(data);
    } catch (error) {
      return Err({
        message: `Can't save product settings at this time`,
      });
    }
  }

  async uploadXml({
    agencyPropertyId,
    packagePpid,
    selectedProductPpid,
    fileContent,
  }: {
    agencyPropertyId: string;
    packagePpid: string;
    selectedProductPpid: string;
    fileContent: string;
  }): Promise<Result<XmlReturnType, Failure>> {
    const uploadUrl = `/api/package/xml/upload?agencyPropertyId=${agencyPropertyId}&packagePropertyId=${packagePpid}&selectedProductPropertyId=${selectedProductPpid}`;
    try {
      const { data } = await this._axios.post(uploadUrl, fileContent, {
        headers: { 'Content-Type': 'text/plain' },
      });

      if (data.message) {
        return Err({ message: "Can't upload this file. Try another one" });
      }

      return Ok(data);
    } catch (error) {
      return Err({ message: "Can't upload this file. Try another one" });
    }
  }

  async getProducts({
    agencyPropertyId,
    level,
  }: {
    agencyPropertyId: string;
    level: string;
  }): Promise<Result<GetProductResult, Failure>> {
    try {
      const url = '/api/products';

      const { data } = await this._axios.post(url, {
        agencyPropertyId,
        level,
      });

      return Ok(data);
    } catch (error) {
      return Err(error);
    }
  }
}

import {
  ProposalGeoSelections,
  ProposalRevenueData,
  Client,
  NewClient,
  ClientInsights,
  ClientProspect,
  AgencyListItem,
} from '@/shared/types';

export interface ClientState {
  newClient: NewClient;
  activeClientId: string | null;
  loadingClients: boolean;
  loadingClient: boolean;
  loadingClientLogos: boolean;
  loadingCategories: boolean;
  loadingAgencies: boolean;
  loadingLookup: boolean;
  allAgencies: { list: AgencyListItem[]; loaded: boolean };
  updateClientLoading: boolean;
  updateLogoLoading: boolean;
  newClientLoading: boolean;
  possibleDuplicates: Client[];
  activeClient: Client | null;
  activeClientPurchaseHistory?: ProposalRevenueData[]; // incorrect naming, but we're using same data structure
  filteredClients: Client[];
  clients: {
    loaded: boolean;
    list?: Client[];
  };
  categories: {
    loaded: boolean;
    list?: string[];
  };
  activeClientGeoSelections: ProposalGeoSelections;
  activeClientInsights: ClientInsights;
  activeClientProspect: ClientProspect;
  activeClientInsightsLoading: boolean;
  activeClientProspectLoading: boolean;
  activeClientProspectRefreshing: boolean;
}

export const state = (): ClientState => ({
  newClientLoading: false,
  activeClient: null,
  newClient: {
    AgencyPartner: '',
    name: '',
    category: { name: '', id: '' },
    WURL: '',
    Phone: '',
    locations: [],
  },
  possibleDuplicates: [],
  activeClientId: null,
  clients: { loaded: false },
  filteredClients: [],
  categories: { loaded: false },
  updateClientLoading: false,
  updateLogoLoading: false,
  loadingClients: false,
  loadingClient: false,
  loadingClientLogos: false,
  loadingCategories: false,
  loadingAgencies: false,
  loadingLookup: false,
  allAgencies: { loaded: false, list: [] },
  activeClientPurchaseHistory: [],
  activeClientGeoSelections: {
    cityList: [],
    zipList: [],
    dmaList: [],
    stateList: [],
    countyList: [],
  },
  activeClientInsights: null,
  activeClientProspect: null,
  activeClientInsightsLoading: false,
  activeClientProspectLoading: false,
  activeClientProspectRefreshing: false,
});

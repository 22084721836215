import * as Types from '../../../../../app/graphql/_types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type GetCategoriesQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetCategoriesQuery = {
  getCategories?: Array<{ id: string; name: string; WebsiteReady: boolean; RegionalAddress: boolean } | null> | null;
};

export const GetCategoriesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getCategories' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getCategories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'WebsiteReady' },
                  name: { kind: 'Name', value: 'websiteReady' },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'RegionalAddress' },
                  name: { kind: 'Name', value: 'regionalAddress' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetCategoriesQuery, GetCategoriesQueryVariables>;

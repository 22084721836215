import { Failure } from '@/injectables/failure';
import {
  BaseProductModel,
  CreateCreativeInput,
  CreativeServiceContract,
  Creatives,
  RemoveCreativeInput,
} from '@/injectables';
import { Err, Ok, Result } from '@sniptt/monads/build';
import { Service } from '@/injectables/service';
import { inject, injectable } from 'inversify';
import {
  CreateCreativeDocument,
  CreateCreativeMutation,
  CreateCreativeMutationVariables,
} from '../graphql/mutations/create-creative.generated';
import {
  UpdateCreativeDocument,
  UpdateCreativeMutation,
  UpdateCreativeMutationVariables,
} from '../graphql/mutations/update-creative.generated';
import {
  DeleteCreativeDocument,
  DeleteCreativeMutation,
  DeleteCreativeMutationVariables,
} from '../graphql/mutations/delete-creative.generated';
import { Models } from '@/injectables/tokens';

@injectable()
export class CreativeService extends Service implements CreativeServiceContract {
  @inject(Models.BaseProduct) _baseProduct: BaseProductModel;

  async create(input: CreateCreativeInput): Promise<Result<CreateCreativeMutation['createProductCreative'], Failure>> {
    try {
      const { data } = await this._apollo.mutate<CreateCreativeMutation, CreateCreativeMutationVariables>({
        mutation: CreateCreativeDocument,
        variables: {
          input,
        },
      });

      const { creatives, ...product } = data.createProductCreative;
      return Ok({
        ...product,
        creatives: this.sortCreatives(creatives).map(this._baseProduct.setFlightIdsList),
      });
    } catch (error) {
      return Err({ message: "Can't create creative at this time." });
    }
  }

  async update(
    input: UpdateCreativeMutationVariables['input'],
  ): Promise<Result<UpdateCreativeMutation['updateProductCreative'], Failure>> {
    try {
      const { data } = await this._apollo.mutate<UpdateCreativeMutation, UpdateCreativeMutationVariables>({
        mutation: UpdateCreativeDocument,
        variables: {
          input,
        },
      });

      const { creatives, ...product } = data.updateProductCreative;
      return Ok({
        ...product,
        creatives: this.sortCreatives(creatives).map(this._baseProduct.setFlightIdsList),
      });
    } catch (error) {
      return Err({ message: "Can't update creative at this time." });
    }
  }

  async remove(input: RemoveCreativeInput): Promise<Result<DeleteCreativeMutation['deleteProductCreative'], Failure>> {
    try {
      const { data } = await this._apollo.mutate<DeleteCreativeMutation, DeleteCreativeMutationVariables>({
        mutation: DeleteCreativeDocument,
        variables: {
          id: input.creativeId,
          agencyId: input.agencyId,
        },
      });

      const { creatives, ...product } = data.deleteProductCreative;
      return Ok({
        ...product,
        creatives: this.sortCreatives(creatives).map(this._baseProduct.setFlightIdsList),
      });
    } catch (error) {
      return Err({ message: "Can't delete creative at this time." });
    }
  }

  sortCreatives(creatives: Creatives): Creatives {
    return creatives.map((c, index) => ({ ...c, index: c?.index || index })).sort((a, b) => a.index - b.index);
  }
}

import { MutationTree } from 'vuex';
import { EnumsState } from './state';

export const mutations = (): MutationTree<EnumsState> => {
  return {
    SET_RATE_TYPES(state, types: string[]) {
      state.rateTypes = [...types];
    },
  };
};

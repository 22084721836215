
import Vue from 'vue';
import { AgencyServiceContract, InstantIOServiceContract, NewProposalServiceContract } from '@/injectables';
import { Services } from '@/injectables/tokens';
import { GetUsersByAgencyQuery } from '@/injectables/services/agency/graphql/query/get-users-by-agency.generated';
import { AgencyInfo } from '@/shared/types';
export default Vue.extend({
  name: 'ReassignCampaignModal',
  useInjectable: [Services.Agency, Services.NewProposal, Services.InstantIO],
  props: {
    dialog: {
      type: Boolean,
    },
    campaignId: {
      type: String,
      required: true,
    },
    campaignName: {
      type: String,
      default: 'Campaign',
    },
    campaignType: {
      type: String,
      default: 'Campaign',
    },
    createdBy: {
      type: Object,
      required: true,
    },
  },
  data: (): { loading: boolean; selectedUser: string; users: GetUsersByAgencyQuery['getUsersByAgencyId'] } => ({
    loading: false,
    users: null,
    selectedUser: null,
  }),
  computed: {
    userAgency(): AgencyInfo {
      return this.$store.state.auth.user.agency;
    },
    openDialog: {
      get(): boolean {
        return this.dialog;
      },
      set(val: boolean): void {
        if (!val) {
          this.closeDialog();
        }
      },
    },
  },
  mounted(): void {
    this.getUsers();
  },
  methods: {
    async onSave(): Promise<void> {
      const handlerMap = {
        Proposal: this.reassignProposal,
        Campaign: this.reassignInstantIO,
      };
      handlerMap?.[this.campaignType]();
    },
    async reassignProposal(): Promise<void> {
      this.loading = true;

      const { isErr } = await (this.newProposalService as NewProposalServiceContract).setProposalCreatedBy({
        proposalId: this.campaignId,
        userId: this.selectedUser,
      });

      this.loading = false;

      if (isErr()) {
        return;
      }

      this.$emit('refetch-campaigns');
      this.closeDialog();
    },
    async reassignInstantIO(): Promise<void> {
      this.loading = true;

      const { isErr } = await (this.instantIOService as InstantIOServiceContract).setCampaignCreatedBy({
        campaignId: this.campaignId,
        userId: this.selectedUser,
      });

      this.loading = false;

      if (isErr()) {
        return;
      }

      this.$emit('refetch-campaigns');
      this.closeDialog();
    },
    closeDialog(): void {
      this.$emit('close-modal');
    },
    async getUsers(): Promise<void> {
      this.loading = true;

      const { isErr, unwrap } = await (this.agencyService as AgencyServiceContract).getUsersByAgencyId(
        this.userAgency.id,
        this.userAgency.type,
      );

      this.loading = false;

      if (isErr()) {
        return;
      }

      this.users = unwrap();
      this.selectedUser = this.users.find(
        user => user.email === this.createdBy.email || user.id === this.createdBy.id,
      )?.id;
    },
  },
});

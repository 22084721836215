
import Vue, { PropType } from 'vue';

export enum WButtonVariants {
  Contained = 'contained', // eslint-disable-line no-unused-vars
  Outlined = 'outlined', // eslint-disable-line no-unused-vars
  Text = 'text', // eslint-disable-line no-unused-vars
}

export enum WButtonSize {
  Small = 'small', // eslint-disable-line no-unused-vars
  Medium = 'medium', // eslint-disable-line no-unused-vars
  Large = 'large', // eslint-disable-line no-unused-vars
}

export enum WButtonColors {
  Primary = 'primary', // eslint-disable-line no-unused-vars
  Red = 'red', // eslint-disable-line no-unused-vars
}

const variants = [WButtonVariants.Contained, WButtonVariants.Outlined, WButtonVariants.Text];

const sizes = [WButtonSize.Small, WButtonSize.Medium, WButtonSize.Large];

const colors = Object.values(WButtonColors);

export default Vue.extend({
  name: 'w-button',

  props: {
    variant: {
      type: String as PropType<WButtonVariants>,
      validator: (v: WButtonVariants) => variants.includes(v),
      default: () => WButtonVariants.Contained,
    },
    size: {
      type: String as PropType<WButtonSize>,
      validator: (v: WButtonSize) => sizes.includes(v),
      default: () => WButtonSize.Medium,
    },
    color: {
      type: String as PropType<WButtonColors>,
      validator: (v: WButtonColors) => colors.includes(v),
      default: () => 'primary',
    },
  },

  computed: {
    isIconLeft() {
      return this.$slots['icon-left'];
    },
    isIconRight() {
      return this.$slots['icon-right'];
    },
    isOutlined(): boolean {
      return this.variant === WButtonVariants.Outlined;
    },
    isText(): boolean {
      return this.variant === WButtonVariants.Text;
    },
    isRounded(): boolean {
      return [WButtonVariants.Contained, WButtonVariants.Outlined].includes(this.variant);
    },
    isXLarge(): boolean {
      return this.size === WButtonSize.Large;
    },
    isSmall(): boolean {
      return this.size === WButtonSize.Small;
    },
  },
});

import 'reflect-metadata';
import { inject, injectable } from 'inversify';
import { ConfiguredProductIO, CreativeModelContract, DateModelContract, KeywordsModelContract } from '@/injectables';
import { BaseProductModel } from '../../base-product/implementations';

import { ProductFlight, Product, UnsafeAny } from '@/shared/types';
import { cloneDeep, omit } from 'lodash';
import { ProposalProductModelContract } from '../contracts';
import { ProductConfigCategory } from '@/app/graphql';
import { Models } from '@/injectables/tokens';
import { v4 as uuidv4 } from 'uuid';

@injectable()
export class ProposalProductModel extends BaseProductModel implements ProposalProductModelContract {
  @inject(Models.Date) private _dateModel: DateModelContract;
  @inject(Models.Creatives) private _creatives: CreativeModelContract;
  @inject(Models.Keywords) private readonly _keywords: KeywordsModelContract;

  flightBudgetRecalculation(product: ConfiguredProductIO): ConfiguredProductIO {
    const splitDivide = (div, dev) => {
      const modulo = div % dev;
      const devResult = div / dev;

      const others = parseInt(devResult.toString(), 10);

      const moduloToFifty = others % 50;

      return [others + modulo + moduloToFifty * (dev - 1), others - moduloToFifty];
    };

    const { flights = [], budget: budget } = product;

    const { budgetDiff, flightBudget, unlockedBudget, unlockedFlightCount } = this.budgetInfo(product);
    if (budget < flightBudget - unlockedBudget) {
      const unlocked = flights.map(pr => ({ ...pr, isLocked: false }));
      return this.flightBudgetRecalculation({ ...product, flights: unlocked });
    }
    const [first, others] = splitDivide(unlockedBudget + budgetDiff, unlockedFlightCount);

    let firstFound = false;

    const recalculated: ProductFlight[] = [];
    for (let c = flights.length - 1; c >= 0; c--) {
      const flight = { ...flights[c] };
      if (!flight.isLocked && !flight.isChanged) {
        flight.budget = firstFound ? others : first;
        firstFound = true;
      }
      // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
      const { isChanged, ...fl } = flight;
      recalculated.unshift(fl);
    }

    return { ...product, flights: recalculated };
  }

  budgetInfo(product: ConfiguredProductIO) {
    const { flights, budget: budget } = product;
    const flightBudget = flights.reduce((acc, el) => acc + el.budget, 0);

    const unlockedFlightCount = flights.reduce((acc, el) => (el.isLocked || el.isChanged ? acc : acc + 1), 0);
    const unlockedBudget = flights.reduce((acc, el) => (el.isLocked || el.isChanged ? acc : acc + el.budget), 0);

    return { budgetDiff: budget - flightBudget, flightBudget, unlockedFlightCount, unlockedBudget };
  }

  marketGeoSelectionConfigured(flight): boolean {
    return (
      flight?.market?.geoSelections &&
      Object.keys(flight.market.geoSelections).length &&
      Object.keys(flight.market.geoSelections).some(
        key => Array.isArray(flight.market.geoSelections[key]) && flight.market.geoSelections[key].length,
      )
    );
  }

  flightsConfigured(product: Product): boolean {
    if (!this.hasFlights(product)) return false;

    const { flightConfigs = [] } = product;
    const hasPlatform = this.hasPlatform(flightConfigs);
    const isGeofence = this.isGeofence(flightConfigs);

    return product.flights.every(f => {
      return (
        (!isGeofence || this.mapConfigured(f)) &&
        (!hasPlatform || f?.selectedPlatform?.length) &&
        (!f?.market?.geoSelections || this.marketGeoSelectionConfigured(f)) &&
        f?.targetingOption?.length > 0 &&
        this.flightHasBudget(f) &&
        this._keywords.flightKeywordsConfigured(f)
      );
    });
  }

  emailMarketingConfigured(product: Product): boolean {
    return (
      product?.flights?.length > 0 &&
      product.flights.every(f => {
        return f?.budget >= product.minSpend && (!f?.market?.geoSelections || this.marketGeoSelectionConfigured(f));
      })
    );
  }

  productIsConfigured(product): boolean {
    if (!product) return false;
    if (!product.name) return false;

    if (product.category === ProductConfigCategory.Package) {
      return product.products.every(pr => this.productIsConfigured(pr));
    }

    if (this.isPaidSearchProduct(product)) {
      return this.paidSearchConfigured(product);
    }
    if (this.isXMLProduct(product)) {
      return this.xmlConfiguredProduct(product);
    }
    return this.flightsConfigured(product);
  }

  getUpdatedProduct(product): ConfiguredProductIO {
    if (this.fixedRateConfig(product)) {
      const flightsBudget = Math.max(
        product.flights.reduce((acc, el) => acc + el.budget, 0),
        product.minSpend,
      );
      return {
        ...product,
        budget: flightsBudget,
        isLocked: true,
      };
    }

    return product;
  }

  getNewFlight(product, { budget, dates, geoSelections, demographics = [], audience = [], locations }): ProductFlight {
    const productFlights = [...(product?.flights || [])];

    const flight: ProductFlight = {
      isLocked: false,
      id: 'temp_' + Date.now().toString(),
      index: productFlights.length + 1,
      budget: budget,
      rateType: null,
      rate: 0,
      startDate: dates[0],
      endDate: dates[1],
      ...(!this.hasPlatform(product.flightConfigs) && { selectedPlatform: '' }),
    };

    if (this.isGeofence(product.flightConfigs)) {
      let geoSelections = { addressList: [] };

      if (productFlights.length) {
        const sorted = [...productFlights].sort((a, b) => b.index - a.index);

        const [mostRecent] = sorted;

        if (mostRecent?.market?.geoSelections) {
          geoSelections = cloneDeep(mostRecent.market.geoSelections);
        }
      }

      if (!geoSelections?.addressList?.length && locations?.length && locations.some(l => l?.address)) {
        const mapped = locations
          .filter(l => l.address)
          .map((l, i) => {
            return {
              id: (Date.now() + i * 10000).toString(),
              address: l.address,
              radius: '400',
              unitType: 'Meters',
              ...(l?.lat && l?.lon ? { lat: l.lat, lon: l.lon } : {}),
            };
          });

        geoSelections = { addressList: [...mapped] };
      }

      flight.market = { geoSelections: cloneDeep({ addressList: geoSelections.addressList }) };
    } else {
      let newGeoSelections = geoSelections;

      if (productFlights.length) {
        const sorted = [...productFlights].sort((a, b) => a.index - b.index);

        const [mostRecent] = sorted;

        if (mostRecent?.market?.geoSelections) {
          newGeoSelections = mostRecent.market.geoSelections;
        }
      }

      flight.market = { geoSelections: cloneDeep(newGeoSelections) };
      flight.demographics = demographics;
      flight.audience = audience;
    }

    return flight;
  }

  getKeywordGeo(geo) {
    return omit(geo, 'zipList');
  }

  getProductFromConfig(
    productConfig,
    info: { clientGeoSelections; clientLocations; proposalGeoSelections; startDate; endDate },
  ) {
    const configDefaults = selection => {
      let propertyToReturn = {};

      if (this.isXMLProduct(selection)) {
        propertyToReturn = { broadcastInfo: { link: '', broadcast: [] } };
      } else if (this.isPaidSearchProduct(selection)) {
        const { proposalGeoSelections } = info;
        propertyToReturn = {
          geoSelections: this.getKeywordGeo(proposalGeoSelections),
          keywords: { list: [], summary: {}, keymetrics: '' },
        };
      } else {
        propertyToReturn = { flights: [] };
      }

      return propertyToReturn;
    };

    const proposalDuration = this._dateModel.getFullMonthDuration(new Date(info.endDate), new Date(info.startDate));

    if (!this.isPackage(productConfig)) {
      const {
        name,
        minSpend,
        keyMetric,
        keyMetricMultiplier,
        description,
        type,
        category,
        recommendedBudget,
        flightType,
        fulfillmentMethod,
        isMonthly = false,
        calcMinSpend = this.getCalcMinSpend(productConfig, proposalDuration),
      } = productConfig;
      return {
        id: 'temp_' + productConfig.id,
        name,
        type,
        category,
        minSpend,
        flightType,
        description,
        keyMetric,
        keyMetricMultiplier,
        isMonthly,
        budget: 0,
        recommendedBudget,
        minDays: productConfig.minDays || 0,
        productConfigId: productConfig.id,
        index: Date.now(),
        isLocked: false,
        calcMinSpend,
        fulfillmentMethod,
        ...(productConfig.rateRangeList ? { rateRangeList: productConfig.rateRangeList } : {}),
        ...configDefaults(productConfig),
        flightConfigs: productConfig.flightConfigs,
        isChanged: false,
        ...this.optional(productConfig.cpcMargin, 'cpcMargin'),
        ...this.optional(productConfig.omsAccountNumber, 'omsAccountNumber'),
        ...this.optional(productConfig.omsName, 'omsName'),
        ...this.optional(productConfig.ioRecipientEmail, 'ioRecipientEmail'),
        ...this.optional(productConfig.slideType, 'slideType'),
        ...this.optional(productConfig.IORecipientEmail, 'IORecipientEmail'),
        ...this.optional(productConfig.rateRangeList, 'rateRangeList'),
        ...this.optional(productConfig.noXmlFlag, 'noXmlFlag'),
      };
    }

    const productsInPackage = [
      ...productConfig.products.map(p => {
        if (p.category === ProductConfigCategory.Geofen) {
          let geoSelections;

          if (info.clientLocations.length && info.clientLocations.some(l => l?.address)) {
            const mapped = info.clientLocations
              .filter(l => l.address)
              .map((l, i) => {
                return {
                  id: (Date.now() + i * 10000).toString(),
                  address: l.address,
                  radius: '400',
                  unitType: 'Meters',
                  ...(l?.lat && l?.lon ? { lat: l.lat, lon: l.lon } : {}),
                };
              });

            geoSelections = { addressList: [...mapped] };
          }

          for (const flight of p.flights) {
            flight.market = { geoSelections: cloneDeep(geoSelections) };
            flight.startDate = info.startDate;
            flight.endDate = info.endDate;
          }
        } else if (p?.flights) {
          const geoSelections = info.proposalGeoSelections;

          for (const flight of p.flights) {
            flight.market = { geoSelections: cloneDeep(geoSelections) };
            flight.startDate = info.startDate;
            flight.endDate = info.endDate;
          }
        }

        return p;
      }),
    ];

    const { name, packageDescription, category } = productConfig;
    return {
      id: 'temp_' + productConfig.id,
      name,
      description: packageDescription,
      category,
      budget: productConfig.budget,
      minDays: 0,
      index: new Date(),
      isLocked: true,
      products: productsInPackage,
      parentPackageConfigId: productConfig.id,
    };
  }

  getCleanPackage(product) {
    return {
      ...(product.id && !this.tempIdTester.test(product.id) ? { id: product.id } : {}),
      ...(product.parentPackageConfigId ? { parentPackageConfigId: product.parentPackageConfigId } : {}),
      name: product.name,
      index: product.index,
      budget: product.budget,
      description: product.description,
      products: this.getCleanProducts(product.products, true, true),
    };
  }

  getCleanPackages(packages) {
    return packages.map(product => this.getCleanPackage(product));
  }

  sortAndCleanProductAndFlights(proposal: UnsafeAny) {
    const { products, ...proposalWithoutProducts } = super.sortAndCleanProductAndFlights(proposal);
    // move to BE
    return { ...proposalWithoutProducts, products: products.map(pr => this.getUpdatedProduct(pr)) };
  }

  getNewCreativeChild(creative) {
    const configs = this._creatives.getChildConfigs(creative) || [];
    const creatives = creative?.creatives || [];
    if (!configs.length) return { index: 0, id: uuidv4(), parentId: creative.id }; // fake index for now
    const newCreative = { index: 0, id: uuidv4(), parentId: creative.id };
    configs.forEach(c => {
      const propertyName = c.PropertyId;
      const dataDefault = c.DataType === 'string' ? '' : 0;
      newCreative[propertyName] = dataDefault;
    });
    const index = Math.max(...creatives.map(c => c.index), 0);
    return { ...newCreative, index: index + 1 };
  }
}

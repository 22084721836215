
import Vue from 'vue';

export default Vue.extend({
  name: 'NavigationButtons',

  props: {
    currentTab: {
      type: String,
      required: true,
    },
    tabs: {
      type: Array,
      required: true,
    },
    wrapperClass: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    buttonWidth: {
      type: String,
      default: '100',
    },
  },

  methods: {
    classBySelected(pathName: string | string[]): string {
      let className: string;

      if (pathName instanceof Array) {
        if (pathName.includes(this.currentTab)) {
          className = 'font-weight-bold';
        } else {
          className = 'font-weight-bold darken';
        }
        return className;
      }
      if (this.currentTab === pathName) {
        className = 'font-weight-bold';
      } else {
        className = 'font-weight-bold darken';
      }
      return className;
    },
  },
});


import Vue from 'vue';

export default Vue.extend({
  name: 'DialogTabs',

  props: {
    /**
     * Selected item. Used for v-model.
     */
    value: {
      type: String,
      required: true,
    },

    /**
     * List of items for selecting.
     */
    items: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    formatItems(): { value; marked; text }[] {
      if (this.items.every(el => typeof el === 'string')) {
        return this.items.map(el => ({ value: el, marked: false, text: el }));
      }

      return this.items;
    },
  },
});

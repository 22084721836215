import { Module } from 'vuex';
import { RootState } from '../root/state';
interface OutputTextEditorState extends Selections {
  modifiedProp: { prop: string | null; value: string | null } | null;
}

export interface Selections {
  isBold: boolean;
  isStrike: boolean;
  isUnderline: boolean;
  isItalic: boolean;
  isBullet: boolean;
  isList: boolean;
  link: string;
  selectedText: string;
  selectedTextColor: string;
  selectedCellColor: string;
}

interface OutputTextEditorFactoryReturn {
  module: Module<OutputTextEditorState, RootState>;
}

export const outputTextEditorModule = (): OutputTextEditorFactoryReturn => {
  return {
    module: {
      namespaced: true as const,
      state: (): OutputTextEditorState => ({
        isBold: false,
        isStrike: false,
        isUnderline: false,
        isItalic: false,
        isBullet: false,
        isList: false,
        link: '',
        selectedText: '',
        modifiedProp: null,
        selectedTextColor: '',
        selectedCellColor: '',
      }),
      getters: {
        getCurrentSelections(state): Selections {
          return {
            isBold: state.isBold,
            isStrike: state.isStrike,
            isUnderline: state.isUnderline,
            isItalic: state.isItalic,
            isBullet: state.isBullet,
            isList: state.isList,
            link: state.link,
            selectedText: state.selectedText,
            selectedTextColor: state.selectedTextColor,
            selectedCellColor: state.selectedCellColor,
          };
        },
        getModifiedProp(state): { prop: string; value?: string } {
          return {
            prop: state.modifiedProp?.prop,
            value: state.modifiedProp?.value,
          };
        },
      },
      actions: {
        setCurrentSelections({ commit }, payload: Selections) {
          commit('SET_IS_BOLD', payload.isBold);
          commit('SET_IS_STRIKE', payload.isStrike);
          commit('SET_IS_UNDERLINE', payload.isUnderline);
          commit('SET_IS_ITALIC', payload.isItalic);
          commit('SET_IS_BULLET', payload.isBullet);
          commit('SET_IS_LIST', payload.isList);
          commit('SET_LINK', payload.link);
          commit('SET_CHOOSED_TEXT', payload.selectedText);
          commit('SET_SELECTED_TEXT_COLOR', payload.selectedTextColor);
          commit('SET_SELECTED_CELL_COLOR', payload.selectedCellColor);
        },
        setModifiedProp({ commit }, payload: { prop: string; value?: string }) {
          commit('SET_MODIFIED_PROP', payload);
        },
      },
      mutations: {
        SET_IS_BOLD(_state, _value): void {
          _state.isBold = _value;
        },
        SET_IS_STRIKE(_state, _value): void {
          _state.isStrike = _value;
        },
        SET_IS_UNDERLINE(_state, _value): void {
          _state.isUnderline = _value;
        },
        SET_IS_ITALIC(_state, _value): void {
          _state.isItalic = _value;
        },
        SET_IS_BULLET(_state, _value): void {
          _state.isBullet = _value;
        },
        SET_IS_LIST(_state, _value): void {
          _state.isList = _value;
        },
        SET_MODIFIED_PROP(_state, _value): void {
          _state.modifiedProp = _value;
        },
        SET_LINK(_state, _value): void {
          _state.link = _value;
        },
        SET_CHOOSED_TEXT(_state, _value): void {
          _state.selectedText = _value;
        },
        SET_SELECTED_TEXT_COLOR(_state, _value): void {
          _state.selectedTextColor = _value;
        },
        SET_SELECTED_CELL_COLOR(_state, _value): void {
          _state.selectedCellColor = _value;
        },
      },
    },
  };
};


import Vue from 'vue';
import { mapGetters } from 'vuex';

import { Services, Models } from '@/injectables/tokens';
import { AuthServiceContract, EnvServiceContract, LocalStorageServiceContract, C360ModelContract } from '@/injectables';
import { AgencyType } from '@/app/graphql';

type Agency = {
  id: string;
  name: string;
  type: AgencyType;
  parentAvailable: boolean;
  children: Agency[];
};

export default Vue.extend({
  name: 'AgencySelector',

  useInjectable: [Services.Auth, Services.LocalStorage, Services.Env, Services.Client, Models.C360],

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    persistent: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    show(val) {
      if (val) this.setCurrentAgency();
    },
  },

  data: (): {
    activeAgency: Agency;
    activeSubAgency: Agency;
    loading: boolean;
  } => ({ activeAgency: null, activeSubAgency: null, loading: false }),

  computed: {
    ...mapGetters({ isAdmin: 'auth/isAdmin' }),
    agencyList() {
      return this.$store.state.auth.availableAgencies;
    },
    availableAgency() {
      return this.agencyList;
    },
    availableSubEntities() {
      const currentAgency = (this.agencyList || []).find(agency => agency.id === this.activeAgency?.id);
      const subItems = currentAgency?.children || [];
      return subItems;
    },
    subEntities() {
      const { agencies, stations } = this.availableSubEntities.reduce(
        (acc, item) => {
          if (item.type === AgencyType.Station) {
            acc.stations.push(item);
            return acc;
          }
          acc.agencies.push(item);
          return acc;
        },
        { agencies: [], stations: [] },
      );
      const divider = agencies.length && stations.length ? [{ divider: true }] : [];
      const agencyHeader = agencies.length ? [{ header: 'Sub. Agencies' }] : [];
      const stationsHeader = stations.length ? [{ header: 'Stations' }] : [];

      return [...agencyHeader, ...agencies, ...divider, ...stationsHeader, ...stations];
    },
    subEntitiesLocked() {
      return !this.subEntities?.length;
    },
    currentAgency() {
      return this.$store.state.auth.user?.agency;
    },
    currentEnv() {
      return (this[Services.Env] as EnvServiceContract).getEnvironmentName(true);
    },
    isCurrentAgencySelected() {
      if (this.activeSubAgency) return this.activeSubAgency?.id === this.currentAgency?.id;
      return this.activeAgency?.id === this.currentAgency?.id;
    },
    isAgencySelected() {
      return Boolean(this.activeAgency?.id);
    },
    selectionBlocked() {
      return !this.isAdmin && !this.activeAgency?.parentAvailable && !this.activeSubAgency;
    },
    agencySelectorDisabled() {
      return this.agencyList?.length < 2;
    },
    subEntitiesCanBeCleared() {
      return this.isAdmin || this.activeAgency?.parentAvailable;
    },
  },

  methods: {
    close() {
      if (this.persistent) return;
      this.$emit('close');
    },
    readableType(type) {
      const mapper = {
        [AgencyType.Agency]: 'Agency',
        [AgencyType.Subagency]: 'Sub. Agency',
        [AgencyType.Station]: 'Station',
      };
      return mapper[type];
    },
    updateActiveAgency(agency) {
      this.activeAgency = agency;
      if (agency?.children?.length === 1 && !agency.parentAvailable && !this.isAdmin) {
        this.activeSubAgency = agency?.children[0];
        return;
      }
      this.activeSubAgency = null;
    },
    async updateAgency() {
      const token = (this[Services.LocalStorage] as LocalStorageServiceContract).getItem('mp_c360_refresh');
      const agencyId = this.activeSubAgency?.id || this.activeAgency?.id;
      if (!agencyId || !token) return;
      const type = this.activeSubAgency?.type || AgencyType.Agency;
      const { isErr, unwrap } = await (this[Services.Auth] as AuthServiceContract).updateUserAgency(
        token,
        agencyId,
        type,
      );
      if (isErr()) {
        return;
      }
      const { refresh } = unwrap();
      const domain = window.location.origin;
      const link = (this.c360Entity as C360ModelContract).addRefreshToken(domain, refresh);
      window.location.href = link + '&redirectUri=' + window.location.href;
      this.close();
    },
    setCurrentAgency() {
      if (!this.currentAgency) return;
      if (this.currentAgency && !this.currentAgency?.parent) {
        const id = this.currentAgency.id;
        const parent = this.agencyList.find(agency => agency.id === id);
        this.activeAgency = parent || this.currentAgency;
        this.activeSubAgency = null;
        return;
      }
      const id = this.currentAgency.parent.id;
      const parent = this.agencyList.find(agency => agency.id === id);
      this.activeAgency = parent || this.currentAgency.parent;
      this.activeSubAgency = this.currentAgency;
    },
  },
});


import Vue from 'vue';
import { InstantCampaignStatus } from '@/app/graphql';
import { Models } from '@/injectables/tokens';
import { InstantModelContract } from '@/injectables';

export default Vue.extend({
  name: 'InstantIoActionsMenu',

  useInjectable: [Models.InstantIO],

  props: {
    readOnly: {
      type: Boolean,
      default: false,
    },
    campaign: {
      type: Object,
      required: true,
    },
  },

  data: (): {
    menuOpened: boolean;
  } => ({
    menuOpened: false,
  }),
  computed: {
    duplicateDisabled(): boolean {
      return (this[Models.InstantIO] as InstantModelContract).instantCampaignDuplicateDisabled(this.campaign);
    },
    isDeleteVisible(): boolean {
      return this.campaign.status !== InstantCampaignStatus.Sold;
    },
    isAgencyAdmin(): boolean {
      return this.$store.getters['auth/isAgencyAdmin'];
    },
  },
});


import Vue, { PropType } from 'vue';

import { OutputSlide } from '@/shared/legacy/classes';

import { OutputModelContract, SlideModelContract } from '@/injectables';
import { Models } from '@/injectables/tokens';

import Slide from '@/components/Output/Slide.vue';
import { SlideLibraryListItem } from '@/entities/slide';

export default Vue.extend({
  name: 'SlideList',

  useInjectable: [Models.Slide, Models.Output],

  components: {
    Slide,
    SlideLibraryListItem,
  },

  props: {
    items: {
      type: Array as PropType<OutputSlide[]>,
      required: true,
    },
    selectedSlides: {
      type: Array as PropType<OutputSlide[]>,
      default: (): OutputSlide[] => [],
    },
    selectionAvailable: {
      type: Boolean,
      default: false,
    },
    actionsAvailable: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    emptyLibraryText: {
      type: String,
      default: '',
    },
    isSlidesEditable: {
      type: Boolean,
      default: false,
    },
    listGap: {
      type: [Number, String],
      default: 4,
    },
    colorScheme: {
      type: Object,
      default: () => ({}),
    },
    layouts: {
      type: Array,
      default: () => [],
    },
    slideRenamingEnabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    layoutTypeMapper() {
      return Object.fromEntries(this.layouts.map(layout => [layout.id, layout.type]));
    },
  },

  methods: {
    slideIsSelected(slide: OutputSlide) {
      return (this.slideEntity as SlideModelContract).getIsSlideSelected(slide, this.selectedSlides);
    },
    dynamicColored(slide: OutputSlide): boolean {
      return (this[Models.Output] as OutputModelContract).isDynamicColorOutput({
        type: this.layoutTypeMapper[slide.layoutId],
      });
    },
  },
});

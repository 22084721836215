import 'reflect-metadata';
import { Err, Ok, Result } from '@sniptt/monads';
import { Failure } from '@/injectables/failure';

import { Service } from '@/injectables/service';

import { injectable } from 'inversify';
import { ProposalDemographics, CensusGeoSelections } from '@/shared/types';

@injectable()
export class NewProposalService extends Service {
  async getCensus(payload: CensusGeoSelections): Promise<Result<{ demographics: ProposalDemographics }, Failure>> {
    try {
      const preparedInput = {
        ...payload,
        zips: payload?.zips?.map(z => z.key.replace(/ZIP_/g, '')),
        counties: payload?.counties?.map(c => c.key.replace(/CNTY_/g, '')),
        dmas: payload?.dmas?.map(d => d.key.replace(/DMA_/g, '')),
        states: payload?.states?.map(s => s.key.replace(/STAT_/g, '')),
        cities: payload?.cities?.map(c => c.key.replace(/CITY_/g, '')),
        congressionalDistricts: payload?.congressionalDistricts?.map(cd => cd.name),
      };
      const url = '/geo/getCensus';

      const { data } = await this._axios.post(url, preparedInput);

      if (data && data.error) {
        return Err({ message: data.error });
      }

      return Ok(data);
    } catch (error) {
      return Err(error);
    }
  }
}

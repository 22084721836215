
import Vue from 'vue';
import debounce from 'lodash.debounce';
import { ClientLocation } from '@/shared/types';
import { ValidationServiceContract, GeocodeServiceContract } from '@/injectables';
import { Services } from '@/injectables/tokens';

const addressToLatLong: Record<string, { lat: number; lon: number }> = {};

export default Vue.extend({
  useInjectable: [Services.Geocode],

  inject: ['showSnackbar'],

  props: {
    label: {
      type: String,
    },
    address: {
      type: String,
    },
    numberOfLocations: {
      type: Number,
    },
    clientLocations: {
      type: Array,
    },
  },

  name: 'EditableAddress',

  data: (): {
    editLabel: boolean;
    editAddress: boolean;
    localLabel: string;
    localAddress: ClientLocation;
    fetchingLocations: boolean;
    locationAutocompleteList: object[];
    locationSearch: string;
  } => ({
    editLabel: false,
    editAddress: false,
    localLabel: '',
    localAddress: {
      label: '',
      address: '',
      lat: 0,
      lon: 0,
      zip: '',
      city: '',
      state: '',
    },
    fetchingLocations: false,
    locationAutocompleteList: [],
    locationSearch: '',
  }),

  watch: {
    locationSearch(val: string): void {
      if (!val || val.length < 5) {
        return;
      }
      this.fetchingLocations = true;
      this.fetchAutocompleteApi(val);
    },
  },

  computed: {
    rules() {
      const validationService: ValidationServiceContract = this.$container.get(Services.Validation);
      return {
        required: validationService.requiredValidatorFactory(),
        maxLength: validationService.maxLengthValidatorFactory(),
      };
    },
    items(): object[] {
      const matches = [];
      this.locationAutocompleteList.forEach(entry => {
        if (entry.position && entry.address && entry.address.label) {
          const value = {
            label: entry.address.label,
            address: entry.address.label,
            lat: entry.position.lat,
            lon: entry.position.lng,
            city: entry.address.city,
            zip: entry.address.postalCode,
            state: entry.address.stateCode,
          };
          matches.push({ text: entry.address.label, value });
          addressToLatLong[entry.address.label] = { lat: entry.position.lat, lon: entry.position.lng };
        }
      });
      return matches;
    },
  },

  methods: {
    submitUpdatedLabel(): void {
      if (this.$refs?.labelInput.validate()) {
        this.$emit('update-label', this.localLabel);
      } else {
        this.localLabel = this.label;
      }
      this.editLabel = false;
    },
    toggleEditLabel(): void {
      this.localLabel = this.label;
      this.editLabel = true;
    },
    submitUpdatedAddress(): void {
      if (this.localAddress?.address && this.$refs?.addressInput.validate()) {
        this.$emit('update-address', this.localAddress);
      } else {
        this.localAddress = {
          label: '',
          address: '',
          lat: 0,
          lon: 0,
          zip: '',
          city: '',
          state: '',
        };
      }
      this.editAddress = false;
    },
    toggleEditAddress(): void {
      this.editAddress = true;
    },
    fetchAutocompleteApi: debounce(async function (query: string) {
      const geocodeService: GeocodeServiceContract = this.geocodeService;
      const { isErr, unwrapErr, unwrap } = await geocodeService.searchByLocation(encodeURIComponent(query));
      if (isErr()) {
        this.showSnackbar(unwrapErr().message, 'error');
        return;
      }
      this.locationAutocompleteList = unwrap();
      this.fetchingLocations = false;
    }, 500),
  },
});


import Vue from 'vue';
import { Services, Models } from '@/injectables/tokens';
import { FilesModelContract, EnvServiceContract } from '@/injectables';
import BasicFileUploader from './BasicFileUploader.vue';

export default Vue.extend({
  name: 'CsvUploader',
  inject: ['showSnackbar'],
  components: {
    BasicFileUploader,
  },
  useInjectable: [Services.Env, Models.Files],
  props: {
    showProgressLoading: {
      type: Boolean,
      default: false,
    },
    mainText: {
      type: String,
    },
    isFileUploaded: {
      type: Boolean,
      default: false,
    },
    hideCancelButton: {
      type: Boolean,
      default: false,
    },
    blockedFileDialogByClick: {
      type: Boolean,
      default: false,
    },
    dropUploading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    fileFormats(): string[] {
      return ['csv'];
    },
    availableTypes() {
      return this.fileFormats.map(type => 'text/' + type);
    },
    fileValidator() {
      const envService = this.envService as EnvServiceContract;
      const sizeLimit = envService.getOrThrow<number>('VUE_APP_UPLOAD_SIZE_LIMIT', v => parseInt(v)); // 2MB
      const filesEntity = this.filesEntity as FilesModelContract;
      return [
        filesEntity.getInvalidFileValidator({
          message: 'File must be a CSV file',
        }),
        filesEntity.getEmptyFileValidator({ message: "File mustn't be an empty file" }),
        filesEntity.getFormatFileValidator({
          formats: this.fileFormats,
          availableTypes: this.availableTypes,
          message: 'File must be a valid CSV file',
        }),
        filesEntity.getSizeValidator({
          size: sizeLimit,
          message: 'File must be less than 2MB',
        }),
      ];
    },
  },
  methods: {
    onImagePicked(file: File): void {
      this.$emit('upload-csv-file', file);
    },
  },
});

import * as Types from '../../../../app/graphql/_types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type GetUploadUrlMutationVariables = Types.Exact<{
  fileName: Types.Scalars['String'];
  isPrivate?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;

export type GetUploadUrlMutation = {
  createUploadUrl?: { url: string; location: string; clientUrl: string; fields: string } | null;
};

export const GetUploadUrlDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'getUploadUrl' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fileName' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'isPrivate' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createUploadUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fileName' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'fileName' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'isPrivate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'isPrivate' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                { kind: 'Field', name: { kind: 'Name', value: 'clientUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fields' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUploadUrlMutation, GetUploadUrlMutationVariables>;

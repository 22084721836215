import { AdProductUser, AgencyListItem } from '@/shared/types';
import { RouteItemProps } from './types';

export interface AuthState {
  user: AdProductUser | null;
  editUserId: string;
  agencyUsers: {
    loaded: boolean;
    list?: AdProductUser[];
  };
  availableAgencies: AgencyListItem[];
  authPromise: Promise<void>;
  checkingAuth: boolean;
  authChecked: boolean;
  loadingUser: boolean;
  loadingCreateUser: boolean;
  loadingAgencyUsers: boolean;
  routes: RouteItemProps[];
}
export const state = (): AuthState => ({
  user: null,
  editUserId: '',
  authPromise: null,
  agencyUsers: {
    loaded: false,
  },
  availableAgencies: null,
  checkingAuth: false,
  authChecked: false,
  loadingUser: false,
  loadingCreateUser: false,
  loadingAgencyUsers: false,
  routes: [
    {
      pathName: 'home',
      name: 'Summary',
      icon: 'analytics',
      authorization: ['client', 'canView', 'canEdit', 'isAdmin'],
      activeChildRoutes: ['home'],
      exact: true,
      disabled: false,
    },
    {
      pathName: 'clients',
      name: 'Clients',
      icon: 'store',
      authorization: ['client', 'canView', 'canEdit', 'isAdmin'],
      activeChildRoutes: ['clientProposals', 'clientInfo', 'clientSummary', 'clients', 'createClient'],
      disabled: false,
    },
    {
      pathName: 'products',
      name: 'Products',
      icon: 'category',
      authorization: ['canEdit', 'isAdmin'],
      activeChildRoutes: ['products'],
      disabled: false,
    },
    {
      pathName: 'proposals',
      name: 'Proposals',
      icon: 'campaign',
      authorization: ['client', 'canView', 'canEdit', 'isAdmin'],
      activeChildRoutes: ['proposals'],
      exact: true,
      disabled: false,
    },
    // {
    //   pathName: 'alerts',
    //   name: 'Alerts',
    //   icon: 'mdi-bell',
    //   authorization: ['client', 'canView', 'canEdit', 'isAdmin'],
    //   activeChildRoutes: [],
    //   disabled: false,
    // },
  ],
});

import { AgencyInfo } from '@/shared/types';

export interface AgencyState {
  currentAgencyInfo: AgencyInfo;
  agencyInfoLoading: boolean;
}

export const defaultAgency: AgencyState = {
  currentAgencyInfo: {
    id: '',
    name: '',
    canHavePackages: false,
    canGenerateContracts: false,
    adminReviewEnabled: false,
    dynamicColorsLayoutEnabled: false,
  },
  agencyInfoLoading: false,
};

export const state = (): AgencyState => ({
  ...defaultAgency,
});

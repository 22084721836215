
import Vue from 'vue';
import { debounce } from 'lodash';

export default Vue.extend({
  name: 'SearchField',

  props: {
    value: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    searchKey: {
      get(): string {
        return this.value;
      },
      set(newValue: string) {
        this.$emit('input', newValue);
      },
    },
  },

  methods: {
    debouncedSearch: debounce(function (str: string): void {
      this.searchKey = str;
    }, 300),
  },
});

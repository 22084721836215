
import Vue from 'vue';

export default Vue.extend({
  name: 'StatusIcon',

  props: {
    size: {
      type: [String, Number],
      default: 28,
    },
    status: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: '',
    },
  },
});


import Vue from 'vue';

export default Vue.extend({
  name: 'GlobalLoaderFeature',

  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
});

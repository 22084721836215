import * as Types from '../../../../../app/graphql/_types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type GetSlideLibraryQueryVariables = Types.Exact<{
  input: Types.GetSlideLibraryInput;
}>;

export type GetSlideLibraryQuery = {
  getPreparedLibrary: {
    userLibrary: Array<{
      id: string;
      userId?: string | null;
      name?: string | null;
      label: string;
      colors?: Record<string, any> | null;
      shapes?: Record<string, any> | null;
      images?: Record<string, any> | null;
      textItems?: Record<string, any> | null;
      slideLibraryId?: string | null;
      layoutId: string;
    } | null>;
    agencyLibrary: Array<{
      id: string;
      userId?: string | null;
      name?: string | null;
      label: string;
      colors?: Record<string, any> | null;
      shapes?: Record<string, any> | null;
      images?: Record<string, any> | null;
      textItems?: Record<string, any> | null;
      slideLibraryId?: string | null;
      layoutId: string;
    } | null>;
    sharedLibrary: Array<{
      id: string;
      userId?: string | null;
      name?: string | null;
      label: string;
      colors?: Record<string, any> | null;
      shapes?: Record<string, any> | null;
      images?: Record<string, any> | null;
      textItems?: Record<string, any> | null;
      slideLibraryId?: string | null;
      layoutId: string;
      readonly?: boolean | null;
    } | null>;
  };
};

export const GetSlideLibraryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getSlideLibrary' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'GetSlideLibraryInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getPreparedLibrary' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userLibrary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'colors' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'shapes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'images' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'textItems' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'slideLibraryId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'layoutId' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'agencyLibrary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'colors' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'shapes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'images' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'textItems' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'slideLibraryId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'layoutId' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sharedLibrary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'colors' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'shapes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'images' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'textItems' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'slideLibraryId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'layoutId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'readonly' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetSlideLibraryQuery, GetSlideLibraryQueryVariables>;

import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';
import { state } from './state';

import { Module } from 'vuex';
import { ConfiguredProduct, Product, UnsafeAny } from '@/shared/types';
import { NewProposalState } from './types';
import { Container } from 'inversify';
import { RootState } from '../root/state';
import { ProductConfigCategory } from '@/app/graphql';

// TODO: extract getProductByID, getProductByIndex, findProductByID into service or something
export const getProductByID = (state: NewProposalState, productId: string): Product => {
  const productIndex = findProductByID(state, productId);
  return getProductByIndex(state, productIndex);
};

export const getProductByIndex = (state: NewProposalState, productIndex: UnsafeAny[]) => {
  if (productIndex.some(el => el !== -1)) {
    let product;
    if (productIndex[1] === -1) {
      product = state.newProposal.products[productIndex[0]];
    } else {
      const packageProduct = state.newProposal.products?.[productIndex[0]];
      product =
        packageProduct.category === ProductConfigCategory.Package ? packageProduct.products?.[productIndex[1]] : null;
    }
    return product;
  }
};

export const findProductByID = (state: NewProposalState, productId: ConfiguredProduct['id']) => {
  const productIndex = state.newProposal.products.reduce(
    (acc, el, i) => {
      const indexInProduct =
        el.category === ProductConfigCategory.Package ? el?.products?.findIndex(el => el.id === productId) : -1;
      if (indexInProduct !== -1) {
        acc = [i, indexInProduct];
      }
      if (el.id === productId) {
        acc = [i, -1];
      }
      return acc;
    },
    [-1, -1],
  );
  return productIndex;
};

interface NewProposalModuleFactoryReturn {
  module: Module<NewProposalState, RootState>;
}

export const newProposalModule = (container: Container): NewProposalModuleFactoryReturn => {
  return {
    module: {
      namespaced: true as const,
      state: state(container),
      mutations: mutations(),
      getters: getters(container),
      actions: actions(container),
    },
  };
};

import * as Types from '../../../../../app/graphql/_types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type DeleteProductConfigWithChildrenMutationVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
  childAgencyIds: Array<Types.InputMaybe<Types.Scalars['UUID']>> | Types.InputMaybe<Types.Scalars['UUID']>;
  agencyId?: Types.InputMaybe<Types.Scalars['UUID']>;
}>;

export type DeleteProductConfigWithChildrenMutation = { deleteProductConfigWithChildren?: { id: string } | null };

export const DeleteProductConfigWithChildrenDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteProductConfigWithChildren' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'childAgencyIds' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'agencyId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteProductConfigWithChildren' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'childAgencyIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'childAgencyIds' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'agencyId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'agencyId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteProductConfigWithChildrenMutation, DeleteProductConfigWithChildrenMutationVariables>;

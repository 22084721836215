export enum ColorType {
  hex = 'hex',
  oklch = 'oklch',
}

export interface ColorModelContract {
  getGeneratedColors({
    baseColor,
    numberOfColors,
    type,
  }: {
    baseColor: string;
    numberOfColors?: number;
    type: ColorType;
  }): string[];
}


import Vue, { PropType } from 'vue';
import { OutputSlide } from '@/shared/legacy/classes';

import { SlideModelContract } from '@/injectables';
import { Models } from '@/injectables/tokens';

import SlideImage from '@/components/Output/Slide.vue';
import { BaseDialog } from '@/shared/ui';

export default Vue.extend({
  name: 'SlidePreviewModal',

  useInjectable: [Models.Slide],

  components: { BaseDialog, SlideImage },

  props: {
    value: {
      type: Boolean,
      required: true,
    },
    slide: {
      type: Object as PropType<OutputSlide>,
      required: true,
    },
    colorScheme: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    showDialog: {
      get(): boolean {
        return this.value;
      },
      set(newValue: boolean) {
        this.$emit('input', newValue);
      },
    },
    slideSizes() {
      const getScale = name => {
        if (['lg', 'xl'].includes(name)) return 0.6;
        return 0.8;
      };
      const { width, height, name } = this.$vuetify.breakpoint;

      return (this.slideEntity as SlideModelContract).getSlideSizeInsideWindow({
        saveHeightSpacing: this.nonContentHeight, // sum of title block height and bottom actions block height
        scales: {
          width: getScale(name),
          height: 0.7,
        },
        window: {
          width,
          height,
        },
      });
    },
    nonContentHeight() {
      if (!this.$scopedSlots.viewActions) return 70;
      return 135;
    },
  },
});

import * as Types from '../../../../../app/graphql/_types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { ProductConfigFragmentFragmentDoc } from '../../../../../app/graphql/fragments/product-config.fragment.generated';
export type GetRecommendationsQueryVariables = Types.Exact<{
  proposalId: Types.Scalars['UUID'];
}>;

export type GetRecommendationsQuery = {
  getRecommendations: {
    currentRecommendations: Array<{
      index: number;
      budget: number;
      productConfig: {
        id: string;
        name?: string | null;
        type?: Types.ProductConfigType | null;
        category?: Types.ProductConfigCategory | null;
        agencyId?: string | null;
        description?: Record<string, any> | null;
        minDays?: number | null;
        minSpend?: number | null;
        recommendedBudget?: number | null;
        flightType?: Types.ProductConfigFlightType | null;
        rateRangeList?: Record<string, any> | null;
        keyMetric?: Types.KeyMetric | null;
        keyMetricMultiplier?: number | null;
        isHidden?: boolean | null;
        isMonthly?: boolean | null;
        cpcMargin?: number | null;
        omsAccountNumber?: string | null;
        omsName?: string | null;
        IORecipientEmail?: string | null;
        fulfillmentMethod?: Types.ProductConfigFullfillmentMethod | null;
        questions?: Array<{ id: string; required: boolean; text: string; index: number } | null> | null;
        flightConfigs?: Array<{
          id: string;
          targetingOption?: string | null;
          flightCategory?: Types.FlightCategory | null;
          adFormatList?: Record<string, any> | null;
          rate?: number | null;
          rateType?: Types.RateType | null;
          markupMin?: number | null;
          markupMax?: number | null;
          platformOption?: Array<string | null> | null;
          mapType?: Types.MapType | null;
          advancedOptions?: {
            autoRenewList?: Array<string | null> | null;
            abTestFlag?: boolean | null;
            darkPeriodList?: Array<{ index: number; startDate: string; endDate: string } | null> | null;
          } | null;
        } | null> | null;
      };
    }>;
    increasedRecommendations?: {
      newDays?: number | null;
      newBudget?: number | null;
      recommendations: Array<{
        index: number;
        budget: number;
        productConfig: {
          id: string;
          name?: string | null;
          type?: Types.ProductConfigType | null;
          category?: Types.ProductConfigCategory | null;
          agencyId?: string | null;
          description?: Record<string, any> | null;
          minDays?: number | null;
          minSpend?: number | null;
          recommendedBudget?: number | null;
          flightType?: Types.ProductConfigFlightType | null;
          rateRangeList?: Record<string, any> | null;
          keyMetric?: Types.KeyMetric | null;
          keyMetricMultiplier?: number | null;
          isHidden?: boolean | null;
          isMonthly?: boolean | null;
          cpcMargin?: number | null;
          omsAccountNumber?: string | null;
          omsName?: string | null;
          IORecipientEmail?: string | null;
          fulfillmentMethod?: Types.ProductConfigFullfillmentMethod | null;
          questions?: Array<{ id: string; required: boolean; text: string; index: number } | null> | null;
          flightConfigs?: Array<{
            id: string;
            targetingOption?: string | null;
            flightCategory?: Types.FlightCategory | null;
            adFormatList?: Record<string, any> | null;
            rate?: number | null;
            rateType?: Types.RateType | null;
            markupMin?: number | null;
            markupMax?: number | null;
            platformOption?: Array<string | null> | null;
            mapType?: Types.MapType | null;
            advancedOptions?: {
              autoRenewList?: Array<string | null> | null;
              abTestFlag?: boolean | null;
              darkPeriodList?: Array<{ index: number; startDate: string; endDate: string } | null> | null;
            } | null;
          } | null> | null;
        };
      }>;
    } | null;
  };
};

export const GetRecommendationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getRecommendations' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'proposalId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getRecommendations' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'proposalId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'proposalId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currentRecommendations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'productConfig' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProductConfigFragment' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'index' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'budget' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'increasedRecommendations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'recommendations' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'productConfig' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProductConfigFragment' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'index' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'budget' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'newDays' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'newBudget' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...ProductConfigFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetRecommendationsQuery, GetRecommendationsQueryVariables>;


import Vue from 'vue';

import { Services } from '../../injectables/tokens';
import { CampaignServiceContract } from '../../injectables';
import { Routes } from '../../router/routes';

export default Vue.extend({
  name: 'Campaign',

  useInjectable: [Services.Campaign],

  created() {
    const { shortId } = this.$route.params;

    if (shortId) {
      this.getCampaignByShortId(shortId);
    } else {
      this.$router.push({ name: Routes.Home });
    }
  },

  methods: {
    async getCampaignByShortId(shortId: string) {
      const { isErr, unwrap } = await (this.campaignService as CampaignServiceContract).getCampaignByShortId(
        Number(shortId),
      );

      if (isErr()) {
        this.$router.push({ name: Routes.Home });
      }

      const data = unwrap();

      if (data.instantCampaign) {
        const { id } = data.instantCampaign;
        this.$router.push({
          name: Routes.InstantCampaigns,
          query: { id, sortBy: 'updatedAgo', desc: 'true', itemsPerPage: '15', modal: 'insertionOrders' },
        });
      } else if (data.proposal) {
        const { id, clientId } = data.proposal;
        this.$router.replace({
          name: Routes.ProposalMarket,
          params: { id: clientId, proposalId: id },
        });
      }
    },
  },
});

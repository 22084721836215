import * as Types from '../../../../../app/graphql/_types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type GetKeywordPlannerXlsxQueryVariables = Types.Exact<{
  input: Types.KeywordPlannerInput;
}>;

export type GetKeywordPlannerXlsxQuery = { fileInfo: { message: string; url?: string | null } };

export const GetKeywordPlannerXlsxDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetKeywordPlannerXlsx' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'KeywordPlannerInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'fileInfo' },
            name: { kind: 'Name', value: 'getKeywordPlannerXLSX' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'keywordPlannerData' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', alias: { kind: 'Name', value: 'url' }, name: { kind: 'Name', value: 'fileURL' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetKeywordPlannerXlsxQuery, GetKeywordPlannerXlsxQueryVariables>;

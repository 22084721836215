
import Vue, { PropType } from 'vue';

import { BaseProductModelContract, ValidationServiceContract } from '@/injectables';
import { Models, Services } from '@/injectables/tokens';
import { ProductConfigFullfillmentMethod, RateType } from '@/app/graphql';

import { Option } from '../contracts';
import uniqBy from 'lodash/uniqBy';

interface Data {
  targetingOptions: Option[];
}

export default Vue.extend({
  name: 'EditProduct.ProductOptions',

  useInjectable: [Services.UI, Models.BaseProduct],

  props: {
    product: {
      type: Object,
      required: true,
    },
    categories: {
      type: Array,
      default: () => [],
    },
    category: {
      type: String,
    },
    options: {
      type: Array as PropType<Option[]>,
    },
    rateTypes: {
      type: Array,
      default: () => [],
    },
    isValid: {
      type: Boolean,
      default: false,
    },
    freezeCategory: {
      type: Boolean,
      default: false,
    },
  },

  data(): Data {
    return {
      targetingOptions: [...this.options],
    };
  },
  computed: {
    allAdFormats(): string[] {
      return uniqBy(
        this.targetingOptions?.reduce((acc: string[], option: Option) => {
          return [...acc, ...(option.adFormatList || [])];
        }, []) || [],
        (adFormat: string) => adFormat,
      );
    },
    rateTypeQuote(): string {
      return RateType.Quote;
    },
    isProductCompulse(): boolean {
      return this.product.fulfillmentMethod === ProductConfigFullfillmentMethod.Compulse;
    },
    rules() {
      const validationService: ValidationServiceContract = this.$container.get(Services.Validation);
      return {
        required: validationService.requiredValidatorFactory(),
        float: validationService.isFloatValidatorFactory(),
        maxLength: validationService.maxLengthValidatorFactory(),
      };
    },
  },

  watch: {
    targetingOptions: {
      handler() {
        this.$emit('update:options', this.targetingOptions);
      },
      immediate: true,
      deep: true,
    },
  },

  methods: {
    rateTypeUpdated(option) {
      this.$emit('data-updated');
      if ((this[Models.BaseProduct] as BaseProductModelContract).isFlightRateTypeQuote(option)) {
        this.$set(option, 'rate', 0);
      }
    },
    removeTargetingOption(index: string) {
      this.$emit('data-updated');
      this.targetingOptions = this.targetingOptions.filter((option: Option) => option.index !== index);
    },
    pushTargetingOption() {
      this.$emit('data-updated');
      this.targetingOptions.push({
        index: this.uiUtilsService.getUniqueId(),
        targetingOption: '',
        adFormatList: [],
        rateType: '',
        rate: 0,
      });
    },
    isBetween(from: number, to: number): Function {
      const validationService: ValidationServiceContract = this.$container.get(Services.Validation);
      return validationService.isBetweenValidatorFactory({
        from,
        to,
      });
    },
    comboboxMaxLength(items: string[]): boolean | string {
      const validationService: ValidationServiceContract = this.$container.get(Services.Validation);
      const validator = validationService.maxLengthValidatorFactory();

      const isValid: string | boolean = (items || []).every(item => validator(item) === true);

      return isValid ? true : 'Exceeds maximum allowed characters';
    },
  },
});

import * as Types from '../../../../../app/graphql/_types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { ColorSchemeFragmentDoc } from '../../../../../app/graphql/fragments/output.fragments.generated';
export type GetLayoutsByAgencyQueryVariables = Types.Exact<{
  agencyId: Types.Scalars['UUID'];
}>;

export type GetLayoutsByAgencyQuery = {
  getLayoutsByAgency: Array<{
    name: string;
    id: string;
    image?: string | null;
    isDefault: boolean;
    type?: Types.OutputLayoutTypeGql | null;
    colorScheme?: {
      textHeading?: string | null;
      textSubheading?: string | null;
      textBody?: string | null;
      cardTextHeading?: string | null;
      cardTextSubheading?: string | null;
      cardTextBody?: string | null;
      accent?: string | null;
      card?: string | null;
      border?: string | null;
      mapLayer?: string | null;
      primary?: string | null;
    } | null;
  }>;
};

export const GetLayoutsByAgencyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getLayoutsByAgency' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'agencyId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getLayoutsByAgency' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'agencyId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'agencyId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isDefault' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'colorScheme' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ColorScheme' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
        ],
      },
    },
    ...ColorSchemeFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetLayoutsByAgencyQuery, GetLayoutsByAgencyQueryVariables>;

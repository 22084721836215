
import Vue from 'vue';
import { Placeholder } from '@/shared/ui';

export default Vue.extend({
  name: 'ClientNotes',

  components: { Placeholder },

  props: {
    maxNotesLength: {
      type: Number,
      default: 1000,
    },
    notes: {
      type: String,
      default: '',
    },
    header: {
      type: String,
      default: 'Notes',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    cardHeight: {
      type: Number,
      default: 380,
    },
  },

  data: (): {
    editNotes: boolean;
    localNotes: string;
    tempNotes: string;
    saveNotesLoading: boolean;
  } => ({
    saveNotesLoading: false,
    tempNotes: '',
    localNotes: '',
    editNotes: false,
  }),

  computed: {
    clientNotes(): string {
      return this.notes || this.localNotes;
    },
    notesContainerHeight(): number {
      return this.cardHeight - 110;
    },
  },

  methods: {
    onKeyUp() {
      if (this.tempNotes.length > this.maxNotesLength) {
        this.tempNotes = this.tempNotes.substring(0, this.maxNotesLength);
      }
    },
    onKeyDown(e) {
      if (e.key === 'Enter') {
        e.preventDefault();
        return;
      }
    },
    setEditNotes(): void {
      this.editNotes = true;
    },
    cancelEditNotes(): void {
      this.editNotes = false;
      this.tempNotes = this.notes;
    },
    saveEditNotes(): void {
      const trimmed = this.tempNotes.trim();
      this.$emit('update-notes', trimmed);
      this.editNotes = false;
    },
  },

  mounted() {
    this.tempNotes = this.clientNotes;
  },
});

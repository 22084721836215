import { RevenueData } from '@/shared/types';
import { ActiveProductCategoriesQuery } from '../graphql/queries/get-acive-products.generated';

export type PopulateActiveProductsData = (
  data: ActiveProductCategoriesQuery['activeProductCategoriesComponent'],
) => RevenueData;

/**
 *  Map graphql fields to existing interfaces
 */
export const mapActiveProductsData: PopulateActiveProductsData = function (data) {
  return {
    ChartList: data.map(item => {
      return {
        Category: item.category,
        Count: String(item.count),
        TotalBudget: String(item.budget),
      };
    }),
  };
};

export const arrayRangToObject = (arr: [string, string]) => {
  return { start: arr[0], end: arr[1] };
};

import { render, staticRenderFns } from "./ConfigProductListItem.vue?vue&type=template&id=7b349bf8&scoped=true"
import script from "./ConfigProductListItem.vue?vue&type=script&lang=ts"
export * from "./ConfigProductListItem.vue?vue&type=script&lang=ts"
import style0 from "./ConfigProductListItem.vue?vue&type=style&index=0&id=7b349bf8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b349bf8",
  null
  
)

export default component.exports
import Heading from '@tiptap/extension-heading';
import { mergeAttributes } from '@tiptap/core';
import Image from '@tiptap/extension-image';

export const CustomHeader = Heading.extend({
  renderHTML({ node, HTMLAttributes }) {
    let nodeLevel = node.attrs.level;
    if (typeof nodeLevel === 'string') {
      nodeLevel = parseInt(nodeLevel, 10);
    }
    const hasLevel = this.options.levels.includes(nodeLevel);
    const level = hasLevel ? node.attrs.level : this.options.levels[0];

    return [`h${level}`, mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
  },
});

export const EditableImage = Image.extend({
  renderHTML({ HTMLAttributes }) {
    const { style } = HTMLAttributes;
    return ['div', { style }, ['img', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)]];
  },
  addAttributes() {
    const levels = ['1', '2', '3', '4', '5', '6'];
    return {
      ...this.parent?.(),

      size: {
        default: '6',
        renderHTML: attributes => {
          return {
            width: `${((levels.includes(attributes.size) ? parseInt(attributes.size, 10) : 6) / 6) * 100}%`,
            height: 'auto',
          };
        },
      },
    };
  },
});

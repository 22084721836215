import { MediaPlannerNotification } from '@/injectables';

export interface NotificationsState {
  notifications: MediaPlannerNotification[];
}

export const state = (): NotificationsState => {
  return {
    notifications: [],
  };
};

import { Err, Ok, Result } from '@sniptt/monads';
import { injectable } from 'inversify';
import { AdProductUser } from '@/shared/types';

import { Failure } from '@/injectables/failure';

import { Service } from '../../../service';
import { GetAccessTokenResponse, GetUserByEmailResponse, TokenData } from '../contracts';

@injectable()
export class AuthService extends Service {
  async checkIfAuth(): Promise<Result<AdProductUser, Failure>> {
    try {
      const random = Math.random();
      const { data } = await this._axios.get(`/api/auth?_=${random}`);
      if (data.auth === false) {
        return Err({ message: `Unauthorized.` });
      }
      return Ok(data);
    } catch (error) {
      return Err({
        message: `Unauthorized request.`,
      });
    }
  }

  async getUserByEmail(email: string): Promise<Result<GetUserByEmailResponse, Failure>> {
    try {
      const url = '/api/auth/getUserByEmail';
      const { data } = await this._axios.get(url, { params: { email } });
      return Ok(data);
    } catch (error) {
      return Err({
        message: `Get user data failure: ${error}`,
      });
    }
  }

  async verifyToken(token: string): Promise<Result<TokenData, Failure>> {
    try {
      const url = '/api/auth/verifyToken';
      const { data } = await this._axios.post(url, { token });
      return Ok(data);
    } catch (error) {
      return Err({
        message: `Invalid token: ${error}`,
      });
    }
  }

  async refreshToken(token: string): Promise<Result<GetAccessTokenResponse, Failure>> {
    try {
      const url = '/api/auth/refreshToken';
      const { data } = await this._axios.post(url, { token });
      return Ok(data);
    } catch (error) {
      return Err({
        message: `Invalid token: ${error}`,
      });
    }
  }
}

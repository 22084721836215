
import Vue from 'vue';
import { InputValidationRules } from 'vuetify';
import debounce from 'lodash.debounce';
import datesHelpers from '@/shared/legacy/isomorphic/datesHelpers';

import { Client } from '@/shared/types';
import { Models, Services } from '@/injectables/tokens';
import {
  ClientServiceContract,
  TrackerContract,
  ValidationServiceContract,
  NewProposalModelContract,
  NewProposalServiceContract,
  BaseProductModelContract,
} from '@/injectables';
import { Routes } from '@/router/routes';

import WrapperWithTooltip from '@/components/WrapperWithTooltip.vue';
import DatePickerMenu from '@/components/DatePickerMenu.vue';
import DateRangePickerWithQuickPeriods from '@/components/Proposal/DatePickerWithQuickPeriods.vue';
import { mapState } from 'vuex';
import { Modules } from '../../store';

export default Vue.extend({
  name: 'CreateProposalDialog',

  inject: ['showSnackbar'],

  useInjectable: [
    Services.Tracker,
    Services.Client,
    Models.BaseProduct,
    Models.NewProposal,
    Services.NewProposal,
    Models.Census,
  ],

  components: {
    DateRangePickerWithQuickPeriods,
    DatePickerMenu,
    WrapperWithTooltip,
  },

  props: {
    dialog: {
      type: Boolean,
    },
    clientId: {
      type: String,
    },
    clientName: {
      type: String,
    },
    clientCategory: {
      type: String,
    },
    createdClientId: {
      type: String,
    },
    dailySpend: {
      type: Number,
      default: 0,
    },
  },

  data: (): {
    isFormValid: boolean;
    nameSearch: string;
    nameMatchSelected: Client;
    returnedClients: Client[];
    advertiserUnavailableText: string;
    hasSearched: boolean;
    goals: string[];
    goal: string;
    secondaryGoal: string;
    dates: string[];
    name: string;
    budget: number | null;
    client: Client | null;
    proposalPreparation: boolean;
    recommendedBudget: number;
    localBudget: number;
    budgetIsFocused: boolean;
    clientFieldFocused: boolean;
    loadingClients: boolean;
    clientRecommendations: Client[];
    cacheKey: string;
  } => ({
    cacheKey: Date.now().toString(),
    localBudget: 0,
    budgetIsFocused: false,
    recommendedBudget: 0,
    isFormValid: false,
    client: null,
    hasSearched: false,
    returnedClients: [],
    nameSearch: '',
    nameMatchSelected: null,
    advertiserUnavailableText: 'Please check with admin to use this advertiser',
    goal: '',
    secondaryGoal: '',
    goals: [
      'Ad Engagement',
      'General Brand Awareness',
      'Phone Call',
      'Promotions/Special Event',
      'Reach',
      'Request for Information/Lead Generation',
      'Store Visit/Foot Traffic',
      'Web Purchase',
      'Website Traffic',
    ],
    dates: [],
    name: '',
    budget: null,
    proposalPreparation: false,
    clientFieldFocused: false,
    loadingClients: false,
    clientRecommendations: [],
  }),
  watch: {
    openDialog(val) {
      if (val) {
        this.getRecentClients();
      }
    },
    createdClientId(val: { id: string; name: string }): void {
      if (val && !this.clientId) {
        // TODO: rethink it
        this.clients.push(val);
        this.client = val;

        this.$emit('clear-created-client');
      }
    },
    nameSearch(val: string): void {
      if (!val) {
        this.$refs.clientSelectDialog.resetValidation();
        this.hasSearched = false;
      }
      if (val?.length >= 3 && this.clientFieldFocused) {
        this.queryForClients(val);
      }
    },
    dialog(): void {
      this.cacheKey = Date.now().toString();
    },
    budget(): void {
      this.$refs?.createProposalDialogBudget?.resetValidation();
    },
    dates(dates: string[]): void {
      if (dates?.length === 2) {
        // this.$refs?.createProposalDialogBudget.focus();
        if ((!this.budget || this.selectedBudgetIsRecommended) && this.clientDailySpend) {
          this.budget = this.quickBudgets[0];
          this.recommendedBudget = this.quickBudgets[0];
        }
        this.$refs?.createProposalDialogBudget.focus();
      }
    },
    client(): void {
      if ((!this.budget || this.selectedBudgetIsRecommended) && this.clientDailySpend && this.dates.length === 2) {
        this.budget = this.quickBudgets[0];
        this.recommendedBudget = this.quickBudgets[0];
      }
    },
  },

  computed: {
    ...mapState(Modules.Agency, ['currentAgencyInfo']),
    canCreateClient() {
      return this.currentAgencyInfo.canCreateClient;
    },
    isAgencyAdmin(): boolean {
      return this.$store.getters['auth/isAgencyAdmin'];
    },
    maxProposalLength(): string {
      return this.toDateString((this.newProposalEntity as NewProposalModelContract).maxProposalLengthDays);
    },
    latestProposalStart(): string {
      return this.toDateString((this.newProposalEntity as NewProposalModelContract).maxProposalFutureStartDays);
    },
    goalItems(): string[] {
      return this.goals.filter((g: string) => g !== this.secondaryGoal);
    },
    secondaryGoalItems(): string[] {
      return this.goals.filter((g: string) => g !== this.goal);
    },
    prettyBudget(): string {
      return this.$options.filters.formatPrice(Number(this.budget));
    },
    clientDailySpend(): number {
      return this.client?.BuzzboardRecommendedDailyBudget || this.dailySpend;
    },
    selectedBudgetIsRecommended(): boolean {
      return this.clientDailySpend && this.budget && this.budget === this.recommendedBudget;
    },
    budgetPlaceholderText(): string {
      return this.selectedBudgetIsRecommended ? 'Budget (Recommended)' : 'Budget *';
    },
    rules() {
      const validationService: ValidationServiceContract = this.$container.get(Services.Validation);

      return {
        required: validationService.requiredValidatorFactory(),
        maxLength: validationService.maxLengthValidatorFactory(),
      };
    },
    quickBudgets(): number[] {
      const [start, end] = this.dates;
      let numOfProposalDays = 0;

      if (start && end) {
        numOfProposalDays = datesHelpers.getDifferenceInCalendarDays(new Date(end), new Date(start));
      }

      return (this.clientService as ClientServiceContract).clientRecommendedProposalBudgets({
        dailySpend: this.clientDailySpend,
        numOfProposalDays,
      });
    },
    savingProposal(): boolean {
      return this.$store.state.newProposal.saveProgressLoading || this.proposalPreparation;
    },
    budgetRules(): InputValidationRules {
      return [
        () =>
          Number(this.budget) >= this.budgetMinimum ||
          `Budget must be at least ${this.$options.filters.formatPrice(this.budgetMinimum)}`,
        () =>
          Number(this.budget) <= this.budgetMaximum ||
          `Budget must not exceed ${this.$options.filters.formatPrice(this.budgetMaximum)}`,
      ];
    },
    budgetMinimum(): number {
      return 1000;
    },
    budgetMaximum(): number {
      return this.$store.getters['newProposal/proposalBudgetMax'];
    },
    clients(): Client[] {
      if (!this.hasSearched || !this.nameSearch) return this.clientRecommendations;
      return this.returnedClients;
    },
    openDialog: {
      get(): boolean {
        return this.dialog;
      },
      set(val: boolean): void {
        if (!val) {
          this.closeDialog();
        }
      },
    },
  },

  methods: {
    toDateString(days = 100): string {
      return new Date(new Date().setDate(new Date().getDate() + days)).toISOString();
    },
    setStartDate(start: string): void {
      this.dates = [start, this.dates?.[1] || ''];
    },
    handleBudgetFocus(): void {
      if (this.$refs?.createProposalDialogBudget) {
        this.$refs.createProposalDialogBudget.resetValidation();
      }
      this.localBudget = this.budget > 0 ? this.budget : null;
      this.budgetIsFocused = true;
    },
    handleBudgetInput(e): void {
      this.localBudget = Number(e);

      if (this.localBudget !== this.budget) {
        this.budget = Number(this.localBudget);
      }
    },
    handleBudgetBlur(): void {
      this.budgetIsFocused = false;
    },
    setBudget(budget: number): void {
      this.budget = budget;
      this.localBudget = budget;
      if (budget === this.quickBudgets[0]) {
        this.recommendedBudget = this.quickBudgets[0];
      }
    },
    async createProposal(): Promise<void> {
      const clientId: string = this.clientId ? this.clientId : this.client.id;

      this.proposalPreparation = true;

      const { unwrapOr: unwrapClient } = await (this[Services.Client] as ClientServiceContract).getClientGeoById(
        clientId,
      );
      const geoSelections = unwrapClient(undefined);

      const proposalMarket = {
        ...(geoSelections && Object.keys(geoSelections).length ? { geoSelections } : {}),
      };

      const [startDate, endDate] = this.dates;

      this.dates.sort((a, b) => Date.parse(a) - Date.parse(b));

      const proposalData = {
        clientId,
        name: this.name || '',
        goal: this.goal,
        ...(this.secondaryGoal ? { secondaryGoal: this.secondaryGoal } : {}),
        campaignStartDate: startDate,
        campaignEndDate: endDate,
        budget: this.budget,
        market: proposalMarket,
      };

      const { isErr, unwrap, unwrapErr } = await (
        this[Services.NewProposal] as NewProposalServiceContract
      ).createProposal(proposalData);

      this.proposalPreparation = false;

      if (isErr()) {
        const { message } = unwrapErr();
        this.$log('error', 'createProposalModal.createNewProposal', message);
      }

      const proposal = unwrap();

      const user = this.$store.state.auth.user;

      const clientCategory = this.client?.category?.name || this.clientCategory?.name;
      const cleanClientCategory = clientCategory?.split('^')?.join(', ');

      (this.tracker as TrackerContract).trackCreateProposal({
        userId: user.id,
        agency: this.currentAgencyInfo.name,
        proposalName: this.name,
        proposalId: proposal.id,
        clientName: this.client?.name || this.clientName,
        goal: this.goal,
        budget: this.budget,
        createdAt: new Date().toISOString(),
        clientCategory: cleanClientCategory,
      });

      this.$emit('proposal-created');
      this.$router.push({
        name: Routes.ProposalMarket,
        params: { id: clientId, proposalId: proposal.id },
      });
      this.closeDialog();
    },
    checkEditable(e, client): void {
      if (client.editable) {
        return;
      } else {
        e.preventDefault();
        e.stopPropagation();
      }
    },
    resetSearch(persist = false): void {
      if (this.$refs?.clientSelectDialog && !persist) {
        this.$refs.clientSelectDialog.reset();
      }
      this.returnedClients = [];
      this.hasSearched = false;
    },
    resetData(): void {
      this.dates = [];
      this.budget = null;
      this.localBudget = 0;
    },
    queryForClients: debounce(function (str): void {
      this.searchClient(str);
    }, 500),
    async searchClient(query: string) {
      this.loadingClients = true;
      const { isErr, unwrap, unwrapErr } = await (this[Services.Client] as ClientServiceContract).search(query);
      this.loadingClients = false;
      if (isErr()) {
        return this.$log('error', 'client/search error', unwrapErr());
      }
      this.hasSearched = true;
      this.returnedClients = unwrap();
    },
    async getRecentClients() {
      const { unwrapOr } = await (this[Services.Client] as ClientServiceContract).getRecentClients();
      this.clientRecommendations = unwrapOr([]);
    },
    closeDialog(persist = false): void {
      this.resetSearch(persist);
      this.resetData();
      this.$emit('close-modal');
    },
  },
});

interface MapState {
  defaultMap: string;
}

const map = {
  namespaced: true as const,
  state: (): MapState => ({
    defaultMap: '5f7337d4-e330-4e9a-997d-d17811d4fa41',
    // defaultMap: 'deaff9f1-d4a1-4f83-b47f-d7a4ec64f009',
  }),
  getters: {},
  mutations: {},
  actions: {},
};

export default map;


import Vue, { PropType } from 'vue';
import { DataTableHeader } from 'vuetify';
import WrapperWithTooltip from '@/components/WrapperWithTooltip.vue';
import { Models } from '@/injectables/tokens';
import { ColorModelContract, ColorType } from '@/injectables';

export default Vue.extend({
  name: 'ClientActiveCampaigns',
  components: { WrapperWithTooltip },

  useInjectable: [Models.Colors],

  props: {
    items: {
      type: Array as PropType<any[]>,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  data: (): {
    headers: DataTableHeader[];
  } => ({
    headers: [
      { text: 'Campaign name', width: '40%', value: 'name' },
      { text: 'Start date', width: '30%', value: 'startDate' },
      { text: 'End date', width: '30%', value: 'endDate' },
    ],
  }),
  computed: {
    colorSet(): string[] {
      const compulseColor = this.$c360?.colors?.primary || '';
      const baseColor = compulseColor && compulseColor.includes('#') ? compulseColor : '#7abcfb';
      const numberOfColors = this.items.length || 15;

      return (this[Models.Colors] as ColorModelContract).getGeneratedColors({
        numberOfColors,
        baseColor: baseColor,
        type: ColorType.hex,
      });
    },
  },
  methods: {
    sort(items, index, isDescending) {
      items.sort((a, b) => {
        if (isDescending && isDescending.length && isDescending[0]) {
          return a[index[0]] < b[index[0]] ? -1 : 1;
        } else if (isDescending && isDescending.length) {
          return b[index[0]] < a[index[0]] ? -1 : 1;
        }
      });
      return items;
    },
    getProductColor(index) {
      return this.colorSet[Number(index)];
    },
  },
});


import Vue, { PropType } from 'vue';
import { Models, Services } from '@/injectables/tokens';
import SearchField from '@/shared/ui/components/search-field.vue';
import SegmentButton from './segmentButton.vue';
import { SegmentItem as SegmentItemType } from '@/injectables';

export default Vue.extend({
  name: 'TargetingSearch',

  useInjectable: [Models.InstantIO, Models.BaseProduct, Services.TargetingSegments, Models.TargetingSegments],

  components: { SearchField, SegmentButton },

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Array as PropType<SegmentItemType[]>,
      default: () => [],
    },
    listHeight: {
      type: [String, Number],
      default: '275px',
    },
    borderedSearch: {
      type: Boolean,
      default: false,
    },
  },
  data(): {
    search: string;
    searchedSegments: SegmentItemType[];
    searchNumber: number;
    isLoading: boolean;
  } {
    return {
      search: '',
      searchNumber: 0,
      searchedSegments: [],
      isLoading: false,
    };
  },

  created() {
    this.$store.dispatch('targetingSegments/getTargeting');
  },

  computed: {
    searchedItems(): SegmentItemType[] {
      return this.value;
    },
    height(): string {
      return typeof this.listHeight === 'number' ? `${this.listHeight}px` : this.listHeight;
    },
  },
  methods: {
    selectSearchedSegment(segment) {
      if (this.isActiveSearchSegment(segment)) {
        this.$emit('input', [...this.value.filter(el => el.id !== segment.id)]);
        return;
      }
      this.$emit('input', [...this.value, segment]);
    },
    isActiveSearchSegment(segment) {
      return this.searchedItems.some(el => el.id === segment.id);
    },
    async searchForSegments(value) {
      this.search = value;
      this.searchedSegments = [];
      this.searchNumber = 0;
      if (this.search.length < 3) return;

      this.isLoading = true;
      const { isErr, unwrap } = await this[Services.TargetingSegments].searchSegments(this.search);
      this.isLoading = false;

      if (isErr()) return;
      const { items, total } = unwrap();
      this.searchedSegments = items;
      this.searchNumber = total;
    },
  },
});

import { render, staticRenderFns } from "./no-data.vue?vue&type=template&id=39d8ceaf&scoped=true"
import script from "./no-data.vue?vue&type=script&lang=ts"
export * from "./no-data.vue?vue&type=script&lang=ts"
import style0 from "./no-data.vue?vue&type=style&index=0&id=39d8ceaf&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39d8ceaf",
  null
  
)

export default component.exports
import { AnyObject } from '@/shared/types';
import Vue from 'vue';

const useInjectableMixin = Vue.extend({
  created() {
    if (!this.$container) {
      throw new Error(
        'Container is not available when useInjectableMixin mixin try to provide needed services and models',
      );
    }
    if (!Array.isArray(this.$options.useInjectable) || this.$options.useInjectable.length === 0) {
      return;
    }
    for (const token of this.$options.useInjectable) {
      (this as AnyObject)[token] = this.$container.get(token);
    }
  },
});

export default useInjectableMixin;

import { inject, injectable } from 'inversify';
import { Err, Ok, Result } from '@sniptt/monads';
import { AuthService as RestAuthService } from './http';
import { Failure } from '@/injectables/failure';
import { AdProductUser } from '@/shared/types';
import { MeDocument, MeQuery } from '../graphql/queries/me.generated';
import { AuthServiceContract, Tokens } from '../contracts';
import {
  RefreshAccessTokenDocument,
  RefreshAccessTokenMutation,
  RefreshAccessTokenMutationVariables,
} from '../graphql/mutations/refresh-token.generated';
import { LogoutDocument, LogoutMutation, LogoutMutationVariables } from '../graphql/mutations/logout.generated';
import { LoginDocument, LoginMutation, LoginMutationVariables } from '../graphql/mutations/login.generated';
import {
  SendResetPasswordEmailDocument,
  SendResetPasswordEmailQuery,
  SendResetPasswordEmailQueryVariables,
} from '../graphql/queries/send-reset-password-email.generated';
import {
  UpdatePasswordDocument,
  UpdatePasswordMutation,
  UpdatePasswordMutationVariables,
} from '../graphql/mutations/update-password.generated';
import {
  GetUserAgencyDocument,
  GetUserAgencyQuery,
  GetUserAgencyQueryVariables,
} from '../graphql/queries/get-user-agency.generated';
import {
  UpdateUserAgencyDocument,
  UpdateUserAgencyMutation,
  UpdateUserAgencyMutationVariables,
} from '../graphql/mutations/update-user-agency.generated';
import { AgencyType } from '@/app/graphql';
import { Services } from '@/injectables/tokens';
import { TrackerContract } from '@/injectables/integrations';
import { LocalStorageServiceContract } from '@/injectables';
@injectable()
export class AuthService extends RestAuthService implements AuthServiceContract {
  @inject(Services.Tracker) private readonly _trackerService: TrackerContract;
  @inject(Services.LocalStorage) private readonly _storage: LocalStorageServiceContract;
  async checkIfAuth(): Promise<Result<AdProductUser, Failure>> {
    try {
      const { data, error } = await this._apollo.query<MeQuery>({
        query: MeDocument,
      });

      if (error) {
        return Err({
          message: 'Unauthorized',
        });
      }

      const { me } = data;

      const user = {
        ...me,
        instantIOEnabled: me?.agency?.instantIOEnabled,
        IndividualRight: me.rights?.individualRight,
        AgencyRight: me.rights?.agencyRight,
        AccessRights: me.rights.accessRights,
        Agency: me?.agency?.id,
        agency: me?.agency,
        C360Products: me.products,
        agencyName: me?.agency?.name,
      };

      this._trackerService && this._trackerService.registerSuperProperties({ user: me });

      return Ok(user);
    } catch (error) {
      return Err({
        message: `Can't get products at this time: ${error}`,
      });
    }
  }

  async refreshToken(token: string): Promise<Result<Tokens, Failure>> {
    try {
      const themeCode = this._storage.getItem('mp_theme_code');
      const { data } = await this._apollo.mutate<RefreshAccessTokenMutation, RefreshAccessTokenMutationVariables>({
        mutation: RefreshAccessTokenDocument,
        variables: { refreshToken: token, themeCode },
      });

      return Ok(data.refreshAccessToken);
    } catch (error) {
      return Err({
        message: `Invalid token: ${error}`,
      });
    }
  }

  async signOut(token: string): Promise<Result<boolean, Failure>> {
    try {
      const { data } = await this._apollo.mutate<LogoutMutation, LogoutMutationVariables>({
        mutation: LogoutDocument,
        variables: { refreshToken: token },
      });

      return Ok(data.logout);
    } catch (error) {
      return Err({
        message: `Can't logout at this time: ${error}`,
      });
    }
  }

  async signIn(email: string, password: string): Promise<Result<Tokens, Failure>> {
    try {
      const themeCode = this._storage.getItem('mp_theme_code');
      const { data } = await this._apollo.mutate<LoginMutation, LoginMutationVariables>({
        mutation: LoginDocument,
        variables: { email, password, themeCode },
      });

      return Ok(data.login);
    } catch (error) {
      return Err({
        message: `Can't logout at this time: ${error}`,
      });
    }
  }

  async getUserAgency(): Promise<Result<GetUserAgencyQuery['getMyMediaplannerAgency'], Failure>> {
    try {
      const { data } = await this._apollo.query<GetUserAgencyQuery, GetUserAgencyQueryVariables>({
        query: GetUserAgencyDocument,
      });

      return Ok(data.getMyMediaplannerAgency);
    } catch (error) {
      return Err({
        message: `Can't logout at this time: ${error}`,
      });
    }
  }

  async updateUserAgency(token: string, agencyId: string, type?: AgencyType): Promise<Result<Tokens, Failure>> {
    try {
      const { data } = await this._apollo.mutate<UpdateUserAgencyMutation, UpdateUserAgencyMutationVariables>({
        mutation: UpdateUserAgencyDocument,
        variables: { refresh: token, agencyId, type },
      });
      return Ok(data.updateActiveAgency);
    } catch (error) {
      return Err({
        message: `Can't logout at this time: ${error}`,
      });
    }
  }

  async sendResetPasswordEmail(email, redirectUrl): Promise<Result<boolean, Failure>> {
    try {
      const { data, errors, error } = await this._apollo.query<
        SendResetPasswordEmailQuery,
        SendResetPasswordEmailQueryVariables
      >({
        query: SendResetPasswordEmailDocument,
        variables: { email, redirectUrl },
      });

      if (error || errors) {
        return Err({
          message: `Can't send reset password letter`,
        });
      }

      return Ok(data.sendResetPasswordEmail);
    } catch (error) {
      return Err({
        message: `Can't send reset password letter: ${error}`,
      });
    }
  }

  async updatePassword(password, user, token): Promise<Result<boolean, Failure>> {
    try {
      const { data, errors } = await this._apollo.mutate<UpdatePasswordMutation, UpdatePasswordMutationVariables>({
        mutation: UpdatePasswordDocument,
        variables: { input: { id: user, password, token } },
      });

      if (errors) {
        return Err({
          message: `Can reset a password at this time`,
        });
      }
      return Ok(data.setNewPassword);
    } catch (error) {
      return Err({
        message: `Can reset a password: ${error}`,
      });
    }
  }
}

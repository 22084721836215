import { GetterTree } from 'vuex';
import { AuthState } from './state';
import { UnsafeAny } from '@/shared/types';
import { RouteItemProps } from './types';
import { AgencyType } from '@/app/graphql';
import { Route, routeCampaignsWithSubItems, routeClient, routeAgencyDashboard, routes } from '@/entities/user';
import clonedeep from 'lodash.clonedeep';
import { mapRoute } from '@/features';

// we have to keep getters any for this version of vuex
// see: https://github.com/vuejs/vuex/issues/1756
export const getters = (): GetterTree<AuthState, UnsafeAny> => ({
  isAdmin: state => state.user?.IndividualRight?.includes('SU'),

  isAgencySuperAdmin: (state, getters): boolean => {
    return getters.isAdmin || state.user?.AgencyRight?.includes('AGENCY_SUPER_ADMIN');
  },

  // for purpose of displaying additional fields/columns dropdowns
  isAgencyAdmin: (state, getters): boolean => {
    return getters.isAdmin || getters.isAgencySuperAdmin || state.user?.AgencyRight?.includes('AGENCY_ADMIN');
  },

  isHelpCenterAvailable: (state): boolean => {
    return !state.user?.AccessRights?.includes('HIDE_HELP_CENTER');
  },

  authRoutes: (state): RouteItemProps[] => {
    if (!state.user) {
      return state.routes.filter(route => route.authorization.includes('guest'));
    }
    return state.routes;
  },

  isParentAgency(state): boolean {
    return state.user.agency.type === AgencyType.Agency;
  },

  sideBarItems(state, getters, rootState): Route[] {
    const instantIo = rootState?.agency?.currentAgencyInfo?.instantIOEnabled;
    const isAdmin = getters.isAgencyAdmin;
    const isSU = getters.isAdmin;
    const baseRoutes = clonedeep(routes);
    if (isAdmin || instantIo) {
      baseRoutes.campaigns = routeCampaignsWithSubItems;
    }
    if (isSU) {
      baseRoutes.agencyDashboard = routeAgencyDashboard;
    }
    if (isAdmin) {
      baseRoutes.clients = routeClient;
    }
    return Object.values(baseRoutes).map(mapRoute);
  },
});

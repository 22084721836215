import 'reflect-metadata';
import { injectable } from 'inversify';
import { HelpSectionModelContract, NewHelpPage } from '@/injectables';

@injectable()
export class HelpSectionModel implements HelpSectionModelContract {
  getDefaultPage(): NewHelpPage {
    return {
      Title: 'New page',
      Index: 0,
      PageContent: {
        type: 'doc',
        content: [
          {
            type: 'paragraph',
            attrs: {
              textAlign: 'left',
            },
            content: [
              {
                type: 'text',
                text: 'empty',
              },
            ],
          },
        ],
      },
    };
  }
}

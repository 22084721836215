import { Failure } from '@/injectables/failure';

import { Err, Ok, Result } from '@sniptt/monads/build';
import { FileServiceContract, TrackDownloadCallback } from '../..';
import {
  GetAvailsXlsxQuery,
  GetAvailsXlsxQueryVariables,
  GetAvailsXlsxDocument,
} from '../graphql/queries/get-avails-xlsx.generated';
import {
  GetKeywordPlannerXlsxDocument,
  GetKeywordPlannerXlsxQuery,
  GetKeywordPlannerXlsxQueryVariables,
} from '../graphql/queries/get-keyword-planner-xlsx.generated';
import {
  GetInstantIoXlsxDocument,
  GetInstantIoXlsxQuery,
  GetInstantIoXlsxQueryVariables,
} from '../graphql/queries/get-instant-io-xlsx.generated';
import {
  GetProposalXlsxDocument,
  GetProposalXlsxQuery,
  GetProposalXlsxQueryVariables,
} from '../graphql/queries/get-proposal-xlsx.generated';
import { DocumentNode } from 'graphql';
import {
  GetProposalPdfDocument,
  GetProposalPdfQuery,
  GetProposalPdfQueryVariables,
} from '../graphql/queries/get-proposal-pdf.generated';
import {
  GetProposalPptxDocument,
  GetProposalPptxQuery,
  GetProposalPptxQueryVariables,
} from '../graphql/queries/get-proposal-pptx.generated';
import {
  GetAvailsPdfDocument,
  GetAvailsPdfQuery,
  GetAvailsPdfQueryVariables,
} from '../graphql/queries/get-avails-pdf.generated';
import { Service } from '@/injectables/service';

type FileInfo =
  | GetProposalXlsxQuery
  | GetInstantIoXlsxQuery
  | GetKeywordPlannerXlsxQuery
  | GetAvailsXlsxQuery
  | GetProposalPdfQuery
  | GetProposalPptxQuery
  | GetAvailsPdfQuery;

export class FileService extends Service implements FileServiceContract {
  _prepareFilename(name: string, type = 'xlsx'): string {
    return `${name.replace(' ', '_').replace(/[^a-z0-9\-\._]/gi, '')}.${type}`;
  }

  async downloadAvailsXlsx(
    info: { avails: GetAvailsXlsxQueryVariables['input'] } & { name?: string },
    trackDownloadCallback: TrackDownloadCallback,
  ): Promise<Result<boolean, Failure>> {
    const { name, avails } = info;
    const filename = this._prepareFilename(name || '');
    return this.getAndDownloadFile<GetAvailsXlsxQueryVariables>(
      {
        variables: {
          input: avails,
        },
        document: GetAvailsXlsxDocument,
      },
      trackDownloadCallback,
      filename,
    );
  }

  async downloadAvailsPDF(
    info: { avails: GetAvailsPdfQueryVariables['data'] } & { name: string },
    trackDownloadCallback,
  ): Promise<Result<boolean, Failure>> {
    const { avails, name } = info;
    const filename = this._prepareFilename(name || '', 'pdf');

    return this.getAndDownloadFile<GetAvailsPdfQueryVariables>(
      {
        variables: {
          data: avails,
        },
        document: GetAvailsPdfDocument,
      },
      trackDownloadCallback,
      filename,
    );
  }

  async downloadKeywordPlannerXlsx(
    info: GetKeywordPlannerXlsxQueryVariables['input'] & { name: string },
    trackDownloadCallback,
  ): Promise<Result<boolean, Failure>> {
    const { name, url, ...keywordsInfo } = info;
    const filename = this._prepareFilename(name || '');
    return this.getAndDownloadFile<GetKeywordPlannerXlsxQueryVariables>(
      {
        variables: {
          input: { ...keywordsInfo, url: url || 'https://google.com' },
        },
        document: GetKeywordPlannerXlsxDocument,
      },
      trackDownloadCallback,
      filename,
    );
  }

  async downloadInstantXlsx(
    info: GetInstantIoXlsxQueryVariables & { name: string },
    trackDownloadCallback,
  ): Promise<Result<boolean, Failure>> {
    const { id, timezoneOffset, name } = info;
    const filename = this._prepareFilename(name || '');

    return this.getAndDownloadFile<GetInstantIoXlsxQueryVariables>(
      {
        variables: {
          id,
          timezoneOffset,
        },
        document: GetInstantIoXlsxDocument,
      },
      trackDownloadCallback,
      filename,
    );
  }

  async downloadProposalXlsx(
    info: GetProposalXlsxQueryVariables & { name: string },
    trackDownloadCallback,
  ): Promise<Result<boolean, Failure>> {
    const { id, timezoneOffset } = info;

    return this.getAndDownloadFile<GetProposalXlsxQueryVariables>(
      {
        variables: {
          id,
          timezoneOffset,
        },
        document: GetProposalXlsxDocument,
      },
      trackDownloadCallback,
    );
  }

  async downloadProposalPDF(
    info: GetProposalPdfQueryVariables & { name: string },
    trackDownloadCallback,
  ): Promise<Result<boolean, Failure>> {
    const { id, name } = info;
    const filename = this._prepareFilename(name || '', 'pdf');

    return this.getAndDownloadFile<GetProposalPdfQueryVariables>(
      {
        variables: {
          id,
        },
        document: GetProposalPdfDocument,
      },
      trackDownloadCallback,
      filename,
    );
  }

  async downloadProposalPPTX(
    info: GetProposalPptxQueryVariables & { name: string },
    trackDownloadCallback,
  ): Promise<Result<boolean, Failure>> {
    const { id, name } = info;
    const filename = this._prepareFilename(name || '', 'pptx');

    return this.getAndDownloadFile<GetProposalPptxQueryVariables>(
      {
        variables: {
          id,
        },
        document: GetProposalPptxDocument,
      },
      trackDownloadCallback,
      filename,
    );
  }

  async getAndDownloadFile<V>(
    request: { variables: V; document: DocumentNode },
    trackDownloadCallback: TrackDownloadCallback,
    filename?: string,
  ): Promise<Result<boolean, Failure>> {
    const { variables, document } = request;
    const { data, error } = await this._apollo.query<FileInfo, V>({
      query: document,
      variables,
    });

    const { url, message } = data.fileInfo;

    if (!url || error) {
      return Err({ message });
    }

    this.downloadFileByUrl(url, filename);
    trackDownloadCallback();
    return Ok(true);
  }

  downloadFileByUrl(url: string, filename?: string): void {
    const link = document.createElement('a');
    link.href = url;

    if (filename) link.download = filename;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}


import Vue from 'vue';
import CsvUploader from '@/components/Basic/Uploader/CsvUploader.vue';
import { Models } from '@/injectables/tokens';
import { FilesModelContract } from '@/injectables';

export default Vue.extend({
  name: 'CsvUploadDialog',

  components: { CsvUploader },

  useInjectable: [Models.Files],

  props: {
    open: {
      type: Boolean,
      required: true,
    },
    geoFence: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'keywords',
    },
    propHelperText: {
      type: String,
      default: 'CSV file smaller than 2MB',
    },
    limit: {
      type: Number,
    },
  },

  data: (): {
    uploadingFile: boolean;
    file: File;
    parsing: boolean;
  } => ({
    uploadingFile: false,
    file: null,
    parsing: false,
  }),

  computed: {
    helperText(): string {
      return this.file ? this.file.name : this.propHelperText;
    },
  },

  methods: {
    onFilePicked(file: File): void {
      this.file = file;
    },
    onClose(): void {
      if (this.parsing) return;
      this.file = null;
      this.$emit('close-modal');
    },
    parseCsvFile(file: File): Promise<Array<string | { address: string; radius: number }>> {
      if (this.type === 'generic') {
        return (this[Models.Files] as FilesModelContract).parseGenericCsvKeywordsFile(file);
      } else if (this.type === 'geofence') {
        return (this[Models.Files] as FilesModelContract).parseGeoFenceFile(file);
      } else if (this.type === 'keywords') {
        return (this[Models.Files] as FilesModelContract).parseKeywordsFile(file);
      }

      return (this[Models.Files] as FilesModelContract).parseKeywordsFile(file, this.limit);
    },
    onDoneClick(): void {
      this.parsing = true;
      this.parseCsvFile(this.file)
        .then(items => {
          this.$emit('done-click', items);
        })
        .catch(() => {
          this.$store.dispatch('showSnackbar', { content: 'Invalid file template', color: 'error' });
        })
        .finally(() => {
          this.inputKey = Date.now();
          this.parsing = false;
          this.onClose();
        });
    },
  },
});

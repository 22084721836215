import { Module } from 'vuex';
import { RootState } from '../root/state';
import { mutations } from './mutations';
import { NotificationsState, state } from './state';

export const notificationsModule = (): Module<NotificationsState, RootState> => {
  return {
    namespaced: true,
    state: state(),
    mutations: mutations(),
  };
};

export { NotificationsMutations } from './mutations';

import 'reflect-metadata';

import { Service } from '../../../service';

import { injectable } from 'inversify';
import { DemoListItem, ProposalDemographic, ProposalDemographics } from '@/shared/types';
import { NewProposalModelContract, Status } from '@/injectables';

@injectable()
export class NewProposalModel extends Service implements NewProposalModelContract {
  segmentToDemoName(d: string): string {
    return d === 'Children' ? '1+ Children' : d;
  }

  calculateTargetedPopulation({
    demoArray,
    total,
    selectedDemographics,
  }: {
    demoArray: ProposalDemographic[];
    total: number;
    selectedDemographics: any;
  }): number {
    const isSelected = (item: DemoListItem) => {
      if (!Array.isArray(selectedDemographics) || selectedDemographics.length === 0) return false;
      return selectedDemographics.some(d => this.segmentToDemoName(d.name) === item.property);
    };

    return this.calculateTargetingPopulation({ demoArray, total, isSelectedCallBack: isSelected });
  }

  calculateOTTTargetedPopulation({ demoArray, total }: { demoArray: ProposalDemographic[]; total: number }): number {
    const isSelected = item => item.isSelected === true;
    return this.calculateTargetingPopulation({ demoArray, total, isSelectedCallBack: isSelected });
  }

  calculateTargetingPopulation({ demoArray, total, isSelectedCallBack }) {
    const arrOfPercents = [];

    demoArray.forEach(demo => {
      const demoIndex = demoArray.findIndex(item => item.demo === demo.demo);

      if (demoIndex !== -1) {
        const selectedPopulation = demoArray[demoIndex].list
          .filter(item => isSelectedCallBack(item))
          .map(item => item.value)
          .reduce((a, b) => {
            return Number.parseInt(a as unknown as string) + Number.parseInt(b as unknown as string);
          }, 0);

        if (selectedPopulation === 0) {
          arrOfPercents.push(1);
        } else {
          arrOfPercents.push(selectedPopulation / total);
        }
      }
    });

    const totalPercentToCalc = arrOfPercents.reduce((a, b) => a * b);

    return Math.round(total * totalPercentToCalc);
  }

  availsDemographicsPercentage(demographics: ProposalDemographics): number {
    const percentOfDemoSelected = (list: DemoListItem[], totalPopulation) => {
      const totalSelected = list.reduce((a, b) => a + (b.isSelected ? Number(b.value) : 0), 0);

      return Math.round(((totalSelected || totalPopulation) / (totalPopulation || 1)) * 100);
    };

    let percent = 100;

    demographics?.list?.forEach(
      arr => (percent *= percentOfDemoSelected(arr.list, Number(demographics.totalPopulation)) / 100),
    );

    return percent || 100;
  }

  newProposalIsChangeDisabled({
    proposalStatus,
    isAgencyAdmin,
  }: {
    proposalStatus: Status;
    isAgencyAdmin: boolean;
  }): boolean {
    return (
      [Status.Sold, Status.ClosedLost].includes(proposalStatus) ||
      (!isAgencyAdmin && [Status.SubmittedForReview, Status.UnderReview].includes(proposalStatus))
    );
  }

  demographicSelections(): ProposalDemographics {
    return {
      targeted: 0,
      totalPopulation: 0,
      list: [
        {
          demo: 'Age',
          list: [
            {
              property: '18-24',
              isSelected: false,
              value: 0,
            },
            {
              property: '25-34',
              isSelected: false,
              value: 0,
            },
            {
              property: '35-44',
              isSelected: false,
              value: 0,
            },
            {
              property: '45-54',
              isSelected: false,
              value: 0,
            },
            {
              property: '55-64',
              isSelected: false,
              value: 0,
            },
            {
              property: '65+',
              isSelected: false,
              value: 0,
            },
          ],
        },
        {
          demo: 'Income',
          list: [
            {
              property: '$0-$49999',
              isSelected: false,
              value: 0,
            },
            {
              property: '$50000-$74999',
              isSelected: false,
              value: 0,
            },
            {
              property: '$75000-$99999',
              isSelected: false,
              value: 0,
            },
            {
              property: '$100000-$149999',
              isSelected: false,
              value: 0,
            },
            {
              property: '$150000-$249999',
              isSelected: false,
              value: 0,
            },
            {
              property: '$250000+',
              isSelected: false,
              value: 0,
            },
          ],
        },
        {
          demo: 'Gender',
          list: [
            {
              property: 'Male',
              isSelected: false,
              value: 0,
            },
            {
              property: 'Female',
              isSelected: false,
              value: 0,
            },
          ],
        },
        {
          demo: 'Family',
          list: [
            {
              property: '1+ Children',
              isSelected: false,
              value: 0,
            },
            {
              property: 'No Children',
              isSelected: false,
              value: 0,
            },
          ],
        },
        {
          demo: 'Marital',
          list: [
            {
              property: 'Married',
              isSelected: false,
              value: 0,
            },
            {
              property: 'Single',
              isSelected: false,
              value: 0,
            },
          ],
        },
        {
          demo: 'Home',
          list: [
            {
              property: 'Rent',
              isSelected: false,
              value: 0,
            },
            {
              property: 'Own',
              isSelected: false,
              value: 0,
            },
          ],
        },
      ],
    };
  }
  censusDefault(): Record<string, string[]> {
    return {
      enthusiastList: [
        'Auto Enthusiast',
        'Business, Finance, Real Estate & Insurance',
        'Career Seeker',
        'Comedy',
        'Dating',
        'Education Seeker',
        'Election Follower',
        'Electronics',
        'Entertainment Craver',
        'Family Oriented',
        'Fashion Passionate',
        'Film Buff',
        'Fitness Fanatic',
        'Foodie',
        'Gamer',
        'Grocery, Health & Beauty',
        'Home & Garden',
        'Pet Lovers',
        'Political Enthusiast',
        'Religion Devoted',
        'Science Interest',
        'Shopping Addict',
        'Sports Fan',
        'Techie',
        'Travel',
      ],
      lifestyleList: [
        'Achievers',
        'Charitable Donor',
        'Established Families',
        'Family Starters',
        'Fashionista',
        'Golden Oldies',
        'High Spenders',
        'Leisure Travelers',
        'Limiteds',
        'Middle America',
        'Outdoor Enthusiast',
        'Secure & Mature',
        'Shopping Addicts',
        'Singles',
        'Soccer Moms',
        'Spa Mavens',
        'The Blues',
        'Trendy Homemakers',
        'Women On the Go',
        'Young & Hip',
      ],
      occupationList: [
        'Accounting',
        'Arts, Entertainment & Gaming',
        'Clerical & Administrative',
        'Construction',
        'Customer Service',
        'Education, Training & Library',
        'Engineering & Architecture',
        'Finance',
        'Health Care Professionals',
        'Human Resources',
        'Insurance',
        'Legal Services',
        'Management & Business',
        'Manufacturing & Production',
        'Marketing & Advertising',
        'Media & Communication',
        'Merchandising, Purchasing & Retail',
        'Military & Government',
        'Personal & Home Services',
        'Public Relations',
        'Public Utilities & Services',
        'Real Estate',
        'Real Estate & Building Maintenance',
        'Research',
        'Retiree',
        'Sales & Sales Management',
        'Science & Biotech',
        'Transportation & Logistics',
        'Travel, Hospitality & Restaurant',
      ],
      searchHistoryList: [
        'Auto',
        'Books & Magazines',
        'Business, Finance, Real Estate & Insurance',
        'Clothing, Shoes & Jewelry',
        'Computers, Office & Occupation',
        'Digital Downloads',
        'Electronics',
        'Grocery, Health & Beauty',
        'Home & Garden',
        'Movies, Music & Games',
        'Seniors',
        'Sports & Outdoors',
        'Tools & Industrial',
        'Toys, Kids & Baby',
        'Travel',
      ],
      shoppingList: [
        'Auto',
        'Clothing, Shoes & Jewelry',
        'Electronics',
        'Grocery, Health & Beauty',
        'Home & Garden',
        'Movies, Music & Games',
        'Tools & Industrial',
        'Travel',
      ],
      customList: [],
    };
  }
  maxProposalLengthDays = 365 * 2;

  maxProposalFutureStartDays = 365;
}

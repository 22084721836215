import { Module } from 'vuex';
import { Container } from 'inversify';
import { Models, Services } from '@/injectables/tokens';
import {
  ProposalServiceContract,
  LoggerContract,
  CalendarServiceContract,
  ProposalProductModelContract,
} from '@/injectables';
import { ProposalState, state } from './state';
import { getters } from './getters';
import { mutations } from './mutations';
import { actions } from './actions';
import { RootState } from '../root/state';

interface ProposalModuleFactoryReturn {
  module: Module<ProposalState, RootState>;
}

// We have to keep getters and actions rootState as any
// see: https://github.com/vuejs/vuex/issues/1756
export const proposalModule = (container: Container): ProposalModuleFactoryReturn => {
  const proposalService = container.get<ProposalServiceContract>(Services.Proposal);
  const calendarService = container.get<CalendarServiceContract>(Services.Calendar);
  const ProposalProductModel = container.get<ProposalProductModelContract>(Models.ProposalProduct);
  const logger = container.get<LoggerContract>('logger');

  return {
    module: {
      namespaced: true,
      state: state(),
      getters: getters({ logger }),
      mutations: mutations(),
      actions: actions({ logger, proposalService, calendarService, ProposalProductModel }),
    },
  };
};

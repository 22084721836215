import { OutputState } from './types';

export const state = (): OutputState => ({
  actionHistoryIndex: 0,
  actionHistory: [],
  availableLayouts: [],
  productLibrarySlides: [],
  insertMenuItems: [
    {
      label: 'Shape',
      icon: 'mdi-shape-outline',
      menu: [
        {
          label: 'Rectangle',
          icon: 'crop_3_2',
          action: { insertShape: 'rectangle' },
        },
        {
          label: 'Circle',
          icon: 'radio_button_unchecked',
          action: { insertShape: 'circle' },
        },
        {
          label: 'Triangle',
          icon: 'change_history',
          action: { insertShape: 'triangle' },
        },
      ],
    },
    {
      label: 'Text box',
      icon: 'post_add',
      action: { insertText: null },
    },
    {
      label: 'Image',
      icon: 'insert_photo',
      action: { triggerUploadImage: null },
    },
    {
      label: 'Table',
      icon: 'table_chart',
      menu: [
        {
          label: 'Dimensions',
          action: { insertTable: 'table' },
        },
      ],
    },
  ],
  availableDynamics: [],
  getLayoutsLoading: false,
  getLibrarySlidesLoading: false,
  updateLibrarySlideLoading: false,
  productSlidesLibraryLoading: false,
  librarySlides: {
    loaded: false,
    agency: [],
    agencyId: '',
    user: [],
    shared: [],
  },
  slideAlternatives: [],
  activeOutput: {
    agency: '',
    slides: [],
    layout: '',
    blanks: [],
    slideColors: {},
    PropertyId: '',
    colors: [],
    dynamicLinks: [],
  },
  layoutColors: null,
  localLayoutColors: null,
  outputLoading: false,
  getOutputLoading: false,
  saveLayoutColorsLoading: false,
  outputSlides: [],
  activeSlideId: '',
  outputHasBeenChanged: false,
  isScrollingByClick: false,
  openImageUpload: false,
  activeElementType: '',
  activeElementId: '',
  currentTextProps: null,
  slideTexts: [],
  slideImages: [],
  slideShapes: [],
});

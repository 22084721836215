/* eslint-disable no-unused-vars */
import { MutationTree } from 'vuex';
import { Alert, RootState } from './state';

export enum MutationTypes {
  SET_SEARCH_TERM = 'SET_SEARCH_TERM',
  ADD_FILTER = 'ADD_FILTER',
  REMOVE_FILTER = 'REMOVE_FILTER',
  REMOVE_ALL_FILTERS = 'REMOVE_ALL_FILTERS',
  SET_ALERT = 'SET_ALERT',
  DISMISS_ALERT = 'DISMISS_ALERT',
  SHOW_SNACKBAR = 'SHOW_SNACKBAR',
  HIDE_SNACKBAR = 'HIDE_SNACKBAR',
  SET_NEXT_URL = 'SET_NEXT_URL',
  SET_ACTIVE_AGENCY = 'SET_ACTIVE_AGENCY',
  SET_ACTIVE_SUB_AGENCY = 'SET_ACTIVE_SUB_AGENCY',
}

export const mutations = (): MutationTree<RootState> => ({
  [MutationTypes.SET_SEARCH_TERM](_state, _search: string): void {
    if (!_search || !_search.length) {
      _search = '';
    }
    _state.searchTerm = _search;
  },
  [MutationTypes.ADD_FILTER](_state, filter: { [key: string]: string }): void {
    _state.filters = Object.assign({}, _state.filters, filter);
  },
  [MutationTypes.REMOVE_FILTER](_state, key: string): void {
    const filters = { ..._state.filters };
    delete filters[key];
    _state.filters = filters;
  },
  [MutationTypes.REMOVE_ALL_FILTERS](_state): void {
    _state.filters = {};
  },
  [MutationTypes.SET_NEXT_URL](_state, _nextUrl: string): void {
    _state.nextUrl = _nextUrl;
  },
  [MutationTypes.SET_ALERT](_state, _alertObject: Alert): void {
    _state.alert = { show: true, ..._alertObject };
  },
  [MutationTypes.DISMISS_ALERT](_state): void {
    _state.alert = { show: false, type: 'error', message: '', dismissible: true };
  },
  [MutationTypes.SHOW_SNACKBAR](
    _state,
    { content, color, timeout, withLoader }: { content: string; color: string; timeout: number; withLoader: boolean },
  ): void {
    _state.snackbarContent = content;
    _state.snackbarColor = color;
    _state.snackbarTimeout = timeout;
    _state.snackbarWithLoader = withLoader;
  },
  [MutationTypes.HIDE_SNACKBAR](_state): void {
    _state.snackbarTimeout = 3500;
  },
  [MutationTypes.SET_ACTIVE_AGENCY](_state, _agency: { name: string; PropertyId: string }): void {
    if (!_agency || !_agency.name || _agency.name === 'Global') {
      _agency = { name: 'Global', PropertyId: 'Global' };
    }
    _state.activeAgency = _agency;
  },
  [MutationTypes.SET_ACTIVE_SUB_AGENCY](_state, _agency: { name: string; PropertyId: string }): void {
    _state.activeSubAgency = _agency;
  },
});

import { ApolloClient, InMemoryCache, HttpLink, DefaultOptions, ApolloLink, fromPromise } from '@apollo/client/core';
import { onError } from '@apollo/client/link/error';
import { omitDeep } from './utils';
import { Tokens } from '@/injectables/services/auth/contracts';
import { RefreshAccessTokenDocument } from '@/injectables/services/auth/graphql/mutations/refresh-token.generated';

interface GraphqlCallbacks {
  onErrorTrackError({
    code,
    graphqlCode,
    graphqlMessage,
  }: {
    code: number;
    graphqlCode: number | string;
    graphqlMessage: string;
  }): Promise<void>;
  onErrorShowSnackBar({ message }: { message: string }): Promise<void>;
  accessTokenRefreshed(): void;
}

let callbacks: GraphqlCallbacks;

const setGraphqlCallbacks = (newCallbacks: GraphqlCallbacks) => {
  callbacks = { ...newCallbacks };
};

const cleanTypeName = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    operation.variables = omitDeep(operation.variables, '__typename');
  }
  return forward(operation).map(data => data);
});

const link = new HttpLink({
  uri: process.env.NEW_BE_API,
  fetch: (uri: RequestInfo, options: RequestInit) => {
    options.headers = getHeaders();
    return fetch(uri, options);
  },
});

const getHeaders = () => {
  const headers: { Authorization?: string; 'Content-Type'?: string } = {};
  const token = localStorage.getItem('mp_c360_access');

  if (token) {
    headers['Authorization'] = `Bearer ${token}`;
  }

  headers['Content-Type'] = 'application/json';

  headers['timezoneoffset'] = new Date().getTimezoneOffset();

  return headers;
};

const refreshToken = async (refreshToken: string): Promise<Tokens> => {
  const { data } = await client.mutate({
    mutation: RefreshAccessTokenDocument,
    variables: {
      refreshToken,
    },
  });

  callbacks.accessTokenRefreshed();

  return data.refreshAccessToken;
};

const errorLink = onError(({ graphQLErrors, operation, forward }) => {
  if (graphQLErrors) {
    for (const err of graphQLErrors) {
      switch (err.message) {
        case 'Unauthorized':
          // TODO: rethink scenario when many request failed with Unauthorized (now refresh for each of them)
          const refresh = localStorage.getItem('mp_c360_refresh');

          if (!refresh) {
            return;
          }

          return fromPromise(
            refreshToken(refresh).catch(() => {
              localStorage.setItem('mp_c360_refresh', null);
              localStorage.setItem('mp_c360_access', null);
              return;
            }),
          )
            .filter(value => Boolean(value))
            .flatMap(tokens => {
              if (tokens) {
                localStorage.setItem('mp_c360_refresh', tokens.refresh);
                localStorage.setItem('mp_c360_access', tokens.access);
                const oldHeaders = operation.getContext().headers;

                operation.setContext({
                  headers: {
                    ...oldHeaders,
                    authorization: `Bearer ${tokens.access}`,
                  },
                });
              }

              return forward(operation);
            });
        default:
          const { onErrorTrackError, onErrorShowSnackBar } = callbacks;
          const data = {
            code: 200,
            graphqlCode: err.extensions.code as string,
            graphqlMessage: err.message,
          };
          onErrorShowSnackBar({ message: err?.message });
          onErrorTrackError(data);
      }
    }
  }
});

const mpLinks = ApolloLink.from([errorLink, cleanTypeName, link]);

const cache = new InMemoryCache();
// TODO: temporary disabling cache
const defaultOptions: DefaultOptions = {
  query: {
    fetchPolicy: 'no-cache',
  },
};

const client = new ApolloClient({
  cache,
  link: mpLinks,
  defaultOptions,
});

export * from './_types';
export { client, setGraphqlCallbacks };

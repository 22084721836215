interface MetaConfig {
  /**
   * Title of the page used for browser tab title.
   */
  title: string;

  /**
   * Whether to skip auth checks for this page or not.
   */
  skipAuth?: boolean;

  /**
   * Route name to return from this page.
   */
  backRoute?: string;

  /**
   * Whether to show dialog on fullscreen or not.
   */
  fullscreen?: boolean;

  /**
   * Is exporting page.
   */
  exporting?: boolean;
}

export const meta = (config: MetaConfig) => {
  const { title, skipAuth, backRoute, fullscreen, exporting } = config;
  return {
    title,
    skipAuth: skipAuth ?? false,
    fullscreen: fullscreen ?? false,
    exporting: exporting ?? false,
    ...(backRoute ? { backRoute: { name: backRoute } } : {}),
  };
};

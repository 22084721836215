
import Vue, { PropType } from 'vue';
import { ClientInsights, ClientProspect } from '@/shared/types';

import WrapperWithTooltip from '@/components/WrapperWithTooltip.vue';
import { Placeholder } from '@/shared/ui';

export default Vue.extend({
  name: 'ClientInsights',

  components: { WrapperWithTooltip, Placeholder },

  props: {
    insights: {
      type: Object as PropType<ClientInsights>,
    },
    prospect: {
      type: Object as PropType<ClientProspect>,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isPending: {
      type: Boolean,
      default: false,
    },
  },

  created() {
    this.$emit('get-prospect');
    this.$emit('get-insights');
  },

  computed: {
    lastUpdated(): string {
      if (!this.prospect) {
        return 'Never';
      }
      const { data } = this.prospect || {};
      const date = data?.updated_at || null;

      if (!date) return 'Never';
      const parsedDate = new Date(Number(data.updated_at) * 1000).toJSON();
      const [actualDate] = parsedDate.split('T');
      return `${actualDate}`;
    },
    insightsData() {
      if (!this.insights) return null;

      const { EstimatedMonthlyAdSpendLower, EstimatedMonthlyAdSpendUpper, ...insightsData } = this.insights || {};

      if (EstimatedMonthlyAdSpendLower && EstimatedMonthlyAdSpendUpper) {
        insightsData['MonthlyAdSpend'] = `${EstimatedMonthlyAdSpendLower} - ${EstimatedMonthlyAdSpendUpper}`;
      }

      const insightHeaders = {
        AnnualRevenue: 'Annual Revenue',
        Locations: 'Locations',
        MonthlyAdSpend: 'Monthly Ad Spend',
        ContentMaturityScore: 'Content Maturity',
        BuzzScore: 'Leaders Avg Score',
        SocialMediaPresenceGrade: 'Social Media',
        AdvertisingGrade: 'Advertising',
        GoogleReviewsGrade: 'Google Review',
        LocalBusinessPresenceGrade: 'Local Presence',
        ContentAnalysisGrade: 'Content Analysis',
        HROrganizationGrade: 'HR Organization',
      };

      const result: { header: string; value: string | number }[] = [];
      for (const key of Object.keys(insightHeaders)) {
        result.push({
          header: insightHeaders[key],
          value: insightsData[key] || 'n / a',
        });
      }

      return result;
    },
  },

  methods: {
    refresh(): void {
      this.$emit('refresh');
    },
  },
});

import * as Types from '../../../../../app/graphql/_types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type UpdateProposalFlagsMutationVariables = Types.Exact<{
  input: Types.MediaplannerProposalBooleansInput;
}>;

export type UpdateProposalFlagsMutation = {
  setMediaplannerProposalBooleans?: {
    id: string;
    goLiveWitoutRetargeting?: boolean | null;
    pixelRequest?: boolean | null;
    conversionTracking?: {
      visits?: { trackVisits: boolean; website?: string | null } | null;
      conversion?: { trackConversion: boolean; websites?: Array<string> | null } | null;
    } | null;
  } | null;
};

export const UpdateProposalFlagsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateProposalFlags' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'MediaplannerProposalBooleansInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setMediaplannerProposalBooleans' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'goLiveWitoutRetargeting' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pixelRequest' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'conversionTracking' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'visits' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'trackVisits' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'website' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'conversion' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'trackConversion' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'websites' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateProposalFlagsMutation, UpdateProposalFlagsMutationVariables>;

import 'reflect-metadata';
import { injectable } from 'inversify';
import { ErrorsModelContract, ErrorInfo } from '@/injectables';

@injectable()
export class ErrorsModel implements ErrorsModelContract {
  getErrorsInfo(code: string | number): ErrorInfo {
    const defaultError = { header: '', text: '' };
    const errors: Record<string, ErrorInfo> = {
      '404': {
        header: 'Page is not found',
        text: 'Sorry, this page no longer exists. Please click the Back button to continue.',
      },
    };
    return errors[code] || defaultError;
  }
}

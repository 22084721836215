import * as Types from '../../../../../app/graphql/_types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { ColorSchemeFragmentDoc } from '../../../../../app/graphql/fragments/output.fragments.generated';
export type GetProposalOutputQueryVariables = Types.Exact<{
  proposalId: Types.Scalars['UUID'];
}>;

export type GetProposalOutputQuery = {
  getProposalOutput?: {
    id: string;
    proposalId: string;
    layoutId?: string | null;
    colors?: Record<string, any> | null;
    dynamicLinks?: Record<string, any> | null;
    colorScheme?: {
      textHeading?: string | null;
      textSubheading?: string | null;
      textBody?: string | null;
      cardTextHeading?: string | null;
      cardTextSubheading?: string | null;
      cardTextBody?: string | null;
      accent?: string | null;
      card?: string | null;
      border?: string | null;
      mapLayer?: string | null;
      primary?: string | null;
    } | null;
    slides: Array<{
      id?: string | null;
      name?: string | null;
      type?: Types.OutputSlideType | null;
      weight?: number | null;
      layoutId: string;
      visibility?: Types.SlideVisibility | null;
      unsavedChanges?: boolean | null;
      orderBeforeProducts?: boolean | null;
      orderAfterProducts?: boolean | null;
      userModified?: boolean | null;
      defaultOrder?: number | null;
      slideSourceId?: string | null;
      slideVariable?: string | null;
      custom?: boolean | null;
      templateVariation?: Record<string, any> | null;
      colors?: Record<string, any> | null;
      images?: Record<string, any> | null;
      textItems?: Record<string, any> | null;
      shapes?: Record<string, any> | null;
      map?: Record<string, any> | null;
      charts?: Record<string, any> | null;
      order?: number | null;
      _id?: string | null;
      defaultSlide?: boolean | null;
      readonly?: boolean | null;
      fromLibrary?: boolean | null;
      isProduct?: boolean | null;
      linkedProduct?: string | null;
    }>;
  } | null;
};

export const GetProposalOutputDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getProposalOutput' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'proposalId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getProposalOutput' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'proposalId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'proposalId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'proposalId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'layoutId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'colors' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dynamicLinks' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'colorScheme' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ColorScheme' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'slides' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'weight' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'layoutId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'visibility' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'unsavedChanges' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'orderBeforeProducts' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'orderAfterProducts' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'userModified' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'defaultOrder' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'slideSourceId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'slideVariable' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'custom' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'templateVariation' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'colors' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'images' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'textItems' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'shapes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'map' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'charts' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                      { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'defaultSlide' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'readonly' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fromLibrary' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isProduct' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkedProduct' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...ColorSchemeFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetProposalOutputQuery, GetProposalOutputQueryVariables>;

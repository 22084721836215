
import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'ReducibleCardContainer',

  props: {
    value: {
      type: Array as PropType<number[]>,
      default: () => [0, 4],
    },
  },
});

import { Module } from 'vuex';
import { Container } from 'inversify';
import {
  LoggerContract,
  ProductServiceContract,
  UIUtilsServiceContract,
  NewProposalModelContract,
  ProductModelContract,
  KeywordsServiceContract,
  PackageServiceContract,
  KeywordsModelContract,
  PackageProductModelContract,
  GeoModelContract,
} from '@/injectables';
import { Models, Services } from '@/injectables/tokens';
import { ProductModuleState, state } from './state';
import { mutations } from './mutations';
import { actions } from './actions';
import { RootState } from '../root/state';

interface ProductModuleFactoryReturn {
  module: Module<ProductModuleState, RootState>;
}

export const productModule = (container: Container): ProductModuleFactoryReturn => {
  const logger = container.get<LoggerContract>('logger');
  const productService = container.get<ProductServiceContract>(Services.Product);
  const productModel = container.get<ProductModelContract>(Models.Product);
  const packageProductModel = container.get<PackageProductModelContract>(Models.PackageProduct);
  const uiUtilsService = container.get<UIUtilsServiceContract>(Services.UI);
  const newProposalModel = container.get<NewProposalModelContract>(Models.NewProposal);
  const packageService = container.get<PackageServiceContract>(Services.Package);
  const keywordsService = container.get<KeywordsServiceContract>(Services.Keywords);
  const keywordsModel = container.get<KeywordsModelContract>(Models.Keywords);
  const geoModel = container.get<GeoModelContract>(Models.Geo);

  return {
    module: {
      namespaced: true as const,
      state: state(newProposalModel, productModel),
      mutations: mutations(),
      actions: actions({
        logger,
        productService,
        uiUtilsService,
        newProposalModel,
        productModel,
        geoModel,
        packageService,
        keywordsService,
        keywordsModel,
        packageProductModel,
      }),
    },
  };
};

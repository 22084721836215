
// TODO: use shared component instead
import { ProductConfigCategory } from '@/app/graphql';
import Vue from 'vue';

const iconLibrary: Record<ProductConfigCategory, string> = {
  [ProductConfigCategory.Test]: 'notes',
  [ProductConfigCategory.Other]: 'notes',
  [ProductConfigCategory.Display]: 'laptop',
  [ProductConfigCategory.Search]: 'money',
  [ProductConfigCategory.Social]: 'person_outline',
  [ProductConfigCategory.Video]: 'movie',
  [ProductConfigCategory.Radio]: 'radio',
  [ProductConfigCategory.Package]: 'widgets',
  [ProductConfigCategory.Ott]: 'connected_tv',
  [ProductConfigCategory.Ctv]: 'connected_tv',
  [ProductConfigCategory.Digvid]: 'smart_display',
  [ProductConfigCategory.Geofen]: 'share_location',
  [ProductConfigCategory.Linear]: 'tv',
  [ProductConfigCategory.Email]: 'mail_outline',
  [ProductConfigCategory.Digaud]: 'mdi-volume-high',
};

export default Vue.extend({
  name: 'LegendPlatform',

  inheritAttrs: false,

  props: ['pieData', 'pieColors'],

  data: (): { maxElements: number } => ({ maxElements: 10 }),

  methods: {
    dotColorStyle(index: number) {
      return {
        'background-color': this.pieColors[index % this.pieColors?.length],
      };
    },
    legendIcon(value): string {
      return iconLibrary[value];
    },
  },

  computed: {
    choosedProduct() {
      if (this.pieData.length > this.maxElements) {
        return this.pieData.slice(0, this.maxElements);
      }
      return this.pieData;
    },
    othersProduct() {
      if (this.choosedProduct.length === this.pieData.length) {
        return [];
      }
      return this.pieData.slice(this.maxElements);
    },
    mdAndDown() {
      return this.$vuetify.breakpoint.mdAndDown;
    },
    countOfColumns() {
      const maxcount = 4;
      return this.othersProduct.length === 1 ? 1 : Math.round(this.othersProduct.length / maxcount);
    },
    columnWidth() {
      return {
        width: this.countOfColumns * 150 + 'px',
      };
    },
    columnValue() {
      return Math.max(12 / this.countOfColumns, 4);
    },
  },
});

/* eslint-disable no-unused-vars */
export enum Services {
  Env = 'envService',
  File = 'fileService',
  Agency = 'agencyService',
  Validation = 'validationService',
  Category = 'categoryService',
  Calendar = 'calendarService',
  Enums = 'enumsService',
  Proposal = 'proposalService',
  Auth = 'authService',
  Client = 'clientService',
  Business = 'businessService',
  Output = 'outputService',
  Management = 'managementService',
  Taxonomy = 'taxonomyService',
  Upload = 'uploadService',
  UI = 'uiUtilsService',
  UX = 'uxUtilsService',
  Tracker = 'tracker',
  NewProposal = 'newProposalService',
  Product = 'productService',
  Contract = 'contractService',
  Geocode = 'geocodeService',
  Package = 'packageService',
  LocalStorage = 'LocalStorageService',
  Keywords = 'keywordsService',
  Creative = 'creativeService',
  HelpSection = 'helpSectionService',
  Polygons = 'polygonsService',
  PitchStatus = 'pitchStatusService',
  BroadcastParser = 'broadcastParserService',
  InstantIO = 'instantIOService',
  TargetingSegments = 'targetingSegmentsService',
  Campaign = 'campaignService',
}

export enum Models {
  Status = 'statusEntity',
  Package = 'packageEntity',
  Product = 'productEntity',
  Flight = 'flightEntity',
  Notification = 'notificationEntity',
  Files = 'filesEntity',
  NewProposal = 'newProposalEntity',
  Chart = 'chartEntity',
  TextEditor = 'textEditorEntity',
  OutputEditor = 'outputEditorEntity',
  Census = 'censusEntity',
  Keywords = 'keywordsEntity',
  HelpSection = 'helpSectionEntity',
  InstantIO = 'instantIOEntity',
  PackageProduct = 'packageProductEntity',
  ProposalProduct = 'ProposalProductEntity',
  Slide = 'slideEntity',
  Budget = 'budgetEntity',
  Output = 'outputEntity',
  Errors = 'errorsEntity',
  BaseProduct = 'baseProductEntity',
  Creatives = 'creativesEntity',
  C360 = 'c360Entity',
  Router = 'routerEntity',
  Date = 'dateEntity',
  Geo = 'geoEntity',
  TargetingSegments = 'targetingSegmentsEntity',
  Campaign = 'campaignEntity',
  Colors = 'colorsEntity',
}

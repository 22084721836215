
// TODO reuse this component in the output editor
import { ValidationServiceContract } from '@/injectables';
import { Services } from '@/injectables/tokens';
import Vue from 'vue';
export default Vue.extend({
  name: 'TextEditor',

  props: {
    value: {
      type: Boolean,
      required: true,
    },
    text: {
      type: String,
      default: '',
    },
    url: {
      type: String,
      default: '',
    },
  },

  data() {
    const url = this.url;
    return { currentUrl: url };
  },

  computed: {
    getLink() {
      const validationService: ValidationServiceContract = this.$container.get(Services.Validation);

      const isLink = validationService.isStringLink(this.currentUrl);

      if (!isLink && this.currentUrl) {
        return 'https://' + this.currentUrl;
      }
      return this.currentUrl;
    },
    showDialog: {
      get(): boolean {
        return this.value;
      },
      set(newValue: boolean) {
        this.$emit('input', newValue);
      },
    },
  },

  methods: {
    closeModal() {
      this.$emit('input', false);
    },
    setURL() {
      this.$emit('set-url', this.getLink);
      this.showDialog = false;
    },
  },
});

import { Module } from 'vuex';
import { Container } from 'inversify';
import { LoggerContract } from '@/injectables';
import { TargetingSegmentsState, state } from './state';
import { mutations } from './mutations';
import { actions } from './actions';
import { RootState } from '../root/state';

interface TargetingSegmentsFactoryReturn {
  module: Module<TargetingSegmentsState, RootState>;
}

// We have to keep getters and actions rootState as any
// see: https://github.com/vuejs/vuex/issues/1756
export const targetingSegmentsModule = (container: Container): TargetingSegmentsFactoryReturn => {
  const logger = container.get<LoggerContract>('logger');

  return {
    module: {
      namespaced: true,
      state: state(),
      mutations: mutations(),
      actions: actions({ logger, container }),
    },
  };
};

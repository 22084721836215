import 'reflect-metadata';
import { injectable } from 'inversify';
import { GeoModelContract, FindObject } from '@/injectables';

@injectable()
export class GeoModel implements GeoModelContract {
  private geoTypeListMapper = {
    zips: 'zipList',
    dmas: 'dmaList',
    states: 'stateList',
    cities: 'cityList',
    counties: 'countyList',
    national: 'stateList',
  };

  getGeoTypeList(geoType: string): string {
    return this.geoTypeListMapper[geoType];
  }

  findObjectToGeoSelection(obj: FindObject) {
    const { type, key, name } = obj;
    if (type === 'city') {
      return { city: name, withState: key };
    }
    if (type === 'state') {
      return { name, short: key };
    }
    if (type === 'county') return key;

    return name;
  }

  flatGeoForGeoService(obj) {
    return {
      zips: obj?.zipList || [],
      states: obj?.stateList || [],
      dmas: obj?.dmaList || [],
      cities: obj?.cityList || [],
      counties: obj.countyList || [],
    };
  }

  cleanGeoString(geoItem): string {
    if (typeof geoItem === 'string') return geoItem;

    if (geoItem?.key?.includes('DMA')) {
      return this.cleanDmaString(geoItem);
    }

    if (geoItem?.key?.includes('CNTY')) return this.cleanCounty(geoItem?.key?.replace('CNTY_', ''));

    if (geoItem?.name) return geoItem?.name ?? '';
    if (geoItem?.city) return geoItem?.city ?? '';

    return '';
  }

  cleanCounty(countyName: string): string {
    const countyArr = countyName.split('_');
    const [stateName, ...countyNameArr] = countyArr;
    return `${countyNameArr.join(' ')}, ${stateName}`;
  }

  geoNameWithState(geoItem): string {
    const withStateTypes = ['city', 'county'];
    let name: string;
    const type = geoItem?.type;

    if (withStateTypes.includes(type)) {
      const [state] = geoItem?.key?.split('_');
      name = `${geoItem.name}, ${state}`;
    } else if (type === 'dma') {
      name = this.cleanDmaString(geoItem);
    } else {
      name = geoItem.name;
    }

    return name;
  }

  isZipCodeValid(zipCode: string): boolean {
    const maxZipCodeLength = 5;

    return !/\D/.test(zipCode) && zipCode.length === maxZipCodeLength;
  }

  cleanDmaString(geoItem): string {
    const splittedKey = geoItem.key?.split('_');
    const stateAbbreviation = splittedKey.pop();
    let formattedName = geoItem?.name;

    if (formattedName && stateAbbreviation && stateAbbreviation.length === 2) {
      formattedName = `${formattedName} (${stateAbbreviation})`;
    }

    return formattedName;
  }
}

import * as Types from '../_types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { TargetingSegmentFragmentDoc } from './campaign.fragment.generated';
import { ProposalPackageFragmentFragmentDoc } from './package.fragment.generated';
import { ProductFragmentFragmentDoc, ProductContractsFragmentDoc } from './product.fragment.generated';
import { ProductCreativeFragmentDoc } from './creatives.fragment.generated';
export type ProposalTableFragmentFragment = {
  id: string;
  name?: string | null;
  budget?: number | null;
  status: string;
  updatedAt?: string | null;
  campaignStartDate?: string | null;
  campaignEndDate?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  client?: { id: string; name: string; logo?: string | null } | null;
  createdBy?: { email: string } | null;
  updatedBy?: { email: string } | null;
};

export type ProposalFullFragmentFragment = {
  id: string;
  name?: string | null;
  agencyId: string;
  budget?: number | null;
  campaignStartDate?: string | null;
  campaignEndDate?: string | null;
  goal?: string | null;
  secondaryGoal?: string | null;
  goLiveWitoutRetargeting?: boolean | null;
  pixelRequest?: boolean | null;
  updatedAt?: string | null;
  status: string;
  nextStatuses?: Array<Types.MediaplannerProposalStatus | null> | null;
  startDate?: string | null;
  endDate?: string | null;
  client?: {
    id: string;
    name: string;
    logo?: string | null;
    category?: { name: string } | null;
    geoSelections?: {
      cityList?: Array<{ key?: string | null; name?: string | null }> | null;
      countyList?: Array<{ key?: string | null; name?: string | null }> | null;
      dmaList?: Array<{ key?: string | null; name?: string | null }> | null;
      stateList?: Array<{ key?: string | null; name?: string | null }> | null;
      zipList?: Array<{ key?: string | null; name?: string | null }> | null;
    } | null;
    locations?: Array<{
      address?: string | null;
      city?: string | null;
      state?: string | null;
      label?: string | null;
      lat?: number | null;
      lon?: number | null;
      zip?: string | null;
      county?: string | null;
    } | null> | null;
  } | null;
  agency: { name: string };
  audienceSegments?: Array<{
    id: string;
    name: string;
    externalId: string;
    description?: string | null;
    segmentCategory: Types.SegmentCategory;
    segmentSubCategory?: Types.SegmentSubCategory | null;
    isMain: boolean;
  }> | null;
  conversionTracking?: {
    visits?: { trackVisits: boolean; website?: string | null } | null;
    conversion?: { trackConversion: boolean; websites?: Array<string> | null } | null;
  } | null;
  createdBy?: { email: string } | null;
  updatedBy?: { email: string } | null;
  contracts: Array<{ id: string; url: string }>;
  products: Array<
    | {
        __typename: 'MediaplannerPackage';
        id: string;
        index?: number | null;
        category: Types.ProductConfigCategory;
        name: string;
        budget: number;
        packageDescription?: string | null;
        products?: Array<{
          id: string;
          dsId?: string | null;
          name: string;
          index?: number | null;
          fulfillmentMethod: Types.ProductConfigFullfillmentMethod;
          isEditable?: boolean | null;
          isHidden?: boolean | null;
          isLocked?: boolean | null;
          minDays?: number | null;
          minSpend?: number | null;
          budget: number;
          omsAccountNumber?: string | null;
          omsName?: string | null;
          category: Types.ProductConfigCategory;
          description?: Record<string, any> | null;
          recommendedBudget?: number | null;
          flightType?: string | null;
          type?: Types.ProductConfigType | null;
          IORecipientEmail?: string | null;
          rateRangeList?: Record<string, any> | null;
          noXmlFlag?: boolean | null;
          keyMetric?: Types.KeyMetric | null;
          keyMetricMultiplier?: number | null;
          productConfigId: string;
          isMonthly?: boolean | null;
          calcMinSpend?: number | null;
          keywords?: {
            list?: Array<{
              keyword?: string | null;
              searchVolume?: number | null;
              competition?: number | null;
              competitionLevel?: string | null;
              averageCpc?: number | null;
              recommendLevel?: number | null;
              isSelected?: boolean | null;
              isCustom?: boolean | null;
            } | null> | null;
            summary?: {
              numKeywords?: number | null;
              totalCpc?: number | null;
              totalClicks?: number | null;
              lowerCpc?: number | null;
              upperCpc?: number | null;
              lowerClicks?: number | null;
              upperClicks?: number | null;
            } | null;
          } | null;
          broadcastInfo?: {
            link?: string | null;
            broadcast?: Array<{
              days?: Array<Types.Weekdays | null> | null;
              startTime: string;
              endTime: string;
              spots: number;
              spend: number;
              rating: number;
              programName: string;
              station: string;
            } | null> | null;
          } | null;
          geoSelections?: {
            cityList?: Array<{ key?: string | null; name?: string | null }> | null;
            countyList?: Array<{ key?: string | null; name?: string | null }> | null;
            dmaList?: Array<{ key?: string | null; name?: string | null }> | null;
            stateList?: Array<{ key?: string | null; name?: string | null }> | null;
          } | null;
          questionnaire?: Array<{ id: string; index: number; answer: string; questionnaireId: string } | null> | null;
          flights?: Array<{
            id: string;
            index?: number | null;
            budget?: number | null;
            startDate?: string | null;
            endDate?: string | null;
            rate?: number | null;
            rateType?: Types.RateType | null;
            selectedPlatform?: string | null;
            isLocked?: boolean | null;
            platformOption?: Array<string | null> | null;
            targetingOption?: string | null;
            flightCategory?: Types.FlightCategory | null;
            market?: Record<string, any> | null;
            mapType?: Types.MapType | null;
            contextualKeywords?: Array<string | null> | null;
            advancedOptions?: {
              autoRenewList?: Array<string | null> | null;
              abTestFlag?: boolean | null;
              darkPeriodList?: Array<{ index: number; startDate: string; endDate: string } | null> | null;
            } | null;
            audienceSegments?: Array<{
              id: string;
              name: string;
              externalId: string;
              description?: string | null;
              segmentCategory: Types.SegmentCategory;
              segmentSubCategory?: Types.SegmentSubCategory | null;
              isMain: boolean;
            }> | null;
          } | null> | null;
          flightConfigs?: Array<{
            id: string;
            markupMax?: number | null;
            markupMin?: number | null;
            rateType?: Types.RateType | null;
            rate?: number | null;
            adFormatList?: Record<string, any> | null;
            targetingOption?: string | null;
            flightCategory?: Types.FlightCategory | null;
            platformOption?: Array<string | null> | null;
            mapType?: Types.MapType | null;
            advancedOptions?: {
              autoRenewList?: Array<string | null> | null;
              abTestFlag?: boolean | null;
              darkPeriodList?: Array<{ index: number; startDate: string; endDate: string } | null> | null;
            } | null;
          } | null> | null;
          creatives?: Array<{
            id: string;
            index?: number | null;
            url?: string | null;
            creativeLink?: string | null;
            customMargin?: number | null;
            omsNumber?: string | null;
            notes?: string | null;
            vastTag?: string | null;
            socialLink?: string | null;
            postText?: string | null;
            headline?: string | null;
            description?: string | null;
            selectedAdFormatList?: Record<string, any> | null;
            flights?: Array<{ id: string } | null> | null;
            creatives?: Array<{
              id: string;
              index?: number | null;
              url?: string | null;
              creativeLink?: string | null;
              customMargin?: number | null;
              omsNumber?: string | null;
              notes?: string | null;
              vastTag?: string | null;
              socialLink?: string | null;
              postText?: string | null;
              headline?: string | null;
              description?: string | null;
            }> | null;
          } | null> | null;
          contract?: { id: string; url: string } | null;
        }> | null;
      }
    | {
        __typename: 'MediaplannerProduct';
        id: string;
        dsId?: string | null;
        name: string;
        index?: number | null;
        fulfillmentMethod: Types.ProductConfigFullfillmentMethod;
        isEditable?: boolean | null;
        isHidden?: boolean | null;
        isLocked?: boolean | null;
        minDays?: number | null;
        minSpend?: number | null;
        budget: number;
        omsAccountNumber?: string | null;
        omsName?: string | null;
        category: Types.ProductConfigCategory;
        description?: Record<string, any> | null;
        recommendedBudget?: number | null;
        flightType?: string | null;
        type?: Types.ProductConfigType | null;
        IORecipientEmail?: string | null;
        rateRangeList?: Record<string, any> | null;
        noXmlFlag?: boolean | null;
        keyMetric?: Types.KeyMetric | null;
        keyMetricMultiplier?: number | null;
        productConfigId: string;
        isMonthly?: boolean | null;
        calcMinSpend?: number | null;
        keywords?: {
          list?: Array<{
            keyword?: string | null;
            searchVolume?: number | null;
            competition?: number | null;
            competitionLevel?: string | null;
            averageCpc?: number | null;
            recommendLevel?: number | null;
            isSelected?: boolean | null;
            isCustom?: boolean | null;
          } | null> | null;
          summary?: {
            numKeywords?: number | null;
            totalCpc?: number | null;
            totalClicks?: number | null;
            lowerCpc?: number | null;
            upperCpc?: number | null;
            lowerClicks?: number | null;
            upperClicks?: number | null;
          } | null;
        } | null;
        broadcastInfo?: {
          link?: string | null;
          broadcast?: Array<{
            days?: Array<Types.Weekdays | null> | null;
            startTime: string;
            endTime: string;
            spots: number;
            spend: number;
            rating: number;
            programName: string;
            station: string;
          } | null> | null;
        } | null;
        geoSelections?: {
          cityList?: Array<{ key?: string | null; name?: string | null }> | null;
          countyList?: Array<{ key?: string | null; name?: string | null }> | null;
          dmaList?: Array<{ key?: string | null; name?: string | null }> | null;
          stateList?: Array<{ key?: string | null; name?: string | null }> | null;
        } | null;
        questionnaire?: Array<{ id: string; index: number; answer: string; questionnaireId: string } | null> | null;
        flights?: Array<{
          id: string;
          index?: number | null;
          budget?: number | null;
          startDate?: string | null;
          endDate?: string | null;
          rate?: number | null;
          rateType?: Types.RateType | null;
          selectedPlatform?: string | null;
          isLocked?: boolean | null;
          platformOption?: Array<string | null> | null;
          targetingOption?: string | null;
          flightCategory?: Types.FlightCategory | null;
          market?: Record<string, any> | null;
          mapType?: Types.MapType | null;
          contextualKeywords?: Array<string | null> | null;
          advancedOptions?: {
            autoRenewList?: Array<string | null> | null;
            abTestFlag?: boolean | null;
            darkPeriodList?: Array<{ index: number; startDate: string; endDate: string } | null> | null;
          } | null;
          audienceSegments?: Array<{
            id: string;
            name: string;
            externalId: string;
            description?: string | null;
            segmentCategory: Types.SegmentCategory;
            segmentSubCategory?: Types.SegmentSubCategory | null;
            isMain: boolean;
          }> | null;
        } | null> | null;
        flightConfigs?: Array<{
          id: string;
          markupMax?: number | null;
          markupMin?: number | null;
          rateType?: Types.RateType | null;
          rate?: number | null;
          adFormatList?: Record<string, any> | null;
          targetingOption?: string | null;
          flightCategory?: Types.FlightCategory | null;
          platformOption?: Array<string | null> | null;
          mapType?: Types.MapType | null;
          advancedOptions?: {
            autoRenewList?: Array<string | null> | null;
            abTestFlag?: boolean | null;
            darkPeriodList?: Array<{ index: number; startDate: string; endDate: string } | null> | null;
          } | null;
        } | null> | null;
        creatives?: Array<{
          id: string;
          index?: number | null;
          url?: string | null;
          creativeLink?: string | null;
          customMargin?: number | null;
          omsNumber?: string | null;
          notes?: string | null;
          vastTag?: string | null;
          socialLink?: string | null;
          postText?: string | null;
          headline?: string | null;
          description?: string | null;
          selectedAdFormatList?: Record<string, any> | null;
          flights?: Array<{ id: string } | null> | null;
          creatives?: Array<{
            id: string;
            index?: number | null;
            url?: string | null;
            creativeLink?: string | null;
            customMargin?: number | null;
            omsNumber?: string | null;
            notes?: string | null;
            vastTag?: string | null;
            socialLink?: string | null;
            postText?: string | null;
            headline?: string | null;
            description?: string | null;
          }> | null;
        } | null> | null;
        contract?: { id: string; url: string } | null;
      }
  >;
  market?: {
    geoSelections?: {
      cityList?: Array<{ key?: string | null; name?: string | null }> | null;
      countyList?: Array<{ key?: string | null; name?: string | null }> | null;
      dmaList?: Array<{ key?: string | null; name?: string | null }> | null;
      stateList?: Array<{ key?: string | null; name?: string | null }> | null;
      zipList?: Array<{ key?: string | null; name?: string | null }> | null;
    } | null;
  } | null;
};

export type ProposalGeoFragmentFragment = {
  market?: {
    geoSelections?: {
      cityList?: Array<{ key?: string | null; name?: string | null }> | null;
      countyList?: Array<{ key?: string | null; name?: string | null }> | null;
      dmaList?: Array<{ key?: string | null; name?: string | null }> | null;
      stateList?: Array<{ key?: string | null; name?: string | null }> | null;
      zipList?: Array<{ key?: string | null; name?: string | null }> | null;
    } | null;
  } | null;
};

export const ProposalTableFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProposalTableFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MediaplannerProposal' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'budget' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'email' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'email' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'startDate' },
            name: { kind: 'Name', value: 'campaignStartDate' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'endDate' },
            name: { kind: 'Name', value: 'campaignEndDate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'campaignStartDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'campaignEndDate' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProposalTableFragmentFragment, unknown>;
export const ProposalGeoFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProposalGeoFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MediaplannerProposal' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'market' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'geoSelections' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cityList' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'countyList' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dmaList' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'stateList' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'zipList' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProposalGeoFragmentFragment, unknown>;
export const ProposalFullFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProposalFullFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MediaplannerProposal' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'category' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'geoSelections' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cityList' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'countyList' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dmaList' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'stateList' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'zipList' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'locations' },
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lat' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lon' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'county' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'agencyId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'budget' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'audienceSegments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'TargetingSegment' } }],
            },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProposalGeoFragment' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'startDate' },
            name: { kind: 'Name', value: 'campaignStartDate' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'endDate' },
            name: { kind: 'Name', value: 'campaignEndDate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'campaignStartDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'campaignEndDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'goal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'secondaryGoal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'goLiveWitoutRetargeting' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pixelRequest' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'conversionTracking' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'visits' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'trackVisits' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'website' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'conversion' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'trackConversion' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'websites' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'email' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'email' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nextStatuses' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contracts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'products' },
            name: { kind: 'Name', value: 'solutions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MediaplannerPackage' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProposalPackageFragment' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'products' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProductFragment' } },
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProductCreative' } },
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProductContracts' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MediaplannerProduct' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProductFragment' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProductCreative' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProductContracts' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProposalFullFragmentFragment, unknown>;

// We have to keep getters and actions rootState as any

import { Container } from 'inversify';
import { Module } from 'vuex';
import { RootState } from '../root/state';
import { actions } from './actions';
import { getters } from './getters';
import { mutations } from './mutations';
import { state } from './state';
import { OutputState } from './types';

interface OutputModuleFactoryResult {
  module: Module<OutputState, RootState>;
}

// see: https://github.com/vuejs/vuex/issues/1756
export const outputModule = (container: Container): OutputModuleFactoryResult => {
  return {
    module: {
      namespaced: true,
      state: state(),
      getters: getters(container),
      mutations: mutations(container),
      actions: actions(container),
    },
  };
};
